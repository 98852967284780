export const contactsSorting = [
  { value: "[none]", label: "Relevance" },
  { value: "sanitized_organization_name_unanalyzed", label: "Company (Slow)" },
  { value: "person_name.raw", label: "Name (Slow)" },
  { value: "person_title_normalized", label: "Title" },
  { value: "person_location_country", label: "Country" },
  { value: "organization_estimated_number_employees", label: "# Employees" },
  { value: "organization_linkedin_industry_tag_ids", label: "Industry" },
  { value: "contact_stage_id", label: "Contact Stage" },
  { value: "contact_last_activity_date", label: "Contact Last Activity" },
  { value: "owner_id", label: "Contact Owner" },
  { value: "contact_created_at", label: "Contact Created" },
  { value: "person_location_state", label: "State" },
  { value: "person_location_city", label: "City" },
  { value: "person_email", label: "Email" },
  { value: "person_phone", label: "Phone" },
  { value: "organization_hq_location_country", label: "Company Country" },
  { value: "organization_hq_location_state", label: "Company State" },
  { value: "organization_hq_location_city", label: "Company City" },
  { value: "account_stage_id", label: "Account Stage" },
  { value: "account_owner_id", label: "Account Owner" },
];

export const accountsSorting = [
  { value: "[none]", label: "Relevance" },
  { value: "sanitized_organization_name_unanalyzed", label: "Name" },
  { value: "account_last_activity_date", label: "Last Contacted Date" },
  { value: "organization_estimated_number_employees", label: "# Employees" },
  { value: "organization_linkedin_industry_tag_ids", label: "Industry" },
  { value: "organization_hq_location_country", label: "Company Country" },
  { value: "account_stage_id", label: "Account Stage" },
  { value: "account_owner_id", label: "Account Owner" },
  { value: "organization_hq_location_state", label: "Company State" },
  { value: "organization_hq_location_city", label: "Company City" },
  { value: "account_created_at", label: "Account Created" },
  { value: "parent_account_id", label: "Parent Account" },
  {
    value: "website_visitor_metrics_v1.last_intent_signal_received_at",
    label: "Website Last Visit",
  },
];
