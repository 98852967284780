import React from "react";
import { YOUTUBE_VIDEOS } from "../../constants/youtubeVideos";

function WalkthroughSidebar({ setSelectedVideo, selectedVideo }) {
  return (
    <div className="walk-through-sidebar">
      <div className="walk-through-sidebar-list">
        {YOUTUBE_VIDEOS.map((video) => (
          <>
            <div className="walk-through-sidebar-list-group">{video.group}</div>
            {video.videos.map((vid) => (
              <div
                key={vid.id}
                className="walk-through-sidebar-list-item"
                onClick={() => setSelectedVideo(vid)}
                style={
                  selectedVideo.src === vid.src
                    ? {
                        color: "#41c99d",
                        fontWeight: "600",
                      }
                    : {}
                }
              >
                {selectedVideo.src === vid.src && (
                  <i
                    className="fa fa-caret-right"
                    aria-hidden="true"
                    style={{ marginRight: "10px" }}
                  ></i>
                )}
                {vid.title}
              </div>
            ))}
          </>
        ))}
      </div>
    </div>
  );
}

export default WalkthroughSidebar;
