import React, { useState } from "react";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import GoogleMapComponent from "../GoogleComponents/GoogleMapComponent";

const CarrierTest = () => {
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const handleOriginPlaceSelect = (place) => {
    setOrigin(place);
    // const { lat, lng } = place.geometry.location;
    // console.log("Latitude:", lat());
    // console.log("Longitude:", lng());
    // console.log("google address check", place);
    // Do something with the selected place, like updating your state or making API calls.
  };
  const handleDestinationPlaceSelect = (place) => {
    setDestination(place);
    // const { lat, lng } = place.geometry.location;
    // console.log("Latitude:", lat());
    // console.log("Longitude:", lng());
    // console.log("google address check", place);
    // Do something with the selected place, like updating your state or making API calls.
  };
  console.log("carreier-test", origin, destination);
  return (
    <div>
      <h1>test</h1>
      <div style={{ display: "flex" }}>
        <GoogleAutocompleteComponent onPlaceSelect={handleOriginPlaceSelect} />
        <GoogleAutocompleteComponent
          onPlaceSelect={handleDestinationPlaceSelect}
        />
      </div>
      {origin?.address && destination?.address && (
        <GoogleMapComponent
          origin={origin.address}
          destination={destination.address}
        />
      )}
    </div>
  );
};

export default CarrierTest;
