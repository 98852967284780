import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
// Redux
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../redux";

class SharedUserSetingModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    isLoading: false,
    companySettings: {
      currency: "USD",
      timezone: null,
      country: "United States",
      date_format: "MM/DD/YYYY",
      is_round_robin_enabled: false,
      is_multiple_account_owner_enabled: false,
      logo: null,
      email: null,
      formatted_mobile: null,
    },
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getCompanySettings = () => {
    axios({
      method: "GET",
      url: `/company/settings`,
    }).then((res) => {
      this.setState({
        companySettings: res.data.settings,
      });
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getCompanySettings();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.getCompanySettings();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  submitHandler = (e) => {
    e.preventDefault();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({
      isLoading: true,
    });
    axios({
      method: "PATCH",
      url: `/company/settings`,
      data: {
        currency: this.state.companySettings.currency,
        timezone,
        country: this.state.companySettings.country || "United States",
        date_format: this.state.companySettings.date_format || "MM/DD/YYYY",
        is_round_robin_enabled:
          this.state.companySettings.is_round_robin_enabled,
        is_multiple_account_owner_enabled:
          this.state.companySettings.is_multiple_account_owner_enabled,
        ...(this.state.companySettings.logo && {
          logo: this.state.companySettings.logo,
        }),
        ...(this.state.companySettings.email && {
          email: this.state.companySettings.email,
        }),
        ...(this.state.companySettings.formatted_mobile && {
          formatted_mobile: this.state.companySettings.formatted_mobile,
        }),
      },
    })
      .then((res) => {
        NotificationManager.success("Company Info Updated.");
        this.props.companyOnboardingSet({
          ...this.props.companyOnboarding,
          is_multiple_account_owner_enabled:
            this.state.companySettings.is_multiple_account_owner_enabled,
        });
        localStorage.setItem(
          "companyOnboarding",
          JSON.stringify({
            ...this.props.companyOnboarding,
            is_multiple_account_owner_enabled:
              this.state.companySettings.is_multiple_account_owner_enabled,
          })
        );
        this.props.handleCloseModal();
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error updating company info");
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "45vh", top: "17%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Shared Account Owners</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <p>
                  If you would like to have multiple team members have ownership
                  and visibility over Accounts in your CRM, please check the box
                  below.
                </p>
                <label>Shared Owner Enabled</label>
                <input
                  type="checkbox"
                  name="is_multiple_account_owner_enabled"
                  style={{ width: "20px", display: "block" }}
                  value={
                    this.state.companySettings.is_multiple_account_owner_enabled
                  }
                  checked={
                    this.state.companySettings.is_multiple_account_owner_enabled
                  }
                  onChange={() =>
                    this.setState((prevState) => ({
                      companySettings: {
                        ...prevState.companySettings,
                        is_multiple_account_owner_enabled:
                          !prevState.companySettings
                            .is_multiple_account_owner_enabled,
                      },
                    }))
                  }
                />
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(SharedUserSetingModal);
