import axios from "axios";
import React, { useState } from "react";
import CreateSequenceTemplateModal from "./CreateSequenceTemplateModal";
import SequenceCreateModal from "./SequenceCreateModal";
import { NotificationManager } from "react-notifications";
import "../../sass/Sequence.scss";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/DragHandler.svg";
import DeleteIcon from "../../images/DeleteIcon.svg";

const DragHandle = sortableHandle(() => (
  <span className="sequence-handle">
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ height: "22px", cursor: "grab" }}
    />
  </span>
));

const SortableItem = sortableElement(
  ({ step, removeStep, setDelay, stepIndex }) => (
    <div>
      <div className="sequence-steps">
        <DragHandle />
        <div style={{ marginLeft: "20px" }}>
          Template Name: <span style={{ fontWeight: "500" }}>{step.name}</span>
          <div style={{ marginTop: "10px" }}>
            Send in{" "}
            <input
              type="number"
              onChange={(e) => setDelay(stepIndex, parseInt(e.target.value))}
              value={step.delay}
              style={{
                display: "inline-block",
                width: "52px",
                marginRight: "5px",
                height: "30px",
              }}
              onWheel={(e) => e.target.blur()}
            />{" "}
            Days
          </div>
        </div>
        <button
          type="button"
          onClick={() => removeStep(stepIndex)}
          className="sequence-button-remove"
        >
          <img src={DeleteIcon} alt="Remove" width="18px" />
        </button>
      </div>
      <div className="sequence-steps-flow"> </div>
    </div>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

function SequenceCreate(props) {
  const [seqName, setSeqName] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [steps, setSteps] = useState([]);
  const [sendOnWeekdays, setSendOnWeekdays] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [stopSequenceOnReply, setStopSequenceOnReply] = useState(true);

  const submitHandler = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: "/sequences",
      data: {
        name: seqName,
        steps: steps?.map((step, index) => {
          return {
            template_id: step.template_id,
            order: index,
            delay: isNaN(step.delay) ? 0 : step.delay * 86400,
          };
        }),
        send_on_week_days: sendOnWeekdays,
        is_private: isPrivate,
        stop_on_reply: stopSequenceOnReply,
      },
    })
      .then((res) => {
        setLoading(false);
        NotificationManager.success("Sequence created successfully");
        props.history.push("/sequencing");
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error creating sequence");
      });
  };

  const removeStep = (i) => {
    const newSteps = steps?.filter((step, index) => index !== i);
    setSteps(newSteps);
  };
  const setDelay = (i, value) => {
    const newStep = steps?.map((step, index) => {
      if (index === i) step.delay = value;
      return step;
    });
    setSteps(newStep);
  };
  return (
    <div className="reports">
      <ReactTooltip multiline={true} place="right" />
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2 style={{ marginBottom: "0px" }}>
            <div className="controlsDashboard">
              <input
                value={seqName}
                onChange={(e) => setSeqName(e.target.value)}
                id="seqName"
                className="floatLabel"
                style={
                  seqName === ""
                    ? {
                        fontSize: "20px",
                        borderRadius: "4px",
                        padding: "8px",
                        border: "2px solid #F36363",
                        paddingTop: "13px",
                        paddingBottom: "5px",
                        minWidth: "300px",
                        marginRight: "10px",
                        height: "40px",
                      }
                    : {
                        fontSize: "20px",
                        borderRadius: "4px",
                        padding: "8px",
                        border: "1px solid #c5c5d3",
                        paddingTop: "13px",
                        paddingBottom: "5px",
                        minWidth: "300px",
                        marginRight: "10px",
                        height: "40px",
                      }
                }
              />
              <label
                for="seqName"
                className="activeLabel"
                style={
                  seqName === ""
                    ? { fontSize: "14px", color: "#F36363", top: "-14px" }
                    : { fontSize: "14px", top: "-14px" }
                }
              >
                Sequence Name
              </label>
            </div>
            <button
              className="button-md"
              style={
                loading || steps.length === 0 || seqName === ""
                  ? {
                      float: "right",
                      background: "#a7abaa",
                      cursor: "not-allowed",
                    }
                  : {
                      float: "right",
                    }
              }
              type="button"
              onClick={submitHandler}
              disabled={loading || steps.length === 0 || seqName === ""}
            >
              Save Sequence
            </button>
          </h2>
          <p> </p>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "59% 41%" }}>
        <div className="sequence">
          <h2 className="sequence-heading">Create Sequence Flow</h2>
          {steps.length === 0 && (
            <>
              <div className="sequence-steps">
                Add your first email template to this sequence.
              </div>
              <div className="sequence-steps-flow"> </div>
            </>
          )}
          <SortableContainer
            onSortEnd={({ oldIndex, newIndex }) => {
              setSteps(arrayMove(steps, oldIndex, newIndex));
            }}
            useDragHandle
          >
            {steps?.map((step, index) => (
              <SortableItem
                step={step}
                removeStep={removeStep}
                setDelay={setDelay}
                stepIndex={index}
                key={`step-${index}`}
                index={index}
              />
            ))}
          </SortableContainer>
          <button
            type="button"
            onClick={() => setShowCreateModal(true)}
            className="button-md"
            style={{
              margin: "5px auto",
              display: "block",
            }}
          >
            Add email template
          </button>
        </div>
        <div>
          <div style={{ marginLeft: "30px" }}>
            <h2 className="sequence-heading">Sequence Settings</h2>
            <div>
              <label style={{ display: "inline-block" }}>
                <label className="switch1">
                  <input
                    type="checkbox"
                    value={isPrivate}
                    checked={!isPrivate}
                    onChange={() => setIsPrivate(!isPrivate)}
                    style={{ width: "20px", display: "inline-block" }}
                  />
                  <span
                    className="slider1 round1"
                    style={{ transform: "translateY(4px)" }}
                  ></span>
                </label>
                <span className="general-heading">
                  {" "}
                  &nbsp;Make sequence visible for all members in your CRM to use
                </span>
              </label>
              <p style={{ margin: "5px 0px 15px 37px" }}>
                If enabled, all users in your CRM will have access to use this
                email sequence.
              </p>
            </div>
            <div>
              <label style={{ display: "inline-block" }}>
                <label className="switch1">
                  <input
                    type="checkbox"
                    value={sendOnWeekdays}
                    onChange={() => setSendOnWeekdays(!sendOnWeekdays)}
                    checked={sendOnWeekdays}
                    style={{ width: "20px", display: "inline-block" }}
                  />
                  <span
                    className="slider1 round1"
                    style={{ transform: "translateY(4px)" }}
                  ></span>
                </label>
                <span className="general-heading">
                  {" "}
                  &nbsp;Execute steps on business days only
                </span>
              </label>
              <p style={{ margin: "5px 0px 15px 37px" }}>
                If enabled, emails will be sent on business days only.
              </p>
            </div>
            <div>
              <label style={{ display: "inline-block" }}>
                <label className="switch1">
                  <input
                    type="checkbox"
                    value={stopSequenceOnReply}
                    onChange={() =>
                      setStopSequenceOnReply(!stopSequenceOnReply)
                    }
                    checked={stopSequenceOnReply}
                    style={{ width: "20px", display: "inline-block" }}
                  />
                  <span
                    className="slider1 round1"
                    style={{ transform: "translateY(4px)" }}
                  ></span>
                </label>
                <span className="general-heading">
                  {" "}
                  &nbsp;Stop Sequence on user reply
                </span>
              </label>
              <p style={{ margin: "5px 0px 15px 37px" }}>
                If enabled, sequence will be stopped if user replies to a
                sequence.
              </p>
            </div>
          </div>
        </div>
      </div>
      <SequenceCreateModal
        showOpportunityModal={showCreateModal}
        setShowOpportunityModal={() => setShowCreateModal(true)}
        handleCloseModal={() => setShowCreateModal(false)}
        createNewTemplate={() => {
          setShowTemplateModal(true);
          setShowCreateModal(false);
        }}
        setSteps={setSteps}
        steps={steps}
      />
      <CreateSequenceTemplateModal
        showOpportunityModal={showTemplateModal}
        setShowOpportunityModal={() => setShowTemplateModal(true)}
        handleCloseModal={() => setShowTemplateModal(false)}
        setSteps={setSteps}
        steps={steps}
      />
    </div>
  );
}

export default withRouter(SequenceCreate);
