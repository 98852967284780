import React, { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

// Redux
import { connect } from "react-redux";
import { authUserSet, authTokenSet, companyOnboardingSet } from "../../redux";

const checkRequests = (Wrapped) => {
  function CheckRequests(props) {
    axios.interceptors.request.use(function (config) {
      const token =
        "Bearer " + JSON.parse(localStorage.getItem("authToken"))?.access_token;
      config.headers.Authorization = token;
      config.headers["user-timezone"] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.baseURL = BASE_URL;

      return config;
    });
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      (error) => {
        console.log("SEEEEE::", error?.response);
        switch (error?.response?.status) {
          case 401:
            // new logout code
            // if (error.config.url === `${BASE_URL}/auth/refresh-token`) {
            //   localStorage.clear();
            //   props.authUserSet(null);
            //   props.authTokenSet(null);

            //   window.location.href = "/";

            //   return new Promise((resolve, reject) => {
            //     reject(error);
            //   });
            // }
            // if (localStorage.getItem("authToken")) {
            //   const authToken = JSON.parse(localStorage.getItem("authToken"));
            //   const authUser = JSON.parse(localStorage.getItem("authUser"));
            //   console.log("hassan testing", authToken);
            //   return axios({
            //     method: "POST",
            //     url: `${BASE_URL}/auth/refresh-token`,
            //     data: {
            //       email: authUser.email,
            //       refresh_token: authToken.refresh_token,
            //     },
            //   })
            //     .then((response) => {
            //       if (response.status === 200) {
            //         const authToken = {
            //           access_token: response.data.token.accessToken,
            //           refresh_token: response.data.token.refreshToken,
            //           expires_in: response.data.token.expiresIn,
            //         };
            //         localStorage.setItem("authToken", authToken);

            //         //   localStorage.setItem(
            //         //     "authToken.access_token",
            //         //     response.data.token.accessToken
            //         //   );
            //         //   localStorage.setItem(
            //         //     "authToken.refresh_token",
            //         //     response.data.token.refreshToken
            //         //   );
            //         //   localStorage.setItem(
            //         //     "authToken.expires_in",
            //         //     response.data.token.expiresIn
            //         //   );
            //       }

            //       // New request with new token
            //       const config = error.config;

            //       config.headers["Authorization"] =
            //         "Bearer " + response.data.token.accessToken;

            //       return new Promise((resolve, reject) => {
            //         axios
            //           .request(config)
            //           .then((response) => {
            //             resolve(response);
            //           })
            //           .catch((error) => {
            //             reject(error);
            //           });
            //       });
            //     })
            //     .catch((error) => {
            //       console.log(error);
            //     });
            // } else {
            //   break;
            // }

            //code ends
            if (window.location.pathname === "/reset-password") {
              const message = error?.response?.data?.message;
              NotificationManager.error(message);
              return error;
            }
            if (error?.response?.config.url === "/users/generate-mfa-otp") {
              return error;
            }
            if (error?.response?.config.url === "/users/enable-mfa") {
              return error;
            }
            if (error?.response?.config.url === "/auth/verify-otp") {
            } else {
              localStorage.clear();
              props.authUserSet(null);
              props.authTokenSet(null);

              window.location.href = "/";
            }
            break;
          // console.log("to ye hai::", error?.response);
          //   return new Promise((resolve, reject) => {
          //     reject(error);
          //   });
          // }

          // if (
          //   moment().isAfter(
          //     moment(
          //       JSON.parse(localStorage.getItem("authToken")).expires_in
          //     )
          //   )
          // ) {
          //   try {
          //     const response = await axios({
          //       method: "POST",
          //       url: `${BASE_URL}/auth/refresh-token`,
          //       data: {
          //         email: JSON.parse(localStorage.getItem("authUser")).email,
          //         refresh_token: JSON.parse(
          //           localStorage.getItem("authToken")
          //         ).refresh_token,
          //       },
          //     });

          //     localStorage.setItem(
          //       "authToken",
          //       JSON.stringify(response.data)
          //     );
          //     props.authTokenSet(response.data);

          //     // New request with new token
          //     const config = error.config;

          //     config.headers["Authorization"] =
          //       "Bearer " + response.data.access_token;
          //     return new Promise((resolve, reject) => {
          //       axios
          //         .request(config)
          //         .then((response_1) => {
          //           resolve(response_1);
          //         })
          //         .catch((error_1) => {
          //           reject(error_1);
          //         });
          //     });
          //   } catch (error_2) {
          //     console.log(error_2);
          //   }
          // }
          case 429:
            NotificationManager.error(
              "Too many requests. Please try again later."
            );
            break;
          case 403:
            NotificationManager.error(
              "You do not have permission to access this"
            );
            break;
          case 402:
            NotificationManager.error("Company Subscription expired");
            if (props.authUser?.role === "SUPERADMIN") {
              window.location.href = "/payment";
            }
            break;
          case 400:
            error?.response?.data?.errors?.body
              ? NotificationManager.error(
                  error?.response?.data?.errors?.body
                    .map((err) => err?.message)
                    .join(", ")
                )
              : NotificationManager.error(error?.response?.data.message);
            break;
          case 406:
            console.log("Invalid Data");
            break;
          case 409:
            console.log("Conflict");
            break;
          case 500:
            console.error(
              "Some Error occured. Please try again after sometime"
            );
            break;
          default:
            break;
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );
    useEffect(() => {
      console.log("ZMZMZpropsbhaaand:", props);
    });

    return (
      <div>
        <NotificationContainer />
        <Wrapped {...props} />
      </div>
    );
  }
  const MSP = (state) => {
    return {
      authToken: state.login.authToken,
      authUser: state.login.authUser,
      companyOnboarding: state.login.companyOnboarding,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
      authTokenSet: (authUserData) => dispatch(authTokenSet(authUserData)),
      companyOnboardingSet: (companyOnboardingData) =>
        dispatch(companyOnboardingSet(companyOnboardingData)),
    };
  };
  return connect(MSP, mapDispatchToProps)(CheckRequests);
};

export default checkRequests;
