import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  // DirectionsService,
  DirectionsRenderer,
  Marker,
} from "@react-google-maps/api";

// Inside InfoBox.js
function InfoBox({ distance, duration }) {
  return (
    <div className="info-box-check-hassan">
      <p>Distance: {distance}</p>
      <p>Duration: {duration}</p>
    </div>
  );
}

function GoogleMapComponent({ origin, destination, setMileage }) {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };
  const originLoc = { lat: origin.lat, lng: origin.long }; // First location
  const destinationLoc = { lat: destination.lat, lng: destination.long }; // Second location

  const [center, setCenter] = useState({ lat: 0, lng: 0 }); // Initial center
  const [directions, setDirections] = useState(null);
  const [zoom, setZoom] = useState(10); // Initial zoom level
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");

  // Function to calculate the center and zoom between two locations
  const calculateCenterAndZoom = (origin, destination) => {
    // Calculate the center point
    const newCenter = {
      lat: (origin.lat + destination.lat) / 2,
      lng: (origin.long + destination.long) / 2,
    };

    // Calculate the zoom level based on the distance between the two locations
    // You can adjust the zoom calculation as needed
    const distance = Math.sqrt(
      (origin.lat - destination.lat) ** 2 +
        (origin.long - destination.long) ** 2
    );

    const newZoom = Math.min(
      14, // Set a maximum zoom level if desired
      Math.floor(12 - Math.log2(distance)) // Adjust the zoom level calculation as needed
    );

    return { center: newCenter, zoom: newZoom };
  };

  // Function to handle user input or other triggers to update the center and zoom
  const updateCenterAndZoom = (origin, destination) => {
    const { center: newCenter, zoom: newZoom } = calculateCenterAndZoom(
      origin,
      destination
    );
    setCenter(newCenter);
    setZoom(newZoom);
  };

  useEffect(() => {
    if (origin.lat && destination.lat) updateCenterAndZoom(origin, destination);
  }, [origin.lat, destination.lat]);

  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();

    const directionsRequest = {
      // routingPreference: "traffic_unaware",
      // polylineQuality: "high_quality",
      // computeAlternativeRoutes: false,
      // routeModifiers: {
      //   avoidTolls: false,
      //   avoidHighways: false,
      //   avoidFerries: false,
      //   avoidIndoor: false,
      // },
      destination: destinationLoc,
      origin: originLoc,
      travelMode: "DRIVING", // You can change this to 'WALKING', 'BICYCLING', etc.
    };

    directionsService.route(directionsRequest, (result, status) => {
      if (status === "OK") {
        setDirections(result);

        // Extract distance and duration from the route response
        const route = result.routes[0].legs[0];
        setDistance(route.distance.text);
        const numbers = route.distance.text.match(/\d+/g); // This will match all digits in the string
        const mileage = numbers ? numbers.join("") : "";
        setMileage && setMileage(parseFloat(mileage));
        setDuration(route.duration.text);
      } else {
        console.error("Directions request failed with status:", status);
      }
    });
  }, [origin.lat, destination.lat]);
  console.log(
    "google map stuff",
    distance,
    duration,
    originLoc,
    destinationLoc
  );
  return (
    <>
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={center}
        zoom={zoom}
        options={{ gestureHandling: "greedy" }}
      >
        {/* {directions && <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }}/>}

      <DirectionsService
        options={directionsOptions}
        callback={directionsCallback}
      /> */}
        {/* Marker for the first location */}
        <Marker position={originLoc} />

        {/* Marker for the second location */}
        <Marker position={destinationLoc} />
        {directions && <DirectionsRenderer directions={directions} />}
        {/* Display distance and duration */}
      </GoogleMap>
      {distance && duration && (
        <InfoBox distance={distance} duration={duration} />
      )}
    </>
  );
}

export default GoogleMapComponent;
