import {
  FETCH_ACCOUNT_SOURCES_REQUEST,
  FETCH_ACCOUNT_SOURCES_SUCCESS,
  FETCH_ACCOUNT_SOURCES_FAILURE,
} from "./types";

const initialState = {
  accountSources: [],
  loading: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SOURCES_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case FETCH_ACCOUNT_SOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        accountSources: action.payload,
      };
    case FETCH_ACCOUNT_SOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        accountSources: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
