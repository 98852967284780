import React from "react";
import "../../sass/DropdownHeader.scss";
import DownloadIcon from "../../images/downloadIcon.svg";

class DropdownDownload extends React.Component {
  state = {
    displayMenu: false,
  };

  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  render() {
    return (
      <div
        className="dropdownTask"
        style={{ background: "transparent", width: "20px" }}
        ref={this.wrapperRef}
      >
        <div
          className="button"
          style={{ fontSize: "14px", top: "0", left: "0" }}
          onClick={(e) =>
            !this.props.downloadLoader && this.showDropdownMenu(e)
          }
        >
          <button
            className="button-outline-green"
            style={
              this.props.downloadLoader
                ? {
                    height: "35px",
                    width: "35px",
                    background: "rgb(229, 229, 229)",
                    border: "1px solid rgb(167, 171, 170)",
                    cursor: "not-allowed",
                  }
                : { height: "35px", width: "35px" }
            }
          >
            <img src={DownloadIcon} alt="download" width="20px" />
          </button>
        </div>

        {this.state.displayMenu ? (
          <ul style={{ width: "133px", top: "20px", borderRadius: "4px" }}>
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.download("pdf");
                this.setState({ displayMenu: false });
              }}
              style={{ width: "112.5px" }}
            >
              Download as pdf
            </li>
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.download("png");
                this.setState({ displayMenu: false });
              }}
              style={{ width: "112.5px" }}
            >
              Download as png
            </li>
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.download("jpeg");
                this.setState({ displayMenu: false });
              }}
              style={{ width: "112.5px" }}
            >
              Download as jpeg
            </li>
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.download("svg+xml");
                this.setState({ displayMenu: false });
              }}
              style={{ width: "112.5px" }}
            >
              Download as svg+xml
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default DropdownDownload;
