import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/CustomerModal.scss";
import axios from "axios";
import OpportunityIcon from "../../images/DealsIntroLogo.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

ReactModal.defaultStyles.overlay.zIndex = 10000001;

class DummyOppModal extends Component {
  state = {
    fieldsData: [],
    account_id: this.props.customerId,
    contactId: this.props.contactId,
    name: "",
    description: null,
    revenue: "",
    stages: [],
    stage_id: "",
    close_date: "",
    showModal: true,
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    close_reason_value: null,
    close_reason: null,
    isLoading: false,
    windowWidth: window.innerWidth,
    searchItems: [],
    searchValue: "",
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.initialStates();
  }
  initialStates = () => {
    axios({
      method: "GET",
      url: `/close-reasons`,
    }).then((response) => {
      this.setState({
        close_reason: response.data.reasons,
      });
    });
  };
  showModal = () => {
    ReactModal.isOpen = false;
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };
  closeDateChangeHandler = (type, date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState({
      [type]: offsetDate,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (
      data.name === null ||
      data.revenue === null ||
      data.close_date === null ||
      data.name === "" ||
      data.revenue === "" ||
      data.close_date === ""
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return;
    }
    const close_date =
      data.close_date &&
      moment(data.close_date, "YYYY-MM-DD").format("MMMM DD, YYYY");
    NotificationManager.success("Deal created successfully!");
    this.props.handleCloseModal();
    this.props.setDealData({
      name: data.name,
      stage: data.stage_id,
      date: close_date,
    });
    this.props.setShowDeal(true);
    this.setState({ isLoading: false });
  };
  cancelStep = () => {
    this.props.handleCloseModal();
    this.props.setDealData({
      name: "Sample Deal",
      stage: "New",
      date: moment().format("MMMM DD, YYYY"),
    });
    this.props.setShowDeal(true);
    this.setState({ isLoading: false });
  };
  render() {
    const { name, description, revenue, stage_id } = this.state;
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          ariaHideApp={false}
          style={modalStyle}
        >
          <div className="modal">
            <div className="modal__helper">
              The three required fields to create a deal are{" "}
              <strong>Deal Name</strong>, <strong>Revenue</strong>, and{" "}
              <strong>Close Date</strong>.<br /> This helps you forecast revenue
              potential and to see projected deals to close within a given time
              period.
            </div>
            <div className="modal__content">
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "#F8E9E2",
                      transform: "translateY(-18px)",
                    }}
                  >
                    <img
                      src={OpportunityIcon}
                      alt="Icon"
                      width="17px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Create a deal</h2>
                  <p className="modal__heading-desc">
                    Add some information about the deal
                  </p>
                </div>
              </div>
              <form
                onSubmit={this.submitHandler}
                style={{
                  maxHeight: "66vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <NotificationContainer />
                <label>Account</label>
                <input
                  type="text"
                  value={this.props?.customerName}
                  readOnly={true}
                />
                <label>
                  Deal Name{" "}
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.name === null || this.state.name === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? "Name is required"
                    : null}
                </div>
                <label>
                  Revenue{" "}
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="number"
                  name="revenue"
                  value={revenue}
                  pattern="^[0-9]*$"
                  min="0"
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.revenue === null || this.state.revenue === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.revenue === null || this.state.revenue === "")
                    ? "Revenue is required"
                    : null}
                </div>
                <label>
                  Close Date{" "}
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                </label>
                <DatePicker
                  name="close_date"
                  selected={Date.parse(this.state.close_date)}
                  value={Date.parse(this.state.close_date)}
                  onChange={(date) =>
                    this.closeDateChangeHandler("close_date", date)
                  }
                  placeholderText="mm/dd/yyyy"
                  autoComplete="off"
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.close_date === null ||
                    this.state.close_date === "")
                    ? "Date is required"
                    : null}
                </div>
                <label>Stage</label>
                <select
                  name="stage_id"
                  value={stage_id}
                  onChange={this.changeHandler}
                >
                  <option>New</option>
                  <option>Discovery</option>
                  <option>Proposal</option>
                  <option>Negotiation</option>
                  <option>Close</option>
                  <option>Won</option>
                  <option>Lost</option>
                </select>
                {this.state?.stage_id === "Won" ||
                this.state?.stage_id === "Lost" ? (
                  <div>
                    <h5 className="c-title-act">Close Reason</h5>
                    {this.state?.stage_id === "Won" ? (
                      <select
                        name="close_reason_value"
                        value={this.state.close_reason_value}
                        onChange={this.changeHandler}
                      >
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        {this.state?.close_reason?.map((reason) => {
                          const { name, id, type } = reason;
                          let options;
                          options =
                            type === "won" ? (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ) : null;
                          return options;
                        })}
                      </select>
                    ) : (
                      <select
                        name="close_reason_value"
                        value={this.state.close_reason_value}
                        onChange={this.changeHandler}
                      >
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        {this.state?.close_reason?.map((reason) => {
                          const { name, id, type } = reason;
                          let options;
                          options =
                            type === "lost" ? (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ) : null;
                          return options;
                        })}
                      </select>
                    )}
                  </div>
                ) : null}
                <label>Description</label>
                <textarea
                  name="description"
                  value={description}
                  style={{ fontFamily: "inherit" }}
                  onChange={this.changeHandler}
                ></textarea>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.cancelStep}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default DummyOppModal;
