import React, { useState, useEffect } from "react";
import "../../sass/Tasks.scss";
import Task from "../Task";
import axios from "axios";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "react-dates/initialize";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../redux";
import HottestFollowupTask from "../HottestFollowup/HottestFollowupTask";
import DashboardLoader from "../Loaders/DashboardLoader";

const TasksColored = (props) => {
  const [tasks, setTasks] = useState([]);
  const [loader, setLoader] = useState(false);
  const [taskFilter, setTaskFilter] = useState([
    "scheduled",
    "open",
    "overdue",
    "contact_account_carrier",
  ]); //scheduled,open,overdue,contact_account_carrier
  const getTasks = () => {
    let url = `/dashboard/customized-tasks-list?user_ids=${props.userId}`;
    if (taskFilter !== "all") {
      url += `&entity_type=${taskFilter}`;
    }
    setLoader(true);
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setTasks(res.data.tasks);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const filterHandler = (e) => {
    console.log("filter");
    const { value } = e.target;
    if (e.target.checked) {
      const newFilters = [...taskFilter, value];
      setTaskFilter(newFilters);
    } else {
      const removeFilter = taskFilter.filter((filter) => filter !== value);
      setTaskFilter(removeFilter);
    }
  };
  useEffect(() => {
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilter, props.userId]);
  useEffect(() => {
    props.setClick(getTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="card" style={{ textAlign: "left", padding: "7px 20px" }}>
      <h3 className="h2">
        <span
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) {
              props.setTab({
                type: "task",
                id: `${props.userId}allTasks`,
                name: "All Tasks",
                blank: true,
                taskType: "",
                userId: props.userId,
                userName: props.userName,
              });
            } else {
              props.setActiveTabIndex(props.totalTabs + 1);
              props.setTab({
                type: "task",
                id: `${props.userId}allTasks`,
                name: "All Tasks",
                taskType: "",
                userId: props.userId,
                userName: props.userName,
              });
              props.history.push("/active-tabs");
            }
          }}
          style={{
            color: "inherit",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          data-tip="View all tasks."
        >
          Today's Tasks
        </span>
        <span>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <label
              data-tip="View Scheduled tasks."
              className="coloredTasks-menu"
              style={
                taskFilter.includes("scheduled")
                  ? { border: "2px solid rgba(97, 201, 157, 0.5)" }
                  : {}
              }
            >
              <input
                value="scheduled"
                onChange={filterHandler}
                style={{ display: "none" }}
                type="checkbox"
                checked={taskFilter.includes("scheduled")}
                disabled={
                  taskFilter.length === 1 && taskFilter.includes("scheduled")
                }
              />
              Scheduled
            </label>
            <label
              data-tip="View Open tasks."
              className="coloredTasks-menu"
              style={
                taskFilter.includes("open")
                  ? { border: "2px solid rgba(254, 210, 0, 0.5)" }
                  : {}
              }
            >
              <input
                value="open"
                onChange={filterHandler}
                style={{ display: "none" }}
                checked={taskFilter.includes("open")}
                type="checkbox"
                disabled={
                  taskFilter.length === 1 && taskFilter.includes("open")
                }
              />
              Open
            </label>
            <label
              style={
                taskFilter.includes("overdue")
                  ? { border: "2px solid rgba(243, 109, 109, 0.5)" }
                  : {}
              }
              data-tip="View Overdue tasks."
              className="coloredTasks-menu"
            >
              <input
                value="overdue"
                onChange={filterHandler}
                style={{ display: "none" }}
                checked={taskFilter.includes("overdue")}
                type="checkbox"
                disabled={
                  taskFilter.length === 1 && taskFilter.includes("overdue")
                }
              />
              Overdue
            </label>
            <label
              style={
                taskFilter.includes("contact_account_carrier")
                  ? { border: "2px solid rgba(99, 91, 255, 0.5)" }
                  : {}
              }
              data-tip="View Suggested tasks."
              className="coloredTasks-menu"
            >
              <input
                value="contact_account_carrier"
                onChange={filterHandler}
                style={{ display: "none" }}
                checked={taskFilter.includes("contact_account_carrier")}
                type="checkbox"
                disabled={
                  taskFilter.length === 1 &&
                  taskFilter.includes("contact_account_carrier")
                }
              />
              Suggested
            </label>
          </div>
        </span>
      </h3>
      <div style={{ marginTop: "20px" }}>
        {loader ? (
          <>
            <DashboardLoader />
            <DashboardLoader />
            <DashboardLoader />
          </>
        ) : tasks.length !== 0 ? (
          tasks?.map((task, taskIndex) => {
            const {
              account_id,
              account_name,
              category,
              start_time,
              name,
              description,
              end_time,
              id,
              opportunity_id,
              opportunity_name,
              contact_id,
              contact_name,
              date,
              entity_type,
              carrier_id,
              carrier_name,
            } = task;

            let newTabData = {};
            let hottestTabData = {};
            if (opportunity_id !== null) {
              newTabData = {
                type: "opportunity",
                id: opportunity_id,
                name: opportunity_name,
                account_id: account_id,
                showTaskArrow: tasks.length !== taskIndex + 1,
                tasks,
                taskIndex,
              };
            } else if (contact_id !== null) {
              newTabData = {
                type: "contact",
                id: contact_id,
                name: contact_name,
                showTaskArrow: tasks.length !== taskIndex + 1,
                tasks,
                taskIndex,
              };
            } else if (carrier_id !== null) {
              newTabData = {
                type: "carrier",
                id: carrier_id,
                name: carrier_name,
                showTaskArrow: tasks.length !== taskIndex + 1,
                tasks,
                taskIndex,
              };
            } else {
              newTabData = {
                type: "account",
                id: account_id,
                name: account_name,
                showTaskArrow: tasks.length !== taskIndex + 1,
                tasks,
                taskIndex,
              };
            }
            if (task.type === "contact") {
              hottestTabData = {
                type: "contact",
                id: task.id,
                name: task.name,
                showTaskArrow: tasks.length !== taskIndex + 1,
                tasks,
                taskIndex,
              };
            } else if (task.type === "carrier") {
              hottestTabData = {
                type: "carrier",
                id: task.id,
                name: task.name,
                showTaskArrow: tasks.length !== taskIndex + 1,
                tasks,
                taskIndex,
              };
            } else {
              hottestTabData = {
                type: "account",
                id: task.id,
                name: task.name,
                showTaskArrow: tasks.length !== taskIndex + 1,
                tasks,
                taskIndex,
              };
            }

            return entity_type === "contact_account_carrier" ? (
              <div
                key={id + name}
                onClick={(e) => {
                  if (e.metaKey || e.ctrlKey) {
                    props.setTab({ ...hottestTabData, blank: true });
                  } else {
                    props.setActiveTabIndex(props.totalTabs + 1);
                    props.setTab(hottestTabData);
                    props.history.push("/active-tabs");
                  }
                }}
              >
                <HottestFollowupTask
                  key={id}
                  name={name}
                  taskId={id}
                  status={task.status}
                  entity_type={entity_type}
                  fromColor={true}
                  type={task.type}
                />
              </div>
            ) : (
              <div
                key={id + name}
                onClick={(e) => {
                  if (e.metaKey || e.ctrlKey) {
                    props.setTab({ ...newTabData, blank: true });
                  } else {
                    props.setActiveTabIndex(props.totalTabs + 1);
                    props.setTab(newTabData);
                    props.history.push("/active-tabs");
                  }
                }}
              >
                <Task
                  key={id}
                  name={name}
                  time={
                    start_time &&
                    moment(start_time, "HH:mm:ss").format("hh:mm") +
                      " - " +
                      moment(end_time, "HH:mm:ss").format("hh:mm A")
                  }
                  detail={description}
                  icon={`${
                    category === "Opened Email" ? "Email" : category
                  }Green`}
                  taskId={id}
                  accountName={account_name}
                  opportunityName={opportunity_name}
                  contactName={contact_name}
                  date={date}
                  entity_type={entity_type}
                  fromColor={true}
                  carrierName={carrier_name}
                />
              </div>
            );
          })
        ) : (
          <p>No tasks at the moment</p>
        )}
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default withRouter(connect(MSP, MDP)(TasksColored));
