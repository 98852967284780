import {
  SET_STORED_RECORD_SELECTABLE,
  REMOVE_STORED_RECORD_SELECTABLE,
} from "./types";

const INITIAL_STATE = {
  fields: {
    account: "",
    deal: "",
    contact: "",
    carrier: "",
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STORED_RECORD_SELECTABLE:
      if (action.payload.id) {
        return {
          fields: {
            ...state.fields,
            [action.payload.type]: action.payload.id,
          },
        };
      } else {
        return state;
      }
    case REMOVE_STORED_RECORD_SELECTABLE:
      if (action.payload.type === "account") {
        return {
          fields: {
            ...state.fields,
            account: "",
          },
        };
      } else if (action.payload.type === "deal") {
        return {
          fields: {
            ...state.fields,
            deal: "",
          },
        };
      } else if (action.payload.type === "contact") {
        return {
          fields: {
            ...state.fields,
            contact: "",
          },
        };
      } else if (action.payload.type === "carrier") {
        return {
          fields: {
            ...state.fields,
            carrier: "",
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default reducer;
