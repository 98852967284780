import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReactTooltip from "react-tooltip";

const NextRecordNavigation = ({
  tasks,
  setTab,
  setActiveTabIndex,
  history,
  totalTabs,
  recordType,
  recordId,
  taskIndex,
}) => {
  const navigateToNextTask = (e) => {
    const upcomingTask = tasks[taskIndex + 1];
    const nextTask =
      upcomingTask?.entity_type === "contact_account_carrier"
        ? upcomingTask
        : upcomingTask?.[`${recordType}_id`] !== null &&
          upcomingTask?.[`${recordType}_id`]?.toString() ===
            recordId?.toString()
        ? tasks[taskIndex + 2]
        : tasks[taskIndex + 1];
    const nextTaskIndex = tasks?.findIndex(
      (task) => task?.id?.toString() === nextTask?.id?.toString()
    );
    console.log("upcomingTask", taskIndex, nextTask, nextTaskIndex);
    let newTabData = {};
    if (nextTask) {
      const {
        account_id,
        account_name,
        opportunity_id,
        opportunity_name,
        contact_id,
        contact_name,
        entity_type,
        carrier_id,
        carrier_name,
      } = nextTask;
      if (entity_type === "contact_account_carrier") {
        if (nextTask.type === "contact") {
          newTabData = {
            type: "contact",
            id: nextTask.id,
            name: nextTask.name,
            showTaskArrow: tasks.length !== nextTaskIndex + 1,
            tasks,
            edited: true,
          };
        } else if (nextTask.type === "carrier") {
          newTabData = {
            type: "carrier",
            id: nextTask.id,
            name: nextTask.name,
            showTaskArrow: tasks.length !== nextTaskIndex + 1,
            tasks,
            taskIndex: nextTaskIndex,
            edited: true,
          };
        } else {
          newTabData = {
            type: "account",
            id: nextTask.id,
            name: nextTask.name,
            showTaskArrow: tasks.length !== nextTaskIndex + 1,
            tasks,
            taskIndex: nextTaskIndex,
            edited: true,
          };
        }
      } else {
        if (opportunity_id !== null) {
          newTabData = {
            type: "opportunity",
            id: opportunity_id,
            name: opportunity_name,
            account_id: account_id,
            showTaskArrow: tasks.length !== nextTaskIndex + 1,
            tasks,
            taskIndex: nextTaskIndex,
            edited: true,
          };
        } else if (contact_id !== null) {
          newTabData = {
            type: "contact",
            id: contact_id,
            name: contact_name,
            showTaskArrow: tasks.length !== nextTaskIndex + 1,
            tasks,
            taskIndex: nextTaskIndex,
            edited: true,
          };
        } else if (carrier_id !== null) {
          newTabData = {
            type: "carrier",
            id: carrier_id,
            name: carrier_name,
            showTaskArrow: tasks.length !== nextTaskIndex + 1,
            tasks,
            taskIndex: nextTaskIndex,
            edited: true,
          };
        } else {
          newTabData = {
            type: "account",
            id: account_id,
            name: account_name,
            showTaskArrow: tasks.length !== nextTaskIndex + 1,
            tasks,
            taskIndex: nextTaskIndex,
            edited: true,
          };
        }
      }
      if (e.metaKey || e.ctrlKey) {
        setTab({ ...newTabData, blank: true });
      } else {
        setActiveTabIndex(totalTabs + 1);
        setTab(newTabData);
        history.push("/active-tabs");
      }
    }
  };
  return (
    <div
      style={{
        position: "absolute",
        top: "100px",
        right: "5px",
        cursor: "pointer",
        background: "rgba(99, 91, 255, 0.5)",
        borderRadius: "50%",
        padding: "2px 4px",
      }}
      onClick={navigateToNextTask}
      data-tip={`Move to next task`}
    >
      <ReactTooltip multiline={true} />
      <ArrowForwardIcon sx={{ color: "black" }} />
    </div>
  );
};

export default NextRecordNavigation;
