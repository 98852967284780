import React, { Component } from "react";
import ReactModal from "react-modal";
import DragHandlerIcon from "../../images/DragHandler.svg";
import DeleteIcon from "../../images/DeleteIcon.svg";
import LockStages from "../../images/LockStages.svg";
import { v4 as uuidv4 } from "uuid";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import axios from "axios";
import { NotificationManager } from "react-notifications";

function FunnelChartCustomFunction(props) {
  return (
    <div>
      <h3
        style={{
          marginTop: "9px",
          fontSize: "14px",
          lineHeight: "16px",
          textAlign: "left",
          marginLeft: "14px",
          color: "#586874",
          fontStyle: "normal",
          fontWeight: "normal",
        }}
      >
        Stages
      </h3>
      <form
        style={{
          textAlign: "left",
          height: "auto",
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        <SalesFunnelStagesSort
          setStages={props?.setStages}
          stages={props?.stages}
          changeStageHandler={props?.changeStageHandler}
          deleteStageHandler={props?.deleteStageHandler}
          deleteNewStageHandler={props?.deleteNewStageHandler}
          errorMessage={props.errorMessage}
        />
      </form>
      {props?.stages.filter((stage) => stage.destroy !== true).length >= 12 ? (
        <p>You can add a maximum of 12 stages</p>
      ) : (
        <form>
          <input
            name="newStage"
            value={props.newStage}
            placeholder="Add Stage"
            onChange={props.newStageChangeHandler}
            type="text"
            style={{
              border: "none",
              borderBottom: "1px solid #C5C5D3",
              width: "135px",
              marginBottom: "15px",
              outline: "none",
            }}
          />
          <button
            type="button"
            disabled={props.newStage.length < 1}
            style={{
              background: "#61C99D",
              border: "none",
              color: "white",
              padding: "4px 10px",
              borderRadius: "6px",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={() =>
              props.addStageHandler(
                null,
                true,
                props?.stagesLength + 1,
                false,
                props?.newStage
              )
            }
          >
            Add
          </button>
        </form>
      )}
    </div>
  );
}

const DragHandle = sortableHandle(() => (
  <span
    style={{ cursor: "grab", padding: "2px 4px 0 4px", marginLeft: "14px" }}
  >
    <img src={DragHandlerIcon} alt="::" />
  </span>
));

const SortableItem = sortableElement(
  ({
    value,
    changeStageHandler,
    deleteStageHandler,
    handleOpenModal,
    stages,
    deleteNewStageHandler,
    errorMessage,
  }) => {
    return (
      <li style={{ display: "block" }}>
        <DragHandle />
        <input
          style={{
            border: "none",
            borderBottom: "1px solid #C5C5D3",
            width: "135px",
            marginBottom: "15px",
            outline: "none",
            padding: "4px 8px",
            display: "inline-block",
          }}
          name={value.id}
          type="text"
          value={value.name}
          onChange={(e) => changeStageHandler(null, e)}
          disabled={!value.editable}
        />
        {!value.editable || value.type === "new" ? (
          <button
            style={{
              marginRight: "30px",
              border: "none",
              background: "transparent",
              padding: "0",
              outline: "none",
              cursor: "not-allowed",
            }}
            // onClick={() => deleteStageHandler(value.id)}
            type="button"
            disabled={!value.editable}
          >
            <img
              src={LockStages}
              alt="Lock"
              width="14"
              style={{ padding: "4px", transform: "translateY(6px)" }}
            />
          </button>
        ) : stages.length <= 5 ? null : (
          <button
            style={{
              marginRight: "30px",
              border: "none",
              background: "transparent",
              padding: "0",
              outline: "none",
              cursor: "pointer",
            }}
            // onClick={() => deleteStageHandler(value.id)}
            onClick={() => deleteNewStageHandler(value.id)}
            type="button"
            disabled={!value.editable}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              width="14"
              style={{ padding: "4px", transform: "translateY(6px)" }}
            />
          </button>
        )}
        {errorMessage === true && (value.name === "" || value.name === null) && (
          <span
            style={{
              fontSize: "12px",
              color: "red",
            }}
          >
            Please enter stage
          </span>
        )}
      </li>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

class SalesFunnelStagesSort extends Component {
  state = {
    items: [],
    currentStageId: "",
    currentStageName: "",
    showDeleteModal: false,
  };
  setCurrentStage = (stageId, stageName) => {
    this.setState({
      currentStageId: stageId,
      currentStageName: stageName,
    });
  };
  handleOpenModal = (stageId, stageName) => {
    this.setState({
      showDeleteModal: true,
      currentStageId: stageId,
      currentStageName: stageName,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    // this.setState(({ items }) => ({
    //   items: arrayMove(items, oldIndex, newIndex),
    // }));
    if (
      newIndex === 0 ||
      this.props?.stages[newIndex].type === "won" ||
      this.props?.stages[newIndex].type === "lost" ||
      this.props?.stages[newIndex].name === "Long-term"
    ) {
      return false;
    }

    this.props.setStages(arrayMove(this.props?.stages, oldIndex, newIndex));
  };

  render() {
    return (
      <div>
        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
          {this.props?.stages
            ?.filter((stage) => !stage.destroy)
            .map((stage, index) => (
              <SortableItem
                key={`stage-${index}`}
                index={index}
                value={stage}
                changeStageHandler={this.props?.changeStageHandler}
                deleteStageHandler={this.props?.deleteStageHandler}
                disabled={!stage.editable}
                stages={this.props?.stages}
                setCurrentStage={this.setCurrentStage}
                handleOpenModal={this.handleOpenModal}
                deleteNewStageHandler={this.props?.deleteNewStageHandler}
                errorMessage={this.props.errorMessage}
              />
            ))}
        </SortableContainer>
      </div>
    );
  }
}

class FunnelCreateModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    funnelName: "",
    isDefault: false,
    errorMessage: false,
    isLoading: false,
    stages: [
      {
        id: 1,
        name: "New",
        position: 1,
        type: "new",
        editable: true,
        destroy: false,
      },
      {
        id: 2,
        name: "Discovery",
        position: 2,
        type: null,
        editable: true,
        destroy: false,
      },
      {
        id: 3,
        name: "Proposal",
        position: 3,
        type: null,
        editable: true,
        destroy: false,
      },
      {
        id: 4,
        name: "Negotiation",
        position: 4,
        type: null,
        editable: true,
        destroy: false,
      },
      {
        id: 5,
        name: "Close",
        position: 5,
        type: null,
        editable: true,
        destroy: false,
      },
      {
        id: 6,
        name: "Long-term",
        position: 6,
        type: null,
        editable: false,
        destroy: false,
      },
      {
        id: 7,
        name: "Won",
        position: 7,
        type: "won",
        editable: false,
        destroy: false,
      },
      {
        id: 8,
        name: "Lost",
        position: 8,
        type: "lost",
        editable: false,
        destroy: false,
      },
    ],
    newStage: "",
  };
  funnelNameChangeHandler = (e) => {
    const { value } = e.target;
    this.setState({
      funnelName: value,
    });
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  deleteNewStageHandler = (id) => {
    this.setState((prevState) => ({
      stages: prevState.stages.filter((stage) => stage.id !== id),
    }));
  };
  changeStageHandler = (type, e) => {
    const { name, value } = e.target;
    const id = parseInt(name);

    this.setState((prevState) => ({
      stages: prevState.stages.map((stage) => {
        if (stage.id === id) stage.name = value;
        return stage;
      }),
    }));
  };
  newStageChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  addStageHandler = (type, editable, position, destroy, name) => {
    let newStages = this.state.stages;
    newStages.splice(this.state.stages.length - 3, 0, {
      id: uuidv4(),
      _new: true,
      type,
      editable,
      position,
      destroy: false,
      name,
    });
    this.setState((prevState) => ({
      stages: newStages,
      newStage: "",
    }));
  };
  setStages = (stagesData) => {
    this.setState({
      stages: stagesData,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.funnelName === null || this.state.funnelName === "") {
      this.setState({ errorMessage: true });
      return;
    }
    const modifiedStages = this.state?.stages
      .reduce((acc, val, idx) => {
        return [acc, { ...val, position: idx + 1 }];
      }, [])
      .flat(Infinity);
    const submitStage = modifiedStages.map((stage) => {
      if (stage._new === true) {
        const { id, _new, ...finalStage } = stage;
        return finalStage;
      }
      return stage;
    });
    const checkError = submitStage.every((stage) => {
      if (stage.name === "" || stage.name === null) {
        this.setState({ isLoading: false, errorMessage: true });
        return false;
      }
      return stage;
    });
    this.setState({ isLoading: true });
    if (checkError) {
      axios({
        method: "POST",
        url: `/funnels`,
        data: {
          name: this.state.funnelName,
          is_default: this.state.isDefault,
          stages: submitStage.map((stage) => {
            const { name, editable, type, position } = stage;
            return {
              name,
              editable,
              type,
              position,
            };
          }),
        },
      })
        .then((res) => {
          this.props.handleCloseModal();
          this.props.getAllFunnels();
          NotificationManager.success("Funnel created successfully.");
          this.setState({
            funnelName: "",
            isDefault: false,
            errorMessage: false,
            isLoading: false,
            stages: [
              {
                id: 1,
                name: "New",
                position: 1,
                type: "new",
                editable: false,
                destroy: false,
              },
              {
                id: 2,
                name: "Discovery",
                position: 2,
                type: null,
                editable: true,
                destroy: false,
              },
              {
                id: 3,
                name: "Proposal",
                position: 3,
                type: null,
                editable: true,
                destroy: false,
              },
              {
                id: 4,
                name: "Negotiation",
                position: 4,
                type: null,
                editable: true,
                destroy: false,
              },
              {
                id: 5,
                name: "Close",
                position: 5,
                type: null,
                editable: true,
                destroy: false,
              },
              {
                id: 6,
                name: "Long-term",
                position: 6,
                type: null,
                editable: false,
                destroy: false,
              },
              {
                id: 7,
                name: "Won",
                position: 7,
                type: "won",
                editable: false,
                destroy: false,
              },
              {
                id: 8,
                name: "Lost",
                position: 8,
                type: "lost",
                editable: false,
                destroy: false,
              },
            ],
            newStage: "",
          });
        })
        .catch((err) => {
          NotificationManager.error("Error creating funnel.");
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "88vh", top: "3%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Add New Funnel</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label>Funnel Name</label>
              <input
                value={this.state.funnelName}
                onChange={this.funnelNameChangeHandler}
                style={
                  this.state.errorMessage === true &&
                  (this.state.funnelName === null ||
                    this.state.funnelName === "")
                    ? {
                        border: "2px solid #F36363",
                      }
                    : {}
                }
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.funnelName === null || this.state.funnelName === "")
                  ? "Funnel Name is required"
                  : null}
              </div>
              <div>
                <input
                  type="checkbox"
                  style={{
                    width: "20px",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  value={this.state.isDefault}
                  checked={this.state.isDefault}
                  id="FunnelCreateModalIsDefault"
                  onChange={() =>
                    this.setState({
                      isDefault: !this.state.isDefault,
                    })
                  }
                />
                <label
                  style={{ display: "inline-block", cursor: "pointer" }}
                  htmlFor="FunnelCreateModalIsDefault"
                >
                  Primary Funnel
                </label>
              </div>
              <FunnelChartCustomFunction
                setStages={this.setStages}
                stages={this.state?.stages}
                stagesLength={this.state?.stages?.length}
                newStage={this.state.newStage}
                newStageChangeHandler={this.newStageChangeHandler}
                addStageHandler={this.addStageHandler}
                changeStageHandler={this.changeStageHandler}
                deleteNewStageHandler={this.deleteNewStageHandler}
                errorMessage={this.state.errorMessage}
              />
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  onClick={this.submitHandler}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default FunnelCreateModal;
