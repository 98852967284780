import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/CustomerModal.scss";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AccountIcon from "../../images/AccountIcon.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { countriesList } from "../../constants/countriesList";
import { colourStyles } from "../../constants/selectStyles";
import { usStatesList } from "../../constants/usStatesList";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../../utils/Helper/reusableFunctions";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  fetchAllAllFormAccountFields,
  authUserSet,
} from "../../redux";

class CreateAccountModal extends Component {
  state = {
    fieldsData: [],
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    phoneValidationError: false,
    emailValidationError: false,
    menuOpen: "bottom",
    customFieldRevenueTypeCheck: [],
    defaultFields: {
      account_owner: null,
      sharedAccountOwners: [],
      name: null,
      phone: null,
      phone_ext: null,
      email: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      country: "United States",
      preferred_communication: [],
      source: null,
      website: null,
      linkedin: null,
      description: null,
    },
    allFields: [],
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      name: null,
      state: null,
      source: null,
      zip: null,
      city: null,
      address: null,
      email: null,
      phone: null,
      phone_ext: null,
      website: null,
      linkedin: null,
      description: null,
      preferred_communication: [],
      frontendPreferredCommunicationArray: [],
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      isLoading: false,
      phoneValidationError: false,
      emailValidationError: false,
      customFieldRevenueTypeCheck: [],
      defaultFields: {
        name: null,
        phone: null,
        phone_ext: null,
        email: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        country: "United States",
        preferred_communication: [],
        source: null,
        website: null,
        linkedin: null,
        description: null,
        sharedAccountOwners: [],
      },
    });
  };
  fetchAccountData = () => {
    this.setState({
      fieldsData: this.props.allAccountCustomFields,
    });
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("mousemove", this.mouseCoordinates);
    this.props.fetchAllAllFormAccountFields();
    this.fetchAccountData();
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        account_owner: this.props.authUser.id,
        country: this.props.companyOnboarding?.country
          ? this.props.companyOnboarding?.country
          : "United States",
      },
      allFields: this.props.allAccountFields,
    }));
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.fetchAccountData();
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          account_owner: this.props.authUser.id,
          country: this.props.companyOnboarding?.country
            ? this.props.companyOnboarding?.country
            : "United States",
        },
        allFields: this.props.allAccountFields,
      }));
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  changeHandler = (name, e) => {
    const { value } = e.target;
    if (name === "phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      const formattedValue = formatter.input(value); // Format the input value
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: formattedValue ? formattedValue : null,
        },
      }));
    } else if (e.target.name === "account_owner") {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: e.target.value === "" ? null : e.target.value,
          sharedAccountOwners: [],
        },
      }));
    } else {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: e.target.value === "" ? null : e.target.value,
        },
      }));
    }
  };
  accountOwnerMultiDropdownChangeHandler = (selectedOptions) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        sharedAccountOwners: selectedOptions,
      },
    }));
  };
  changeHandlerSelect = (name, value) => {
    this.setState((prevState) => ({
      defaultFields: { ...prevState.defaultFields, [name]: value.value },
    }));
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    } else {
      return;
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  defaultFieldsMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [fieldName]: selectedOptions,
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.defaultFields.phone !== null &&
      this.state.defaultFields.phone !== "" &&
      !phoneValidator.test(this.state.defaultFields.phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.defaultFields.phone !== null &&
      this.state.defaultFields.phone !== "" &&
      phoneValidator.test(this.state.defaultFields.phone)
    ) {
      this.setState({
        phoneValidationError: false,
      });
    }
    if (
      this.state.defaultFields.email !== null &&
      this.state.defaultFields.email !== "" &&
      !emailValidator.test(this.state.defaultFields.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (
      this.state.defaultFields.email !== null &&
      this.state.defaultFields.email !== "" &&
      emailValidator.test(this.state.defaultFields.email)
    ) {
      this.setState({
        emailValidationError: false,
      });
    }
    if (
      this.state.defaultFields.name === null ||
      this.state.defaultFields.name === ""
    ) {
      this.setState({ errorMessage: true });
      return;
    }
    const requiredFields = this.state.allFields
      ?.filter((field) => field.required)
      ?.map((field) => field.name);
    const isNotError = requiredFields?.every((field) =>
      Array.isArray(this.state.defaultFields[field])
        ? this.state.defaultFields[field].length !== 0
        : this.state.defaultFields[field] !== null &&
          this.state.defaultFields[field] !== ""
    );
    const isNotErrorCustom = requiredFields?.every((field) =>
      Array.isArray(this.state.customFields[field])
        ? this.state.customFields[field].length !== 0
        : this.state.customFields[field] !== null &&
          this.state.customFields[field] !== ""
    );
    console.log("isNotError", isNotError);
    if (!isNotError || !isNotErrorCustom) {
      this.setState({ errorMessage: true });
      return false;
    }
    let defaultFields = this.state.defaultFields;
    for (let i in defaultFields) {
      if (defaultFields[i] === "") {
        defaultFields[i] = null;
      }
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/accounts/create`,
      data: {
        owners: !defaultFields.account_owner
          ? []
          : defaultFields.sharedAccountOwners
              ?.map((user) => ({
                is_primary: false,
                owner_id: user.value,
              }))
              .concat([
                {
                  is_primary: true,
                  owner_id: defaultFields.account_owner,
                },
              ]),
        name: defaultFields.name,
        source: defaultFields.source,
        state: defaultFields.state,
        zip: defaultFields.zip,
        city: defaultFields.city,
        address: defaultFields.address,
        country:
          defaultFields.country === null
            ? "United States"
            : defaultFields.country,
        email: defaultFields.email,
        formatted_phone: defaultFields.phone,
        phone_ext: defaultFields.phone_ext,
        website:
          defaultFields.website === null
            ? null
            : defaultFields.website.startsWith("https://") ||
              defaultFields.website.startsWith("http://")
            ? defaultFields.website
            : "https://" + defaultFields.website,
        linkedin:
          defaultFields.linkedin === null
            ? null
            : defaultFields.linkedin.startsWith("https://") ||
              defaultFields.linkedin.startsWith("http://")
            ? defaultFields.linkedin
            : "https://" + defaultFields.linkedin,
        description:
          defaultFields.description === "" ? null : defaultFields.description,
        preferred_communication: defaultFields.preferred_communication?.map(
          (pref) => pref.value
        ),
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "account",
          id: response.data.account.account_id,
          name: response.data.account.name,
        };

        this.props.setActiveTabIndex(this.props.totalTabs + 1);
        this.props.setTab(newTabData);
        this.props.history.push("/active-tabs");
        this.handleCloseModal();
      })
      .then((response) => {
        NotificationManager.success("Account created successfully!");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating account.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      })
      .finally(() => {
        this.fetchAccountData();
      });
  };
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired,
    fieldParent
  ) => {
    let inputType;
    if (fieldName === "preferred_communication") {
      let multiSelectDropdownValue = this.state.defaultFields[fieldName];
      const preferredChoices = [
        { value: "call", label: "Call" },
        { value: "email", label: "Email" },
        { value: "text", label: "Text" },
      ];
      inputType = (
        <Select
          value={multiSelectDropdownValue}
          closeMenuOnSelect={false}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={preferredChoices.map((choice) => {
            const { value, label } = choice;
            return { value, label };
          })}
          placeholder="-Select-"
          menuPlacement={this.state.menuOpen}
          className="basic-multi-select"
          classNamePrefix="select"
          required={fieldRequired}
          onChange={(selectedOption) =>
            this.defaultFieldsMultiDropdownChangeHandler(
              fieldName,
              selectedOption
            )
          }
        />
      );
    } else if (fieldType === "text") {
      inputType = (
        <input
          type="text"
          name={fieldName}
          value={
            fieldParent === "default_fields"
              ? this.state.defaultFields[fieldName]
              : this.state.customFields[fieldName]
          }
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          required={fieldRequired}
        />
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <input
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          required={fieldRequired}
        />
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <input
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={(e) => this.customFieldRevenueChangeHandler(e)}
          required={fieldRequired}
        />
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <textarea
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          required={fieldRequired}
        ></textarea>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <input
          type="number"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          required={fieldRequired}
        />
      );
    } else if (fieldType === "dropdown") {
      inputType =
        fieldName === "state" ? (
          this.state.defaultFields.country !== "United States" ? (
            <input
              type="text"
              name="state"
              value={this.state.defaultFields.state}
              onChange={(e) => this.changeHandler("state", e)}
              required={fieldRequired}
            />
          ) : (
            <Select
              className="basic-single"
              classNamePrefix="select"
              styles={colourStyles}
              value={{
                value: this.state.defaultFields.state,
                label: this.state.defaultFields.state,
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                this.changeHandlerSelect("state", selected)
              }
              isClearable={false}
              isSearchable={true}
              menuPlacement={this.state.menuOpen}
              options={usStatesList.map((state) => ({
                value: state,
                label: state,
              }))}
            />
          )
        ) : fieldName === "country" ? (
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            value={{
              value: this.state.defaultFields.country,
              label: this.state.defaultFields.country,
            }}
            placeholder="-Select-"
            onChange={(selected) =>
              this.changeHandlerSelect("country", selected)
            }
            isClearable={false}
            isSearchable={true}
            menuPlacement={this.state.menuOpen}
            options={countriesList.map((country) => ({
              value: country,
              label: country,
            }))}
          />
        ) : fieldName === "account_owner_id" ? (
          <>
            <select
              value={this.state.defaultFields.account_owner}
              onChange={(e) => this.changeHandler("account_owner", e)}
              name="account_owner"
              style={{ width: "99%" }}
            >
              {(this.props.authUser.permissions.includes(
                "crm:unassigned:leads:viewer"
              ) ||
                this.props.authUser.role === "SUPERADMIN") && (
                <option value="">Unassigned Leads</option>
              )}
              {this.props.allUsers?.map((user) => {
                const { id, name } = user;
                return (
                  <option key={id} value={id}>
                    {name}
                  </option>
                );
              })}
            </select>
            {this.state.defaultFields.account_owner &&
              this.props.companyOnboarding
                ?.is_multiple_account_owner_enabled && (
                <>
                  <label>Shared Account Owners</label>
                  <Select
                    value={this.state.defaultFields.sharedAccountOwners}
                    closeMenuOnSelect={false}
                    menuPlacement={this.state.menuOpen}
                    isMulti
                    name={"accountOwner"}
                    styles={colourStyles}
                    options={this.props.allUsers
                      ?.filter(
                        (user) =>
                          user.id?.toString() !==
                          this.state.defaultFields.account_owner?.toString()
                      )
                      ?.map((user) => ({
                        value: user.id,
                        label: user.name,
                      }))}
                    placeholder="-Select-"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    required={true}
                    onChange={(selectedOption) =>
                      this.accountOwnerMultiDropdownChangeHandler(
                        selectedOption
                      )
                    }
                  />
                </>
              )}
          </>
        ) : fieldName === "source" ? (
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            value={{
              value: this.state.defaultFields.source,
              label: this.state.defaultFields.source,
            }}
            placeholder="-Select-"
            onChange={(selected) =>
              this.changeHandlerSelect("source", selected)
            }
            isClearable={false}
            isSearchable={true}
            menuPlacement={this.state.menuOpen}
            options={this.props.allAccountSources?.map((source) => ({
              value: source.name,
              label: source.name,
            }))}
          />
        ) : (
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            required={fieldRequired}
            value={{
              value: this.state.customFields[fieldName],
              label: this.state.customFields[fieldName],
            }}
            placeholder="-Select-"
            onChange={(selected) =>
              fieldParent === "default_fields"
                ? this.changeHandlerSelect(fieldName, selected.value)
                : this.customFieldSelectChangeHandler(fieldName, selected.value)
            }
            isClearable={false}
            menuPlacement={this.state.menuOpen}
            isSearchable={true}
            name={fieldName}
            options={fieldChoices.map((choice) => {
              const { value } = choice;
              return { value, label: value };
            })}
          />
        );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px" }}>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={(e) =>
                    fieldParent === "default_fields"
                      ? this.changeHandler(fieldName, e)
                      : this.customFieldChangeHandler(e)
                  }
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          name={fieldName}
          selected={Date.parse(this.state.customFields[fieldName])}
          value={Date.parse(this.state.customFields[fieldName])}
          onChange={(date) =>
            this.customFieldDateChangeHandler(fieldName, date)
          }
          dateFormat={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
          required={fieldRequired}
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      let options = [];
      if (
        fieldName === "cf_equipment_type" ||
        fieldName === "cf_equipment_types"
      ) {
        options = this.props.allEquipmentTypes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (fieldName === "cf_special_requirements") {
        options = this.props.allSpecialRequirements?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (fieldName === "cf_modes") {
        options = this.props.allModes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else {
        options = fieldChoices.map((choice) => {
          const { value } = choice;
          return { value, label: value };
        });
      }
      inputType = (
        <Select
          value={multiSelectDropdownValue}
          closeMenuOnSelect={false}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={options}
          placeholder="-Select-"
          className="basic-multi-select"
          classNamePrefix="select"
          menuPlacement={this.state.menuOpen}
          required={fieldRequired}
          onChange={(selectedOption) =>
            this.customFieldMultiDropdownChangeHandler(
              fieldName,
              selectedOption
            )
          }
        />
      );
    }
    return (
      <>
        {fieldType === "heading" ? (
          <h3 className="modal__heading-sub">{fieldLabel}</h3>
        ) : (
          <div>
            <label>
              {fieldLabel}{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            {inputType}
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              fieldRequired &&
              (this.state.defaultFields[fieldName] === null ||
                this.state.defaultFields[fieldName] === "" ||
                this.state.defaultFields[fieldName]?.length === 0 ||
                this.state.customFields[fieldName] === null ||
                this.state.customFields[fieldName] === "" ||
                this.state.customFields[fieldName]?.length === 0)
                ? `${fieldLabel} is required`
                : null}
            </div>
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {fieldName === "email" &&
              this.state.emailValidationError === true &&
              this.state.defaultFields.email !== "" &&
              this.state.defaultFields.email !== null
                ? `Please enter a valid email address`
                : null}
            </div>
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {fieldName === "phone" &&
              this.state.phoneValidationError === true &&
              this.state.defaultFields.phone !== "" &&
              this.state.defaultFields.phone !== null
                ? `Please enter a valid phone number`
                : null}
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <ReactModal
        isOpen={this.props.showOpportunityModal}
        contentLabel="onRequestClose Example"
        onRequestClose={this.handleCloseModal}
        ariaHideApp={false}
        style={modalStyle}
      >
        <div className="modal">
          <div className="modal__content">
            <div className="modal__header">
              <span>
                <div className="customerInfo-icon">
                  <img
                    src={AccountIcon}
                    alt=""
                    width="17px"
                    style={{ transform: "translateY(9px)" }}
                  />
                </div>
                <NotificationContainer />
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Create an Account</h2>
                <p className="modal__heading-desc">
                  Add some information about the account
                </p>
              </div>
              <button
                onClick={this.handleCloseModal}
                className="modal__close"
                style={{
                  border: "none",
                  background: "transparent",
                  height: "12px",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <form
              className="modal__form"
              style={{
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
              }}
            >
              {this.state.allFields?.map((field) => {
                const { id, label, type, choices, name, required, parent_id } =
                  field;
                return (
                  <div key={id}>
                    {this.showInput(
                      name,
                      label,
                      type,
                      choices,
                      required,
                      parent_id
                    )}
                  </div>
                );
              })}
            </form>
            <div className="modal__buttons">
              <button
                onClick={this.handleCloseModal}
                type="reset"
                className="modal__cancel-button"
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={this.state.isLoading}
                onClick={this.submitHandler}
              >
                {this.state.isLoading ? "Saving" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    allAccountFields: state.allAccountFields.fields,
    authUser: state.login.authUser,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountCustomFields: state.allAccountCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    fetchAllAllFormAccountFields: (allAccountFields) =>
      dispatch(fetchAllAllFormAccountFields(allAccountFields)),
  };
};

export default connect(MSP, MDP)(withRouter(CreateAccountModal));
