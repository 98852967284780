import React from "react";
import "../../sass/DropdownHeader.scss";
import ReactTooltip from "react-tooltip";
import { contactsFilters } from "../../constants/filters/contactsFilters";
import { accountsFilters } from "../../constants/filters/accountsFilters";
import ArrowIcon from "../../images/ArrowDown.svg";

class DropdownReportFilters extends React.Component {
  state = {
    displayMenu: false,
    searchValue: "",
    allFiltersArray: [],
    accountCollapsed: {
      default: false,
    },
    contactCollapsed: {
      default: false,
    },
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const accountArray = accountsFilters.concat(
      this.props.accountCustomFieldsMerged
    );
    const contactArray = contactsFilters.concat(
      this.props.contactCustomFieldsMerged
    );
    const allFiltersArray = accountArray.concat(contactArray);
    const obj = {};
    const obj1 = {};
    const contactCollapsed = this.props.contactCustomFields
      ?.map((field) => field.label)
      ?.forEach((element) => {
        obj[element] = false;
      });
    const accountCollapsed = this.props.accountCustomFields
      ?.map((field) => field.label)
      ?.forEach((element) => {
        obj1[element] = false;
      });
    this.setState({
      allFiltersArray,
      contactCollapsed: { ...contactCollapsed, default: false },
      accountCollapsed: { ...accountCollapsed, default: false },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.displayMenu !== this.state.displayMenu &&
      this.state.displayMenu
    ) {
      const accountArray = accountsFilters.concat(
        this.props.accountCustomFieldsMerged
      );
      const contactArray = contactsFilters.concat(
        this.props.contactCustomFieldsMerged
      );
      const allFiltersArray = accountArray.concat(contactArray);
      const obj = {};
      const obj1 = {};
      const contactCollapsed = this.props.contactCustomFields
        ?.map((field) => field.label)
        ?.forEach((element) => {
          obj[element] = false;
        });
      const accountCollapsed = this.props.accountCustomFields
        ?.map((field) => field.label)
        ?.forEach((element) => {
          obj1[element] = false;
        });
      this.setState({
        allFiltersArray,
        contactCollapsed: { ...contactCollapsed, default: false },
        accountCollapsed: { ...accountCollapsed, default: false },
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false, searchValue: "" });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };
  searchFilters = (e) => {
    const accountArray = accountsFilters.concat(
      this.props.accountCustomFieldsMerged
    );
    const contactArray = contactsFilters.concat(
      this.props.contactCustomFieldsMerged
    );
    const allFiltersArray = accountArray.concat(contactArray);
    const { value } = e.target;
    this.setState({
      searchValue: value,
      allFiltersArray: value
        ? allFiltersArray?.filter((list) =>
            list.label.toLowerCase().includes(value.toLowerCase())
          )
        : allFiltersArray,
    });
  };

  render() {
    const accountArray = accountsFilters.concat(
      this.props.accountCustomFieldsMerged
    );
    const contactArray = contactsFilters.concat(
      this.props.contactCustomFieldsMerged
    );
    const accountArrayFiltered = this.state.allFiltersArray
      ?.filter((arr) => arr.type === "account")
      ?.filter((acc) => {
        const accountArr = accountArray?.map((account) =>
          account.value.toString()
        );
        return accountArr?.includes(acc.value.toString());
      });
    const contactArrayFiltered = this.state.allFiltersArray
      ?.filter((arr) => arr.type === "contact")
      ?.filter((con) => {
        const contactArr = contactArray?.map((contact) =>
          contact.value.toString()
        );
        return contactArr?.includes(con.value.toString());
      });
    const contactArrayFilteredIds = contactArrayFiltered.map((arr) =>
      arr.value.toString()
    );
    const accountArrayFilteredIds = accountArrayFiltered.map((arr) =>
      arr.value.toString()
    );
    const contactLabel = contactsFilters.find(
      (field) => field.value.toString() === this.props.filterValue
    );
    const accountLabel = accountsFilters.find(
      (field) => field.value.toString() === this.props.filterValue
    );
    const contactLabelCustom = this.props.contactCustomFieldsMerged.find(
      (field) => field.value.toString() === this.props.filterValue
    );
    const accountLabelCustom = this.props.accountCustomFieldsMerged.find(
      (field) => field.value.toString() === this.props.filterValue
    );
    const displayLabel =
      contactLabel?.label ||
      accountLabel?.label ||
      contactLabelCustom?.label ||
      accountLabelCustom?.label ||
      this.props.filterValue;
    console.log("dropdownReport", this.props, this.state);
    return (
      <div
        ref={this.wrapperRef}
        className="dropdownTemplate"
        style={{
          background: "transparent",
          width: "15px",
        }}
      >
        <ReactTooltip />
        <div
          className="button"
          onClick={this.props.filterValue !== "batch" && this.showDropdownMenu}
          style={{ fontSize: "14px", position: "relative", width: "192px" }}
          data-tip="Add Filter"
        >
          <input value={displayLabel ? displayLabel : "-Select-"} readOnly />
          <img
            src={ArrowIcon}
            alt="V"
            width="10px"
            style={{ position: "absolute", right: "15px", top: "11px" }}
          />
        </div>

        {this.state.displayMenu ? (
          <ul
            style={{
              width: "192px",
              left: "-8px",
              top: "38px",
              background: "white",
            }}
          >
            <li
              style={{
                cursor: "default",
                width: "176px",
                background: "white",
                boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div style={{ position: "relative" }}>
                <input
                  value={this.state.searchValue}
                  onChange={this.searchFilters}
                  placeholder="Search Filters"
                  style={{ width: "94%", fontSize: "14px" }}
                />
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ position: "absolute", right: "20px", top: "11px" }}
                ></i>
              </div>
            </li>
            <div
              style={{
                height: "auto",
                maxHeight: "440px",
                overflowY: "auto",
                overflowX: "hidden",
                background: "white",
                boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
              }}
            >
              {contactsFilters?.filter((template) =>
                contactArrayFilteredIds.includes(template.value.toString())
              ).length > 0 ? (
                <li
                  style={{
                    background: "#ebfaff",
                    cursor: "pointer",
                    width: "176px",
                    color: "#1878F2",
                    fontSize: "14px",
                  }}
                  onClick={() =>
                    this.setState({
                      contactCollapsed: {
                        ...this.state.contactCollapsed,
                        ["default"]: !this.state.contactCollapsed["default"],
                      },
                    })
                  }
                >
                  Contacts Default Fields
                  <span style={{ float: "right" }}>
                    <i
                      className={`fa fa-caret-${
                        this.state.contactCollapsed["default"] ? "up" : "down"
                      }`}
                      aria-hidden="true"
                    ></i>
                  </span>
                </li>
              ) : null}
              {this.state.contactCollapsed["default"] &&
                contactsFilters
                  ?.filter((template) =>
                    contactArrayFilteredIds.includes(template.value.toString())
                  )
                  ?.map((template) => (
                    <li
                      key={template.value + template.type}
                      style={{ width: "176px", fontSize: "14px" }}
                    >
                      <div
                        style={{ maxWidth: "161px", width: "auto" }}
                        onClick={(e) => {
                          this.props.filterChangeHandler(
                            this.props.filterIndex,
                            template.label,
                            template.value.toString(),
                            "contact"
                          );
                          this.showDropdownMenu(e);
                        }}
                      >
                        {template.label}
                      </div>
                    </li>
                  ))}
              {this.props.contactCustomFields?.map((field) => {
                const fieldIds = field.fields?.map((f) => f.id.toString());
                const showGroup = contactArrayFilteredIds.some((r) =>
                  fieldIds.includes(r)
                );
                return showGroup ? (
                  <div
                    key={field.id}
                    style={{ borderBottom: "1px solid #e5e5e5" }}
                  >
                    <li
                      style={{
                        background: "#ebfaff",
                        cursor: "pointer",
                        width: "176px",
                        color: "#1878F2",
                        fontSize: "14px",
                      }}
                      onClick={() =>
                        this.setState({
                          contactCollapsed: {
                            ...this.state.contactCollapsed,
                            [field.label]:
                              !this.state.contactCollapsed[field.label],
                          },
                        })
                      }
                    >
                      {field.label}{" "}
                      <span style={{ float: "right" }}>
                        <i
                          className={`fa fa-caret-${
                            this.state.contactCollapsed[field.label]
                              ? "up"
                              : "down"
                          }`}
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                    {this.state.contactCollapsed[field.label] &&
                      field.fields
                        ?.filter((template) =>
                          contactArrayFilteredIds.includes(
                            template.id.toString()
                          )
                        )
                        ?.map((template) => (
                          <li
                            key={template.id + template.name}
                            style={{ width: "176px", fontSize: "14px" }}
                          >
                            <div
                              style={{ maxWidth: "161px", width: "auto" }}
                              onClick={(e) => {
                                this.props.filterChangeHandler(
                                  this.props.filterIndex,
                                  template.label,
                                  template.id.toString(),
                                  "contact"
                                );
                                this.showDropdownMenu(e);
                              }}
                            >
                              {template.label}
                            </div>
                          </li>
                        ))}
                  </div>
                ) : null;
              })}
              {accountsFilters?.filter((template) =>
                accountArrayFilteredIds.includes(template.value.toString())
              ).length > 0 ? (
                <li
                  style={{
                    background: "#fff1d6",
                    cursor: "pointer",
                    width: "176px",
                    color: "#EDAA4F",
                    fontSize: "14px",
                  }}
                  onClick={() =>
                    this.setState({
                      accountCollapsed: {
                        ...this.state.accountCollapsed,
                        ["default"]: !this.state.accountCollapsed["default"],
                      },
                    })
                  }
                >
                  Accounts Default Fields
                  <span style={{ float: "right" }}>
                    <i
                      className={`fa fa-caret-${
                        this.state.accountCollapsed["default"] ? "up" : "down"
                      }`}
                      aria-hidden="true"
                    ></i>
                  </span>
                </li>
              ) : null}
              {this.state.accountCollapsed["default"] &&
                accountsFilters
                  ?.filter((template) =>
                    accountArrayFilteredIds.includes(template.value.toString())
                  )
                  ?.map((template) => (
                    <li
                      key={template.value + template.type}
                      style={{ width: "176px", fontSize: "14px" }}
                    >
                      <div
                        style={{ maxWidth: "161px", width: "auto" }}
                        onClick={(e) => {
                          this.props.filterChangeHandler(
                            this.props.filterIndex,
                            template.label,
                            template.value.toString(),
                            "account"
                          );
                          this.showDropdownMenu(e);
                        }}
                      >
                        {template.label}
                      </div>
                    </li>
                  ))}
              {this.props.accountCustomFields?.map((field) => {
                const fieldIds = field.fields?.map((f) => f.id.toString());
                const showGroup = accountArrayFilteredIds.some((r) =>
                  fieldIds.includes(r)
                );
                return showGroup ? (
                  <div
                    key={field.id}
                    style={{ borderBottom: "1px solid #e5e5e5" }}
                  >
                    <li
                      style={{
                        background: "#fff1d6",
                        cursor: "pointer",
                        width: "176px",
                        color: "#EDAA4F",
                        fontSize: "14px",
                      }}
                      onClick={() =>
                        this.setState({
                          accountCollapsed: {
                            ...this.state.accountCollapsed,
                            [field.label]:
                              !this.state.accountCollapsed[field.label],
                          },
                        })
                      }
                    >
                      {field.label}{" "}
                      <span style={{ float: "right" }}>
                        <i
                          className={`fa fa-caret-${
                            this.state.accountCollapsed[field.label]
                              ? "up"
                              : "down"
                          }`}
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                    {this.state.accountCollapsed[field.label] &&
                      field.fields
                        ?.filter((template) =>
                          accountArrayFilteredIds.includes(
                            template.id.toString()
                          )
                        )
                        ?.map((template) => (
                          <li
                            key={template.id + template.name}
                            style={{ width: "176px", fontSize: "14px" }}
                          >
                            <div
                              style={{ maxWidth: "161px", width: "auto" }}
                              onClick={(e) => {
                                this.props.filterChangeHandler(
                                  this.props.filterIndex,
                                  template.label,
                                  template.id.toString(),
                                  "account"
                                );
                                this.showDropdownMenu(e);
                              }}
                            >
                              {template.label}
                            </div>
                          </li>
                        ))}
                  </div>
                ) : null;
              })}
            </div>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default DropdownReportFilters;
