import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";

function ActivitiesScoreCard({
  filter_rules,
  fromDashboard,
  setSegmentByDashboard,
  mode,
  segmentByDashboard,
  viewDashboard,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [segmentBy, setSegmentBy] = useState("type");
  useEffect(() => {
    const getData = () => {
      let newValue = [];
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        return {
          attribute,
          operator,
          value,
        };
      });
      setLoading(true);
      let url = `/charts/activity-chart`;
      axios({
        method: "POST",
        url,
        data: {
          chart: {
            type: "scorecard",
            metric: segmentBy,
          },
          filter_rules: newValue,
        },
      })
        .then((res) => {
          setLoading(false);
          setData(res.data.activities);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getData();
    fromDashboard && setSegmentByDashboard(segmentBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_rules, segmentBy]);
  useEffect(() => {
    mode === "edit" &&
      viewDashboard === "scorecard" &&
      setSegmentBy(segmentByDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <label>Segment By</label>
      <select value={segmentBy} onChange={(e) => setSegmentBy(e.target.value)}>
        <option value="type">Type</option>
        <option value="result">Result</option>
      </select>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <div>
          <h3
            style={{ fontSize: "22px", fontWeight: "400", textAlign: "center" }}
          >
            Score Card
          </h3>
          <Row>
            {data?.map((d) => (
              <Col>
                <div
                  style={{
                    border: "1px solid #e7e7e7",
                    borderRadius: "4px",
                    width: "60%",
                    textAlign: "center",
                    paddingBottom: "21px",
                    margin: "0 auto",
                  }}
                >
                  <h4 style={{ fontSize: "16px", fontWeight: "400" }}>
                    {segmentBy === "type"
                      ? d.type === "activity_logged"
                        ? "Activity Logged"
                        : d.type === "task_completed"
                        ? "Task Completed"
                        : d.type
                      : d.result}
                  </h4>
                  <div style={{ fontSize: "16px", fontWeight: "500" }}>
                    {d.count}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}

export default ActivitiesScoreCard;
