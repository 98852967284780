import { SET_REPORTS_FILTERS } from "./types";

const INITIAL_STATE = {
  reports: [], // id, filters: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_REPORTS_FILTERS:
      console.log("action.payload", action.payload)
      const reportId = action.payload.id;
      const reports = state.reports.map((obj) => {
        if (obj.id === reportId) {
          return { ...obj, filters: action.payload.filters };
        }
        return obj;
      });

      const isIdExist = state.reports.some((obj) => obj.id === reportId);

      if (!isIdExist) {
        reports.push(action.payload);
      }
      return {
        reports,
      };
    default:
      return state;
  }
};

export default reducer;
