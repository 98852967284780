export const carrierHeaderMapping = {
  name: "Carrier Name",
  email: "Email",
  formatted_phone: "Phone",
  phone_ext: "Phone extension",
  mc_number: "MC#",
  owners: "Carrier Owners",
  equipment_types: "Equipment Types",
  status: "Status",
  dot_number: "DOT#",
  fleet_size: "Fleet Size",
  description: "Description",
  city: "City",
  state: "State",
  zip: "Zip",
  country: "Country",
  address: "Home Base",
  lat: "Latitude",
  long: "Longitude",
};
