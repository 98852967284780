import React, { useState } from "react";
import "../../sass/CustomerProfile.scss";
import DummyActivityForm from "./DummyActivityForm";
import DummyActivityLog from "./DummyActivityLog";
import DummyIntro from "./DummyIntro";

const DummyProfile = ({ accountId, customerName, tabIndex, deleteTab }) => {
  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [restart, setRestart] = useState(false);
  const [loggedData, setLoggedData] = useState(false);
  const [description, setDescription] = useState("");
  const [showDeal, setShowDeal] = useState(false);
  const [dealData, setDealData] = useState({
    name: "",
    stage: "",
    date: "",
  });

  return (
    <div>
      <div className="customerProfile">
        <div className="row">
          <div className="col-1-of-3">
            <DummyIntro
              customerId={accountId}
              showOpportunityModal={showOpportunityModal}
              setShowOpportunityModal={setShowOpportunityModal}
              tabIndex={tabIndex}
              deleteTab={deleteTab}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              restart={restart}
              setRestart={setRestart}
              showDeal={showDeal}
              setShowDeal={setShowDeal}
              dealData={dealData}
              setDealData={setDealData}
            />
          </div>
          <div className="col-1-of-3">
            <DummyActivityLog
              loggedData={loggedData}
              description={description}
            />
          </div>
          <div className="col-1-of-3">
            <DummyActivityForm
              customerId={accountId}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              // New props
              restart={restart}
              setRestart={setRestart}
              loggedData={loggedData}
              setLoggedData={setLoggedData}
              setDescription={setDescription}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DummyProfile);
