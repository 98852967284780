import {
  FETCH_DEAL_FIELDS_REQUEST,
  FETCH_DEAL_FIELDS_SUCCESS,
  FETCH_DEAL_FIELDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllFormDealFieldsRequest = () => {
  return {
    type: FETCH_DEAL_FIELDS_REQUEST,
  };
};
const fetchAllFormDealFieldsSuccess = (data) => {
  return {
    type: FETCH_DEAL_FIELDS_SUCCESS,
    payload: data,
  };
};
const fetchAllFormDealFieldsFailure = (err) => {
  return {
    type: FETCH_DEAL_FIELDS_FAILURE,
    payload: err,
  };
};

// Fetch All AllFormDealFields
export const fetchAllAllFormDealFields = () => {
  return (dispatch) => {
    dispatch(fetchAllFormDealFieldsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/default-fields?type=opportunity`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.default_fields;
        dispatch(fetchAllFormDealFieldsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllFormDealFieldsFailure(errorMsg));
      });
  };
};
