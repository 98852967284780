import React from "react";
import { thirtyThreeUsdCompanies } from "../constants/companiesOverride";
// Redux stuff
import { connect } from "react-redux";

function ProMonthly(props) {
  const { plan, trialDateLimitExceeded, carrierDateLimitExceeded } = props;
  return (
    <div>
      <div className="item1">
        <h3 className="package-heading">
          PRO {plan === "annually" ? "ANNUALLY" : "MONTHLY"} &nbsp;
          <span style={{ color: "#3aab7b" }} id="proPricingYearly">
            {plan === "annually" && "20% Off"}
          </span>
        </h3>
        <h3 className="package-pricing" id="proPricing">
          {plan === "monthly"
            ? thirtyThreeUsdCompanies.includes(
                props.companyOnboarding?.id?.toString()
              )
              ? "$33"
              : carrierDateLimitExceeded
              ? "$39"
              : trialDateLimitExceeded
              ? "$33"
              : "$20"
            : carrierDateLimitExceeded
            ? "$31.2"
            : trialDateLimitExceeded
            ? "$26.4"
            : "$16"}
        </h3>
        <p className="package-heading-sub" id="proPricingDetails">
          Per user, per month. Billed {plan}.
        </p>
        <p>
          <i
            className="fa fa-check-circle"
            style={{ color: "#3aab7b" }}
            aria-hidden="true"
          ></i>
          &nbsp;&nbsp;50,000 contacts
        </p>
        <p>
          <i
            className="fa fa-check-circle"
            style={{ color: "#3aab7b" }}
            aria-hidden="true"
          ></i>
          &nbsp;&nbsp;10GB storage per user
        </p>
        <p>Cancel any time.</p>
        <button
          type="button"
          className="try-free-button"
          disabled={
            props.subscriptionDetails.plan_id ===
            props.plans.find(
              (plan) => plan.name === "Pro" && plan.interval === "month"
            ).id
          }
          onClick={() =>
            props.updatePlan(
              props.plans.find(
                (plan) => plan.name === "Pro" && plan.interval === "month"
              ).id
            )
          }
          style={
            props.subscriptionDetails.plan_id ===
            props.plans.find(
              (plan) => plan.name === "Pro" && plan.interval === "month"
            ).id
              ? { background: "#A7ABAA" }
              : {}
          }
        >
          {props.subscriptionDetails.plan_id ===
          props.plans.find(
            (plan) => plan.name === "Pro" && plan.interval === "month"
          ).id
            ? "Purchased"
            : "Change Plan"}
        </button>
      </div>
    </div>
  );
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = () => {
  return {};
};

export default connect(MSP, MDP)(ProMonthly);
