import React, { Component } from "react";
import "../../sass/CustomerIntro.scss";
import CarrierGreenIcon from "../../images/CarrierGreenIcon.svg";
import ReactTooltip from "react-tooltip";

import { withRouter } from "react-router-dom";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex } from "../../redux";
import { compose } from "recompose";

class Carrier extends Component {
  render() {
    console.log("this.props", this.props)
    return (
      <div
        className="schedule__items-1"
        style={{ cursor: "pointer", height: "auto", minHeight: "54px" }}
        onClick={() => {
          this.props.setActiveTabIndex(this.props.totalTabs + 1);
          this.props.setTab({
            type: "carrier",
            id: this.props.id,
            name: this.props.name
          });
          this.props.history.push("/active-tabs");
        }}
        data-tip={this.props.name}
      >
        <ReactTooltip />
        <span className="com-2" style={{ background: "#B3E0DC" }}>
          <img
            src={CarrierGreenIcon}
            alt=""
            style={{
              transform: "translate(0.5px,0px)",
              background: "#B3E0DC",
              width: "18px",
            }}
          />
        </span>
        <div className="schedule__heading">
          <span className="name-opp">
            {this.props.name && this.props.name.length > 30
              ? this.props.name.substring(0, 29) + "..."
              : this.props.name}
          </span>
        </div>
        <div className="schedule__desc-contact">{this.props.detail}</div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(Carrier);
