import React, { Component } from "react";
import ReactModal from "react-modal";

class PersonalizedVarsModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    personalizedVariablesArray: [],
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      personalizedVariablesArray: this.props.personalizedVariablesArray,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.setState({
        personalizedVariablesArray: this.props.personalizedVariablesArray,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  personlizeChangeHandler = (key, e) => {
    const { value } = e.target;
    this.setState({
      personalizedVariablesArray: this.state.personalizedVariablesArray?.map(
        (arr) => {
          if (key === arr.key) {
            arr.value = value;
          }
          return arr;
        }
      ),
    });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "46vh", top: "17%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Personalize Variables</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <p>Please fill empty values</p>
              <div
                style={{
                  height: "auto",
                  maxHeight: "25vh",
                  overflowY: "auto",
                  margin: "5px",
                }}
              >
                {this.state.personalizedVariablesArray?.map((arr) => (
                  <div key={arr.key}>
                    <label>{arr.label}</label>
                    <input
                      value={arr.value}
                      onChange={(e) => this.personlizeChangeHandler(arr.key, e)}
                    />
                  </div>
                ))}
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  onClick={() =>
                    this.props.readTemplateVarsValues(
                      this.props.templateId,
                      this.state.personalizedVariablesArray
                    )
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default PersonalizedVarsModal;
