import React from "react";

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  // This method catches errors in the component tree below
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // This is where you can log the error (e.g., to an external service)
  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
    const userDomain = window.location.origin
    // Clear local storage when an error is caught
    localStorage.clear();
    window.location.replace(userDomain);
  }

  render() {
    if (this.state.hasError) {
      // You can render a fallback UI here
      return (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <h1>Something went wrong.</h1>
          <p>
            We encountered an unexpected issue. Please try refreshing the page.
          </p>
          <button onClick={() => window.location.reload()}>Refresh</button>
        </div>
      );
    }

    // Render children if no error
    return this.props.children;
  }
}

export default ErrorBoundary;
