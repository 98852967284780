import React from "react";
import "../../sass/DropdownHeader.scss";
import { Link } from "react-router-dom";
import QualifyModal from "./../QualifyModal";
import ReactModal from "react-modal";
import axios from "axios";
import ArrowIcon from "../../images/ArrowDown.svg";
import { NotificationManager } from "react-notifications";

class DropdownAccount extends React.Component {
  state = {
    displayMenu: false,
    showOpportunityModal: false,
    showQualifyModal: false,
    showDeleteModal: false,
  };

  handleOpenModal = () => {
    this.setState({
      showOpportunityModal: true,
    });
  };
  handleOpenDeleteModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };
  handleOpenQualifyModal = () => {
    this.setState({
      showQualifyModal: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showOpportunityModal: false,
    });
  };
  handleCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  handleCloseQualifyModal = () => {
    this.setState({
      showQualifyModal: false,
    });
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  };

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  };

  render() {
    return (
      <div
        className="dropdownAccount"
        style={{
          background: "transparent",
          width: "15px",
        }}
      >
        <div
          className="button"
          onClick={this.showDropdownMenu}
          style={{ fontSize: "14px" }}
        >
          <img src={ArrowIcon} alt="V" width="10px" />
        </div>
        <QualifyModal
          showOpportunityModal={this.state.showOpportunityModal}
          setShowOpportunityModal={this.handleOpenModal}
          handleCloseModal={this.handleCloseModal}
          customerName={this.props.customerName}
          customerId={this.props.customerId}
          fetchAccountData={this.props.fetchAccountData}
          status={this.props.status}
        />
        <DeleteModal
          showOpportunityModal={this.state.showDeleteModal}
          setShowOpportunityModal={this.handleOpenDeleteModal}
          handleCloseModal={this.handleCloseDeleteModal}
          customerName={this.props.customerName}
          customerId={this.props.customerId}
          tabIndex={this.props.tabIndex}
          deleteTab={this.props.deleteTab}
        />

        {this.state.displayMenu ? (
          <ul style={{ width: "171px" }}>
            <li>
              <Link
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  lineHeight: "16px",
                  color: "#212121",
                }}
                onClick={this.handleOpenModal}
              >
                Change Status
              </Link>
            </li>
            <li>
              <Link
                onClick={this.handleOpenDeleteModal}
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  lineHeight: "16px",
                  color: "#F47272",
                }}
              >
                Delete Account
              </Link>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

class DeleteModal extends React.Component {
  state = {
    showModal: true,
    isLoading: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  deleteAccount = () => {
    this.setState({ isLoading: true });
    axios({
      method: "DELETE",
      url: `/accounts/${this.props.customerId}`,
    })
      .then((res) => {
        this.props.deleteTab(this.props.tabIndex);
        NotificationManager.success("Account deleted successfully.");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        NotificationManager.error("Failed to delete account.");
        this.setState({ isLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "30vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Account</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form>
                <p>
                  Are you sure you want to permanently delete this account?
                  <br />
                  Changes will not be restored
                </p>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="modal__confirm-button"
                    onClick={this.deleteAccount}
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Deleting" : "Delete"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default DropdownAccount;
