import React, { Component } from "react";
import "../sass/Targets.scss";
import "../sass/ReactProgressBar.scss";
import axios from "axios";
import DropdownTargets from "./Dropdown/DropdownTargets";
import { withRouter } from "react-router-dom";
import { Progress } from "react-sweet-progress";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
} from "../redux";
import { CurrencyList } from "../constants/currencylist";
import DashboardLoader from "./Loaders/DashboardLoader";
import ReactTooltip from "react-tooltip";

class Targets extends Component {
  state = {
    activities: null,
    activities_total: null,
    opportunities: null,
    opportunities_total: null,
    won_opportunities: null,
    won_opportunities_total: null,
    activities_period: null,
    opportunities_period: null,
    won_opportunities_period: null,
    won_opportunities_metric: null,
    showModal: false,
    windowWidth: window.innerWidth,
  };
  resetLoader = () => {
    axios({
      method: "GET",
      url: `/dashboard/target/stats?user_ids=${this.props.userId}`,
    }).then((response) => {
      this.setState({
        activities: response.data.activities.completed,
        activities_total: response.data.activities.value,
        activities_period: response.data.activities.period,
        opportunities: response.data.opportunities.completed,
        opportunities_total: response.data.opportunities.value,
        opportunities_period: response.data.opportunities.period,
        won_opportunities: response.data.won_opportunities.completed,
        won_opportunities_total: response.data.won_opportunities.value,
        won_opportunities_period: response.data.won_opportunities.period,
        won_opportunities_metric: response.data.won_opportunities.metric,
      });
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.props.setClick(this.resetLoader);
    axios({
      method: "GET",
      url: `/dashboard/target/stats?user_ids=${this.props.userId}`,
    }).then((response) => {
      this.setState({
        activities: response.data.activities.completed,
        activities_total: response.data.activities.value,
        activities_period: response.data.activities.period,
        opportunities: response.data.opportunities.completed,
        opportunities_total: response.data.opportunities.value,
        opportunities_period: response.data.opportunities.period,
        won_opportunities: response.data.won_opportunities.completed,
        won_opportunities_total: response.data.won_opportunities.value,
        won_opportunities_period: response.data.won_opportunities.period,
        won_opportunities_metric: response.data.won_opportunities.metric,
      });
    });
  }
  handleOpenModal = () => {
    this.setState({
      showModal: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      window.addEventListener("resize", this.handleResize);
      axios({
        method: "GET",
        url: `/dashboard/target/stats?user_ids=${this.props.userId}`,
      }).then((response) => {
        this.setState({
          activities: response.data.activities.completed,
          activities_total: response.data.activities.value,
          activities_period: response.data.activities.period,
          opportunities: response.data.opportunities.completed,
          opportunities_total: response.data.opportunities.value,
          opportunities_period: response.data.opportunities.period,
          won_opportunities: response.data.won_opportunities.completed,
          won_opportunities_total: response.data.won_opportunities.value,
          won_opportunities_period: response.data.won_opportunities.period,
          won_opportunities_metric: response.data.won_opportunities.metric,
        });
      });
    }
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleUserId = (user_ids) => {
    this.setState({
      user_ids,
    });
  };

  render() {
    const {
      activities,
      activities_total,
      activities_period,
      opportunities,
      opportunities_total,
      opportunities_period,
      won_opportunities,
      won_opportunities_total,
      won_opportunities_period,
      won_opportunities_metric,
    } = this.state;
    const activitiesPercent = (activities / activities_total) * 100;
    const activitiesPercentFixed =
      isNaN(activitiesPercent) || !isFinite(activitiesPercent)
        ? 0
        : activitiesPercent.toFixed(0);
    const opportunitiesPercent = (opportunities / opportunities_total) * 100;
    const opportunitiesPercentFixed =
      isNaN(opportunitiesPercent) || !isFinite(opportunitiesPercent)
        ? 0
        : opportunitiesPercent.toFixed(0);
    const won_opportunitiesPercent =
      (won_opportunities / won_opportunities_total) * 100;
    const won_opportunitiesPercentFixed =
      isNaN(won_opportunitiesPercent) || !isFinite(won_opportunitiesPercent)
        ? 0
        : won_opportunitiesPercent.toFixed(0);
    const selectedCurrency = this.props.companyOnboarding?.currency
      ? this.props.companyOnboarding?.currency
      : "USD";
    const selectedCurrencyObject = CurrencyList.find(
      (currency) => currency.code === selectedCurrency
    );
    return (
      <>
        <div
          className="card"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <ReactTooltip multiline={true} id="Activities-Targets" />
          <ReactTooltip multiline={true} id="New-Deals-Targets" />
          <ReactTooltip multiline={true} id="Won-Deals-Targets" />
          {this.props.targetPermissions?.includes(
            "crm:teams:targets-goals:editor"
          ) && (
            <div style={{ position: "absolute", right: "5%", top: "0%" }}>
              <DropdownTargets userId={this.props.userId} />
            </div>
          )}
          <div
            style={{ borderBottom: "1px solid #f3f3f6", paddingBottom: "10px" }}
          >
            <div className="targets-heading">
              <h4 className="targets-header" style={{ marginTop: "14px" }}>
                <span
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      this.props.setTab({
                        type: "activity",
                        id: `${this.props.userId}activities`,
                        name: "Activities",
                        blank: true,
                        userId: this.props.userId,
                        userName: this.props.userName,
                        activities_period,
                      });
                    } else {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "activity",
                        id: `${this.props.userId}activities`,
                        name: "Activities",
                        userId: this.props.userId,
                        userName: this.props.userName,
                        activities_period,
                      });
                      this.props.history.push("/active-tabs");
                    }
                  }}
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  data-tip="View all activities."
                  data-for="Activities-Targets"
                >
                  Activities
                </span>
              </h4>
            </div>
            {activities === null && <DashboardLoader />}
            {activities !== null && (
              <div>
                <div className="targets-progressbar">
                  <Progress
                    percent={activitiesPercentFixed}
                    theme={{
                      success: {
                        color: "#62ca9d",
                      },
                      active: {
                        color: "#62ca9d",
                      },
                      default: {
                        color: "#62ca9d",
                      },
                    }}
                  />
                </div>
                <p className="targets-desc">
                  You have logged <strong>{activities} activities</strong> on
                  your target of {activities_total}
                  &nbsp;{activities_period}.
                </p>
              </div>
            )}
          </div>
          <div
            style={{ borderBottom: "1px solid #f3f3f6", paddingBottom: "10px" }}
          >
            <div className="targets-heading">
              <h4 className="targets-header" style={{ marginTop: "14px" }}>
                <span
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      this.props.setTab({
                        type: "dealStage",
                        id: `${this.props.userId}newDeals`,
                        name: "New Deals",
                        blank: true,
                        userId: this.props.userId,
                        userName: this.props.userName,
                        dealPeriod: opportunities_period,
                      });
                    } else {
                      this.props.setActiveTabIndex(this.props.totalTabs + 1);
                      this.props.setTab({
                        type: "dealStage",
                        id: `${this.props.userId}newDeals`,
                        name: "New Deals",
                        userId: this.props.userId,
                        userName: this.props.userName,
                        dealPeriod: opportunities_period,
                      });
                      this.props.history.push("/active-tabs");
                    }
                  }}
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  data-tip="View all new deals."
                  data-for="New-Deals-Targets"
                >
                  New Deals
                </span>
              </h4>
            </div>
            {opportunities === null && <DashboardLoader />}
            {opportunities !== null && (
              <div>
                <div className="targets-progressbar">
                  <Progress
                    percent={opportunitiesPercentFixed}
                    theme={{
                      success: {
                        color: "#62ca9d",
                      },
                      active: {
                        color: "#62ca9d",
                      },
                      default: {
                        color: "#62ca9d",
                      },
                    }}
                  />
                </div>
                <p className="targets-desc">
                  You have created <strong>{opportunities} new deals</strong> on{" "}
                  your target of {opportunities_total} {opportunities_period}.
                </p>
              </div>
            )}
          </div>
          <div className="targets-heading">
            <h4 className="targets-header" style={{ marginTop: "14px" }}>
              <span
                onClick={(e) => {
                  if (e.metaKey || e.ctrlKey) {
                    this.props.setTab({
                      type: "dealStage",
                      id: `${this.props.userId}wonDeals`,
                      name: "Won Deals",
                      blank: true,
                      stageId: this.props.wonStage,
                      userId: this.props.userId,
                      userName: this.props.userName,
                      closeDatePeriod: won_opportunities_period,
                    });
                  } else {
                    this.props.setActiveTabIndex(this.props.totalTabs + 1);
                    this.props.setTab({
                      type: "dealStage",
                      id: `${this.props.userId}wonDeals`,
                      name: "Won Deals",
                      userId: this.props.userId,
                      stageId: this.props.wonStage,
                      userName: this.props.userName,
                      closeDatePeriod: won_opportunities_period,
                    });
                    this.props.history.push("/active-tabs");
                  }
                }}
                style={{
                  color: "inherit",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                data-tip="View all won deals."
                data-for="Won-Deals-Targets"
              >
                Won Deals
              </span>
            </h4>
          </div>
          {won_opportunities === null && <DashboardLoader />}
          {won_opportunities !== null && (
            <div>
              <div className="targets-progressbar">
                <Progress
                  percent={won_opportunitiesPercentFixed}
                  theme={{
                    success: {
                      color: "#62ca9d",
                    },
                    active: {
                      color: "#62ca9d",
                    },
                    default: {
                      color: "#62ca9d",
                    },
                  }}
                />
              </div>
              {won_opportunities_metric === "revenue" ? (
                <p className="targets-desc">
                  You have won{" "}
                  <strong>
                    {selectedCurrencyObject.symbol}
                    {won_opportunities}
                  </strong>{" "}
                  in revenue on your target of {selectedCurrencyObject.symbol}
                  {won_opportunities_total} {won_opportunities_period}.
                </p>
              ) : (
                <p className="targets-desc">
                  You have won <strong>{won_opportunities} deals</strong> on{" "}
                  your target of {won_opportunities_total}{" "}
                  {won_opportunities_period}.
                </p>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default withRouter(connect(MSP, MDP)(Targets));
