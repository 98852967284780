import React, { Component } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
// Redux stuff
import { connect } from "react-redux";

class EditTeamModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    name: "",
    parentTeam: null,
    teamLeader: [],
    frontendTeamLeader: [],
    teamMember: [],
    frontendTeamMember: [],
    description: "",
    allTeams: [],
    removedArrayLeader: [],
    removedArrayMember: [],
    addedArrayLeader: [],
    addedArrayMember: [],
    isLoading: false,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      name: this.props.name,
      description: this.props.description,
      parentTeam: this.props.parentTeam,
      teamLeader: this.props.members
        .map((member) => {
          const { role, id } = member;
          if (role === "leader") {
            return id;
          } else {
            return null;
          }
        })
        .filter((m) => m !== null),
      frontendTeamLeader: this.props.members
        .map((member) => {
          const { role, name, id } = member;
          if (role === "leader") {
            return { value: id, label: name };
          } else {
            return null;
          }
        })
        .filter((m) => m !== null),
      frontendTeamMember: this.props.members
        .map((member) => {
          const { role, name, id } = member;
          if (role === "member") {
            return { value: id, label: name };
          } else {
            return null;
          }
        })
        .filter((m) => m !== null),
      teamMember: this.props.members
        .map((member) => {
          const { role, id } = member;
          if (role === "member") {
            return id;
          } else {
            return null;
          }
        })
        .filter((m) => m !== null),
    });
    axios({
      method: "GET",
      url: `/admin/teams?view=table`,
    })
      .then((response) => {
        this.setState({ allTeams: response.data.teams });
      })
      .catch((error) => console.log("error", error));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value === "" ? null : value,
    });
  };
  teamLeaderChangeHandler = (selected, actionType) => {
    let difference;
    let addition;
    if (actionType.action === "remove-value") {
      difference = actionType.removedValue;
    }
    if (actionType.action === "select-option") {
      addition = actionType.option;
    }
    this.setState((prevState) => ({
      removedArrayLeader: [...prevState.removedArrayLeader, difference].filter(
        (data) => data !== undefined
      ),
      addedArrayLeader: [...prevState.addedArrayLeader, addition].filter(
        (data) => data !== undefined
      ),
    }));
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    console.log("QWE:", selectedArray);

    this.setState((prevState) => ({
      teamLeader: selectedArray,
      frontendTeamLeader: selected,
    }));
  };
  teamMemberChangeHandler = (selected, actionType) => {
    let differenceM;
    let additionM;
    if (actionType.action === "remove-value") {
      differenceM = actionType.removedValue;
    }
    if (actionType.action === "select-option") {
      additionM = actionType.option;
    }
    this.setState((prevState) => ({
      removedArrayMember: [...prevState.removedArrayMember, differenceM].filter(
        (data) => data !== undefined
      ),
      addedArrayMember: [...prevState.addedArrayMember, additionM].filter(
        (data) => data !== undefined
      ),
    }));
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    console.log("QWE:", selectedArray);

    this.setState((prevState) => ({
      teamMember: selectedArray,
      frontendTeamMember: selected,
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const teamL = this.state.teamLeader?.map((leader) => {
      return { user_id: leader, role: "leader", _new: false, _destroy: false };
    });
    const teamLAdded = this.state.addedArrayLeader?.map((leader) => {
      return {
        user_id: leader.value,
        role: "leader",
        _new: true,
        _destroy: false,
      };
    });
    const teamLRemove = this.state.removedArrayLeader?.map((leader) => {
      return {
        user_id: leader.value,
        role: "leader",
        _new: false,
        _destroy: true,
      };
    });
    const teamLFinal = teamL.concat(teamLAdded, teamLRemove);
    const teamM = this.state.teamMember?.map((member) => {
      return { user_id: member, role: "member", _new: false, _destroy: false };
    });
    const teamMAdded = this.state.addedArrayMember?.map((member) => {
      return {
        user_id: member.value,
        role: "member",
        _new: true,
        _destroy: false,
      };
    });
    const teamMRemove = this.state.removedArrayMember?.map((member) => {
      return {
        user_id: member.value,
        role: "member",
        _new: false,
        _destroy: true,
      };
    });
    const teamMFinal = teamM.concat(teamMAdded, teamMRemove);
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/admin/team/${this.props.teamId}`,
      data: {
        name: this.state.name,
        description: this.state.description,
        parent_id: this.state.parentTeam === "" ? null : this.state.parentTeam,
        users: teamLFinal.concat(teamMFinal),
      },
    })
      .then((res) => {
        this.props.fetchTeams();
        this.props.handleCloseModal();
        NotificationManager.success("Team edited successfully.");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        NotificationManager.error("Error editing team.");
        this.setState({ isLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "97%",
        outline: "#C0EAD8",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#C0EAD8"
            : null,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#C0EAD8"),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "#C0EAD8",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
      }),
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "60vh", top: "15%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-users" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Edit Team</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <label>Name</label>
                <input
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={this.changeHandler}
                />
                <label>Description</label>
                <input
                  name="description"
                  type="text"
                  value={this.state.description}
                  onChange={this.changeHandler}
                />
                <label>Parent</label>
                <select
                  name="parentTeam"
                  value={this.state.parentTeam}
                  onChange={this.changeHandler}
                >
                  <option hidden>-select-</option>
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  <option value="">{this.props.companyName}</option>
                  {this.state.allTeams
                    ?.filter((team) => team.id !== this.props.teamId)
                    ?.map((team) => {
                      const { id, name } = team;
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                </select>
                <label>Team Leader(s)</label>
                <Select
                  value={this.state.frontendTeamLeader}
                  closeMenuOnSelect={false}
                  isMulti
                  name="teamLeader"
                  styles={colourStyles}
                  options={this.props.allUsers
                    ?.filter((user) => !this.state.teamMember.includes(user.id))
                    .map((user) => {
                      const { id, name } = user;
                      return { value: id, label: name };
                    })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.teamLeaderChangeHandler}
                />
                <label>Team Member(s)</label>
                <Select
                  value={this.state.frontendTeamMember}
                  closeMenuOnSelect={false}
                  isMulti
                  name="teamMember"
                  styles={colourStyles}
                  options={this.props.allUsers
                    ?.filter((user) => !this.state.teamLeader.includes(user.id))
                    .map((user) => {
                      const { id, name } = user;
                      return { value: id, label: name };
                    })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.teamMemberChangeHandler}
                />
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    allUsers: state.allUsers.users,
  };
};

const MDP = () => {
  return {};
};

export default connect(MSP, MDP)(EditTeamModal);
