import React from "react";
import "../../sass/Targets.scss";
import TargetsActivities from "../../images/TargetsActivities.svg";
import TargetsWonDeal from "../../images/TargetsWonDeal.svg";
import TargetsNewDeal from "../../images/TargetsNewDeal.svg";
import { Hidden } from "react-grid-system";

function TargetsCustomFunction(props) {
  //   const { targets } = props;
  return (
    <div>
      <div className="targets">
        <Hidden md sm xs>
          <p
            style={{
              textAlign: "right",
              fontSize: "15px",
              lineHeight: "18px",
              color: "#8B9BA7",
              marginTop: "5px",
              marginRight: "10px",
            }}
          >
            Dashboard Customization
          </p>
        </Hidden>
        <div className="card">
          <form>
            <div
              style={{
                borderBottom: "2px dashed #E7EFEC",
                paddingBottom: "30px",
              }}
            >
              <h3
                style={{
                  fontSize: "16px",
                  lineHeight: "19px",
                  fontWeight: "500",
                  textAlign: "left",
                  marginLeft: "14px",
                  marginTop: "8px",
                }}
              >
                Activities
              </h3>
              <div>
                <Hidden sm xs>
                  <div style={{ display: "inline-block", marginRight: "34px" }}>
                    <img
                      src={TargetsActivities}
                      alt="Targets"
                      style={{ transform: "translateY(-60px)" }}
                    />
                  </div>
                </Hidden>
                <div style={{ display: "inline-block", textAlign: "left" }}>
                  <div>
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "6px",
                      }}
                    >
                      Period
                    </label>
                    <select
                      name="period"
                      onChange={(e) => props.changeHandler("activities", e)}
                      value={props?.targets?.activities?.period}
                      style={{
                        width: "215px",
                        border: "1px solid #C5C5D3",
                        borderRadius: "7px",
                        padding: "5px",
                      }}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginTop: "15px",
                        marginBottom: "6px",
                      }}
                    >
                      Target
                    </label>
                    <input
                      type="number"
                      name="value"
                      onChange={(e) => props.changeHandler("activities", e)}
                      value={props?.targets?.activities?.value}
                      style={
                        props.errorMessage === true &&
                        (props?.targets?.activities?.value === "" ||
                          props?.targets?.activities?.value === undefined)
                          ? {
                              width: "215px",
                              border: "2px solid #F36363",
                              borderRadius: "7px",
                              padding: "5px",
                            }
                          : {
                              width: "215px",
                              border: "1px solid #C5C5D3",
                              borderRadius: "7px",
                              padding: "5px",
                            }
                      }
                    />
                    <div
                      style={{
                        color: "#F36363",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      {props.errorMessage === true &&
                      (props?.targets?.activities?.value === "" ||
                        props?.targets?.activities?.value === undefined)
                        ? "Activities Targets is required"
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                borderBottom: "2px dashed #E7EFEC",
                paddingBottom: "30px",
              }}
            >
              <h3
                style={{
                  fontSize: "16px",
                  lineHeight: "19px",
                  fontWeight: "500",
                  textAlign: "left",
                  marginLeft: "14px",
                  marginTop: "30px",
                }}
              >
                New Deals
              </h3>
              <div>
                <Hidden sm xs>
                  <div style={{ display: "inline-block", marginRight: "34px" }}>
                    <img
                      src={TargetsNewDeal}
                      alt="Targets"
                      style={{ transform: "translateY(-60px)" }}
                    />
                  </div>
                </Hidden>
                <div style={{ display: "inline-block", textAlign: "left" }}>
                  <div>
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "6px",
                      }}
                    >
                      Period
                    </label>
                    <select
                      name="period"
                      onChange={(e) => props.changeHandler("opportunities", e)}
                      value={props?.targets?.opportunities?.period}
                      style={{
                        width: "215px",
                        border: "1px solid #C5C5D3",
                        borderRadius: "7px",
                        padding: "5px",
                      }}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "6px",
                        marginTop: "15px",
                      }}
                    >
                      Target
                    </label>
                    <input
                      type="number"
                      name="value"
                      onChange={(e) => props.changeHandler("opportunities", e)}
                      value={props.targets?.opportunities?.value}
                      style={
                        props.errorMessage === true &&
                        (props?.targets?.opportunities?.value === "" ||
                          props?.targets?.opportunities?.value === undefined)
                          ? {
                              width: "215px",
                              border: "2px solid #F36363",
                              borderRadius: "7px",
                              padding: "5px",
                            }
                          : {
                              width: "215px",
                              border: "1px solid #C5C5D3",
                              borderRadius: "7px",
                              padding: "5px",
                            }
                      }
                    />
                    <div
                      style={{
                        color: "#F36363",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      {props.errorMessage === true &&
                      (props?.targets?.opportunities?.value === "" ||
                        props?.targets?.opportunities?.value === undefined)
                        ? "New Deals Targets is required"
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingBottom: "30px" }}>
              <h3
                style={{
                  fontSize: "16px",
                  lineHeight: "19px",
                  fontWeight: "500",
                  textAlign: "left",
                  marginLeft: "14px",
                  marginTop: "30px",
                }}
              >
                Won Deals
              </h3>
              <div>
                <Hidden sm xs>
                  <div style={{ display: "inline-block", marginRight: "34px" }}>
                    <img
                      src={TargetsWonDeal}
                      alt="Targets"
                      style={{ transform: "translateY(-130px)" }}
                    />
                  </div>
                </Hidden>
                <div style={{ display: "inline-block", textAlign: "left" }}>
                  <div>
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "6px",
                      }}
                    >
                      Metric
                    </label>
                    <select
                      name="metric"
                      onChange={(e) =>
                        props.changeHandler("won_opportunities", e)
                      }
                      value={props?.targets?.won_opportunities?.metric}
                      style={{
                        width: "215px",
                        border: "1px solid #C5C5D3",
                        borderRadius: "7px",
                        padding: "5px",
                      }}
                    >
                      <option value="revenue">Total Revenue</option>
                      <option value="count">Deal Count</option>
                    </select>
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "6px",
                        marginTop: "15px",
                      }}
                    >
                      Period
                    </label>
                    <select
                      name="period"
                      onChange={(e) =>
                        props.changeHandler("won_opportunities", e)
                      }
                      value={props?.targets?.won_opportunities?.period}
                      style={{
                        width: "215px",
                        border: "1px solid #C5C5D3",
                        borderRadius: "7px",
                        padding: "5px",
                      }}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "6px",
                        marginTop: "15px",
                      }}
                    >
                      Target
                    </label>
                    <input
                      type="number"
                      name="value"
                      value={props?.targets?.won_opportunities?.value}
                      onChange={(e) =>
                        props.changeHandler("won_opportunities", e)
                      }
                      style={
                        props.errorMessage === true &&
                        (props?.targets?.won_opportunities?.value === "" ||
                          props?.targets?.won_opportunities?.value ===
                            undefined)
                          ? {
                              width: "215px",
                              border: "2px solid #F36363",
                              borderRadius: "7px",
                              padding: "5px",
                            }
                          : {
                              width: "215px",
                              border: "1px solid #C5C5D3",
                              borderRadius: "7px",
                              padding: "5px",
                            }
                      }
                    />
                    <div
                      style={{
                        color: "#F36363",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      {props.errorMessage === true &&
                      (props?.targets?.won_opportunities?.value === "" ||
                        props?.targets?.won_opportunities?.value === undefined)
                        ? "Won Deals Targets is required"
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TargetsCustomFunction;
