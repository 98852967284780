import React from "react";
import "../../sass/DropdownHeader.scss";
import ShowMore from "../../images/ShowMore.svg";

class DropdownActivity extends React.Component {
  state = {
    displayMenu: false,
    showModal: false,
  };

  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  render() {
    return (
      <div
        className="dropdownTask"
        ref={this.wrapperRef}
        style={{ background: "transparent", width: "20px" }}
      >
        <div
          className="button"
          style={{ fontSize: "14px", transform: "translate(0px,-24px)" }}
          onClick={this.showDropdownMenu}
        >
          <img src={ShowMore} alt="V" width={12} />{" "}
        </div>

        {this.state.displayMenu ? (
          <ul
            style={{
              width: "111.5px",
              top: "0px",
              borderRadius: "4px",
              zIndex: "101",
              fontWeight: "400",
            }}
          >
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.setShowPinnedModal(true);
                this.setState({ displayMenu: false });
              }}
            >
              {this.props.isPinned ? "Unpin" : "Pin"}
            </li>
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.setShowEditModal(true);
                this.setState({ displayMenu: false });
              }}
            >
              Edit
            </li>
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.setShowDeleteModal(true);
                this.setState({ displayMenu: false });
              }}
            >
              Delete
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default DropdownActivity;
