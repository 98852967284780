import {
  FETCH_CARRIER_STATUSES_REQUEST,
  FETCH_CARRIER_STATUSES_SUCCESS,
  FETCH_CARRIER_STATUSES_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllCarrierStatusesRequest = () => {
  return {
    type: FETCH_CARRIER_STATUSES_REQUEST,
  };
};
const fetchAllCarrierStatusesSuccess = (data) => {
  return {
    type: FETCH_CARRIER_STATUSES_SUCCESS,
    payload: data,
  };
};
const fetchAllCarrierStatusesFailure = (err) => {
  return {
    type: FETCH_CARRIER_STATUSES_FAILURE,
    payload: err,
  };
};

// Fetch All CarrierStatuses
export const fetchAllCarrierStatuses = () => {
  return (dispatch) => {
    dispatch(fetchAllCarrierStatusesRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/carriers/statuses`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.carrier_statuses;
        dispatch(fetchAllCarrierStatusesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllCarrierStatusesFailure(errorMsg));
      });
  };
};
