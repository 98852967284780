import { useState } from "react";
import ReactModal from "react-modal";

const InvoiceSendModal = ({ showModal, handleCloseModal, message, link }) => {
  const [buttonText, setButtonText] = useState("Copy");
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  function copyText(text) {
    var input = document.createElement("textarea");
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    setButtonText("Copied");
    return result;
  }
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: "45vh", top: "20%", textAlign: "center" }}
          >
            <div className="modal__header">
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            {/* <img src={PlanChangeSuccess} alt="" width="100px" /> */}
            <h4
              style={{
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "150%",
                color: "#0C0D0D",
                margin: "20px 0",
              }}
            >
              Send Quote
            </h4>
            <p>{message}</p>
            <div style={{ display: "flex" }}>
              <input
                value={link}
                readOnly
                style={{ margin: "0", borderRadius: "4px 0 0 4px" }}
              />
              <button
                onClick={() => copyText(link)}
                style={{
                  background: "#bfe9d7",
                  color: "#199661",
                  border: "none",
                  outline: "none",
                  borderRadius: "0 4px 4px 0",
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default InvoiceSendModal;
