import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { NotificationManager } from "react-notifications";
import Select from "react-select";

const EditWebhooksModal = ({
  showModal,
  handleCloseModal,
  modalHeight,
  modalTop,
  webhooksLoading: loading,
  setWebhooksLoading: setLoading,
  getAllWebhooks,
  webhookData,
  allStatuses,
  colourStyles,
}) => {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [description, setDescription] = useState("");
  const [events, setEvents] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };

  useEffect(() => {
    if (webhookData && showModal) {
      const eventMapper = webhookData?.events?.map((eve) => {
        const eventLabel = allStatuses.find(
          (stat) => stat.value === eve
        )?.label;
        return {
          label: eventLabel,
          value: eve,
        };
      });
      const webhookStatus = webhookData?.status === "active" ? true : false;
      setWebhookUrl(webhookData?.webhook_url);
      setDescription(webhookData?.description);
      setEvents(eventMapper);
      setIsActive(webhookStatus);
    }
  }, [webhookData, showModal]);

  const eventsChangeHandler = (selectedOptions) => {
    setEvents(selectedOptions);
  };

  const checkVerification = () => {
    axios({
      method: "POST",
      url: webhookUrl,
      data: {
        event: "ping",
        timestamp: Date.now(),
        data: {},
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setIsVerified(true);
          NotificationManager.success("Webhook url verified successfully");
        }
      })
      .catch((err) => {
        NotificationManager.error("Error verifying webhook url");
      });
  };

  const submitHandler = () => {
    if (!webhookUrl || webhookUrl === "") {
      setLoading(false);
      return false;
    }
    setLoading(true);
    axios({
      method: "PUT",
      url: `/webhooks/subscriptions/${webhookData?.id}`,
      data: {
        status: isActive ? "active" : "inactive",
        events: events?.map((eve) => eve.value),
        webhook_url: webhookUrl,
        description,
      },
    })
      .then((res) => {
        setLoading(false);
        handleCloseModal();
        getAllWebhooks();
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error saving webhooks.");
      });
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Edit Webhooks</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <label>Enter Webhook url</label>
            <div style={{ display: "flex" }}>
              <input
                value={webhookUrl}
                onChange={(e) => setWebhookUrl(e.target.value)}
              />
              {webhookUrl !== webhookData?.webhook_url && (
                <button
                  type="button"
                  className="button-sm"
                  disabled={!webhookUrl}
                  style={
                    !webhookUrl
                      ? {
                          background: "#a7abaa",
                          marginLeft: "10px",
                          marginTop: "5px",
                        }
                      : { marginLeft: "10px", marginTop: "5px" }
                  }
                  onClick={checkVerification}
                >
                  Verify
                </button>
              )}
            </div>
            <label>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <label>Webhooks Events</label>
            <Select
              value={events}
              closeMenuOnSelect={false}
              isMulti
              name={"events"}
              styles={colourStyles}
              options={allStatuses.map((choice) => {
                const { value, label } = choice;
                return { value, label };
              })}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOption) => eventsChangeHandler(selectedOption)}
            />
            <label>Status</label>
            <label className="switch1">
              <input
                type="checkbox"
                value={isActive}
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
                style={{ width: "20px", display: "inline-block" }}
              />
              <span
                className="slider1 round1"
                style={{ transform: "translateY(4px)" }}
              ></span>
            </label>
            <span className="general-heading"> &nbsp;Active</span>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
                style={{ zIndex: "auto" }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={
                  loading ||
                  (webhookUrl !== webhookData?.webhook_url && !isVerified) ||
                  !webhookUrl
                }
                onClick={submitHandler}
                style={
                  loading ||
                  (webhookUrl !== webhookData?.webhook_url && !isVerified) ||
                  !webhookUrl
                    ? { background: "#a7abaa", zIndex: "auto" }
                    : { zIndex: "auto" }
                }
              >
                {loading ? "Confirming" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default EditWebhooksModal;
