import { AUTH_USER_SET, AUTH_TOKEN_SET, COMPANY_ONBOARDING_SET } from "./loginTypes";

const initialState = {
  authUser: {},
  authToken: {},
  companyOnboarding: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER_SET:
      return {
        ...state,
        authUser: action.payload,
      };

    case AUTH_TOKEN_SET:
      return {
        ...state,
        authToken: action.payload,
      };

      case COMPANY_ONBOARDING_SET:
        return {
          ...state,
          companyOnboarding: action.payload,
        };

    default:
      return state;
  }
};

export default reducer;
