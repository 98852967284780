import { SET_STORED_RECORD_NOTES, REMOVE_STORED_RECORD_NOTES } from "./types";

const INITIAL_STATE = {
  fields: {
    account: [],
    deal: [],
    contact: [],
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STORED_RECORD_NOTES:
      if (action.payload.type === "account") {
        return {
          fields: {
            ...state.fields,
            account: [
              ...state.fields.account,
              {
                id: action.payload.id,
                title: action.payload.title,
                description: action.payload.description,
                result_id: action.payload.result_id,
                category_id: action.payload.category_id,
                opportunity_id: action.payload.opportunity_id,
                contact_id: action.payload.contact_id,
              },
            ],
          },
        };
      } else if (action.payload.type === "deal") {
        return {
          fields: {
            ...state.fields,
            deal: [...state.fields.deal, action.payload.id],
          },
        };
      } else if (action.payload.type === "contact") {
        return {
          fields: {
            ...state.fields,
            contact: [...state.fields.contact, action.payload.id],
          },
        };
      } else {
        return state;
      }
    case REMOVE_STORED_RECORD_NOTES:
      if (action.payload.type === "account") {
        return {
          fields: {
            ...state.fields,
            account: state.fields.account.filter(
              (field) => field !== action.payload.id
            ),
          },
        };
      } else if (action.payload.type === "deal") {
        return {
          fields: {
            ...state.fields,
            deal: state.fields.deal.filter(
              (field) => field !== action.payload.id
            ),
          },
        };
      } else if (action.payload.type === "contact") {
        return {
          fields: {
            ...state.fields,
            contact: state.fields.contact.filter(
              (field) => field !== action.payload.id
            ),
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default reducer;
