import React, { Component } from "react";
import ReactModal from "react-modal";
import DeleteIcon from "../../images/DeleteIconRed.svg";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";

class SourceChangeModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    sourceArray: [],
    isLoading: false,
    errorMessage: false,
  };
  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getSources();
  }
  getSources = () => {
    this.setState({
      sourceArray: this.props.allAccountSources,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  editChoiceChangeHandler = (choiceId, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      sourceArray: prevState.sourceArray?.map((choice) => {
        if (choice.id === choiceId) {
          choice.name = value;
        }
        return choice;
      }),
    }));
  };
  deleteChoiceChangeHandler = (choiceId, e) => {
    this.setState((prevState) => ({
      sourceArray: prevState.sourceArray?.map((choice) => {
        if (choice.id === choiceId) {
          choice._destroy = true;
          // if (choice.name === "") {
          choice.name = Math.random().toString();
          // }
        }
        return choice;
      }),
    }));
  };
  addEditFieldChoice = () => {
    this.setState((prevState) => ({
      sourceArray: [
        ...prevState.sourceArray,
        {
          name: "",
          id: uuidv4(),
          _destroy: false,
          _new: true,
        },
      ],
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const sourceNameArray = this.state.sourceArray?.map((source) => source.name);
    const checkError = this.state.sourceArray?.every((source) => {
      let resultToReturn = false;
      // call some function with callback function as argument
      resultToReturn = sourceNameArray.some((element, index) => {
        return sourceNameArray.indexOf(element) !== index;
      });
      let { name } = source;
      if (name === "" || name === null) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (resultToReturn) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return source;
    });
    this.setState({ isLoading: true });
    if (checkError === true) {
      axios({
        method: "PUT",
        url: `/accounts/sources`,
        data: {
          account_sources: this.state.sourceArray?.map((source) => {
            const { name, _new, _destroy, id } = source;
            return {
              ...(_new !== true && { id }),
              name,
              _new: _new === undefined ? false : _new,
              _destroy: _destroy === undefined ? false : _destroy,
            };
          }),
        },
      })
        .then((res) => {
          this.props.fromDashboard !== true && this.props.fetchAccountData();
          this.props.handleCloseModal();
          this.props.getSources();
          NotificationManager.success("Sources Updated.");
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          NotificationManager.error("Error updating sources.");
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const sourceNameArray = this.state.sourceArray?.map(
      (source) => source.name
    );
    let duplicateArray = false;
    // call some function with callback function as argument
    duplicateArray = sourceNameArray?.some((element, index) => {
      return sourceNameArray.indexOf(element) !== index;
    });
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "55vh", top: "15%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Customize Source</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <button
                  type="button"
                  onClick={this.addEditFieldChoice}
                  className="button-sm"
                >
                  + Add Source
                </button>
                <div style={{ height: "35vh", overflowY: "auto" }}>
                  {this.state.sourceArray?.map((choice) =>
                    choice._destroy === true ? null : (
                      <div style={{ display: "flex" }} key={choice.id}>
                        <input
                          value={choice.name}
                          onChange={(e) =>
                            this.editChoiceChangeHandler(choice.id, e)
                          }
                        />
                        <button
                          type="button"
                          onClick={(e) =>
                            this.deleteChoiceChangeHandler(choice.id, e)
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                          }}
                        >
                          <img src={DeleteIcon} alt="Delete" width="16px" />
                        </button>
                        {this.state.errorMessage === true &&
                          choice.name === "" && (
                            <div
                              style={{
                                display: "block",
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              Please enter source
                            </div>
                          )}
                      </div>
                    )
                  )}
                </div>
                {this.state.errorMessage === true && duplicateArray && (
                  <div
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    Sources must be unique
                  </div>
                )}
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SourceChangeModal;
