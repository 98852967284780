import {
  FETCH_SPECIAL_REQUIREMENTS_REQUEST,
  FETCH_SPECIAL_REQUIREMENTS_SUCCESS,
  FETCH_SPECIAL_REQUIREMENTS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllSpecialRequirementsRequest = () => {
  return {
    type: FETCH_SPECIAL_REQUIREMENTS_REQUEST,
  };
};
const fetchAllSpecialRequirementsSuccess = (data) => {
  return {
    type: FETCH_SPECIAL_REQUIREMENTS_SUCCESS,
    payload: data,
  };
};
const fetchAllSpecialRequirementsFailure = (err) => {
  return {
    type: FETCH_SPECIAL_REQUIREMENTS_FAILURE,
    payload: err,
  };
};

// Fetch All AllSpecialRequirements
export const fetchAllSpecialRequirements = () => {
  return (dispatch) => {
    dispatch(fetchAllSpecialRequirementsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/lanes/requirement-types`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.requirement_types;
        dispatch(fetchAllSpecialRequirementsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllSpecialRequirementsFailure(errorMsg));
      });
  };
};
