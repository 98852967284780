import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../sass/Reports.scss";

const CampaignPage = (props) => {
  const [campaign, setCampaign] = useState([]);
  const getCampaign = () => {
    axios({
      method: "GET",
      url: `/email-campaigns/${props.campaignId}`,
    }).then((res) => setCampaign(res.data.campaign));
  };
  useEffect(() => getCampaign(), []);
  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>{props.campaignName}</h2>
          <p> </p>
        </div>
      </div>
      <table
        className="accountList__table"
        style={{ marginTop: "20px", width: "93vw" }}
      >
        <thead>
          <tr>
            <th>Email</th>
            <th>Delivery</th>
            <th>Click</th>
            <th>bounce</th>
            <th>spam</th>
          </tr>
        </thead>
        <tbody>
          {campaign?.map((cam) => {
            const { id, email, delivery, click, bounce, spam } = cam;
            return <tr key={id}>
                <td>{email}</td>
                <td>{delivery}</td>
                <td>{click}</td>
                <td>{bounce}</td>
                <td>{spam}</td>
            </tr>;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CampaignPage;
