import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";

class FreePlanModal extends Component {
  state = {
    showModal: true,
    isLoading: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  retrieveUpcomingInvoice = (planId) => {
    axios({
      method: "POST",
      url: `/payments/retrieve-upcoming-invoice`,
      data: {
        plan_id: planId,
      },
    }).then((res) => this.createSubscription(res.data.stripe_cus_id, planId));
  };
  createSubscription = (stripeCustomerId, priceId) => {
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/payments/create-subscription`,
      data: {
        stripe_customer_id: stripeCustomerId,
        plan_id: priceId,
      },
    })
      .then((res) => window.location.reload())
      .catch((error) => this.setState({ isLoading: false }));
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "30vh", top: "25%" }}
            >
              <div className="modal__header">
                <div className="modal__head">
                  <h2 className="modal__heading">Free Plan</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label>Are you sure you want to subscribe to free plan?</label>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Exit
                </button>
                <button
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() =>
                    this.retrieveUpcomingInvoice(
                      this.props.plans?.find(
                        (plan) =>
                          plan.name === "Free" && plan.interval === "year"
                      )?.id
                    )
                  }
                  className="modal__confirm-button"
                >
                  {this.state.isLoading ? "Confirm .." : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default FreePlanModal;
