import React from "react";
import "../../sass/DropdownHeader.scss";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../redux";

class DropdownSchedule extends React.Component {
  state = {
    displayMenu: false,
    showOpportunityModal: false,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  render() {
    return (
      <div
        ref={this.wrapperRef}
        className="dropdownSchedule"
        style={{
          background: "transparent",
          width: "15px",
        }}
      >
        <ReactTooltip />
        <div
          className="button"
          onClick={this.showDropdownMenu}
          style={{ fontSize: "14px" }}
          data-tip="Schedule Send"
        >
          <i
            className="fa fa-caret-down"
            aria-hidden="true"
            style={{ color: "#000", transform: "translateY(7px)" }}
          ></i>
        </div>

        {this.state.displayMenu ? (
          <ul className="dropdownSchedule-ul" style={{ width: "auto" }}>
            <li>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "15px" }}>
                  <label style={{ textAlign: "left", fontWeight: "500" }}>
                    Date
                  </label>
                  <DatePicker
                    selected={this.props.scheduleDate}
                    todayButton="Today"
                    value={this.props.scheduleDate}
                    onChange={(date) => this.props.dateChangeHandler(date)}
                    placeholderText="Date"
                    autoComplete="off"
                    minDate={moment().toDate()}
                    dateFormat={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    style={
                      this.props.scheduleErrorMessage === true &&
                      (this.props.scheduleDate === null ||
                        this.props.scheduleDate === "")
                        ? {
                            border: "2px solid #F36363",
                            width: "130px",
                          }
                        : { width: "130px" }
                    }
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.props.scheduleErrorMessage === true &&
                    (this.props.scheduleDate === null ||
                      this.props.scheduleDate === "")
                      ? "Please select date for schedule"
                      : null}
                  </div>
                </div>
                <div>
                  <label style={{ textAlign: "left", fontWeight: "500" }}>
                    Time
                  </label>
                  <DatePicker
                    selected={this.props.scheduleTime}
                    onChange={(date) => this.props.timeChangeHandler(date)}
                    value={this.props.scheduleTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Time"
                    autoComplete="off"
                    style={
                      this.props.scheduleErrorMessage === true &&
                      (this.props.scheduleTime === null ||
                        this.props.scheduleTime === "")
                        ? {
                            border: "2px solid #F36363",
                            width: "130px",
                          }
                        : { width: "130px" }
                    }
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.props.scheduleErrorMessage === true &&
                    (this.props.scheduleTime === null ||
                      this.props.scheduleTime === "")
                      ? "Please select time for schedule"
                      : null}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="dropdownSchedule-buttonSchedule"
                onClick={(e) => this.props.submitHandler(true, e)}
                disabled={this.props.isLoading}
                style={{ marginLeft: "0" }}
              >
                {this.props.isLoading ? "Scheduling" : "Schedule"}
              </button>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};
export default connect(MSP, MDP)(DropdownSchedule);
