import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import LeadFunnelEight from "./Funnel/LeadFunnelEight";
import LeadFunnelFive from "./Funnel/LeadFunnelFive";
import LeadFunnelFour from "./Funnel/LeadFunnelFour";
import LeadFunnelNine from "./Funnel/LeadFunnelNine";
import LeadFunnelSeven from "./Funnel/LeadFunnelSeven";
import LeadFunnelSix from "./Funnel/LeadFunnelSix";
import LeadFunnelTen from "./Funnel/LeadFunnelTen";
import LeadFunnelThree from "./Funnel/LeadFunnelThree";
import { withRouter } from "react-router-dom";
import "../../sass/Funnel.scss";
import DropdownFunnel from "../Dropdown/DropdownFunnel";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../redux";
import DashboardLoader from "../Loaders/DashboardLoader";

function LeadFunnel(props) {
  const [funnelLoader, setFunnelLoader] = useState(false);
  const [funnelData, setFunnelData] = useState([]);
  const [allStatuses, setAllStatuses] = useState([]);
  const [statusType, setStatusType] = useState("contact");
  const [defaultFunnel, setDefaultFunnel] = useState("");

  const resetLoader = () => {
    axios({
      method: "GET",
      url: `/dashboard/default-lead-funnel`,
    }).then((res) => {
      const value = res.data.default_lead_funnel ?? "contact";
      setDefaultFunnel(value);
      setStatusType(value);
      getData(value);
    });
  };
  useEffect(() => {
    axios({
      method: "GET",
      url: `/dashboard/default-lead-funnel`,
    }).then((res) => {
      const value = res.data.default_lead_funnel ?? "contact";
      setDefaultFunnel(value);
      setStatusType(value);
      getData(value);
    });
    props.setClick(resetLoader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (value) => {
    setFunnelLoader(true);
    let url = `/dashboard/${value}-lead-funnel?user_ids=${props.userId}`;
    axios({
      method: "GET",
      url,
    }).then((res) => {
      const response = res.data.statuses?.filter(
        (status) => status.status !== "" && status.status !== null
      );
      setFunnelData(response);
      setFunnelLoader(false);
    });
    const newAllStatuses =
      value === "account" ? props.allAccountStatuses : props.allContactStatuses;
    setAllStatuses(newAllStatuses?.map((status) => status.name));
  };
  useEffect(() => {
    getData(statusType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  const setFunnel = (id) => {
    setStatusType(id);
    getData(id);
  };
  const setPrimaryFunnel = () => {
    axios({
      method: "POST",
      url: `/dashboard/default-lead-funnel`,
      data: {
        default_lead_funnel: statusType,
      },
    }).then((res) => setDefaultFunnel(statusType));
  };

  const showFunnel = () => {
    if (funnelData.length === 0) {
      return <DashboardLoader />;
    } else if (funnelData.length === 3) {
      return (
        <LeadFunnelThree
          sales_funnel={funnelData}
          userId={props.userId}
          userName={props.userName}
          statusType={statusType}
        />
      );
    } else if (funnelData.length === 4) {
      return (
        <LeadFunnelFour
          sales_funnel={funnelData}
          userId={props.userId}
          userName={props.userName}
          statusType={statusType}
        />
      );
    } else if (funnelData.length === 5) {
      return (
        <LeadFunnelFive
          sales_funnel={funnelData}
          userId={props.userId}
          userName={props.userName}
          statusType={statusType}
        />
      );
    } else if (funnelData.length === 6) {
      return (
        <LeadFunnelSix
          sales_funnel={funnelData}
          userId={props.userId}
          userName={props.userName}
          statusType={statusType}
        />
      );
    } else if (funnelData.length === 7) {
      return (
        <LeadFunnelSeven
          sales_funnel={funnelData}
          userId={props.userId}
          userName={props.userName}
          statusType={statusType}
        />
      );
    } else if (funnelData.length === 8) {
      return (
        <LeadFunnelEight
          sales_funnel={funnelData}
          userId={props.userId}
          userName={props.userName}
          statusType={statusType}
        />
      );
    } else if (funnelData.length === 9) {
      return (
        <LeadFunnelNine
          sales_funnel={funnelData}
          userId={props.userId}
          userName={props.userName}
          statusType={statusType}
        />
      );
    } else if (funnelData.length === 10) {
      return (
        <LeadFunnelTen
          sales_funnel={funnelData}
          userId={props.userId}
          userName={props.userName}
          statusType={statusType}
        />
      );
    }
  };
  return (
    <div
      className="card card-funnel"
      style={{ textTransform: "capitalize", paddingBottom: "0" }}
    >
      <div className="funnel-heading">
        <h4 className="funnel-heading-sub" style={{ marginRight: "0" }}>
          <button
            type="button"
            onClick={setPrimaryFunnel}
            disabled={defaultFunnel === statusType}
            style={
              defaultFunnel === statusType
                ? {
                    cursor: "not-allowed",
                    marginRight: "10px",
                    padding: "0",
                    border: "none",
                    background: "transparent",
                    color: "gold",
                  }
                : {
                    cursor: "pointer",
                    marginRight: "10px",
                    padding: "0",
                    border: "none",
                    background: "transparent",
                    color: "grey",
                  }
            }
          >
            <i class="fa fa-star" aria-hidden="true"></i>
          </button>
          <div
            style={{
              color: "inherit",
              cursor: "pointer",
              textDecoration: "underline",
              display: "inline-block",
            }}
            data-tip="View active leads."
            data-for="Leads-Funnel"
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                props.setTab({
                  type: "leadFunnel",
                  id: `${props?.userId}${"Active"}LeadFunnel${statusType}`,
                  name: `Active Leads`,
                  blank: true,
                  status: "AllActiveLeads",
                  userId: props?.userId,
                  userName: props.userName,
                  statusType,
                  allStatuses: allStatuses?.map((stat) => ({ name: stat })),
                });
              } else {
                props.setActiveTabIndex(props.totalTabs + 1);
                props.setTab({
                  type: "leadFunnel",
                  id: `${props?.userId}${"Active"}LeadFunnel${statusType}`,
                  name: `Active Leads`,
                  userId: props?.userId,
                  status: "AllActiveLeads",
                  userName: props.userName,
                  statusType,
                  allStatuses: allStatuses?.map((stat) => ({ name: stat })),
                });
                props.history.push("/active-tabs");
              }
            }}
          >
            {`Lead Nurturing (${statusType})`}
          </div>
          <DropdownFunnel
            allFunnels={[
              { name: "Lead Nurturing (Account)", id: "account" },
              { name: "Lead Nurturing (Contact)", id: "contact" },
            ]}
            getAllFunnels={setFunnel}
          />
        </h4>
        <ReactTooltip multiline={true} id={"Leads-Funnel"} />
      </div>
      <div style={{ marginTop: "38px", paddingLeft: "14px" }}>
        {funnelLoader ? <DashboardLoader /> : showFunnel()}
        <div className="funnel-redirect-buttons">
          {allStatuses.includes("Long-term") && (
            <span
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}Long-termLeadFunnel${statusType}`,
                    name: `Long-term`,
                    blank: true,
                    status: "Long-term",
                    userId: props?.userId,
                    userName: props.userName,
                    statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}Long-termLeadFunnel${statusType}`,
                    name: `Long-term`,
                    userId: props?.userId,
                    status: "Long-term",
                    userName: props.userName,
                    statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
            >
              Long-term
            </span>
          )}
          {allStatuses.includes("Client") && (
            <span
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}ClientLeadFunnel${statusType}`,
                    name: `Client`,
                    blank: true,
                    status: "Client",
                    userId: props?.userId,
                    userName: props.userName,
                    statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}ClientLeadFunnel${statusType}`,
                    name: `Client`,
                    userId: props?.userId,
                    status: "Client",
                    userName: props.userName,
                    statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
            >
              Client
            </span>
          )}
          {allStatuses.includes("disqualified") && (
            <span
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}disqualifiedLeadFunnel${statusType}`,
                    name: `disqualified`,
                    blank: true,
                    status: "disqualified",
                    userId: props?.userId,
                    userName: props.userName,
                    statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}disqualifiedLeadFunnel${statusType}`,
                    name: `disqualified`,
                    userId: props?.userId,
                    status: "disqualified",
                    userName: props.userName,
                    statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
            >
              Disqualified
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
    allContactStatuses: state.allContactStatuses.contactStatuses,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default withRouter(connect(MSP, MDP)(LeadFunnel));
