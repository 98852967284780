import React, { Component } from "react";
import "../sass/Tasks.scss";
import DropdownTask from "./Dropdown/DropdownTask";
import ReactTooltip from "react-tooltip";
import moment from "moment";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../redux";

class Task extends Component {
  state = {
    name: "",
    time: "",
    detail: "",
    icon: "",
    showTaskModal: false,
  };
  setShowTaskModal = (decision) => {
    this.setState({
      showTaskModal: decision,
    });
  };

  render() {
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    return (
      <div
        className={`schedule__items ${
          this.props.fromColor && "coloredTasks-" + this.props.entity_type
        }`}
        data-tip={
          this.props.fromColor ? this.props.name : "Click to complete task"
        }
        data-for={this.props.name + this.props.typeOfTask}
      >
        <ReactTooltip
          multiline={true}
          id={this.props.name + this.props.typeOfTask}
        />
        {this.props.showDropdown === true && (
          <span style={{ position: "absolute", left: "93%", top: "-30%" }}>
            <DropdownTask
              account_id={this.props.account_id}
              carrier_id={this.props.carrier_id}
              showTaskModal={this.state.showTaskModal}
              setShowTaskModal={this.setShowTaskModal}
              name={this.props.name}
              time={this.props.time}
              detail={this.props.detail}
              start_time={this.props.start_time}
              end_time={this.props.end_time}
              date={this.props.date}
              task_id={this.props.task_id}
              opportunityName={this.props.opportunityName}
              accountName={this.props.accountName}
              carrierName={this.props.carrierName}
              contactName={this.props.contactName}
              category_id={this.props.category_id}
              opportunityId={this.props.opportunityId}
              contactId={this.props.contactId}
              getTasks={this.props.getTasks}
              assignedTo={this.props.assignedTo}
              users={this.props.users}
              opportunities={this.props.opportunities}
              contacts={this.props.contacts}
              typeOfTask={this.props.typeOfTask}
            />
          </span>
        )}
        <div
          onClick={() =>
            this.props.typeOfTask === "account"
              ? this.props.makeDecision(
                  this.props.task_id,
                  this.props.category_id,
                  this.props.name,
                  this.props.opportunityId,
                  this.props.contactId
                )
              : this.props.typeOfTask === "carrier"
              ? this.props.makeDecision(
                  this.props.task_id,
                  this.props.category_id,
                  this.props.name,
                  this.props.opportunityId,
                  this.props.contactId
                )
              : this.props.typeOfTask === "contact"
              ? this.props.makeDecision(
                  this.props.task_id,
                  this.props.category_id,
                  this.props.name,
                  this.props.opportunityId
                )
              : this.props.typeOfTask === "opportunity"
              ? this.props.makeDecision(
                  this.props.task_id,
                  this.props.category_id,
                  this.props.name,
                  this.props.opportunityId,
                  this.props.contactId
                )
              : null
          }
        >
          <span className="com-1">
            <img
              src={require(`../images/${this.props.icon}.svg`)}
              alt=""
              width={18}
            />
          </span>
          <div className="schedule__heading">
            <span className="name">
              {this.props.name.length > 30
                ? this.props.name.substring(0, 28) + `   ...`
                : this.props.name}
            </span>
            <div className="schedule__date">
              {moment(this.props.date).format(dateFormat)}
            </div>
            {this.props.time ? (
              <span className="time">{this.props.time}</span>
            ) : (
              ""
            )}
          </div>
          <div className="schedule__details">
            {this.props.opportunityName !== null ? (
              <span>
                {this.props.opportunityName !== null &&
                this.props.opportunityName.length > 22
                  ? this.props.opportunityName.substring(0, 19) + "..."
                  : this.props.opportunityName}{" "}
                <span className="schedule__details-sub"> Deal</span>
              </span>
            ) : this.props.contactName !== null &&
              this.props.contactName !== undefined ? (
              <span>
                {this.props.contactName !== null &&
                this.props.contactName.length > 22
                  ? this.props.contactName.substring(0, 19) + "..."
                  : this.props.contactName}{" "}
                <span className="schedule__details-sub"> Contact</span>
              </span>
            ) : this.props.carrierName !== null &&
              this.props.carrierName !== undefined ? (
              <span>
                {this.props.carrierName !== null &&
                this.props.carrierName.length > 22
                  ? this.props.carrierName.substring(0, 19) + "..."
                  : this.props.carrierName}{" "}
                <span className="schedule__details-sub"> Carrier</span>{" "}
              </span>
            ) : (
              <span>
                {this.props.accountName !== null &&
                this.props.accountName.length > 22
                  ? this.props.accountName.substring(0, 19) + "..."
                  : this.props.accountName}{" "}
                <span className="schedule__details-sub"> Acct.</span>{" "}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};
export default connect(MSP, MDP)(Task);
