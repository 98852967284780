import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardLoader from "../../Loaders/DashboardLoader";
import { Link } from "react-router-dom";

const ActivityMetrics = ({ userId }) => {
  const [filter, setFilter] = useState("last_7_days");
  const [metrics, setMetrics] = useState({});
  const [loader, setLoader] = useState(false);

  const allFilters = [
    { value: "all_time", label: "All time" },
    { value: "last_7_days", label: "Last 7 days" },
    { value: "last_14_days", label: "Last 14 days" },
    { value: "last_30_days", label: "Last 30 days" },
    { value: "last_60_days", label: "Last 60 days" },
    { value: "last_90_days", label: "Last 90 days" },
    { value: "last_365_days", label: "Last 365 days" },
  ];
  const allMetrics = [
    {
      label: "Avg. Activities per day",
      value: "average_activity_count",
      metricValue: "all_activities",
    },
    {
      label: "Avg. calls per day",
      value: "average_call_count",
      metricValue: "call_activities",
    },
    {
      label: "Avg emails per day",
      value: "average_email_count",
      metricValue: "email_activities",
    },
    {
      label: "Avg new accounts touched per week",
      value: "average_new_account_touched",
      metricValue: "average_new_account",
    },
    {
      label: "Avg accounts moved out per week",
      value: "average_accounts_moved_per_week",
      metricValue: "average_accounts_moved",
    },
    {
      label: "Total Lanes Added",
      value: "total_lanes_created",
      metricValue: "total_lanes_created",
    },
  ];

  const getMetrics = () => {
    setLoader(true);
    axios({
      method: "GET",
      url: `/charts/kpi-stats?user_ids=${userId}&duration=${filter}`,
    })
      .then((res) => {
        setMetrics(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getMetrics();
  }, [filter, userId]);

  // const formatNumber = (num) => {
  //   // Check if the number has decimal places
  //   if (num % 1 !== 0) {
  //     // If it has decimal places, format to two decimal points
  //     return num.toFixed(2);
  //   } else {
  //     // If it's a whole number, return it without decimal places
  //     return num;
  //   }
  // };
  return (
    <div className="card" style={{ paddingBottom: "3px" }}>
      <div className="funnel-heading">
        <h4 className="funnel-heading-sub-1">Activities Metrics</h4>
        <h6 className="funnel-heading-sub-c">
          <select
            className="funnel-select"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            {allFilters.map((fil) => (
              <option key={fil.value} value={fil.value}>
                {fil.label}
              </option>
            ))}
          </select>
        </h6>
      </div>
      {loader ? (
        <DashboardLoader />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            gridGap: "15px 10px",
          }}
        >
          {allMetrics.map((metric) => (
            <div
              style={{
                border: "1px solid #e3e3e3",
                padding: "0px 5px",
                borderRadius: "8px",
                width: "90%",
                position: "relative",
                height: "91px",
              }}
              key={metric.value}
            >
              <Link
                style={{
                  color: "#000",
                  position: "absolute",
                  top: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                }}
                to={{
                  pathname: `/activities-metrics-report`,
                  state: {
                    metrics: metric.metricValue,
                  },
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize:
                      metric.value === "average_accounts_moved_per_week" ||
                      metric.value === "average_new_account_touched"
                        ? "12px"
                        : "13px",
                  }}
                >
                  {metric.label}
                </h2>
              </Link>
              <p
                style={{
                  fontSize: "28px",
                  fontWeight: "700",
                  margin: "0",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%)",
                  color: "#252A2D",
                }}
              >
                {/* {metrics?.[metric.value]
                  ? formatNumber(metrics?.[metric.value])
                  : "0"} */}
                {metrics?.[metric.value] ?? "0"}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActivityMetrics;
