import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";

class PasswordConfirmModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    password: "",
    isLoading: false,
    errorMessage: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  submitHandler = () => {
    if (this.state.password === "") {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    this.setState({
      isLoading: true,
    });
    axios({
      method: "POST",
      url: `/users/generate-mfa-otp`,
      data: {
        password: this.state.password,
      },
    })
      .then((res) => {
        this.props.handleCloseModal();
        this.props.handleVerifyOpenModal();
        this.props.setMfaToken(res.data.mfa_token);
        this.setState({
          isLoading: false,
          errorMessage: false,
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "35vh", top: "25%" }}
            >
              <div className="modal__header">
                <div className="modal__head">
                  <h2 className="modal__heading">
                    Enable 2 Factor Authentication
                  </h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <p>
                To enable 2 factor authentication, first confirm your password
              </p>
              <label>Password</label>
              <input
                name="password"
                value={this.state.password}
                onChange={this.changeHandler}
                type="password"
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true && this.state.password === ""
                  ? "Please enter password"
                  : null}
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  disabled={this.state.isLoading}
                  onClick={this.submitHandler}
                  type="button"
                  className="modal__confirm-button"
                >
                  {this.state.isLoading ? "Confirming" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default PasswordConfirmModal;
