import React, { Component } from "react";
import ReactModal from "react-modal";
import { CurrencyList } from "../constants/currencylist";
import { countriesList } from "../constants/countriesList";

class OnboardingInfoModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "86vh", top: "4%" }}
            >
              <div className="modal__header">
                <div className="modal__head">
                  <h2 className="modal__heading">Onboarding User</h2>
                </div>
              </div>
              <form onSubmit={this.props.submitHandler}>
                <label>Company Name</label>
                <input
                  name="name"
                  type="text"
                  value={this.props.name}
                  onChange={this.props.changeHandler}
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.props.errorMessage === true &&
                  (this.props.name === null || this.props.name === "")
                    ? "Company Name is required"
                    : null}
                </div>
                <label>Phone</label>
                <input
                  name="mobile"
                  value={this.props.mobile}
                  onChange={this.props.changeHandler}
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.props.errorMessage === true &&
                  (this.props.mobile === null || this.props.mobile === "")
                    ? "Phone number is required"
                    : null}
                </div>
                <label>Industry</label>
                <select
                  name="industry"
                  value={this.props.industry}
                  onChange={this.props.changeHandler}
                  style={{ width: "99%" }}
                >
                  <option value="Freight Brokerage">Freight Brokerage</option>
                  <option value="others">Other</option>
                </select>
                {this.props.industry === "others" && (
                  <>
                    <label>What industry is your business in?</label>
                    <input
                      name="industryOther"
                      value={this.props.industryOther}
                      onChange={this.props.changeHandler}
                    />
                  </>
                )}
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.props.errorMessage === true &&
                  (this.props.industry === null ||
                    this.props.industry === "" ||
                    (this.props.industry === "others" &&
                      this.props.industryOther === ""))
                    ? "Industry is required"
                    : null}
                </div>
                <label>Employee Size</label>
                <select
                  name="employeeSize"
                  value={this.props.employeeSize}
                  onChange={this.props.changeHandler}
                  style={{ width: "99%" }}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  <option value="4">1-4</option>
                  <option value="9">5-9</option>
                  <option value="19">10-19</option>
                  <option value="49">20-49</option>
                  <option value="99">50-99</option>
                  <option value="249">100-249</option>
                  <option value="499">250-499</option>
                  <option value="500">500+</option>
                </select>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.props.errorMessage === true &&
                  (this.props.employeeSize === null ||
                    this.props.employeeSize === "" ||
                    this.props.employeeSize === undefined)
                    ? "Please select number of employees in your company"
                    : null}
                </div>
                <label>Currency</label>
                <select
                  name="currency"
                  value={this.props.currency}
                  onChange={this.props.changeHandler}
                  style={{ width: "99%" }}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {CurrencyList?.map((currency) => (
                    <option key={currency.id} value={currency.code}>
                      {currency.code}
                    </option>
                  ))}
                </select>
                <label>Country</label>
                <select
                  value={this.props.country}
                  onChange={this.props.changeHandler}
                  name="country"
                  style={{ width: "99%" }}
                >
                  {countriesList?.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                <label>Date Format</label>
                <select
                  value={this.props.dateFormat}
                  onChange={this.props.changeHandler}
                  name="dateFormat"
                  style={{ width: "99%" }}
                >
                  <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                  <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                </select>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.props.errorMessage === true &&
                  (this.props.currency === null ||
                    this.props.currency === "" ||
                    this.props.currency === undefined)
                    ? "Please select default currency for your company"
                    : null}
                </div>
                <div className="modal__buttons">
                  <button
                    type="submit"
                    className="modal__addButton"
                    style={{
                      position: "absolute",
                      bottom: "1px",
                      right: "15%",
                      padding: "2px 17px",
                      background: "linear-gradient(0deg, #61C99D, #61C99D)",
                      width: "80.22px",
                      height: "35px",
                    }}
                    disabled={this.props.loader}
                  >
                    {this.props.loader ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default OnboardingInfoModal;
