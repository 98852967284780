import React from "react";
import "../../sass/DropdownHeader.scss";
import ShowMore from "../../images/ShowMore.svg";
import TargetEditModal from "./../TargetsEditModal";

class DropdownTargets extends React.Component {
  state = {
    displayMenu: false,
    showModal: false,
  };

  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  handleOpenModal = () => {
    this.setState({ displayMenu: false, showModal: true });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    return (
      <div
        className="dropdownTask"
        ref={this.wrapperRef}
        style={{
          background: "transparent",
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      >
        <div
          className="button"
          style={{
            fontSize: "14px",
            width: "20px",
            height: "20px",
            background: "transparent",
          }}
          onClick={this.showDropdownMenu}
        >
          <img src={ShowMore} alt="V" width={14} />
        </div>

        {this.state.displayMenu ? (
          <ul>
            <li onClick={this.handleOpenModal}>
              <span
                className="active"
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                Edit Targets
              </span>
            </li>
          </ul>
        ) : null}
        <TargetEditModal
          showOpportunityModal={this.state.showModal}
          setShowOpportunityModal={this.handleOpenModal}
          handleCloseModal={this.handleCloseModal}
          userId={this.props.userId}
        />
      </div>
    );
  }
}

export default DropdownTargets;
