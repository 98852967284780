export const CONTACTS = [
  { label: "Full Name-Contact", key: "contact.full_name" },
  { label: "First Name-Contact", key: "contact.first_name" },
  { label: "Last Name-Contact", key: "contact.last_name" },
  { label: "Contact Owner", key: "contact.owner" },
  { label: "Contact Email", key: "contact.email" },
  { label: "Contact Phone", key: "contact.phone" },
  { label: "Address-Contact", key: "contact.address" },
  { label: "City-Contact", key: "contact.city" },
  { label: "State-Contact", key: "contact.state" },
  { label: "Zip-Contact", key: "contact.zip" },
  { label: "Country-Contact", key: "contact.country" },
];

export const ACCOUNTS = [
  { key: "account.full_name", label: "Full Name-Account" },
  { key: "account.first_name", label: "First Name-Account" },
  { key: "account.last_name", label: "Last Name-Account" },
  { key: "account.owner", label: "Account Owner" },
  { key: "account.email", label: "Account Email" },
  { key: "account.phone", label: "Account Phone" },
  { key: "account.address", label: "Address-Account" },
  { key: "account.city", label: "City-Account" },
  { key: "account.state", label: "State-Account" },
  { key: "account.zip", label: "Zip-Account" },
  { key: "account.country", label: "Country-Account" },
];

export const DEALS = [
  { key: "opportunity.closed_reason", label: "Closed Reason" },
  { key: "opportunity.owner", label: "Deal Owner" },
  { key: "opportunity.full_name", label: "Deal Name" },
  { key: "opportunity.revenue", label: "Revenue" },
  { key: "opportunity.email", label: "Deal Email" },
];
