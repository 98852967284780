import React from "react";
import "jodit";
import "../../sass/Jodit.scss";
import JoditEditor from "jodit-react";
import { BASE_URL } from "../../config";

export default class JoditBulkEmail extends React.Component {
  updateContent = (value) => {
    this.setState({ content: value });
  };
  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  config = {
    height: "inherit",
    maxHeight: "inherit",
    minHeight: "inherit",
    width: "100%",
    spellcheck: true,
    enableDragAndDropFileToEditor: true,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: true,
    toolbarButtonSize: "small",
    tabIndex: 1,
    addNewLine: false,
    allowResizeY: false,
    buttons:
      this.props.isBulk === true
        ? [
            "bold",
            "italic",
            "underline",
            "|",
            "source",
            "|",
            "ul",
            "ol",
            "align",
            "|",
            "fontsize",
            "brush",
            "|",
            "image",
            "insertFile",
            "link",
          ]
        : this.props.isTemplate === true
        ? [
            "bold",
            "italic",
            "underline",
            "|",
            "source",
            "|",
            "ul",
            "ol",
            "align",
            "|",
            "fontsize",
            "brush",
            "|",
            "image",
            "link",
          ]
        : this.props.windowWidth > 600
        ? [
            "bold",
            "italic",
            "underline",
            "|",
            "source",
            "|",
            "ul",
            "ol",
            "|",
            "fontsize",
            "brush",
            "align",
            "|",
            "image",
            "insertFile",
            "link",
            "|",
            "insertSignature",
          ]
        : [
            "bold",
            "italic",
            "underline",
            "|",
            "source",
            "|",
            "ul",
            "ol",
            "align",
            "|",
            "image",
            "link",
          ],
    controls: {
      insertFile: {
        name: "insertFile",
        icon: "file",
        tooltip: "Insert File",
        exec: () => this.props.triggerInputFile(),
      },
      insertSignature: {
        name: "insertSignature",
        icon: "pencil",
        tooltip: "Insert Signature",
        exec: () => this.props.handleOpenSignatureModal(),
      },
    },
    link: {
      modeClassName: false,
      processVideoLink: false,
    },
    uploader: {
      url: `${BASE_URL}/email-campaigns/attachments`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      prepareData: (formData) => {
        let file = formData.getAll("files[0]")[0];
        formData.append("attachment", file);
        formData.delete("path");
        formData.delete("source");
        formData.delete("files[0]");
        return formData;
      },
      imagesExtensions: ["jpg", "png", "jpeg", "gif"],
      process: function (resp) {
        return resp;
        // files: resp.files,
        // path: resp.path,
        // baseurl: resp.baseurl,
        // error: resp.error,
        // message: resp.message,
        // };
      },

      defaultHandlerSuccess: function (data) {
        this.selection.insertImage(data.s3_url);
      },
      defaultHandlerError: (resp) => {
        console.log("jodit def error", resp);
      },
      error: (e) => {
        console.log("jodit main error", e);
        this.props.setUploaderErrorMessage &&
          this.props.setUploaderErrorMessage();
      },
      isSuccess: function (e) {
        console.log("Jodit isSuccess", e);
        return true;
      },
    },
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
    toolbarSticky: true,
    enter: "BR",
    placeholder: "Type your message...",
    zIndex: 2147483640,
  };
  render() {
    return (
      <JoditEditor
        editorRef={this.setRef}
        value={this.props.content}
        config={this.config}
        onChange={(content) => {
          this.props.updateContent(content);
          this.props.inputFocusHandler &&
            this.props.inputFocusHandler("content");
        }}
        onBlur={() =>
          this.props.inputFocusHandler &&
          this.props.inputFocusHandler("content")
        }
      />
    );
  }
}
