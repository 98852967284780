export const accountsFilters = [
  {
    value: "owner",
    label: "Account Owner",
    type: "account",
  },
  {
    value: "account_name",
    label: "Account Name",
    type: "account",
  },
  {
    value: "created_date",
    label: "Created Date",
    type: "account",
  },
  {
    value: "status",
    label: "Status",
    type: "account",
  },
  {
    value: "phone",
    label: "Phone",
    type: "account",
  },
  {
    value: "city",
    label: "City",
    type: "account",
  },
  {
    value: "state",
    label: "State",
    type: "account",
  },
  {
    value: "zip",
    label: "Zip",
    type: "account",
  },
  {
    value: "country",
    label: "Country",
    type: "account",
  },
  {
    value: "source",
    label: "Lead Source",
    type: "account",
  },
  {
    value: "disqualified_reason",
    label: "Disqualified Reason",
    type: "account",
  },
  {
    value: "last_edit_date",
    label: "Last Edit Date",
    type: "account",
  },
  {
    value: "age",
    label: "Age",
    type: "account",
  },
  {
    value: "last_activity_date",
    label: "Last Activity Date",
    type: "account",
  },
];
