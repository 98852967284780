export const carrierColumns = [
  {
    name: "Carrier Owners",
    key: "owners",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: false,
  },
  {
    name: "Carrier Name",
    key: "name",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: true,
  },
  {
    name: "MC#",
    key: "mc_number",
    selected: true,
    custom: false,
    type: "decimal",
    choices: [],
    editable: true,
  },
  {
    name: "DOT#",
    key: "dot_number",
    selected: true,
    custom: false,
    type: "decimal",
    choices: [],
    editable: true,
  },
  {
    name: "Description",
    key: "description",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: true,
  },
  {
    name: "Email",
    key: "email",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: true,
  },
  {
    name: "Phone",
    key: "phone",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: true,
  },
  {
    name: "Phone Ext",
    key: "phone_ext",
    selected: false,
    custom: false,
    type: "text",
    choices: [],
    editable: true,
  },
  {
    name: "Last Activity Date",
    key: "last_activity_date",
    selected: false,
    custom: false,
    type: "date",
    choices: [],
    editable: false,
  },
  {
    name: "Fleet Size",
    key: "fleet_size",
    selected: true,
    custom: false,
    type: "decimal",
    choices: [],
    editable: true,
  },
  {
    name: "Created Date",
    key: "created_at",
    selected: false,
    custom: false,
    type: "date",
    choices: [],
    editable: false,
  },
  {
    name: "Status",
    key: "status",
    selected: false,
    custom: false,
    type: "dropdown",
    choices: [],
    editable: true,
  },
  {
    name: "Home Base",
    key: "address",
    selected: false,
    custom: false,
    type: "text",
    choices: [],
    editable: true,
  },
  {
    name: "Last Edit Date",
    key: "last_updated",
    selected: false,
    custom: false,
    type: "date",
    choices: [],
    editable: false,
  },
];
