import React, { useEffect, useState } from "react";

import { BASE_URL } from "../../config";

// Redux
import { connect } from "react-redux";
import {
  authUserSet,
  authTokenSet,
  companyOnboardingSet,
  setActiveTabIndex,
} from "../../redux";

// 3rd party libraries
import axios from "axios";
import { Link } from "react-router-dom";
// import GoogleLogin from "react-google-login";
import {
  Container,
  Row,
  Col,
  // setConfiguration,
  Visible,
} from "react-grid-system";
import "../../sass/SignIn.scss";
import "../../sass/Grid.scss";
import SalesdashLogo from "../../images/SalesdashLogoLogin.png";
import { FORGOT_PASSWORD } from "../../constants/routes";

const FormikLogin = (props) => {
  const url_string = window.location.href;
  const url = new URL(url_string);
  const emailUrl = url.searchParams.get("email");
  const [otp, setOtp] = useState("");
  const [backupCode, setBackupCode] = useState("");
  const [showBackupCode, setShowBackupCode] = useState(false);
  const [userId, setUserId] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [email, setEmail] = useState(emailUrl === null ? "" : emailUrl);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageOtp, setErrorMessageOtp] = useState("");
  const [primaryMethod, setPrimaryMethod] = useState("");
  // const [maskedPhone, setMakedPhone] = useState("");
  useEffect(() => {
    localStorage.clear();
  });
  const [showPassword, setShowPassword] = useState(false);
  const [mfaToken, setMfaToken] = useState("");
  // const responseGoogle = (response, props) => {
  //   axios({
  //     method: "post",
  //     url: `${BASE_URL}/auth/google`,
  //     data: {
  //       access_token: response.accessToken,
  //     },
  //   })
  //     .then((res) => {
  //       localStorage.setItem("authUser", JSON.stringify(res.data.user));
  //       localStorage.setItem("authToken", JSON.stringify(res.data.token));
  //       localStorage.setItem(
  //         "companyOnboarding",
  //         JSON.stringify(res.data.company)
  //       );

  //       props.authUserSet(res.data.user);
  //       props.authTokenSet(res.data.token);
  //       props.companyOnboardingSet(res.data.company);

  //       // resetForm();
  //       props.history.push("/");
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log("err:", err?.response?.data);
  //       const errorMsg =
  //         err.response && err.response.status !== 500 ? (
  //           err.response.data.message
  //         ) : (
  //           <div>
  //             Some Technical error occurred!
  //             <br />
  //             Please try again after sometime.
  //           </div>
  //         );
  //       props.setErrors({
  //         password: errorMsg,
  //       });
  //     })
  //     .finally(() => props.setSubmitting(false));
  // };
  const submitHandler = (e) => {
    e.preventDefault();
    if (email === "" || email === null) {
      return setError(true);
    }
    if (password === "" || password === null) {
      return setError(true);
    }
    setLoading(true);
    axios({
      method: "POST",
      url: `${BASE_URL}/auth/login`,
      data: {
        email: email,
        site_name: window.location.host.split(".")[0],
        password: password,
      },
    })
      .then((res) => {
        if (res.data.two_fa_enabled === true) {
          setUserId(res.data.user_id);
          setMfaToken(res.data.mfa_token);
          setPrimaryMethod(res.data.primary_method);
          setLoading(false);
          return setShowOtp(true);
        }
        localStorage.setItem("authUser", JSON.stringify(res.data.user));
        localStorage.setItem("authToken", JSON.stringify(res.data.token));
        localStorage.setItem(
          "companyOnboarding",
          JSON.stringify(res.data.company)
        );
        var tour =
          res.data.user.tour === null
            ? {
                complete_task: false,
                create_deal: false,
                integrate_email: false,
                customize: false,
                import: false,
                create_team: false,
              }
            : res.data.user.tour;
        if (!res.data.user?.permissions.includes("crm:all:customizer")) {
          tour.customize = true;
        }
        if (!res.data.user?.permissions.includes("crm:importer")) {
          tour.import = true;
        }
        if (!res.data.user?.permissions.includes("crm:add:user")) {
          tour.create_team = true;
        }

        // Put the object into storage
        localStorage.setItem("tour", JSON.stringify(tour));

        props.authUserSet(res.data.user);
        props.authTokenSet(res.data.token);
        props.companyOnboardingSet(res.data.company);
        props.setActiveTabIndex(0);
        props.history.push("/active-tabs" + window.location.search);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err?.response?.data);
        setErrorMessage(
          err.response && err.response.status !== 500
            ? err.response.data.message
            : "Some Technical error occurred! Please try again after sometime."
        );
        // const errorMsg =
        //   err.response && err.response.status !== 500 ? (
        //     err.response.data.message
        //   ) : (
        //     <div>
        //       Some Technical error occurred!
        //       <br />
        //       Please try again after sometime.
        //     </div>
        //   );
      });
    // .finally(() => setSubmitting(false));
  };
  const loginWithOtp = (e) => {
    e.preventDefault();
    if (!showBackupCode && (otp === "" || otp === null)) {
      setErrorOtp(true);
      return;
    }
    if (showBackupCode && (backupCode === "" || backupCode === null)) {
      setErrorOtp(true);
      return;
    }
    setLoading(true);
    axios({
      method: "POST",
      url: `/auth/verify-otp`,
      data: {
        ...(showBackupCode
          ? { recovery_code: parseInt(backupCode) }
          : { verification_code: otp }),
        mfa_token: mfaToken,
        user_id: userId,
        verification_method: primaryMethod,
      },
    })
      .then((res) => {
        localStorage.setItem("authUser", JSON.stringify(res.data.user));
        localStorage.setItem("authToken", JSON.stringify(res.data.token));
        localStorage.setItem(
          "companyOnboarding",
          JSON.stringify(res.data.company)
        );
        var tour =
          res.data.user.tour === null
            ? {
                complete_task: false,
                create_deal: false,
                integrate_email: false,
                customize: false,
                import: false,
                create_team: false,
              }
            : res.data.user.tour;
        if (!res.data.user?.permissions.includes("crm:all:customizer")) {
          tour.customize = true;
        }
        if (!res.data.user?.permissions.includes("crm:importer")) {
          tour.import = true;
        }
        if (!res.data.user?.permissions.includes("crm:add:user")) {
          tour.create_team = true;
        }

        // Put the object into storage
        localStorage.setItem("tour", JSON.stringify(tour));

        props.authUserSet(res.data.user);
        props.authTokenSet(res.data.token);
        props.companyOnboardingSet(res.data.company);
        props.setActiveTabIndex(0);
        props.history.push("/active-tabs");
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err.response);
        setErrorMessageOtp(
          err.response && err.response.status === 401
            ? "The code you entered is wrong. Please recheck and try again."
            : err.response && err.response.status !== 500
            ? err.response.data.message
            : "Some Technical error occurred! Please try again after sometime."
        );
      });
  };

  return (
    <div className="signin">
      <Container>
        <Row>
          <Col xl={6} lg={6}>
            <div className="signin__left">
              <div className="signin__form">
                <h2 className="signin__heading">Log in to Salesdash</h2>
                <form onSubmit={showOtp ? loginWithOtp : submitHandler}>
                  {/* <div className='form-group'> */}
                  {/* <GoogleLogin
                    className="signin__button"
                    clientId="431435031827-jfjmdoft0ctit8gemgmiigmsd5ko2k2u.apps.googleusercontent.com"
                    buttonText="Login with Google"
                    onSuccess={response=>responseGoogle(response,props)}
                    onFailure={response=>responseGoogle(response,props)}
                    cookiePolicy={"single_host_origin"}
                  />
                  <div className="OR">OR</div> */}
                  {showOtp &&
                    (showBackupCode ? (
                      <p>
                        Please enter recovery code in case you don't have access
                        to your mobile number anymore
                        <br />
                        to access Salesdash
                      </p>
                    ) : primaryMethod === "SMS" ? (
                      <p>
                        A text message with a 6-digit verification code has been
                        sent <br /> to your phone number
                      </p>
                    ) : (
                      <p>
                        Please enter the 6-digit verification code from your
                        security app.
                      </p>
                    ))}
                  {showOtp ? (
                    <>
                      <label htmlFor="otpValue" className="signin__label">
                        Enter code
                      </label>
                      {showBackupCode ? (
                        <div>
                          <input
                            id="otpValue"
                            name="backupCode"
                            placeholder="Enter your recovery code"
                            className="signin__input"
                            autoFocus
                            value={backupCode}
                            onChange={(e) => setBackupCode(e.target.value)}
                            type="number"
                          />
                          <div
                            className="text-danger"
                            style={{
                              color: "#F36363",
                              fontSize: "12px",
                              marginBottom: "5px",
                            }}
                          >
                            {errorOtp &&
                              (backupCode === "" || backupCode === null) &&
                              "Please enter your backup code"}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <input
                            id="otpValue"
                            name="otp"
                            placeholder="Enter your 6 digit code"
                            className="signin__input"
                            autoFocus
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            type="number"
                          />
                          <div
                            className="text-danger"
                            style={{
                              color: "#F36363",
                              fontSize: "12px",
                              marginBottom: "5px",
                            }}
                          >
                            {errorOtp &&
                              (otp === "" || otp === null) &&
                              "Please enter your verification code"}
                          </div>
                        </div>
                      )}
                      <div
                        className="text-danger"
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {errorMessageOtp}
                      </div>
                      {showBackupCode ? (
                        <button
                          type="button"
                          onClick={() => {
                            setShowBackupCode(false);
                            setErrorMessageOtp("");
                            setErrorOtp(false);
                          }}
                          className="forgot-password"
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          Go back to enter 6 digit verification code
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            setShowBackupCode(true);
                            setErrorMessageOtp("");
                            setErrorOtp(false);
                          }}
                          className="forgot-password"
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          Don't have access to the code?{" "}
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <label htmlFor="emailaddress" className="signin__label">
                        Email
                      </label>
                      <input
                        id="emailaddress"
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="signin__input"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={showOtp}
                      />
                      <div
                        className="text-danger"
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {error &&
                          (email === "" || email === null) &&
                          "Please enter your email address"}
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="signin__label">
                          Password
                        </label>
                        <div style={{ display: "flex" }}>
                          <input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Enter your password"
                            className="signin__input"
                            autoFocus
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={showOtp}
                          />
                          <button
                            onClick={() => setShowPassword(!showPassword)}
                            className="showpassword-button"
                            type="button"
                          >
                            {showPassword ? (
                              <i
                                className="fa fa-eye-slash"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            )}
                          </button>
                        </div>
                        <div
                          className="text-danger"
                          style={{
                            color: "#F36363",
                            fontSize: "12px",
                            marginBottom: "5px",
                          }}
                        >
                          {error &&
                            (password === "" || password === null) &&
                            "Please enter your password"}
                        </div>
                      </div>
                      <div
                        className="text-danger"
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {errorMessage}
                      </div>
                    </>
                  )}
                  <button
                    className="signin__button-1"
                    disabled={loading}
                    type="submit"
                  >
                    <i className="mdi mdi-login"></i> Log In{" "}
                    {loading ? "..." : ""}
                  </button>
                </form>
                <Link to={FORGOT_PASSWORD} className="forgot-password">
                  Forgot Password?
                </Link>
              </div>
            </div>
          </Col>
          <Visible lg xl xxl>
            <Col sm={6} lg={6}>
              <div className="grad">
                <img
                  src={SalesdashLogo}
                  alt=""
                  width="600px"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                />
              </div>
            </Col>
          </Visible>
        </Row>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    authTokenSet: (authTokenData) => dispatch(authTokenSet(authTokenData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(null, mapDispatchToProps)(FormikLogin);
