import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";

class RedirectToBillingModal extends Component {
  state = {
    showModal: true,
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const superAdminPara =
      "This import would put you over your records credit limit for your current pricing plan. To complete this import, you will need to purchase more records storage from the add-on section in your Billing page.";
    const nonSuperAdminPara =
      "This import is unable to be completed because it would cause you to go above your records credit limit. Please contact your superadmin to purchase more records storage. Contact us at info@salesdashcrm.com with questions.";
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "32vh", top: "23%", textAlign: "center" }}
            >
              <div className="modal__header" style={{ textAlign: "left" }}>
                <div className="modal__head">
                  <h2
                    className="modal__heading"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Credit Limit Exceeded
                  </h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    height: "12px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label
                style={{
                  fontSize: "16px",
                  color: "#0C0D0D",
                  margin: "20px 0",
                  textAlign: "left",
                }}
              >
                {this.props.userRole === "SUPERADMIN"
                  ? superAdminPara
                  : nonSuperAdminPara}
              </label>
              {this.props.userRole === "SUPERADMIN" && (
                <div style={{ marginTop: "53px" }}>
                  <Link to="/payment">
                    <button
                      className="button-md"
                      style={{
                        height: "48px",
                        fontSize: "18px",
                      }}
                    >
                      View Billing
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default RedirectToBillingModal;
