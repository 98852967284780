import {
  FETCH_CONTACT_STATUSES_REQUEST,
  FETCH_CONTACT_STATUSES_SUCCESS,
  FETCH_CONTACT_STATUSES_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllContactStatusesRequest = () => {
  return {
    type: FETCH_CONTACT_STATUSES_REQUEST,
  };
};
const fetchAllContactStatusesSuccess = (data) => {
  return {
    type: FETCH_CONTACT_STATUSES_SUCCESS,
    payload: data,
  };
};
const fetchAllContactStatusesFailure = (err) => {
  return {
    type: FETCH_CONTACT_STATUSES_FAILURE,
    payload: err,
  };
};

// Fetch All ContactStatuses
export const fetchAllContactStatuses = () => {
  return (dispatch) => {
    dispatch(fetchAllContactStatusesRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/contacts/statuses`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.contact_statuses;
        dispatch(fetchAllContactStatusesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllContactStatusesFailure(errorMsg));
      });
  };
};
