import React from "react";
import { Link } from "react-router-dom";

function SubscriptionNav(props) {
  return (
    <div
      style={{
        background: "#FEF1E1",
        height: "20px",
        color: "#D7461F",
        position: "fixed",
        zIndex: "9999998",
        width: "100vw",
        textAlign: "center",
        fontSize: "14px",
        top: "0",
      }}
    >
      {props.status === "past_due" && props.role === "SUPERADMIN" ? (
        <span>
          Please update your billing information to reactivate your Salesdash
          account.{" "}
          <Link to="/payment" style={{ color: "#D7461F", fontWeight: "500" }}>
            Go to billing page.
          </Link>
        </span>
      ) : props.status === "inactive" && props.role === "SUPERADMIN" ? (
        <span>
          Your trial has expired. To continue using Salesdash, please{" "}
          <Link to="/payment" style={{ color: "#D7461F", fontWeight: "500" }}>
            choose your plan.
          </Link>
        </span>
      ) : (
        <span>
          Your Salesdash account is currently inactive. Please contact your
          administrator.
        </span>
      )}
    </div>
  );
}

export default SubscriptionNav;
