import React from "react";
import Rec1 from "../../../images/Funnel10Rec1.svg";
import Rec2 from "../../../images/Funnel10Rec2.svg";
import Rec3 from "../../../images/Funnel10Rec3.svg";
import Rec4 from "../../../images/Funnel10Rec4.svg";
import Rec5 from "../../../images/Funnel10Rec5.svg";
import Rec6 from "../../../images/Funnel10Rec6.svg";
import Rec7 from "../../../images/Funnel10Rec7.svg";
import Rec8 from "../../../images/Funnel10Rec8.svg";
import Rec9 from "../../../images/Funnel10Rec9.svg";
import Rec10 from "../../../images/Funnel10Rec10.svg";
import ReactTooltip from "react-tooltip";
import { Row, Col, Hidden } from "react-grid-system";
import { withRouter } from "react-router-dom";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../../redux";

function LeadFunnelTen(props) {
  return (
    <div className="funnel-chart">
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "29.5%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[0]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[0]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[0]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[0]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[0]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[0]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec1}
                alt=""
                data-tip={`${props?.sales_funnel[0]?.status}: ${props?.sales_funnel[0]?.count}`}
                style={{ marginBottom: "-1.6px", marginLeft: "-3px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[0]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[0]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "33%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[1]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[1]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[1]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[1]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[1]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[1]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec2}
                alt=""
                data-tip={`${props?.sales_funnel[1]?.status}: ${props?.sales_funnel[1]?.count}`}
                style={{ marginBottom: "-2.6px", marginLeft: "-2px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[1]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[1]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "36%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[2]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[2]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[2]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[2]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[2]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[2]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec3}
                alt=""
                data-tip={`${props?.sales_funnel[2]?.status}: ${props?.sales_funnel[2]?.count}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[2]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[2]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "39%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[3]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[3]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[3]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[3]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[3]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[3]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec4}
                alt=""
                data-tip={`${props?.sales_funnel[3]?.status}: ${props?.sales_funnel[3]?.count}`}
                style={{ marginBottom: "-2.6px", marginLeft: "-1px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[3]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[3]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "42%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[4]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[4]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[4]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[4]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[4]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[4]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec5}
                alt=""
                data-tip={`${props?.sales_funnel[4]?.status}: ${props?.sales_funnel[4]?.count}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[4]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[4]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "45%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[5]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[5]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[5]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[5]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[5]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[5]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec6}
                alt=""
                data-tip={`${props?.sales_funnel[5]?.status}: ${props?.sales_funnel[5]?.count}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[5]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[5]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "48%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[6]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[6]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[6]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[6]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[6]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[6]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec7}
                alt=""
                data-tip={`${props?.sales_funnel[6]?.status}: ${props?.sales_funnel[6]?.count}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[6]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[6]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "51%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[7]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[7]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[7]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[7]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[7]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[7]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec8}
                alt=""
                data-tip={`${props?.sales_funnel[7]?.status}: ${props?.sales_funnel[7]?.count}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[7]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[7]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "54%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[8]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[8]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[8]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[8]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[8]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[8]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec9}
                alt=""
                data-tip={`${props?.sales_funnel[8]?.status}: ${props?.sales_funnel[8]?.count}`}
                style={{ marginBottom: "-2.6px", marginLeft: "-2px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[8]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[8]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "14%",
                right: "0%",
                top: "50%",
                width: "59%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[9]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[9]?.status}`,
                    blank: true,
                    status: props?.sales_funnel[9]?.status,
                    userId: props?.userId,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "leadFunnel",
                    id: `${props?.userId}${props?.sales_funnel[9]?.status}LeadFunnel${props.statusType}`,
                    name: `${props?.sales_funnel[9]?.status}`,
                    userId: props?.userId,
                    status: props?.sales_funnel[9]?.status,
                    userName: props.userName,
                    statusType: props.statusType,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec10}
                alt=""
                data-tip={`${props?.sales_funnel[9]?.status}: ${props?.sales_funnel[9]?.count}`}
                style={{ marginTop: "-1px", marginLeft: "-2px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[9]?.count}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "16%",
                  right: "0",
                }}
              >
                {props?.sales_funnel[9]?.status}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
    </div>
  );
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default withRouter(connect(MSP, MDP)(LeadFunnelTen));
