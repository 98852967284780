import ReactModal from "react-modal";

const SaveEmailModal = ({
  showModal,
  handleCloseModal,
  submitHandler,
  modalHeight,
  modalTop,
  loading,
  email,
  changeHandler,
  error,
}) => {
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Save Email</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <p>Please add an email to start the sequence.</p>
            <form onSubmit={(e) => submitHandler(e)}>
              <label>Email</label>
              <input
                name="email"
                value={email}
                onChange={(e) => changeHandler(e)}
              />
              {error && (
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  Please enter a valid email
                </div>
              )}
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="modal__confirm-button"
                  disabled={loading || email === "" || email === null}
                  style={
                    loading || email === "" || email === null
                      ? { background: "#E3E3E3", color: "#A7ABAA" }
                      : {}
                  }
                >
                  {loading ? "Saving" : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default SaveEmailModal;
