import React, { useEffect, useState } from "react";
import axios from "axios";
import CreateWebhooksModal from "./CreateWebhooksModal";
import EditWebhooksModal from "./EditWebhooksModal";
import DeleteWebhooksModal from "./DeleteWebhooksModal";
import moment from "moment";

const allStatuses = [
  { label: "Create Account", value: "account.created" },
  { label: "Update Account", value: "account.updated" },
  { label: "Delete Account", value: "account.deleted" },
  { label: "Create Carrier", value: "carrier.created" },
  { label: "Update Carrier", value: "carrier.updated" },
  { label: "Delete Carrier", value: "carrier.deleted" },
  { label: "Create Contact", value: "contact.created" },
  { label: "Update Contact", value: "contact.updated" },
  { label: "Delete Contact", value: "contact.deleted" },
  { label: "Create Deal", value: "opportunity.created" },
  { label: "Update Deal", value: "opportunity.updated" },
  { label: "Create Activity", value: "activity.created" },
];

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#62ca9d",
    margin: "5px auto",
    boxShadow: "none",
    ":active": {
      ...styles[":active"],
      border: "2px solid #62ca9d",
      outline: "#62ca9d",
    },
    ":hover": {
      ...styles[":hover"],
      border: "1px solid #62ca9d",
    },
    ":focus": {
      ...styles[":hover"],
      border: "1px solid #62ca9d",
    },
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

const WebhooksList = () => {
  const [showWebhooksModal, setShowWebhooksModal] = useState(false);
  const [showEditWebhooksModal, setShowEditWebhooksModal] = useState(false);
  const [showDeleteWebhooksModal, setShowDeleteWebhooksModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [webhooksLoading, setWebhooksLoading] = useState(false);
  const [webhooksEditLoading, setWebhooksEditLoading] = useState(false);
  const [webhooksDeleteLoading, setWebhooksDeleteLoading] = useState(false);
  const [webhooksList, setWebhooksList] = useState([]);
  const [selectedWebhook, setSelectedWebhook] = useState({});

  const tableHeaders = [
    "Webhooks URL",
    "Description",
    "Created By",
    "Events",
    "Secret",
    "status",
    "Created Date",
    "Action",
  ];

  const getAllWebhooks = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `/webhooks/subscriptions`,
    }).then((res) => {
      console.log("res check", res.status === 200);
      setWebhooksList(res.data.webhooks);
      setLoading(false);
    });
  };
  useEffect(() => {
    getAllWebhooks();
  }, []);
  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ marginBottom: "0px" }}>
          Webhooks
          <button
            className="button-md"
            style={{ float: "right" }}
            type="button"
            onClick={() => setShowWebhooksModal(true)}
          >
            Create Webhooks
          </button>
        </h2>
        <p> </p>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              {tableHeaders.map((headers) => (
                <th key={headers}>{headers}</th>
              ))}
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {webhooksList?.map((webhook) => (
              <tr>
                <td>{webhook.webhook_url}</td>
                <td>{webhook.description}</td>
                <td>{webhook.created_by}</td>
                <td>
                  {webhook.events
                    ?.map((eve) => {
                      const eventLabel = allStatuses.find(
                        (stat) => stat.value === eve
                      )?.label;
                      return eventLabel;
                    })
                    ?.join(", ")}
                </td>
                <td
                  style={{
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {webhook.secret}
                </td>
                <td style={{ textTransform: "capitalize" }}>
                  {webhook.status}
                </td>
                <td>{moment(webhook.created_at).format("L")}</td>
                <td>
                  <button
                    type="button"
                    className="button-sm"
                    onClick={() => {
                      setSelectedWebhook(webhook);
                      setShowEditWebhooksModal(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedWebhook(webhook);
                      setShowDeleteWebhooksModal(true);
                    }}
                    className="button-sm"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <CreateWebhooksModal
        showModal={showWebhooksModal}
        handleCloseModal={() => setShowWebhooksModal(false)}
        modalHeight={`50`}
        modalTop={`15`}
        webhooksLoading={webhooksLoading}
        setWebhooksLoading={setWebhooksLoading}
        getAllWebhooks={getAllWebhooks}
        allStatuses={allStatuses}
        colourStyles={colourStyles}
      />
      <EditWebhooksModal
        showModal={showEditWebhooksModal}
        handleCloseModal={() => setShowEditWebhooksModal(false)}
        modalHeight={`50`}
        modalTop={`15`}
        webhooksLoading={webhooksEditLoading}
        setWebhooksLoading={setWebhooksEditLoading}
        getAllWebhooks={getAllWebhooks}
        webhookData={selectedWebhook}
        allStatuses={allStatuses}
        colourStyles={colourStyles}
      />
      <DeleteWebhooksModal
        showModal={showDeleteWebhooksModal}
        handleCloseModal={() => setShowDeleteWebhooksModal(false)}
        modalHeight={`30`}
        modalTop={`25`}
        webhooksLoading={webhooksDeleteLoading}
        setWebhooksLoading={setWebhooksDeleteLoading}
        getAllWebhooks={getAllWebhooks}
        webhookData={selectedWebhook}
      />
    </div>
  );
};

export default WebhooksList;
