import { useState } from "react";
import ReactModal from "react-modal";

const TagDealModal = ({
  showModal,
  handleCloseModal,
  submitHandler,
  loading,
  allUntaggedDeals,
  currencySymbol,
  getAllUnTaggedDeals,
}) => {
  const [taggedDeals, setTaggedDeals] = useState([]);
  const handleTagDeals = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      setTaggedDeals([
        ...taggedDeals.filter((com) => com !== ""),
        parseInt(value),
      ]);
    } else {
      setTaggedDeals(
        taggedDeals.filter((pc) => pc.toString() !== value.toString())
      );
    }
  };
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div className="modal__content" style={{ height: "75vh", top: "5%", width: "40vw", left: '30%' }}>
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Tag Deals to Carriers</h2>
                <p className="modal__heading-desc">
                  Tag existing deal(s) into a carrier
                </p>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <input
              placeholder="Search Deals"
              onChange={(e) => getAllUnTaggedDeals(e.target.value)}
            />
            <div
              style={{ height: "auto", maxHeight: "60vh", overflowY: "auto" }}
            >
              <table className="accountList__tableReport">
                <thead>
                  <tr>
                    <th style={{ width: "8px" }}></th>
                    <th>Name</th>
                    <th>Account</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {allUntaggedDeals?.map((deal) => {
                    const { id, name, revenue, account_name } = deal;
                    return (
                      <tr key={id}>
                        <td>
                          <input
                            value={id}
                            onChange={handleTagDeals}
                            type="checkbox"
                            style={{ display: "inline-block", width: "auto" }}
                          />
                        </td>
                        <td>{name}</td>
                        <td>{account_name}</td>
                        <td>
                          {currencySymbol}
                          {revenue}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={loading}
                onClick={() => {
                  submitHandler(taggedDeals);
                  setTaggedDeals([]);
                }}
              >
                {loading ? "Confirming" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default TagDealModal;
