import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import SendEmailIcon from "../../images/SendEmailIcon.svg";

class PostMarkAppVerificationModal extends Component {
  state = {
    showModal: true,
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  resendSignature = () => {
    axios({
      method: "POST",
      url: `/users/resend-postmark-signature`,
      data: {},
    })
      .then((res) => {
        NotificationManager.success(
          "Verification email was sent to your email"
        );
      })
      .catch((err) => NotificationManager.error("Email already verified"));
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "32vh", top: "18%", textAlign: "center" }}
            >
              <div className="modal__header" style={{ textAlign: "left" }}>
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "#61C99D",
                      transform: "translateY(-10px)",
                    }}
                  >
                    <img
                      src={SendEmailIcon}
                      alt="Icon"
                      width="16px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head">
                  <h2
                    className="modal__heading"
                    style={{ fontWeight: "500", fontSize: "18px" }}
                  >
                    Verify Email
                  </h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label
                style={{
                  fontSize: "16px",
                  color: "#0C0D0D",
                  margin: "20px 0",
                }}
              >
                Please verify your email in order to start sending bulk emails.
              </label>
              <button
                onClick={this.resendSignature}
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #3AAB7B",
                  padding: "10px 24px",
                  color: "#3AAB7B",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "150%",
                  textDecoration: "none",
                  cursor: "pointer",
                  marginTop: "15px",
                  outline: "none",
                  marginRight: "30px",
                }}
              >
                Resend Email
              </button>
              <button
                onClick={this.props.checkVerification}
                style={{
                  background: "#3AAB7B",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #3AAB7B",
                  padding: "10px 24px",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "150%",
                  textDecoration: "none",
                  cursor: "pointer",
                  marginTop: "15px",
                  outline: "none",
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default PostMarkAppVerificationModal;
