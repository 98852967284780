import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST,
  };
};
const fetchAllUsersSuccess = (data) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: data,
  };
};
const fetchAllUsersFailure = (err) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: err,
  };
};

// Fetch All Users
export const fetchAllUsers = () => {
  return (dispatch) => {
    dispatch(fetchAllUsersRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/users`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.users?.slice()?.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Ignore case for comparison
          const nameB = b.name.toUpperCase(); // Ignore case for comparison

          if (nameA < nameB) {
            return -1; // Negative value: a should come before b
          }
          if (nameA > nameB) {
            return 1; // Positive value: b should come before a
          }
          return 0; // Return 0 for elements considered equal
        });
        dispatch(fetchAllUsersSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllUsersFailure(errorMsg));
      });
  };
};
