import React, { Component } from "react";
import "../../sass/Reports.scss";
import "../../sass/DropdownHeader.scss";
import axios from "axios";
import moment from "moment";
import DeleteIcon from "../../images/DeleteIconRed.svg";
import ReportIcon from "../../images/ReportsLogo.svg";
import ColumnReportIcon from "../../images/ReportsColumnIcon.svg";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { Col, Row, setConfiguration } from "react-grid-system";
import { v4 as uuidv4 } from "uuid";
import { NotificationManager } from "react-notifications";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/DragHandler.svg";
import { CurrencyList } from "../../constants/currencylist";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import { usStatesList } from "../../constants/usStatesList";

setConfiguration({ gutterWidth: "0" });

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ marginRight: "10px", cursor: "grab" }}
    />
  </span>
));

const SortableItem = sortableElement(({ column, sortButtonClickHandler }) => (
  <th>
    <Row>
      <Col lg={9} xl={9}>
        <DragHandle />
        {column.name}
      </Col>
      <Col lg={3} xl={3}>
        <button
          type="button"
          style={{
            background: "transparent",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => sortButtonClickHandler(column)}
        >
          <i className="fa fa-long-arrow-up"></i>
          <i className="fa fa-long-arrow-down"></i>
        </button>
      </Col>
    </Row>
  </th>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <thead>{children}</thead>;
});

class ReportsDealSave extends Component {
  state = {
    filter: "",
    operator: "",
    filter_rules: [
      {
        id: uuidv4(),
        attribute: "owner",
        operator: "only_me",
        value: null,
        type: null,
      },
    ],
    filter_rules_isIn: [
      {
        id: uuidv4(),
        attribute: "owner",
        operator: "only_me",
        value: null,
        type: null,
      },
    ],
    reportsTable: [],
    showColumn: false,
    displayMenu: false,
    errorMessage: false,
    sortKey: null,
    sortDirection: false,
    customFields: [],
    isLoading: false,
    isApplying: false,
    access: "everyone",
    columnsArray: [
      {
        name: "Owner",
        key: "opportunity_owner",
        selected: true,
        custom: false,
      },
      { name: "Deal Name", key: "name", selected: true, custom: false },
      { name: "Stage", key: "stage_name", selected: true, custom: false },
      { name: "Revenue", key: "revenue", selected: true, custom: false },
      { name: "Next Step", key: "next_step", selected: true, custom: false },
      {
        name: "Created Date",
        key: "created_date",
        selected: false,
        custom: false,
      },
      {
        name: "Close Date",
        key: "close_date",
        selected: false,
        custom: false,
      },
      { name: "Email", key: "email", selected: false, custom: false },
      { name: "Phone", key: "phone", selected: false, custom: false },
      { name: "City", key: "city", selected: false, custom: false },
      { name: "State", key: "state", selected: false, custom: false },
      { name: "Zip", key: "zip", selected: false, custom: false },
      { name: "Reason", key: "close_reason", selected: false, custom: false },
      {
        name: "Account Name",
        key: "account_name",
        selected: false,
        custom: false,
      },
      {
        name: "Description",
        key: "description",
        selected: false,
        custom: false,
      },
      { name: "Age", key: "age", selected: false, custom: false },
      { name: "Stage Age", key: "stage_age", selected: false, custom: false },
      {
        name: "Prev. Stage",
        key: "prev_stage",
        selected: false,
        custom: false,
      },
      {
        name: "Stage Change Date",
        key: "stage_changed_at",
        selected: false,
        custom: false,
      },
      { name: "Country", key: "country", selected: false, custom: false },
      { name: "Source", key: "source", selected: false, custom: false },
      {
        name: "Last Edit Date",
        key: "last_edit_date",
        selected: false,
        custom: false,
      },
      {
        name: "Last Activity Date",
        key: "last_activity_date",
        selected: false,
        custom: false,
      },
    ],
    stages: [],
    allFunnels: [],
  };
  wrapperRef = React.createRef();
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false });
  };
  accessChange = () => {
    const checkboxView = document.getElementById("checkboxView");
    if (checkboxView.checked) {
      this.setState({ access: "everyone" });
    } else {
      this.setState({ access: "only_me" });
    }
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    axios({
      method: "POST",
      url: `/opportunities/reports?_limit=30&_page=1`,
      data: {
        filter_rules: this.state.filter_rules_isIn.map((rule) => {
          const { attribute, operator, value } = rule;
          return {
            attribute,
            operator,
            value,
          };
        }),
        sort_key: this.state.sortKey,
        sort_dir: this.state.sortDirection === true ? "desc" : "asc",
      },
    }).then((res) => {
      this.setState({
        reportsTable: res.data.opportunities,
      });
    });
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => funnel.is_default === true && funnel)
        .filter((funnel) => funnel !== false);
      this.setState({
        allFunnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages.map((stage) => {
          stage.destroy = false;
          return stage;
        }),
        funnelId: selectedFunnel[0].id,
      });
    });
    this.setState({
      customFields: this.props.allDealCustomFields,
    });
    const customField = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { label, name, id } = field;
            return {
              name: label,
              key: name,
              selected: false,
              custom: true,
              id,
            };
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const columnsArray = this.state.columnsArray;
    this.setState({
      columnsArray: columnsArray.concat(customFieldArray),
    });
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };
  funnelChangeHandler = (e) => {
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(value) && funnel;
        })
        .filter((funnel) => funnel !== false);
      this.setState({
        allFunnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages?.map((stage) => {
          stage.destroy = false;
          return stage;
        }),
        funnelId: value,
      });
    });
  };
  getReport = () => {
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      return rule;
    });
    this.setState({ isApplying: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/opportunities/reports?_limit=30&_page=1`,
        data: {
          filter_rules: this.state.filter_rules_isIn.map((rule) => {
            const { attribute, operator, value } = rule;
            return {
              attribute,
              operator,
              value,
            };
          }),
          sort_key: this.state.sortKey,
          sort_dir: this.state.sortDirection === true ? "desc" : "asc",
        },
      })
        .then((res) => {
          this.setState({
            reportsTable: res.data.opportunities,
            isApplying: false,
          });
        })
        .catch((err) => this.setState({ isApplying: false }));
    } else {
      this.setState({ isApplying: false });
    }
  };
  sortButtonClickHandler = (column) => {
    this.setState(
      {
        sortKey: column.custom ? column.id : column.key,
        sortDirection: !this.state.sortDirection,
      },
      this.sortHandler
    );
  };
  sortHandler = () => {
    axios({
      method: "POST",
      url: `/opportunities/reports?_limit=30&_page=1`,
      data: {
        filter_rules: this.state.filter_rules_isIn.map((rule) => {
          const { attribute, operator, value } = rule;
          return {
            attribute,
            operator,
            value,
          };
        }),
        sort_key: this.state.sortKey,
        sort_dir: this.state.sortDirection === true ? "desc" : "asc",
      },
    }).then((res) => {
      this.setState({
        reportsTable: res.data.opportunities,
      });
    });
  };
  changeType = (index, e) => {
    const { value } = e.target;
    const fieldType = this.state.customFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.find((field) => field.id === parseInt(value))
        );
      })
      .filter((data) => data !== undefined && data !== false);
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule.type = fieldType[0]?.type;
          rule.choices = fieldType[0]?.choices;
        }
        return rule;
      }),
      filter_rules_isIn: prevState.filter_rules_isIn.map((rule, i) => {
        if (index === i) {
          rule.type = fieldType[0]?.type;
          rule.choices = fieldType[0]?.choices;
        }
        return rule;
      }),
    }));
  };
  changeHandler = (index, e) => {
    const { name, value } = e.target;
    name === "attribute" && this.changeType(index, e);
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule[name] = value;
          if (
            (rule.operator === "contains" ||
              rule.operator === "not_contains") &&
            Array.isArray(rule.value)
          ) {
            rule.value = "";
          }
          if (
            rule.operator === "is_empty" ||
            rule.operator === "is_not_empty" ||
            rule.operator === "today" ||
            rule.operator === "yesterday" ||
            rule.operator === "this_week" ||
            rule.operator === "last_7_days" ||
            rule.operator === "last_14_days" ||
            rule.operator === "last_30_days" ||
            rule.operator === "greater_than_14_days" ||
            rule.operator === "greater_than_30_days" ||
            rule.operator === "greater_than_60_days" ||
            rule.operator === "this_month" ||
            rule.operator === "this_quarter" ||
            rule.operator === "next_quarter" ||
            rule.operator === "last_quarter" ||
            rule.operator === "this_year" ||
            rule.operator === "only_me" ||
            rule.operator === "my_team" ||
            rule.operator === "everyone" ||
            rule.operator === "last_year"
          ) {
            rule.value = null;
          }
        }
        return rule;
      }),
      filter_rules_isIn: prevState.filter_rules_isIn.map((rule, i) => {
        if (index === i) {
          rule[name] = value;
          if (
            (rule.operator === "contains" ||
              rule.operator === "not_contains") &&
            Array.isArray(rule.value)
          ) {
            rule.value = "";
          }
          if (
            rule.operator === "is_empty" ||
            rule.operator === "is_not_empty" ||
            rule.operator === "today" ||
            rule.operator === "yesterday" ||
            rule.operator === "this_week" ||
            rule.operator === "last_7_days" ||
            rule.operator === "last_14_days" ||
            rule.operator === "last_30_days" ||
            rule.operator === "greater_than_14_days" ||
            rule.operator === "greater_than_30_days" ||
            rule.operator === "greater_than_60_days" ||
            rule.operator === "this_month" ||
            rule.operator === "this_quarter" ||
            rule.operator === "next_quarter" ||
            rule.operator === "last_quarter" ||
            rule.operator === "this_year" ||
            rule.operator === "only_me" ||
            rule.operator === "my_team" ||
            rule.operator === "everyone" ||
            rule.operator === "last_year"
          ) {
            rule.value = null;
          }
        }
        return rule;
      }),
    }));
  };
  valueChangeHandler = (index, valueIndex, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          if (rule["value"] === null) {
            rule["value"] = [];
          }
          rule["value"][valueIndex] = value;
        }
        return rule;
      }),
      filter_rules_isIn: prevState.filter_rules_isIn.map((rule, i) => {
        if (index === i) {
          if (rule["value"] === null) {
            rule["value"] = [];
          }
          rule["value"][valueIndex] = value;
        }
        return rule;
      }),
    }));
  };
  isInChangeHandler = (index, selected) => {
    console.log("selected:", selected);
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    console.log("QWE:", selectedArray);

    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule["value"] = selected;
        }
        return rule;
      }),
      filter_rules_isIn: prevState.filter_rules_isIn.map((rule, i) => {
        if (index === i) {
          rule["value"] = selected?.map((option) => option.value);
        }
        return rule;
      }),
    }));
  };
  addFilter = () => {
    this.setState((prevState) => ({
      filter_rules: [
        ...prevState.filter_rules,
        { id: uuidv4(), attribute: "", operator: "", value: [] },
      ],
      filter_rules_isIn: [
        ...prevState.filter_rules_isIn,
        { id: uuidv4(), attribute: "", operator: "", value: [] },
      ],
    }));
  };
  deleteFilter = (index) => {
    this.setState(
      (prevState) => ({
        filter_rules: prevState.filter_rules.filter((rule, i) => i !== index),
        filter_rules_isIn: prevState.filter_rules_isIn.filter(
          (rule, i) => i !== index
        ),
      }),
      () => console.log("this.state.filter_rules", this.state.filter_rules)
    );
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return rule;
    });
    this.setState({ isLoading: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/reports`,
        data: {
          name: this.props.location.state.reportName,
          type: this.props.location.state.reportType,
          description: this.props.location.state.reportDescription,
          access: this.state.access,
          filters: this.state.filter_rules_isIn.map((rule) => {
            return {
              attribute: rule.attribute,
              operator: rule.operator,
              value: rule.value,
            };
          }),
          columns: this.state.columnsArray
            .filter((columns) => columns.selected === true)
            .map((columns) => (columns.custom ? columns.id : columns.key)),
        },
      })
        .then((res) => {
          let newTabData = {
            type: "report",
            id: res.data.reports.id,
            name: res.data.reports.name,
          };

          this.props.setActiveTabIndex(this.props.totalTabs + 1);
          this.props.setTab(newTabData);
          this.props.history.push("/active-tabs");
          console.log("Report created", res.data);
        })
        .catch((error) => this.setState({ isLoading: false }));
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(
      "reports save props",
      this.props,
      "reports save state",
      this.state
    );
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    const { columnsArray } = this.state;
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        minWidth: "200px",
        outline: "#C0EAD8",
        minHeight: "30px",
        padding: "5px auto",
        transform: "translateY(-10px)",
        marginRight: "12px",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#C0EAD8"
            : null,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#C0EAD8"),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "#C0EAD8",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
        fontSize: "13px",
      }),
    };
    return (
      <div className="reports">
        <div>
          <div className="customerInfo-icon-report">
            <img
              src={ReportIcon}
              alt=""
              width="17px"
              style={{ transform: "translateY(9px)" }}
            />
          </div>
          <div className="customerInfo-info">
            <h2 className="c-type">Report</h2>
            <h1 className="c-name">{this.props.location.state.reportName}</h1>
          </div>
          <div style={{ margin: "10px 0px" }}>
            <span
              className="general-heading"
              style={
                this.state.access === "only_me"
                  ? {
                      color: "#3AAB7B",
                      fontSize: "20px",
                    }
                  : {
                      color: "#A7ABAA",
                      fontSize: "20px",
                    }
              }
            >
              <i
                className="fa fa-lock"
                aria-hidden="true"
                style={{ transform: "translateY(3px)" }}
              ></i>{" "}
              &nbsp;
            </span>
            <label className="switch">
              <input
                type="checkbox"
                value={this.state.access}
                id="checkboxView"
                checked={this.state.access === "everyone"}
                onChange={this.accessChange}
              />
              <span className="slider round"></span>
            </label>
            <span
              className="general-heading"
              style={
                this.state.access === "everyone"
                  ? {
                      color: "#3AAB7B",
                      fontSize: "20px",
                      transform: "translateY(2px)",
                    }
                  : {
                      color: "#A7ABAA",
                      fontSize: "20px",
                      transform: "translateY(2px)",
                    }
              }
            >
              {" "}
              &nbsp;<i className="fa fa-users" aria-hidden="true"></i>
            </span>
          </div>
          <button
            className="button-md"
            type="button"
            onClick={this.submitHandler}
            style={{ position: "absolute", top: "15%", right: "5%" }}
            disabled={this.state.isLoading}
          >
            {this.state.isLoading ? "Saving ..." : "Save Report"}
          </button>
        </div>
        <form>
          <div className="reports-filter">
            <div>
              <h4 style={{ marginTop: "0px" }}>Filters</h4>
              <button
                type="button"
                className="button-md"
                style={{
                  marginTop: "0px",
                  float: "right",
                  transform: "translateY(-40px)",
                  marginRight: "30px",
                }}
                onClick={() => this.getReport()}
              >
                {this.state.isApplying ? "Applying ..." : "Apply Filter"}
              </button>
            </div>
            {this.state.filter_rules.map((rule, index) => (
              <div style={{ display: "flex" }} key={rule.id}>
                <div>
                  <label>Filter</label>
                  <select
                    name="attribute"
                    onChange={(e) => this.changeHandler(index, e)}
                    value={rule.attribute}
                  >
                    <option hidden>-Select-</option>
                    <option selected disabled>
                      -Select-
                    </option>
                    <optgroup label="Default Fields">
                      <option value="owner">Owner</option>
                      <option value="opportunity_name">Deal Name</option>
                      <option value="account_name">Account Name</option>
                      <option value="created_date">Created Date</option>
                      <option value="email">Email</option>
                      <option value="phone">Phone</option>
                      <option value="city">City</option>
                      <option value="state">State</option>
                      <option value="zip">Zip</option>
                      <option value="funnel">Funnel</option>
                      <option value="stage">Stage</option>
                      <option value="last_edit_date">Last Edit Date</option>
                      <option value="age">Age</option>
                      <option value="stage_age">Stage Age</option>
                      <option value="country">Country</option>
                      <option value="close_reason">Close Reason</option>
                      <option value="close_date">Close Date</option>
                      <option value="source">Source</option>
                      {/* <option value="last_edited_date">Last Edited Date</option> */}
                      <option value="description">Description</option>
                      <option value="revenue">Revenue</option>
                      <option value="last_activity_date">
                        Last Activity Date
                      </option>
                    </optgroup>
                    {this.state.customFields.map((group) => {
                      const { label, fields, meta } = group;
                      return (
                        (meta === null || meta.visible_in_form === true) && (
                          <optgroup label={label}>
                            {fields?.map((field) => {
                              const { id, label } = field;
                              return (
                                <option key={id} value={id}>
                                  {label}
                                </option>
                              );
                            })}
                          </optgroup>
                        )
                      );
                    })}
                  </select>
                  {this.state.errorMessage === true &&
                    rule.attribute === "" && (
                      <div
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        Filters is required field
                      </div>
                    )}
                </div>
                {rule.attribute === "stage" && (
                  <div>
                    <label>Select Funnel</label>
                    <select onChange={this.funnelChangeHandler}>
                      {this.state.allFunnels?.map((funnel) => (
                        <option key={funnel.id} value={funnel.id}>
                          {funnel.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {rule.attribute === "last_edit_date" ||
                rule.attribute === "close_date" ||
                rule.type === "date" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="this_week">This Week</option>
                      <option value="last_7_days">Last 7 days</option>
                      <option value="last_14_days">Last 14 days</option>
                      <option value="last_30_days">Last 30 days</option>
                      <option value="this_month">This Month</option>
                      <option value="this_quarter">This Quarter</option>
                      <option value="next_quarter">Next Quarter</option>
                      <option value="last_quarter">Last Quarter</option>
                      <option value="this_year">This Year</option>
                      <option value="last_year">Last Year</option>
                      <option value="custom_period">Custom Period</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "last_activity_date" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="this_week">This Week</option>
                      <option value="last_7_days">Last 7 days</option>
                      <option value="last_14_days">Last 14 days</option>
                      <option value="last_30_days">Last 30 days</option>
                      <option value="greater_than_14_days">
                        Greater than last 14 days
                      </option>
                      <option value="greater_than_30_days">
                        Greater than last 30 days
                      </option>
                      <option value="greater_than_60_days">
                        Greater than last 60 days
                      </option>
                      <option value="this_month">This Month</option>
                      <option value="this_quarter">This Quarter</option>
                      <option value="next_quarter">Next Quarter</option>
                      <option value="last_quarter">Last Quarter</option>
                      <option value="this_year">This Year</option>
                      <option value="last_year">Last Year</option>
                      <option value="custom_period">Custom Period</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.type === "checkbox" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      value={rule.operator}
                      onChange={(e) => this.changeHandler(index, e)}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is_not_empty">is true</option>
                      <option value="is_empty">is false</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.type === "decimal" || rule.type === "number" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      value={rule.operator}
                      onChange={(e) => this.changeHandler(index, e)}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="greater_than_equal">
                        equal or greater than
                      </option>
                      <option value="less_than_equal">
                        equal or less than
                      </option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "created_date" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      value={rule.operator}
                      onChange={(e) => this.changeHandler(index, e)}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="this_week">This Week</option>
                      <option value="last_7_days">Last 7 days</option>
                      <option value="last_14_days">Last 14 days</option>
                      <option value="last_30_days">Last 30 days</option>
                      <option value="this_month">This Month</option>
                      <option value="this_quarter">This Quarter</option>
                      <option value="next_quarter">Next Quarter</option>
                      <option value="last_quarter">Last Quarter</option>
                      <option value="this_year">This Year</option>
                      <option value="last_year">Last Year</option>
                      <option value="custom_period">Custom Period</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "stage" ||
                  rule.attribute === "funnel" ||
                  rule.attribute === "source" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "revenue" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                      <option value="contains">contains</option>
                      <option value="not_contains">does not contain</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "age" ||
                  rule.attribute === "stage_age" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "owner" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="only_me">My Records</option>
                      <option value="my_team">My Team's Records</option>
                      <option value="everyone">Everyone’s Records</option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                      <option value="contains">contains</option>
                      <option value="not_contains">does not contain</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                      <option value="contains">contains</option>
                      <option value="not_contains">does not contain</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                )}
                {rule.operator === "custom_period" ? (
                  <div style={{ display: "flex" }}>
                    <div>
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="value"
                        onChange={(e) => this.valueChangeHandler(index, 0, e)}
                      />
                    </div>
                    <div>
                      <label>End Date</label>
                      <input
                        type="date"
                        name="value"
                        onChange={(e) => this.valueChangeHandler(index, 1, e)}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label>&nbsp;</label>
                    {rule.operator === "is_empty" ||
                    rule.operator === "is_not_empty" ||
                    rule.operator === "today" ||
                    rule.operator === "yesterday" ||
                    rule.operator === "this_week" ||
                    rule.operator === "last_7_days" ||
                    rule.operator === "last_14_days" ||
                    rule.operator === "last_30_days" ||
                    rule.operator === "greater_than_14_days" ||
                    rule.operator === "greater_than_30_days" ||
                    rule.operator === "greater_than_60_days" ||
                    rule.operator === "this_month" ||
                    rule.operator === "this_quarter" ||
                    rule.operator === "next_quarter" ||
                    rule.operator === "last_quarter" ||
                    rule.operator === "this_year" ||
                    rule.operator === "only_me" ||
                    rule.operator === "my_team" ||
                    rule.operator === "everyone" ||
                    rule.operator === "last_year" ? (
                      <input
                        type="text"
                        name="value"
                        value={(rule.value = null)}
                        disabled={true}
                        onChange={(e) => this.changeHandler(index, e)}
                      />
                    ) : rule.attribute === "age" ||
                      rule.attribute === "stage_age" ? (
                      <input
                        type="number"
                        name="value"
                        value={rule.value}
                        onChange={(e) => this.changeHandler(index, e)}
                      />
                    ) : rule.attribute === "created_date" ||
                      rule.attribute === "last_edit_date" ||
                      rule.attribute === "last_activity_date" ||
                      rule.attribute === "close_date" ||
                      rule.type === "date" ? (
                      <input
                        type="date"
                        name="value"
                        value={rule.value}
                        onChange={(e) => this.changeHandler(index, e)}
                      />
                    ) : rule.operator === "is_in" ||
                      rule.operator === "is_not_in" ? (
                      rule.type === "dropdown" ||
                      rule.type === "multi_select_dropdown" ||
                      rule.type === "radio" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={rule?.choices?.map((choice) => {
                            const { value } = choice;
                            return { value, label: value };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : rule.attribute === "stage" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={this.state?.stages.map((stage) => {
                            const { id, name } = stage;
                            return { value: id, label: name };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : rule.attribute === "funnel" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={this.state?.allFunnels.map((funnel) => {
                            const { id, name } = funnel;
                            return { value: id, label: name };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : rule.attribute === "source" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={this.props.allAccountSources?.map(
                            (status) => {
                              return {
                                label: status.name,
                                value: status.name,
                              };
                            }
                          )}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : rule.attribute === "state" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={usStatesList?.map((status) => {
                            return {
                              label: status,
                              value: status,
                            };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : rule.attribute === "owner" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={this.props.allUsers?.map((user) => {
                            const { name } = user;
                            return { value: name, label: name };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : (
                        <CreatableSelect
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={rule.choices?.map((choice) => {
                            return { label: choice.value, value: choice.value };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      )
                    ) : (
                      <input
                        type="text"
                        name="value"
                        value={rule.value}
                        onChange={(e) => this.changeHandler(index, e)}
                      />
                    )}
                    {this.state.errorMessage === true &&
                      ((rule.operator === "is_in" &&
                        (rule.value === null ||
                          rule.value === "" ||
                          rule.value.length === 0)) ||
                        (rule.operator === "is_not_in" &&
                          (rule.value === null ||
                            rule.value === "" ||
                            rule.value.length === 0)) ||
                        (rule.operator === "contains" &&
                          (rule.value === null || rule.value === "")) ||
                        (rule.operator === "not_contains" &&
                          (rule.value === null || rule.value === "")) ||
                        (rule.operator === "greater_than" &&
                          (rule.value === null || rule.value === "")) ||
                        (rule.operator === "less_than" &&
                          (rule.value === null || rule.value === ""))) && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Value cannot be empty
                        </div>
                      )}
                  </div>
                )}
                <button
                  type="button"
                  onClick={() => this.deleteFilter(index)}
                  style={{
                    background: "#ffffff",
                    border: "none",
                    borderRadius: "28px",
                    outline: "none",
                    cursor: "pointer",
                    transform: "translateY(12px)",
                  }}
                >
                  <img src={DeleteIcon} alt="Delete" width="16px" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={this.addFilter}
              style={{
                border: "1px dashed #C5C5D3",
                borderRadius: "7px",
                marginTop: "25px",
                width: "109px",
                height: "35px",
              }}
            >
              + Add Filter
            </button>
          </div>
        </form>
        <div>
          <div style={{ display: "flex", marginBottom: "40px" }}>
            <div
              className="dropdownTask"
              ref={this.wrapperRef}
              style={{
                background: "transparent",
                width: "20px",
                marginLeft: "90vw",
              }}
            >
              <div
                className="button"
                style={{ fontSize: "14px", color: "black", width: "90px" }}
                onClick={this.showDropdownMenu}
              >
                <div
                  style={{
                    color: "#4BC893",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    border: "1px solid #4BC893",
                    padding: "10px 16px",
                    width: "90px",
                  }}
                >
                  <img src={ColumnReportIcon} alt="" width="14px" />
                  &nbsp; Columns
                </div>
              </div>

              {this.state.displayMenu ? (
                <ul
                  style={{
                    width: "260px",
                    background: "white",
                    paddingLeft: "15px",
                    height: "350px",
                    overflowY: "auto",
                    right: "-21px",
                    top: "52px",
                  }}
                >
                  <h4
                    style={{
                      color: "#586874",
                      fontSize: "14px",
                      marginLeft: "0px",
                      textAlign: "center",
                      marginBottom: "10px",
                      borderBottom: "1px solid #586874",
                      paddingBottom: "10px",
                      width: "220px",
                    }}
                  >
                    Displayed Columns
                  </h4>
                  {columnsArray.map((column, index) => {
                    let { name, selected } = column;
                    return (
                      <li style={{ width: "200px", fontSize: "12px" }}>
                        <input
                          type="checkbox"
                          checked={selected}
                          id={column.key + index}
                          onChange={() =>
                            this.setState((prevState) => ({
                              columnsArray: prevState.columnsArray.map(
                                (clm) => {
                                  if (clm.key === column.key) {
                                    clm.selected = !clm.selected;
                                  }
                                  return clm;
                                }
                              ),
                            }))
                          }
                          style={{
                            height: "15px",
                            display: "inline-block",
                            width: "auto",
                            marginRight: "5px",
                          }}
                        />
                        <label
                          htmlFor={column.key + index}
                          style={{ display: "inline-block", cursor: "pointer" }}
                        >
                          {name}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        {this.state.reportsTable.length === 0 ? (
          <div
            style={{
              margin: "30px auto",
              marginLeft: "35vw",
              fontWeight: "500",
            }}
          >
            No records found.
          </div>
        ) : (
          <div style={{ width: "93vw", height: "auto", overflowX: "auto" }}>
            <table className="accountList__table" style={{ marginTop: "40px" }}>
              <SortableContainer
                useDragHandle
                axis={"x"}
                onSortEnd={({ oldIndex, newIndex }) => {
                  this.setState(({ columnsArray }) => ({
                    columnsArray: arrayMove(columnsArray, oldIndex, newIndex),
                  }));
                }}
              >
                {columnsArray.map(
                  (column, index) =>
                    column.selected === true && (
                      <SortableItem
                        key={column.key}
                        column={column}
                        index={index}
                        sortButtonClickHandler={this.sortButtonClickHandler}
                      />
                    )
                )}
              </SortableContainer>
              <tbody>
                {this.state?.reportsTable.map((report) => {
                  const { id } = report;
                  return (
                    <tr key={id}>
                      {columnsArray.map(
                        (column) =>
                          column.selected === true &&
                          (column.key === "description" ? (
                            <td>
                              {report.description?.length > 140
                                ? report.description?.substring(0, 140)
                                : report.description}
                            </td>
                          ) : column.key === "phone" ? (
                            <td>{report.formatted_phone}</td>
                          ) : column.key === "revenue" ? (
                            <td>
                              {currencySymbol.symbol}
                              {report.revenue?.toLocaleString("en-US")}
                            </td>
                          ) : moment(
                              report[column.key] ||
                                report.custom_fields[column.key],
                              "YYYY-MM-DDTHH:mm:ss.SSSZ",
                              true
                            ).isValid() ? (
                            <td>
                              {(report[column.key] ||
                                report.custom_fields[column.key]) &&
                                moment(
                                  report[column.key] ||
                                    report.custom_fields[column.key]
                                ).format(dateFormat)}
                            </td>
                          ) : (
                            <td>
                              {report.custom_fields[column.key] !== undefined &&
                              report.custom_fields[column.key] !== null &&
                              report.custom_fields[column.key]
                                .toString()
                                .startsWith(";")
                                ? report.custom_fields[column.key]
                                    .replace(/;/g, ", ")
                                    .substr(1)
                                    .slice(0, -2)
                                : report[column.key] ||
                                  report.custom_fields[column.key]}
                            </td>
                          ))
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ReportsDealSave);
