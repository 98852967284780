import {
  FETCH_ACCOUNT_SOURCES_REQUEST,
  FETCH_ACCOUNT_SOURCES_SUCCESS,
  FETCH_ACCOUNT_SOURCES_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllAccountSourcesRequest = () => {
  return {
    type: FETCH_ACCOUNT_SOURCES_REQUEST,
  };
};
const fetchAllAccountSourcesSuccess = (data) => {
  return {
    type: FETCH_ACCOUNT_SOURCES_SUCCESS,
    payload: data,
  };
};
const fetchAllAccountSourcesFailure = (err) => {
  return {
    type: FETCH_ACCOUNT_SOURCES_FAILURE,
    payload: err,
  };
};

// Fetch All AccountSources
export const fetchAllAccountSources = () => {
  return (dispatch) => {
    dispatch(fetchAllAccountSourcesRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/accounts/sources`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.account_sources;
        dispatch(fetchAllAccountSourcesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllAccountSourcesFailure(errorMsg));
      });
  };
};
