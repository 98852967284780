import {
  FETCH_LANE_TYPES_REQUEST,
  FETCH_LANE_TYPES_SUCCESS,
  FETCH_LANE_TYPES_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllLaneTypesRequest = () => {
  return {
    type: FETCH_LANE_TYPES_REQUEST,
  };
};
const fetchAllLaneTypesSuccess = (data) => {
  return {
    type: FETCH_LANE_TYPES_SUCCESS,
    payload: data,
  };
};
const fetchAllLaneTypesFailure = (err) => {
  return {
    type: FETCH_LANE_TYPES_FAILURE,
    payload: err,
  };
};

// Fetch All AllLaneTypes
export const fetchAllLaneTypes = () => {
  return (dispatch) => {
    dispatch(fetchAllLaneTypesRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/lanes/types`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.types;
        dispatch(fetchAllLaneTypesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllLaneTypesFailure(errorMsg));
      });
  };
};
