import React from "react";
import "../../sass/DropdownHeader.scss";
import Collapsible from "react-collapsible";
import axios from "axios";
import ArrowIcon from "../../images/ArrowDown.svg";
import ArrowUpIcon from "../../images/ArrowUp.svg";
// Redux stuff
import { connect } from "react-redux";

class DropdownDashboard extends React.Component {
  state = {
    displayMenu: false,
    teamsName: [],
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    axios({
      method: "GET",
      url: `/dashboard/teams`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((res) => this.setState({ teamsName: res.data.teams }));
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  hideDropdownMenu = () => {
    this.setState({ displayMenu: false });
  };

  render() {
    const allUsers = this.props.allUsers?.map((user) => user.id)?.join(",");
    return (
      <div
        className="dropdownDashboard"
        ref={this.wrapperRef}
        style={{
          background: "transparent",
          zIndex: "100",
        }}
      >
        <div
          className="button"
          onClick={this.showDropdownMenu}
          style={{
            color: "#4BC893",
            width: "auto",
            marginBottom: "15px",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          <div>
            Viewing as:{" "}
            <span style={{ color: "#0C0D0D" }}>
              {this.props.displayUserName}&nbsp;
              <img src={ArrowIcon} alt="V" height="6px" />
            </span>
          </div>
        </div>

        {this.state.displayMenu ? (
          <ul style={{ width: "171px" }}>
            <li
              onClick={() => {
                this.props.handleUserId(this.props.userId, this.props.userName);
                this.hideDropdownMenu();
              }}
              style={{
                cursor: "pointer",
                borderBottom: "4px solid #E3E3E3",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "150%",
                color: "#0C0D0D",
              }}
            >
              {this.props.userName} (you)
            </li>
            {this.props.userRole === "SUPERADMIN" && (
              <li
                style={{
                  cursor: "pointer",
                  borderBottom: "4px solid #E3E3E3",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "150%",
                  color: "#0C0D0D",
                }}
                onClick={() => {
                  this.props.handleUserId(allUsers, "Company Dashboard");
                  this.hideDropdownMenu();
                }}
              >
                Company Dashboard
              </li>
            )}
            <li>
              {this.state?.teamsName?.map((team) => {
                const { team_name, members } = team;
                return (
                  <Collapsible
                    trigger={
                      <div>
                        {team_name}
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            padding: "3px 10px",
                            border: "none",
                            borderRadius: "5px",
                            color: "white",
                            outline: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "5%",
                            right: "2%",
                          }}
                        >
                          <img src={ArrowIcon} alt="" height="7px" />
                        </button>
                      </div>
                    }
                    triggerWhenOpen={
                      <div>
                        {team_name}
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            padding: "3px 10px",
                            border: "none",
                            borderRadius: "5px",
                            color: "white",
                            outline: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "5%",
                            right: "2%",
                          }}
                        >
                          <img src={ArrowUpIcon} alt="" height="7px" />
                        </button>
                      </div>
                    }
                    triggerStyle={{
                      fontSize: "15px",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    <div
                      onClick={() => {
                        this.props.handleUserId(
                          members.map((member) => member.id).join(","),
                          team_name
                        );
                        this.hideDropdownMenu();
                      }}
                      style={{
                        fontSize: "12px",
                        color: "#383A39",
                        borderTop: "1px solid #E3E3E3",
                      }}
                    >{`${team_name} Dashboard`}</div>
                    {members.map((member) => (
                      <div
                        onClick={() => {
                          this.props.handleUserId(member.id, member.name);
                          this.hideDropdownMenu();
                        }}
                        style={{
                          fontSize: "12px",
                          color: "#383A39",
                          borderTop: "1px solid #E3E3E3",
                        }}
                      >
                        {member.name}
                      </div>
                    ))}
                  </Collapsible>
                );
              })}
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    allUsers: state.allUsers.users,
  };
};

const MDP = () => {
  return {};
};

export default connect(MSP, MDP)(DropdownDashboard);
