import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import ScoreCardIcon from "../../images/scorecard.png";
import AccountsColumn from "../Analytics/Pages/AccountsColumn";
import AccountsLine from "../Analytics/Pages/AccountsLine";
import AccountsPie from "../Analytics/Pages/AccountsPie";
import AccountsScoreCard from "../Analytics/Pages/AccountsScoreCard";
import ActivitiesColumn from "../Analytics/Pages/Activities/ActivitiesColumn";
import ActivitiesLine from "../Analytics/Pages/Activities/ActivitiesLine";
import ActivitiesPie from "../Analytics/Pages/Activities/ActivitiesPie";
import ActivitiesScoreCard from "../Analytics/Pages/Activities/ActivitiesScoreCard";
import DealsColumn from "../Analytics/Pages/Deals/DealsColumn";
import DealsLine from "../Analytics/Pages/Deals/DealsLine";
import DealsPie from "../Analytics/Pages/Deals/DealsPie";
import DealsScoreCard from "../Analytics/Pages/Deals/DealsScoreCard";
import CarriersColumn from "../Analytics/Pages/Carriers/CarriersColumn";
import CarriersLine from "../Analytics/Pages/Carriers/CarriersLine";
import CarriersPie from "../Analytics/Pages/Carriers/CarriersPie";
import CarriersScoreCard from "../Analytics/Pages/Carriers/CarriersScoreCard";
import ReportsAccountDashboard from "./ReportCards/Pages/ReportsAccountDashboard";
import ReportsActivityDashboard from "./ReportCards/Pages/ReportsActivityDashboard";
import ReportsContactDashboard from "./ReportCards/Pages/ReportsContactDashboard";
import ReportsDealDashboard from "./ReportCards/Pages/ReportsDealDashboard";
import ReportsCarrierDashboard from "./ReportCards/Pages/Carriers/ReportsCarrierDashboard";

const CustomComponentModal = ({
  showModal,
  handleCloseModal,
  submitHandler,
  modalHeight,
  modalTop,
  loading,
  setReportType,
  reportType,
  reportData,
  mode,
}) => {
  const [filterRules, setFilterRules] = useState([]);
  const [segmentBy, setSegmentBy] = useState("");
  const [yAxis, setYAxis] = useState("");
  const [dataFormat, setDataFormat] = useState("count");
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  const buttonsArray = [
    { key: "table", tooltip: "Tabular View", class: "fa fa-table" },
    { key: "column", tooltip: "Column chart", class: "fa fa-bar-chart" },
    { key: "line", tooltip: "Line chart", class: "fa fa-line-chart" },
    { key: "bar", tooltip: "Bar chart", class: "fa fa-bar-chart-o" },
    { key: "pie", tooltip: "Pie chart", class: "fa fa-pie-chart" },
    { key: "scorecard", tooltip: "Scorecard" },
  ];
  const getReportById = () => {
    axios({
      method: "GET",
      url: `/reports/${reportData?.id}`,
    }).then((res) => {
      const filter = res.data.report.filters?.map((rule) => {
        const { operator, value } = rule;
        if (operator === "is_in" || operator === "is_not_in") {
          rule.value = value.map((val) => ({ value: val, label: val }));
        }
        return rule;
      });
      setFilterRules(filter);
    });
  };
  useEffect(() => {
    if (reportData.id !== undefined) {
      getReportById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData?.id]);
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{
              height: `${modalHeight}vh`,
              top: `${modalTop}%`,
              width: "60vw",
              left: "20%",
            }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">
                  {mode === "edit" ? "Edit" : "Add"} a custom component
                </h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <div>
              <div className="reports-tab-container">
                {buttonsArray.map((button) => (
                  <button
                    className="reports-tab"
                    type="button"
                    onClick={() => setReportType(button.key)}
                    data-tip={button.tooltip}
                  >
                    {button.key === "scorecard" ? (
                      <img
                        src={ScoreCardIcon}
                        alt="ScoreCard"
                        width="20px"
                        height="15px"
                      />
                    ) : (
                      <i
                        className={button.class}
                        aria-hidden="true"
                        style={
                          button.key === "bar"
                            ? { transform: "rotate(90deg)" }
                            : {}
                        }
                      ></i>
                    )}
                  </button>
                ))}
              </div>
              {reportType === "pie" &&
                (reportData?.type === "opportunities" ? (
                  <DealsPie
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                    setDataFormatDashboard={setDataFormat}
                    dataFormatDashboard={dataFormat}
                  />
                ) : reportData?.type === "activities" ? (
                  <ActivitiesPie
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                    setDataFormatDashboard={setDataFormat}
                    dataFormatDashboard={dataFormat}
                  />
                ) : reportData?.type === "carriers" ? (
                  <CarriersPie
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                    setDataFormatDashboard={setDataFormat}
                    dataFormatDashboard={dataFormat}
                  />
                ) : (
                  <AccountsPie
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                    setDataFormatDashboard={setDataFormat}
                    dataFormatDashboard={dataFormat}
                    reportType={
                      reportData?.type === "contacts" ? "contact" : "account"
                    }
                  />
                ))}
              {reportType === "line" &&
                (reportData?.type === "opportunities" ? (
                  <DealsLine
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    setYAxisDashboard={setYAxis}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    yAxisDashboard={reportData.yAxis}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : reportData?.type === "activities" ? (
                  <ActivitiesLine
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : reportData?.type === "carriers" ? (
                  <CarriersLine
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : (
                  <AccountsLine
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                    reportType={
                      reportData?.type === "contacts" ? "contact" : "account"
                    }
                  />
                ))}
              {reportType === "column" &&
                (reportData?.type === "opportunities" ? (
                  <DealsColumn
                    filter_rules={filterRules}
                    chartType="column"
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    setYAxisDashboard={setYAxis}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    yAxisDashboard={reportData.yAxis}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : reportData?.type === "activities" ? (
                  <ActivitiesColumn
                    filter_rules={filterRules}
                    chartType="column"
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : reportData?.type === "carriers" ? (
                  <CarriersColumn
                    filter_rules={filterRules}
                    chartType="column"
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : (
                  <AccountsColumn
                    filter_rules={filterRules}
                    chartType="column"
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                    reportType={
                      reportData?.type === "contacts" ? "contact" : "account"
                    }
                  />
                ))}
              {reportType === "bar" &&
                (reportData?.type === "opportunities" ? (
                  <DealsColumn
                    filter_rules={filterRules}
                    chartType="bar"
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    setYAxisDashboard={setYAxis}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    yAxisDashboard={reportData.yAxis}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : reportData?.type === "activities" ? (
                  <ActivitiesColumn
                    filter_rules={filterRules}
                    chartType="bar"
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : reportData?.type === "carriers" ? (
                  <CarriersColumn
                    filter_rules={filterRules}
                    chartType="bar"
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : (
                  <AccountsColumn
                    filter_rules={filterRules}
                    chartType="bar"
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                    reportType={
                      reportData?.type === "contacts" ? "contact" : "account"
                    }
                  />
                ))}
              {reportType === "scorecard" &&
                (reportData?.type === "opportunities" ? (
                  <DealsScoreCard
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : reportData?.type === "activities" ? (
                  <ActivitiesScoreCard
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : reportData?.type === "carriers" ? (
                  <CarriersScoreCard
                    filter_rules={filterRules}
                    fromDashboard={true}
                    setSegmentByDashboard={setSegmentBy}
                    mode={mode}
                    segmentByDashboard={reportData.segmentBy}
                    viewDashboard={reportData.viewDashboard}
                  />
                ) : (
                  <AccountsScoreCard
                    filter_rules={filterRules}
                    reportType={
                      reportData?.type === "contacts" ? "contact" : "account"
                    }
                  />
                ))}
              {reportType === "table" &&
                (reportData?.type === "opportunities" ? (
                  <ReportsDealDashboard
                    reportName={reportData.name}
                    reportId={reportData.id}
                    maxHeight={"55vh"}
                    fromModal={true}
                  />
                ) : reportData?.type === "activities" ? (
                  <ReportsActivityDashboard
                    reportName={reportData.name}
                    reportId={reportData.id}
                    maxHeight={"55vh"}
                    fromModal={true}
                  />
                ) : reportData?.type === "contacts" ? (
                  <ReportsContactDashboard
                    reportName={reportData.name}
                    reportId={reportData.id}
                    maxHeight={"55vh"}
                    fromModal={true}
                  />
                ) : reportData?.type === "carriers" ? (
                  <ReportsCarrierDashboard
                    reportName={reportData.name}
                    reportId={reportData.id}
                    maxHeight={"55vh"}
                    fromModal={true}
                  />
                ) : (
                  <ReportsAccountDashboard
                    reportName={reportData.name}
                    reportId={reportData.id}
                    maxHeight={"55vh"}
                    fromModal={true}
                  />
                ))}
            </div>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={loading}
                onClick={() => {
                  submitHandler(
                    reportType,
                    reportData,
                    filterRules,
                    segmentBy,
                    yAxis,
                    dataFormat
                  );
                  handleCloseModal();
                }}
              >
                {loading ? "Confirming" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default CustomComponentModal;
