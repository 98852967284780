import React from "react";
import Rec1 from "../../images/Funnel10Rec1.svg";
import Rec2 from "../../images/Funnel10Rec2.svg";
import Rec3 from "../../images/Funnel10Rec3.svg";
import Rec4 from "../../images/Funnel10Rec4.svg";
import Rec5 from "../../images/Funnel10Rec5.svg";
import Rec6 from "../../images/Funnel10Rec6.svg";
import Rec7 from "../../images/Funnel10Rec7.svg";
import Rec8 from "../../images/Funnel10Rec8.svg";
import Rec9 from "../../images/Funnel10Rec9.svg";
import Rec10 from "../../images/Funnel10Rec10.svg";
import ReactTooltip from "react-tooltip";
import { Row, Col, Hidden } from "react-grid-system";
import { withRouter } from "react-router-dom";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../redux";

function FunnelTen(props) {
  return (
    <div className="funnel-chart">
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "29.5%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[0]?.id}Funnel`,
                    name: `${props?.sales_funnel[0]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[0]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[0]?.id}Funnel`,
                    name: `${props?.sales_funnel[0]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[0]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec1}
                alt=""
                data-tip={`${props?.sales_funnel[0]?.name}: ${props?.sales_funnel[0]?.amount}`}
                style={{ marginBottom: "-1.6px", marginLeft: "-3px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[0]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[0]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "33%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[1]?.id}Funnel`,
                    name: `${props?.sales_funnel[1]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[1]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[1]?.id}Funnel`,
                    name: `${props?.sales_funnel[1]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[1]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec2}
                alt=""
                data-tip={`${props?.sales_funnel[1]?.name}: ${props?.sales_funnel[1]?.amount}`}
                style={{ marginBottom: "-2.6px", marginLeft: "-2px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[1]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[1]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "36%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[2]?.id}Funnel`,
                    name: `${props?.sales_funnel[2]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[2]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[2]?.id}Funnel`,
                    name: `${props?.sales_funnel[2]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[2]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec3}
                alt=""
                data-tip={`${props?.sales_funnel[2]?.name}: ${props?.sales_funnel[2]?.amount}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[2]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[2]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "39%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[3]?.id}Funnel`,
                    name: `${props?.sales_funnel[3]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[3]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[3]?.id}Funnel`,
                    name: `${props?.sales_funnel[3]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[3]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec4}
                alt=""
                data-tip={`${props?.sales_funnel[3]?.name}: ${props?.sales_funnel[3]?.amount}`}
                style={{ marginBottom: "-2.6px", marginLeft: "-1px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[3]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[3]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "42%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[4]?.id}Funnel`,
                    name: `${props?.sales_funnel[4]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[4]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[4]?.id}Funnel`,
                    name: `${props?.sales_funnel[4]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[4]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec5}
                alt=""
                data-tip={`${props?.sales_funnel[4]?.name}: ${props?.sales_funnel[4]?.amount}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[4]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[4]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "45%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[5]?.id}Funnel`,
                    name: `${props?.sales_funnel[5]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[5]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[5]?.id}Funnel`,
                    name: `${props?.sales_funnel[5]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[5]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec6}
                alt=""
                data-tip={`${props?.sales_funnel[5]?.name}: ${props?.sales_funnel[5]?.amount}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[5]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[5]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "48%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[6]?.id}Funnel`,
                    name: `${props?.sales_funnel[6]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[6]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[6]?.id}Funnel`,
                    name: `${props?.sales_funnel[6]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[6]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec7}
                alt=""
                data-tip={`${props?.sales_funnel[6]?.name}: ${props?.sales_funnel[6]?.amount}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[6]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[6]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "51%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[7]?.id}Funnel`,
                    name: `${props?.sales_funnel[7]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[7]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[7]?.id}Funnel`,
                    name: `${props?.sales_funnel[7]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[7]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec8}
                alt=""
                data-tip={`${props?.sales_funnel[7]?.name}: ${props?.sales_funnel[7]?.amount}`}
                style={{ marginBottom: "-2.6px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[7]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[7]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "0",
                right: "0%",
                top: "50%",
                width: "54%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[8]?.id}Funnel`,
                    name: `${props?.sales_funnel[8]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[8]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[8]?.id}Funnel`,
                    name: `${props?.sales_funnel[8]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[8]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec9}
                alt=""
                data-tip={`${props?.sales_funnel[8]?.name}: ${props?.sales_funnel[8]?.amount}`}
                style={{ marginBottom: "-2.6px", marginLeft: "-2px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[8]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {props?.sales_funnel[8]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
      <div>
        <Row style={{ position: "relative" }}>
          <Hidden sm xs>
            <div
              style={{
                position: "absolute",
                borderBottom: "2px solid #EEF1F0",
                bottom: "14%",
                right: "0%",
                top: "50%",
                width: "59%",
              }}
            ></div>
          </Hidden>
          <Col style={{ textAlign: "center", position: "relative" }} lg={9}>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[9]?.id}Funnel`,
                    name: `${props?.sales_funnel[9]?.name}`,
                    blank: true,
                    stageId: props?.sales_funnel[9]?.id,
                    userId: props?.userId,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab({
                    type: "dealStage",
                    id: `${props?.userId}${props?.sales_funnel[9]?.id}Funnel`,
                    name: `${props?.sales_funnel[9]?.name}`,
                    userId: props?.userId,
                    stageId: props?.sales_funnel[9]?.id,
                    userName: props.userName,
                    funnelId: props?.funnelId,
                  });
                  props.history.push("/active-tabs");
                }
              }}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <img
                src={Rec10}
                alt=""
                data-tip={`${props?.sales_funnel[9]?.name}: ${props?.sales_funnel[9]?.amount}`}
                style={{ marginTop: "-1px", marginLeft: "-2px" }}
              />
              <ReactTooltip />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "999",
                }}
              >
                {props?.sales_funnel[9]?.amount}
              </span>
            </div>
          </Col>
          <Hidden sm xs>
            <Col lg={3} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "right",
                  marginRight: "20px",
                  position: "absolute",
                  bottom: "16%",
                  right: "0",
                }}
              >
                {props?.sales_funnel[9]?.name}
              </p>
            </Col>
          </Hidden>
        </Row>
      </div>
    </div>
  );
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default withRouter(connect(MSP, MDP)(FunnelTen));
