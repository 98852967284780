import { useState } from "react";
import "./Walkthrough.scss";
import WalkthroughSidebar from "./WalkthroughSidebar";

function WalkthoughVideosPage() {
  const [selectedVideo, setSelectedVideo] = useState({
    title: "Import Spreadsheet Data",
    src: "https://www.youtube.com/embed/dUY5d0OzQSw",
  });
  return (
    <div className="walk-through">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "10px", marginLeft: "20px" }}>
            Video Tutorials
          </h2>
        </div>
      </div>
      <div className="walk-through-grid">
        <WalkthroughSidebar
          setSelectedVideo={setSelectedVideo}
          selectedVideo={selectedVideo}
        />
        <div className="walk-through-player">
          <h3 className="walk-through-player-title">{selectedVideo.title}</h3>
          <iframe
            width="560"
            height="315"
            src={selectedVideo.src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default WalkthoughVideosPage;
