export const defaultContactFields = {
  field_class: "contact",
  fields: [
    {
      choices: [],
      field_class: "contact",
      id: "d-contact_owner_id",
      label: "Contact Owner",
      name: "contact_owner_id",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-account_id",
      label: "Account",
      name: "account_id",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-name",
      label: "Contact Name",
      name: "name",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "text",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-phone",
      label: "Phone number",
      name: "phone",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-phone_ext",
      label: "Phone Extension",
      name: "phone_ext",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-mobile",
      label: "Mobile",
      name: "mobile",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-email",
      label: "Email",
      name: "email",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-role",
      label: "Job Title",
      name: "role",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-address",
      label: "Address",
      name: "address",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-city",
      label: "City",
      name: "city",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-State",
      label: "State",
      name: "state",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-zip",
      label: "Zip/Postal",
      name: "zip",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-country",
      label: "Country",
      name: "country",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [
        { label: "Call", value: "call" },
        { label: "Text", value: "text" },
        { label: "Email", value: "email" },
      ],
      field_class: "contact",
      id: "d-preferred_communication",
      label: "Preferred Communication",
      name: "preferred_communication",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-Source",
      label: "Source",
      name: "source",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-Linkedin",
      label: "Linkedin",
      name: "linkedin",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "contact",
      id: "d-date_of_birth",
      label: "Birthday",
      name: "date_of_birth",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "date",
      visible: null,
    },
  ],
  id: "default_fields",
  label: "Default Fields",
  meta: null,
  name: "default_fields",
  placeholder: null,
  position: 0,
  type: "section",
};
