import "../../sass/CustomCheckbox.scss";

const Checkbox = ({
  label = "",
  id = "",
  isDisabled,
  isChecked,
  onChange,
  value,
}) => {
  return (
    <label class="container-checkbox">
      {label}
      <input
        id={`customFields${id}${label}`}
        type="checkbox"
        style={
          isDisabled
            ? {
                width: "20px",
                display: "inline-block",
                cursor: "not-allowed",
              }
            : {
                width: "20px",
                display: "inline-block",
                cursor: "pointer",
              }
        }
        value={value}
        onChange={onChange}
        checked={isChecked}
        disabled={isDisabled}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
