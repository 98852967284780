import axios from "axios";
import React, { useEffect, useState } from "react";
import LineChart from "../../Charts/LineGraph";

function ActivitiesLine({
  filter_rules,
  fromDashboard,
  setSegmentByDashboard,
  mode,
  segmentByDashboard,
  viewDashboard,
  fromReport,
}) {
  const [segmentBy, setSegmentBy] = useState("type");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState("");
  useEffect(() => {
    const getData = () => {
      let newValue = [];
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        return {
          attribute,
          operator,
          value,
        };
      });
      setLoading(true);
      let url = `/charts/activity-chart`;
      axios({
        method: "POST",
        url,
        data: {
          chart: {
            type: "line",
            x_axis: segmentBy,
            y_axis: "no_of_activities",
          },
          filter_rules: newValue,
        },
      })
        .then((res) => {
          setLoading(false);
          setData(res.data.activities);
          setRefresh(segmentBy);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getData();
    fromDashboard && setSegmentByDashboard(segmentBy);
    fromReport && setRefresh(segmentBy + "no_of_activities");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_rules, segmentBy]);
  useEffect(() => {
    mode === "edit" &&
      viewDashboard === "line" &&
      setSegmentBy(segmentByDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <label>X-axis</label>
      <select value={segmentBy} onChange={(e) => setSegmentBy(e.target.value)}>
        <option value="type">Type</option>
        <option value="activity_owner">Owner</option>
        <option value="completion_date">Completion Date</option>
        <option value="result">Result</option>
      </select>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <LineChart
          data={data}
          segmentBy={segmentBy}
          type="Activities"
          refresh={refresh}
          fromDashboard={fromDashboard}
        />
      )}
    </div>
  );
}

export default ActivitiesLine;
