import React, { Component } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Sidebar from "../Profile/Sidebar";

class NotificationSetting extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    teamNotifications: true,
    teamNotificationsInterval: "15,30,60",
    isLoading: false,
    errorMessage: false,
  };
  componentDidMount() {
    this.getSettings();
  }
  getSettings = () => {
    axios({
      method: "GET",
      url: `/notifications/settings`,
    }).then((res) => {
      this.setState({
        teamNotifications: res.data.settings[0].team_notifications,
        teamNotificationsInterval:
          res.data.settings[0].task_notifications_interval?.split(","),
      });
    });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  intervalChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        teamNotificationsInterval: [
          ...this.state.teamNotificationsInterval.filter((com) => com !== ""),
          value,
        ],
      }));
    } else {
      this.setState({
        teamNotificationsInterval: this.state.teamNotificationsInterval.filter(
          (pc) => pc !== value
        ),
      });
    }
  };
  submitHandler = () => {
    if (this.state.teamNotificationsInterval.length === 0) {
      this.setState({ isLoading: false, errorMessage: true });
      return false;
    }
    this.setState({ isLoading: true });
    const task_notifications_interval = this.state.teamNotificationsInterval
      .sort()
      .join();
    axios({
      url: `/notifications`,
      method: "PUT",
      data: {
        team_notifications: this.state.teamNotifications,
        task_notifications_interval,
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        NotificationManager.success("Notification Settings Updated");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        NotificationManager.error("Error Updating Notification Settings");
      });
  };

  render() {
    return (
      <>
        <Sidebar />
        <div className="userProfile">
          <form>
            <h2 className="userProfile-heading-main">Notification Settings</h2>
            <label style={{ fontSize: "14px" }}>Task reminder interval</label>
            <input
              id="teamNotificationsInterval15"
              type="checkbox"
              name="teamNotificationsInterval"
              style={{ width: "20px", display: "inline-block" }}
              value="15"
              checked={this.state.teamNotificationsInterval.includes("15")}
              onChange={this.intervalChangeHandler}
            />
            <label
              style={{ display: "inline-block", marginRight: "10px" }}
              htmlFor="teamNotificationsInterval15"
            >
              15 minutes
            </label>
            <input
              id="teamNotificationsInterval30"
              type="checkbox"
              name="teamNotificationsInterval"
              style={{ width: "20px", display: "inline-block" }}
              value="30"
              checked={this.state.teamNotificationsInterval.includes("30")}
              onChange={this.intervalChangeHandler}
            />
            <label
              style={{ display: "inline-block", marginRight: "10px" }}
              htmlFor="teamNotificationsInterval30"
            >
              30 minutes
            </label>
            <input
              id="teamNotificationsInterval60"
              type="checkbox"
              name="teamNotificationsInterval"
              style={{ width: "20px", display: "inline-block" }}
              value="60"
              checked={this.state.teamNotificationsInterval.includes("60")}
              onChange={this.intervalChangeHandler}
            />
            <label
              style={{ display: "inline-block" }}
              htmlFor="teamNotificationsInterval60"
            >
              60 minutes
            </label>
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              this.state.teamNotificationsInterval.length === 0
                ? "Please select atleast one interval for task reminder."
                : null}
            </div>
            <label style={{ fontSize: "14px", marginTop: "10px" }}>
              Would you like to be notified when a Deal has been marked as Won
              by team members?
            </label>
            <input
              type="checkbox"
              style={{ width: "20px" }}
              value={this.state.teamNotifications}
              checked={this.state.teamNotifications}
              onChange={() =>
                this.setState({
                  teamNotifications: !this.state.teamNotifications,
                })
              }
            />
            <button
              type="button"
              disabled={this.state.isLoading}
              onClick={this.submitHandler}
              className="button-md"
            >
              {this.state.isLoading ? "Saving" : "Save Changes"}
            </button>
          </form>
        </div>
      </>
    );
  }
}

export default NotificationSetting;
