import {
  FETCH_LANE_TYPES_REQUEST,
  FETCH_LANE_TYPES_SUCCESS,
  FETCH_LANE_TYPES_FAILURE,
} from "./types";

const initialState = {
  laneTypes: [],
  loading: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LANE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case FETCH_LANE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        laneTypes: action.payload,
      };
    case FETCH_LANE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        laneTypes: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
