import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../redux";

function WebFormPreview({
  heading,
  primaryColor,
  theme,
  fields,
  companyOnboarding,
}) {
  const showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired
  ) => {
    let inputType;
    if (fieldType === "text") {
      inputType = <input type="text" />;
    } else if (fieldType === "hyperlink") {
      inputType = <input type="text" />;
    } else if (fieldType === "paragraph") {
      inputType = <textarea></textarea>;
    } else if (fieldType === "decimal") {
      inputType = <input type="number" />;
    } else if (fieldType === "dropdown") {
      inputType = (
        <select>
          <option selected disabled>
            -Select-
          </option>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return <option key={id}>{value}</option>;
          })}
        </select>
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px" }}>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  id={fieldName + value}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          name={fieldName}
          dateFormat={
            companyOnboarding.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            companyOnboarding.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = fields[fieldName];
      const colourStyles = {
        control: (styles) => ({
          ...styles,
          backgroundColor: "white",
          width: "100%",
          outline: "#C0EAD8",
          color: "black",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? "#C0EAD8"
              : null,
            cursor: isDisabled ? "not-allowed" : "default",
            color: "black",

            ":active": {
              ...styles[":active"],
              backgroundColor:
                !isDisabled && (isSelected ? data.color : "#C0EAD8"),
            },
          };
        },
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "#C0EAD8",
            color: "black",
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "black",
        }),
      };
      inputType = (
        <Select
          value={multiSelectDropdownValue}
          closeMenuOnSelect={false}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value: value, label: value };
          })}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      );
    }
    return (
      <div>
        <label>
          {fieldLabel} {fieldRequired && <span>*</span>}
        </label>
        {inputType}
      </div>
    );
  };
  return (
    <>
      <div
        className="webform-preview"
        style={
          theme === "light"
            ? { background: "white", color: "black" }
            : { background: "#252A2D", color: "white" }
        }
      >
        <h2 className="webform-preview-heading">{heading}</h2>
        {fields?.map((field) => {
          const { id, label, type, choices, name, selected, required } = field;
          return (
            selected && (
              <div key={id}>
                {showInput(name, label, type, choices, required)}
              </div>
            )
          );
        })}
        <button style={{ background: primaryColor, color: "white" }}>
          Submit
        </button>
      </div>
    </>
  );
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};
export default connect(MSP, MDP)(WebFormPreview);
