import React from "react";
import "../../sass/DropdownHeader.scss";
import { Link } from "react-router-dom";
import ContactsSettingsIcon from "../../images/ContactsSettingsIcon.svg";
import DealsSettingsIcon from "../../images/DealsSettingsIcon.svg";
import AccountsLogo from "../../images/AccountsSettingsIcon.svg";
import CarrierGreenNavIcon from "../../images/CarrierGreenNavIcon.svg";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../redux";

class DropdownAdd extends React.Component {
  state = {
    displayMenu: false,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  render() {
    return (
      <div
        className="dropdownTask"
        ref={this.wrapperRef}
        style={{ background: "transparent", width: "115px" }}
      >
        <div
          className="button"
          style={{ fontSize: "14px", top: "-22px", left: "0" }}
          onClick={this.showDropdownMenu}
        >
          <Link
            className="customer__add button-md"
            to="#"
            style={{ width: "80px", textAlign: "center" }}
          >
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "122px",
              }}
            >
              + Add Record
            </span>
          </Link>
        </div>

        {this.state.displayMenu ? (
          <ul
            style={{
              width: "111.5px",
              top: "18px",
              borderRadius: "4px",
              left: "0",
            }}
          >
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.setShowAccountModal(true);
                this.setState({ displayMenu: false });
              }}
            >
              <img src={AccountsLogo} alt="" width="16px" />
              &nbsp;&nbsp;Account
            </li>
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.setShowContactModal(true);
                this.setState({ displayMenu: false });
              }}
            >
              <img src={ContactsSettingsIcon} alt="" width="16px" />
              &nbsp;&nbsp;Contact
            </li>
            <li
              className="dropdownAddLi"
              onClick={() => {
                this.props.setShowOppModal(true);
                this.setState({ displayMenu: false });
              }}
            >
              <img src={DealsSettingsIcon} alt="" width="16px" />
              &nbsp;&nbsp;Deal
            </li>
            {this.props.companyOnboarding.industry === "Freight Brokerage" && (
              <li
                className="dropdownAddLi"
                onClick={() => {
                  this.props.setShowCarrierModal(true);
                  this.setState({ displayMenu: false });
                }}
              >
                <img src={CarrierGreenNavIcon} alt="" width="16px" />
                &nbsp;&nbsp;Carrier
              </li>
            )}
          </ul>
        ) : null}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(DropdownAdd);
