import React, { useState } from "react";
import ReactModal from "react-modal";

const StatusTransferModal = ({
  showOpportunityModal,
  handleCloseModal,
  sourceArray,
  selectedStatus,
  deleteChoiceChangeHandler,
}) => {
  const [newStatus, setNewStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={showOpportunityModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: "50vh", top: "15%" }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Delete Status</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <p>
              Are you sure you want to delete{" "}
              <strong>{selectedStatus?.name}</strong>?<br />
              By deleting this status all accounts/contacts associated with it
              will be transferred to new status
            </p>
            <label>Select new status</label>
            <select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
            >
              <option value="" disabled selected>
                -select-
              </option>
              {sourceArray
                ?.filter(
                  (source) =>
                    source._new !== true &&
                    source._destroy !== true &&
                    source.id !== selectedStatus.id
                )
                ?.map((source) => (
                  <option key={source.name + source.id} value={source.id}>
                    {source.name}
                  </option>
                ))}
            </select>
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {errorMessage && newStatus === ""
                ? "Please select a status"
                : null}
            </div>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                onClick={() => {
                  if (newStatus === "") {
                    setErrorMessage(true);
                  } else {
                    deleteChoiceChangeHandler(selectedStatus.id, newStatus);
                    setErrorMessage(false);
                    handleCloseModal();
                  }
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default StatusTransferModal;
