import axios from "axios";
import React, { useEffect, useState } from "react";
import PieGraph from "../../Charts/PieGraph";
// Redux stuff
import { connect } from "react-redux";

function DealsPie({
  filter_rules,
  reportType,
  fromDashboard,
  setSegmentByDashboard,
  setDataFormatDashboard,
  mode,
  segmentByDashboard,
  dataFormatDashboard,
  viewDashboard,
  currency,
  fromReport,
  allDealCustomFields,
}) {
  const [segmentBy, setSegmentBy] = useState("stage");
  const [dataFormat, setDataFormat] = useState("count");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const fetchCustomFields = () => {
    const customField = allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    setCustomFields(customFieldArray);
  };
  useEffect(() => {
    fetchCustomFields();
  }, []);
  useEffect(() => {
    const getData = () => {
      let newValue = [];
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        return {
          attribute,
          operator,
          value,
        };
      });
      setLoading(true);
      let url = `/charts/opportunity-chart`;
      axios({
        method: "POST",
        url,
        data: {
          chart: {
            type: "pie",
            segment_by: segmentBy,
          },
          filter_rules: newValue,
        },
      })
        .then((res) => {
          setLoading(false);
          setData(res.data.opportunities);
          setRefresh(segmentBy);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getData();
    fromDashboard && setSegmentByDashboard(segmentBy);
    fromDashboard && setDataFormatDashboard(dataFormat);
    fromReport && setRefresh(segmentBy + dataFormat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_rules, segmentBy, reportType, dataFormat]);
  useEffect(() => {
    mode === "edit" &&
      viewDashboard === "pie" &&
      setSegmentBy(segmentByDashboard);
    mode === "edit" &&
      viewDashboard === "pie" &&
      setDataFormat(dataFormatDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <label>Segment By</label>
      <select value={segmentBy} onChange={(e) => setSegmentBy(e.target.value)}>
        <option value="stage">Stage</option>
        <option value="opportunity_owner">Deal Owner</option>
        <option value="close_date">Close Date</option>
        <option value="opportunity_owner">Owner</option>
        <option value="created_date">Created Date</option>
        <option value="revenue_by_stage">Revenue by Stage</option>
        <option value="revenue_by_owner">Revenue by Owner</option>
        {customFields?.map((field) => (
          <option value={field.id}>{field.label}</option>
        ))}
      </select>
      {fromDashboard && (
        <>
          <label>Data Format</label>
          <select
            value={dataFormat}
            onChange={(e) => setDataFormat(e.target.value)}
          >
            <option value="count">Count</option>
            <option value="percentage">Percentage</option>
          </select>
        </>
      )}
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <PieGraph
          data={data}
          segmentBy={segmentBy}
          type="Deals"
          refresh={refresh}
          fromDashboard={fromDashboard}
          currency={currency}
          dataFormat={dataFormat}
        />
      )}
    </div>
  );
}

const MSP = (state) => {
  return {
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};
const MDP = () => {
  return {};
};

export default connect(MSP, MDP)(DealsPie);
