import React, { useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Sidebar from "./Sidebar";

const ResetPasswordPage = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      setError(true);
      setLoading(false);
      return false;
    }
    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword !== confirmPassword
    ) {
      setError(true);
      setLoading(false);
      return false;
    }
    setLoading(true);
    axios({
      method: "POST",
      url: `/users/change-password`,
      data: {
        current_password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      },
    })
      .then((res) => {
        NotificationManager.success("Password changed successfully.");
        setError(false);
        setLoading(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error changing password.");
      });
  };
  return (
    <>
      <Sidebar />
      <div className="userProfile">
        <form>
          <h2 className="userProfile-heading-main">Reset Password</h2>
          <label style={{ fontSize: "14px" }}>Old Password</label>
          <input
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            type="password"
          />
          <div
            style={{
              color: "#F36363",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            {error && oldPassword === "" ? "Please enter old password" : null}
          </div>
          <label style={{ fontSize: "14px" }}>New Password</label>
          <input
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
          />
          <div
            style={{
              color: "#F36363",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            {error && newPassword === "" ? "Please enter new password" : null}
          </div>
          <label style={{ fontSize: "14px" }}>Confirm Password</label>
          <input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
          />
          <div
            style={{
              color: "#F36363",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            {error && confirmPassword === ""
              ? "Please enter old password"
              : null}
          </div>
          <div
            style={{
              color: "#F36363",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            {error && confirmPassword !== "" && confirmPassword !== newPassword
              ? "Passwords don't match"
              : null}
          </div>
          <button
            onClick={submitHandler}
            type="button"
            disabled={loading}
            className="button-md"
          >
            {loading ? "Confirming..." : "Confirm"}
          </button>
        </form>
      </div>
    </>
  );
};

export default ResetPasswordPage;
