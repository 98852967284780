import React, { Component } from "react";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import Collapsible from "react-collapsible";
import DragHandlerIcon from "../../images/DragHandler.svg";
import LockIcon from "../../images/LockIcon.svg";
import ArrowIcon from "../../images/Arrow.svg";
import AccountFieldsCustomSort from "./../AccountFieldsCustomSort";
import DeleteIconRed from "../../images/DeleteIconRed.svg";
import EditIcon from "../../images/EditIcon.svg";

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ marginRight: "10px", marginTop: "15px", cursor: "grab" }}
    />
  </span>
));

const SortableItem = sortableElement(
  ({
    fieldId,
    fields,
    label,
    handleOpenModal,
    handleEditOpenModal,
    handleDeleteOpenModal,
    handleGroupDeleteOpenModal,
    deleteGroupHandler,
    onFieldSortEnd,
    handleGroupEditOpenModal,
    meta,
  }) => (
    <li style={{ display: "block" }}>
      <div style={{ display: "flex" }}>
        <DragHandle />

        <Collapsible
          className="collapseHeader"
          classParentString="collapseHeader"
          trigger={
            <div>
              {label}
              <button
                type="button"
                style={{
                  background: "transparent",
                  padding: "3px 10px",
                  border: "none",
                  borderRadius: "5px",
                  color: "white",
                  outline: "none",
                  cursor: "pointer",
                  position: "absolute",
                  top: "35%",
                  right: "2%",
                }}
              >
                <img src={ArrowIcon} alt="" height="10px" />
              </button>
            </div>
          }
          triggerWhenOpen={
            meta === null ? (
              <span>
                {label}
                <button
                  type="button"
                  onClick={() => handleGroupEditOpenModal(fieldId)}
                  style={{
                    background: "white",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "2%",
                    right: "10%",
                  }}
                >
                  <img src={EditIcon} alt="Edit Group" height="14px" />
                </button>
                <button
                  type="button"
                  onClick={() => handleGroupDeleteOpenModal(fieldId)}
                  style={{
                    background: "white",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "2%",
                    right: "2%",
                  }}
                >
                  <img src={DeleteIconRed} alt="Delete Group" height="14px" />
                </button>
              </span>
            ) : (
              label
            )
          }
          triggerStyle={{
            fontSize: "15px",
            lineHeight: "18px",
            cursor: "pointer",
          }}
        >
          {meta === null ? (
            <button
              onClick={() => handleOpenModal(fieldId)}
              className="button-sm"
              style={{ margin: "7px 0 10px" }}
            >
              Add Field
            </button>
          ) : null}
          <AccountFieldsCustomSort
            parent_id={fieldId}
            onSortEnd={onFieldSortEnd}
            fields={fields}
            handleEditOpenModal={handleEditOpenModal}
            handleDeleteOpenModal={handleDeleteOpenModal}
            handleGroupDeleteOpenModal={handleGroupDeleteOpenModal}
            handleOpenModal={handleOpenModal}
            deleteGroupHandler={deleteGroupHandler}
          />
        </Collapsible>
      </div>
    </li>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

class ContactsCustomSort extends Component {
  render() {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <span style={{ cursor: "not-allowed" }}>
            <img
              src={LockIcon}
              alt="x"
              style={{
                marginRight: "10px",
                marginTop: "15px",
                cursor: "not-allowed",
              }}
            />
          </span>
          <Collapsible
            trigger={
              <div>
                Contact Info
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "35%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIcon} alt="" height="10px" />
                </button>
              </div>
            }
            triggerStyle={{
              fontSize: "15px",
              lineHeight: "18px",
              cursor: "pointer",
            }}
            triggerWhenOpen="Contact Info"
            className="collapseHeader"
            classParentString="collapseHeader"
          >
            <div className="customerInfo__Info">
              <h5 className="c-title-act">Contact Name</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="name"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["name"].required}
                  disabled={true}
                />
                Required
              </label>
              <h5 className="c-title-act">Phone number</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="phone"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["phone"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Phone Extension</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="phone_ext"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields?.["phone_ext"]?.required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Mobile</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="mobile"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["mobile"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Email</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="email"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["email"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Role</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="role"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["role"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Address</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="address"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["address"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">City</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="city"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["city"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">State</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="state"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["state"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Zip/Postal</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="zip"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["zip"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Country</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="country"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["country"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Preferred Communication</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="preferred_communication"
                  type="checkbox"
                  value={true}
                  checked={
                    this.props.defaultFields["preferred_communication"].required
                  }
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Source</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="source"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["source"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
              <h5 className="c-title-act">Birthday</h5>
              <label style={{ marginBottom: "5px" }}>
                <input
                  name="date_of_birth"
                  type="checkbox"
                  value={true}
                  checked={this.props.defaultFields["date_of_birth"].required}
                  onChange={this.props.defaultFieldsRequiredChangeHandler}
                />
                Required
              </label>
            </div>
          </Collapsible>
        </div>
        <SortableContainer onSortEnd={this.props.onSortEnd} useDragHandle>
          {this.props?.accountFields.map((field, index) => (
            <SortableItem
              key={`item-${field.id}`}
              index={index}
              fields={field.fields}
              label={field.label}
              fieldId={field.id}
              accountFields={this.props.accountFields}
              handleEditOpenModal={this.props.handleEditOpenModal}
              handleDeleteOpenModal={this.props.handleDeleteOpenModal}
              handleGroupDeleteOpenModal={this.props.handleGroupDeleteOpenModal}
              handleOpenModal={this.props.handleOpenModal}
              deleteGroupHandler={this.props.deleteGroupHandler}
              onFieldSortEnd={this.props.onFieldSortEnd}
              meta={field.meta}
              handleGroupEditOpenModal={this.props.handleGroupEditOpenModal}
            />
          ))}
        </SortableContainer>
      </div>
    );
  }
}

export default ContactsCustomSort;
