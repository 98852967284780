import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CreateUserSuccessModal from "./CreateUserSuccessModal";
import { CountryCodeList } from "../2FA/CountryCodeList";
import Select from "react-select";
import { colourStyles } from "../../constants/selectStyles";
// Redux stuff
import { connect } from "react-redux";
import { fetchAllUsers } from "../../redux";

class CreateUserModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    name: "",
    email: "",
    formatted_mobile: "",
    roleId: null,
    roles: [],
    teams: [],
    team: null,
    teamRole: null,
    errorMessage: false,
    showSuccessModal: false,
    isLoading: false,
    countryPhoneCode: "+1",
    emailValidationError: false,
    phoneValidationError: false,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleOpenSuccessModal = () => {
    this.setState({
      showSuccessModal: true,
    });
  };
  handleCloseSuccessModal = () => {
    this.setState({
      showSuccessModal: false,
    });
  };
  getGeoInfo = () => {
    axios
      .get("https://extreme-ip-lookup.com/json/")
      .then((response) => {
        let data = response.data;
        const countryPhoneCode = CountryCodeList.find(
          (country) => data.countryCode === country.code
        );
        this.setState({
          countryName: data.country,
          countryCode: data.countryCode,
          countryPhoneCode: countryPhoneCode.dial_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    this.getGeoInfo();
    window.addEventListener("resize", this.handleResize);
    axios({
      method: "GET",
      url: `/admin/teams?view=table`,
    })
      .then((response) => {
        this.setState({
          teams: response.data.teams,
        });
      })
      .catch((error) => console.log("error", error));
    axios({
      method: "GET",
      url: `/admin/roles`,
    })
      .then((response) => {
        this.setState({
          roles: response.data.roles,
        });
      })
      .catch((error) => console.log("error", error));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    // const phoneValidator =
    //   /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    const data = this.state;
    if (!data.name || !data.email || !data.formatted_mobile || !data.roleId) {
      this.setState({ errorMessage: true });
      return false;
    } else if (data.team && !data.teamRole) {
      this.setState({ errorMessage: true });
      return false;
    } else if (
      this.state.formatted_mobile !== null &&
      this.state.formatted_mobile !== "" &&
      !phoneValidator.test(this.state.formatted_mobile)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/admin/users`,
      data: {
        name: this.state.name,
        email: this.state.email,
        formatted_mobile: this.state.formatted_mobile,
        role_id: this.state.roleId,
        country_code: this.state.countryPhoneCode.substring(1),
        ...(this.state.team !== null &&
          this.state.team !== "" && {
            teams: [
              {
                id: this.state.team === "" ? null : this.state.team,
                role: this.state.teamRole === "" ? null : this.state.teamRole,
              },
            ],
          }),
      },
    })
      .then((res) => {
        this.handleOpenSuccessModal();
        this.props.fetchUsers();
        this.props.fetchAllUsers();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          NotificationManager.error(error.response.data.message);
          this.setState({ isLoading: false });
        }
        this.setState({ isLoading: false });
      })
      .finally(() => {
        this.setState({
          name: "",
          email: "",
          formatted_mobile: "",
          roleId: "",
          team: "",
          teamRole: "",
          errorMessage: false,
          phoneValidationError: false,
          emailValidationError: false,
        });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "85vh", top: "6%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Add User</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <NotificationContainer />
              <CreateUserSuccessModal
                showOpportunityModal={this.state.showSuccessModal}
                setShowOpportunityModal={this.handleOpenSuccessModal}
                handleCloseModal={this.handleCloseSuccessModal}
                handleParentClose={this.props.handleCloseModal}
              />
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "150%",
                  color: "#1778F2",
                  background: "#F2FCFF",
                  border: "1px solid #1778F2",
                  padding: "5px",
                  marginBottom: "10px",
                  borderRadius: "8px",
                }}
              >
                Note: You will be charged according to your current billing plan
                on adding new user
              </div>
              <form onSubmit={this.submitHandler}>
                <label>Name</label>
                <input
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.name === null || this.state.name === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? "Name is required"
                    : null}
                </div>
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.email === null || this.state.email === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.email === null || this.state.email === "")
                    ? "Email is required"
                    : null}
                </div>
                {this.state.emailValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid email
                  </div>
                )}
                <label>Phone</label>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "50%",
                      fontSize: "14px",
                    }}
                  >
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      styles={colourStyles}
                      value={{
                        value: this.state.countryPhoneCode,
                        label: this.state.countryPhoneCode,
                      }}
                      onChange={(selected) =>
                        this.setState({ countryPhoneCode: selected.value })
                      }
                      isClearable={false}
                      isSearchable={true}
                      name="countryPhoneCode"
                      options={CountryCodeList?.map((currency) => ({
                        value: currency.dial_code,
                        label: `${currency.dial_code} ${currency.code}`,
                      }))}
                    />
                  </div>
                  <input
                    name="formatted_mobile"
                    value={this.state.formatted_mobile}
                    onChange={this.changeHandler}
                    style={
                      this.state.errorMessage === true &&
                      (this.state.formatted_mobile === null ||
                        this.state.formatted_mobile === "")
                        ? {
                            border: "2px solid #F36363",
                          }
                        : {}
                    }
                  />
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.formatted_mobile === null ||
                    this.state.formatted_mobile === "")
                    ? "Phone Number is required"
                    : null}
                </div>
                {this.state.phoneValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid phone number
                  </div>
                )}
                <label>Role</label>
                <select
                  name="roleId"
                  value={this.state.roleId}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.roleId === null || this.state.roleId === "")
                      ? {
                          border: "2px solid #F36363",
                          width: "100%",
                        }
                      : { width: "100%" }
                  }
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state.roles.map((role) => {
                    const { name, id } = role;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.roleId === null || this.state.roleId === "")
                    ? "Please select a role for user"
                    : null}
                </div>
                <label>Team</label>
                <select
                  name="team"
                  value={this.state.team}
                  onChange={this.changeHandler}
                  style={{ width: "100%" }}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state.teams?.map((team) => {
                    const { id, name } = team;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <label>Team Role</label>
                <select
                  name="teamRole"
                  value={this.state.teamRole}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    this.state.team &&
                    (this.state.teamRole === null || this.state.teamRole === "")
                      ? {
                          border: "2px solid #F36363",
                          width: "100%",
                        }
                      : { width: "100%" }
                  }
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  <option value="leader">Leader</option>
                  <option value="member">Member</option>
                </select>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  this.state.team &&
                  (this.state.teamRole === null || this.state.teamRole === "")
                    ? "Please select a role for user in the team"
                    : null}
                </div>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    allUsers: state.allUsers,
  };
};
const MDP = (dispatch) => {
  return {
    fetchAllUsers: (usersData) => dispatch(fetchAllUsers(usersData)),
  };
};

export default connect(MSP, MDP)(CreateUserModal);
