import React, { Component } from "react";
import ReactModal from "react-modal";

class FunnelDeleteModal extends Component {
  state = {
    showModal: true,
    funnelTransfer: "",
    errorMessage: false,
    newSelectedFunnel: {},
    stageMapping: [],
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  funnelTransferChangeHandler = (e) => {
    const { name, value } = e.target;
    const newSelectedFunnel = this.props.allFunnels
      ?.map((funnel) => {
        return funnel.id === parseInt(value) && funnel;
      })
      .filter((funnel) => funnel !== false);
    this.setState({
      newSelectedFunnel: newSelectedFunnel[0],
      [name]: value,
    });
  };
  funnelMappingChangeHandler = (stage_id, e) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const stageMappingInitial = [
        ...prevState.stageMapping,
        { stage_id, new_stage_id: parseInt(value) },
      ];
      const checkDuplicates = stageMappingInitial?.filter(
        (stage) => stage.stage_id === stage_id
      );
      const notDuplicates = stageMappingInitial?.filter(
        (stage) => stage.stage_id !== stage_id
      );
      const match =
        checkDuplicates.length > 1 ? [checkDuplicates[1]] : checkDuplicates;
      const stageMapping = notDuplicates.concat(match);
      return {
        stageMapping,
      };
    });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    console.log("FunnelDeleteModal", this.state.stageMapping);
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "80vh", top: "5%", width: "550px", left: "30%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Funnel</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form>
                <p>
                  Are you sure you want to delete{" "}
                  <strong>{this.props.name}</strong>?
                  <br />
                  By deleting this funnel all deals associated with it will be
                  transferred to the new funnel
                </p>
                {this.props.selectedFunnel.is_default && (
                  <div>
                    <label style={{ fontWeight: "500" }}>
                      Select new primary funnel
                    </label>
                    {this.props.allFunnels
                      ?.filter(
                        (funnel) => funnel.id !== this.props.selectedFunnel.id
                      )
                      .map((funnel) => (
                        <span
                          style={{ marginRight: "12px" }}
                          key={`${funnel.id}${funnel.name}checkboax`}
                        >
                          <input
                            type="radio"
                            name="isDefaultFunnel"
                            style={{ width: "20px", display: "inline-block" }}
                            value={funnel.id}
                            id={`${funnel.id}${funnel.name}checkboax`}
                            onChange={() =>
                              this.props.isDefaultChangeHandler(funnel.id)
                            }
                          />
                          <label
                            htmlFor={`${funnel.id}${funnel.name}checkboax`}
                            style={{
                              display: "inline-block",
                              cursor: "pointer",
                            }}
                          >
                            {funnel.name}
                          </label>
                        </span>
                      ))}
                  </div>
                )}
                <label style={{ fontWeight: "500" }}>Select New Funnel</label>
                <select
                  name="funnelTransfer"
                  value={this.state.funnelTransfer}
                  onChange={this.funnelTransferChangeHandler}
                  style={{ width: "auto" }}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.props.allFunnels
                    ?.filter(
                      (funnel) => funnel.id !== this.props.selectedFunnel.id
                    )
                    ?.map((funnel) => (
                      <option key={funnel.id} value={funnel.id}>
                        {funnel.name}
                      </option>
                    ))}
                </select>
                {this.state.errorMessage === true &&
                this.state.funnelTransfer === "" ? (
                  <div style={{ color: "red" }}>Please select a funnel</div>
                ) : null}
                <label style={{ fontWeight: "500" }}>Map Stages</label>
                <div
                  style={{
                    maxHeight: "43vh",
                    height: "auto",
                    overflowY: "auto",
                  }}
                >
                  {this.props.selectedFunnel?.stages?.map((stage) => {
                    const { id, name } = stage;
                    return (
                      <span
                        key={id}
                        style={{
                          display: "inline-block",
                          margin: "10px",
                          padding: "15px",
                          border: "2px solid #E3E3E3",
                          borderRadius: "8px",
                        }}
                      >
                        <label>Deleted Funnel Stage</label>
                        {name}
                        <label>New Funnel Stage</label>
                        <select
                          onChange={(e) =>
                            this.funnelMappingChangeHandler(id, e)
                          }
                        >
                          <option value="" selected disabled>
                            -Select-
                          </option>
                          {this.state.newSelectedFunnel?.stages?.map(
                            (stage) => (
                              <option key={stage.id} value={stage.id}>
                                {stage.name}
                              </option>
                            )
                          )}
                        </select>
                      </span>
                    );
                  })}
                </div>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (this.state.funnelTransfer === "") {
                        this.setState({
                          errorMessage: true,
                        });
                        return;
                      } else {
                        this.props.deleteFunnelHandler(
                          this.state.funnelTransfer,
                          this.state.stageMapping
                        );
                      }
                    }}
                    className="modal__confirm-button"
                  >
                    Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default FunnelDeleteModal;
