import React, { Component } from "react";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Col, Row } from "react-grid-system";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Pagination from "react-js-pagination";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
} from "../../../../redux";
import DashboardLoader from "../../../Loaders/DashboardLoader";

export class ReportsActivityDashboard extends Component {
  state = {
    isInitializing: true,
    filter: "",
    operator: "",
    filter_rules: [{ id: uuidv4(), attribute: "", operator: "", value: null }],
    reportsTable: [],
    showColumn: false,
    displayMenu: false,
    isEditing: false,
    reportName: this.props.reportName,
    reportDescription: "",
    errorMessage: false,
    sortKey: null,
    displayExitMenu: false,
    sortDirection: false,
    isLoading: false,
    columnsArray: [
      { name: "Owner", key: "activity_owner", selected: true },
      { name: "Account", key: "account_name", selected: true },
      { name: "Deal", key: "opportunity_name", selected: true },
      { name: "Type", key: "category_id", selected: true },
      { name: "Result", key: "result_id", selected: true },
      { name: "Description", key: "description", selected: true },
      {
        name: "Last Activity Date",
        key: "last_activity_date",
        selected: false,
      },
      { name: "Task Name", key: "activity_name", selected: false },
      { name: "Account Status", key: "account_status", selected: false },
      { name: "Deal Stage", key: "opportunity_stage", selected: false },
      { name: "Account City", key: "account_city", selected: false },
      { name: "Account State", key: "account_state", selected: false },
      { name: "Account Zip", key: "account_zip", selected: false },
      { name: "Account Country", key: "account_country", selected: false },
      { name: "Deal City", key: "opportunity_city", selected: false },
      { name: "Deal State", key: "opportunity_state", selected: false },
      { name: "Deal Zip", key: "opportunity_zip", selected: false },
      { name: "Deal Country", key: "opportunity_country", selected: false },
    ],
    results: [],
    categories: [],
    columnsData: [],
    showDeleteModal: false,
    showExitModal: false,
    activePage: 1,
    total: 0,
    isApplying: false,
    access: "everyone",
    analyticsMode: "table",
  };
  getReportById = () => {
    axios({
      method: "GET",
      url: `/reports/${this.props.reportId}`,
    }).then((res) =>
      this.setState(
        {
          reportsData: res.data.report,
          reportType: res.data.report.type,
          reportName: res.data.report.name,
          access: res.data.report.access,
          reportDescription: res.data.report.description,
          filter_rules: res.data.report.filters?.map((rule) => {
            const { operator, value } = rule;
            if (
              this.props.userId &&
              this.props.selectedUsers.length !== 0 &&
              operator === "only_me"
            ) {
              rule.operator = "is_in";
              rule.value = this.props.selectedUsers.map((user) => ({
                value: user.name,
                label: user.name,
              }));
            }
            if (operator === "is_in" || operator === "is_not_in") {
              if (rule.attribute === "result") {
                rule.value = value.map((val) => ({
                  value: val,
                  label: this.state.results.find((stage) => stage.id === val)
                    ?.name,
                }));
              } else {
                rule.value = value.map((val) => ({ value: val, label: val }));
              }
            }
            return rule;
          }),
          columnsData: res.data.report.columns,
          columnsArray: [
            {
              name: "Owner",
              key: "activity_owner",
              selected: res.data.report.columns.includes("activity_owner")
                ? true
                : false,
            },
            {
              name: "Account",
              key: "account_name",
              selected: res.data.report.columns.includes("account_name")
                ? true
                : false,
            },
            {
              name: "Deal",
              key: "opportunity_name",
              selected: res.data.report.columns.includes("opportunity_name")
                ? true
                : false,
            },
            {
              name: "Type",
              key: "category_id",
              selected: res.data.report.columns.includes("category_id")
                ? true
                : false,
            },
            {
              name: "Result",
              key: "result_id",
              selected: res.data.report.columns.includes("result_id")
                ? true
                : false,
            },
            {
              name: "Description",
              key: "description",
              selected: res.data.report.columns.includes("description")
                ? true
                : false,
            },
            {
              name: "Last Activity Date",
              key: "last_activity_date",
              selected: res.data.report.columns.includes("last_activity_date")
                ? true
                : false,
            },
            {
              name: "Task Name",
              key: "activity_name",
              selected: res.data.report.columns.includes("activity_name")
                ? true
                : false,
            },
            {
              name: "Account Status",
              key: "account_status",
              selected: res.data.report.columns.includes("account_status")
                ? true
                : false,
            },
            {
              name: "Deal Stage",
              key: "opportunity_stage",
              selected: res.data.report.columns.includes("opportunity_stage")
                ? true
                : false,
            },
            {
              name: "Account City",
              key: "account_city",
              selected: res.data.report.columns.includes("account_city")
                ? true
                : false,
            },
            {
              name: "Account State",
              key: "account_state",
              selected: res.data.report.columns.includes("account_state")
                ? true
                : false,
            },
            {
              name: "Account Zip",
              key: "account_zip",
              selected: res.data.report.columns.includes("account_zip")
                ? true
                : false,
            },
            {
              name: "Account Country",
              key: "account_country",
              selected: res.data.report.columns.includes("account_country")
                ? true
                : false,
            },
            {
              name: "Deal City",
              key: "opportunity_city",
              selected: res.data.report.columns.includes("opportunity_city")
                ? true
                : false,
            },
            {
              name: "Deal State",
              key: "opportunity_state",
              selected: res.data.report.columns.includes("opportunity_state")
                ? true
                : false,
            },
            {
              name: "Deal Zip",
              key: "opportunity_zip",
              selected: res.data.report.columns.includes("opportunity_zip")
                ? true
                : false,
            },
            {
              name: "Deal Country",
              key: "opportunity_country",
              selected: res.data.report.columns.includes("opportunity_country")
                ? true
                : false,
            },
          ],
        },
        () => this.getReportInitializer(res.data.report.order_by),
        () => this.filterRulesIsIn()
      )
    );
  };
  componentDidMount() {
    this.getReportById();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.userId !== prevProps.userId) {
      this.getReportById();
    }
  }
  getReportInitializer = (orderby, page = 1) => {
    const orderBy = orderby?.split(",");
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    this.setState({
      sortKey: orderby === null ? null : orderBy[0],
      sortDirection:
        orderby === null ? null : orderBy[1] === "desc" ? true : false,
    });
    axios({
      method: "POST",
      url: `/${this.state.reportType}/reports?_limit=30&_page=${page}`,
      data: {
        filter_rules: newValue,
        sort_key: orderby === null ? null : orderBy[0],
        sort_dir: orderby === null ? null : orderBy[1],
      },
    })
      .then((res) => {
        this.setState({
          reportsTable: res.data.activities?.results,
          total: res.data.total,
          isInitializing: false,
        });
      })
      .catch((err) => this.setState({ isInitializing: false }));
  };
  filterRulesIsIn = () => {
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules?.map((rule) => {
        const { operator, value } = rule;
        if (
          this.props.userId &&
          this.props.selectedUsers.length !== 0 &&
          operator === "only_me"
        ) {
          rule.operator = "is_in";
          rule.value = this.props.selectedUsers.map((user) => ({
            value: user.name,
            label: user.name,
          }));
        }
        if (operator === "is_in" || operator === "is_not_in") {
          rule.value = value.map((val) => ({ value: val, label: val }));
        }
        return rule;
      }),
    }));
  };
  getReport = (page = 1) => {
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      return rule;
    });
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    this.setState({ isApplying: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/activities/reports?_limit=30&_page=${page}`,
        data: {
          filter_rules: newValue,
          sort_key: this.state.sortKey,
          sort_dir: this.state.sortDirection === true ? "desc" : "asc",
        },
      })
        .then((res) => {
          this.setState({
            reportsTable: res.data.activities.results,
            total: res.data.total,
            isApplying: false,
          });
        })
        .catch((err) => this.setState({ isApplying: false }));
    } else {
      this.setState({ isApplying: false });
    }
  };
  render() {
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const { columnsArray } = this.state;
    return (
      <div style={{ paddingTop: "55px" }}>
        {!this.props.fromModal && (
          <h3
            className="funnel-heading-sub"
            style={{
              marginBottom: "0",
              textDecoration: "underline",
              zIndex: "1",
              cursor: "pointer",
            }}
            onClick={this.props.chartRedirectToReport}
          >
            {this.state.reportName}
          </h3>
        )}
        {this.state.isInitializing === true ? (
          <DashboardLoader />
        ) : this.state.isInitializing === false &&
          this.state.reportsTable?.length === 0 ? (
          <div
            style={{
              margin: "auto",
              fontWeight: "500",
            }}
          >
            No records found.
          </div>
        ) : this.state.isApplying ? (
          <DashboardLoader />
        ) : (
          <div
            style={{
              overflow: "auto",
              maxHeight: this.props.maxHeight,
              ...(this.props.maxWidth && {
                maxWidth: this.props.maxWidth,
              }),
            }}
          >
            <table className="accountList__table" style={{ marginTop: "0" }}>
              <thead>
                {columnsArray?.map(
                  (column) =>
                    this.state?.columnsData.includes(column.key) && (
                      <th key={column.key}>
                        <Row>
                          <Col lg={9} xl={9}>
                            {column.name}
                          </Col>
                          <Col lg={3} xl={3}>
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                                outline: "none",
                                color: "#657885",
                              }}
                              onClick={() =>
                                this.setState(
                                  {
                                    sortKey: column.custom
                                      ? column.id
                                      : column.key,
                                    sortDirection: !this.state.sortDirection,
                                  },
                                  this.storeSorting
                                )
                              }
                            >
                              <i className="fa fa-long-arrow-up"></i>
                              <i className="fa fa-long-arrow-down"></i>
                            </button>
                          </Col>
                        </Row>
                      </th>
                    )
                )}
              </thead>
              <tbody>
                {this.state?.reportsTable?.map((report) => {
                  const {
                    id,
                    activity_owner,
                    category,
                    result,
                    activity_name,
                    description,
                    account_name,
                    opportunity_name,
                    account_status,
                    opportunity_stage,
                    account_state,
                    account_zip,
                    account_country,
                    opportunity_city,
                    opportunity_state,
                    opportunity_zip,
                    opportunity_country,
                    last_activity_date,
                  } = report;
                  return (
                    <tr key={id}>
                      {this.state?.columnsData.includes("activity_owner") && (
                        <td>{activity_owner}</td>
                      )}
                      {this.state?.columnsData.includes("account_name") && (
                        <td>{account_name}</td>
                      )}
                      {this.state?.columnsData.includes("opportunity_name") && (
                        <td>{opportunity_name}</td>
                      )}
                      {this.state?.columnsData.includes("category_id") && (
                        <td>{category}</td>
                      )}
                      {this.state?.columnsData.includes("result_id") && (
                        <td>{result}</td>
                      )}
                      {this.state?.columnsData.includes("description") && (
                        <td>
                          {description?.length > 140
                            ? description?.substring(0, 140)
                            : description}
                        </td>
                      )}
                      {this.state?.columnsData.includes(
                        "last_activity_date"
                      ) && (
                        <td>
                          {(last_activity_date &&
                            moment(last_activity_date).format(dateFormat)) ||
                            null}
                        </td>
                      )}
                      {this.state?.columnsData.includes("activity_name") && (
                        <td>{activity_name}</td>
                      )}
                      {this.state?.columnsData.includes("account_status") && (
                        <td>{account_status}</td>
                      )}
                      {this.state?.columnsData.includes(
                        "opportunity_stage"
                      ) && <td>{opportunity_stage}</td>}
                      {this.state?.columnsData.includes("account_city") && (
                        <td>{report.account_city}</td>
                      )}
                      {this.state?.columnsData.includes("account_state") && (
                        <td>{account_state}</td>
                      )}
                      {this.state?.columnsData.includes("account_zip") && (
                        <td>{account_zip || null}</td>
                      )}
                      {this.state?.columnsData.includes("account_country") && (
                        <td>{account_country}</td>
                      )}
                      {this.state?.columnsData.includes("opportunity_city") && (
                        <td>{opportunity_city}</td>
                      )}
                      {this.state?.columnsData.includes(
                        "opportunity_state"
                      ) && <td>{opportunity_state}</td>}
                      {this.state?.columnsData.includes("opportunity_zip") && (
                        <td>{opportunity_zip}</td>
                      )}
                      {this.state?.columnsData.includes(
                        "opportunity_country"
                      ) && <td>{opportunity_country}</td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div style={{ marginTop: "8px" }}>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={30}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              this.setState({ activePage: page });
              this.getReport(page);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        </div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};
export default connect(MSP, MDP)(ReportsActivityDashboard);
