import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import Collapsible from "react-collapsible";
import ArrowIcon from "../../images/Arrow.svg";
import NotificationManager from "react-notifications/lib/NotificationManager";
import ReactTooltip from "react-tooltip";
import moment from "moment";

class EditRoleModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    name: "",
    permissions: [],
    permissionsValue: [],
    description: "",
    accountShow: true,
    targetShow: false,
    settingsShow: false,
    accountView: "crm:all:viewer",
    accountEdit: "crm:all:editor",
    accountCommunicate: "crm:all:communicator",
    targetEdit: false,
    targetEditValue: "",
    exporter: false,
    exporterValue: "",
    dealClosed: false,
    dealClosedValue: "",
    importer: false,
    importerValue: "",
    addUser: false,
    addUserValue: "",
    bulkDelete: false,
    bulkDeleteValue: "",
    unassignedLeads: false,
    unassignedLeadsValue: "",
    customize: false,
    customizeValue: "",
    errorMessage: false,
    isLoading: false,
    accountLimit: null,
    activityDaysLimit: null,
    clientDaysLimit: null,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const permissionsProps = this.props.permissions.map((p) => p.name);
    this.setState({
      name: this.props.name,
      description: this.props.description,
      accountLimit: this.props.accountLimit,
      activityDaysLimit: this.props.activityDaysLimit,
      clientDaysLimit: this.props.clientDaysLimit,
    });
    if (permissionsProps.includes("crm:teams:targets-goals:editor")) {
      this.setState({
        targetEdit: true,
        targetEditValue: "crm:teams:targets-goals:editor",
      });
    } else {
      this.setState({
        targetEdit: false,
        targetEditValue: "",
      });
    }
    if (permissionsProps.includes("crm:importer")) {
      this.setState({
        importer: true,
        importerValue: "crm:importer",
      });
    } else {
      this.setState({
        importer: false,
        importerValue: "",
      });
    }
    if (permissionsProps.includes("crm:closed:deal:editor")) {
      this.setState({
        dealClosed: true,
        dealClosedValue: "crm:closed:deal:editor",
      });
    } else {
      this.setState({
        dealClosed: false,
        dealClosedValue: "",
      });
    }
    if (permissionsProps.includes("crm:exporter")) {
      this.setState({
        exporter: true,
        exporterValue: "crm:exporter",
      });
    } else {
      this.setState({
        exporter: false,
        exporterValue: "",
      });
    }
    if (permissionsProps.includes("crm:add:user")) {
      this.setState({
        addUser: true,
        addUserValue: "crm:add:user",
      });
    } else {
      this.setState({
        addUser: false,
        addUserValue: "",
      });
    }
    if (permissionsProps.includes("crm:bulk:records:delete")) {
      this.setState({
        bulkDelete: true,
        bulkDeleteValue: "crm:bulk:records:delete",
      });
    } else {
      this.setState({
        bulkDelete: false,
        bulkDeleteValue: "",
      });
    }
    if (permissionsProps.includes("crm:unassigned:leads:viewer")) {
      this.setState({
        unassignedLeads: true,
        unassignedLeadsValue: "crm:unassigned:leads:viewer",
      });
    } else {
      this.setState({
        unassignedLeads: false,
        unassignedLeadsValue: "",
      });
    }
    if (permissionsProps.includes("crm:all:customizer")) {
      this.setState({
        customize: true,
        customizeValue: "crm:all:customizer",
      });
    } else {
      this.setState({
        customize: false,
        customizeValue: "",
      });
    }
    if (permissionsProps.includes("crm:all:viewer")) {
      this.setState({
        accountView: "crm:all:viewer",
      });
    } else if (permissionsProps.includes("crm:team:only:viewer")) {
      this.setState({
        accountView: "crm:team:only:viewer",
      });
    } else {
      this.setState({
        accountView: "crm:owned:only:viewer",
      });
    }

    if (permissionsProps.includes("crm:all:editor")) {
      this.setState({
        accountEdit: "crm:all:editor",
      });
    } else if (permissionsProps.includes("crm:team:only:editor")) {
      this.setState({
        accountEdit: "crm:team:only:editor",
      });
    } else {
      this.setState({
        accountEdit: "crm:owned:only:editor",
      });
    }

    if (permissionsProps.includes("crm:all:communicator")) {
      this.setState({
        accountCommunicate: "crm:add:user",
      });
    } else {
      this.setState({
        accountCommunicate: "crm:team:only:communicator",
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      permissions: [
        this.state.accountView,
        this.state.accountEdit,
        this.state.accountCommunicate,
        this.state.targetEditValue,
        this.state.importerValue,
        this.state.dealClosedValue,
        this.state.exporterValue,
        this.state.addUserValue,
        this.state.bulkDeleteValue,
        this.state.unassignedLeadsValue,
      ],
      permissionsValue: this.state.permissions.filter(
        (permission) => permission !== ""
      ),
    });
  };
  booleanChangeHandler = (e) => {
    if (this.state.targetEdit === true) {
      this.setState({ targetEditValue: "crm:teams:targets-goals:editor" });
    }
    if (this.state.targetEdit === false) {
      this.setState({ targetEditValue: "" });
    }
    if (this.state.importer === true) {
      this.setState({ importerValue: "crm:importer" });
    }
    if (this.state.importer === false) {
      this.setState({ importerValue: "" });
    }
    if (this.state.exporter === true) {
      this.setState({ exporterValue: "crm:exporter" });
    }
    if (this.state.exporter === false) {
      this.setState({ exporterValue: "" });
    }
    if (this.state.dealClosed === true) {
      this.setState({ dealClosedValue: "crm:closed:deal:editor" });
    }
    if (this.state.dealClosed === false) {
      this.setState({ dealClosedValue: "" });
    }
    if (this.state.addUser === true) {
      this.setState({ addUserValue: "crm:add:user" });
    }
    if (this.state.addUser === false) {
      this.setState({ addUserValue: "" });
    }
    if (this.state.bulkDelete === true) {
      this.setState({ bulkDeleteValue: "crm:bulk:records:delete" });
    }
    if (this.state.bulkDelete === false) {
      this.setState({ bulkDeleteValue: "" });
    }
    if (this.state.unassignedLeads === true) {
      this.setState({ unassignedLeadsValue: "crm:unassigned:leads:viewer" });
    }
    if (this.state.unassignedLeads === false) {
      this.setState({ unassignedLeadsValue: "" });
    }
    if (this.state.customize === true) {
      this.setState({ customizeValue: "crm:all:customizer" });
    }
    if (this.state.customize === false) {
      this.setState({ customizeValue: "" });
    }
    this.setState({
      permissions: [
        this.state.accountView,
        this.state.accountEdit,
        this.state.accountCommunicate,
        this.state.targetEditValue,
        this.state.importerValue,
        this.state.exporterValue,
        this.state.dealClosedValue,
        this.state.addUserValue,
        this.state.bulkDeleteValue,
        this.state.unassignedLeadsValue,
      ],
      permissionsValue: this.state.permissions.filter(
        (permission) => permission !== ""
      ),
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (!data.name || !data.description) {
      this.setState({ errorMessage: true });
      return false;
    }
    const permissions = [
      this.state.accountView,
      this.state.accountEdit,
      this.state.accountCommunicate,
      this.state.targetEditValue,
      this.state.importerValue,
      this.state.exporterValue,
      this.state.dealClosedValue,
      this.state.addUserValue,
      this.state.bulkDeleteValue,
      this.state.unassignedLeadsValue,
      this.state.customizeValue,
    ];
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/admin/role/${this.props.roleId}`,
      data: {
        name: this.state.name,
        description: this.state.description,
        account_limit: this.state.accountLimit ? this.state.accountLimit : null,
        activity_days_limit: this.state.activityDaysLimit
          ? this.state.activityDaysLimit
          : null,
        account_status_days_limit: this.state.clientDaysLimit
          ? this.state.clientDaysLimit
          : null,
        is_activity_days_limit_enabled: this.state.activityDaysLimit
          ? true
          : false,
        is_account_status_days_limit_enabled: this.state.clientDaysLimit
          ? true
          : false,
        permissions: permissions.filter((permission) => permission !== ""),
      },
    })
      .then((res) => {
        this.props.fetchRoles();
        this.props.handleCloseModal();
        NotificationManager.success("Role edited successfully.");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        NotificationManager.error("Error editing role.");
        this.setState({ isLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "89vh", top: "2%", width: "50vw", left: "22%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Edit Role</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <label>Name</label>
                <input
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.name === null || this.state.name === "")
                      ? {
                          border: "2px solid #F36363",
                          width: "97%",
                        }
                      : { width: "97%" }
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? "Name is required"
                    : null}
                </div>
                <label>Description</label>
                <input
                  name="description"
                  type="text"
                  value={this.state.description}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.description === null ||
                      this.state.description === "")
                      ? {
                          border: "2px solid #F36363",
                          width: "97%",
                          marginBottom: "10px",
                        }
                      : { width: "97%", marginBottom: "10px" }
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.description === null ||
                    this.state.description === "")
                    ? "Description is required"
                    : null}
                </div>
                <div>
                  <Collapsible
                    open={true}
                    trigger={
                      <div>
                        Accounts, Contacts, Deals, & Reports
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            padding: "5px 10px",
                            border: "none",
                            borderRadius: "5px",
                            color: "white",
                            outline: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "30%",
                            right: "2%",
                          }}
                        >
                          <img src={ArrowIcon} alt="" height="10px" />
                        </button>
                      </div>
                    }
                    className="collapseRoleHeader"
                    classParentString="collapseRoleHeader"
                    triggerStyle={{
                      background: "#F5F5F5",
                      height: "45px",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    triggerWhenOpen="Accounts, Contacts, Deals, & Reports"
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "33% 33% 33%",
                      }}
                    >
                      <div>
                        <label style={{ marginTop: "10px" }}>View</label>
                        <select
                          name="accountView"
                          value={this.state.accountView}
                          onChange={this.changeHandler}
                          style={{ width: "150px" }}
                        >
                          <option value="crm:all:viewer">Everything</option>
                          <option value="crm:team:only:viewer">
                            Team Only
                          </option>
                          <option value="crm:owned:only:viewer">
                            Owned Only
                          </option>
                        </select>
                      </div>
                      <div>
                        <label style={{ marginTop: "10px" }}>Edit</label>
                        <select
                          name="accountEdit"
                          value={this.state.accountEdit}
                          onChange={this.changeHandler}
                          style={{ width: "150px" }}
                        >
                          <option value="crm:all:editor">Everything</option>
                          <option value="crm:team:only:editor">
                            Team Only
                          </option>
                          <option value="crm:owned:only:editor">
                            Owned Only
                          </option>
                        </select>
                      </div>
                      <div>
                        <label style={{ marginTop: "10px" }}>Communicate</label>
                        <select
                          name="accountCommunicate"
                          value={this.state.accountCommunicate}
                          onChange={this.changeHandler}
                          style={{ width: "150px" }}
                        >
                          <option value="crm:all:communicator">
                            Everything
                          </option>
                          <option value="crm:team:only:communicator">
                            Team Only
                          </option>
                        </select>
                      </div>
                    </div>
                  </Collapsible>
                  <Collapsible
                    open={true}
                    trigger={
                      <div>
                        Settings
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            padding: "3px 10px",
                            border: "none",
                            borderRadius: "5px",
                            color: "white",
                            outline: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "30%",
                            right: "2%",
                          }}
                        >
                          <img src={ArrowIcon} alt="" height="10px" />
                        </button>
                      </div>
                    }
                    className="collapseRoleHeader"
                    classParentString="collapseRoleHeader"
                    triggerStyle={{
                      background: "#F5F5F5",
                      height: "45px",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    triggerWhenOpen="Settings"
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "33% 33% 33%",
                      }}
                    >
                      <div>
                        <input
                          type="checkbox"
                          id="perImporter"
                          style={{ width: "20px", display: "inline-block" }}
                          value={this.state.importer}
                          checked={this.state.importer}
                          onChange={() => {
                            this.setState(
                              { importer: !this.state.importer },
                              this.booleanChangeHandler
                            );
                          }}
                        />
                        <label
                          htmlFor="perImporter"
                          style={{ marginTop: "10px", display: "inline-block" }}
                        >
                          Import
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="perExporter"
                          style={{ width: "20px", display: "inline-block" }}
                          value={this.state.exporter}
                          checked={this.state.exporter}
                          onChange={() => {
                            this.setState(
                              { exporter: !this.state.exporter },
                              this.booleanChangeHandler
                            );
                          }}
                        />
                        <label
                          htmlFor="perExporter"
                          style={{ display: "inline-block" }}
                        >
                          Export
                        </label>
                      </div>
                      <ReactTooltip />
                      <div>
                        <input
                          type="checkbox"
                          id="perClosedDeal"
                          style={{ width: "20px", display: "inline-block" }}
                          value={this.state.dealClosed}
                          checked={this.state.dealClosed}
                          onChange={() => {
                            this.setState(
                              { dealClosed: !this.state.dealClosed },
                              this.booleanChangeHandler
                            );
                          }}
                        />
                        <label
                          htmlFor="perClosedDeal"
                          style={{ display: "inline-block" }}
                        >
                          Closed Deal Stage Change &nbsp;&nbsp;
                          <span
                            data-tip="Allows users to change a deal stage after it has been marked Won or Lost."
                            style={{
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#62CA9D",
                              padding: "0px 5.3px",
                              height: "14px",
                              borderRadius: "50%",
                              border: "1px solid #62CA9D",
                            }}
                            data-multiline={true}
                          >
                            i
                          </span>
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="perAddEditUser"
                          style={{ width: "20px", display: "inline-block" }}
                          value={this.state.addUser}
                          checked={this.state.addUser}
                          onChange={() => {
                            this.setState(
                              { addUser: !this.state.addUser },
                              this.booleanChangeHandler
                            );
                          }}
                        />
                        <label
                          htmlFor="perAddEditUser"
                          style={{ display: "inline-block" }}
                        >
                          Add and Edit users
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="perbulkDelete"
                          style={{ width: "20px", display: "inline-block" }}
                          value={this.state.bulkDelete}
                          checked={this.state.bulkDelete}
                          onChange={() => {
                            this.setState(
                              { bulkDelete: !this.state.bulkDelete },
                              this.booleanChangeHandler
                            );
                          }}
                        />
                        <label
                          htmlFor="perbulkDelete"
                          style={{ display: "inline-block" }}
                        >
                          Bulk Delete Records
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="perunassignedLeads"
                          style={{ width: "20px", display: "inline-block" }}
                          value={this.state.unassignedLeads}
                          checked={this.state.unassignedLeads}
                          onChange={() => {
                            this.setState(
                              { unassignedLeads: !this.state.unassignedLeads },
                              this.booleanChangeHandler
                            );
                          }}
                        />
                        <label
                          htmlFor="perunassignedLeads"
                          style={{ display: "inline-block" }}
                        >
                          Unassigned Leads Access&nbsp;&nbsp;
                          <span
                            data-tip="This would allow the users within this role to be able to access and change ownership for your Unassigned Leads."
                            style={{
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#62CA9D",
                              padding: "0px 5.3px",
                              height: "14px",
                              borderRadius: "50%",
                              border: "1px solid #62CA9D",
                            }}
                            data-multiline={true}
                          >
                            i
                          </span>
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          style={{ width: "20px", display: "inline-block" }}
                          value={this.state.customize}
                          checked={this.state.customize}
                          id="perCustomize"
                          onChange={() => {
                            this.setState(
                              { customize: !this.state.customize },
                              this.booleanChangeHandler
                            );
                          }}
                        />
                        <label
                          htmlFor="perCustomize"
                          style={{ display: "inline-block" }}
                        >
                          All Customization
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            style={{ width: "20px", display: "inline-block" }}
                            value={this.state.targetEdit}
                            checked={this.state.targetEdit}
                            onChange={() => {
                              this.setState(
                                { targetEdit: !this.state.targetEdit },
                                this.booleanChangeHandler
                              );
                            }}
                          />
                          Edit Targets and Goals
                        </label>
                      </div>
                    </div>
                    <div>
                      <label style={{ marginTop: "10px" }}>
                        Max Account Limit&nbsp;&nbsp;
                        <span
                          data-tip="This sets a maximum number of Accounts that a rep can own to help prevent them from having too many in their name."
                          style={{
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#62CA9D",
                            padding: "0px 5.3px",
                            height: "14px",
                            borderRadius: "50%",
                            border: "1px solid #62CA9D",
                          }}
                          data-multiline={true}
                        >
                          i
                        </span>
                      </label>
                      <input
                        type="number"
                        value={this.state.accountLimit}
                        onChange={(e) =>
                          this.setState({ accountLimit: e.target.value })
                        }
                      />
                    </div>
                  </Collapsible>
                  <Collapsible
                    open={true}
                    trigger={
                      <div>
                        Shot Clock
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            padding: "3px 10px",
                            border: "none",
                            borderRadius: "5px",
                            color: "white",
                            outline: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "30%",
                            right: "2%",
                          }}
                        >
                          <img src={ArrowIcon} alt="" height="10px" />
                        </button>
                      </div>
                    }
                    className="collapseRoleHeader"
                    classParentString="collapseRoleHeader"
                    triggerStyle={{
                      background: "#F5F5F5",
                      height: "45px",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    triggerWhenOpen={
                      <div>
                        Shot Clock &nbsp;&nbsp;
                        <span
                          data-tip="This feature automatically moves leads from a rep’s name to unassigned that have either<br> 1) not had activity logged for a certain number of days or<br> 2) have not been marked as a Client within a certain number of days."
                          style={{
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#62CA9D",
                            padding: "0px 5.3px",
                            height: "14px",
                            borderRadius: "50%",
                            border: "1px solid #62CA9D",
                          }}
                          data-multiline={true}
                        >
                          i
                        </span>
                      </div>
                    }
                  >
                    {this.props.shotClockTime && (
                      <p>
                        Leads get automatically moved to Unassigned at{" "}
                        {moment(this.props.shotClockTime, "HH:mm").format(
                          "hh:mm A"
                        )}{" "}
                        {this.props.shotClockTimeZone}
                      </p>
                    )}
                    <label style={{ marginTop: "10px" }}>
                      Activity - How many days of no activity would you like the
                      account to move to Unassigned Leads?
                    </label>
                    <input
                      value={this.state.activityDaysLimit}
                      type="number"
                      onChange={(e) =>
                        this.setState({
                          activityDaysLimit: e.target.value,
                        })
                      }
                    />
                    <label style={{ marginTop: "10px" }}>
                      Customer - How many days do you want to give the rep to
                      close the account (mark as Client)?
                    </label>
                    <input
                      value={this.state.clientDaysLimit}
                      type="number"
                      onChange={(e) =>
                        this.setState({
                          clientDaysLimit: e.target.value,
                        })
                      }
                    />
                  </Collapsible>
                </div>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                    style={{ right: "8%" }}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default EditRoleModal;
