import React, { useState, useCallback, useEffect, useRef } from "react";
import ActivityLog from "./../ActivityLog";
import "../../sass/CustomerProfile.scss";
import Confetti from "react-confetti";

import axios from "axios";
import { BASE_URL } from "../../config";
import ContactsIntro from "./ContactsIntro";
import ContactsActivityForm from "./ContactsActivityForm";
import { NotificationManager } from "react-notifications";
import AllSequenceModal from "../Sequencing/AllSequenceModal";
import SaveEmailModal from "../SendEmail/SaveEmailModal";
import SendSequenceModal from "../Sequencing/SendSequenceModal";
import EditSequenceModal from "../Sequencing/EditSequenceModal";
import BillingCreditModal from "../Reports/BillingCreditModal";
import BillingRedirectModal from "../Reports/BillingRedirectModal";
import PostMarkAppModal from "../Reports/PostMarkAppModal";
import PostMarkAppVerificationModal from "../Reports/PostMarkAppVerificationModal";
import PersonalizedVarsModal from "../SendEmail/PersonalizedVarsModal";

// Redux stuff
import { connect } from "react-redux";
import { authUserSet, setTab, setActiveTabIndex } from "../../redux";
import NextRecordNavigation from "../NextRecordNavigation";

const ContactsProfile = ({
  contactId,
  customerName,
  tabIndex,
  deleteTab,
  authUser,
  activities,
  showTaskArrow,
  tasks,
  setTab,
  setActiveTabIndex,
  history,
  totalTabs,
  taskIndex,
}) => {
  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showAllSequenceModal, setShowAllSequenceModal] = useState(false);
  const [showSaveEmailModal, setShowSaveEmailModal] = useState(false);
  const [showSendSequenceModal, setShowSendSequenceModal] = useState(false);
  const [showEditSequenceModal, setShowEditSequenceModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [showPostMarkModal, setShowPostMarkModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showPersonalizedVarsModal, setShowPersonalizedVarsModal] =
    useState(false);

  const [logs, setLogs] = useState([]);
  const [pinnedActivity, setPinnedActivity] = useState(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [contactName, setContactName] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountId, setAccountId] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [accountData, setAccountData] = useState({});
  const [accountCustomFields, setAccountCustomFields] = useState({});
  const [signatureId, setSignatureId] = useState(null);
  const [signatureVerified, setSignatureVerified] = useState(false);
  const [emailCredits, setEmailCredits] = useState(0);
  const [selectedSequence, setSelectedSequence] = useState({});
  const [templateId, setTemplateId] = useState(null);
  const [personalizedVariablesArray, setPersonalizedVariablesArray] = useState(
    []
  );
  const [recorderLoading, setRecorderLoading] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [activityLoader, setActivityLoader] = useState(false);
  const [emailSignature, setEmailSignature] = useState("");
  const [opportunities, setOpportunities] = useState([]);
  const contactMobileRef = useRef(contactMobile);
  const contactPhoneRef = useRef(contactPhone);
  const setContactMobileRef = (data) => {
    contactMobileRef.current = data;
    setContactMobile(data);
  };
  const setContactPhoneRef = (data) => {
    contactPhoneRef.current = data;
    setContactPhone(data);
  };

  const emailDecision = () => {
    if (signatureId === null) {
      setShowPostMarkModal(true);
    } else if (!signatureVerified) {
      setShowVerificationModal(true);
    } else if (emailCredits === null || 10 > emailCredits) {
      setShowCreditsModal(true);
    } else {
      setShowAllSequenceModal(true);
    }
  };
  const getSignature = () => {
    axios({
      method: "GET",
      url: `/users/email-signature`,
    }).then((res) => {
      if (res.data.email_signature !== null) {
        setEmailSignature(res.data.email_signature);
      }
    });
  };
  const recordChangeHandler = (e) => {
    setContactEmail(e.target.value);
  };
  const fetchAllOpportunitiesContacts = () => {
    axios({
      method: "GET",
      url: `/contacts/${contactId}/opportunities?_limit=100`,
    }).then((res) => {
      const data = res.data.opportunities;
      setOpportunities(data);
    });
  };
  const recordSubmitHandler = (e) => {
    e.preventDefault();
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      contactEmail !== null &&
      contactEmail !== "" &&
      !emailValidator.test(contactEmail)
    ) {
      setRecorderLoading(false);
      setEmailValidationError(true);
      return false;
    }
    setRecorderLoading(true);
    let customField = accountCustomFields;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    axios({
      method: "PUT",
      url: `/contacts/${contactId}`,
      data: {
        name: accountData.name,
        role: accountData.role,
        source: accountData.source,
        email: accountData.email,
        formatted_mobile: accountData.formatted_mobile,
        phone_ext: accountData.phone_ext,
        formatted_phone: accountData.formatted_phone,
        date_of_birth: accountData.date_of_birth,
        zip: accountData.zip,
        city: accountData.city,
        address: accountData.address,
        state: accountData.state,
        country: accountData.country,
        preferred_communication: (
          accountData.preferred_communication || []
        ).filter((com) => com !== ""),
        contact_owner_id: accountData.contact_owner_id,
        custom_fields: customField,
        account_id: accountData.account_id,
      },
    })
      .then((response) => {
        NotificationManager.success("Contact edited successfully.");
        setRecorderLoading(false);
        setEmailValidationError(false);
        setShowSaveEmailModal(false);
        setShowAllSequenceModal(true);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing contact.");
        }
      });
  };
  const checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      setSignatureVerified(res.data.postmark_signature_verified);
      setSignatureId(res.data.postmark_signature_id);
      setEmailCredits(res.data.postmark_email_balance);
    });
  };
  const checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      verifySignature(res.data.postmark_signature_verified);
      setSignatureVerified(res.data.postmark_signature_verified);
      setSignatureId(res.data.postmark_signature_id);
      setEmailCredits(res.data.postmark_email_balance);
    });
  };
  const verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          setShowVerificationModal(false);
          checkUserProfile();
          setShowCreditsModal(true);
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  const setSequenceValue = (value) => {
    axios({
      method: "GET",
      url: `/sequences/${value}?contact_id=${contactId}`,
    }).then((res) => {
      setSelectedSequence(res.data.sequence);
      setShowSendSequenceModal(true);
      setShowAllSequenceModal(false);
    });
  };
  const readTemplateVarsValues = (tempId, personalizedVariables) => {
    let url = `/emails/templates/${tempId}?contact_id=${contactId}`;
    axios({
      method: "GET",
      url,
    }).then((res) => {
      setShowPersonalizedVarsModal(false);
      let finalContent = res.data.template.body;
      let finalSubject = res.data.template.subject;
      for (let i = 0; i < personalizedVariables.length; i++) {
        finalContent = finalContent.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalContent.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              personalizedVariables[i].value
            )
          : finalContent;
        finalSubject = finalSubject.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalSubject.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              personalizedVariables[i].value
            )
          : finalSubject;
      }
    });
  };
  const contactInfo = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/contacts/${contactId}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((res) => {
      setContactPhoneRef(res.data.contact.formatted_phone);
      setContactMobileRef(res.data.contact.formatted_mobile);
    });
  };
  useEffect(() => {
    checkUserProfile();
    getSignature();
    contactInfo();
    fetchAllOpportunitiesContacts();
  }, []);

  const loadActivities = useCallback(
    (page, fetchType) => {
      setActivityLoader(true);
      let url = `${BASE_URL}/${"contacts"}/${contactId}/activities?_limit=30&_page=${page}`;
      axios({
        method: "GET",
        url,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
      })
        .then((res) => {
          setActivityLoader(false);
          if (res.data.logs.length === 0) setHasMoreItems(false);
          switch (fetchType) {
            case "refetch": {
              setLogs(res.data.logs);
              setHasMoreItems(false);
              setPinnedActivity(res.data.pinned_activity);
              break;
            }
            case "initial":
              setLogs((oldLogs) => [...oldLogs, ...res.data.logs]);
              setHasMoreItems(false);
              setPinnedActivity(res.data.pinned_activity);
              break;
            case "nextPage":
            default:
              setLogs((oldLogs) => [...oldLogs, ...res.data.logs]);
              setPinnedActivity(res.data.pinned_activity);
              if (res.data.logs.length === 0) {
                setHasMoreItems(false);
              } else {
                setHasMoreItems(true);
              }
          }
        })
        .catch((err) => {
          setActivityLoader(false);
        });
      axios({
        method: "GET",
        url: `${BASE_URL}/contacts/${contactId}`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
      }).then((res) => {
        setContactName(res.data.contact.name);
        setAccountId(res.data.contact.account_id);
        setAccountName(res.data.contact.account_name);
      });
    },
    [contactId]
  );
  useEffect(() => {
    if (activities.fetchActivity === true) {
      loadActivities(1, "refetch");
    }
  }, [activities.fetchActivity]);
  const getConfetti = () => {
    setShowConfetti(true);
  };
  return (
    <div>
      <div className="customerProfile" id={`contactProfile${contactId}`}>
        {showConfetti && (
          <Confetti
            width={window.innerWidth / 1.1}
            height={window.innerHeight}
            numberOfPieces={400}
            recycle={false}
          />
        )}
        <div className="row">
          {showTaskArrow && (
            <NextRecordNavigation
              tasks={tasks}
              setTab={setTab}
              setActiveTabIndex={setActiveTabIndex}
              history={history}
              totalTabs={totalTabs}
              recordType="contact"
              recordId={contactId}
              taskIndex={taskIndex}
            />
          )}
          <div className="col-1-of-3">
            <ContactsIntro
              contactId={contactId}
              showOpportunityModal={showOpportunityModal}
              setShowOpportunityModal={setShowOpportunityModal}
              customerName={contactName}
              tabIndex={tabIndex}
              deleteTab={deleteTab}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              loadActivities={loadActivities}
              setContactEmail={setContactEmail}
              setIntegration={setIntegration}
              getConfetti={getConfetti}
              setAccountData={setAccountData}
              setAccountCustomFields={setAccountCustomFields}
              fetchAllOpportunitiesContacts={fetchAllOpportunitiesContacts}
              opportunities={opportunities}
            />
          </div>
          <AllSequenceModal
            showOpportunityModal={showAllSequenceModal}
            setShowOpportunityModal={() => setShowAllSequenceModal(true)}
            handleCloseModal={() => setShowAllSequenceModal(false)}
            setSequenceValue={setSequenceValue}
            handleOpenEditSequenceModal={() => setShowEditSequenceModal(true)}
          />
          <SaveEmailModal
            showModal={showSaveEmailModal}
            handleCloseModal={() => setShowSaveEmailModal(false)}
            email={contactEmail}
            changeHandler={recordChangeHandler}
            modalHeight={45}
            modalTop={15}
            loading={recorderLoading}
            submitHandler={recordSubmitHandler}
            error={emailValidationError}
          />
          <SendSequenceModal
            showOpportunityModal={showSendSequenceModal}
            setShowOpportunityModal={() => setShowSendSequenceModal(true)}
            handleCloseModal={() => setShowSendSequenceModal(false)}
            selectedSequence={selectedSequence}
            toEmail={contactEmail ? [contactEmail] : []}
            contactId={contactId}
            fromEmail={authUser.email}
            handleCloseEmailModal={() => setShowSendSequenceModal(false)}
            emailType={"contact_id"}
            loadActivities={loadActivities}
            emailSignature={emailSignature}
          />
          <EditSequenceModal
            showOpportunityModal={showEditSequenceModal}
            setShowOpportunityModal={() => setShowEditSequenceModal(true)}
            handleCloseModal={() => setShowEditSequenceModal(false)}
            selectedSequence={selectedSequence}
          />
          <BillingCreditModal
            showOpportunityModal={showCreditsModal}
            setShowOpportunityModal={() => setShowCreditsModal(true)}
            handleCloseModal={() => setShowCreditsModal(false)}
            handleEmailOpenModal={() => setShowAllSequenceModal(true)}
            checkUserProfile={checkUserProfile}
          />
          <BillingRedirectModal
            showOpportunityModal={showRedirectModal}
            setShowOpportunityModal={() => setShowRedirectModal(true)}
            handleCloseModal={() => setShowRedirectModal(false)}
          />
          <PostMarkAppModal
            showOpportunityModal={showPostMarkModal}
            setShowOpportunityModal={() => setShowPostMarkModal(true)}
            handleCloseModal={() => setShowPostMarkModal(false)}
            handleVerificationOpenModal={() => setShowVerificationModal(true)}
            checkUserProfile={checkUserProfile}
          />
          <PostMarkAppVerificationModal
            showOpportunityModal={showVerificationModal}
            setShowOpportunityModal={() => setShowVerificationModal(true)}
            handleCloseModal={() => setShowVerificationModal(false)}
            checkUserProfile={checkUserProfile}
            signatureVerified={signatureVerified}
            handleEmailOpenModal={() => setShowAllSequenceModal(true)}
            checkVerification={checkVerification}
          />
          <PersonalizedVarsModal
            showOpportunityModal={showPersonalizedVarsModal}
            setShowOpportunityModal={() => setShowPersonalizedVarsModal(true)}
            handleCloseModal={() => setShowPersonalizedVarsModal(false)}
            readTemplateVarsValues={readTemplateVarsValues}
            templateId={templateId}
            personalizedVariablesArray={personalizedVariablesArray}
          />
          <div className="col-1-of-3">
            <ActivityLog
              entityId={contactId}
              customerName={contactName}
              entityType="account"
              // New props
              loadActivities={loadActivities}
              hasMoreItems={hasMoreItems}
              logs={logs}
              integration={integration}
              pinnedActivity={pinnedActivity}
              activityLoader={activityLoader}
              toEmail={contactEmail ? [contactEmail] : []}
            />
          </div>
          <div className="col-1-of-3">
            <ContactsActivityForm
              contactId={contactId}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              customerName={contactName}
              accountId={accountId}
              accountName={accountName}
              // New props
              loadActivities={loadActivities}
              contactEmail={contactEmail}
              integration={integration}
              emailDecision={emailDecision}
              fetchAllOpportunitiesContacts={fetchAllOpportunitiesContacts}
              opportunities={opportunities}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    activities: state.activities,
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default connect(MSP, MDP)(React.memo(ContactsProfile));
