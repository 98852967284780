import {
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDS_SUCCESS,
  FETCH_DASHBOARDS_FAILURE,
} from "./types";

const initialState = {
  dashboards: [],
  loading: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case FETCH_DASHBOARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        dashboards: action.payload,
      };
    case FETCH_DASHBOARDS_FAILURE:
      return {
        ...state,
        loading: false,
        dashboards: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
