export const usStatesList = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const usStatesListWithTimeZones = [
  {
    label: "Eastern Time Zone (ET)",
    value: "et",
    states: [
      "ME",
      "NH",
      "VT",
      "MA",
      "RI",
      "CT",
      "NY",
      "NJ",
      "PA",
      "DE",
      "MD",
      "WV",
      "OH",
      "GA",
      "FL",
      "SC",
      "NC",
      "VA",
      "DC",
      "IN",
      "MI",
      "TN",
      "KY",
      "Maine",
      "New Hampshire",
      "Vermont",
      "Massachusetts",
      "Rhode Island",
      "Connecticut",
      "New York",
      "New Jersey",
      "Pennsylvania",
      "Delaware",
      "Maryland",
      "West Virginia",
      "Ohio",
      "Georgia",
      "Florida",
      "South Carolina",
      "North Carolina",
      "Virginia",
      "Indiana",
      "Michigan",
      "Tennessee",
      "Kentucky",
    ],
  },
  {
    label: "Central Time Zone (CT)",
    value: "ct",
    states: [
      "TN",
      "KY",
      "AL",
      "MS",
      "LA",
      "AR",
      "MO",
      "OK",
      "KS",
      "NE",
      "SD",
      "ND",
      "MN",
      "IA",
      "WI",
      "IL",
      "TX",
      "Tennessee",
      "Kentucky",
      "Alabama",
      "Mississippi",
      "Louisiana",
      "Arkansas",
      "Missouri",
      "Oklahoma",
      "Kansas",
      "Nebraska",
      "South Dakota",
      "North Dakota",
      "Minnesota",
      "Iowa",
      "Wisconsin",
      "Illinois",
      "Texas",
    ],
  },
  {
    label: "Mountain Time Zone (MT)",
    value: "mt",
    states: [
      "MT",
      "WY",
      "CO",
      "NM",
      "UT",
      "AZ",
      "ID",
      "Montana",
      "Wyoming",
      "Colorado",
      "New Mexico",
      "Utah",
      "Arizona",
      "Idaho",
    ],
  },
  {
    label: "Pacific Time Zone (PT)",
    value: "pt",
    states: [
      "WA",
      "OR",
      "CA",
      "NV",
      "Washington",
      "Oregon",
      "California",
      "Nevada",
    ],
  },
];
