import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import axios from "axios";
// Redux stuff
import { connect } from "react-redux";
import { authUserSet } from "../../redux";

const TOUR_STEPS = [
  {
    title: "Import accounts",
    target: ".tour-import-account",
    content:
      "Upload a .csv or .xlsx file to import and map the fields for your data.",
    disableBeacon: true, // This makes the tour to start automatically without clicking
    disableOverlayClose: true,
    spotlightClicks: true,
    placement: "right",
    // hideCloseButton: true,
    hideFooter: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

// Tour component
const TaskTourImport = (props) => {
  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    setTimeout(() => {
      let retrievedObject = localStorage.getItem("tour");
      let tourObject = JSON.parse(retrievedObject);
      if (
        props.authUser?.tour_completed === false &&
        tourObject.import === false
      ) {
        dispatch({ type: "START" });
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.tourComplete === true) {
      setTourViewed();
      dispatch({ type: "STOP" });
    }
  }, [props.tourComplete]);

  const callApi = (tour) => {
    const {
      complete_task,
      create_deal,
      integrate_email,
      customize,
      create_team,
    } = tour;
    axios({
      method: "PATCH",
      url: `/users/tour-meta`,
      data: {
        complete_task,
        create_deal,
        integrate_email,
        customize,
        import: tour.import,
        create_team,
      },
    });
  };

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    let retrievedObject = localStorage.getItem("tour");
    let tourObject = JSON.parse(retrievedObject);
    let tour = { ...tourObject, import: true };
    localStorage.setItem("tour", JSON.stringify(tour));
    callApi(tour);
  };

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{
          last: "End tour",
        }}
      />
    </>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
  };
};
const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(TaskTourImport);
