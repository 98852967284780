import axios from "axios";
import React, { useEffect, useState } from "react";

function CarriersScoreCard({ filter_rules }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = () => {
      let newValue = [];
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        return {
          attribute,
          operator,
          value,
        };
      });
      setLoading(true);
      let url =`/charts/carrier-chart`;
      axios({
        method: "POST",
        url,
        data: {
          chart: {
            type: "scorecard",
          },
          filter_rules: newValue,
        },
      })
        .then((res) => {
          setLoading(false);
          setData(res.data.carriers
          );
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getData();
  }, [filter_rules]);
  return (
    <div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <div>
          <h3
            style={{ fontSize: "22px", fontWeight: "400", textAlign: "center" }}
          >
            Score Card
          </h3>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "400",
              paddingTop: "70px",
              textAlign: "center",
            }}
          >
            <h4 style={{ fontSize: "16px", fontWeight: "400" }}>
              Total Carriers
            </h4>
            <div
              style={{
                fontWeight: "500",
                fontSize: "100px",
                textAlign: "center",
                color: "#4a9876",
              }}
            >
              {data.length > 0 ? data[0]?.no_of_carrier : 0}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CarriersScoreCard;
