import {
  SET_STORED_RECORD_COLLAPSIBLE,
  REMOVE_STORED_RECORD_COLLAPSIBLE,
} from "./types";

export const setStoredRecordCollapsible = (payload) => {
  return {
    type: SET_STORED_RECORD_COLLAPSIBLE,
    payload,
  };
};
export const removeStoredRecordCollapsible = (payload) => {
  return {
    type: REMOVE_STORED_RECORD_COLLAPSIBLE,
    payload,
  };
};
