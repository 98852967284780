import React, { Component } from "react";
import XLSX from "xlsx";
import axios from "axios";
import { BASE_URL } from "../../config";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TaskTourImport from "../Onboarding/TaskTourImport";
import moment from "moment";
import YoutubePlayer from "../YoutubePlayer";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, companyOnboardingSet } from "../../redux";

class ImportHubspotActivity extends Component {
  state = {
    format: "",
    data: [],
    linkData: [],
    headers: [],
    headersMapping: {},
    rows: [],
    isLoading: false,
    erroredData: [],
    totalCreated: 0,
    totalFailed: 0,
    submitted: false,
    tourComplete: false,
    showYoutubeVideo: false,
    reportCreated: false,
    reportData: {},
    reportError: false,
  };
  fileInput = React.createRef();
  fileLinkInput = React.createRef();
  componentDidMount() {}
  headersChangeHandler = (sheetHeader, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      headers: [...prevState.headers, value],
      headersMapping: { ...prevState.headersMapping, [sheetHeader]: value },
    }));
  };
  showRows = () => {
    this.setState((prevState) => ({
      rows: this.state.data.filter((d) => Object.keys(this.state.headers)),
    }));
  };
  // createReport = (batch) => {
  //   const dateFormat =
  //     this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
  //       ? "DD/MM/YYYY"
  //       : "l";
  //   const date = new Date();
  //   const myDate = moment(date).format(dateFormat);
  //   axios({
  //     method: "POST",
  //     url: `/reports`,
  //     data: {
  //       name: `Accounts imported on ${myDate}`,
  //       type: "accounts",
  //       description: null,
  //       access: "everyone",
  //       filters: [
  //         {
  //           attribute: "batch",
  //           operator: "is",
  //           value: batch.toString(),
  //         },
  //       ],
  //       columns: ["account_owner", "name", "description", "email", "mobile"],
  //     },
  //   })
  //     .then((res) => {
  //       NotificationManager.success("Import report created");
  //       let newTabData = {
  //         type: "report",
  //         id: res.data.reports.id,
  //         name: res.data.reports.name,
  //       };
  //       this.setState({
  //         reportCreated: true,
  //         reportData: newTabData,
  //       });
  //     })
  //     .catch((err) => {
  //       NotificationManager.error("Error creating report");
  //       this.setState({
  //         reportCreated: true,
  //         reportError: true,
  //       });
  //     });
  // };

  handleSubmit = (e) => {
    e.preventDefault();
    const batch = Date.now();
    const linkedObject = this.state.linkData?.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.engagementId]: item.contactId }),
      {}
    );
    // if (!Object.values(this.state.headersMapping).includes("name")) {
    //   NotificationManager.error("Account Name field is mandatory");
    //   return false;
    // }
    this.setState({ isLoading: true });

    let dataChunks = _.chunk(
      this.state.data.map((obj) => {
        const data = Object.keys(this.state.headersMapping)
          .filter((key) => this.state.headersMapping[key] !== "")
          .map((key) => obj[key]);
        return [...data, linkedObject[obj.id]];
      }),
      100
    );
    let totalCreated = 0,
      totalFailed = 0;
    let counter = 0;
    let headers = [];
    let erroredData = [];
    const headersMapping = [
      ...Object.values(this.state.headersMapping),
      "contact_id",
    ];
    dataChunks.forEach(async (chunk, i) => {
      const res = await axios({
        method: "POST",
        url: `/unts/import`,
        data: {
          headers: headersMapping.filter((value) => value !== ""),
          rows: chunk,
          batch: batch.toString(),
        },
      });
      totalCreated += await res.data.total_created;
      totalFailed += await res.data.total_failed;
      headers = await res.data.headers;
      console.log("$$ headers:", headers);
      erroredData = await [...erroredData, ...res.data.error_data];
      console.log("$$ erroredData:", erroredData);
      counter++;
      console.log("import acc res", res);

      console.log("import acc resxxx", counter, dataChunks.length);
      if (counter === dataChunks.length) {
        // NotificationManager.success(`Total Created : ${totalCreated}`);
        // NotificationManager.error(`Total Failed : ${totalFailed}`);
        this.setState({
          totalCreated,
          totalFailed,
          data: [],
          headers: [],
          headersMapping: {},
          submitted: true,
        });

        if (erroredData) {
          console.log("MEME", [headers, ...erroredData]);

          this.setState({ erroredData: [headers, ...erroredData] });
        }

        this.setState({ isLoading: false });
      }
    });
    // this.createReport(batch);
    console.log("import acc res", totalCreated, totalFailed);
  };

  OnDrop = (file, e) => {
    this.setState({ tourComplete: true });
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        defval: null,
      });
      if (data) {
        console.log("json import", data);
        this.setState({
          data,
        });
      }
    };
    reader.readAsBinaryString(file.files[0]);
  };
  OnLinkDrop = (file, e) => {
    this.setState({ tourComplete: true });
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        defval: null,
      });
      if (data) {
        console.log("json import", data);
        this.setState({
          linkData: data,
        });
      }
    };
    reader.readAsBinaryString(file.files[0]);
  };

  downloadFailedImportData = () => {
    let errorDataInExcel = XLSX.utils.aoa_to_sheet(this.state.erroredData);
    let errorDataInCSV = XLSX.utils.sheet_to_csv(errorDataInExcel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, errorDataInExcel, "Failed");

    console.log("errorDataInCSV:", errorDataInCSV);
    return XLSX.writeFile(wb, "FailedImports.xlsx");

    // return XLSX.writeFile(this.state.errorDataInCSV, "failedImports.xlsx", {
    //   bookType: "csv",
    //   bookSST: true,
    //   type: "base64",
    // });
  };

  render() {
    let headerKeyValueMappings = {
      account_owner: "Account Owner",
      name: "Account Name",
      account_owner_email: "Account Owner Email",
      source: "Source",
      website: "Website",
      state: "State",
      zip: "Zip",
      city: "City",
      address: "Address",
      linkedin: "LinkedIn",
      mobile_ext: "Phone Extension",
      email: "Email",
      mobile: "Phone",
      description: "Description",
      country: "Country",
      status: "Status",
      preferred_communication: "Preferred Communication",
    };
    console.log(
      "fileInput",
      this.fileInput.current,
      this.state.data,
      this.state.headersMapping
    );
    console.log(
      "fileLinkInput",
      this.fileLinkInput.current,
      this.state.linkData,
      this.state.data
    );
    return (
      <div style={{ margin: "auto 30px" }}>
        {this.state.showYoutubeVideo && (
          <YoutubePlayer
            src="https://www.youtube.com/embed/AZFj07YaQlc"
            closeIframe={() => this.setState({ showYoutubeVideo: false })}
          />
        )}
        <NotificationContainer />
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              borderBottom: "1px solid #E3E3E3",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <h2 style={{ marginBottom: "0px" }}>
                Import Hubspot Activty
                <button
                  type="button"
                  onClick={() => this.setState({ showYoutubeVideo: true })}
                  className="button-no-border-red"
                  style={{ color: "#61c99d", marginLeft: "20px" }}
                >
                  <i className="fa fa-video-camera" aria-hidden="true"></i>
                  &nbsp; Walk-through video
                </button>
              </h2>
              <p>
                Please upload a .csv or .xlsx file to import and map the fields
                for the data
              </p>
            </div>
            {this.state.submitted === false &&
              this.fileInput?.current?.files[0] &&
              this.fileLinkInput?.current?.files[0] && (
                <div
                  style={{
                    marginTop: "4vh",
                    float: "right",
                    marginRight: "30px",
                  }}
                >
                  <button
                    type="submit"
                    className="button-md"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Please Wait..." : "Start Upload"}
                  </button>
                </div>
              )}
          </div>
          <label
            htmlFor="file-upload"
            // className="tour-import-account"
            style={{
              textDecoration: "none",
              background: "#FFFFFF",
              color: "#3AAB7B",
              padding: "10px 24px",
              borderRadius: "4px",
              border: "1px solid #3AAB7B",
              cursor: "pointer",
              display: "inline-block",
              marginRight: "10px",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "150%",
            }}
          >
            {this.fileInput?.current?.files[0]?.name
              ? "File Name"
              : "Choose Activity File"}
          </label>
          <input
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            ref={this.fileInput}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={() => this.OnDrop(this.fileInput.current)}
          />
          {this.fileInput?.current?.files[0]?.name}
          <label
            htmlFor="file-link-upload"
            // className="tour-import-account"
            style={{
              textDecoration: "none",
              background: "#FFFFFF",
              color: "#3AAB7B",
              padding: "10px 24px",
              borderRadius: "4px",
              border: "1px solid #3AAB7B",
              cursor: "pointer",
              display: "inline-block",
              marginRight: "10px",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "150%",
            }}
          >
            {this.fileLinkInput?.current?.files[0]?.name
              ? "File Name"
              : "Upload Engagement - Record CSV"}
          </label>
          <input
            style={{ display: "none" }}
            id="file-link-upload"
            type="file"
            ref={this.fileLinkInput}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={() => this.OnLinkDrop(this.fileLinkInput.current)}
          />
          {this.fileLinkInput?.current?.files[0]?.name}
        </form>
        <TaskTourImport tourComplete={this.state.tourComplete} />
        <div style={{ padding: "5px" }}>
          {this.state.data.length > 0 &&
            Object.keys(this.state.data[0]).map((key, i) => (
              <div
                key={i}
                style={{
                  display: "inline-block",
                  margin: "10px",
                  padding: "15px",
                  border: "2px solid #E3E3E3",
                  borderRadius: "8px",
                }}
              >
                <span>
                  <h4
                    style={{
                      margin: "6px auto",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    Sheet field
                  </h4>{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    {key}
                  </div>
                </span>
                <h4
                  style={{
                    margin: "6px auto",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                    color: "#0C0D0D",
                  }}
                >
                  Salesdash field
                </h4>
                <select
                  name="headers"
                  onChange={(e) => this.headersChangeHandler(key, e)}
                  style={{
                    border: "1px solid #A7ABAA",
                    borderRadius: "8px",
                    padding: "7px",
                    maxWidth: "210px",
                  }}
                >
                  <option value="">Do not import</option>
                  <optgroup label="Default Fields">
                    {(!Object.values(this.state.headersMapping).includes(
                      "account_owner"
                    ) ||
                      this.state.headersMapping[key] === "account_owner") && (
                      <option value="account_owner">Account Owner</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "name"
                    ) ||
                      this.state.headersMapping[key] === "name") && (
                      <option value="name">Account Name</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "account_owner_email"
                    ) ||
                      this.state.headersMapping[key] ===
                        "account_owner_email") && (
                      <option value="account_owner_email">
                        Account Owner Email
                      </option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "source"
                    ) ||
                      this.state.headersMapping[key] === "source") && (
                      <option value="source">Source</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "website"
                    ) ||
                      this.state.headersMapping[key] === "website") && (
                      <option value="website">Website</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "state"
                    ) ||
                      this.state.headersMapping[key] === "state") && (
                      <option value="state">State</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "zip"
                    ) ||
                      this.state.headersMapping[key] === "zip") && (
                      <option value="zip">Zip</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "city"
                    ) ||
                      this.state.headersMapping[key] === "city") && (
                      <option value="city">City</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "address"
                    ) ||
                      this.state.headersMapping[key] === "address") && (
                      <option value="address">Address</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "linkedin"
                    ) ||
                      this.state.headersMapping[key] === "linkedin") && (
                      <option value="linkedin">LinkedIn</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "country"
                    ) ||
                      this.state.headersMapping[key] === "country") && (
                      <option value="country">Country</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "status"
                    ) ||
                      this.state.headersMapping[key] === "status") && (
                      <option value="status">Status</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "email"
                    ) ||
                      this.state.headersMapping[key] === "email") && (
                      <option value="email">Email</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "mobile"
                    ) ||
                      this.state.headersMapping[key] === "mobile") && (
                      <option value="mobile">Phone</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "mobile_ext"
                    ) ||
                      this.state.headersMapping[key] === "mobile_ext") && (
                      <option value="mobile_ext">Phone Extension</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "description"
                    ) ||
                      this.state.headersMapping[key] === "description") && (
                      <option value="description">Description</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "preferred_communication"
                    ) ||
                      this.state.headersMapping[key] ===
                        "preferred_communication") && (
                      <option value="preferred_communication">
                        Preferred Communication
                      </option>
                    )}
                  </optgroup>
                </select>
              </div>
            ))}
        </div>
        <table className="accountList__table">
          <thead>
            <tr>
              {Object.values(this.state.headersMapping)
                .filter((header) => header !== "")
                .map((header) => {
                  return <th>{headerKeyValueMappings[header]}</th>;
                })}
            </tr>
          </thead>
          <tbody>
            {this.state.data.slice(0, 5).map((obj, index) => (
              <tr key={index}>
                {Object.keys(this.state.headersMapping)
                  .filter((key) => this.state.headersMapping[key] !== "")
                  .map((key) => (
                    <td>{obj[key]}</td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>

        {this.state.submitted && (
          <>
            <p
              style={{
                fontSize: "1.5rem",
              }}
            >
              Import Summary
            </p>

            <p
              style={{
                fontSize: "1rem",
              }}
            >
              <b>Total Created:</b> {this.state.totalCreated}{" "}
            </p>
            <p
              style={{
                fontSize: "1rem",
              }}
            >
              <b>Total Failed:</b> {this.state.totalFailed}{" "}
            </p>
            <button
              type="button"
              className="button-md"
              style={{ display: "inline-block" }}
              disabled={!this.state.reportCreated}
              onClick={() => {
                if (this.state.reportError) {
                  this.props.history.push("/accounts");
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(this.state?.reportData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              Proceed
            </button>
            {this.state.totalFailed > 0 && (
              <div style={{ display: "inline-block" }}>
                <button
                  type="button"
                  style={{
                    textDecoration: "none",
                    background: "#FFFFFF",
                    color: "#3AAB7B",
                    padding: "10px 24px",
                    borderRadius: "4px",
                    border: "1px solid #3AAB7B",
                    cursor: "pointer",
                    display: "inline-block",
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                  }}
                  onClick={this.downloadFailedImportData}
                >
                  Download Failed imports
                </button>
              </div>
            )}
            {this.state.totalFailed > 0 && (
              <div style={{ marginTop: "15px" }}>
                Please make sure that Account Name is not empty/duplicated. You
                can download the failed imports and try again.
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ImportHubspotActivity);
