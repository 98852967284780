import axios from "axios";
import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Disable2faModal from "../2FA/Disable2faModal";
import { CountryCodeList } from "../2FA/CountryCodeList";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { withRouter } from "react-router";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

// Redux
import { compose } from "recompose";
import { connect } from "react-redux";
import { authUserSet } from "../../redux";

class TwoFaPage extends Component {
  state = {
    showDisableModal: false,
    mfaToken: "",
    userMobile: null,
    userCountryCode: null,
    showMobileFields: false,
    showPasswordFields: false,
    isLoading: false,
    errorMessage: false,
    countryCode: "",
    mobile: "",
    countryPhoneCode: "",
    password: "",
    otp: "",
    showOtp: false,
    backupCodesArray: [],
    showBackupCodes: false,
    passwordErrorMessage: "",
    otpErrorMessage: "",
    verificationMethod: "",
    qrCodeImage: "",
    showVerificationMethods: true,
    dailyDigest: false,
    weeklyDigest: false,
    ringCentralIntegrated: false,
    apolloConnected: false,
  };
  setMfaToken = (mfaToken) => {
    this.setState({
      mfaToken,
    });
  };
  setQrCodeImage = (qrCodeImage) => {
    this.setState({
      qrCodeImage,
    });
  };
  handleDisableOpenModal = () => {
    this.setState({
      showDisableModal: true,
      showBackupCodes: false,
    });
  };
  handleDisableCloseModal = () => {
    this.setState({
      showDisableModal: false,
    });
  };
  disabled2fa = () => {
    this.props.authUserSet({ ...this.props.authUser, two_fa_enabled: false });
    localStorage.setItem(
      "authUser",
      JSON.stringify({ ...this.props.authUser, two_fa_enabled: false })
    );
  };
  enable2fa = () => {
    this.props.authUserSet({ ...this.props.authUser, two_fa_enabled: true });
    localStorage.setItem(
      "authUser",
      JSON.stringify({ ...this.props.authUser, two_fa_enabled: true })
    );
  };
  showRecoveryCodes = () => {
    axios({
      method: "GET",
      url: `/users/recovery-codes`,
    }).then((res) => {
      this.setState({
        backupCodesArray: res.data.codes?.map((code) => code.code),
        showBackupCodes: true,
      });
    });
  };
  getUserDetails = () => {
    axios({
      method: "GET",
      url: `/users`,
    }).then((res) => {
      this.setState({
        userMobile: res.data.user.formatted_mobile,
        mobile: res.data.user.formatted_mobile,
        userCountryCode: res.data.user.country_code,
        dailyDigest: res.data.user.daily_digest,
        weeklyDigest: res.data.user.weekly_digest,
        ringCentralIntegrated: res.data.user.is_ringcentral_enabled,
        apolloConnected: res.data.user.is_apollo_connected,
      });
    });
  };
  getGeoInfo = () => {
    axios
      .get("https://extreme-ip-lookup.com/json/")
      .then((response) => {
        let data = response.data;
        const countryPhoneCode = CountryCodeList.find(
          (country) => data.countryCode === country.code
        );
        this.setState({
          countryName: data.country,
          countryCode: data.countryCode,
          countryPhoneCode: countryPhoneCode.dial_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getGeoInfo();
    this.getUserDetails();
    this.props.authUser.two_fa_enabled && this.showRecoveryCodes();
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  mobileSubmitHandler = () => {
    if (this.state.mobile === "" || this.state.mobile === null) {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    this.setState({
      isLoading: true,
    });
    axios({
      method: "PUT",
      url: `/users`,
      data: {
        formatted_mobile: this.state.mobile.toString(),
        country_code: this.state.countryPhoneCode.substring(1),
        name: this.props.authUser.name,
        daily_digest: this.state.dailyDigest,
        weekly_digest: this.state.weeklyDigest,
        is_ringcentral_enabled: this.state.ringCentralIntegrated,
        is_apollo_connected: this.state.apolloConnected,
      },
    })
      .then((res) => {
        this.setState({
          isLoading: false,
          errorMessage: false,
          showMobileFields: false,
          showPasswordFields: true,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: false,
        })
      );
  };
  passwordSubmitHandler = (e) => {
    e.preventDefault();
    if (this.state.password === "") {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    this.setState({
      isLoading: true,
    });
    axios({
      method: "POST",
      url: `/users/generate-mfa-otp`,
      data: {
        password: this.state.password,
        verification_method: this.state.verificationMethod,
      },
    })
      .then((res) => {
        this.setMfaToken(res.data.mfa_token);
        if (res.data.verification_method === "GOOGLE_AUTHENTICATOR") {
          this.setQrCodeImage(res.data.image_path);
        }
        this.setState({
          isLoading: false,
          errorMessage: false,
          showOtp: true,
          passwordErrorMessage: "",
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: false,
          passwordErrorMessage:
            "The password you entered is incorrect. Please try again.",
        })
      );
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.otp === "") {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    this.setState({
      isLoading: true,
    });
    axios({
      method: "POST",
      url: `/users/enable-mfa`,
      data: {
        mfa_token: this.state.mfaToken,
        verification_code: this.state.otp,
        verification_method: this.state.verificationMethod,
        method_type: "primary",
      },
    })
      .then((res) => {
        console.log("2fa res", res.status);
        if (res.status !== 200) {
          NotificationManager.error("Error enabling Two Factor Authentication");
          this.setState({
            isLoading: false,
            otpErrorMessage:
              "The code you entered is incorrect. Please try again.",
          });
        } else {
          NotificationManager.success("Two Factor Authentication enabled");
          this.enable2fa();
          this.setState({
            isLoading: false,
            errorMessage: false,
            showMobileFields: false,
            showPasswordFields: false,
            backupCodesArray: res.data.codes,
            showBackupCodes: true,
            showOtp: false,
            otpErrorMessage: "",
          });
        }
      })
      .catch((err) => {
        console.log("2fa error", err);
        NotificationManager.error("Error enabling Two Factor Authentication");
        this.setState({
          isLoading: false,
          otpErrorMessage:
            "The code you entered is incorrect. Please try again.",
        });
      });
  };
  downloadCodesPdf = () => {
    const doc = new jsPDF();
    const col = ["Sr. No.", "Back-up Code"];
    const rows = [];

    /* The following array of object as response from the API req  */

    const itemNew = this.state.backupCodesArray.map((arr, index) => {
      return { index: index + 1, code: arr };
    });

    itemNew.forEach((element) => {
      const temp = [element.index, element.code];
      rows.push(temp);
    });

    doc.autoTable(col, rows, { startY: 10 });
    doc.save("BackupCodes.pdf");
  };
  render() {
    return (
      <>
        <Sidebar />
        <Disable2faModal
          showOpportunityModal={this.state.showDisableModal}
          setShowOpportunityModal={this.handleDisableOpenModal}
          handleCloseModal={this.handleDisableCloseModal}
          disabled2fa={this.disabled2fa}
        />
        <div className="userProfile">
          <h2 className="userProfile-heading-main">
            Two-factor authentication
          </h2>
          <p>
            Two-factor authentication is an enhanced security measure. Once
            enabled, you'll be required to give two types of identification when
            you log in to Salesdash. SMS authentication is supported.
          </p>
          {!this.props.authUser.two_fa_enabled &&
          this.state.showVerificationMethods ? (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "300px",
                  border: "1px solid #e1e1e1",
                  padding: "15px 25px",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <h3>Security App</h3>
                <p>
                  Get a code from a free app like Google Authenticator, Authy or
                  Duo. The app is always available — even when you don't have
                  phone service.
                </p>
                <button
                  type="button"
                  onClick={
                    this.state.userMobile === null ||
                    this.state.userCountryCode === null
                      ? () =>
                          this.setState({
                            showMobileFields: true,
                            showVerificationMethods: false,
                          })
                      : () =>
                          this.setState({
                            showPasswordFields: true,
                            verificationMethod: "GOOGLE_AUTHENTICATOR",
                            showVerificationMethods: false,
                          })
                  }
                  className="button-md"
                >
                  Set up App
                </button>
              </div>
              <div
                style={{
                  width: "300px",
                  border: "1px solid #e1e1e1",
                  padding: "15px 25px",
                  borderRadius: "5px",
                }}
              >
                <h3>SMS Text Message</h3>
                <p>You'll receive a 6-digit verification code upon login.</p>
                <button
                  type="button"
                  onClick={
                    this.state.userMobile === null ||
                    this.state.userCountryCode === null
                      ? () =>
                          this.setState({
                            showMobileFields: true,
                            showVerificationMethods: false,
                          })
                      : () =>
                          this.setState({
                            showPasswordFields: true,
                            verificationMethod: "SMS",
                            showVerificationMethods: false,
                          })
                  }
                  className="button-md"
                >
                  Set up SMS
                </button>
              </div>
            </div>
          ) : this.props.authUser.two_fa_enabled ? (
            <button onClick={this.handleDisableOpenModal} className="button-md">
              &nbsp;Disable 2 factor auth
            </button>
          ) : null}
          {this.state.showMobileFields && (
            <div>
              <h2>Confirm your Phone number</h2>
              <label>Phone</label>
              <div style={{ display: "flex" }}>
                <select
                  value={this.state.countryPhoneCode}
                  name="countryPhoneCode"
                  onChange={this.changeHandler}
                >
                  {CountryCodeList.map((country) => {
                    const { dial_code, code } = country;
                    return (
                      <option value={dial_code}>
                        {dial_code} {code}
                      </option>
                    );
                  })}
                </select>
                <input
                  name="mobile"
                  value={this.state.mobile}
                  onChange={this.changeHandler}
                  // type="number"
                  // pattern="/^[0-9]*$/"
                />
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.mobile === "" || this.state.mobile === null)
                  ? "Please enter mobile"
                  : null}
              </div>
              <button
                disabled={this.state.isLoading}
                onClick={this.mobileSubmitHandler}
                type="button"
                className="button-md"
              >
                {this.state.isLoading ? "Confirming" : "Confirm"}
              </button>
            </div>
          )}
          {this.state.showPasswordFields && (
            <form
              onSubmit={
                this.state.showOtp
                  ? this.submitHandler
                  : this.passwordSubmitHandler
              }
            >
              <h2>Enable 2 Factor Authentication</h2>
              <p>
                To enable 2 factor authentication, first confirm your password
              </p>
              <label>Password</label>
              <input
                name="password"
                value={this.state.password}
                onChange={this.changeHandler}
                type="password"
                disabled={this.state.showOtp}
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true && this.state.password === ""
                  ? "Please enter password"
                  : null}
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.passwordErrorMessage}
              </div>
              {this.state.showOtp && (
                <>
                  {this.state.verificationMethod === "SMS" ? (
                    <p>
                      A 6-digit code was sent to your phone number. Please
                      verify the code
                    </p>
                  ) : (
                    <p>
                      Enter a code from authentication app to make sure
                      everything works.
                    </p>
                  )}
                  {this.state.verificationMethod === "GOOGLE_AUTHENTICATOR" && (
                    <img
                      src={this.state?.qrCodeImage}
                      alt=""
                      height="300px"
                      width="300px"
                    />
                  )}
                  <label>Enter Code</label>
                  <input
                    name="otp"
                    value={this.state.otp}
                    type="number"
                    onChange={this.changeHandler}
                    placeholder="Enter your 6 digit code"
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.state.errorMessage === true && this.state.otp === ""
                      ? "Please enter otp"
                      : null}
                  </div>
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.state.otpErrorMessage}
                  </div>
                </>
              )}
              <button
                disabled={this.state.isLoading}
                type="submit"
                className="button-md"
              >
                {this.state.isLoading ? "Confirming" : "Confirm"}
              </button>
            </form>
          )}
          {this.state.showBackupCodes && (
            <button
              style={{ marginTop: "30px" }}
              className="button-md"
              onClick={this.downloadCodesPdf}
              type="button"
            >
              Download Backup Codes
            </button>
          )}
          {this.state.showBackupCodes && (
            <p>Here's the list of backup codes: </p>
          )}
          {this.state.showBackupCodes &&
            this.state.backupCodesArray?.map((code, index) => (
              <div key={code}>{`${index + 1}: ${code}`}</div>
            ))}
        </div>
      </>
    );
  }
}

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(TwoFaPage);
