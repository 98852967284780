import React, { Component } from "react";
import "../../sass/Tasks.scss";
import ReactTooltip from "react-tooltip";
import ContactsNavIcon from "../../images/ContactsNavIconGreen.svg";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../redux";

class HottestFollowupTask extends Component {
  state = {
    name: "",
    time: "",
    detail: "",
  };

  render() {
    return (
      <div
        className={`schedule__items ${
          this.props.fromColor && "coloredTasks-" + this.props.entity_type
        }`}
        data-tip={this.props.name}
      >
        <ReactTooltip multiline={true} />
        <div>
          <span className="com-1">
            <img src={ContactsNavIcon} alt="" width={18} />
          </span>
          <div className="schedule__heading">
            <span className="name">
              {this.props.name.length > 30
                ? this.props.name.substring(0, 28) + `   ...`
                : this.props.name}
            </span>
            <div className="schedule__date">{this.props.status}</div>
            {/*{this.props.time ? (
              <span className="time">{this.props.time}</span>
            ) : (
              ""
            )} */}
          </div>
          <div className="schedule__details">
            {this.props.type === "contact" ? (
              <span className="schedule__details-sub"> Contact</span>
            ) : this.props.type === "account" ? (
              <span className="schedule__details-sub"> Acct.</span>
            ) : (
              <span className="schedule__details-sub"> Carrier</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};
export default connect(MSP, MDP)(HottestFollowupTask);
