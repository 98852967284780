import React from "react";
import "../../sass/Funnel.scss";
import "../../sass/Targets.scss";

import SalesFunnelStagesSort from "./SalesFunnelStagesSort";

function FunnelChartCustomFunction(props) {
  return (
    <div>
      <h3
        style={{
          marginTop: "9px",
          fontSize: "14px",
          lineHeight: "16px",
          textAlign: "left",
          marginLeft: "14px",
          color: "#586874",
          fontStyle: "normal",
          fontWeight: "normal",
        }}
      >
        Stages
      </h3>
      <form style={{ textAlign: "left" }}>
        <SalesFunnelStagesSort
          setStages={props?.setStages}
          stages={props?.stages}
          changeStageHandler={props?.changeStageHandler}
          deleteStageHandler={props?.deleteStageHandler}
          deleteNewStageHandler={props?.deleteNewStageHandler}
        />
      </form>
      {props?.stages.filter((stage) => stage.destroy !== true).length >= 12 ? (
        <p>You can add a maximum of 12 stages</p>
      ) : (
        <form>
          <input
            name="newStage"
            value={props.newStage}
            placeholder="Add Stage"
            onChange={props.newStageChangeHandler}
            type="text"
            style={{
              border: "none",
              borderBottom: "1px solid #C5C5D3",
              width: "135px",
              marginBottom: "15px",
              outline: "none",
            }}
          />
          <button
            type="button"
            disabled={props.newStage.length < 1}
            style={{
              marginLeft: "5px",
            }}
            className="button-sm"
            onClick={() =>
              props.addStageHandler(
                null,
                true,
                props?.stagesLength + 1,
                false,
                props?.newStage
              )
            }
          >
            Add
          </button>
        </form>
      )}
    </div>
  );
}

export default FunnelChartCustomFunction;
