import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { NotificationManager } from "react-notifications";

class NotificationSettingModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    teamNotifications: true,
    teamNotificationsInterval: "15,30,60",
    isLoading: false,
    errorMessage: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getSettings();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal !== prevProps.showOpportunityModal &&
      this.props.showOpportunityModal === true
    ) {
      this.getSettings();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  getSettings = () => {
    axios({
      method: "GET",
      url: `/notifications/settings`,
    }).then((res) => {
      this.setState({
        teamNotifications: res.data.settings[0].team_notifications,
        teamNotificationsInterval:
          res.data.settings[0].task_notifications_interval?.split(","),
      });
    });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  intervalChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        teamNotificationsInterval: [
          ...this.state.teamNotificationsInterval.filter((com) => com !== ""),
          value,
        ],
      }));
    } else {
      this.setState({
        teamNotificationsInterval: this.state.teamNotificationsInterval.filter(
          (pc) => pc !== value
        ),
      });
    }
  };
  submitHandler = () => {
    if (this.state.teamNotificationsInterval.length === 0) {
      this.setState({ isLoading: false, errorMessage: true });
      return false;
    }
    this.setState({ isLoading: true });
    const task_notifications_interval = this.state.teamNotificationsInterval
      .sort()
      .join();
    axios({
      url: `/notifications`,
      method: "PUT",
      data: {
        team_notifications: this.state.teamNotifications,
        task_notifications_interval,
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        NotificationManager.success("Notification Settings Updated");
        this.props.handleCloseModal();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        NotificationManager.error("Error Updating Notification Settings");
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "35vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-bell" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading" style={{ fontSize: "19px" }}>
                    Notification Settings
                  </h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label style={{ fontSize: "14px" }}>Task reminder interval</label>
              <input
                id="teamNotificationsInterval15"
                type="checkbox"
                name="teamNotificationsInterval"
                style={{ width: "20px", display: "inline-block" }}
                value="15"
                checked={this.state.teamNotificationsInterval.includes("15")}
                onChange={this.intervalChangeHandler}
              />
              <label
                style={{ display: "inline-block", marginRight: "10px" }}
                htmlFor="teamNotificationsInterval15"
              >
                15 minutes
              </label>
              <input
                id="teamNotificationsInterval30"
                type="checkbox"
                name="teamNotificationsInterval"
                style={{ width: "20px", display: "inline-block" }}
                value="30"
                checked={this.state.teamNotificationsInterval.includes("30")}
                onChange={this.intervalChangeHandler}
              />
              <label
                style={{ display: "inline-block", marginRight: "10px" }}
                htmlFor="teamNotificationsInterval30"
              >
                30 minutes
              </label>
              <input
                id="teamNotificationsInterval60"
                type="checkbox"
                name="teamNotificationsInterval"
                style={{ width: "20px", display: "inline-block" }}
                value="60"
                checked={this.state.teamNotificationsInterval.includes("60")}
                onChange={this.intervalChangeHandler}
              />
              <label
                style={{ display: "inline-block" }}
                htmlFor="teamNotificationsInterval60"
              >
                60 minutes
              </label>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                this.state.teamNotificationsInterval.length === 0
                  ? "Please select atleast one interval for task reminder."
                  : null}
              </div>
              <label style={{ fontSize: "14px", marginTop: "10px" }}>
                Would you like to be notified when a Deal has been marked as Won
                by team members?
              </label>
              <input
                type="checkbox"
                style={{ width: "20px" }}
                value={this.state.teamNotifications}
                checked={this.state.teamNotifications}
                onChange={() =>
                  this.setState({
                    teamNotifications: !this.state.teamNotifications,
                  })
                }
              />
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  disabled={this.state.isLoading}
                  onClick={this.submitHandler}
                >
                  {this.state.isLoading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default NotificationSettingModal;
