import React from "react";

function SampleDataNav(props) {
  return (
    <div
      style={{
        background: "#EBFAFF",
        height: "20px",
        color: "#1778F2",
        position: "fixed",
        zIndex: "9999998",
        width: "100vw",
        textAlign: "center",
        fontSize: "14px",
        top: "0",
      }}
    >
      <span>
        <span
          style={{ fontWeight: "700", textDecoration: "underline", cursor: "pointer" }}
          onClick={props.deleteSampleData}
        >
          Click here
        </span> to remove the sample data from your CRM.
      </span>
    </div>
  );
}

export default SampleDataNav;
