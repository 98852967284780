import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
class LineChartAnalytics extends Component {
  state = {
    // To avoid unnecessary update keep all options in the state.
    chartOptions: {
      // chart: {
      //   type: "spline",
      // },
      xAxis: {
        type: "datetime",
        // dateTimeLabelFormats: {
        //   // don't display the year
        //   month: "%b %e",
        //   year: "%e",
        // },
        labels: {
          format: '{value:%b %e}'
        },
        title: null,
      },
      yAxis: {
        title: null,
      },
      credits: {
        enabled: false,
      },
      colors: ["#64B5F5", "#FFBC4F", "#7A64C6"],
      // series: [{ data: [1, 2, 3] }],
      title: null,
      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 2.5,
          },
        },
      },
    },
    hoverData: null,
  };

  componentDidMount() {
    this.setState({
      chartOptions: {
        ...this.state.chartOptions,
        series: this.props.series?.map((d) => {
          const { data } = d;
          return {
            ...d,
            data: data?.map((date) => {
              const dateArray = date.date.split("-");
              return [
                Date.UTC(dateArray[0], dateArray[1] - 1, dateArray[2]),
                date.count,
              ];
            }),
          };
        }),
        dataLabels: {
          enabled: true,
        },
        yAxis: {
          title: null,
        },
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        chartOptions: {
          ...this.state.chartOptions,
          series: this.props.series?.map((d) => {
            const { data } = d;
            return {
              ...d,
              data: data?.map((date) => {
                const dateArray = date.date.split("-");
                return [
                  Date.UTC(dateArray[0], dateArray[1] - 1, dateArray[2]),
                  date.count,
                ];
              }),
            };
          }),
          dataLabels: {
            enabled: true,
          },
          yAxis: {
            title: null,
          },
        },
      });
    }
  }

  render() {
    const { chartOptions } = this.state;
    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    );
  }
}

export default LineChartAnalytics;
