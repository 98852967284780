import {
  SET_TAB,
  SET_ACTIVE_TAB_INDEX,
  DELETE_TAB,
  DELETE_ALL_TABS,
} from "./types";

export const setTab = (newTabData) => {
  return {
    type: SET_TAB,
    payload: newTabData,
  };
};
export const deleteTab = (index) => {
  return {
    type: DELETE_TAB,
    payload: index,
  };
};
export const setActiveTabIndex = (activeTabIndex) => {
  return {
    type: SET_ACTIVE_TAB_INDEX,
    payload: activeTabIndex,
  };
};
export const deleteAllTabs = (activeTabIndex) => {
  console.log("delete")
  return {
    type: DELETE_ALL_TABS,
    payload: activeTabIndex,
  };
};
