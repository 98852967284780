import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
// Redux stuff
import { connect } from "react-redux";
import { fetchAllUsers } from "../../redux";

class DeleteUserModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    isLoading: false,
    new_owner_id: "",
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      name: this.props.name,
      email: this.props.email,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    axios({
      method: "DELETE",
      url: `/admin/user/${this.props.userId}`,
      data: {
        new_owner_id:
          this.state.new_owner_id === "unassigned"
            ? null
            : this.state.new_owner_id,
      },
    })
      .then((res) => {
        this.props.fetchUsers();
        this.props.fetchAllUsers();
        this.props.handleCloseModal();
        this.props.handleCloseUserEditModal();
        NotificationManager.success("User deleted successfully.");
        this.setState({ isLoading: false, new_owner_id: "" });
      })
      .catch((err) => {
        NotificationManager.error("Error deleting user.");
        this.setState({ isLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "30vh", top: "20%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete User</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <p>
                Are you sure you want to delete {this.props.name} from your CRM?
              </p>
              <p>
                Select the user to whom {this.props.name}'s records will be
                transferred to
              </p>
              <select
                value={this.state.new_owner_id}
                onChange={this.changeHandler}
                name="new_owner_id"
                style={{ width: "100%" }}
              >
                <option value="" selected disabled>
                  - Select -
                </option>
                <option value={"unassigned"}>Unassigned Leads</option>
                {this.props.users
                  ?.filter((user) => user.id !== this.props.userId)
                  .map((user) => (
                    <option value={user.id} key={user.id}>
                      {user.name}
                    </option>
                  ))}
              </select>

              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  onClick={this.submitHandler}
                  disabled={
                    this.state.isLoading || this.state.new_owner_id === ""
                  }
                >
                  {this.state.isLoading ? "Deleting" : "Delete"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    allUsers: state.allUsers,
  };
};
const MDP = (dispatch) => {
  return {
    fetchAllUsers: (usersData) => dispatch(fetchAllUsers(usersData)),
  };
};

export default connect(MSP, MDP)(DeleteUserModal);
