import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { BASE_URL } from "../config";
import LockIconTargets from "../images/LockIconTargets.svg";

class TargetEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
      windowWidth: window.innerWidth,
      targets: [],
      userId: [],
      loading: false,
    };
  }
  handleUserId = (userId) => {
    this.setState({
      userId,
    });
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  changeHandler = (type, e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      targets: {
        ...prevState.targets,
        [type]: { ...prevState.targets[type], [name]: value },
      },
    }));
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    axios({
      method: "GET",
      url: `${BASE_URL}/dashboard/target/stats?user_ids=${this.props.userId}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((response) => {
      this.setState({
        targets: response.data,
        userId: this.props.userId,
      });
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/dashboard/teams`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((res) => this.setState({ teamsName: res.data.teams }));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.setState({
        userId: this.props.userId,
      });
    }
    if (prevState.userId !== this.state.userId) {
      axios({
        method: "GET",
        url: `${BASE_URL}/dashboard/target/stats?user_ids=${this.state.userId}`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
      }).then((response) => {
        this.setState({
          targets: response.data,
        });
      });
    }
  }
  userIdChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    axios({
      method: "PUT",
      url: `${BASE_URL}/targets/settings`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        target_user_ids: [this.state.userId],
        targets: [
          {
            id: this.state.targets?.activities.id,
            value: this.state.targets?.activities.value,
          },
          {
            id: this.state.targets?.opportunities.id,
            value: this.state.targets?.opportunities.value,
          },
          {
            id: this.state.targets?.won_opportunities.id,
            value: this.state.targets?.won_opportunities.value,
          },
        ],
      },
    })
      .then((res) => window.location.reload())
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "92vh", top: "2%", width: "31vw", left: "32%" }}
            >
              <div
                className="modal__header"
                style={{ borderBottom: "1px solid #E3E3E3" }}
              >
                <div className="modal__head">
                  <h2
                    className="modal__heading"
                    style={{
                      fontSize: "20px",
                      lineHeight: "150%",
                      fontWeight: "500",
                      textAlign: "left",
                      color: "#0C0D0D",
                      marginBottom: "20px",
                    }}
                  >
                    Edit Targets Settings
                  </h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <label>Edit for</label>
                <select
                  name="userId"
                  onChange={this.userIdChangeHandler}
                  value={this.state.userId}
                  style={{
                    width: "215px",
                    border: "1px solid #C5C5D3",
                    borderRadius: "7px",
                    padding: "5px",
                  }}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state.teamsName?.map((team) => {
                    const { team_name, members } = team;
                    return (
                      <optgroup label={team_name} key={team_name}>
                        {
                          <option
                            value={members.map((member) => member.id).join(",")}
                          >{`${team_name} Team`}</option>
                        }
                        {members.map((member) => (
                          <option key={member.id} value={member.id}>
                            {member.name}
                          </option>
                        ))}
                      </optgroup>
                    );
                  })}
                </select>
                <div>
                  <h3
                    style={{
                      fontSize: "16px",
                      lineHeight: "150%",
                      fontWeight: "500",
                      textAlign: "left",
                      color: "#0C0D0D",
                      display: "inline-block",
                    }}
                  >
                    Activities
                  </h3>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#3AAB7B",
                      background: "#E7F3ED",
                      border: "1px solid #3AAB7B",
                      borderRadius: "8px",
                      padding: "4px 8px",
                      marginLeft: "16px",
                    }}
                  >
                    <img src={LockIconTargets} alt="" width="10px" /> Period:{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.targets?.activities?.period}
                    </span>
                  </span>
                  <div>
                    <div style={{ display: "inline-block", textAlign: "left" }}>
                      <div>
                        <label
                          style={{
                            fontSize: "14px",
                            lineHeight: "16px",
                          }}
                        >
                          Target
                        </label>
                        <input
                          type="number"
                          name="value"
                          onKeyPress={(e) => e.charCode >= 48}
                          min="0"
                          onChange={(e) => this.changeHandler("activities", e)}
                          value={this.state.targets?.activities?.value}
                          style={{
                            width: "215px",
                            border: "1px solid #C5C5D3",
                            borderRadius: "7px",
                            padding: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3
                    style={{
                      fontSize: "16px",
                      lineHeight: "150%",
                      fontWeight: "500",
                      textAlign: "left",
                      color: "#0C0D0D",
                      display: "inline-block",
                    }}
                  >
                    New Deals
                  </h3>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#3AAB7B",
                      background: "#E7F3ED",
                      border: "1px solid #3AAB7B",
                      borderRadius: "8px",
                      padding: "4px 8px",
                      marginLeft: "16px",
                    }}
                  >
                    <img src={LockIconTargets} alt="" width="10px" /> Period:{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.targets?.opportunities?.period}
                    </span>
                  </span>
                  <div>
                    <div style={{ display: "inline-block", textAlign: "left" }}>
                      <div>
                        <label
                          style={{
                            fontSize: "14px",
                            lineHeight: "16px",
                          }}
                        >
                          Target
                        </label>
                        <input
                          type="number"
                          name="value"
                          onKeyPress={(e) => e.charCode >= 48}
                          min="0"
                          onChange={(e) =>
                            this.changeHandler("opportunities", e)
                          }
                          value={this.state.targets?.opportunities?.value}
                          style={{
                            width: "215px",
                            border: "1px solid #C5C5D3",
                            borderRadius: "7px",
                            padding: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingBottom: "30px" }}>
                  <h3
                    style={{
                      fontSize: "16px",
                      lineHeight: "150%",
                      fontWeight: "500",
                      textAlign: "left",
                      color: "#0C0D0D",
                      display: "inline-block",
                    }}
                  >
                    Won Deals
                  </h3>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#3AAB7B",
                      background: "#E7F3ED",
                      border: "1px solid #3AAB7B",
                      borderRadius: "8px",
                      padding: "4px 8px",
                      marginLeft: "16px",
                    }}
                  >
                    <img src={LockIconTargets} alt="" width="10px" /> Metric:{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.targets?.won_opportunities?.metric}
                    </span>
                  </span>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#3AAB7B",
                      background: "#E7F3ED",
                      border: "1px solid #3AAB7B",
                      borderRadius: "8px",
                      padding: "4px 8px",
                      marginLeft: "16px",
                    }}
                  >
                    <img src={LockIconTargets} alt="" width="10px" /> Period:{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.targets?.won_opportunities?.period}
                    </span>
                  </span>
                  <div>
                    <div style={{ display: "inline-block", textAlign: "left" }}>
                      <div>
                        <label
                          style={{
                            fontSize: "14px",
                            lineHeight: "16px",
                          }}
                        >
                          Target
                        </label>
                        <input
                          type="number"
                          name="value"
                          onKeyPress={(e) => e.charCode >= 48}
                          min="0"
                          value={this.state.targets?.won_opportunities?.value}
                          onChange={(e) =>
                            this.changeHandler("won_opportunities", e)
                          }
                          style={{
                            width: "215px",
                            border: "1px solid #C5C5D3",
                            borderRadius: "7px",
                            padding: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="modal__confirm-button">
                    {this.state.loading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default TargetEditModal;
