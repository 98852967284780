// libraries
import React, { Component } from "react";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import Collapsible from "react-collapsible";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { debounce } from "lodash";
import { AsYouType } from "libphonenumber-js";
// components
import Opportunity from "../Opportunity";
import DeleteAttachmentModal from "../SendEmail/DeleteAttachmentModal";
import DisqualifyModal from "../DisqualifyModal";
import DeleteAccountModal from "../Accounts/DeleteAccountModal";
import CreateContactModal from "../CreateModal/CreateContactModal";
import CreateDealModal from "../CreateModal/CreateDealModal";
import OppModal from "../OppModal";
import SendEmailModal from "../SendEmail/SendEmailModal";
import CreateContact from "../Contacts/CreateContact";
import Contact from "../Contacts/Contact";
import TaskCreateModal from "../TaskCreateModal";
import TaskTourDealCreate from "../Onboarding/TaskTourDealCreate";
import IntegrationRedirectModal from "../Integration/IntegrationRedirectModal";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../../utils/Helper/reusableFunctions";
import { CurrencyList } from "../../constants/currencylist";
//images
import Edit from "../../images/EditIcon.svg";
import CarrierGreenIcon from "../../images/CarrierGreenIcon.svg";
import SendEmailIcon from "../../images/AccountEmailIconBlack.svg";
import ArrowIcon from "../../images/ArrowDown.svg";
import ArrowIconUp from "../../images/ArrowUp.svg";
import ContactsNavIcon from "../../images/AccountContactIcon.svg";
import AccountTaskIcon from "../../images/AccountTaskIcon.svg";
import DealsNavIcon from "../../images/AccountDealIcon.svg";
import AccountActivityIcon from "../../images/ActivityAccountIcon.svg";
import SaveButtonAccount from "../../images/SaveButtonAccount.svg";
import EditIcon from "../../images/EditIcon.svg";
import DeleteIconRed from "../../images/DeleteIconRed.svg";
import CrossIcon from "../../images/CrossIcon.svg";
import DocumentIcon from "../../images/DocumentIcon.svg";
import DeleteIcon from "../../images/DeleteIcon.svg";
import DocumentQuickAccessIcon from "../../images/DocumentQuickAccessIcon.svg";
import LaneIcon from "../../images/lane.svg";
// constants
import { colourStyles } from "../../constants/selectStyles";
//scss
import "../../sass/CustomerIntro.scss";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
  setStoredRecordCollapsible,
  removeStoredRecordCollapsible,
  setStoredRecordSelectable,
  removeStoredRecordSelectable,
} from "../../redux";
import { compose } from "recompose";
import TagDealModal from "./Deal/TagDealModal";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";

class CarrierIntro extends Component {
  state = {
    userData: {
      custom_fields: {},
    },
    customFieldsForAPI: {},
    opportunities: [],
    allFields: [],
    showOpportunityModal: false,
    showNewOpportunityModal: false,
    editing: false,
    name: null,
    mc_number: null,
    carrier_owner_id: [],
    carrier_owner: [],
    email: null,
    phone_ext: null,
    formatted_phone: null,
    equipment_types: [],
    modes: [],
    specialRequirements: [],
    dot_number: null,
    fleet_size: null,
    description: null,
    homeBase: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    customFields: {},
    status: null,
    showDisqualifyModal: false,
    showDeleteModal: false,
    disqualify_reason: null,
    account_owner: null,
    showEmailModal: false,
    showContactModal: false,
    showNewContactModal: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    tasks: [],
    users: [],
    tourComplete: false,
    showRedirectModal: false,
    integration: false,
    postMarkId: null,
    postMarkVerified: false,
    showInputField: null,
    showButtonModal: false,
    errorMessage: false,
    showSourceModal: false,
    sourceArray: [],
    selected: 0,
    showInfo: true,
    showInfoOf: "",
    documents: [],
    showDeleteAttachmentModal: false,
    showTagDealModal: false,
    tagDealLoader: false,
    allUntaggedDeals: [],
    documentId: null,
    showAddDocumentButton: true,
    contactEmailsArray: [],
    phoneValidationError: false,
    emailValidationError: false,
    showSaveEmailModal: false,
    showAllSequenceModal: false,
    customFieldRevenueTypeCheck: [],
    funnels: [],
    showAddDealButton: true,
  };
  fileInput = React.createRef();
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
    this.setState({ tourComplete: true });
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleOpenNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: true });
  };

  handleCloseNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: false });
  };

  handleOpenEmailModal = () => {
    this.setState({
      showEmailModal: true,
      showInputField: null,
    });
  };

  handleCloseEmailModal = () => {
    this.setState({
      showEmailModal: false,
      showInputField: null,
    });
  };
  handleOpenDeleteAttachmentModal = (id) => {
    this.setState({
      showDeleteAttachmentModal: true,
      documentId: id,
    });
  };

  handleCloseDeleteAttachmentModal = () => {
    this.getDocuments();
    this.setState({
      showDeleteAttachmentModal: false,
    });
  };
  handleOpenRedirectModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  handleCloseRedirectModal = () => {
    this.setState({
      showRedirectModal: false,
      showInputField: null,
    });
  };
  handleOpenSourceModal = () => {
    this.setState({
      showSourceModal: true,
    });
  };
  handleCloseSourceModal = () => {
    this.setState({
      showSourceModal: false,
    });
  };
  handleOpenSaveEmailModal = () => {
    this.setState({
      showSaveEmailModal: true,
    });
  };

  handleCloseSaveEmailModal = () => {
    this.setState({
      showSaveEmailModal: false,
    });
  };
  handleOpenTagDealModal = () => {
    this.setState({
      showTagDealModal: true,
    });
  };

  handleCloseTagDealModal = () => {
    this.setState({
      showTagDealModal: false,
    });
  };
  handleOpenAllSequenceModal = () => {
    if (this.state.email === "" || this.state.email === null) {
      this.handleOpenSaveEmailModal();
    } else {
      this.setState({
        showSaveEmailModal: false,
        showAllSequenceModal: true,
      });
    }
  };
  handleCloseAllSequenceModal = () => {
    this.setState({
      showAllSequenceModal: false,
    });
  };
  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };

  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenNewContactModal = () => {
    this.setState({
      showNewContactModal: true,
    });
  };

  handleCloseNewContactModal = () => {
    this.setState({
      showNewContactModal: false,
    });
  };
  handleOpenDisqualifyModal = () => {
    this.setState({
      showDisqualifyModal: true,
    });
  };

  handleCloseDisqualifyModal = () => {
    this.setState({
      showDisqualifyModal: false,
    });
  };
  handleOpenDeleteModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  handleOpenTaskModal = () => {
    this.props.setShowTaskModal(true);
  };

  handleCloseTaskModal = () => {
    this.props.setShowTaskModal(false);
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  onSelect = (key) => {
    this.setState({ selected: key });
  };
  getTasks = () => {
    axios({
      method: "GET",
      url: `/carriers/${this.props.customerId}/tasks`,
    })
      .then((res) => {
        this.setState({ tasks: res.data.tasks });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getUsers = () => {
    axios({
      method: "GET",
      url: `/admin/users?carrier_id=${this.props.customerId}`,
    }).then((response) => {
      this.setState({
        users: response.data.users?.slice()?.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Ignore case for comparison
          const nameB = b.name.toUpperCase(); // Ignore case for comparison

          if (nameA < nameB) {
            return -1; // Negative value: a should come before b
          }
          if (nameA > nameB) {
            return 1; // Positive value: b should come before a
          }
          return 0; // Return 0 for elements considered equal
        }),
      });
    });
  };
  getAllFunnels = () => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      this.setState({
        funnels: res.data.funnels,
      });
    });
  };
  getDocuments = () => {
    axios({
      method: "GET",
      url: `/attachments?carrier_id=${this.props.customerId}`,
    }).then((res) => this.setState({ documents: res.data.attachments }));
  };
  fetchCarrierData = () => {
    axios({
      method: "GET",
      url: `/carriers/${this.props.customerId}`,
    }).then((res) => {
      this.setState({
        userData: res.data.carrier,
        name: res.data.carrier.name,
        mc_number: res.data.carrier.mc_number,
        status: res.data.carrier.status,
        carrier_owner_id: res.data.carrier.owners?.map(
          (owner) => owner.owner_id
        ),
        carrier_owner: res.data.carrier.owners?.map((owner) => ({
          label: owner.name,
          value: owner.id,
        })),
        equipment_types: res.data.carrier.equipment_types?.map((type) => ({
          label: type.name,
          value: type.id,
        })),
        modes: res.data.carrier.modes?.map((type) => ({
          label: type.name,
          value: type.id,
        })),
        specialRequirements: res.data.carrier.requirement_types?.map(
          (type) => ({
            label: type.name,
            value: type.id,
          })
        ),
        email: res.data.carrier.email,
        phone_ext: res.data.carrier.phone_ext,
        formatted_phone: res.data.carrier.formatted_phone,
        dot_number: res.data.carrier.dot_number,
        fleet_size: res.data.carrier.fleet_size,
        description: res.data.carrier.description,
        homeBase: {
          address: res.data.carrier.address,
          lat: res.data.carrier.lat,
          long: res.data.carrier.long,
          city: res.data.carrier.city,
          state: res.data.carrier.state,
          zip: res.data.carrier.zip,
          country: res.data.carrier.country,
        },
        showButtonModal: false,
        showInputField: null,
        errorMessage: false,
        phoneValidationError: false,
        emailValidationError: false,
      });
      this.customFieldInitializer(res.data.carrier.custom_fields);
    });
  };
  getContactsEmailArray = () => {
    axios({
      url: `/carriers/${this.props.customerId}/contacts?_limit=100`,
      method: "GET",
    }).then((res) => {
      this.props.setContactEmailsArray(
        res.data.contacts?.filter((contact) => contact.email !== null)
      );
      this.setState({
        contactEmailsArray: res.data.contacts?.filter(
          (contact) => contact.email !== null
        ),
      });
    });
  };
  getAllUnTaggedDeals = debounce((name) => {
    let url = `/carriers/${this.props.customerId}/untagged-opportunities`;
    if (name) {
      url += `?name=${encodeURIComponent(name)}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => {
      this.setState({
        allUntaggedDeals: res.data.opportunities,
      });
    });
  }, 500);
  fetchShowInfoOf = () => {
    this.setState({
      showInfoOf: this.props.storedRecordSelectable.carrier,
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    // document.addEventListener("mousedown", this.handleClick);
    this.fetchShowInfoOf();
    this.getTasks();
    this.getUsers();
    this.getDocuments();
    this.getContactsEmailArray();
    this.getAllFunnels();
    this.getAllUnTaggedDeals();
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      this.props.setIntegration(res.data.user.mail_provider);
      this.setState({
        integration: res.data.user.mail_provider,
        postMarkId: res.data.user.postmark_signature_id,
        postMarkVerified: res.data.user.postmark_signature_verified,
      });
    });
    axios({
      method: "GET",
      url: `/carriers/${this.props.customerId}`,
    }).then((res) => {
      this.props.setCarrierEmail(res.data.carrier.email);
      this.props.setCarrierData(res.data.carrier);
      this.setState({
        userData: res.data.carrier,
        name: res.data.carrier.name,
        mc_number: res.data.carrier.mc_number,
        status: res.data.carrier.status,
        carrier_owner_id: res.data.carrier.owners?.map(
          (owner) => owner.owner_id
        ),
        carrier_owner: res.data.carrier.owners?.map((owner) => ({
          label: owner.name,
          value: owner.id,
        })),
        equipment_types: res.data.carrier.equipment_types?.map((type) => ({
          label: type.name,
          value: type.id,
        })),
        modes: res.data.carrier.modes?.map((type) => ({
          label: type.name,
          value: type.id,
        })),
        specialRequirements: res.data.carrier.requirement_types?.map(
          (type) => ({
            label: type.name,
            value: type.id,
          })
        ),
        email: res.data.carrier.email,
        phone_ext: res.data.carrier.phone_ext,
        formatted_phone: res.data.carrier.formatted_phone,
        dot_number: res.data.carrier.dot_number,
        fleet_size: res.data.carrier.fleet_size,
        description: res.data.carrier.description,
        homeBase: {
          address: res.data.carrier.address,
          lat: res.data.carrier.lat,
          long: res.data.carrier.long,
          city: res.data.carrier.city,
          state: res.data.carrier.state,
          zip: res.data.carrier.zip,
          country: res.data.carrier.country,
        },
      });
      this.customFieldInitializer(res.data.carrier.custom_fields);
    });

    this.props.fetchAllOpportunitiesCarriers(this.props.customerId);
    this.props.fetchAllContactsCarriers(this.props.customerId);
  }
  customFieldInitializer = (initialValue) => {
    this.setState({
      allFields: this.props.allCarrierCustomFields,
    });
    const customField = this.props.allCarrierCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allCarrierCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    const finalObject = { ...obj, ...initialValue };
    this.props.setCarrierCustomFields(finalObject);
    this.setState((prevState) => ({
      customFields: finalObject,
      customFieldsForAPI: finalObject,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };
  editStart = () => {
    this.setState(
      {
        editing: !this.state.editing,
        errorMessage: false,
        phoneValidationError: false,
        emailValidationError: false,
      },
      () => {
        if (!this.state.editing) {
          this.fetchCarrierData();
        }
      }
    );
  };
  editCancel = () => {
    this.fetchCarrierData();
    this.setState({
      editing: false,
      phoneValidationError: false,
      emailValidationError: false,
      errorMessage: false,
    });
  };
  openFileSelect = () => this.fileInput.current.click();
  handleInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    this.submitAttachment(event.target.files[0]);
  };
  showDealPortion = () => {
    this.setState({
      showInfo: true,
      showInfoOf: "deals",
      showAddDealButton: true,
    });
  };
  submitAttachment = (selectedFile) => {
    const { customerId } = this.props;
    const formData = new FormData();
    formData.append("attachment", selectedFile);
    formData.append("carrier_id", customerId);
    axios({
      method: "POST",
      url: `/attachments`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((res) => {
      this.getDocuments();
      NotificationManager.success("Document added successfully.");
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    // document.removeEventListener("mousedown", this.handleClick);
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value !== "" ? e.target.value : null,
    });
  };
  handlePhoneChangeHandler = (e) => {
    const { name, value } = e.target;
    const formatter = new AsYouType("US"); // Initialize AsYouType instance
    const formattedValue = formatter.input(value); // Format the input value
    console.log(formattedValue);
    this.setState({
      [name]: formattedValue !== "" ? formattedValue : null,
    });
  };
  deleteAttachmentHandler = (id) => {
    axios({
      method: "DELETE",
      url: `/attachments/${id}`,
    })
      .then((res) => {
        this.handleCloseDeleteAttachmentModal();
        NotificationManager.success("Document deleted successfully.");
      })
      .catch((err) => NotificationManager.error("Failed to delete document."));
  };
  tagDealsToCarriers = (opportunity_ids) => {
    this.setState({
      tagDealLoader: true,
    });
    axios({
      method: "POST",
      url: `/carriers/${this.props.customerId}/opportunities`,
      data: {
        opportunity_ids,
      },
    })
      .then((res) => {
        this.setState({
          tagDealLoader: false,
          showTagDealModal: false,
        });
        this.props.fetchAllOpportunitiesCarriers(this.props.customerId);
        this.getAllUnTaggedDeals();
        NotificationManager.success("Deal(s) tagged successfully.");
      })
      .catch((err) => {
        NotificationManager.error("Error tagging deal");
        this.setState({
          tagDealLoader: false,
        });
      });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.formatted_phone !== null &&
      this.state.formatted_phone !== "" &&
      !phoneValidator.test(this.state.formatted_phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (!this.state.name) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    const selectedStatus = this.props.allCarrierStatuses?.find(
      (s) => s.name === this.state.status
    );
    axios({
      method: "PUT",
      url: `/carriers/${this.props.customerId}`,
      data: {
        name: this.state.name,
        mc_number: this.state.mc_number,
        status_id: selectedStatus.id,
        owner_ids: this.state.carrier_owner?.map((owner) => owner.value),
        email: this.state.email,
        phone_ext: this.state.phone_ext,
        formatted_phone: this.state.formatted_phone,
        equipment_type_ids: this.state.equipment_types?.map(
          (type) => type.value
        ),
        mode_ids: this.state.modes?.map((type) => type.value),
        requirement_type_ids: this.state.specialRequirements?.map(
          (type) => type.value
        ),
        dot_number: this.state.dot_number,
        fleet_size: this.state.fleet_size,
        description: this.state.description,
        city: this.state.homeBase.city,
        state: this.state.homeBase.state,
        zip: this.state.homeBase.zip,
        country: this.state.homeBase.country,
        address: this.state.homeBase.address,
        lat: this.state.homeBase.lat,
        long: this.state.homeBase.long,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "carrier",
          id: this.props.customerId,
          name: this.state.name,
        };
        this.props.setTab({ ...newTabData, edited: true });
        NotificationManager.success("Carrier edited successfully.");
      })
      .then((response) => this.fetchCarrierData())
      .then((response) =>
        this.setState({
          editing: false,
          isLoading: false,
          showButtonModal: false,
          showInputField: null,
          errorMessage: false,
          phoneValidationError: false,
          emailValidationError: false,
        })
      )
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing carrier.");
        }
      });
  };
  handleHomeBaseSelect = (homeBase) => {
    this.setState({
      homeBase,
    });
  };
  saveEmailSubmitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.formatted_phone !== null &&
      this.state.formatted_phone !== "" &&
      !phoneValidator.test(this.state.formatted_phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (!this.state.name) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    const selectedStatus = this.props.allCarrierStatuses?.find(
      (s) => s.name === this.state.status
    );
    axios({
      method: "PUT",
      url: `/carriers/${this.props.customerId}`,
      data: {
        name: this.state.name,
        mc_number: this.state.mc_number,
        status_id: selectedStatus.id,
        owner_ids: this.state.carrier_owner?.map((owner) => owner.value),
        email: this.state.email,
        phone_ext: this.state.phone_ext,
        formatted_phone: this.state.formatted_phone,
        equipment_type_ids: this.state.equipment_types?.map(
          (type) => type.value
        ),
        // mode_ids: this.state.modes?.map(
        //   (type) => type.value
        // ),
        // requirement_type_ids: this.state.specialRequirements?.map(
        //   (type) => type.value
        // ),
        dot_number: this.state.dot_number,
        fleet_size: this.state.fleet_size,
        description: this.state.description,
        city: this.state.homeBase.city,
        state: this.state.homeBase.state,
        zip: this.state.homeBase.zip,
        country: this.state.homeBase.country,
        address: this.state.homeBase.address,
        lat: this.state.homeBase.lat,
        long: this.state.homeBase.long,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "carrier",
          id: this.props.customerId,
          name: this.state.name,
        };
        this.props.setTab({ ...newTabData, edited: true });
        NotificationManager.success("Carrier edited successfully.");
      })
      .then((response) => this.fetchCarrierData())
      .then((response) =>
        this.setState({
          editing: false,
          isLoading: false,
          showButtonModal: false,
          showInputField: null,
          errorMessage: false,
          phoneValidationError: false,
          emailValidationError: false,
          showSaveEmailModal: false,
          showAllSequenceModal: true,
        })
      )
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing carrier.");
        }
      });
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    }
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  defaultFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      [fieldName]: selectedOptions,
      multiSelectDropdownValue: selectedArray,
    }));
  };
  showInput = (fieldName, fieldLabel, fieldType, fieldChoices, singleEdit) => {
    let inputType;
    if (fieldType === "text") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldRevenueChangeHandler}
        />
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <textarea
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          style={{ fontFamily: "inherit" }}
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        ></textarea>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="number"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <select
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        >
          <option selected disabled>
            -Select-
          </option>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return <option key={id}>{value}</option>;
          })}
        </select>
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div>
          <input
            type="checkbox"
            onFocus={() =>
              this.setState(singleEdit && { showInputField: fieldName })
            }
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  onFocus={() =>
                    this.setState(singleEdit && { showInputField: fieldName })
                  }
                  type="radio"
                  style={{ display: "inline-block", textAlign: "left" }}
                  name={fieldName}
                  value={value}
                  onChange={this.customFieldChangeHandler}
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                />
                <label
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          selected={Date.parse(this.state.customFields[fieldName])}
          value={Date.parse(this.state.customFields[fieldName])}
          onChange={(date) =>
            this.customFieldDateChangeHandler(fieldName, date)
          }
          dateFormat={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      const colourStyles = {
        control: (styles) => ({
          ...styles,
          backgroundColor: "white",
          width: "280px",
          outline: "#C0EAD8",
          minHeight: "30px",
          padding: "5px auto",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? "#C0EAD8"
              : null,
            cursor: isDisabled ? "not-allowed" : "default",

            ":active": {
              ...styles[":active"],
              backgroundColor:
                !isDisabled && (isSelected ? data.color : "#C0EAD8"),
            },
          };
        },
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "#C0EAD8",
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "black",
          fontSize: "13px",
        }),
      };
      inputType = (
        <Select
          autoFocus={singleEdit}
          className={
            singleEdit
              ? "customerIntro-inputField basic-multi-select"
              : "basic-multi-select"
          }
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          closeMenuOnSelect={false}
          value={this.state.customFields[fieldName]}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value: value, label: value };
          })}
          classNamePrefix="select"
          onChange={(selectedOption) =>
            this.customFieldMultiDropdownChangeHandler(
              fieldName,
              selectedOption
            )
          }
        />
      );
    }
    return inputType;
  };
  statusChangeHandler = (e) => {
    const { value } = e.target;
    const newStatus = this.props.allCarrierStatuses?.find(
      (stat) => stat.name.toString() === value.toString()
    );
    this.setState({
      status: value,
    });
    axios({
      method: "PUT",
      url: `/carriers/${this.props.customerId}/status`,
      data: {
        status_id: newStatus.id,
      },
    })
      .then((res) => {
        NotificationManager.success("Successfully changed status");
        this.fetchCarrierData();
      })
      .catch((err) => {
        NotificationManager.error("Error changing status");
      });
  };

  render() {
    const { opportunities, contacts } = this.props;
    const { showInputField } = this.state;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    return (
      <div className="customerInfo">
        <div className="customerInfo__Intro">
          <div className="customerInfo-icon" style={{ background: "#B3E0DC" }}>
            <img
              src={CarrierGreenIcon}
              alt=""
              width="24px"
              style={{ transform: "translateY(9px)" }}
            />
          </div>
          <NotificationContainer />
          <div className="customerInfo-info">
            <h2 className="c-type">
              Carrier{" "}
              <i
                className="fa fa-circle"
                style={{ fontSize: "4px", transform: "translateY(-3px)" }}
              ></i>{" "}
              <select
                className={
                  this.state.status?.toLowerCase() === "active"
                    ? "c-icon-won"
                    : this.state.status?.toLowerCase() === "failed"
                    ? "c-icon-lost"
                    : "c-icon-3"
                }
                name="status"
                value={this.state?.status}
                onChange={(e) => {
                  this.statusChangeHandler(e);
                }}
              >
                {this.props.allCarrierStatuses.map((stat) => (
                  <option
                    value={stat.name}
                    style={{ textTransform: "capitalize" }}
                  >
                    {stat.name}
                  </option>
                ))}
              </select>
            </h2>
            <h1 className="c-name">
              {this.state.editing ? (
                <input
                  type="text"
                  name="name"
                  value={this.state?.name}
                  onChange={this.changeHandler}
                  className="c-name-input"
                  autoComplete="off"
                  style={{ width: "120%" }}
                />
              ) : this.state.userData.name &&
                this.state.userData.name.length > 30 ? (
                this.state.userData.name.substring(0, 30) + "..."
              ) : (
                this.state.userData.name
              )}
              <button
                onClick={this.editStart}
                className="account-editButton-pencil"
              >
                <img src={Edit} alt="Edit" height="13" />
              </button>
            </h1>
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              (this.state.name === null || this.state.name === "")
                ? "Name is required"
                : null}
            </div>
          </div>
          {/*carrier_owner*/}
          <div
            className="c-hoverDesc-div"
            onClick={() => this.setState({ showInputField: "carrier_owner" })}
          >
            <h5 className="c-title-act">Carrier Owner</h5>
            {showInputField === "carrier_owner" ? (
              <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                <Select
                  autoFocus={true}
                  className={"customerIntro-inputField basic-multi-select"}
                  onFocus={() =>
                    this.setState({ showInputField: "carrier_owner" })
                  }
                  closeMenuOnSelect={false}
                  value={this.state.carrier_owner}
                  isMulti
                  name={"carrier_owner"}
                  styles={colourStyles}
                  options={this.props.allUsers?.map((user) => {
                    const { name, id } = user;
                    return { value: id, label: name };
                  })}
                  classNamePrefix="select"
                  onChange={(selectedOption) =>
                    this.defaultFieldMultiDropdownChangeHandler(
                      "carrier_owner",
                      selectedOption
                    )
                  }
                />
                {!this.state.isLoading && (
                  <button
                    type="button"
                    className="c-hoverDesc-button-close"
                    onClick={this.fetchCarrierData}
                  >
                    <img src={CrossIcon} alt="Save" width="8" />
                  </button>
                )}
                {this.state.isLoading ? (
                  <div class="spinning-loader"></div>
                ) : (
                  <button
                    type="submit"
                    className="c-hoverDesc-button-save"
                    disabled={this.state.isLoading}
                  >
                    <img src={SaveButtonAccount} alt="Save" width="20" />
                  </button>
                )}
              </form>
            ) : (
              <p className="c-desc c-hoverDesc">
                {this.state.carrier_owner
                  ?.map((owner) => owner.label)
                  .join(", ")}{" "}
                <button
                  className="c-hoverDesc-button"
                  onClick={() =>
                    this.setState({ showInputField: "carrier_owner" })
                  }
                >
                  <img src={EditIcon} alt="Edit" width="13" />
                </button>
              </p>
            )}
          </div>
          <div style={{ display: "flex" }}>
            {/*mc_number*/}
            <div
              className="c-hoverDesc-div"
              onClick={() => this.setState({ showInputField: "mc_number" })}
              style={{ width: "100%" }}
            >
              <h5 className="c-title-act">MC#</h5>
              {showInputField === "mc_number" ? (
                <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                  <input
                    autoFocus
                    className="customerIntro-inputField"
                    onFocus={() =>
                      this.setState({ showInputField: "mc_number" })
                    }
                    type="number"
                    name="mc_number"
                    value={this.state.mc_number}
                    onChange={this.changeHandler}
                  />
                  {!this.state.isLoading && (
                    <button
                      type="button"
                      className="c-hoverDesc-button-close"
                      onClick={this.fetchCarrierData}
                    >
                      <img src={CrossIcon} alt="Save" width="8" />
                    </button>
                  )}
                  {this.state.isLoading ? (
                    <div class="spinning-loader"></div>
                  ) : (
                    <button
                      type="submit"
                      className="c-hoverDesc-button-save"
                      disabled={this.state.isLoading}
                    >
                      <img src={SaveButtonAccount} alt="Save" width="20" />
                    </button>
                  )}
                </form>
              ) : (
                <p className="c-desc c-hoverDesc">
                  {this.state.mc_number}{" "}
                  <button
                    className="c-hoverDesc-button"
                    onClick={() =>
                      this.setState({ showInputField: "mc_number" })
                    }
                  >
                    <img src={EditIcon} alt="Edit" width="13" />
                  </button>
                </p>
              )}
            </div>
            {/*dot_number*/}
            <div
              className="c-hoverDesc-div"
              onClick={() => this.setState({ showInputField: "dot_number" })}
              style={{ width: "100%", marginLeft: "15px" }}
            >
              <h5 className="c-title-act">DOT#</h5>
              {showInputField === "dot_number" ? (
                <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                  <input
                    autoFocus
                    className="customerIntro-inputField"
                    onFocus={() =>
                      this.setState({ showInputField: "dot_number" })
                    }
                    type="number"
                    name="dot_number"
                    value={this.state.dot_number}
                    onChange={this.changeHandler}
                  />
                  {!this.state.isLoading && (
                    <button
                      type="button"
                      className="c-hoverDesc-button-close"
                      onClick={this.fetchCarrierData}
                    >
                      <img src={CrossIcon} alt="Save" width="8" />
                    </button>
                  )}
                  {this.state.isLoading ? (
                    <div class="spinning-loader"></div>
                  ) : (
                    <button
                      type="submit"
                      className="c-hoverDesc-button-save"
                      disabled={this.state.isLoading}
                    >
                      <img src={SaveButtonAccount} alt="Save" width="20" />
                    </button>
                  )}
                </form>
              ) : (
                <p className="c-desc c-hoverDesc">
                  {this.state.dot_number}{" "}
                  <button
                    className="c-hoverDesc-button"
                    onClick={() =>
                      this.setState({ showInputField: "dot_number" })
                    }
                  >
                    <img src={EditIcon} alt="Edit" width="13" />
                  </button>
                </p>
              )}
            </div>
          </div>
          <TaskCreateModal
            carrierId={this.props.customerId}
            showTaskModal={this.props.showTaskModal}
            setShowTaskModal={this.handleOpenTaskModal}
            handleCloseModal={this.handleCloseTaskModal}
            customerName={this.props.customerName}
            opportunities={opportunities}
            contacts={contacts}
            getTasks={this.getTasks}
            users={this.state.users}
            existingUser={this.props.authUser.id}
            keyValue="CarrierIntro"
          />
          <ReactTooltip multiline={true} />
          <div className="accountsActionButtonDiv">
            <label
              htmlFor="AccountActivityForm"
              className="account-Logactivity-button"
              data-tip="Add Activity"
            >
              <img src={AccountActivityIcon} alt="" style={{ width: "16px" }} />
            </label>
            <button
              type="button"
              onClick={this.handleOpenTaskModal}
              data-tip="Add Task"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={AccountTaskIcon} alt="" style={{ width: "16px" }} />
              <div>Task</div>
            </button>
            <button
              type="button"
              onClick={this.handleOpenTagDealModal}
              data-tip="Add Deal"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={DealsNavIcon} alt="" style={{ width: "16px" }} />
              <div>Deal</div>
            </button>
            <button
              type="button"
              onClick={
                this.props.isContactFieldsModified
                  ? this.handleOpenNewContactModal
                  : this.handleOpenContactModal
              }
              data-tip="Add Contact"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={ContactsNavIcon} alt="" style={{ width: "16px" }} />
              <div>Contact</div>
            </button>
            <button
              type="button"
              for="file-upload"
              onClick={this.openFileSelect}
              data-tip="Add Document"
              className="accountsActionButtonDiv-buttons"
            >
              <img
                src={DocumentQuickAccessIcon}
                alt=""
                style={{ width: "16px", height: "17px" }}
              />
              <div>Document</div>
            </button>
          </div>
          <div style={{ display: "flex" }}>
            <button
              type="button"
              onClick={
                this.state.integration !== null ||
                (this.state.postMarkId !== null && this.state.postMarkVerified)
                  ? this.handleOpenEmailModal
                  : this.handleOpenRedirectModal
              }
              className="button-md button-outline-green button-outline-black-hover"
              style={{
                height: "36px",
                padding: "0 16px",
                fontWeight: "500",
                background: "#ffffff",
                color: "#000",
                border: "1px solid #000",
                width: "100%",
                borderRadius: "50px",
                marginRight: "15px",
              }}
            >
              <img
                src={SendEmailIcon}
                alt=""
                style={{
                  width: "16px",
                  marginRight: "5px",
                  transform: "translateY(2px)",
                }}
              />
              &nbsp;Send Email
            </button>
            <button
              type="button"
              onClick={() => {
                this.props.setAddLane(!this.props.addLane);
                this.props.setLaneMode("create");
              }}
              className="button-md button-outline-green button-outline-black-hover"
              style={{
                height: "36px",
                padding: "0 16px",
                fontWeight: "500",
                background: "#fff",
                border: "1px solid #000",
                color: "#000",
                width: "100%",
                borderRadius: "50px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={LaneIcon}
                  alt=""
                  style={{
                    width: "16px",
                    marginRight: "5px",
                  }}
                />
                &nbsp;
                <div>Add Lane</div>
              </div>
            </button>
          </div>
          {this.state.editing && (
            <button
              type="button"
              className="accountDeleteButton"
              onClick={this.handleOpenDeleteModal}
            >
              <img src={DeleteIconRed} alt="Delete" width="18px" />
            </button>
          )}
          <div
            className="customer-shortcut"
            style={{ display: "grid", gridTemplateColumns: "22% 26% 22% 30%" }}
          >
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "deals"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              style={{ margin: 0 }}
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "deals" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "carrier",
                        id: "deals",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "carrier",
                        id: "deals",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "deals" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "deals",
                    showAddDealButton: true,
                  };
                })
              }
            >
              Deals ({opportunities.length})
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "contacts"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              style={{ margin: 0 }}
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "contacts" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "carrier",
                        id: "contacts",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "carrier",
                        id: "contacts",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "contacts" &&
                      this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "contacts",
                  };
                })
              }
            >
              Contacts ({contacts.length})
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "lanes"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              style={{ margin: 0 }}
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "lanes" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "carrier",
                        id: "lanes",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "carrier",
                        id: "lanes",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "lanes" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "lanes",
                  };
                })
              }
            >
              Lanes ({this.props.totalLanes})
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "docs"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              style={{ margin: 0 }}
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "docs" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "carrier",
                        id: "docs",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "carrier",
                        id: "docs",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "docs" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "docs",
                  };
                })
              }
            >
              Documents ({this.state.documents.length})
            </span>
          </div>
        </div>
        {this.state.showInfo && this.state.showInfoOf === "contacts" && (
          <div className="customerInfo__opportunity" id="CustomerIntroContacts">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              open={true}
              trigger={
                <div>
                  Contacts ({contacts.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Contacts ({contacts.length})
                  </h4>
                  <button
                    onClick={
                      this.props.isContactFieldsModified
                        ? this.handleOpenNewContactModal
                        : this.handleOpenContactModal
                    }
                    className="button-sm"
                    style={{ transform: "translateY(-6px)" }}
                  >
                    + Add Contact
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              {contacts.length > 2 ? (
                <div style={{ height: "200px", overflowY: "auto" }}>
                  {contacts?.map((contact) => {
                    return (
                      <Contact
                        customerId={this.props.customerId}
                        name={contact.name}
                        detail={contact.role}
                        type={contact.stage_name}
                        key={contact.id}
                        {...contact}
                      />
                    );
                  })}
                </div>
              ) : (
                <div>
                  {contacts?.map((contact) => {
                    return (
                      <Contact
                        customerId={this.props.customerId}
                        name={contact.name}
                        detail={contact.role}
                        type={contact.stage_name}
                        key={contact.id}
                        {...contact}
                      />
                    );
                  })}
                </div>
              )}
            </Collapsible>
          </div>
        )}
        {/*Deals */}
        {this.state.showInfo && this.state.showInfoOf === "deals" && (
          <div className="customerInfo__opportunity" id="CustomerIntroDeals">
            <Collapsible
              className="collapseHeaderAccount-deal"
              classParentString="collapseHeaderAccount-deal"
              open={true}
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Deals ({opportunities.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Deals ({opportunities.length})
                  </h4>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
              onClosing={() => this.setState({ showAddDealButton: false })}
              onOpening={() => this.setState({ showAddDealButton: true })}
            >
              {this.state.showAddDealButton && (
                <button
                  onClick={this.handleOpenTagDealModal}
                  className="button-sm"
                  style={{ position: "absolute", top: "9px", right: "8px" }}
                >
                  + Add Deal
                </button>
              )}
              {opportunities.length > 2 ? (
                <div style={{ height: "200px", overflowY: "auto" }}>
                  {opportunities?.map((opportunity) => {
                    const close_date =
                      opportunity.close_date &&
                      moment(opportunity.close_date, "YYYY-MM-DD").format(
                        "MMMM DD, YYYY"
                      );
                    return (
                      <Opportunity
                        customerId={this.props.customerId}
                        name={opportunity.name}
                        detail={close_date}
                        type={opportunity.stage_name}
                        key={opportunity.id}
                        funnels={this.state.funnels}
                        authUser={this.props.authUser}
                        fetchAllOpportunities={() =>
                          this.props.fetchAllOpportunitiesCarriers(
                            this.props.customerId
                          )
                        }
                        users={this.state.users}
                        getConfetti={this.props.getConfetti}
                        opportunityData={{ ...opportunity }}
                        {...opportunity}
                      />
                    );
                  })}
                </div>
              ) : (
                <div>
                  {opportunities?.map((opportunity) => {
                    const close_date =
                      opportunity.close_date &&
                      moment(opportunity.close_date, "YYYY-MM-DD").format(
                        "MMMM DD, YYYY"
                      );
                    return (
                      <Opportunity
                        customerId={this.props.customerId}
                        name={opportunity.name}
                        detail={close_date}
                        type={opportunity.stage_name}
                        key={opportunity.id}
                        funnels={this.state.funnels}
                        authUser={this.props.authUser}
                        fetchAllOpportunities={() =>
                          this.props.fetchAllOpportunitiesCarriers(
                            this.props.customerId
                          )
                        }
                        getConfetti={this.props.getConfetti}
                        users={this.state.users}
                        opportunityData={{ ...opportunity }}
                        {...opportunity}
                      />
                    );
                  })}
                </div>
              )}
            </Collapsible>
          </div>
        )}
        {/*Lanes */}
        {this.state.showInfo && this.state.showInfoOf === "lanes" && (
          <div className="customerInfo__opportunity" id="CustomerIntroDeals">
            <Collapsible
              className="collapseHeaderAccount-deal"
              classParentString="collapseHeaderAccount-deal"
              open={true}
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Lanes ({this.props.totalLanes})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Lanes ({this.props.totalLanes})
                  </h4>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
              onClosing={() => this.setState({ showAddDealButton: false })}
              onOpening={() => this.setState({ showAddDealButton: true })}
            >
              {this.state.showAddDealButton && (
                <button
                  onClick={() => {
                    this.props.setAddLane(!this.props.addLane);
                    this.props.setLaneMode("create");
                  }}
                  className="button-sm"
                  style={{ position: "absolute", top: "9px", right: "8px" }}
                >
                  + Add Lane
                </button>
              )}
              <div style={{ height: "200px", overflowY: "auto" }}>
                {this.props.carrierLanes?.map((lane) => (
                  <div
                    className="documentStorage"
                    key={lane.id}
                    onClick={() => {
                      this.props.setAddLane(true);
                      this.props.setLaneData(lane);
                      this.props.setLaneMode("edit");
                    }}
                  >
                    <div
                      className="schedule__items-1"
                      data-tip={`${lane.origin_address} to ${lane.destination_address}`}
                    >
                      <ReactTooltip />
                      <span className="com-2" style={{ background: "#FFE3B3" }}>
                        <img
                          src={LaneIcon}
                          alt=""
                          style={{
                            transform: "translate(0.5px,0px)",
                            background: "#FFE3B3",
                            width: "18px",
                          }}
                        />
                      </span>
                      <div className="schedule__heading">
                        <span className="name-opp">
                          {lane.origin_address.length > 17
                            ? lane.origin_address.substring(0, 17) + "..."
                            : lane.origin_address}{" "}
                          &nbsp; to &nbsp;
                          {lane.destination_address.length > 17
                            ? lane.destination_address.substring(0, 17) + "..."
                            : lane.destination_address}
                        </span>
                      </div>
                      <div className="schedule__desc">
                        {lane.equipment_types
                          ?.map((type) => type.name)
                          .join(", ")}
                      </div>
                      <div className="schedule__desc" style={{ left: "88%" }}>
                        {currencySymbol.symbol}
                        {lane.price}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Collapsible>
          </div>
        )}
        {/*Documnets */}
        {this.state.showInfo && this.state.showInfoOf === "docs" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              open={true}
              onOpen={() => this.setState({ showAddDocumentButton: true })}
              onClose={() => this.setState({ showAddDocumentButton: false })}
              triggerDisabled={this.state.documents.length === 0 ? true : false}
              trigger={
                <div>
                  Documents ({this.state.documents.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Documents ({this.state.documents.length})
                  </h4>
                  {this.state.showAddDocumentButton && (
                    <button
                      type="button"
                      onClick={this.openFileSelect}
                      for="file-upload"
                      className="button-sm"
                      style={{ transform: "translateY(-6px)" }}
                    >
                      + Add Documents
                    </button>
                  )}
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              <div
                style={
                  this.state.documents.length > 2
                    ? { height: "200px", overflowY: "auto" }
                    : {}
                }
              >
                {this.state.documents?.map((doc) => (
                  <div className="documentStorage" key={doc.id}>
                    <a
                      href={doc.s3_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="schedule__items-1" data-tip={doc.name}>
                        <ReactTooltip />
                        <span
                          className="com-2"
                          style={{ background: "#D2DCFF" }}
                        >
                          <img
                            src={DocumentIcon}
                            alt=""
                            style={{
                              transform: "translate(0.5px,0px)",
                              background: "#D2DCFF",
                              width: "18px",
                            }}
                          />
                        </span>
                        <div className="schedule__heading">
                          <span className="name-opp">
                            {doc.name.length > 25
                              ? doc.name.substring(0, 25) + "..."
                              : doc.name}
                          </span>
                        </div>
                        <div className="schedule__desc">
                          {doc.created_at &&
                            moment(doc.created_at).format("MMMM DD, YYYY")}{" "}
                          .{" "}
                          {doc.size > 1024
                            ? (doc.size / 1024).toFixed(2) + "MB"
                            : doc.size + "KB"}
                        </div>
                      </div>
                    </a>
                    <button
                      className="schedule__delete"
                      type="button"
                      onClick={() =>
                        this.handleOpenDeleteAttachmentModal(doc.id)
                      }
                    >
                      <img src={DeleteIcon} alt="x" width="16px" />
                    </button>
                  </div>
                ))}
              </div>
            </Collapsible>
          </div>
        )}
        <input
          style={{ display: "none" }}
          id="file-upload"
          type="file"
          ref={this.fileInput}
          onChange={this.handleInputChange}
        />
        <TaskTourDealCreate
          tourComplete={this.state.tourComplete}
          restart={this.props.restart}
        />
        <DisqualifyModal
          showOpportunityModal={this.state.showDisqualifyModal}
          setShowOpportunityModal={this.handleOpenDisqualifyModal}
          handleCloseModal={this.handleCloseDisqualifyModal}
          customerName={this.props.customerName}
          customerId={this.props.customerId}
          fetchCarrierData={this.fetchCarrierData}
          status={this.props.status}
        />
        <DeleteAccountModal
          showOpportunityModal={this.state.showDeleteModal}
          setShowOpportunityModal={this.handleOpenDeleteModal}
          handleCloseModal={this.handleCloseDeleteModal}
          customerName={this.props.customerName}
          customerId={this.props.customerId}
          tabIndex={this.props.tabIndex}
          deleteTab={this.props.deleteTab}
          isCarrier={true}
        />
        <SendEmailModal
          showOpportunityModal={this.state.showEmailModal}
          setShowOpportunityModal={this.handleOpenEmailModal}
          handleCloseModal={this.handleCloseEmailModal}
          email={this.state.email}
          carrierId={this.props.customerId}
          loadActivities={this.props.loadActivities}
          contactEmailsArray={this.state.contactEmailsArray}
          replyToEmail={this.state.email ? [this.state.email] : []}
          fromEmail={this.props.authUser.email}
          idSequence={this.props.customerId}
          type={"carrier_id"}
          companyId={this.props.authUser.company_id}
          integration={this.state.integration}
          recordChangeHandler={this.changeHandler}
          recordSubmitHandler={this.saveEmailSubmitHandler}
          recorderLoading={this.state.isLoading}
          emailValidationError={this.state.emailValidationError}
          showSaveEmailModal={this.state.showSaveEmailModal}
          showAllSequenceModal={this.state.showAllSequenceModal}
          handleOpenAllSequenceModal={this.handleOpenAllSequenceModal}
          handleCloseAllSequenceModal={this.handleCloseAllSequenceModal}
          handleOpenSaveEmailModal={this.handleOpenSaveEmailModal}
          handleCloseSaveEmailModal={this.handleCloseSaveEmailModal}
          allUsers={this.props.allUsers}
          recordName={this.state.name}
        />
        <IntegrationRedirectModal
          showOpportunityModal={this.state.showRedirectModal}
          setShowOpportunityModal={this.handleOpenRedirectModal}
          handleCloseModal={this.handleCloseRedirectModal}
        />
        {this.state.editing ? (
          <form onSubmit={this.submitHandler}>
            <div
              className="customerInfo__Info"
              style={{ height: "53vh", overflowY: "auto" }}
            >
              <div className="collapseHeaderAccount-edit">
                <h4 className="c-title-main">Carrier Info</h4>
                <h5 className="c-title-act">Email</h5>
                <input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.changeHandler}
                />
                {this.state.emailValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid email
                  </div>
                )}
                <h5 className="c-title-act">Phone</h5>
                <input
                  type="tel"
                  name="formatted_phone"
                  // pattern="[0-9\-]+"
                  value={this.state.formatted_phone}
                  onChange={this.changeHandler}
                />
                {this.state.phoneValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid phone number
                  </div>
                )}
                <h5 className="c-title-act">Phone Extension</h5>
                <input
                  type="text"
                  name="phone_ext"
                  value={this.state.phone_ext}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Home Base</h5>
                <GoogleAutocompleteComponent
                  onPlaceSelect={this.handleHomeBaseSelect}
                  valueProps={this.state.homeBase.address}
                />
                <h5 className="c-title-act">Equipment Types</h5>
                <Select
                  autoFocus={true}
                  className={"customerIntro-inputField basic-multi-select"}
                  onFocus={() =>
                    this.setState({ showInputField: "equipment_types" })
                  }
                  closeMenuOnSelect={false}
                  value={this.state.equipment_types}
                  isMulti
                  name={"equipment_types"}
                  styles={colourStyles}
                  options={this.props.allEquipmentTypes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  classNamePrefix="select"
                  onChange={(selectedOption) =>
                    this.defaultFieldMultiDropdownChangeHandler(
                      "equipment_types",
                      selectedOption
                    )
                  }
                />
                <h5 className="c-title-act">Modes</h5>
                <Select
                  autoFocus={true}
                  className={"customerIntro-inputField basic-multi-select"}
                  onFocus={() => this.setState({ showInputField: "modes" })}
                  closeMenuOnSelect={false}
                  value={this.state.modes}
                  isMulti
                  name={"modes"}
                  styles={colourStyles}
                  options={this.props.allModes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  classNamePrefix="select"
                  onChange={(selectedOption) =>
                    this.defaultFieldMultiDropdownChangeHandler(
                      "modes",
                      selectedOption
                    )
                  }
                />
                <h5 className="c-title-act">Special Requirements</h5>
                <Select
                  autoFocus={true}
                  className={"customerIntro-inputField basic-multi-select"}
                  onFocus={() =>
                    this.setState({ showInputField: "specialRequirements" })
                  }
                  closeMenuOnSelect={false}
                  value={this.state.specialRequirements}
                  isMulti
                  name={"specialRequirements"}
                  styles={colourStyles}
                  options={this.props.allModes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  classNamePrefix="select"
                  onChange={(selectedOption) =>
                    this.defaultFieldMultiDropdownChangeHandler(
                      "specialRequirements",
                      selectedOption
                    )
                  }
                />
                <h5 className="c-title-act">Fleet Size</h5>
                <input
                  type="number"
                  name="fleet_size"
                  value={this.state.fleet_size}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Description</h5>
                <textarea
                  name="description"
                  value={this.state.description}
                  onChange={this.changeHandler}
                  style={{ whiteSpace: "pre-wrap" }}
                ></textarea>
              </div>
              {this?.state?.allFields?.map((field) => {
                const { label, id, fields, meta } = field;
                return (
                  <div key={id}>
                    {meta === null && (
                      <div className="collapseHeaderAccount-edit">
                        <h4 className="c-title-main-1">{label}</h4>
                        <div>
                          {fields.map((data) => {
                            const { id, label, type, choices, name } = data;
                            return (
                              <div key={id}>
                                <h5 className="c-title-act">{label}</h5>
                                <div key={id}>
                                  {this.showInput(name, label, type, choices)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="customerIntroStateChange">
              <p>Save changes to fields?</p>
              <button
                type="button"
                onClick={this.editCancel}
                className="modal__clear-1 customerIntroStateChange-cancel"
              >
                Cancel
              </button>
              <button
                disabled={this.state.isLoading}
                type="button"
                onClick={this.submitHandler}
                className="modal__addButton-1 customerIntroStateChange-save"
              >
                {this.state.isLoading ? "Saving" : "Save"}
              </button>
            </div>
          </form>
        ) : (
          <div className="customerInfo__Info" id="CustomerIntroAccountInfo">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Carrier Info
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div>
                  Carrier Info
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "4%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIconUp} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
              onOpening={() =>
                this.props.setStoredRecordCollapsible({
                  type: "account",
                  id: "default",
                })
              }
              onClosing={() =>
                this.props.removeStoredRecordCollapsible({
                  type: "account",
                  id: "default",
                })
              }
              open={this.props.storedRecordCollapsible.account.includes(
                "default"
              )}
            >
              {/*homeBase*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "homeBase" })}
              >
                <h5 className="c-title-act">Home Base</h5>
                {showInputField === "homeBase" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <GoogleAutocompleteComponent
                      onPlaceSelect={this.handleHomeBaseSelect}
                      valueProps={this.state.homeBase.address}
                      className="customerIntro-inputField"
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.homeBase.address}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "homeBase" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*formatted_phone*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "formatted_phone" })
                }
              >
                <h5 className="c-title-act">Phone</h5>
                {showInputField === "formatted_phone" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "formatted_phone" })
                      }
                      type="tel"
                      name="formatted_phone"
                      value={this.state.formatted_phone}
                      onChange={this.handlePhoneChangeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc  c-hoverDesc">
                    <a
                      target="_blank"
                      data-stringify-link={`tel:${this.state.formatted_phone
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      delay="150"
                      data-sk="tooltip_parent"
                      href={`tel:${this.state.formatted_phone
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      rel="noopener noreferrer"
                      tabindex="-1"
                      data-remove-tab-index="true"
                    >
                      {this.state.formatted_phone}
                    </a>{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "formatted_phone" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
                {this.state.phoneValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid phone number
                  </div>
                )}
              </div>
              {/*phone_ext*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "phone_ext" })}
              >
                <h5 className="c-title-act">Phone Extension</h5>
                {showInputField === "phone_ext" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "phone_ext" })
                      }
                      type="text"
                      name="phone_ext"
                      value={this.state.phone_ext}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc  c-hoverDesc">
                    {this.state.phone_ext}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "phone_ext" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*email*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "email" })}
              >
                <h5 className="c-title-act">Email</h5>
                {showInputField === "email" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() => this.setState({ showInputField: "email" })}
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.email !== null ? (
                      <span
                        style={{
                          color: "#62ca9d",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={
                          this.state.integration !== null ||
                          (this.state.postMarkId !== null &&
                            this.state.postMarkVerified)
                            ? this.handleOpenEmailModal
                            : this.handleOpenRedirectModal
                        }
                      >
                        {this.state.email}
                      </span>
                    ) : (
                      this.state.email
                    )}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "email" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {this.state.emailValidationError && (
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  Please enter a valid email
                </div>
              )}
              {/*equipment_types*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "equipment_types" })
                }
              >
                <h5 className="c-title-act">Equipment Type</h5>
                {showInputField === "equipment_types" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <Select
                      autoFocus={true}
                      className={"customerIntro-inputField basic-multi-select"}
                      onFocus={() =>
                        this.setState({ showInputField: "equipment_types" })
                      }
                      closeMenuOnSelect={false}
                      value={this.state.equipment_types}
                      isMulti
                      name={"equipment_types"}
                      styles={colourStyles}
                      options={this.props.allEquipmentTypes?.map((type) => {
                        return { value: type.id, label: type.name };
                      })}
                      classNamePrefix="select"
                      onChange={(selectedOption) =>
                        this.defaultFieldMultiDropdownChangeHandler(
                          "equipment_types",
                          selectedOption
                        )
                      }
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.equipment_types
                      ?.map((type) => type.label)
                      ?.join(", ")}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "equipment_types" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*modes*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "modes" })}
              >
                <h5 className="c-title-act">Modes</h5>
                {showInputField === "modes" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <Select
                      autoFocus={true}
                      className={"customerIntro-inputField basic-multi-select"}
                      onFocus={() => this.setState({ showInputField: "modes" })}
                      closeMenuOnSelect={false}
                      value={this.state.modes}
                      isMulti
                      name={"modes"}
                      styles={colourStyles}
                      options={this.props.allModes?.map((type) => {
                        return { value: type.id, label: type.name };
                      })}
                      classNamePrefix="select"
                      onChange={(selectedOption) =>
                        this.defaultFieldMultiDropdownChangeHandler(
                          "modes",
                          selectedOption
                        )
                      }
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.modes?.map((type) => type.label)?.join(", ")}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "modes" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*specialRequirements*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "specialRequirements" })
                }
              >
                <h5 className="c-title-act">Special Requirements</h5>
                {showInputField === "specialRequirements" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <Select
                      autoFocus={true}
                      className={"customerIntro-inputField basic-multi-select"}
                      onFocus={() =>
                        this.setState({ showInputField: "specialRequirements" })
                      }
                      closeMenuOnSelect={false}
                      value={this.state.specialRequirements}
                      isMulti
                      name={"specialRequirements"}
                      styles={colourStyles}
                      options={this.props.allSpecialRequirements?.map(
                        (type) => {
                          return { value: type.id, label: type.name };
                        }
                      )}
                      classNamePrefix="select"
                      onChange={(selectedOption) =>
                        this.defaultFieldMultiDropdownChangeHandler(
                          "specialRequirements",
                          selectedOption
                        )
                      }
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.specialRequirements
                      ?.map((type) => type.label)
                      ?.join(", ")}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "specialRequirements" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*fleet_size*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "fleet_size" })}
              >
                <h5 className="c-title-act">Fleet Size</h5>
                {showInputField === "fleet_size" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "fleet_size" })
                      }
                      type="number"
                      name="fleet_size"
                      value={this.state.fleet_size}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.fleet_size}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "fleet_size" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*description*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "description" })}
              >
                <h5 className="c-title-act">Description</h5>
                {showInputField === "description" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <textarea
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "description" })
                      }
                      name="description"
                      value={this.state.description}
                      onChange={this.changeHandler}
                      style={{ whiteSpace: "pre-wrap" }}
                    ></textarea>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchCarrierData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p
                    className="c-desc c-hoverDesc"
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      marginBottom: "15px",
                      whiteSpace: "pre-wrap",
                      height: "auto",
                    }}
                  >
                    {this.state.description}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "description",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
            </Collapsible>
            {this.state?.allFields?.map((field) => {
              const { label, id, fields, meta } = field;
              return (
                <div key={id} id={id}>
                  {meta === null && (
                    <div>
                      <Collapsible
                        className="collapseHeaderAccount"
                        classParentString="collapseHeaderAccount"
                        overflowWhenOpen="initial"
                        onOpening={() =>
                          this.props.setStoredRecordCollapsible({
                            type: "account",
                            id,
                          })
                        }
                        onClosing={() =>
                          this.props.removeStoredRecordCollapsible({
                            type: "account",
                            id,
                          })
                        }
                        open={this.props.storedRecordCollapsible.account.includes(
                          id
                        )}
                        trigger={
                          <div>
                            {label}
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                padding: "3px 10px",
                                border: "none",
                                borderRadius: "5px",
                                color: "white",
                                outline: "none",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30%",
                                right: "2%",
                              }}
                            >
                              <img src={ArrowIcon} alt="" height="10px" />
                            </button>
                          </div>
                        }
                        triggerWhenOpen={
                          <div>
                            {label}
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                padding: "3px 10px",
                                border: "none",
                                borderRadius: "5px",
                                color: "white",
                                outline: "none",
                                cursor: "pointer",
                                position: "absolute",
                                top: "4%",
                                right: "2%",
                              }}
                            >
                              <img src={ArrowIconUp} alt="" height="10px" />
                            </button>
                          </div>
                        }
                        triggerClassName="c-title-main"
                        triggerOpenedClassName="c-title-main"
                      >
                        <div>
                          {fields?.map((data) => {
                            const { id, label, type, name, choices } = data;
                            return type === "date" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchCarrierData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null ||
                                    this?.state?.userData?.custom_fields[
                                      name
                                    ] === undefined
                                      ? ""
                                      : moment(
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]
                                        ).format(dateFormat)}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "revenue" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchOpportunitiesData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : currencySymbol.symbol +
                                        getCurrencyValue(
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]
                                        )?.toLocaleString("en-US")}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "multi_select_dropdown" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchCarrierData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ] &&
                                        this?.state?.userData?.custom_fields[
                                          name
                                        ]
                                          .replace(/;/g, ", ")
                                          .substr(1)
                                          .slice(0, -2)}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "paragraph" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchCarrierData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p
                                    className="c-desc c-hoverDesc"
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      marginBottom: "15px",
                                      whiteSpace: "pre-wrap",
                                      height: "auto",
                                    }}
                                  >
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ]}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "hyperlink" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchCarrierData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p
                                    className="c-desc c-hoverDesc"
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      marginBottom: "15px",
                                      whiteSpace: "pre-wrap",
                                      height: "auto",
                                    }}
                                  >
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null ? (
                                      ""
                                    ) : (
                                      <a
                                        href={
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]?.startsWith("https://") ||
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]?.startsWith("http://")
                                            ? this?.state?.userData
                                                ?.custom_fields[name]
                                            : "https://" +
                                              this?.state?.userData
                                                ?.custom_fields[name]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="c-desc"
                                        style={{
                                          cursor: "pointer",
                                          color: "#41BE89",
                                        }}
                                      >
                                        {this?.state?.userData?.custom_fields[
                                          name
                                        ] &&
                                        this?.state?.userData?.custom_fields[
                                          name
                                        ].length > 40
                                          ? this?.state?.userData?.custom_fields[
                                              name
                                            ].substring(0, 39) + "..."
                                          : this?.state?.userData
                                              ?.custom_fields[name]}
                                      </a>
                                    )}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchCarrierData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ]}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Collapsible>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <OppModal
          customerId={this.props.customerId}
          showOpportunityModal={this.props.showOpportunityModal}
          setShowOpportunityModal={this.handleOpenModal}
          handleCloseModal={this.handleCloseModal}
          customerName={this.props.customerName}
          fetchAllOpportunitiesAgain={() =>
            this.props.fetchAllOpportunitiesCarriers(this.props.customerId)
          }
          email={this.state.email}
          formatted_phone={this.state.formatted_phone}
          website={this.state.website}
          fromRecord={true}
          showDealPortion={this.showDealPortion}
        />
        <TagDealModal
          showModal={this.state.showTagDealModal}
          handleCloseModal={this.handleCloseTagDealModal}
          submitHandler={this.tagDealsToCarriers}
          getAllUnTaggedDeals={this.getAllUnTaggedDeals}
          loading={this.state.tagDealLoader}
          allUntaggedDeals={this.state.allUntaggedDeals}
          currencySymbol={currencySymbol.symbol}
        />
        <CreateDealModal
          customerId={this.props.customerId}
          showOpportunityModal={this.state.showNewOpportunityModal}
          setShowOpportunityModal={this.handleOpenNewOpportunityModal}
          handleCloseModal={this.handleCloseNewOpportunityModal}
          customerName={this.props.customerName}
          fetchAllOpportunitiesAgain={() =>
            this.props.fetchAllOpportunitiesCarriers(this.props.customerId)
          }
          email={this.state.email}
          formatted_mobile={this.state.formatted_phone}
          website={this.state.website}
          fromRecord={true}
          showDealPortion={this.showDealPortion}
        />
        <DeleteAttachmentModal
          showOpportunityModal={this.state.showDeleteAttachmentModal}
          setShowOpportunityModal={this.handleOpenDeleteAttachmentModal}
          handleCloseModal={this.handleCloseDeleteAttachmentModal}
          deleteAttachmentHandler={this.deleteAttachmentHandler}
          documentId={this.state?.documentId}
        />
        <CreateContact
          showOpportunityModal={this.state.showContactModal}
          setShowOpportunityModal={this.handleOpenContactModal}
          handleCloseModal={this.handleCloseContactModal}
          carrierId={this.props.customerId}
          carrierName={this.state.name}
        />
        <CreateContactModal
          showOpportunityModal={this.state.showNewContactModal}
          setShowOpportunityModal={this.handleOpenNewContactModal}
          handleCloseModal={this.handleCloseNewContactModal}
          carrierId={this.props.customerId}
          carrierName={this.state.name}
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    isContactFieldsModified: state.allContactFields.isModified,
    isDealFieldsModified: state.allDealFields.isModified,
    storedRecordCollapsible: state.storedRecordCollapsible.fields,
    storedRecordSelectable: state.storedRecordSelectable.fields,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allCarrierStatuses: state.allCarrierStatuses.carrierStatuses,
    allCarrierCustomFields: state.allCarrierCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    setStoredRecordCollapsible: (data) =>
      dispatch(setStoredRecordCollapsible(data)),
    setStoredRecordSelectable: (data) =>
      dispatch(setStoredRecordSelectable(data)),
    removeStoredRecordCollapsible: (data) =>
      dispatch(removeStoredRecordCollapsible(data)),
    removeStoredRecordSelectable: (data) =>
      dispatch(removeStoredRecordSelectable(data)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(CarrierIntro);
