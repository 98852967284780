import React, { Component } from "react";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Col, Row } from "react-grid-system";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Pagination from "react-js-pagination";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
} from "../../../../redux";
import DashboardLoader from "../../../Loaders/DashboardLoader";

export class ReportsDealDashboard extends Component {
  state = {
    isInitializing: true,
    filter: "",
    operator: "",
    filter_rules: [
      { id: uuidv4(), attribute: "", operator: "", value: null, type: null },
    ],
    reportsTable: [],
    showColumn: false,
    displayMenu: false,
    isEditing: false,
    reportName: this.props.reportName,
    reportDescription: "",
    errorMessage: false,
    sortKey: null,
    displayExitMenu: false,
    sortDirection: false,
    allUsers: [],
    isLoading: false,
    exportLoader: false,
    isApplying: false,
    transferAccountEmail: [],
    showStageModal: false,
    currentStageId: "",
    currentDealData: {},
    currentStageType: "",
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
      name: "",
    },
    columnsArray: [
      {
        name: "Owner",
        key: "opportunity_owner",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Deal Name",
        key: "name",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Stage",
        key: "stage_name",
        selected: true,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Revenue",
        key: "revenue",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Next Step",
        key: "next_step",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Created Date",
        key: "created_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Close Date",
        key: "close_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: true,
      },
      {
        name: "Email",
        key: "email",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Phone",
        key: "phone",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "City",
        key: "city",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "State",
        key: "state",
        selected: false,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Zip",
        key: "zip",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Reason",
        key: "close_reason",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Account Name",
        key: "account_name",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Description",
        key: "description",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Age",
        key: "age",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Country",
        key: "country",
        selected: false,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Last Edit Date",
        key: "last_edit_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Last Activity Date",
        key: "last_activity_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
    ],
    stages: [],
    columnsData: [],
    showDeleteModal: false,
    showExitModal: false,
    showEmailModal: false,
    showPostMarkModal: false,
    showVerificationModal: false,
    signatureVerified: false,
    signatureId: null,
    activePage: 1,
    total: 0,
    transferAccount: [],
    allReportTransfer: false,
    showOwnerModal: false,
    ownerErrorMessage: false,
    customFields: [],
    access: "everyone",
    subscriptionStatus: null,
    showRedirectModal: false,
    emailCredits: 0,
    showCreditsModal: false,
    analyticsMode: "table",
  };
  filterRulesIsIn = () => {
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules?.map((rule) => {
        const { operator, value } = rule;
        if (
          this.props.userId &&
          this.props.selectedUsers.length !== 0 &&
          operator === "only_me"
        ) {
          rule.operator = "is_in";
          rule.value = this.props.selectedUsers.map((user) => ({
            value: user.name,
            label: user.name,
          }));
        }
        if (operator === "is_in" || operator === "is_not_in") {
          rule.value = value.map((val) => ({ value: val, label: val }));
        }
        return rule;
      }),
    }));
  };
  getReportById = () => {
    axios({
      method: "GET",
      url: `/reports/${this.props.reportId}`,
    }).then((res) => {
      const columns = res.data.report.columns.replace("mobile", "phone");
      const columnsPositionArray = columns.split(",");
      this.getCustomFields(columns, columnsPositionArray);
      this.setState(
        {
          reportsData: res.data.report,
          columnsData: columns,
          reportName: res.data.report.name,
          access: res.data.report.access,
          reportDescription: res.data.report.description,
          reportType: res.data.report.type,
          filter_rules: res.data.report.filters?.map((rule) => {
            const { operator, value } = rule;
            if (
              this.props.userId &&
              this.props.selectedUsers.length !== 0 &&
              operator === "only_me"
            ) {
              rule.operator = "is_in";
              rule.value = this.props.selectedUsers.map((user) => ({
                value: user.name,
                label: user.name,
              }));
            }
            if (operator === "is_in" || operator === "is_not_in") {
              if (rule.attribute === "stage") {
                rule.value = value.map((val) => ({
                  value: val,
                  label: this.state.stages.find((stage) => stage.id === val)
                    ?.name,
                }));
              } else {
                rule.value = value.map((val) => ({
                  value: val,
                  label: val,
                }));
              }
            }
            return rule;
          }),
          columnsArray: [
            {
              name: "Owner",
              key: "opportunity_owner",
              selected: columns.includes("opportunity_owner")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("opportunity_owner")
                ? columnsPositionArray.indexOf("opportunity_owner")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Deal Name",
              key: "name",
              selected: columns.includes("name") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("name")
                ? columnsPositionArray.indexOf("name")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  1,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Stage",
              key: "stage_name",
              selected: columns.includes("stage_name")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("stage_name")
                ? columnsPositionArray.indexOf("stage_name")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  2,
              type: "dropdown",
              choices: [],
              editable: true,
            },
            {
              name: "Revenue",
              key: "revenue",
              selected: columns.includes("revenue")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("revenue")
                ? columnsPositionArray.indexOf("revenue")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  3,
              type: "decimal",
              choices: [],
              editable: true,
            },
            {
              name: "Next Step",
              key: "next_step",
              selected: columns.includes("next_step")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("next_step")
                ? columnsPositionArray.indexOf("next_step")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  4,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Created Date",
              key: "created_date",
              selected: columns.includes("created_date")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("created_date")
                ? columnsPositionArray.indexOf("created_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  5,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Close Date",
              key: "close_date",
              selected: columns.includes("close_date")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("close_date")
                ? columnsPositionArray.indexOf("close_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  6,
              type: "date",
              choices: [],
              editable: true,
            },
            {
              name: "Email",
              key: "email",
              selected: columns.includes("email")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("email")
                ? columnsPositionArray.indexOf("email")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  7,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Phone",
              key: "phone",
              selected: columns.includes("phone")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("phone")
                ? columnsPositionArray.indexOf("phone")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  8,
              type: "decimal",
              choices: [],
              editable: true,
            },
            {
              name: "City",
              key: "city",
              selected: columns.includes("city") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("city")
                ? columnsPositionArray.indexOf("city")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  9,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "State",
              key: "state",
              selected: columns.includes("state")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("state")
                ? columnsPositionArray.indexOf("state")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  10,
              type: "dropdown",
              choices: [],
              editable: true,
            },
            {
              name: "Zip",
              key: "zip",
              selected: columns.includes("zip") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("zip")
                ? columnsPositionArray.indexOf("zip")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  11,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Reason",
              key: "close_reason",
              selected: columns.includes("close_reason")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("close_reason")
                ? columnsPositionArray.indexOf("close_reason")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  12,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Account Name",
              key: "account_name",
              selected: columns.includes("account_name")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("account_name")
                ? columnsPositionArray.indexOf("account_name")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  13,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Description",
              key: "description",
              selected: columns.includes("description")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("description")
                ? columnsPositionArray.indexOf("description")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  14,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Age",
              key: "age",
              selected: columns.includes("age") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("age")
                ? columnsPositionArray.indexOf("age")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  15,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Country",
              key: "country",
              selected: columns.includes("country")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("country")
                ? columnsPositionArray.indexOf("country")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  16,
              type: "dropdown",
              choices: [],
              editable: true,
            },
            {
              name: "Last Edit Date",
              key: "last_edit_date",
              selected: columns.includes("last_edit_date")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("last_edit_date")
                ? columnsPositionArray.indexOf("last_edit_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  17,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Last Activity Date",
              key: "last_activity_date",
              selected: columns.includes("last_activity_date")
                ? true
                : false,
              custom: false,
              position: columnsPositionArray.includes("last_activity_date")
                ? columnsPositionArray.indexOf("last_activity_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  18,
              type: "date",
              choices: [],
              editable: false,
            },
          ],
        },
        () => this.getReportInitializer(res.data.report.order_by),
        () => this.filterRulesIsIn()
      );
    });
  };
  getReportInitializer = (orderby, page = 1) => {
    const orderBy = orderby?.split(",");
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    this.setState({
      sortKey: orderby === null ? null : orderBy[0],
      sortDirection:
        orderby === null ? null : orderBy[1] === "desc" ? true : false,
    });
    axios({
      method: "POST",
      url: `/opportunities/reports?_limit=30&_page=${page}`,
      data: {
        filter_rules: newValue,
        sort_key: orderby === null ? null : orderBy[0],
        sort_dir: orderby === null ? null : orderBy[1],
      },
    })
      .then((res) => {
        this.setState({
          reportsTable: res.data.opportunities,
          total: res.data.total,
          isInitializing: false,
        });
      })
      .catch((err) => this.setState({ isInitializing: false }));
  };
  getCustomFields = (selectedArray, columnsPositionArray) => {
    this.setState({
      customFields: this.props.allDealCustomFields,
    });
    const customField = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field, index) => {
            const { label, name, id, type, choices } = field;
            return {
              name: label,
              key: name,
              selected: selectedArray.includes(id) ? true : false,
              custom: true,
              id,
              position: columnsPositionArray.includes(id.toString())
                ? columnsPositionArray.indexOf(id.toString())
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  index,
              type,
              choices,
              editable: true,
            };
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const columnsArray = this.state.columnsArray;
    this.setState({
      columnsArray: columnsArray.concat(customFieldArray),
    });
  };
  getReport = (page = 1) => {
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return rule;
    });
    this.setState({ isApplying: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/opportunities/reports?_limit=30&_page=${page}`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
        data: {
          filter_rules: newValue,
          sort_key: this.state.sortKey,
          sort_dir: this.state.sortDirection === true ? "desc" : "asc",
        },
      })
        .then((res) => {
          this.setState({
            reportsTable: res.data.opportunities,
            total: res.data.total,
            isApplying: false,
          });
        })
        .catch((err) => this.setState({ isApplying: false }));
    } else {
      this.setState({ isApplying: false });
    }
  };
  sortButtonClickHandler = (column) => {
    this.setState(
      {
        sortKey: column.custom ? column.id : column.key,
        sortDirection: !this.state.sortDirection,
      },
      this.getReport
    );
  };
  storeSorting = () => {
    this.getReport();
    let sortDirection = "asc";
    this.state.sortDirection === true
      ? (sortDirection = "desc")
      : (sortDirection = "asc");
    axios({
      method: "PATCH",
      url: `/reports/${this.props.reportId}`,
      data: {
        order_by: `${this.state.sortKey},${sortDirection}`,
      },
    });
  };
  componentDidMount() {
    this.getReportById();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.userId !== prevProps.userId) {
      this.getReportById();
    }
  }
  render() {
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const { columnsArray } = this.state;
    return (
      <div style={{ paddingTop: "55px" }}>
        {!this.props.fromModal && (
          <h3
            className="funnel-heading-sub"
            style={{
              marginBottom: "0",
              textDecoration: "underline",
              zIndex: "1",
              cursor: "pointer",
            }}
            onClick={this.props.chartRedirectToReport}
          >
            {this.state.reportName}
          </h3>
        )}
        {this.state.isInitializing === true ? (
          <DashboardLoader />
        ) : this.state.isInitializing === false &&
          this.state.reportsTable?.length === 0 ? (
          <div
            style={{
              margin: "auto",
              fontWeight: "500",
            }}
          >
            No records found.
          </div>
        ) : this.state.isApplying ? (
          <DashboardLoader />
        ) : (
          <div
            style={{
              overflow: "auto",
              maxHeight: this.props.maxHeight,
              ...(this.props.maxWidth && {
                maxWidth: this.props.maxWidth,
              }),
            }}
          >
            <table className="accountList__table" style={{ marginTop: "0" }}>
              <thead>
                {columnsArray
                  .sort((a, b) => (a.position > b.position ? 1 : -1))
                  .map(
                    (column) =>
                      this.state?.columnsData.includes(
                        column.id === undefined ? column.key : column.id
                      ) && (
                        <th key={column.key}>
                          <Row>
                            <Col lg={9} xl={9}>
                              {column.name}
                            </Col>
                            <Col lg={3} xl={3}>
                              <button
                                type="button"
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                  outline: "none",
                                  color: "#657885",
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      sortKey: column.custom
                                        ? column.id
                                        : column.key,
                                      sortDirection: !this.state.sortDirection,
                                    },
                                    this.storeSorting
                                  )
                                }
                              >
                                <i className="fa fa-long-arrow-up"></i>
                                <i className="fa fa-long-arrow-down"></i>
                              </button>
                            </Col>
                          </Row>
                        </th>
                      )
                  )}
              </thead>
              <tbody>
                {this.state?.reportsTable.map((report) => {
                  const { id, name } = report;
                  let newTabData = {
                    type: "opportunity",
                    id: id,
                    name: name,
                  };
                  return (
                    <tr key={id}>
                      {columnsArray.map(
                        (column) =>
                          this.state.columnsData.includes(
                            column.id === undefined ? column.key : column.id
                          ) &&
                          (column.key === "name" ? (
                            <td
                              style={{ cursor: "pointer", color: "#41BE89" }}
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    ...newTabData,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab(newTabData);
                                }
                                // this.props.history.push("/active-tabs");
                              }}
                            >
                              {name}
                            </td>
                          ) : column.key === "description" ? (
                            <td>
                              {report.description?.length > 140
                                ? report.description?.substring(0, 140)
                                : report.description}
                            </td>
                          ) : column.key === "revenue" ? (
                            <td>
                              {report.revenue}{" "}
                              {this.props.companyOnboarding?.currency}
                            </td>
                          ) : column.key === "phone" ? (
                            <td>{report.formatted_phone}</td>
                          ) : moment(
                              report[column.key] ||
                                report.custom_fields[column.key],
                              "YYYY-MM-DDTHH:mm:ss.SSSZ",
                              true
                            ).isValid() ? (
                            <td>
                              {(report[column.key] ||
                                report.custom_fields[column.key]) &&
                                moment(
                                  report[column.key] ||
                                    report.custom_fields[column.key]
                                ).format(dateFormat)}
                            </td>
                          ) : (
                            <td>
                              {report.custom_fields[column.key] !== undefined &&
                              report.custom_fields[column.key] !== null &&
                              report.custom_fields[column.key]
                                .toString()
                                .startsWith(";")
                                ? report.custom_fields[column.key]
                                    .replace(/;/g, ", ")
                                    .substr(1)
                                    .slice(0, -2)
                                : report[column.key] ||
                                  report.custom_fields[column.key]}
                            </td>
                          ))
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div style={{ marginTop: "8px" }}>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={30}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              this.setState({ activePage: page });
              this.getReport(page);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        </div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};
export default connect(MSP, MDP)(ReportsDealDashboard);
