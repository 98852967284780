import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";
import JoditDemo from "../SendEmail/JoditDemo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import SequenceVariableSubstituteModal from "./SequenceVariableSubstituteModal";

class SendSequenceModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    sequences: [],
    isLoading: false,
    showVariableModal: false,
    allVariables: [],
  };
  handleOpenVariableModal = () => {
    this.setState({
      showVariableModal: true,
    });
  };
  handleCloseVariableModal = () => {
    this.setState({
      showVariableModal: false,
    });
  };
  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  dateChangeHandler = (id, date) => {
    // const offsetDate =
    //   date === null || date === ""
    //     ? new Date()
    //     : date.getTimezoneOffset() < 0
    //     ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    //     : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.date = date;
        }
        return seq;
      }),
    }));
  };
  timeChangeHandler = (id, date) => {
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.time = date;
        }
        return seq;
      }),
    }));
  };
  setSequenceValue = () => {
    let allVariables = [];
    this.setState({
      sequences: this.props.selectedSequence.steps
        ?.sort((a, b) => a.order - b.order)
        .map((step, index) => {
          const date = new Date();
          let sec = date.getTime() / 1000;
          let seconds = Math.round(sec + step.delay);
          const newDate = new Date(seconds * 1000);
          let finalContent = step.body;
          let finalSubject = step.subject;
          for (let i = 0; i < step.personalize_vars.length; i++) {
            finalContent = finalContent.includes(
              `{{ ${step.personalize_vars[i].key} }}`
            )
              ? finalContent.replaceAll(
                  `{{ ${step.personalize_vars[i].key} }}`,
                  step.personalize_vars[i].value
                )
              : finalContent;
            finalSubject = finalSubject.includes(
              `{{ ${step.personalize_vars[i].key} }}`
            )
              ? finalSubject.replaceAll(
                  `{{ ${step.personalize_vars[i].key} }}`,
                  step.personalize_vars[i].value
                )
              : finalSubject;
          }
          for (let i = 0; i < step.personalize_vars.length; i++) {
            allVariables = [...allVariables, step.personalize_vars[i]];
          }
          const allVariablesArray = allVariables?.map((vars) => vars.value);
          if (
            allVariables.length !== 0 &&
            this.props.showOpportunityModal &&
            allVariablesArray.includes(null)
          ) {
            this.handleOpenVariableModal();
          }
          return {
            to: this.props.toEmail[0],
            from: this.props.fromEmail,
            order: index + 1,
            subject: finalSubject === false ? step.subject : finalSubject,
            body:
              finalContent === false
                ? step.body + `<br/><br/>` + this.props.emailSignature
                : finalContent + `<br/><br/>` + this.props.emailSignature,
            id: step.id,
            delay: step.delay,
            showDateTime: step.delay === 0 ? "now" : "later",
            date: newDate,
            time: newDate,
            variables: step.personalize_vars,
          };
        }),
      allVariables: allVariables.filter(
        (v, i, a) => a.findIndex((t) => t.key === v.key) === i
      ),
    });
  };
  setNullSequenceValue = () => {
    this.setState({
      sequences: this.props.selectedSequence.steps
        ?.sort((a, b) => a.order - b.order)
        .map((step, index) => {
          const date = new Date();
          let sec = date.getTime() / 1000;
          let seconds = Math.round(sec + step.delay);
          const newDate = new Date(seconds * 1000);
          let personalizeVars =
            step.personalize_vars.length === 0
              ? []
              : step.personalize_vars?.map((v) => ({
                  ...v,
                  value: v.value === null ? v.substitute : v.value,
                }));
          let finalContent = step.body;
          let finalSubject = step.subject;
          for (let i = 0; i < step.personalize_vars.length; i++) {
            finalContent = finalContent.includes(
              `{{ ${personalizeVars[i].key} }}`
            )
              ? finalContent.replaceAll(
                  `{{ ${personalizeVars[i].key} }}`,
                  personalizeVars[i].value
                )
              : finalContent;
            finalSubject = finalSubject.includes(
              `{{ ${personalizeVars[i].key} }}`
            )
              ? finalSubject.replaceAll(
                  `{{ ${personalizeVars[i].key} }}`,
                  personalizeVars[i].value
                )
              : finalSubject;
          }
          return {
            to: this.props.toEmail[0],
            from: this.props.fromEmail,
            order: index + 1,
            subject: finalSubject === false ? step.subject : finalSubject,
            body:
              finalContent === false
                ? step.body + `<br/><br/>` + this.props.emailSignature
                : finalContent + `<br/><br/>` + this.props.emailSignature,
            id: step.id,
            delay: step.delay,
            showDateTime: step.delay === 0 ? "now" : "later",
            date: newDate,
            time: newDate,
            variables: step.personalize_vars,
          };
        }),
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setSequenceValue();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.setSequenceValue();
    }
  }
  subjectChangeHandler = (id, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.subject = value;
        }
        return seq;
      }),
    }));
  };
  updateContent = (id, value) => {
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.body = value;
        }
        return seq;
      }),
    }));
  };
  setShowDateTime = (id, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.showDateTime = value;
        }
        return seq;
      }),
    }));
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  sendSequence = () => {
    this.setState({
      isLoading: true,
    });
    axios({
      method: "POST",
      url: `/sequences/schedule`,
      data: {
        ...(this.props.emailType === "account_id" && {
          account_id: this.props.accountId,
        }),
        ...(this.props.emailType === "carrier_id" && {
          carrier_id: this.props.carrierId,
        }),
        ...(this.props.emailType === "opportunity_id" && {
          opportunity_id: this.props.opportunityId,
        }),
        ...(this.props.emailType === "contact_id" && {
          contact_id: this.props.contactId,
        }),
        sequences: this.state.sequences?.map((sequence) => {
          const { to, from, order, subject, body, date, showDateTime } =
            sequence;
          const sendTime = new Date(new Date(date).toUTCString().substr(0, 25));
          const myDate = moment(sendTime).format("YYYY-MM-DD");
          const myTime = moment(sendTime).format("HH:mm:ss");
          const nowDate = new Date(new Date().toUTCString().substr(0, 25));
          return {
            to,
            from,
            send_now: showDateTime === "now" ? true : false,
            send_at:
              showDateTime === "now"
                ? moment(nowDate).format("YYYY-MM-DD HH:mm:ss")
                : `${myDate} ${myTime}`,
            order,
            subject,
            body,
            sequence_id: this.props.selectedSequence.id,
          };
        }),
      },
    })
      .then((res) => {
        NotificationManager.success("Sequence started successfully");
        this.props.handleCloseEmailModal();
        this.props.loadActivities(1, "refetch");
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error starting sequence.");
        this.setState({
          isLoading: false,
        });
      });
  };
  readTemplateVarsValues = (allVar, e) => {
    e.preventDefault();
    this.setState({
      allVariables: allVar?.map((v) => ({
        key: v.key,
        label: v.label,
        value: v.value === null ? v.substitute : v.value,
      })),
      showVariableModal: false,
    });
    this.setNullSequenceValue();
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <SequenceVariableSubstituteModal
            showOpportunityModal={this.state.showVariableModal}
            setShowOpportunityModal={this.handleOpenVariableModal}
            handleCloseModal={this.handleCloseVariableModal}
            readTemplateVarsValues={this.readTemplateVarsValues}
            personalizedVariablesArray={this.state.allVariables}
          />
          <div className="modal">
            <div
              className="modal__content"
              style={
                this.state.windowWidth < 600
                  ? {
                      height: "95vh",
                      top: "1%",
                      left: "0%",
                      width: "90vw",
                    }
                  : { height: "90vh", top: "2%", left: "12%", width: "70vw" }
              }
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Send Sequence</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div style={{ height: "70vh", overflowY: "auto" }}>
                {this.state.sequences?.map((step) => {
                  return (
                    <div
                      style={{
                        border: "1px solid #eee",
                        position: "relative",
                        marginBottom: "16px",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        marginRight: "5px",
                      }}
                    >
                      <div>
                        <select
                          value={step.showDateTime}
                          onChange={(e) => this.setShowDateTime(step.id, e)}
                          style={{ width: "180px", marginRight: "20px" }}
                        >
                          <option value="now">Send now</option>
                          <option value="later">Send Later</option>
                        </select>
                        {step.showDateTime === "later" && (
                          <span
                            style={
                              this.state.windowWidth < 600
                                ? {
                                    display: "block",
                                  }
                                : {}
                            }
                          >
                            <DatePicker
                              selected={step.date}
                              todayButton="Today"
                              value={step.date}
                              onChange={(date) =>
                                this.dateChangeHandler(step.id, date)
                              }
                              placeholderText="Date"
                              autoComplete="off"
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              className="modal-datepicker"
                            />
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid #EDF0F2",
                          borderBottom: "1px solid #EDF0F2",
                          margin: "10px 0",
                          padding: "3px 0",
                        }}
                      >
                        <label
                          style={{
                            fontSize: "14px",
                            display: "inline-block",
                            marginRight: "5px",
                            fontWeight: "500",
                          }}
                        >
                          Subject
                        </label>
                        <input
                          name="subject"
                          type="text"
                          style={{
                            width: "86%",
                            padding: "5px",
                            borderRadius: "4px",
                            border: "none",
                            outline: "none",
                            fontSize: "14px",
                            display: "inline-block",
                          }}
                          value={step.subject}
                          onChange={(e) =>
                            this.subjectChangeHandler(step.id, e)
                          }
                        />
                      </div>
                      <div
                        style={{
                          height: "30.2vh",
                          maxHeight: "30.2vh",
                          minHeight: "30vh",
                        }}
                      >
                        <JoditDemo
                          content={step.body}
                          updateContent={(value) =>
                            this.updateContent(step.id, value)
                          }
                          triggerInputFile={this.triggerInputFile}
                          accountId={this.props.accountId}
                          carrierId={this.props.carrierId}
                          opportunityId={this.props.opportunityId}
                          contactId={this.props.contactId}
                          windowWidth={this.state.windowWidth}
                          isTemplate={true}
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="modal__confirm-button"
                    onClick={this.sendSequence}
                    disabled={this.state.isLoading}
                    style={{ right: "7%" }}
                  >
                    {this.state.isLoading ? "Starting ..." : "Start Sequence"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SendSequenceModal;
