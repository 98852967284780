import {
  FETCH_EQUIPMENT_TYPES_REQUEST,
  FETCH_EQUIPMENT_TYPES_SUCCESS,
  FETCH_EQUIPMENT_TYPES_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllEquipmentTypesRequest = () => {
  return {
    type: FETCH_EQUIPMENT_TYPES_REQUEST,
  };
};
const fetchAllEquipmentTypesSuccess = (data) => {
  return {
    type: FETCH_EQUIPMENT_TYPES_SUCCESS,
    payload: data,
  };
};
const fetchAllEquipmentTypesFailure = (err) => {
  return {
    type: FETCH_EQUIPMENT_TYPES_FAILURE,
    payload: err,
  };
};

// Fetch All AllEquipmentTypes
export const fetchAllEquipmentTypes = () => {
  return (dispatch) => {
    dispatch(fetchAllEquipmentTypesRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/lanes/equipment-types`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.equipment_types;
        dispatch(fetchAllEquipmentTypesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllEquipmentTypesFailure(errorMsg));
      });
  };
};
