import React from 'react';
import AnalyticsComingSoonIcon from "../images/comingsoon.svg"
import "../sass/Analytics.scss"

const AnalyticsComingSoon=(props)=> {
  return (
    <div>
      <h1 style={{textAlign:'center',fontSize:'40px'}}>Coming Soon!</h1>
      <img src={AnalyticsComingSoonIcon} alt="" className="analytics_coming-soon" />
    </div>
  );
}

export default AnalyticsComingSoon;
