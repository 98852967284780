import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Funnel from "../Funnel";
import HottestFollowupDashboard from "../HottestFollowup/HottestFollowupDashboard";
import LeadFunnel from "../LeadFunnel/LeadFunnel";
import Targets from "../Targets";
import Tasks from "../Tasks";
import WonLostReason from "../WonLostReason/WonLostReason";
import AddComponentModal from "./AddComponentModal";
import { Prompt, withRouter } from "react-router-dom";
import closeIcon from "../../images/closeIcon.svg";
import Select from "react-select";

import "./DashboardCustomScreen.scss";
import AccountsColumnDashboard from "./ReportCards/Pages/AccountsColumnDashboard";
import AccountsLineDashboard from "./ReportCards/Pages/AccountsLineDashboard";
import AccountsPieDashboard from "./ReportCards/Pages/AccountsPieDashboard";
import AccountsScoreCardDashboard from "./ReportCards/Pages/AccountsScoreCardDashboard";
import ActivitiesColumnDashboard from "./ReportCards/Pages/Activities/ActivitiesColumnDashboard";
import ActivitiesLineDashboard from "./ReportCards/Pages/Activities/ActivitiesLineDashboard";
import ActivitiesPieDashboard from "./ReportCards/Pages/Activities/ActivitiesPieDashboard";
import ActivitiesScoreCardDashboard from "./ReportCards/Pages/Activities/ActivitiesScoreCardDashboard";
import DealsColumnDashboard from "./ReportCards/Pages/Deals/DealsColumnDashboard";
import DealsLineDashboard from "./ReportCards/Pages/Deals/DealsLineDashboard";
import DealsPieDashboard from "./ReportCards/Pages/Deals/DealsPieDashboard";
import DealsScoreCardDashboard from "./ReportCards/Pages/Deals/DealsScoreCardDashboard";
import ReportsAccountDashboard from "./ReportCards/Pages/ReportsAccountDashboard";
import ReportsActivityDashboard from "./ReportCards/Pages/ReportsActivityDashboard";
import ReportsContactDashboard from "./ReportCards/Pages/ReportsContactDashboard";
import ReportsDealDashboard from "./ReportCards/Pages/ReportsDealDashboard";
import TasksColored from "../Tasks/TasksColored";
import CustomComponentModal from "./CustomComponentModal";
import CarriersPieDashboard from "./ReportCards/Pages/Carriers/CarriersPieDashboard";
import CarriersLineDashboard from "./ReportCards/Pages/Carriers/CarriersLineDashboard";
import CarriersColumnDashboard from "./ReportCards/Pages/Carriers/CarriersColumnDashboard";
import CarriersScoreCardDashboard from "./ReportCards/Pages/Carriers/CarriersScoreCardDashboard";
import ReportsCarrierDashboard from "./ReportCards/Pages/Carriers/ReportsCarrierDashboard";

import { NotificationManager } from "react-notifications";
// Redux stuff
import { connect } from "react-redux";
import {
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
  setTab,
  fetchAllDashboards,
} from "../../redux";
import ActivityMetrics from "./ActivityMetrics/ActivityMetrics";
import UnassignedNext7Days from "./UnassignedLeads/UnassignedNext7Days";
import ActivitiesPeriodOverPeriodChart from "./ActivitiesPeriodOverPeriodChart/ActivitiesPeriodOverPeriodChart";
import PipelineMetrics from "./PipelineMetrics/PipelineMetrics";
import PipelineStageChanges from "./PipelineStageChanges/PipelineStageChanges";
import UserLeaderboard from "./UserLeaderboard/UserLeaderboard";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#62ca9d",
    transform: "translateY(-3px)",
    height: "auto",
    boxShadow: "none",
    // zIndex: "1002",
    ":active": {
      ...styles[":active"],
      border: "2px solid #62ca9d",
      outline: "#62ca9d",
    },
    ":hover": {
      ...styles[":hover"],
      border: "1px solid #62ca9d",
    },
    ":focus": {
      ...styles[":hover"],
      border: "1px solid #62ca9d",
    },
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "default",
      zIndex: "100002",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

const DashboardCustomScreen = (props) => {
  const [dashboardName, setDashboardName] = useState(
    `${props.authUser.name}'s dashboard`
  );
  const [dashboardType, setDashboardType] = useState("user"); // company || team || user
  const [loader, setLoader] = useState(false);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamIds, setTeamIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [showPropmpt, setShowPropmpt] = useState(true);
  const [isPublicDashboard, setIsPublicDashboard] = useState(false);
  // widget data states
  const [layouts, setLayouts] = useState(null);
  const [widgetArray, setWidgetArray] = useState([
    { i: "tasks", x: 0, y: 0, w: 4, h: 4.7, type: "tasks" },
    { i: "lead", x: 4, y: 0, w: 4, h: 2.35, type: "lead" },
    { i: "funnel", x: 8, y: 0, w: 4, h: 2.35, type: "funnel" },
    {
      filters: [
        {
          attribute: "owner",
          operator: "is_in",
          value: [
            {
              label: `${props.authUser.name}`,
              value: `${props.authUser.name}`,
            },
          ],
        },
      ],
      h: 2.35,
      height: 2.35,
      i: "default-lead-source-pie",
      name: "Lead Sources (Contacts)",
      reportType: "contact",
      segmentBy: "source",
      type: "pie",
      w: 4,
      width: 4,
      x: 4,
      y: 3,
      yAxis: "",
    },
    { i: "targets", x: 8, y: 3, w: 4, h: 2.35, type: "targets" },
  ]);
  // other states
  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [showCustomComponentModal, setShowCustomComponentModal] =
    useState(false);
  const [reportData, setReportData] = useState({});
  const [reportType, setReportType] = useState("table");
  const setSelectedFunnel = (selectedFunnel) => {
    return selectedFunnel;
  };
  // constants
  const pathArray = window.location.pathname.split("/");
  const dashboardMode = pathArray[2];
  const dashboardId = pathArray[3] ?? "";
  const getDashboardById = () => {
    setDashboardName(props.location.state.name);
    setDashboardType(props.location.state.type);
    setWidgetArray(props.location.state.settings);
    setIsPublicDashboard(props.location.state.is_public);
  };
  useEffect(() => {
    if (dashboardMode === "edit") {
      getDashboardById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axios({
      method: "GET",
      url: `/admin/teams?view=table`,
    }).then((response) => {
      setTeams(response.data.teams);
      if (dashboardMode === "edit") {
        const selectedTeams = response.data.teams?.filter((team) =>
          props.location.state.team_ids?.includes(team.id)
        );
        const selectedTeamObj = selectedTeams?.map((team) => ({
          label: team.name,
          value: team.id,
        }));
        setTeamIds(selectedTeamObj);
      }
    });
    axios({
      method: "GET",
      url: `/admin/users`,
    }).then((response) => {
      setUsers(
        response.data.users
          ?.filter((user) => user.id !== props.authUser.id)
          ?.slice()
          ?.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // Ignore case for comparison
            const nameB = b.name.toUpperCase(); // Ignore case for comparison

            if (nameA < nameB) {
              return -1; // Negative value: a should come before b
            }
            if (nameA > nameB) {
              return 1; // Positive value: b should come before a
            }
            return 0; // Return 0 for elements considered equal
          })
      );
      if (dashboardMode === "edit") {
        const selectedUsers = response.data.users?.filter((user) =>
          props.location.state.user_ids?.includes(user.id)
        );
        const selectedUserObj = selectedUsers?.map((user) => ({
          label: user.name,
          value: user.id,
        }));
        setUserIds(selectedUserObj);
      }
    });
  }, []);
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    // window.addEventListener('unload', handleEndConcert)
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      // window.removeEventListener('unload', handleEndConcert)
      // handleEndConcert()
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  const isPrompt = () => {
    return showPropmpt;
  };

  // refs
  const bodyRef = useRef();
  const renderComponent = (item) => {
    if (item.type === "tasks") {
      return (
        <TasksColored
          userId={props.authUser.id}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
        />
      );
    } else if (item.type === "tasks_old") {
      return (
        <Tasks
          userId={props.authUser.id}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
        />
      );
    } else if (item.type === "lead") {
      return (
        <LeadFunnel
          userId={props.authUser.id}
          wonStage={"2"}
          lostStage={"2"}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
        />
      );
    } else if (item.type === "funnel") {
      return (
        <Funnel
          userId={props.authUser.id}
          wonStage={"2"}
          lostStage={"2"}
          userName={props.authUser.name}
          setSelectedFunnel={setSelectedFunnel}
          setClick={(click) => console.log(click)}
        />
      );
    } else if (item.type === "targets") {
      return (
        <Targets
          minValue={0}
          maxValue={50}
          userId={props.authUser.id}
          targetPermissions={[]}
          newStage={"2"}
          wonStage={"2"}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
        />
      );
    } else if (item.type === "followup") {
      return (
        <HottestFollowupDashboard
          userId={props.authUser.id}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
        />
      );
    } else if (item.type === "activities-metrics") {
      return (
        <ActivityMetrics
          userId={props.authUser.id}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
        />
      );
    } else if (item.type === "pipeline-metrics") {
      return (
        <PipelineMetrics
          userId={props.authUser.id}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
        />
      );
    } else if (
      item.type === "activities-leaderboard" ||
      item.type === "stage-change-leaderboard"
    ) {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <UserLeaderboard
          userId={props.authUser.id}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
          reportWidth={width}
          maxHeight={item.height === 2.35 ? "275px" : "690px"}
          type={
            item.type === "activities-leaderboard"
              ? "activities"
              : "stage_changes"
          }
        />
      );
    } else if (item.type === "pipeline-stage-changes") {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <PipelineStageChanges
          userId={props.authUser.id}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
          maxWidth={width}
          maxHeight={"250px"}
        />
      );
    } else if (item.type === "activities-period-over-period") {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <ActivitiesPeriodOverPeriodChart
          userId={props.authUser.id}
          userName={props.authUser.name}
          setClick={(click) => console.log(click)}
          reportWidth={width}
        />
      );
    } else if (item.type === "reasons") {
      return (
        <WonLostReason
          userId={props.authUser.id}
          wonStage={"2"}
          lostStage={"2"}
          userName={props.authUser.name}
          selectedFunnel={"4096"}
        />
      );
    } else if (item.type === "unassignedNext7Days") {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <div className="card">
          <UnassignedNext7Days
            userId={props.authUser.id}
            userName={props.authUser.name}
            maxHeight={"250px"}
            maxWidth={width - 20}
          />
        </div>
      );
    } else if (item.type === "pie") {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <div className="card">
          {item.reportType === "opportunities" ? (
            <DealsPieDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              dataFormat={item.dataFormat}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesPieDashboard
              filter_rules={item.filters}
              segmentBy={
                item.segmentBy === "type" ? "category" : item.segmentBy
              }
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              dataFormat={item.dataFormat}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersPieDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              dataFormat={item.dataFormat}
            />
          ) : (
            <AccountsPieDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              isDefaultChart={item.i === "default-lead-source-pie"}
              setLeadSourceChartType={setLeadSourceChartType}
              setClick={(click) => console.log(click)}
              reportId={item.id}
              dataFormat={item.dataFormat}
            />
          )}
        </div>
      );
    } else if (item.type === "line") {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <div className="card">
          {item.reportType === "opportunities" ? (
            <DealsLineDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              yAxis={item.yAxis}
              reportId={item.id}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesLineDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersLineDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          ) : (
            <AccountsLineDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          )}
        </div>
      );
    } else if (item.type === "bar") {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <div className="card">
          {item.reportType === "opportunities" ? (
            <DealsColumnDashboard
              filter_rules={item.filters}
              chartType="bar"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              yAxis={item.yAxis}
              reportId={item.id}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesColumnDashboard
              filter_rules={item.filters}
              chartType="bar"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersColumnDashboard
              filter_rules={item.filters}
              chartType="bar"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          ) : (
            <AccountsColumnDashboard
              filter_rules={item.filters}
              chartType="bar"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          )}
        </div>
      );
    } else if (item.type === "column") {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <div className="card">
          {item.reportType === "opportunities" ? (
            <DealsColumnDashboard
              filter_rules={item.filters}
              chartType="column"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              yAxis={item.yAxis}
              reportId={item.id}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesColumnDashboard
              filter_rules={item.filters}
              chartType="column"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersColumnDashboard
              filter_rules={item.filters}
              chartType="column"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          ) : (
            <AccountsColumnDashboard
              filter_rules={item.filters}
              chartType="column"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
            />
          )}
        </div>
      );
    } else if (item.type === "scorecard") {
      return (
        <div className="card">
          {item.reportType === "opportunities" ? (
            <DealsScoreCardDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportName={item.name}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesScoreCardDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportName={item.name}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersScoreCardDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportName={item.name}
            />
          ) : (
            <AccountsScoreCardDashboard
              filter_rules={item.filters}
              reportType={item.reportType}
              reportName={item.name}
            />
          )}
        </div>
      );
    } else if (item.type === "table") {
      let width = (item.width * (bodyRef?.current?.clientWidth - 75)) / 12;
      return (
        <div className="card">
          {item.reportType === "opportunities" ? (
            <ReportsDealDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
            />
          ) : item.reportType === "activities" ? (
            <ReportsActivityDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
            />
          ) : item.reportType === "contacts" ? (
            <ReportsContactDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
            />
          ) : item.reportType === "carriers" ? (
            <ReportsCarrierDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
            />
          ) : (
            <ReportsAccountDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
            />
          )}
        </div>
      );
    }
  };

  const handleModify = (layouts, layout) => {
    const tempArray = widgetArray;
    console.log("chart dekho", layouts, layout);
    setLayouts(layout);
    layouts?.map((position) => {
      tempArray[Number(position.i)].x = position.x;
      tempArray[Number(position.i)].y = position.y;
      tempArray[Number(position.i)].width = position.w;
      tempArray[Number(position.i)].w = position.w;
      tempArray[Number(position.i)].height = position.h;
      tempArray[Number(position.i)].h = position.h;
    });
    setWidgetArray(tempArray);
  };
  const handleStop = (layouts, layout) => {
    const tempArray = widgetArray;
    layouts?.map((position) => {
      tempArray[Number(position.i)].x = position.x;
      tempArray[Number(position.i)].y = position.y;
      tempArray[Number(position.i)].width = position.w;
      tempArray[Number(position.i)].w = position.w;
      tempArray[Number(position.i)].height = position.h;
      tempArray[Number(position.i)].h = position.h;
    });
    setWidgetArray(tempArray);
  };
  const setLeadSourceChartType = (type) => {
    const myArray = widgetArray?.map((setting) => {
      if (setting.i === "default-lead-source-pie") {
        setting.reportType = type;
      }
      return setting;
    });
    setWidgetArray(myArray);
  };

  const handleAdd = (
    widgetType,
    widgetData,
    widgetFilters,
    segmentBy,
    yAxis,
    dataFormat
  ) => {
    setWidgetArray([
      ...widgetArray,
      {
        i: widgetType + (widgetArray.length + 1),
        x: 0,
        y: 0,
        w: 4,
        h: 2.35,
        ...widgetData,
        type: widgetType,
        reportType: widgetData.type,
        filters: widgetFilters,
        segmentBy,
        yAxis,
        dataFormat,
      },
    ]);
    setShowAddComponentModal(false);
  };
  const handleEdit = (
    widgetType,
    widgetData,
    widgetFilters,
    segmentBy,
    yAxis,
    dataFormat
  ) => {
    const newArray = widgetArray?.map((widget) => {
      if (widget.i === widgetData.i) {
        widget = {
          i: widgetType + (widgetArray.length + 1),
          x: 0,
          y: 0,
          w: 4,
          h: 2.35,
          ...widgetData,
          type: widgetType,
          reportType: widgetData.type,
          filters: widgetFilters,
          segmentBy,
          yAxis,
          dataFormat,
        };
      }
      return widget;
    });
    setWidgetArray(newArray);
    setShowAddComponentModal(false);
  };
  const handleAddDefaultComponent = (widgetType, widgetWidth, widgetHeight) => {
    let component =
      widgetType === "pie"
        ? {
            filters: [
              {
                attribute: "owner",
                operator: "is_in",
                value: [
                  {
                    label: `${props.authUser.name}`,
                    value: `${props.authUser.name}`,
                  },
                ],
              },
            ],
            h: 2.35,
            height: 2.35,
            i: "default-lead-source-pie",
            name: "Lead Sources (Contacts)",
            reportType: "contact",
            segmentBy: "source",
            type: "pie",
            w: 4,
            width: 4,
            x: 4,
            y: 3,
            yAxis: "",
          }
        : {
            i: widgetType,
            x: 0,
            y: 0,
            w: widgetWidth,
            h: widgetHeight,
            type: widgetType,
          };
    setWidgetArray([...widgetArray, component]);
    setShowAddComponentModal(false);
  };

  const handleDelete = (key) => {
    const tempArray = widgetArray?.filter((array) => array.i !== key);
    setWidgetArray(tempArray);
  };
  const submitHandler = () => {
    let url =
      dashboardMode === "edit" ? `/dashboard/${dashboardId}` : `/dashboard`;
    let method = dashboardMode === "edit" ? "PUT" : "POST";
    if (dashboardType === "team" && teamIds.length === 0) {
      setLoader(false);
      NotificationManager.error("Please select a team for teams dashboard");
      return false;
    }
    if (widgetArray.length === 0) {
      setLoader(false);
      NotificationManager.error("Please add at least one component");
      return false;
    }
    setLoader(true);
    axios({
      method,
      url,
      data: {
        name: dashboardName,
        ...(dashboardMode !== "edit" && { type: dashboardType }),
        settings: widgetArray,
        is_public: isPublicDashboard,
        team_ids:
          dashboardType !== "user" ? teamIds?.map((team) => team.value) : [],
        user_ids:
          dashboardType !== "team" ? userIds?.map((user) => user.value) : [],
      },
    })
      .then((res) => {
        NotificationManager.success("Dashboard Created.");
        setLoader(false);
        props.fetchAllDashboards();
        let newTabData = {
          type: "dashboard",
          id: dashboardMode === "edit" ? dashboardId : res.data.dashboard.id,
          name: dashboardName,
          settings: widgetArray,
          dashboardType,
          teamIds,
          userIds,
          ...(dashboardMode !== "edit" && { edited: true }),
        };
        setShowPropmpt(false);
        props.setActiveTabIndex(props.totalTabs + 1);
        props.setTab(newTabData);
        props.history.push("/active-tabs");
      })
      .catch((err) => {
        NotificationManager.error("Error creating dashboard");
        console.log("error", err);
        setLoader(false);
      });
  };
  console.log("widget check", widgetArray);
  return (
    <div className="body" ref={bodyRef}>
      <Prompt
        when={isPrompt()}
        message={() =>
          "Are you sure you want to leave this page? Any unsaved changes will be lost."
        }
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div className="controlsDashboard">
            <input
              value={dashboardName}
              onChange={(e) => setDashboardName(e.target.value)}
              id="dashboardName"
              className="floatLabel"
              style={{
                fontSize: "15px",
                borderRadius: "4px",
                padding: "8px",
                border: "1px solid #c5c5d3",
                paddingTop: "13px",
                paddingBottom: "5px",
                minWidth: "220px",
                marginRight: "10px",
              }}
            />
            <label
              for="dashboardName"
              className="activeLabel"
              style={{ fontSize: "12px" }}
            >
              Dashboard Name
            </label>
          </div>
          {dashboardMode !== "edit" &&
            props.authUser?.role === "SUPERADMIN" && (
              <div
                className="controlsDashboard"
                style={{ marginRight: "10px" }}
              >
                <select
                  value={dashboardType}
                  onChange={(e) => setDashboardType(e.target.value)}
                  id="dashboardType"
                  className="floatLabel"
                  style={{
                    fontSize: "15px",
                    borderRadius: "4px",
                    padding: "8px",
                    border: "1px solid #c5c5d3",
                    paddingTop: "13px",
                    paddingBottom: "5px",
                    marginRight: "10px",
                    height: "40px",
                  }}
                >
                  <option value="company">Company Dashboard</option>
                  <option value="team">Teams Dashboard</option>
                  <option value="user">Personal Dashboard</option>
                </select>
                <label
                  for="dashboardType"
                  className="activeLabel"
                  style={{ top: "-9px", fontSize: "12px" }}
                >
                  Dashboard Type
                </label>
              </div>
            )}
          {!isPublicDashboard &&
            (dashboardType === "team" || dashboardType === "company") && (
              <div
                className="controlsDashboard"
                style={{ marginRight: "5px", zIndex: "1002" }}
              >
                <div style={{ width: "300px" }} id="teamIds">
                  <Select
                    isMulti
                    value={teamIds}
                    onChange={(selected) => setTeamIds(selected)}
                    options={teams?.map((team) => {
                      return {
                        label: team.name,
                        value: team.id,
                      };
                    })}
                    styles={colourStyles}
                    placeholder="Add Team(s)"
                  />
                </div>
                <label
                  for="teamIds"
                  className="activeLabel"
                  style={{
                    top: "-16px",
                    fontSize: "13px",
                    padding: teamIds?.length > 1 ? "0px 10px" : "2px 10px",
                  }}
                >
                  Teams
                </label>
              </div>
            )}
          {!isPublicDashboard &&
            (dashboardType === "user" || dashboardType === "company") && (
              <div className="controlsDashboard" style={{ zIndex: "1002" }}>
                <div style={{ width: "300px" }} id="userIds">
                  <Select
                    isMulti
                    value={userIds}
                    onChange={(selected) => setUserIds(selected)}
                    options={users?.map((user) => {
                      return {
                        label: user.name,
                        value: user.id,
                      };
                    })}
                    styles={colourStyles}
                    placeholder="Add User(s)"
                  />
                </div>
                <label
                  for="userIds"
                  className="activeLabel"
                  style={{
                    top: "-16px",
                    fontSize: "13px",
                    padding: userIds?.length > 1 ? "0px 10px" : "2px 10px",
                  }}
                >
                  Users
                </label>
              </div>
            )}
          <label style={{ display: "inline-block", marginLeft: "5px" }}>
            <span className="general-heading">Private Dashboard &nbsp;</span>
            <label className="switch1" style={{ transform: "translateY(0px)" }}>
              <input
                type="checkbox"
                value={isPublicDashboard}
                checked={isPublicDashboard}
                onChange={() => setIsPublicDashboard(!isPublicDashboard)}
                style={{ width: "20px", display: "inline-block" }}
              />
              <span className="slider1 round1"></span>
            </label>
            <span className="general-heading"> &nbsp;Public Dashboard</span>
          </label>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            onClick={() => setShowAddComponentModal(true)}
            className="button-md"
            style={{
              marginRight: "10px",
              background: "#ffffff",
              color: "#4BC893",
              border: "1px solid #4BC893",
            }}
          >
            + Add Component
          </button>
          <button
            onClick={submitHandler}
            className="button-md"
            disabled={loader}
          >
            {loader ? "Saving" : "Save Dashboard"}
          </button>
        </div>
      </div>
      <AddComponentModal
        showModal={showAddComponentModal}
        handleCloseModal={() => setShowAddComponentModal(false)}
        submitHandler={handleAdd}
        modalHeight={88}
        modalTop={3}
        loading={false}
        widgetArray={widgetArray}
        handleAddDefaultComponent={handleAddDefaultComponent}
        isFreight={props.companyOnboarding?.industry === "Freight Brokerage"}
      />
      <CustomComponentModal
        showModal={showCustomComponentModal}
        handleCloseModal={() => setShowCustomComponentModal(false)}
        submitHandler={handleEdit}
        modalHeight={90}
        modalTop={3}
        loading={false}
        reportType={reportType}
        setReportType={setReportType}
        reportData={{
          ...reportData,
          type: reportData.reportType,
          viewDashboard: reportData.type,
        }}
        mode={"edit"}
      />
      <ResponsiveReactGridLayout
        onLayoutChange={handleModify}
        onResizeStop={handleStop}
        verticalCompact={true}
        layout={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        preventCollision={false}
        cols={{ lg: 12, md: 8, sm: 4, xs: 2, xxs: 2 }}
        autoSize={true}
        containerPadding={[0, 10]}
        useCSSTransforms={false}
        margin={{
          lg: [25, 25],
          md: [20, 20],
          sm: [20, 20],
          xs: [20, 20],
          xxs: [20, 20],
        }}
        resizeHandles={["se", "e", "s"]}
      >
        {widgetArray?.map((widget, index) => {
          return (
            <div
              className="reactGridItem"
              key={index}
              data-grid={{
                x: widget?.x,
                y: widget?.y,
                w: widget?.w,
                h: widget?.h,
                i: widget.i,
                minW: 3,
                maxW: Infinity,
                minH: widget?.type === "tasks" ? 4.7 : 2.35,
                maxH: Infinity,
                isDraggable: true,
                isResizable: true,
              }}
            >
              {(widget.type === "pie" ||
                widget.type === "line" ||
                widget.type === "bar" ||
                widget.type === "column" ||
                widget.type === "table" ||
                widget.type === "scorecard") &&
                widget.i !== "default-lead-source-pie" && (
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setReportData(widget);
                      setShowCustomComponentModal(true);
                      setReportType(widget.type);
                    }}
                    style={{
                      border: "none",
                      background: "transparent",
                      height: "12px",
                      cursor: "pointer",
                      outline: "none",
                      color: "#A7ABAA",
                      right: "35px",
                      fontSize: "14px",
                    }}
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                )}
              <button
                className="deleteButton"
                onClick={() => handleDelete(widget.i)}
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  outline: "none",
                  right: "10px",
                }}
              >
                <img src={closeIcon} alt="x" width="11px" />
              </button>
              <div>{renderComponent(widget)}</div>
            </div>
          );
        })}
      </ResponsiveReactGridLayout>
    </div>
  );
};

const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    totalTabs: state.tabsState.tabs.length,
    dashboardsState: state.dashboardsState,
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    fetchAllDashboards: (dashboardData) =>
      dispatch(fetchAllDashboards(dashboardData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(withRouter(DashboardCustomScreen));
