import axios from "axios";
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { NotificationManager } from "react-notifications";

const ZapierSettings = () => {
  const [isRoundRobin, setIsRoundRobin] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    axios({
      method: "",
      url: "",
    })
      .then((res) => {
        NotificationManager.success("Settings changed successfully.");
        setLoading(false);
      })
      .catch((err) => {
        NotificationManager.error("Error changing settings.");
        setLoading(false);
      });
  };
  return (
    <>
      <Sidebar />
      <div className="userProfile">
        <form>
          <h2 className="userProfile-heading-main">Zapier Settings</h2>
          <label style={{ fontSize: "14px" }}>Round Robin Approach</label>
          <input
            id="isRoundRobin"
            type="checkbox"
            name="isRoundRobin"
            style={{ width: "20px", display: "block" }}
            value={isRoundRobin}
            checked={isRoundRobin}
            onChange={() => setIsRoundRobin(!isRoundRobin)}
          />
          <button
            onClick={submitHandler}
            type="button"
            disabled={loading}
            className="button-md"
          >
            {loading ? "Confirming..." : "Confirm"}
          </button>
        </form>
      </div>
    </>
  );
};

export default ZapierSettings;
