import React from "react";
import { Link } from "react-router-dom";

function TeamsDetailTable(props) {
  return (
    <div style={{ margin: "0px 30px" }}>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2 style={{ marginBottom: "0px" }}>
            <Link to="/teams" style={{ color: "black" }}>
              Teams
            </Link>{" "}
            - {props.location.state.name}
          </h2>
          <p>{props.location.state.members.length} members on your team</p>
        </div>
      </div>
      <div>
        <table className="accountList__table" style={{ width: "93vw" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Team</th>
              <th>Phone</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {props.location.state.members?.map((member) => {
              const { name, role, id, formatted_mobile, email } = member;
              return (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{role}</td>
                  <td>{props.location.state.name}</td>
                  <td>{formatted_mobile}</td>
                  <td>{email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TeamsDetailTable;
