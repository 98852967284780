import React, { Component } from "react";
import "../sass/Grid.scss";
import "../sass/Body.scss";
import Tasks from "./Tasks";
import Targets from "./Targets";
import Funnel from "./Funnel";
import axios from "axios";
import { BASE_URL } from "../config";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";

// Redux stuff
import { connect } from "react-redux";
import {
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
  setTab,
  fetchAllDashboards,
} from "../redux";
import DropdownDashboard from "./Dropdown/DropdownDashboard";
import OnboardingInfoModal from "./OnboardingInfoModal";
import OnboardingVideoTutorial from "./Onboarding/VideoTutorials";
import WonLostReason from "./WonLostReason/WonLostReason";
import LeadFunnel from "./LeadFunnel/LeadFunnel";
import HottestFollowupDashboard from "./HottestFollowup/HottestFollowupDashboard";
import { NotificationManager } from "react-notifications";
// custom components
import DealsPieDashboard from "./DashboardCustom/ReportCards/Pages/Deals/DealsPieDashboard";
import ActivitiesPieDashboard from "./DashboardCustom/ReportCards/Pages/Activities/ActivitiesPieDashboard";
import AccountsPieDashboard from "./DashboardCustom/ReportCards/Pages/AccountsPieDashboard";
import CarriersPieDashboard from "./DashboardCustom/ReportCards/Pages/Carriers/CarriersPieDashboard";
import DealsLineDashboard from "./DashboardCustom/ReportCards/Pages/Deals/DealsLineDashboard";
import ActivitiesLineDashboard from "./DashboardCustom/ReportCards/Pages/Activities/ActivitiesLineDashboard";
import CarriersLineDashboard from "./DashboardCustom/ReportCards/Pages/Carriers/CarriersLineDashboard";
import AccountsLineDashboard from "./DashboardCustom/ReportCards/Pages/AccountsLineDashboard";
import DealsColumnDashboard from "./DashboardCustom/ReportCards/Pages/Deals/DealsColumnDashboard";
import ActivitiesColumnDashboard from "./DashboardCustom/ReportCards/Pages/Activities/ActivitiesColumnDashboard";
import CarriersColumnDashboard from "./DashboardCustom/ReportCards/Pages/Carriers/CarriersColumnDashboard";
import AccountsColumnDashboard from "./DashboardCustom/ReportCards/Pages/AccountsColumnDashboard";
import DealsScoreCardDashboard from "./DashboardCustom/ReportCards/Pages/Deals/DealsScoreCardDashboard";
import ActivitiesScoreCardDashboard from "./DashboardCustom/ReportCards/Pages/Activities/ActivitiesScoreCardDashboard";
import CarriersScoreCardDashboard from "./DashboardCustom/ReportCards/Pages/Carriers/CarriersScoreCardDashboard";
import AccountsScoreCardDashboard from "./DashboardCustom/ReportCards/Pages/AccountsScoreCardDashboard";
import ReportsDealDashboard from "./DashboardCustom/ReportCards/Pages/ReportsDealDashboard";
import ReportsActivityDashboard from "./DashboardCustom/ReportCards/Pages/ReportsActivityDashboard";
import ReportsCarrierDashboard from "./DashboardCustom/ReportCards/Pages/Carriers/ReportsCarrierDashboard";
import ReportsContactDashboard from "./DashboardCustom/ReportCards/Pages/ReportsContactDashboard";
import ReportsAccountDashboard from "./DashboardCustom/ReportCards/Pages/ReportsAccountDashboard";
import TasksColored from "./Tasks/TasksColored";
import DashboardLoader from "./Loaders/DashboardLoader";
import ActivityMetrics from "./DashboardCustom/ActivityMetrics/ActivityMetrics";
import UnassignedNext7Days from "./DashboardCustom/UnassignedLeads/UnassignedNext7Days";
import ActivitiesPeriodOverPeriodChart from "./DashboardCustom/ActivitiesPeriodOverPeriodChart/ActivitiesPeriodOverPeriodChart";
import PipelineMetrics from "./DashboardCustom/PipelineMetrics/PipelineMetrics";
import PipelineStageChanges from "./DashboardCustom/PipelineStageChanges/PipelineStageChanges";
import UserLeaderboard from "./DashboardCustom/UserLeaderboard/UserLeaderboard";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
class Dashboard extends Component {
  state = {
    showInfo: false,
    user_ids: this.props.authUser.id,
    userName: this.props.authUser.name,
    displayMenu: false,
    displayUserName: this.props.authUser.name,
    name: "",
    mobile: "",
    employeeSize: "",
    newStage: "",
    lostStage: "",
    wonStage: "",
    currency: "USD",
    selectedFunnel: "",
    country: "United States",
    dateFormat: "MM/DD/YYYY",
    industry: "Freight Brokerage",
    industryOther: "",
    infoModalLoader: false,
    allUsers: [],
    teamsName: [],
    dashboardLoader: false,
    defaultCheckCount: false,
    dashboardType: "user", // company || team || user
    widgetArray: [
      { i: "tasks", x: 0, y: 0, w: 4, h: 4.7, type: "tasks" },
      { i: "lead", x: 4, y: 0, w: 4, h: 2.35, type: "lead" },
      { i: "funnel", x: 8, y: 0, w: 4, h: 2.35, type: "funnel" },
      {
        filters: [
          {
            attribute: "owner",
            operator: "is_in",
            value: [
              {
                label: `${this.props.authUser.name}`,
                value: `${this.props.authUser.name}`,
              },
            ],
          },
        ],
        h: 2.35,
        height: 2.35,
        i: "default-lead-source-pie",
        name: "Lead Sources (Contacts)",
        reportType: "contact",
        segmentBy: "source",
        type: "pie",
        w: 4,
        width: 4,
        x: 4,
        y: 3,
        yAxis: "",
      },
      { i: "targets", x: 8, y: 3, w: 4, h: 2.35, type: "targets" },
    ],
    bodyRef: null,
  };
  onRefChange = (node) => {
    this.setState({ bodyRef: node });
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  handleOpenInfoModal = () => {
    this.setState({
      showInfo: true,
    });
  };
  handleCloseInfoModal = () => {
    this.setState({
      showInfo: false,
    });
  };
  handleUserId = (user_ids, userName) => {
    this.setState({
      user_ids,
      displayMenu: !this.state.displayMenu,
      displayUserName: userName,
    });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  getDefaultDashboard = () => {
    axios({
      method: "GET",
      url: `/dashboard/default`,
    }).then((res) => {
      this.setState({
        dashboardType: res.data.dashboard?.type,
        widgetArray: res.data.dashboard?.settings?.map((setting) => {
          if (setting.i === "default-lead-source-pie") {
            setting.filters = [
              {
                attribute: "owner",
                operator: "is_in",
                value: [
                  {
                    label: `${this.props.authUser.name}`,
                    value: `${this.props.authUser.name}`,
                  },
                ],
              },
            ];
          }
          return setting;
        }),
      });
    });
  };
  redirectToAccount = (e) => {
    if (e.metaKey || e.ctrlKey) {
      this.props.setTab({
        type: "accountList",
        id: `${this.props.authUser.id}AccountsList`,
        name: "Accounts",
        blank: true,
        userId: this.props.authUser.id,
        userName: this.props.authUser.name,
      });
    } else {
      this.props.setActiveTabIndex(this.props.totalTabs + 1);
      this.props.setTab({
        type: "accountList",
        id: `${this.props.authUser.id}AccountsList`,
        name: "Accounts",
        userId: this.props.authUser.id,
        userName: this.props.authUser.name,
      });
      this.props.history.push("/active-tabs");
    }
  };
  redirectToContact = (e) => {
    if (e.metaKey || e.ctrlKey) {
      this.props.setTab({
        type: "contactList",
        id: `${this.props.authUser.id}ContactsList`,
        name: "Contacts",
        blank: true,
        userId: this.props.authUser.id,
        userName: this.props.authUser.name,
      });
    } else {
      this.props.setActiveTabIndex(this.props.totalTabs + 1);
      this.props.setTab({
        type: "contactList",
        id: `${this.props.authUser.id}ContactsList`,
        name: "Contacts",
        userId: this.props.authUser.id,
        userName: this.props.authUser.name,
      });
      this.props.history.push("/active-tabs");
    }
  };
  getCompanyDashboard = (type, settings) => {
    this.setState({
      dashboardLoader: true,
    });
    axios({
      method: "GET",
      url: `/admin/users`,
    }).then((response) => {
      const allUsers = response.data.users?.map((user) => user.id)?.join(",");
      this.setState({
        allUsers: response.data.users,
        user_ids: allUsers,
        displayUserName: "Company Dashboard",
        dashboardLoader: false,
        dashboardType: type,
        widgetArray: settings?.map((setting) => {
          if (setting.i === "default-lead-source-pie") {
            setting.filters = [
              {
                attribute: "owner",
                operator: "is_in",
                value: [
                  {
                    label: `${this.props.authUser.name}`,
                    value: `${this.props.authUser.name}`,
                  },
                ],
              },
            ];
          }
          return setting;
        }),
      });
    });
  };
  getTeamsDashboard = (type, settings, teamId) => {
    this.setState({
      dashboardLoader: true,
    });
    axios({
      method: "GET",
      url: `/dashboard/teams`,
    }).then((res) => {
      const selectedTeam = teamId
        ? res.data.teams?.find(
            (team) => team.team_id.toString() === teamId.toString()
          )
        : res.data.teams[0];
      const membersArray = selectedTeam.members
        .map((member) => member.id)
        .join(",");
      this.setState({
        teamsName: res.data.teams,
        user_ids: membersArray,
        displayUserName: selectedTeam.team_name,
        dashboardType: type,
        dashboardLoader: false,
        widgetArray: settings?.map((setting) => {
          if (setting.i === "default-lead-source-pie") {
            setting.filters = [
              {
                attribute: "owner",
                operator: "is_in",
                value: [
                  {
                    label: `${this.props.authUser.name}`,
                    value: `${this.props.authUser.name}`,
                  },
                ],
              },
            ];
          }
          return setting;
        }),
      });
    });
  };
  getUserDashboard = (type, settings) => {
    this.setState({
      dashboardType: type,
      widgetArray: settings?.map((setting) => {
        if (setting.i === "default-lead-source-pie") {
          setting.filters = [
            {
              attribute: "owner",
              operator: "is_in",
              value: [
                {
                  label: `${this.props.authUser.name}`,
                  value: `${this.props.authUser.name}`,
                },
              ],
            },
          ];
        }
        return setting;
      }),
    });
  };
  componentDidMount() {
    if (this.props.settings) {
      if (this.props.dashboardsState?.dashboards.length === 1) {
        this.getUserDashboard("user", this.props.settings);
      } else if (this.props.type === "company") {
        this.getCompanyDashboard(this.props.type, this.props.settings);
      } else if (this.props.type === "team") {
        this.getTeamsDashboard(
          this.props.type,
          this.props.settings,
          this.props.teamId
        );
      } else {
        this.getUserDashboard(this.props.type, this.props.settings);
      }
    } else if (this.props.dashboardsState?.dashboards) {
      const defaultDashboard = this.props.dashboardsState?.dashboards?.find(
        (dash) => dash.user_default_dashboard === true
      );
      const defaultCompanyDashboard =
        this.props.dashboardsState?.dashboards?.find(
          (dash) => dash.company_default_dashboard === true
        );
      const myDashboard = defaultDashboard || defaultCompanyDashboard;
      if (this.props.dashboardsState?.dashboards.length === 1) {
        this.getUserDashboard("user", myDashboard?.settings);
      } else if (myDashboard?.type === "company") {
        this.getCompanyDashboard(myDashboard?.type, myDashboard?.settings);
      } else if (myDashboard?.type === "team") {
        this.getTeamsDashboard(
          myDashboard?.type,
          myDashboard?.settings,
          myDashboard?.teamId
        );
      } else {
        this.getUserDashboard(myDashboard?.type, myDashboard?.settings);
      }
    } else {
      this.getDefaultDashboard();
    }
    if (
      this.props.companyOnboarding?.onboarded === false &&
      this.props.authUser.role === "SUPERADMIN"
    ) {
      this.handleOpenInfoModal();
    }
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => funnel.is_default === true && funnel)
        .filter((funnel) => funnel !== false);
      const wonStage = selectedFunnel[0].stages.find(
        (stage) => stage.type === "won"
      );
      const lostStage = selectedFunnel[0].stages.find(
        (stage) => stage.type === "lost"
      );
      const newStage = selectedFunnel[0].stages.find(
        (stage) => stage.type === "new"
      );
      this.setState({
        newStage: newStage.id,
        lostStage: lostStage.id,
        wonStage: wonStage.id,
        selectedFunnel: selectedFunnel[0].id,
      });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.companyOnboarding !== this.props.companyOnboarding) {
      if (
        this.props.companyOnboarding?.onboarded === false &&
        this.props.authUser.role === "SUPERADMIN"
      ) {
        this.handleOpenInfoModal();
      }
    }
    if (!this.state.widgetArray && !this.state.defaultCheckCount) {
      this.setState({
        defaultCheckCount: true,
      });
      console.log("ye hua tha dekho");
      this.getDefaultDashboard();
    }
    if (
      this.props.dashboardsState?.dashboards !==
        prevProps.dashboardsState?.dashboards &&
      this.props.activeTabIndex === 0
    ) {
      const defaultDashboard = this.props.dashboardsState?.dashboards?.find(
        (dash) => dash.user_default_dashboard === true
      );
      const defaultCompanyDashboard =
        this.props.dashboardsState?.dashboards?.find(
          (dash) => dash.company_default_dashboard === true
        );
      const myDashboard = defaultDashboard || defaultCompanyDashboard;
      if (this.props.dashboardsState?.dashboards.length === 1) {
        this.getUserDashboard("user", myDashboard?.settings);
      } else if (myDashboard?.type === "company") {
        this.getCompanyDashboard(myDashboard?.type, myDashboard?.settings);
      } else if (myDashboard?.type === "team") {
        this.getTeamsDashboard(
          myDashboard?.type,
          myDashboard?.settings,
          myDashboard?.teamId
        );
      } else {
        this.getUserDashboard(myDashboard?.type, myDashboard?.settings);
      }
    }
  }
  setLeadSourceChartType = (type) => {
    this.setState({
      widgetArray: this.state.widgetArray?.map((setting) => {
        if (setting.i === "default-lead-source-pie") {
          setting.reportType = type;
        }
        return setting;
      }),
    });
  };
  infoSubmitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (
      !data.name ||
      !data.employeeSize ||
      !data.mobile ||
      !data.currency ||
      !data.industry
    ) {
      this.setState({ errorMessage: true });
      return false;
    }
    if (data.industry === "others" && !data.industryOther) {
      this.setState({ errorMessage: true });
      return false;
    }
    this.setState({
      infoModalLoader: true,
    });
    axios({
      method: "PUT",
      url: `${BASE_URL}/company`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        name: this.state.name,
        formatted_mobile: this.state.mobile,
        number_of_employee: this.state.employeeSize,
        currency: this.state.currency,
        country: this.state.country ? this.state.country : "United States",
        date_format: this.state.dateFormat
          ? this.state.dateFormat
          : "MM/DD/YYYY",
        industry:
          this.state.industry === "others"
            ? this.state.industryOther
            : this.state.industry,
      },
    })
      .then((res) => {
        this.setState({
          infoModalLoader: false,
        });
        this.props.companyOnboardingSet({
          ...this.props.companyOnboarding,
          onboarded: true,
          name: this.state.name,
          mobile: this.state.mobile,
          number_of_employee: this.state.employeeSize,
          currency: this.state.currency,
          country: this.state.country ? this.state.country : "United States",
          date_format: this.state.dateFormat
            ? this.state.dateFormat
            : "MM/DD/YYYY",
          industry:
            this.state.industry === "others"
              ? this.state.industryOther
              : this.state.industry,
        });
        localStorage.setItem(
          "companyOnboarding",
          JSON.stringify({
            ...this.props.companyOnboarding,
            onboarded: true,
            name: this.state.name,
            mobile: this.state.mobile,
            number_of_employee: this.state.employeeSize,
            currency: this.state.currency,
            country: this.state.country ? this.state.country : "United States",
            date_format: this.state.dateFormat
              ? this.state.dateFormat
              : "MM/DD/YYYY",
            industry:
              this.state.industry === "others"
                ? this.state.industryOther
                : this.state.industry,
          })
        );
        this.props.fetchAllDashboards();
        this.handleCloseInfoModal();
        this.clickLeadChild();
        this.clickActivityMeticsChild();
        this.clickFunnelChild();
        this.clickTargetsChild();
        this.clickTasksChild();
        this.clickDefaultLeadSourcesPieChild();
        this.onboardedButton.click();
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
          NotificationManager.error("Error saving company info");
        }
        this.setState({
          infoModalLoader: false,
        });
        this.onboardedButton.click();
      });
  };
  setSelectedFunnel = (selectedFunnel) => {
    this.setState({
      selectedFunnel,
    });
  };
  chartRedirectToReport = (item, e) => {
    let newTabData = {
      type: "report",
      id: item.id,
      name: item.name,
    };
    if (item.i === "default-lead-source-pie") {
      item.reportType === "contact"
        ? this.redirectToContact(e)
        : this.redirectToAccount(e);
    } else {
      if (e.metaKey || e.ctrlKey) {
        this.props.setTab({ ...newTabData, blank: true });
      } else {
        this.props.setActiveTabIndex(this.props.totalTabs + 1);
        this.props.setTab(newTabData);
        this.props.history.push("/active-tabs");
      }
    }
  };
  renderComponent = (item) => {
    const user_ids = this.state.user_ids.toString()?.split(",");
    const selectedUsers = this.props.allUsers?.filter((user) =>
      user_ids.includes(user.id.toString())
    );
    if (item.type === "tasks") {
      return (
        <TasksColored
          userId={this.state.user_ids}
          userName={this.state.displayUserName}
          setClick={(click) => (this.clickTasksChild = click)}
        />
      );
    } else if (item.type === "tasks_old") {
      return (
        <Tasks
          userId={this.state.user_ids}
          userName={this.state.displayUserName}
        />
      );
    } else if (item.type === "lead") {
      return (
        <LeadFunnel
          userId={this.state.user_ids}
          wonStage={this.state.wonStage}
          lostStage={this.state.lostStage}
          userName={this.state.displayUserName}
          setClick={(click) => (this.clickLeadChild = click)}
        />
      );
    } else if (item.type === "funnel") {
      return (
        <Funnel
          userId={this.state.user_ids}
          wonStage={this.state.wonStage}
          lostStage={this.state.lostStage}
          userName={this.state.displayUserName}
          setSelectedFunnel={this.setSelectedFunnel}
          setClick={(click) => (this.clickFunnelChild = click)}
        />
      );
    } else if (item.type === "targets") {
      return (
        <Targets
          minValue={0}
          maxValue={50}
          userId={this.state.user_ids}
          targetPermissions={this.props.authUser.permissions}
          newStage={this.state.newStage}
          wonStage={this.state.wonStage}
          userName={this.state.displayUserName}
          setClick={(click) => (this.clickTargetsChild = click)}
        />
      );
    } else if (item.type === "followup") {
      return (
        <HottestFollowupDashboard
          userId={this.state.user_ids}
          userName={this.state.displayUserName}
        />
      );
    } else if (item.type === "activities-metrics") {
      return (
        <ActivityMetrics
          userId={this.state.user_ids}
          userName={this.state.displayUserName}
          setClick={(click) => (this.clickActivityMeticsChild = click)}
        />
      );
    } else if (item.type === "pipeline-metrics") {
      return (
        <PipelineMetrics
          userId={this.state.user_ids}
          userName={this.state.displayUserName}
          setClick={(click) => (this.clickActivityMeticsChild = click)}
        />
      );
    } else if (
      item.type === "activities-leaderboard" ||
      item.type === "stage-change-leaderboard"
    ) {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <UserLeaderboard
          userId={this.state.user_ids}
          userName={this.state.displayUserName}
          setClick={(click) => (this.clickActivityMeticsChild = click)}
          maxHeight={item.height === 2.35 ? "275px" : "690px"}
          maxWidth={width - 20}
          type={
            item.type === "activities-leaderboard"
              ? "activities"
              : "stage_changes"
          }
        />
      );
    } else if (item.type === "pipeline-stage-changes") {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <PipelineStageChanges
          userId={this.state.user_ids}
          userName={this.state.displayUserName}
          setClick={(click) => (this.clickActivityMeticsChild = click)}
          maxHeight={"250px"}
          maxWidth={width - 20}
        />
      );
    } else if (item.type === "activities-period-over-period") {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <ActivitiesPeriodOverPeriodChart
          userId={this.state.user_ids}
          userName={this.state.displayUserName}
          setClick={(click) => (this.clickActivityMeticsChild = click)}
          reportWidth={width}
        />
      );
    } else if (item.type === "reasons") {
      return (
        <WonLostReason
          userId={this.state.user_ids}
          wonStage={this.state.wonStage}
          lostStage={this.state.lostStage}
          userName={this.state.displayUserName}
          selectedFunnel={this.state.selectedFunnel}
        />
      );
    } else if (item.type === "unassignedNext7Days") {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <div className="card">
          <UnassignedNext7Days
            userId={this.state.user_ids}
            userName={this.props.authUser.name}
            maxHeight={"250px"}
            maxWidth={width - 20}
          />
        </div>
      );
    } else if (item.type === "pie") {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <div className="card" style={{ padding: "0" }}>
          {item.reportType === "opportunities" ? (
            <DealsPieDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              dataFormat={item.dataFormat}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesPieDashboard
              filter_rules={item.filters}
              segmentBy={
                item.segmentBy === "type" ? "category" : item.segmentBy
              }
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              dataFormat={item.dataFormat}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersPieDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              dataFormat={item.dataFormat}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : (
            <AccountsPieDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
              isDefaultChart={item.i === "default-lead-source-pie"}
              reportId={item.id}
              dataFormat={item.dataFormat}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              setLeadSourceChartType={this.setLeadSourceChartType}
              setClick={(click) =>
                (this.clickDefaultLeadSourcesPieChild = click)
              }
            />
          )}
        </div>
      );
    } else if (item.type === "line") {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <div className="card" style={{ padding: "0" }}>
          {item.reportType === "opportunities" ? (
            <DealsLineDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              yAxis={item.yAxis}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesLineDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersLineDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : (
            <AccountsLineDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          )}
        </div>
      );
    } else if (item.type === "bar") {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <div className="card" style={{ padding: "0" }}>
          {item.reportType === "opportunities" ? (
            <DealsColumnDashboard
              filter_rules={item.filters}
              chartType="bar"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              yAxis={item.yAxis}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesColumnDashboard
              filter_rules={item.filters}
              chartType="bar"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersColumnDashboard
              filter_rules={item.filters}
              chartType="bar"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : (
            <AccountsColumnDashboard
              filter_rules={item.filters}
              chartType="bar"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          )}
        </div>
      );
    } else if (item.type === "column") {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <div className="card" style={{ padding: "0" }}>
          {item.reportType === "opportunities" ? (
            <DealsColumnDashboard
              filter_rules={item.filters}
              chartType="column"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              yAxis={item.yAxis}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesColumnDashboard
              filter_rules={item.filters}
              chartType="column"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersColumnDashboard
              filter_rules={item.filters}
              chartType="column"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : (
            <AccountsColumnDashboard
              filter_rules={item.filters}
              chartType="column"
              segmentBy={item.segmentBy}
              reportType={item.reportType}
              reportName={item.name}
              reportWidth={width}
              reportHeight={item.height}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          )}
        </div>
      );
    } else if (item.type === "scorecard") {
      let width =
        this.state.bodyRef &&
        (item.width * this.state.bodyRef?.clientWidth) / 12;
      return (
        <div className="card" style={{ padding: "0" }}>
          {item.reportType === "opportunities" ? (
            <DealsScoreCardDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportName={item.name}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
              currency={this.props.companyOnboarding?.currency}
              reportWidth={width}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
            />
          ) : item.reportType === "activities" ? (
            <ActivitiesScoreCardDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportName={item.name}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
              currency={this.props.companyOnboarding?.currency}
              reportWidth={width}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
            />
          ) : item.reportType === "carriers" ? (
            <CarriersScoreCardDashboard
              filter_rules={item.filters}
              segmentBy={item.segmentBy}
              reportName={item.name}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
              currency={this.props.companyOnboarding?.currency}
              reportWidth={width}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
            />
          ) : (
            <AccountsScoreCardDashboard
              filter_rules={item.filters}
              reportType={item.reportType}
              reportName={item.name}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
              currency={this.props.companyOnboarding?.currency}
              reportWidth={width}
              reportId={item.id}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
            />
          )}
        </div>
      );
    } else if (item.type === "table") {
      let width =
        this.state.bodyRef &&
        (item.width * (this.state.bodyRef?.clientWidth - 75)) / 12;
      return (
        <div className="card" style={{ padding: "0" }}>
          {item.reportType === "opportunities" ? (
            <ReportsDealDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "activities" ? (
            <ReportsActivityDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "contacts" ? (
            <ReportsContactDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : item.reportType === "carriers" ? (
            <ReportsCarrierDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          ) : (
            <ReportsAccountDashboard
              reportName={item.name}
              reportId={item.id}
              maxHeight={"250px"}
              maxWidth={width - 20}
              selectedUsers={selectedUsers}
              userId={this.state.user_ids}
              chartRedirectToReport={(e) => this.chartRedirectToReport(item, e)}
            />
          )}
        </div>
      );
    }
  };
  render() {
    return (
      <>
        <button
          style={{ display: "none" }}
          ref={(ref) => (this.onboardedButton = ref)}
          onClick={() => {
            this.clickLeadChild();
            this.clickActivityMeticsChild();
            this.clickFunnelChild();
            this.clickTargetsChild();
            this.clickTasksChild();
            this.clickDefaultLeadSourcesPieChild();
          }}
        >
          click me
        </button>
        {this.props.authUser.tour_completed === false ? (
          <OnboardingVideoTutorial />
        ) : this.props.dashboardsState?.loading ||
          this.state.dashboardLoader ? (
          <div className="body">
            <div className="row" style={{ paddingTop: "35px" }}>
              <div className="col-1-of-3">
                <div className="card" style={{ height: "76vh" }}>
                  <DashboardLoader />
                  <DashboardLoader />
                  <DashboardLoader />
                </div>
              </div>
              <div className="col-1-of-3">
                <div className="funnel">
                  <div
                    className="card"
                    style={{ height: "35vh", marginBottom: "26px" }}
                  >
                    <DashboardLoader />
                  </div>
                  <div className="card" style={{ height: "35vh" }}>
                    <DashboardLoader />
                  </div>
                </div>
              </div>
              <div className="col-1-of-3">
                <div className="funnel" style={{ borderRight: "none" }}>
                  <div
                    className="card"
                    style={{ height: "35vh", marginBottom: "26px" }}
                  >
                    <DashboardLoader />
                  </div>
                  <div className="card" style={{ height: "35vh" }}>
                    <DashboardLoader />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="body" ref={this.onRefChange}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "20px",
              }}
            >
              <DropdownDashboard
                handleUserId={this.handleUserId}
                user_ids={this.state.user_ids}
                userName={this.state.userName}
                userId={this.props.authUser.id}
                displayMenu={this.state.displayMenu}
                showDropdownMenu={this.showDropdownMenu}
                displayUserName={this.state.displayUserName}
                userRole={this.props.authUser.role}
              />
            </div>
            <ResponsiveReactGridLayout
              // onLayoutChange={handleModify}
              verticalCompact={true}
              // layout={layouts}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              preventCollision={false}
              cols={{ lg: 12, md: 8, sm: 4, xs: 2, xxs: 2 }}
              autoSize={true}
              containerPadding={[0, 10]}
              margin={{
                lg: [25, 25],
                md: [20, 20],
                sm: [20, 20],
                xs: [20, 20],
                xxs: [20, 20],
              }}
              resizeHandles={["se", "e", "s"]}
            >
              {this.state.widgetArray?.map((widget, index) => {
                return (
                  <div
                    className="reactGridItem"
                    key={index}
                    data-grid={{
                      x: widget?.x,
                      y: widget?.y,
                      w: widget?.w,
                      h: widget?.h,
                      i: widget.i,
                      minW: 3,
                      maxW: Infinity,
                      minH: widget?.i === "tasks" ? 4.7 : 2.35,
                      maxH: Infinity,
                      isDraggable: false,
                      isResizable: false,
                    }}
                  >
                    <div>{this.renderComponent(widget)}</div>
                  </div>
                );
              })}
            </ResponsiveReactGridLayout>
            <OnboardingInfoModal
              showOpportunityModal={this.state.showInfo}
              setShowOpportunityModal={this.handleOpenInfoModal}
              handleCloseModal={this.handleCloseInfoModal}
              submitHandler={this.infoSubmitHandler}
              changeHandler={this.changeHandler}
              name={this.state.name}
              mobile={this.state.mobile}
              employeeSize={this.state.employeeSize}
              currency={this.state.currency}
              country={this.state.country}
              dateFormat={this.state.dateFormat}
              errorMessage={this.state.errorMessage}
              industry={this.state.industry}
              industryOther={this.state.industryOther}
              loader={this.state.infoModalLoader}
            />
          </div>
        )}
      </>
    );
  }
}

const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    totalTabs: state.tabsState.tabs.length,
    activeTabIndex: state.tabsState.activeTabIndex,
    dashboardsState: state.dashboardsState,
    allUsers: state.allUsers.users,
  };
};
const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    fetchAllDashboards: (dashboardData) =>
      dispatch(fetchAllDashboards(dashboardData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(Dashboard);
