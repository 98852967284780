export const chartColors = [
  "#12556F",
  "#167599",
  "#0096C8",
  "#00EFB1",
  "#00B294",
];
export const pieChartColors = [
  "#3C7D6C",
  "#5CBCA4",
  "#4A9482",
  "#AAAF8B",
  "#D2D2D2",
];
