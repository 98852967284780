import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardLoader from "../../../../Loaders/DashboardLoader";
import PieGraphDashboard from "../../Charts/PieGraphDashboard";

function CarriersPieDashboard({
  filter_rules,
  segmentBy,
  reportName,
  reportWidth,
  chartRedirectToReport,
  isDefaultChart,
  setLeadSourceChartType,
  setClick,
  reportId,
  dataFormat,
  userId,
  selectedUsers,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState("");
  const [repName, setRepName] = useState(reportName);
  const getChart = (filter_rules) => {
    let url = `/charts/carrier-chart`;
    axios({
      method: "POST",
      url,
      data: {
        chart: {
          type: "pie",
          segment_by: segmentBy,
        },
        filter_rules,
      },
    })
      .then((res) => {
        setLoading(false);
        setData(res.data.carriers);
        setRefresh(segmentBy);
        userId && setRefresh(segmentBy + userId);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getData = () => {
    setLoading(true);
    let newValue = [];
    if (reportId) {
      axios({
        method: "GET",
        url: `/reports/${reportId}`,
      }).then((res) => {
        setRepName(res.data.report.name);
        newValue = res.data.report.filters?.map((rule) => {
          let { attribute, operator, value } = rule;
          if (userId && selectedUsers.length !== 0 && operator === "only_me") {
            operator = "is_in";
            value = selectedUsers.map((user) => user.name);
          }
          return {
            attribute,
            operator,
            value,
          };
        });
        getChart(newValue);
      });
    } else {
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        if (userId && selectedUsers.length !== 0 && operator === "only_me") {
          operator = "is_in";
          value = selectedUsers.map((user) => user.name);
        }
        return {
          attribute,
          operator,
          value,
        };
      });
      getChart(newValue);
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_rules, segmentBy, userId]);
  useEffect(() => {
    setClick(getData);
    setRefresh("new loading data");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setClick]);
  return (
    <div>
      {loading ? (
        <DashboardLoader />
      ) : (
        <PieGraphDashboard
          data={data}
          segmentBy={segmentBy}
          type={"Carriers"}
          refresh={refresh}
          reportName={repName}
          reportWidth={reportWidth}
          chartRedirectToReport={chartRedirectToReport}
          isDefaultChart={isDefaultChart}
          setLeadSourceChartType={setLeadSourceChartType}
          setRefresh={setRefresh}
          dataFormat={dataFormat}
          carrierPieChart={true}
        />
      )}
    </div>
  );
}

export default CarriersPieDashboard;
