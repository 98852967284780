import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import "../../sass/WebForm.scss";
import "../../sass/CustomCheckbox.scss";
import FormInstructions from "./FormInstructions";
import WebFormPreview from "./WebFormPreview";
import ReactTooltip from "react-tooltip";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/webformdrag.svg";

// Redux stuff
import { connect } from "react-redux";
import { authUserSet } from "../../redux";

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{
        marginRight: "10px",
        cursor: "grab",
        width: "14px",
        transform: "translateY(3px)",
      }}
    />
  </span>
));

const SortableItem = sortableElement(
  ({
    field,
    customFieldsChangeHandler,
    customFields,
    requiredFieldChangeHandler,
  }) => (
    <div
      style={{
        width: "70%",
        padding: "4px",
        borderBottom: "1px solid #e5e5e5",
      }}
    >
      <DragHandle />
      <label class="container-checkbox">
        {field.label}
        <input
          id={`customFields${field.id}${field.label}`}
          type="checkbox"
          style={
            field.name === "name"
              ? {
                  width: "20px",
                  display: "inline-block",
                  cursor: "not-allowed",
                }
              : {
                  width: "20px",
                  display: "inline-block",
                  cursor: "pointer",
                }
          }
          value={field.id}
          onChange={(e) => customFieldsChangeHandler(e)}
          checked={customFields?.includes(field.id.toString())}
          disabled={field.id === "name"}
        />
        <span class="checkmark"></span>
      </label>
      <label class="container-checkbox" style={{ float: "right" }}>
        <input
          id={`customFields${field.id}${field.label}`}
          type="checkbox"
          style={
            field.id === "name" || !customFields?.includes(field.id.toString())
              ? {
                  width: "20px",
                  display: "inline-block",
                  cursor: "not-allowed",
                }
              : {
                  width: "20px",
                  display: "inline-block",
                  cursor: "pointer",
                }
          }
          value={field.id}
          onChange={(e) => requiredFieldChangeHandler(e)}
          checked={field.required}
          disabled={
            field.id === "name" || !customFields?.includes(field.id.toString())
          }
        />
        <span class="checkmark"></span>
      </label>
    </div>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class ContactForm extends Component {
  state = {
    view: false,
    showInstructions: false,
    heading: "Get in touch with us",
    primaryColor: "#000000",
    theme: "light",
    allFields: [],
    customFields: ["name"],
    fieldsPreview: [],
    isLoading: false,
    userId: this.props.authUser.id,
    fieldsArray: [],
  };
  componentDidMount() {
    const fieldPreview = this.props.allContactCustomFields?.map((group) => {
      const { fields, meta } = group;
      return meta === null && fields?.map((field) => field);
    });
    const fieldsPreview = [].concat.apply([], fieldPreview);
    const fieldsPreviewPosition = fieldsPreview?.map((field, index) => {
      return {
        ...field,
        position:
          fieldsPreview.length +
          fieldsPreview.length +
          fieldsPreview.length +
          fieldsPreview.length +
          index,
      };
    });
    const defaultColumns = [
      {
        choices: [],
        field_class: "contact",
        id: "name",
        label: "Name",
        name: "name",
        type: "text",
        required: true,
        selected: true,
        parent_id: null,
        placeholder: null,
        position: fieldsPreview.length + fieldsPreview.length + 1,
        visible: null,
      },
      {
        choices: [],
        field_class: "contact",
        id: "phone",
        label: "Phone",
        name: "phone",
        type: "text",
        required: false,
        selected: false,
        parent_id: null,
        placeholder: null,
        position: fieldsPreview.length + fieldsPreview.length + 2,
        visible: null,
      },
      {
        choices: [],
        field_class: "contact",
        id: "email",
        label: "Email",
        name: "email",
        type: "text",
        required: false,
        selected: false,
        parent_id: null,
        placeholder: null,
        position: fieldsPreview.length + fieldsPreview.length + 3,
        visible: null,
      },
      {
        choices: [],
        field_class: "contact",
        id: "role",
        label: "Role",
        name: "role",
        type: "text",
        required: false,
        selected: false,
        parent_id: null,
        placeholder: null,
        position: fieldsPreview.length + fieldsPreview.length + 4,
        visible: null,
      },
      {
        choices: [
          { id: "call", position: 1, value: "Call" },
          { id: "email", position: 2, value: "Email" },
          { id: "text", position: 3, value: "Text" },
        ],
        field_class: "contact",
        id: "preferred_communication",
        label: "Preferred Communication",
        name: "preferred_communication",
        type: "dropdown",
        required: false,
        selected: false,
        parent_id: null,
        placeholder: null,
        position: fieldsPreview.length + fieldsPreview.length + 5,
        visible: null,
      },
      {
        choices: [],
        field_class: "contact",
        id: "date_of_birth",
        label: "Birthday",
        name: "date_of_birth",
        type: "date",
        required: false,
        selected: false,
        parent_id: null,
        placeholder: null,
        position: fieldsPreview.length + fieldsPreview.length + 6,
        visible: null,
      },
      {
        choices: [],
        field_class: "contact",
        id: "activity_description",
        label: "Your Message",
        name: "activity_description",
        type: "paragraph",
        required: false,
        selected: false,
        parent_id: null,
        placeholder: null,
        position: fieldsPreview.length + fieldsPreview.length + 7,
        visible: null,
      },
    ];
    const fieldsArray = defaultColumns.concat(fieldsPreviewPosition);
    this.setState(
      {
        allFields: this.props.allContactCustomFields,
        fieldsPreview,
        fieldsArray: fieldsArray.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        ),
      },
      this.getFormData
    );
    axios({
      method: "GET",
      url: `/admin/users?account_id=${this.props.authUser.id}`,
    })
      .then((response) => {
        this.setState({
          allUsers: response.data.users?.slice()?.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // Ignore case for comparison
            const nameB = b.name.toUpperCase(); // Ignore case for comparison

            if (nameA < nameB) {
              return -1; // Negative value: a should come before b
            }
            if (nameA > nameB) {
              return 1; // Positive value: b should come before a
            }
            return 0; // Return 0 for elements considered equal
          }),
          // userId: this.props.authUser.id,
        });
      })
      .catch((error) => console.log("error", error));
  }
  getFormData = () => {
    const webformId = this.props.match.params.id;
    axios({
      method: "GET",
      url: `/company/contact-form`,
    }).then((res) => {
      const selectedForm = res.data.web_forms?.find(
        (form) => form.id?.toString() === webformId?.toString()
      );
      const fieldsArray =
        selectedForm?.fields.length === 0
          ? this.state.fieldsArray
          : this.state.fieldsArray?.map((field) => {
              selectedForm?.fields.map((f) => {
                if (f.name.toString() === field.id.toString()) {
                  field.required = f.is_required === 1 ? true : false;
                  field.selected = true;
                  field.position = parseInt(f.position);
                }
                return f;
              });
              return field;
            });
      this.setState({
        heading: selectedForm.heading,
        primaryColor: selectedForm.primary_color,
        userId: selectedForm.user_id,
        theme: selectedForm.theme,
        customFields:
          selectedForm.fields.length === 0
            ? ["name"]
            : selectedForm.fields?.map((f) => f.name),
        view: selectedForm.is_active,
        fieldsArray: fieldsArray.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        ),
      });
    });
  };
  submitHandler = () => {
    // /company/contact-form/:web_form_id
    let url = `/company/contact-form`;
    let method = "POST";
    if (this.props.match.params.mode === "edit") {
      url += `/${this.props.match.params.id}`;
      method = "PUT";
    }
    this.setState({ isLoading: true });
    const { heading, theme, primaryColor, view, userId } = this.state;
    const fieldsArray = this.state.fieldsArray
      .map((field) => field.selected === true && field)
      .filter((f) => f !== false);
    axios({
      method,
      url,
      data: {
        heading,
        theme,
        fields: fieldsArray.map((field) => {
          const { id, required } = field;
          return {
            name: id,
            is_required: required,
            position: fieldsArray.indexOf(field) + 1,
          };
        }),
        primary_color: primaryColor,
        is_active: view,
        user_id: userId,
      },
    })
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.success("Web form saved");
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Error saving web form");
      });
  };
  requiredFieldChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => {
        const customFieldsArray = prevState.fieldsArray?.find(
          (field) => field.id.toString() === value
        );
        let fieldsArray = [
          ...prevState.fieldsArray
            .map((f) => {
              if (f.id.toString() === customFieldsArray.id.toString()) {
                f.required = true;
              }
              return f;
            })
            .filter((com) => com !== ""),
        ];
        return { fieldsArray };
      });
    } else {
      this.setState((prevState) => {
        const customFieldsArray = prevState.fieldsArray?.find(
          (field) => field.id.toString() === value && field
        );
        let fieldsArray = [
          ...prevState.fieldsArray
            .map((f) => {
              if (f.id.toString() === customFieldsArray.id.toString()) {
                f.required = false;
              }
              return f;
            })
            .filter((com) => com !== ""),
        ];
        return { fieldsArray };
      });
    }
  };
  customFieldsChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => {
        const customFieldsArray = prevState.fieldsArray?.find(
          (field) => field.id.toString() === value && field
        );
        let customFields = [
          ...prevState.customFields.map((f) => f).filter((com) => com !== ""),
          value,
        ];
        let fieldsArray = [
          ...prevState.fieldsArray
            .map((f) => {
              if (f.id.toString() === customFieldsArray.id.toString()) {
                f.selected = true;
              }
              return f;
            })
            .filter((com) => com !== ""),
        ];
        return {
          customFields,
          fieldsArray,
        };
      });
    } else {
      this.setState((prevState) => {
        const customFieldsArray = prevState.fieldsArray?.find(
          (field) => field.id.toString() === value && field
        );
        let fieldsArray = [
          ...prevState.fieldsArray
            .map((f) => {
              if (f.id.toString() === customFieldsArray.id.toString()) {
                f.selected = false;
              }
              return f;
            })
            .filter((com) => com !== ""),
        ];
        return {
          customFields: prevState.customFields.filter((pc) => pc !== value),
          fieldsArray,
        };
      });
    }
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    const { fieldsArray } = this.state;
    return (
      <div style={{ margin: "auto 30px" }}>
        <div
          style={{
            borderBottom: "1px solid #E3E3E3",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <h2 style={{ marginBottom: "0px" }}>Web Forms</h2>
            <p>Embed the form on your website</p>
          </div>
          <div
            style={{ display: "inline-block", float: "right", margin: "20px" }}
          >
            <label className="switch">
              <input
                type="checkbox"
                value={this.state.view}
                checked={this.state.view}
                id="checkboxView"
                onChange={() =>
                  this.setState({
                    view: !this.state.view,
                  })
                }
              />
              <span className="slider round"></span>
            </label>
            <label
              className="general-heading"
              htmlFor="checkboxView"
              style={
                this.state.view
                  ? {
                      display: "inline-block",
                      color: "#62ca9d",
                      cursor: "pointer",
                    }
                  : {
                      display: "inline-block",
                      color: "grey",
                      cursor: "pointer",
                    }
              }
            >
              {" "}
              &nbsp;Active
            </label>
            <button
              disabled={this.state.isLoading}
              type="button"
              onClick={this.submitHandler}
              style={{
                background: "#62ca9d",
                color: "white",
                border: "none",
                borderRadius: "4px",
                padding: "6px 10px",
                marginLeft: "20px",
                cursor: "pointer",
              }}
            >
              {this.state.isLoading ? "Saving" : "Save"}
            </button>
          </div>
        </div>
        <div className="webform-options">
          <span
            className={
              this.state.showInstructions ? "" : "webform-options-selected"
            }
            onClick={() => this.setState({ showInstructions: false })}
          >
            Form Editor
          </span>{" "}
          |{" "}
          <span
            className={
              !this.state.showInstructions ? "" : "webform-options-selected"
            }
            onClick={() => this.setState({ showInstructions: true })}
          >
            Share and Embed
          </span>
        </div>
        <div className="webform">
          <ReactTooltip />
          <div>
            {this.state.showInstructions ? (
              <FormInstructions
                companyId={this.props.authUser.company_id}
                userId={this.state.userId}
                webformId={this.props.match.params.id}
              />
            ) : (
              <div>
                <label style={{ fontWeight: "500", marginBottom: "5px" }}>
                  Form Owner{" "}
                  <span
                    data-tip="Can edit the form and will receive all submissions."
                    style={{
                      fontSize: "10px",
                      fontWeight: "400",
                      color: "#62CA9D",
                      padding: "0.2px 4.8px",
                      borderRadius: "50%",
                      border: "1px solid #62CA9D",
                    }}
                    data-multiline={true}
                  >
                    i
                  </span>
                </label>
                <select
                  name="userId"
                  value={this.state.userId}
                  onChange={this.changeHandler}
                  style={{
                    color: "#495057",
                    background: "#fff",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    padding: "0.375rem 0.5rem",
                    width: "50%",
                  }}
                >
                  {this.state.allUsers?.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
                <label style={{ fontWeight: "500", marginTop: "10px" }}>
                  Heading
                </label>
                <input
                  name="heading"
                  value={this.state.heading}
                  onChange={this.changeHandler}
                  style={{
                    color: "#495057",
                    background: "#fff",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    padding: "0.375rem 0.5rem",
                    width: "47.5%",
                  }}
                />
                <label style={{ fontWeight: "500", marginTop: "10px" }}>
                  Primary Color
                </label>
                <input
                  name="primaryColor"
                  type="color"
                  value={this.state.primaryColor}
                  onChange={this.changeHandler}
                />
                <label style={{ fontWeight: "500", marginTop: "10px" }}>
                  Theme
                </label>
                <input
                  type="radio"
                  style={{ width: "20px", display: "inline-block" }}
                  name="theme"
                  value="light"
                  onChange={this.changeHandler}
                  checked={this.state.theme === "light"}
                  id="ContactFormLightTheme"
                />
                <label
                  style={{ display: "inline-block" }}
                  htmlFor="ContactFormLightTheme"
                >
                  Light
                </label>
                <input
                  type="radio"
                  style={{ width: "20px", display: "inline-block" }}
                  name="theme"
                  value="dark"
                  onChange={this.changeHandler}
                  checked={this.state.theme === "dark"}
                  id="ContactFormDarkTheme"
                />
                <label
                  style={{ display: "inline-block" }}
                  htmlFor="ContactFormDarkTheme"
                >
                  Dark
                </label>
                <label
                  style={{
                    fontWeight: "500",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Form Fields{" "}
                  <span style={{ float: "right", marginRight: "30%" }}>
                    Required
                  </span>
                </label>
                <SortableContainer
                  useDragHandle
                  onSortEnd={({ oldIndex, newIndex }) => {
                    this.setState(({ fieldsArray }) => ({
                      fieldsArray: arrayMove(fieldsArray, oldIndex, newIndex),
                    }));
                  }}
                >
                  {fieldsArray.map((field, index) => (
                    <SortableItem
                      key={field.key}
                      field={field}
                      index={index}
                      customFieldsChangeHandler={this.customFieldsChangeHandler}
                      requiredFieldChangeHandler={
                        this.requiredFieldChangeHandler
                      }
                      customFields={this.state.customFields}
                    />
                  ))}
                </SortableContainer>
              </div>
            )}
          </div>
          <div>
            <WebFormPreview
              heading={this.state.heading}
              theme={this.state.theme}
              primaryColor={this.state.primaryColor}
              fields={this.state.fieldsArray}
            />
          </div>
        </div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    allContactCustomFields: state.allContactCustomFields.fields,
  };
};
const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(withRouter(ContactForm));
