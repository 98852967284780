import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../sass/Reports.scss";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  fetchAllDashboards,
} from "../../redux";
import ReusableModal from "../Reusables/ReusableModal";
import { NotificationManager } from "react-notifications";

const DashboardListPage = (props) => {
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [defaultLoader, setDefaultLoader] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.fetchAllDashboards(), []);
  const deleteDashboard = () => {
    setLoader(true);
    axios({
      method: "DELETE",
      url: `/dashboard/${selectedDashboard}`,
    })
      .then((res) => {
        setLoader(false);
        setShowDeleteModal(false);
        NotificationManager.success("Dashboard deleted successfully.");
        props.fetchAllDashboards();
      })
      .catch((err) => {
        setLoader(false);
        NotificationManager.error("Error deleting dashboard.");
      });
  };
  const markDashboardDefault = (id, type) => {
    setDefaultLoader(true);
    axios({
      method: "PATCH",
      url: `/dashboard/${id}`,
      data: {
        type,
      },
    })
      .then((res) => {
        NotificationManager.success("Dashboard marked default");
        props.fetchAllDashboards();
        setDefaultLoader(false);
      })
      .catch((err) => {
        NotificationManager.error("Error marking dashboard default");
        setDefaultLoader(false);
      });
  };
  return (
    <div className="reports">
      <ReusableModal
        showModal={showDeleteModal}
        handleCloseModal={() => setShowDeleteModal(false)}
        submitHandler={deleteDashboard}
        modalHeading={`Delete Dashboard`}
        modalPara={`Are you sure you want to delete this dashboard`}
        modalHeight={`30`}
        modalTop={`25`}
        loading={loader}
      />
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>Dashboards</h2>
          <p> </p>
        </div>
        <div>
          <Link
            to="/dashboard-customization/create"
            className="button-md"
            style={{
              textDecoration: "none",
              marginTop: "20px",
              display: "block",
              paddingTop: "8px",
              height: "24px",
            }}
          >
            + Create Dashboard
          </Link>
        </div>
      </div>
      {props.dashboardsState.loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <table
          className="accountList__tableReport"
          style={{ marginTop: "20px", width: "93vw" }}
        >
          <thead>
            <tr>
              <th style={{ width: "8px" }}> </th>
              <th>Dashboard Name</th>
              <th>Dashboard Type</th>
              {props.authUser.role === "SUPERADMIN" && (
                <th>Company Default Dashboard</th>
              )}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {props.dashboardsState.dashboards?.map((cam) => {
              const {
                id,
                name,
                type,
                settings,
                company_default_dashboard,
                user_default_dashboard,
                created_by,
                team_ids,
                is_public,
                user_ids,
              } = cam;
              let newTabData = {
                type: "dashboard",
                id,
                name,
                settings,
                dashboardType: type,
                teamIds: team_ids,
                userIds: user_ids,
              };
              return (
                <tr key={id}>
                  <td style={{ width: "8px" }}>
                    <button
                      type="button"
                      onClick={() => markDashboardDefault(id, "user")}
                      disabled={user_default_dashboard}
                      style={
                        user_default_dashboard
                          ? {
                              cursor: "not-allowed",
                              marginRight: "10px",
                              padding: "0",
                              border: "none",
                              background: "transparent",
                              color: "gold",
                            }
                          : {
                              cursor: "pointer",
                              marginRight: "10px",
                              padding: "0",
                              border: "none",
                              background: "transparent",
                              color: "grey",
                            }
                      }
                    >
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </button>
                  </td>
                  <td
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        props.setTab({ ...newTabData, blank: true });
                      } else {
                        props.setActiveTabIndex(props.totalTabs + 1);
                        props.setTab(newTabData);
                        props.history.push("/active-tabs");
                      }
                    }}
                    style={{ cursor: "pointer", color: "#41BE89" }}
                  >
                    {name}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{type}</td>
                  {props.authUser.role === "SUPERADMIN" && (
                    <td>
                      {type !== "company" ? (
                        "-"
                      ) : company_default_dashboard ? (
                        "Default Dashboard"
                      ) : (
                        <button
                          onClick={() => markDashboardDefault(id, "company")}
                          className="button-sm"
                          disabled={defaultLoader}
                        >
                          {defaultLoader
                            ? "Marking ..."
                            : "Company default dashboard"}
                        </button>
                      )}
                    </td>
                  )}
                  <td>
                    {(props.authUser.role === "SUPERADMIN" ||
                      (is_public &&
                        props.authUser.permissions.includes(
                          "crm:all:customizer"
                        )) ||
                      (!is_public && created_by === props.authUser.id)) && (
                      <Link
                        to={{
                          pathname: `/dashboard-customization/edit/${id}`,
                          state: {
                            name,
                            settings,
                            type,
                            is_public,
                            user_ids,
                            team_ids,
                          },
                        }}
                        className="button-sm"
                        style={{
                          padding: "4.5px 10px",
                          marginRight: "10px",
                          fontSize: "14px",
                        }}
                      >
                        Edit
                      </Link>
                    )}
                    {!(
                      props.dashboardsState.dashboards.length === 1 ||
                      company_default_dashboard ||
                      user_default_dashboard ||
                      created_by !== props.authUser.id
                    ) && (
                      <button
                        onClick={() => {
                          setSelectedDashboard(id);
                          setShowDeleteModal(true);
                        }}
                        className="button-sm"
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    dashboardsState: state.dashboardsState,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    fetchAllDashboards: (dashboardData) =>
      dispatch(fetchAllDashboards(dashboardData)),
  };
};

export default connect(MSP, MDP)(DashboardListPage);
