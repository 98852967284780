export const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#62ca9d",
    margin: "5px auto",
    height: "35px",
    boxShadow: "none",
    ":active": {
      ...styles[":active"],
      border: "2px solid #62ca9d",
      outline: "#62ca9d",
    },
    ":hover": {
      ...styles[":hover"],
      border: "1px solid #62ca9d",
    },
    ":focus": {
      ...styles[":hover"],
      border: "1px solid #62ca9d",
    },
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      height: "37px",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};
