import React, { Component } from "react";
import "../../sass/CustomerIntro.scss";
import { withRouter } from "react-router-dom";
import Edit from "../../images/EditIcon.svg";
import AccountsIcon from "../../images/AccountsIcon.svg";
import { NotificationContainer } from "react-notifications";
import "react-datepicker/dist/react-datepicker.css";
import SendEmailIcon from "../../images/AccountEmailIcon.svg";
import ArrowIcon from "../../images/Arrow.svg";
import ContactsNavIcon from "../../images/AccountContactIcon.svg";
import AccountActivityIcon from "../../images/AccountActivityIcon.svg";
import AccountTaskIcon from "../../images/AccountTaskIcon.svg";
import DealsNavIcon from "../../images/DealsSettingsIcon.svg";
import DealsIcon from "../../images/DealsIcon.svg";

import axios from "axios";

// Redux stuff
import { connect } from "react-redux";
import { authUserSet } from "../../redux";
import { compose } from "recompose";
import DropdownAccount from "./../Dropdown/DropdownAccount";
import SendEmailModal from "./../SendEmail/SendEmailModal";
import CreateContact from "./../Contacts/CreateContact";
import Collapsible from "react-collapsible";
import ReactTooltip from "react-tooltip";
import TaskCreateModal from "./../TaskCreateModal";
import TaskTourDealCreate from "./../Onboarding/TaskTourDealCreate";
import IntegrationRedirectModal from "./../Integration/IntegrationRedirectModal";
import DummyOppModal from "./DummyOppModal";
import DummyExitModal from "./DummyExitModal";

class DummyIntro extends Component {
  state = {
    userData: {
      custom_fields: {},
    },
    customFieldsForAPI: {},
    opportunities: [],
    allFields: [],
    showOpportunityModal: false,
    editing: false,
    name: null,
    email: null,
    mobile: null,
    address: null,
    source: null,
    website: null,
    zip: null,
    state: null,
    city: null,
    description: null,
    preferred_communication: [],
    customFields: {},
    status: null,
    disqualify_reason: null,
    account_owner: null,
    showEmailModal: false,
    showContactModal: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    allUsers: [],
    account_owner_id: null,
    tasks: [],
    users: [],
    tourComplete: false,
    showRedirectModal: false,
    integration: false,
    showExitModal: false,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
    this.setState({ tourComplete: true });
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
    this.setState({
      showExitModal: true,
    });
  };

  handleOpenEmailModal = () => {
    this.setState({
      showEmailModal: true,
    });
  };

  handleCloseEmailModal = () => {
    this.setState({
      showEmailModal: false,
    });
  };
  handleOpenExitModal = () => {
    this.setState({
      showExitModal: true,
    });
  };
  handleCloseExitModal = () => {
    this.setState({
      showExitModal: false,
    });
  };
  handleOpenRedirectModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  handleCloseRedirectModal = () => {
    this.setState({
      showRedirectModal: false,
    });
  };
  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };

  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenTaskModal = () => {
    this.props.setShowTaskModal(true);
  };

  handleCloseTaskModal = () => {
    this.props.setShowTaskModal(false);
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  getUsers = () => {
    axios({
      method: "GET",
      url: `/admin/users`,
    }).then((response) => {
      this.setState({ users: response.data.users });
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getUsers();
    axios({
      method: "GET",
      url: `/admin/users`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((response) => {
        this.setState({ allUsers: response.data.users });
      })
      .catch((error) => console.log("error", error));
  }
  editStart = () => {
    this.setState({
      editing: !this.state.editing,
    });
  };
  editCancel = () => {
    this.setState({
      editing: false,
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { name, stage, date } = this.props.dealData;
    return (
      <div className="customerInfo">
        <div className="customerInfo__Intro">
          <div className="customerInfo-icon">
            <img
              src={AccountsIcon}
              alt=""
              width="17px"
              style={{ transform: "translateY(9px)" }}
            />
          </div>
          <NotificationContainer />
          <div className="customerInfo-info">
            <h2 className="c-type">
              Account{" "}
              <i
                className="fa fa-circle"
                style={{ fontSize: "4px", transform: "translateY(-3px)" }}
              ></i>{" "}
              <span
                style={
                  this.state?.status === "disqualified"
                    ? { textTransform: "capitalize", color: "#F36363" }
                    : { textTransform: "capitalize" }
                }
              >
                Qualified
              </span>{" "}
              <span style={{ color: "#F36363" }}>
                {this.state?.status === "disqualified"
                  ? "due to " + this.state?.disqualify_reason
                  : null}
              </span>
              <DropdownAccount
                customerName={this.props.customerName}
                customerId={this.props.customerId}
                status={this.state?.status}
                tabIndex={this.props.tabIndex}
                deleteTab={this.props.deleteTab}
              />
            </h2>
            <h1 className="c-name">
              Salesdash
              <button
                onClick={this.editStart}
                className="account-editButton-pencil"
              >
                <img src={Edit} alt="Edit" height="13" />
              </button>
            </h1>
          </div>
          <TaskCreateModal
            accountId={this.props.customerId}
            showTaskModal={this.props.showTaskModal}
            setShowTaskModal={this.handleOpenTaskModal}
            handleCloseModal={this.handleCloseTaskModal}
            customerName={this.props.customerName}
            users={this.state.users}
            existingUser={this.props.authUser.id}
            keyValue="CustomerIntro"
          />
          <DummyExitModal
            showOpportunityModal={this.state.showExitModal}
            setShowOpportunityModal={this.handleOpenExitModal}
            handleCloseModal={this.handleCloseExitModal}
          />
          <ReactTooltip multiline={true} />
          <div>
            {ReactTooltip.rebuild()}
            <label
              htmlFor="AccountActivityForm"
              data-tip="Add Activity"
              style={{
                width: "36px",
                display: "inline-block",
                background: "#F4F6F5",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                color: "white",
                cursor: "pointer",
                boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                height: "36px",
                transform: "translateY(-6px)",
                marginRight: "15px",
              }}
            >
              <img
                src={AccountActivityIcon}
                alt=""
                style={{ width: "16px", transform: "translate(10px, 10px)" }}
              />
            </label>
            <button
              type="button"
              onClick={this.handleOpenTaskModal}
              data-tip="Add Task"
              style={{
                padding: "8px 10px",
                background: "#F4F6F5",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                color: "white",
                cursor: "pointer",
                boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                marginRight: "15px",
                height: "36px",
              }}
            >
              <img src={AccountTaskIcon} alt="" style={{ width: "16px" }} />
            </button>
            <button
              type="button"
              className="tour-deal-create"
              onClick={this.handleOpenModal}
              data-tip="Add Deal"
              style={{
                padding: "8px 10px",
                borderRadius: "8px",
                background: "#F4F6F5",
                border: "none",
                outline: "none",
                color: "white",
                cursor: "pointer",
                boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                marginRight: "15px",
                height: "36px",
              }}
            >
              <img src={DealsNavIcon} alt="" style={{ width: "16px" }} />
            </button>
            <button
              type="button"
              onClick={this.handleOpenContactModal}
              data-tip="Add Contact"
              style={{
                padding: "8px 10px",
                background: "#F4F6F5",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                color: "white",
                cursor: "pointer",
                boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                height: "36px",
                marginRight: "15px",
              }}
            >
              <img src={ContactsNavIcon} alt="" style={{ width: "16px" }} />
            </button>
            <button
              type="button"
              onClick={this.handleOpenRedirectModal}
              data-tip="Send Email"
              style={{
                padding: "8px 10px",
                background: "#F4F6F5",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                color: "white",
                cursor: "pointer",
                boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)",
                height: "36px",
                transform: "translateY(-2px)",
              }}
            >
              <img src={SendEmailIcon} alt="" style={{ width: "16px" }} />
            </button>
          </div>
        </div>
        <TaskTourDealCreate
          tourComplete={this.state.tourComplete}
          restart={this.props.restart}
        />
        <SendEmailModal
          showOpportunityModal={this.state.showEmailModal}
          setShowOpportunityModal={this.handleOpenEmailModal}
          handleCloseModal={this.handleCloseEmailModal}
          email={this.state.email}
          accountId={this.props.customerId}
        />
        <IntegrationRedirectModal
          showOpportunityModal={this.state.showRedirectModal}
          setShowOpportunityModal={this.handleOpenRedirectModal}
          handleCloseModal={this.handleCloseRedirectModal}
        />
        <div className="customerInfo__Info">
          <Collapsible
            className="collapseHeaderAccount"
            classParentString="collapseHeaderAccount"
            open={true}
            overflowWhenOpen="initial"
            trigger={
              <div>
                Account Info
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    padding: "3px 10px",
                    border: "none",
                    borderRadius: "5px",
                    color: "white",
                    outline: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "30%",
                    right: "2%",
                  }}
                >
                  <img src={ArrowIcon} alt="" height="10px" />
                </button>
              </div>
            }
            triggerWhenOpen="Account Info"
            triggerClassName="c-title-main"
            triggerOpenedClassName="c-title-main"
          >
            <h5 className="c-title-act">Account Owner</h5>
            <p className="c-desc">Salesdash</p>
            <h5 className="c-title-act">Email</h5>
            <p className="c-desc">info@salesdashcrm.com</p>
            <h5 className="c-title-act">Phone</h5>
            <p className="c-desc">+16151234567</p>
            <h5 className="c-title-act">Address</h5>
            <p className="c-desc">41 Peabody St</p>
            <h5 className="c-title-act">Zip</h5>
            <p className="c-desc">37210</p>
            <h5 className="c-title-act">City</h5>
            <p className="c-desc">Nashville</p>
            <h5 className="c-title-act">State</h5>
            <p className="c-desc">TN</p>
            <h5 className="c-title-act">Source</h5>
            <p className="c-desc"></p>
            <h5 className="c-title-act">Website</h5>
            <p className="c-desc">
              <a
                href="https://salesdashcrm.com"
                target="_blank"
                rel="noopener noreferrer"
                className="c-desc"
                style={{ cursor: "pointer", color: "#41BE89" }}
              >
                https://salesdashcrm.com
              </a>
            </p>
            <h5 className="c-title-act">Preferred Communication</h5>
            <div className="customerInfo__AddInfo-icon c-desc"></div>
            <h5 className="c-title-act">Description</h5>
            <p
              className="c-desc"
              style={{
                fontSize: "14px",
                lineHeight: "16px",
                marginBottom: "15px",
                whiteSpace: "pre-wrap",
              }}
            ></p>
          </Collapsible>
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              open={true}
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Deals 0
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div style={{ display: "flex" }}>
                  <h4 className="c-title-main-1">
                    Deals {this.props.showDeal ? "1" : "0"}
                  </h4>
                  <button
                    onClick={this.handleOpenModal}
                    className=" c-opportunityAdd"
                    style={{
                      width: "100px",
                      height: "23px",
                      border: "none",
                      outline: "none",
                      padding: "1px 13px",
                      background: "#61C99D",
                      borderRadius: "15px",
                    }}
                  >
                    + Add Deal
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              <DummyOppModal
                customerId={this.props.customerId}
                showOpportunityModal={this.props.showOpportunityModal}
                setShowOpportunityModal={this.handleOpenModal}
                handleCloseModal={this.handleCloseModal}
                customerName="Salesdash"
                setShowDeal={this.props.setShowDeal}
                setDealData={this.props.setDealData}
              />
              {this.props.showDeal && (
                <div
                  className="schedule__items-1"
                  style={{ cursor: "pointer" }}
                  data-tip={name}
                >
                  <ReactTooltip />
                  <span className="com-2">
                    <img
                      src={DealsIcon}
                      alt=""
                      style={{ transform: "translate(0.5px,0px)" }}
                    />
                  </span>
                  <div className="schedule__heading">
                    <span className="name">
                      {name && name.length > 28
                        ? name.substring(0, 27) + "..."
                        : name}
                    </span>
                    {stage === "Won" ? (
                      <span
                        className="opportunity-type"
                        style={{ color: "#28A429" }}
                      >
                        <i
                          className="fa fa-circle"
                          aria-hidden="true"
                          style={{
                            fontSize: "6px",
                            transform: "translateY(-2px)",
                            marginRight: "6px",
                          }}
                        ></i>
                        {stage}
                      </span>
                    ) : (
                      <span
                        className="opportunity-type"
                        style={{ color: "#FFB202" }}
                      >
                        <i
                          className="fa fa-circle"
                          aria-hidden="true"
                          style={{
                            fontSize: "6px",
                            transform: "translateY(-2px)",
                            marginRight: "6px",
                          }}
                        ></i>
                        {stage}
                      </span>
                    )}
                  </div>
                  <div className="schedule__desc">{`Close Date: ${date}`}</div>
                </div>
              )}
            </Collapsible>
          </div>
        </div>
        {/*Contact Portion */}

        {this.state.editing === false && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              open={true}
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Contacts 0
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div style={{ display: "flex" }}>
                  <h4 className="c-title-main-1">Contacts 0</h4>
                  <button
                    onClick={this.handleOpenContactModal}
                    className=" c-opportunityAdd"
                    style={{
                      height: "23px",
                      border: "none",
                      outline: "none",
                      padding: "1px 13px",
                      background: "#61C99D",
                      borderRadius: "15px",
                    }}
                  >
                    + Add Contact
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              <CreateContact
                showOpportunityModal={this.state.showContactModal}
                setShowOpportunityModal={this.handleOpenContactModal}
                handleCloseModal={this.handleCloseContactModal}
                accountId={this.props.customerId}
              />
            </Collapsible>
          </div>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(DummyIntro);
