import React, { Component } from "react";
import axios from "axios";
import { CountryCodeList } from "../2FA/CountryCodeList";
import NotificationManager from "react-notifications/lib/NotificationManager";
import "../../sass/ProfilePage.scss";
import ProfilePicCrop from "./ProfilePicCrop";
import "react-image-crop/dist/ReactCrop.css";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { colourStyles } from "../../constants/selectStyles";
// Redux
import { connect } from "react-redux";
import { authUserSet } from "../../redux";

class ProfilePage extends Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    country_code: "",
    errorMessage: false,
    isLoading: false,
    showPicModal: false,
    profileImage: null,
    dailyDigest: false,
    weeklyDigest: false,
    ringCentralIntegrated: false,
    apolloConnected: false,
  };
  handleOpenPicModal = () => {
    this.setState({
      showPicModal: true,
    });
  };
  handleClosePicModal = () => {
    this.setState({
      showPicModal: false,
    });
  };
  getUser = () => {
    axios({
      method: "GET",
      url: `/users`,
    }).then((res) => {
      this.setState({
        name: res.data.user.name,
        email: res.data.user.email,
        mobile: res.data.user.formatted_mobile,
        country_code: `+${res.data.user.country_code}`,
        profileImage: res.data.user.profile_img,
        dailyDigest: res.data.user.daily_digest,
        weeklyDigest: res.data.user.weekly_digest,
        ringCentralIntegrated: res.data.user.is_ringcentral_enabled,
        apolloConnected: res.data.user.is_apollo_connected,
      });
      !res.data.user.country_code && this.getGeoInfo();
    });
  };
  getGeoInfo = () => {
    axios
      .get("https://extreme-ip-lookup.com/json/")
      .then((response) => {
        let data = response.data;
        const countryPhoneCode = CountryCodeList.find(
          (country) => data.countryCode === country.code
        );
        this.setState({
          countryName: data.country,
          country_code: countryPhoneCode.dial_code,
          countryPhoneCode: countryPhoneCode.dial_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount() {
    this.getUser();
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.name === "" || this.state.name === null) {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    if (this.state.mobile === "" || this.state.mobile === null) {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    const { name, country_code, mobile } = this.state;
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/users`,
      data: {
        name,
        formatted_mobile: mobile.toString(),
        country_code:
          country_code === null || country_code === ""
            ? 1
            : country_code.substring(1),
        daily_digest: this.state.dailyDigest,
        weekly_digest: this.state.weeklyDigest,
        is_ringcentral_enabled: this.state.ringCentralIntegrated,
        is_apollo_connected: this.state.apolloConnected,
      },
    })
      .then((res) => {
        NotificationManager.success("Profile Edited successfully");
        this.props.authUserSet({
          ...this.props.authUser,
          name: this.state.name,
        });
        localStorage.setItem(
          "authUser",
          JSON.stringify({
            ...this.props.authUser,
            name: this.state.name,
          })
        );
        this.props.history.push("/");
      })
      .catch((err) => {
        NotificationManager.error("Error editing profile");
        this.setState({
          isLoading: false,
        });
      });
  };
  render() {
    return (
      <>
        <Sidebar />
        <div className="userProfile">
          <ProfilePicCrop
            showOpportunityModal={this.state.showPicModal}
            setShowOpportunityModal={this.handleOpenPicModal}
            handleCloseModal={this.handleClosePicModal}
            getUser={this.getUser}
          />
          <h2 className="userProfile-heading-main">User Profile</h2>
          <div className="userProfile-profile">
            <h3 className="userProfile-heading-sub">Profile Picture</h3>
            <div className="userProfile-profile-flex">
              {this.state.profileImage ? (
                <img
                  src={this.state.profileImage}
                  alt=""
                  width="160px"
                  height="160px"
                />
              ) : (
                <div className="userProfile-profile-substitute">
                  <div>
                    {this.props.authUser?.name
                      ?.split(" ")
                      .map((a) => a.charAt(0))
                      .join("")}
                  </div>
                </div>
              )}
              <button
                className="button-md"
                type="button"
                onClick={this.handleOpenPicModal}
              >
                {this.state.profileImage ? "Change" : "Upload"}
              </button>
            </div>
          </div>
          <form>
            <label>Name</label>
            <input
              name="name"
              value={this.state.name}
              onChange={this.changeHandler}
              type="text"
            />
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              (this.state.name === "" || this.state.name === null)
                ? "Please enter name"
                : null}
            </div>
            <label>Email</label>
            <input
              name="email"
              value={this.state.email}
              onChange={this.changeHandler}
              type="email"
              disabled
            />
            <label>Phone</label>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  marginRight: "5%",
                  width: "70%",
                  transform: "translateY(-5.5px)",
                }}
              >
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  styles={colourStyles}
                  value={{
                    value: this.state.country_code,
                    label: this.state.country_code,
                  }}
                  onChange={(selected) =>
                    this.setState({ country_code: selected.value })
                  }
                  isClearable={false}
                  isSearchable={true}
                  name="country_code"
                  options={CountryCodeList?.map((currency) => ({
                    value: currency.dial_code,
                    label: `${currency.dial_code} ${currency.code}`,
                  }))}
                />
              </div>
              <input
                name="mobile"
                value={this.state.mobile}
                onChange={this.changeHandler}
              />
            </div>
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              (this.state.mobile === "" || this.state.mobile === null)
                ? "Please enter mobile"
                : null}
            </div>
            <label>Salesdash Email Digest</label>
            <div>
              <label style={{ display: "inline-block", marginRight: "30px" }}>
                <input
                  name="dailyDigest"
                  value={this.state.dailyDigest}
                  onChange={() =>
                    this.setState({ dailyDigest: !this.state.dailyDigest })
                  }
                  checked={this.state.dailyDigest}
                  type="checkbox"
                  style={{ display: "inline-block", width: "20px" }}
                />{" "}
                Daily
              </label>
              <label style={{ display: "inline-block" }}>
                <input
                  name="weeklyDigest"
                  value={this.state.weeklyDigest}
                  onChange={() =>
                    this.setState({ weeklyDigest: !this.state.weeklyDigest })
                  }
                  checked={this.state.weeklyDigest}
                  type="checkbox"
                  style={{ display: "inline-block", width: "20px" }}
                />{" "}
                Weekly
              </label>
            </div>
            <button
              disabled={this.state.isLoading}
              onClick={this.submitHandler}
              type="button"
              className="button-md"
            >
              {this.state.isLoading ? "Saving" : "Save Changes"}
            </button>
          </form>
        </div>
      </>
    );
  }
}

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(ProfilePage);
