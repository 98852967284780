import React, { Component } from "react";
import "../sass/Funnel.scss";
import axios from "axios";
import FunnelThree from "./Funnel/FunnelThree";
import FunnelFour from "./Funnel/FunnelFour";
import FunnelFive from "./Funnel/FunnelFive";
import FunnelSix from "./Funnel/FunnelSix";
import FunnelSeven from "./Funnel/FunnelSeven";
import FunnelEight from "./Funnel/FunnelEight";
import FunnelNine from "./Funnel/FunnelNine";
import FunnelTen from "./Funnel/FunnelTen";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";
import DropdownFunnel from "./Dropdown/DropdownFunnel";
import { NotificationManager } from "react-notifications";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../redux";
import DashboardLoader from "./Loaders/DashboardLoader";

class Funnel extends Component {
  state = {
    funnel_total_days: localStorage.getItem("funnel_total_days") ?? "all_days",
    sales_funnel: [],
    funnelLoader: false,
    reasonsLoader: false,
    allFunnels: [],
    selectedFunnel: {},
    allStagesInFunnel: [],
  };
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  salesFunnelHandler = (funnelId, e) => {
    let date = e !== undefined ? e.target.value : this.state.funnel_total_days;
    this.setState({ funnelLoader: true });
    let url = `/dashboard/sales-funnel?total_days=${date}&user_ids=${this.props.userId}`;
    if (funnelId) {
      url += `&funnel_id=${funnelId}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          sales_funnel: response.data.sales_funnel.filter(
            (data) =>
              data.name !== "Won" &&
              data.name !== "Lost" &&
              data.name !== "Long-term"
          ),
          funnelLoader: false,
          allStagesInFunnel: response.data.sales_funnel?.filter(
            (data) =>
              data.name === "Won" ||
              data.name === "Lost" ||
              data.name === "Long-term"
          ),
        });
      })
      .catch((error) => {
        this.setState({ funnelLoader: false });
      });
  };

  getAllFunnels = (id) => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(id) && funnel;
        })
        .filter((funnel) => funnel !== false);
      this.salesFunnelHandler(selectedFunnel[0].id);
      this.props.setSelectedFunnel(selectedFunnel[0].id);
      this.setState({
        allFunnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages.map((stage) => {
          stage.destroy = false;
          return stage;
        }),
      });
    });
  };

  getAllFunnelsInitializer = () => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => funnel.is_default === true && funnel)
        .filter((funnel) => funnel !== false);
      this.props.setSelectedFunnel(selectedFunnel[0].id);
      this.setState({
        allFunnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages.map((stage) => {
          stage.destroy = false;
          return stage;
        }),
      });
    });
  };
  resetLoader = () => {
    this.salesFunnelHandler();
    this.getAllFunnelsInitializer();
  };

  componentDidMount() {
    this.salesFunnelHandler();
    this.getAllFunnelsInitializer();
    this.props.setClick(this.resetLoader);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      this.salesFunnelHandler();
    }
  }
  setPrimaryFunnel = () => {
    axios({
      method: "PATCH",
      url: `/users/funnel`,
      data: {
        funnel_id: this.state.selectedFunnel.id,
      },
    }).then(() => {
      this.getAllFunnelsInitializer();
      NotificationManager.success("Primary Funnel selected");
    });
  };

  showFunnel = () => {
    if (this.state.sales_funnel.length === 0) {
      return <DashboardLoader />;
    } else if (this.state.sales_funnel.length === 3) {
      return (
        <FunnelThree
          sales_funnel={this.state?.sales_funnel}
          userId={this.props.userId}
          userName={this.props.userName}
          funnelId={this.state.selectedFunnel.id}
        />
      );
    } else if (this.state?.sales_funnel.length === 4) {
      return (
        <FunnelFour
          sales_funnel={this.state?.sales_funnel}
          userId={this.props.userId}
          userName={this.props.userName}
          funnelId={this.state.selectedFunnel.id}
        />
      );
    } else if (this.state?.sales_funnel.length === 5) {
      return (
        <FunnelFive
          sales_funnel={this.state?.sales_funnel}
          userId={this.props.userId}
          userName={this.props.userName}
          funnelId={this.state.selectedFunnel.id}
        />
      );
    } else if (this.state?.sales_funnel.length === 6) {
      return (
        <FunnelSix
          sales_funnel={this.state?.sales_funnel}
          userId={this.props.userId}
          userName={this.props.userName}
          funnelId={this.state.selectedFunnel.id}
        />
      );
    } else if (this.state?.sales_funnel.length === 7) {
      return (
        <FunnelSeven
          sales_funnel={this.state?.sales_funnel}
          userId={this.props.userId}
          userName={this.props.userName}
          funnelId={this.state.selectedFunnel.id}
        />
      );
    } else if (this.state?.sales_funnel.length === 8) {
      return (
        <FunnelEight
          sales_funnel={this.state?.sales_funnel}
          userId={this.props.userId}
          userName={this.props.userName}
          funnelId={this.state.selectedFunnel.id}
        />
      );
    } else if (this.state?.sales_funnel.length === 9) {
      return (
        <FunnelNine
          sales_funnel={this.state?.sales_funnel}
          userId={this.props.userId}
          userName={this.props.userName}
          funnelId={this.state.selectedFunnel.id}
        />
      );
    } else if (this.state?.sales_funnel.length === 10) {
      return (
        <FunnelTen
          sales_funnel={this.state?.sales_funnel}
          userId={this.props.userId}
          userName={this.props.userName}
          funnelId={this.state.selectedFunnel.id}
        />
      );
    }
  };

  render() {
    return (
      <div
        className="card card-funnel"
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "0",
        }}
      >
        <div className="funnel-heading">
          <h4 className="funnel-heading-sub">
            <button
              type="button"
              onClick={this.setPrimaryFunnel}
              disabled={this.state.selectedFunnel.is_default}
              style={
                this.state.selectedFunnel.is_default
                  ? {
                      cursor: "not-allowed",
                      marginRight: "10px",
                      padding: "0",
                      border: "none",
                      background: "transparent",
                      color: "gold",
                    }
                  : {
                      cursor: "pointer",
                      marginRight: "10px",
                      padding: "0",
                      border: "none",
                      background: "transparent",
                      color: "grey",
                    }
              }
            >
              <i class="fa fa-star" aria-hidden="true"></i>
            </button>
            <div
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({
                    type: "dealStage",
                    id: `${this.props.userId}sel${this.state.selectedFunnel?.id}Funnel`,
                    name: `${this.state.selectedFunnel?.name}`,
                    blank: true,
                    userId: this.props?.userId,
                    userName: this.props.userName,
                    funnelId: this.state.selectedFunnel?.id,
                    stageId: "showActiveStages",
                  });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab({
                    type: "dealStage",
                    id: `${this.props.userId}sel${this.state.selectedFunnel?.id}Funnel`,
                    name: `${this.state.selectedFunnel?.name}`,
                    userId: this.props?.userId,
                    userName: this.props.userName,
                    funnelId: this.state.selectedFunnel?.id,
                    stageId: "showActiveStages",
                  });
                  this.props.history.push("/active-tabs");
                }
              }}
              style={{
                color: "inherit",
                display: "inline-block",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              data-tip="View all active deals."
              data-for="Deals-Funnel"
            >
              {this.state.selectedFunnel?.name}
            </div>
            <DropdownFunnel
              allFunnels={this.state.allFunnels}
              getAllFunnels={this.getAllFunnels}
            />
          </h4>
          <h6 className="funnel-heading-sub-c">
            <select
              className="funnel-select"
              name="funnel_total_days"
              value={localStorage.getItem("funnel_total_days")}
              defaultValue="all_days"
              onChange={(e) => {
                this.salesFunnelHandler(this.state.selectedFunnel.id, e);
                localStorage.setItem("funnel_total_days", e.target.value);
              }}
            >
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
              <option value="365">365 Days</option>
              <option value="all_days">All Time</option>
            </select>
          </h6>
          <ReactTooltip multiline={true} id={"Deals-Funnel"} />
        </div>
        <div style={{ marginTop: "38px", paddingLeft: "14px" }}>
          {this.state.funnelLoader ? <DashboardLoader /> : this.showFunnel()}
          <div className="funnel-redirect-buttons">
            {this.state.allStagesInFunnel.map((data) => (
              <span
                key={data.id+data.name}
                onClick={(e) => {
                  if (e.metaKey || e.ctrlKey) {
                    this.props.setTab({
                      type: "dealStage",
                      id: `${this.props?.userId}${data?.id}Funnel`,
                      name: `${data?.name}`,
                      blank: true,
                      stageId: data?.id,
                      userId: this.props?.userId,
                      userName: this.props.userName,
                      funnelId: this.state.selectedFunnel.id,
                    });
                  } else {
                    this.props.setActiveTabIndex(this.props.totalTabs + 1);
                    this.props.setTab({
                      type: "dealStage",
                      id: `${this.props?.userId}${data?.id}Funnel`,
                      name: `${data?.name}`,
                      stageId: data?.id,
                      userId: this.props?.userId,
                      userName: this.props.userName,
                      funnelId: this.state.selectedFunnel.id,
                    });
                    this.props.history.push("/active-tabs");
                  }
                }}
              >
                {data.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default withRouter(connect(MSP, MDP)(Funnel));
