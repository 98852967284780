import {
  FETCH_ACCOUNT_FIELDS_REQUEST,
  FETCH_ACCOUNT_FIELDS_SUCCESS,
  FETCH_ACCOUNT_FIELDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllFormAccountFieldsRequest = () => {
  return {
    type: FETCH_ACCOUNT_FIELDS_REQUEST,
  };
};
const fetchAllFormAccountFieldsSuccess = (data) => {
  return {
    type: FETCH_ACCOUNT_FIELDS_SUCCESS,
    payload: data,
  };
};
const fetchAllFormAccountFieldsFailure = (err) => {
  return {
    type: FETCH_ACCOUNT_FIELDS_FAILURE,
    payload: err,
  };
};

// Fetch All AllFormAccountFields
export const fetchAllAllFormAccountFields = () => {
  return (dispatch) => {
    dispatch(fetchAllFormAccountFieldsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/default-fields?type=account`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.default_fields;
        dispatch(fetchAllFormAccountFieldsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllFormAccountFieldsFailure(errorMsg));
      });
  };
};
