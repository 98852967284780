import React, { useEffect, useState } from "react";
import "./CustomizeAccountModalPage.scss";
import AccountIcon from "../../images/AccountIcon.svg";
import ModalPreview from "./ModalPreview";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { defaultAccountFields } from "../../constants/fields/defaultAccountFields";
import { defaultContactFields } from "../../constants/fields/defaultContactFields";
import { defaultDealFields } from "../../constants/fields/defaultDealFields";
import { NotificationManager } from "react-notifications";
// Redux stuff
import { connect } from "react-redux";
import {
  fetchAllAllFormAccountFields,
  fetchAllAllFormCarrierFields,
  fetchAllAllFormContactFields,
  fetchAllAllFormDealFields,
  setActiveTabIndex,
} from "../../redux";
import DashboardLoader from "../Loaders/DashboardLoader";
import Checkbox from "../Reusables/Checkbox";
import { defaultCarrierFields } from "../../constants/fields/defaultCarrierFields";

const CustomizeAccountModalPage = (props) => {
  const pathArray = window.location.pathname.split("/");
  const [loading, setLoading] = useState(false);
  const [fieldsLoader, setFieldsLoader] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState(
    pathArray[2] === "account"
      ? defaultAccountFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
      : pathArray[2] === "carrier"
      ? defaultCarrierFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
      : pathArray[2] === "contact"
      ? defaultContactFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
      : defaultDealFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
  );
  const fetchAccountData = () => {
    axios({
      method: "GET",
      url: `/fields/${pathArray[2]}`,
    })
      .then((response) => {
        const allCustomFields = response.data.fields?.filter(
          (field) => field.meta === null || field.meta.visible_in_form === true
        );
        const defaultFields =
          pathArray[2] === "account"
            ? defaultAccountFields
            : pathArray[2] === "carrier"
            ? defaultCarrierFields
            : pathArray[2] === "contact"
            ? defaultContactFields
            : defaultDealFields;
        const allFields = [defaultFields, ...allCustomFields];
        setCustomFields(allFields);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    fetchAccountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathArray[2]]);
  const getStoredFieldsData = () => {
    setFieldsLoader(true);
    const fallBackFields =
      pathArray[2] === "account"
        ? defaultAccountFields.fields?.filter(
            (field) => field.selected_by_default === true
          )
        : pathArray[2] === "contact"
        ? defaultContactFields.fields?.filter(
            (field) => field.selected_by_default === true
          )
        : pathArray[2] === "carrier"
        ? defaultCarrierFields.fields?.filter(
            (field) => field.selected_by_default === true
          )
        : defaultDealFields.fields?.filter(
            (field) => field.selected_by_default === true
          );
    axios({
      method: "GET",
      url: `/admin/default-fields?type=${pathArray[2]}`,
    })
      .then((res) => {
        setFieldsLoader(false);
        const default_fields = res.data?.default_fields?.data;
        default_fields
          ? setSelectedFields(default_fields)
          : selectedFields(fallBackFields);
      })
      .catch((err) => {
        setFieldsLoader(false);
        selectedFields(fallBackFields);
      });
  };
  useEffect(() => {
    getStoredFieldsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathArray[2]]);
  const submitHandler = () => {
    setLoading(true);
    axios({
      method: "PUT",
      url: `/admin/default-fields`,
      data: {
        type: pathArray[2],
        is_modified: true,
        data: selectedFields,
      },
    })
      .then((res) => {
        setLoading(false);
        NotificationManager.success("Form saved successfully.");
        props.fetchAllAllFormAccountFields();
        props.fetchAllAllFormCarrierFields();
        props.fetchAllAllFormContactFields();
        props.fetchAllAllFormDealFields();
        props.setActiveTabIndex(0);
        props.history.push("/active-tabs");
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error saving changes.");
      });
  };
  const selectedFieldsChangeHandler = (field, e) => {
    if (e.target.checked) {
      const newArray = [...selectedFields, field];
      setSelectedFields(newArray);
    } else {
      const filteredArray = selectedFields?.filter(
        (f) => f.id !== field.id && f.name !== field.name
      );
      setSelectedFields(filteredArray);
    }
  };
  const changeRequiredHandler = (field, e) => {
    if (e.target.checked) {
      const newArray = selectedFields?.map((f) => {
        if (f.id === field.id && f.name === field.name) {
          f.required = true;
        }
        return f;
      });
      setSelectedFields(newArray);
    } else {
      const newArray = selectedFields?.map((f) => {
        if (f.id === field.id && f.name === field.name) {
          f.required = false;
        }
        return f;
      });
      setSelectedFields(newArray);
    }
  };
  const deleteField = (field) => {
    const filteredArray = selectedFields?.filter(
      (f) =>
        f.id !== field.id && f.name !== field.name && f.label !== field.label
    );
    setSelectedFields(filteredArray);
  };
  const insertSectionHeading = () => {
    const newArray = [
      ...selectedFields,
      {
        choices: [],
        field_class: pathArray[2],
        id: `d-sectionHeading${selectedFields.length}`,
        label: "Section Heading",
        name: `section_heading${selectedFields.length}`,
        parent_id: "default_fields",
        placeholder: null,
        position: 1,
        required: true,
        type: "heading",
        visible: null,
      },
    ];
    setSelectedFields(newArray);
  };
  const sectionHeadingChangeHandler = (name, e) => {
    const { value } = e.target;
    const newArray = selectedFields?.map((field) => {
      if (field.name === name) {
        field.label = value;
      }
      return field;
    });
    setSelectedFields(newArray);
  };
  let selectedFieldsArray = selectedFields?.map((field) => field.name);
  let requiredFieldsArray = selectedFields
    ?.filter((field) => field.required)
    ?.map((field) => field.name);
  return (
    <div className="customize-modal-container">
      <div>
        <ModalPreview
          heading={`Create ${pathArray[2] === "account" ? "an" : "a"} ${
            pathArray[2] === "opportunity" ? "Deal" : pathArray[2]
          }`}
          description={`Add some information about the ${
            pathArray[2] === "opportunity" ? "deal" : pathArray[2]
          }`}
          displayIcon={AccountIcon}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          sectionHeadingChangeHandler={sectionHeadingChangeHandler}
          deleteField={deleteField}
        />
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className="button-md button-outline-green"
            type="button"
            onClick={() => insertSectionHeading()}
            style={{
              marginRight: "15px",
              height: "36px",
              padding: "0 16px",
              fontWeight: "500",
              background: "#ffffff",
              color: "#4BC893",
              border: "1px solid #4BC893",
            }}
          >
            + Insert Section Heading
          </button>
          <button
            type="button"
            onClick={() => submitHandler()}
            disabled={loading}
            className="button-md"
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </div>
        {fieldsLoader ? (
          <>
            <DashboardLoader />
            <DashboardLoader />
            <DashboardLoader />
          </>
        ) : (
          customFields?.map((group) => {
            const { label, fields, meta, id } = group;
            return (
              <div key={id}>
                {meta === null ? (
                  <div>
                    <h3
                      className="modal__heading-sub"
                      style={{ fontSize: "16px" }}
                    >
                      {label}
                    </h3>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                      }}
                    >
                      {fields?.map((field) => {
                        const { id, label, type, required } = field;
                        return (
                          <span key={id} className="customize-modal-field">
                            <h3 style={{ color: "#555" }}>Selected</h3>
                            <div>{label}</div>
                            <h3 style={{ color: "#555" }}>Required</h3>
                            <Checkbox
                              label=""
                              id={id}
                              isDisabled={field.selected_by_default === true}
                              isChecked={selectedFieldsArray.includes(
                                field.name
                              )}
                              onChange={(e) =>
                                selectedFieldsChangeHandler(field, e)
                              }
                              value={true}
                            />
                            <span
                              style={{
                                color: "#a7abaa",
                                transform: "translateY(2px)",
                                fontSize: "11px",
                              }}
                            >
                              {type === "multi_select_dropdown"
                                ? "Multi-select"
                                : type === "decimal"
                                ? "Number"
                                : type}
                            </span>
                            <Checkbox
                              label=""
                              id={id}
                              isDisabled={field.selected_by_default === true}
                              isChecked={
                                required ||
                                requiredFieldsArray.includes(field.name)
                              }
                              onChange={(e) => changeRequiredHandler(field, e)}
                              value={true}
                            />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    totalTabs: state.tabsState.tabs.length,
    dashboardsState: state.dashboardsState,
    isAccountFieldsModified: state.allAccountFields.isModified,
    isCarrierFieldsModified: state.allCarrierFields.isModified,
    isContactFieldsModified: state.allContactFields.isModified,
    isDealFieldsModified: state.allDealFields.isModified,
  };
};
const MDP = (dispatch) => {
  return {
    fetchAllAllFormAccountFields: (allAccountFields) =>
      dispatch(fetchAllAllFormAccountFields(allAccountFields)),
    fetchAllAllFormCarrierFields: (allCarrierFields) =>
      dispatch(fetchAllAllFormCarrierFields(allCarrierFields)),
    fetchAllAllFormContactFields: (allContactFields) =>
      dispatch(fetchAllAllFormContactFields(allContactFields)),
    fetchAllAllFormDealFields: (allDealFields) =>
      dispatch(fetchAllAllFormDealFields(allDealFields)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};
export default connect(MSP, MDP)(withRouter(CustomizeAccountModalPage));
