import { combineReducers } from "redux";

import loginReducer from "./Login/loginReducer";
import tabReducer from "./Tabs/reducer";
import dashboardsReducer from "./FetchAllDashboards/reducer";
import accountFieldsReducer from "./FetchAllAccountFields/reducer";
import accountCustomFieldsReducer from "./FetchAllAccountCustomFields/reducer";
import carrierFieldsReducer from "./FetchAllCarrierFields/reducer";
import carrierCustomFieldsReducer from "./FetchAllCarrierCustomFields/reducer";
import contactFieldsReducer from "./FetchAllContactFields/reducer";
import contactCustomFieldsReducer from "./FetchAllContactCustomFields/reducer";
import dealFieldsReducer from "./FetchAllDealFields/reducer";
import dealCustomFieldsReducer from "./FetchAllDealCustomFields/reducer";
import storedRecordCollapsibleReducer from "./FetchStoredRecordCollapsible/reducer";
import storedRecordSelectableReducer from "./FetchStoredRecordSelectable/reducer";
import storedRecordNotesReducer from "./FetchStoredRecordNotes/reducer";
import allUsersReducer from "./FetchAllUsers/reducer";
import allEquipmentTypesReducer from "./FetchAllEquipmentTypes/reducer";
import allLaneTypesReducer from "./FetchAllLaneTypes/reducer";
import allSpecialRequirementsReducer from "./FetchAllSpecialRequirements/reducer";
import allModesReducer from "./FetchAllModes/reducer";
import allAccountSourcesReducer from "./FetchAllAccountSources/reducer";
import allAccountStatusesReducer from "./FetchAllAccountStatuses/reducer";
import allCarrierStatusesReducer from "./FetchAllCarrierStatuses/reducer";
import allContactStatusesReducer from "./FetchAllContactStatuses/reducer";
import reportsFiltersReducer from "./FetchReportsFilters/reducer";
import activitiesReducer from "./Activities/reducer";

const rootReducer = combineReducers({
  login: loginReducer,
  tabsState: tabReducer,
  dashboardsState: dashboardsReducer,
  allAccountFields: accountFieldsReducer,
  allAccountCustomFields: accountCustomFieldsReducer,
  allCarrierFields: carrierFieldsReducer,
  allCarrierCustomFields: carrierCustomFieldsReducer,
  allContactFields: contactFieldsReducer,
  allContactCustomFields: contactCustomFieldsReducer,
  allDealFields: dealFieldsReducer,
  allDealCustomFields: dealCustomFieldsReducer,
  storedRecordCollapsible: storedRecordCollapsibleReducer,
  storedRecordSelectable: storedRecordSelectableReducer,
  storedRecordNotes: storedRecordNotesReducer,
  allUsers: allUsersReducer,
  allEquipmentTypes: allEquipmentTypesReducer,
  allLaneTypes: allLaneTypesReducer,
  allSpecialRequirements: allSpecialRequirementsReducer,
  allModes: allModesReducer,
  allAccountSources: allAccountSourcesReducer,
  allAccountStatuses: allAccountStatusesReducer,
  allCarrierStatuses: allCarrierStatusesReducer,
  allContactStatuses: allContactStatusesReducer,
  reportsFilters: reportsFiltersReducer,
  activities: activitiesReducer,
});

export default rootReducer;
