import {
  SET_TAB,
  SET_ACTIVE_TAB_INDEX,
  DELETE_TAB,
  DELETE_ALL_TABS,
} from "./types";

const INITIAL_STATE = {
  tabs: [],
  activeTabIndex: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TAB:
      let index;
      const tabIsAlreadyOpen = state.tabs.some((tab, idx) => {
        index = idx;
        return tab.type === action.payload.type && tab.id === action.payload.id;
      });

      if (tabIsAlreadyOpen && action.payload.blank === true) {
        return { ...state };
      } else if (tabIsAlreadyOpen && action.payload.edited === true) {
        return {
          activeTabIndex: index + 1,
          tabs: state.tabs.map((tab) =>
            tab.id === action.payload.id ? (tab = action.payload) : tab
          ),
        };
      } else if (tabIsAlreadyOpen) {
        return { ...state, activeTabIndex: index + 1 };
      } else {
        return {
          ...state,
          tabs: [...state.tabs, action.payload],
        };
      }
    case DELETE_TAB:
      const newTabs = state.tabs.filter((tab, idx) => idx !== action.payload);
      return {
        ...state,
        tabs: newTabs,
        activeTabIndex:
          action.payload === 0 && newTabs.length === 0
            ? 0
            : action.payload >= newTabs.length
            ? action.payload
            : action.payload + 1,
      };

    case SET_ACTIVE_TAB_INDEX:
      return {
        ...state,
        activeTabIndex: action.payload,
      };
    case DELETE_ALL_TABS:
      return {
        // ...state,
        tabs: [],
        activeTabIndex: 0,
      };

    default:
      return state;
  }
};

export default reducer;
