import {
  FETCH_ACCOUNT_STATUSES_REQUEST,
  FETCH_ACCOUNT_STATUSES_SUCCESS,
  FETCH_ACCOUNT_STATUSES_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllAccountStatusesRequest = () => {
  return {
    type: FETCH_ACCOUNT_STATUSES_REQUEST,
  };
};
const fetchAllAccountStatusesSuccess = (data) => {
  return {
    type: FETCH_ACCOUNT_STATUSES_SUCCESS,
    payload: data,
  };
};
const fetchAllAccountStatusesFailure = (err) => {
  return {
    type: FETCH_ACCOUNT_STATUSES_FAILURE,
    payload: err,
  };
};

// Fetch All AccountStatuses
export const fetchAllAccountStatuses = () => {
  return (dispatch) => {
    dispatch(fetchAllAccountStatusesRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/accounts/statuses`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.account_statuses;
        dispatch(fetchAllAccountStatusesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllAccountStatusesFailure(errorMsg));
      });
  };
};
