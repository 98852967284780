import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";

class TransferOwnershipModal extends Component {
  state = {
    showModal: true,
    allUsers: null,
    newUser: null,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  componentDidMount() {
    this.showOwners();
  }
  showOwners = () => {
    axios({
      method: "GET",
      url: `/admin/users`,
    })
      .then((response) => {
        const unassignedOption = this.props.fromAccountsReport
          ? [
              {
                name: "Unassigned Leads",
                id: "unassigned",
              },
            ]
          : [];
        this.setState({
          allUsers: unassignedOption.concat(
            response.data.users?.slice()?.sort((a, b) => {
              const nameA = a.name.toUpperCase(); // Ignore case for comparison
              const nameB = b.name.toUpperCase(); // Ignore case for comparison

              if (nameA < nameB) {
                return -1; // Negative value: a should come before b
              }
              if (nameA > nameB) {
                return 1; // Positive value: b should come before a
              }
              return 0; // Return 0 for elements considered equal
            })
          ),
        });
      })
      .catch((error) => console.log("error", error));
  };
  changeHandler = (e) => {
    this.setState({
      newUser: e.target.value,
    });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "28vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Transfer Ownership</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label>New owner</label>
              <select
                value={this.state.newUser}
                onChange={this.changeHandler}
                style={
                  this.props.ownerErrorMessage === true &&
                  (this.state.newUser === null || this.state.newUser === "")
                    ? {
                        border: "2px solid #F36363",
                      }
                    : {
                        width: "auto",
                        maxWidth: "250px",
                        minWidth: "150px",
                      }
                }
              >
                <option value="" selected disabled>
                  - Select -
                </option>
                {this.state.allUsers?.map((user) => {
                  const { name, id } = user;
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
              </select>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.props.ownerErrorMessage === true &&
                (this.state.newUser === null || this.state.newUser === "")
                  ? "Please select an owner"
                  : null}
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  onClick={() =>
                    this.props.transferOwnership(this.state.newUser)
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default TransferOwnershipModal;
