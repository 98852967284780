export const truncateDecimals = (number, digits) => {
  // this function  will take number to be rounded
  // and number of places to be truncated as args
  const multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? "ceil" : "floor"](adjustedNum);

  return truncatedNum / multiplier;
};
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};
export const getCurrencyValue = (value) => {
  if (value?.toString().toLowerCase().includes("k")) {
    const valueInt = value
      .split("")
      ?.filter((val) => val !== "k" && val !== " " && val !== ",")
      .join("");
    return parseFloat(valueInt) * 1000;
  } else if (value?.toString().toLowerCase().includes("m")) {
    const valueInt = value
      .split("")
      ?.filter((val) => val !== "m" && val !== " " && val !== ",")
      .join("");
    return parseFloat(valueInt) * 1000000;
  } else if (value?.toString().toLowerCase().includes("b")) {
    const valueInt = value
      .split("")
      ?.filter((val) => val !== "b" && val !== " " && val !== ",")
      .join("");
    return parseFloat(valueInt) * 1000000000;
  } else if (value?.toString().toLowerCase().includes("t")) {
    const valueInt = value
      .split("")
      ?.filter((val) => val !== "t" && val !== " " && val !== ",")
      .join("");
    return parseFloat(valueInt) * 1000000000000;
  } else if (value?.toString().toLowerCase().includes(",")) {
    const valueInt = value
      .split("")
      ?.filter((val) => val !== " " && val !== ",")
      .join("");
    return valueInt;
  } else {
    return parseFloat(value);
  }
};
export const isValidCurrency = (value) => {
  const valueArray = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "-",
    "k",
    "K",
    "m",
    "M",
    "b",
    "B",
    "t",
    "T",
    ".",
    ",",
  ];
  const moneySymbol = ["k", "K", "m", "M", "b", "B", "t", "T"];
  const removeByIndex = (array, index) => array.filter((_, i) => i !== index);
  if (value) {
    const valArray = value.split("");
    const valArrayWithoutLastItem = removeByIndex(
      valArray,
      valArray.length - 1
    );
    const found = valArray.every((r) => valueArray.indexOf(r) >= 0);
    if (
      moneySymbol.includes(valArray.at(-1)) &&
      moneySymbol.includes(valArrayWithoutLastItem.at(-1))
    ) {
      // restrict currency symbol to repeat
      return false;
    }
    if (valArray.at(-1) === "-" && valArray.length > 1) {
      // only allow minus sign to be first element
      return false;
    }
    if (moneySymbol.includes(valArray.at(-2))) {
      // don't allow to write after currency symbol
      return false;
    }
    if (valArray.at(-1) === "." && valArrayWithoutLastItem.includes(".")) {
      // don't allow more than one decimal points
      return false;
    }
    if (found) {
      // check if all elements of entered value are allowed
      return true;
    }
    return false;
  } else {
    return true;
  }
};
