import React from "react";
import { accountsSorting, contactsSorting } from "./constants/sorting";
import "../../sass/DropdownHeader.scss";

class ApolloSortingDropdown extends React.Component {
  state = {
    displayMenu: false,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  render() {
    const recordsSorting =
      this.props.type === "contacts" ? contactsSorting : accountsSorting;
    const label = recordsSorting.find(
      (rec) => rec.value === this.props.sortingParam
    );
    return (
      <div
        ref={this.wrapperRef}
        className="dropdownSchedule"
        style={{
          background: "transparent",
          width: "160px",
        }}
      >
        <div
          className="button-md-black-outline"
          onClick={this.showDropdownMenu}
          style={{
            width: "124px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {label?.label} ({this.props.isAscending})
        </div>

        {this.state.displayMenu ? (
          <ul
            className="dropdownSchedule-ul"
            style={{ width: "auto", bottom: "-100px" }}
          >
            <li>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "15px" }}>
                  <label
                    style={{
                      textAlign: "left",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Sort by
                  </label>
                  <select
                    value={this.props.sortingParam}
                    onChange={(e) => this.props.setSortingParam(e.target.value)}
                    style={{
                      padding: "4px",
                      border: "1px solid #e3e3e3",
                      margin: "7px 0",
                      borderRadius: "3px",
                      width: "150px",
                    }}
                  >
                    {recordsSorting.map((rec) => (
                      <option value={rec.value}>{rec.label}</option>
                    ))}
                  </select>
                  <select
                    value={this.props.isAscending}
                    onChange={(e) => this.props.setIsAscending(e.target.value)}
                    style={{
                      padding: "4px",
                      border: "1px solid #e3e3e3",
                      margin: "0 0 14px 0",
                      borderRadius: "3px",
                      width: "150px",
                    }}
                  >
                    <option value={"Desc"}>Descending</option>
                    <option value={"Asc"}>Ascending</option>
                  </select>
                </div>
              </div>
              <button
                type="button"
                className="button-md"
                onClick={() => {
                  this.props.submitHandler(this.props.labelId, 1);
                  this.showDropdownMenu();
                }}
                disabled={this.props.isLoading}
                style={{ marginLeft: "0" }}
              >
                {this.props.isLoading ? "Applying" : "Apply"}
              </button>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default ApolloSortingDropdown;
