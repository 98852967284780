import {
  FETCH_CARRIER_FIELDS_REQUEST,
  FETCH_CARRIER_FIELDS_SUCCESS,
  FETCH_CARRIER_FIELDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllFormCarrierFieldsRequest = () => {
  return {
    type: FETCH_CARRIER_FIELDS_REQUEST,
  };
};
const fetchAllFormCarrierFieldsSuccess = (data) => {
  return {
    type: FETCH_CARRIER_FIELDS_SUCCESS,
    payload: data,
  };
};
const fetchAllFormCarrierFieldsFailure = (err) => {
  return {
    type: FETCH_CARRIER_FIELDS_FAILURE,
    payload: err,
  };
};

// Fetch All AllFormCarrierFields
export const fetchAllAllFormCarrierFields = () => {
  return (dispatch) => {
    dispatch(fetchAllFormCarrierFieldsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/default-fields?type=carrier`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.default_fields;
        dispatch(fetchAllFormCarrierFieldsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllFormCarrierFieldsFailure(errorMsg));
      });
  };
};
