import {
  SET_STORED_RECORD_COLLAPSIBLE,
  REMOVE_STORED_RECORD_COLLAPSIBLE,
} from "./types";

const INITIAL_STATE = {
  fields: {
    account: [],
    deal: [],
    contact: [],
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STORED_RECORD_COLLAPSIBLE:
      if (action.payload.type === "account") {
        return {
          fields: {
            ...state.fields,
            account: [...state.fields.account, action.payload.id],
          },
        };
      } else if (action.payload.type === "deal") {
        return {
          fields: {
            ...state.fields,
            deal: [...state.fields.deal, action.payload.id],
          },
        };
      } else if (action.payload.type === "contact") {
        return {
          fields: {
            ...state.fields,
            contact: [...state.fields.contact, action.payload.id],
          },
        };
      } else {
        return state;
      }
    case REMOVE_STORED_RECORD_COLLAPSIBLE:
      if (action.payload.type === "account") {
        return {
          fields: {
            ...state.fields,
            account: state.fields.account.filter(
              (field) => field !== action.payload.id
            ),
          },
        };
      } else if (action.payload.type === "deal") {
        return {
          fields: {
            ...state.fields,
            deal: state.fields.deal.filter(
              (field) => field !== action.payload.id
            ),
          },
        };
      } else if (action.payload.type === "contact") {
        return {
          fields: {
            ...state.fields,
            contact: state.fields.contact.filter(
              (field) => field !== action.payload.id
            ),
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default reducer;
