import React, { useState } from "react";
import "../../sass/ActivityLog.scss";
import "../../sass/Animation.scss";
import moment from "moment";
import ResultIcon from "../../images/ResultIcon.svg";

const DummyActivityLog = (props) => {
  const [expand, setExpand] = useState(false);
  let whiteSpace = expand === false ? "normal" : "pre-wrap";
  let text =
    "Hey Josh, \n \n Just tried reaching you and was unable to connect. When would be a good time to reach back out?";
  let newText = text.split("\n").map((item, i) => <div key={i}>{item}</div>);
  return (
    <div className="activityLog">
      <div className="activityLog__header">
        <div>
          <h3 className="activity-title">Activity Timeline</h3>
        </div>

        <div className=" activityLog__menu">
          <button
            type="button"
            onClick={() => setExpand(!expand)}
            className="activityLog__button"
          >
            {expand === false ? "Expand All" : "Collapse All"}
          </button>
        </div>
      </div>
      <div style={{ height: "700px", overflow: "auto" }}>
        <div className="activityLog__date">{moment().format("D MMM")}</div>
        <div className="activityLog__activity">
          {props.loggedData && (
            <div>
              <div className="activityLog__activity-time">9:40 PM</div>
              <div className="activity-area">
                <div className="activityLog__activity-desc">
                  <div style={{ display: "inline-block" }}>
                    <img
                      src={require(`../../images/CallGreen.svg`)}
                      alt=""
                      width={16}
                      className="a-type "
                      style={{ display: "inline" }}
                    />
                  </div>
                  <div style={{ display: "inline-block", marginLeft: "45px" }}>
                    <div className="activityLog__activity-name">
                      Josh Lyles{" "}
                      <i
                        className="fa fa-circle"
                        style={{
                          fontSize: "4px",
                          transform: "translateY(-3px)",
                        }}
                      ></i>{" "}
                      Call by Salesdash
                    </div>
                    <div className="activityLog__activity-title">
                      Call Salesdash
                    </div>
                    <div
                      className="activityLog__activity-description"
                      style={{
                        color: "#586874",
                        marginTop: "5px",
                        fontSize: "12px",
                        whiteSpace: whiteSpace,
                      }}
                    >
                      {props.description &&
                        (expand === false
                          ? props.description.substring(0, 47) + `   ...`
                          : props.description)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="activityLog__activity-time">9:40 PM</div>
          <div className="activity-area">
            <div className="activityLog__activity-desc">
              <div style={{ display: "inline-block" }}>
                <img
                  src={require(`../../images/EmailGreen.svg`)}
                  alt=""
                  width={16}
                  className="a-type "
                  style={{ display: "inline" }}
                />
              </div>
              <div style={{ display: "inline-block", marginLeft: "45px" }}>
                <div className="activityLog__activity-name">
                  Josh Lyles{" "}
                  <i
                    className="fa fa-circle"
                    style={{ fontSize: "4px", transform: "translateY(-3px)" }}
                  ></i>{" "}
                  Email by Salesdash
                </div>
                <div className="activityLog__activity-title">
                  Outbound Email
                </div>
                <div
                  className="activityLog__activity-description"
                  style={{ whiteSpace: whiteSpace }}
                >
                  {expand === false
                    ? text.substring(0, 47) + `   ...`
                    : newText}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="activityLog__activity-time">9:40 PM</div>
        <div className="activity-area">
          <div className="activityLog__activity-desc">
            <div style={{ display: "inline-block" }}>
              <img
                src={require(`../../images/CallGreen.svg`)}
                alt="Activity"
                width={16}
                className="a-type "
                style={{ display: "inline" }}
              />
            </div>
            <div style={{ display: "inline-block", marginLeft: "45px" }}>
              <div className="activityLog__activity-name">
                Josh Lyles{" "}
                <i
                  className="fa fa-circle"
                  style={{ fontSize: "4px", transform: "translateY(-3px)" }}
                ></i>{" "}
                Call by Salesdash
              </div>
              <div className="activityLog__activity-title">Call Josh</div>
              <div
                className="activityLog__activity-description"
                style={{
                  color: "#586874",
                  marginTop: "5px",
                  fontSize: "12px",
                }}
              >
                <img src={ResultIcon} alt="Result:" width="10" /> No Answer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DummyActivityLog);
