export const contactsFilters = [
  {
    value: "owner",
    label: "Contact Owner",
    type: "contact",
  },
  {
    value: "contact_name",
    label: "Contact Name",
    type: "contact",
  },
  {
    value: "created_date",
    label: "Created Date",
    type: "contact",
  },
  {
    value: "account_report_id",
    label: "Account Report",
    type: "contact",
  },
  {
    value: "status",
    label: "Status",
    type: "contact",
  },
  {
    value: "phone",
    label: "Phone",
    type: "contact",
  },
  {
    value: "role",
    label: "Job Title",
    type: "contact",
  },
  {
    value: "address",
    label: "Address",
    type: "contact",
  },
  {
    value: "city",
    label: "City",
    type: "contact",
  },
  {
    value: "state",
    label: "State",
    type: "contact",
  },
  {
    value: "zip",
    label: "Zip",
    type: "contact",
  },
  {
    value: "country",
    label: "Country",
    type: "contact",
  },
  {
    value: "age",
    label: "Age",
    type: "contact",
  },
  {
    value: "birthday",
    label: "Birthday",
    type: "contact",
  },
  {
    value: "source",
    label: "Lead Source",
    type: "contact",
  },
  {
    value: "disqualified_reason",
    label: "Disqualified Reason",
    type: "contact",
  },
  {
    value: "last_edited_date",
    label: "Last Edited Date",
    type: "contact",
  },
  {
    value: "last_activity_date",
    label: "Last Activity Date",
    type: "contact",
  },
  {
    value: "batch",
    label: "Batch",
    type: "contact",
  },
];
