import React from "react";
import "../../sass/DropdownHeader.scss";
import BellLogo from "../../images/BellActiveWhite.svg";
import BellIconGrey from "../../images/BellWhite.svg";
import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router-dom";
import DealsIcon from "../../images/DealsIcon.svg";
import ContactsIcon from "../../images/ContactsIcon.svg";
import TaskIcon from "../../images/TaskIcon.svg";
import AccountsIcon from "../../images/AccountsIcon.svg";
import NotificationReminderIcon from "../../images/NotificationReminderIcon.svg";
import TargetsWonDeals from "../../images/WonDealNotificationIcon.svg";
import AccountActivityIcon from "../../images/ActivityAccountIcon.svg";
import SequenceReplyIcon from "../../images/SequenceReplyIcon.svg";
import SendEmailIcon from "../../images/AccountEmailIcon.svg";
import SalesdashLogo from "../../images/logo.png";
import Webform from "../../images/Webform.svg";
import CalendlyLogo from "../../images/calendlyIcon.png";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex } from "../../redux";

class DropdownNotification extends React.Component {
  state = {
    displayMenu: false,
    notificationCount: 0,
    notifications: [],
    isLoading: false,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.checkCount();
    this.interval = setInterval(() => this.checkCount(), 90000);
  }
  loadNotification = (page = 1) => {
    this.setState({ isLoading: true });
    axios({
      url: `/notifications?_limit=50&_page=${page}`,
      method: "GET",
    })
      .then((res) => {
        this.setState({
          notifications: res.data.notifications,
          isLoading: false,
        });
        this.checkCount();
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  checkCount = () => {
    const authToken = JSON.parse(localStorage.getItem("authToken"));
    const now = moment(new Date()); //todays date
    const end = moment(authToken.expires_in); // expiry date
    const duration = moment.duration(now.diff(end));
    const minutes = duration.asMinutes();
    if (minutes >= -5) {
      this.refreshToken();
    }
    const favicon = document.getElementById("faviconSalesdash");
    axios({ url: `/notifications/count`, method: "GET" }).then((res) => {
      this.setState({ notificationCount: res.data.count }, () => {
        if (res.data.count > 0) {
          document.title = `(${res.data.count}) Salesdash`;
        } else {
          document.title = `Salesdash`;
        }
      });
      if (res.data.count === 0) {
        favicon && (favicon.href = "favicon.png");
      } else {
        favicon && (favicon.href = "faviconNotification.png");
      }
    });
  };
  refreshToken = () => {
    const authToken = JSON.parse(localStorage.getItem("authToken"));
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    axios({
      method: "POST",
      url: `/auth/refresh-token`,
      data: {
        email: authUser.email,
        refresh_token: authToken.refresh_token,
      },
    }).then((response) => {
      if (response.status === 200) {
        const authToken = {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
          expires_in: response.data.expires_in,
        };
        localStorage.setItem("authToken", JSON.stringify(authToken));
      }
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.displayMenu !== prevState.displayMenu &&
      this.state.displayMenu === true
    ) {
      this.loadNotification();
    }
    if (
      this.state.displayMenu !== prevState.displayMenu &&
      this.state.displayMenu === true &&
      this.state.notificationCount !== 0
    ) {
      this.loadNotification();
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    clearInterval(this.interval);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  render() {
    return (
      <div
        ref={this.wrapperRef}
        className="dropdownNotification"
        style={{ background: "transparent", width: "20px" }}
      >
        <div
          className="button notifications-button-wrapper"
          onClick={this.showDropdownMenu}
        >
          <img
            src={this.state.notificationCount > 0 ? BellLogo : BellIconGrey}
            alt="Bell"
            style={{ height: "24px" }}
          />
        </div>

        {this.state.displayMenu ? (
          <ul>
            <h2 className="dropdownNotification-heading">Notifications</h2>
            {this.state.isLoading ? (
              <div class="load-wrapp" key={0}>
                <div class="load-3">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
            ) : this.state.isLoading === false &&
              this.state.notifications.length === 0 ? (
              <div className="notifications-empty-container">
                <img src={SalesdashLogo} alt="" />
                <h2>No notifications right now</h2>
                <p>
                  Send emails from Salesdash and add your teammates, and we'll
                  show the notifications here.
                </p>
              </div>
            ) : (
              this.state.notifications?.map((notification) => {
                const { id, title, metadata, created_at, type } = notification;
                let newTabData = {};
                let imageSrc = TaskIcon;
                if (type === "calendly") {
                  imageSrc = CalendlyLogo;
                } else if (type === "reminder") {
                  imageSrc = NotificationReminderIcon;
                } else if (type === "target_achieved") {
                  imageSrc = TaskIcon;
                } else if (type === "deal_won") {
                  imageSrc = TargetsWonDeals;
                } else if (type === "task_assigned") {
                  imageSrc = AccountActivityIcon;
                } else if (type === "mail_read") {
                  imageSrc = SendEmailIcon;
                } else if (type === "sequence_reply") {
                  imageSrc = SequenceReplyIcon;
                } else if (type === "webform_submitted") {
                  imageSrc = Webform;
                } else if (
                  type === "entity_assigned" &&
                  metadata.entity.type === "opportunity"
                ) {
                  imageSrc = DealsIcon;
                } else if (
                  type === "entity_assigned" &&
                  metadata.entity.type === "contact"
                ) {
                  imageSrc = ContactsIcon;
                } else if (
                  type === "entity_assigned" &&
                  metadata.entity.type === "account"
                ) {
                  imageSrc = AccountsIcon;
                }
                if (metadata !== null) {
                  if (metadata.entity?.type === "opportunity") {
                    newTabData = {
                      type: "opportunity",
                      id: metadata.entity?.id,
                      name: metadata.entity?.name,
                    };
                  } else if (metadata.entity?.type === "contact") {
                    newTabData = {
                      type: "contact",
                      id: metadata.entity?.id,
                      name: metadata.entity?.name,
                    };
                  } else {
                    newTabData = {
                      type: "account",
                      id: metadata.entity?.id,
                      name: metadata.entity?.name,
                    };
                  }
                }
                return metadata === null ? (
                  <li key={id} style={{ cursor: "default" }}>
                    <div className="notifications-img">
                      <img src={imageSrc} alt="" style={{ width: "20px" }} />
                    </div>
                    <div>
                      <div className="notifications-title">{title}</div>
                      <div className="notifications-time">
                        {moment && moment(created_at).format("lll")}
                      </div>
                    </div>
                  </li>
                ) : (
                  <li
                    key={id}
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        this.props.setTab({ ...newTabData, blank: true });
                      } else {
                        this.setState({ displayMenu: false });
                        this.props.setActiveTabIndex(this.props.totalTabs + 1);
                        this.props.setTab(newTabData);
                        this.props.history.push("/active-tabs");
                      }
                    }}
                    style={type === "calendly" ? { paddingLeft: "4px" } : {}}
                  >
                    <div
                      className="notifications-img"
                      style={
                        type === "calendly"
                          ? {
                              padding: "8px 2px 5px 0",
                            }
                          : {}
                      }
                    >
                      <img
                        src={imageSrc}
                        alt=""
                        style={
                          type === "calendly"
                            ? { height: "30px", width: "50px" }
                            : { width: "20px" }
                        }
                      />
                    </div>
                    <div>
                      <div className="notifications-title">{title}</div>
                      <div className="notifications-body">
                        {metadata && metadata?.content}
                      </div>
                      <div className="notifications-time">
                        {moment && moment(created_at).format("lll")}
                      </div>
                    </div>
                  </li>
                );
              })
            )}
          </ul>
        ) : null}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default withRouter(connect(MSP, MDP)(DropdownNotification));
