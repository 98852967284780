import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";

class DeleteRoleModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    isLoading: false,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      name: this.props.name,
      email: this.props.email,
    });
    axios({
      method: "GET",
      url: `/admin/teams?view=table`,
    })
      .then((response) => {
        this.setState({
          teams: response.data.teams,
        });
      })
      .catch((error) => console.log("error", error));
    axios({
      method: "GET",
      url: `/admin/roles`,
    })
      .then((response) => {
        this.setState({
          roles: response.data.roles,
        });
      })
      .catch((error) => console.log("error", error));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    axios({
      method: "DELETE",
      url: `/admin/role/${this.props.roleId}`,
    })
      .then((res) => {
        this.props.fetchRoles();
        this.props.handleCloseModal();
        NotificationManager.success("Role deleted successfully.");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        NotificationManager.error("Error editing role.");
        this.setState({ isLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "26vh", top: "20%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Role</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <p>Are you sure you want to delete {this.props.name}?</p>

              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={this.submitHandler}
                  className="modal__confirm-button"
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Deleting" : "Delete"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default DeleteRoleModal;
