import React from "react";

function InvoiceViewMode({
  invoiceNumber,
  dateIssued,
  invoiceTitle,
  invoiceDescription,
  companyLogo,
  fromCompanyData,
  toCompanyData,
  items,
  adjustments,
  totalPrice,
  notes,
  billFromTitle,
  billToTitle,
  selectedCurrency,
  salesdashLogo,
  showSignature,
}) {
  return (
    <div className="invoicing-form-view">
      <div id="invoiceToPrint">
        <div className="invoicing-form-view-container">
          <div className="invoicing-form-header">
            <div className="invoicing-form-view-info">#{invoiceNumber}</div>
            <div className="invoicing-form-view-info">
              Date Issued: {dateIssued}
            </div>
          </div>
          <div className="invoicing-form-header invoice-margin-y-20">
            <div>
              <h3 className="invoicing-form-view-heading">{invoiceTitle}</h3>
              <div className="invoicing-form-view-info">
                {invoiceDescription}
              </div>
            </div>
            <div
              style={{
                height: "70px",
              }}
            >
              {companyLogo && (
                <img
                  src={companyLogo}
                  alt=""
                  height="66px"
                  style={{
                    display: "block",
                    margin: "auto",
                    marginTop: "2px",
                  }}
                />
              )}
            </div>
          </div>
          <div className="invoicing-form-header">
            <div>
              <h3 className="invoicing-form-view-heading">{billFromTitle}</h3>
              <div>
                <div className="invoicing-form-view-info">
                  {fromCompanyData.name}
                </div>
                <div className="invoicing-form-view-info">
                  {fromCompanyData.address}
                </div>
                <div className="invoicing-form-view-info">
                  {fromCompanyData.country}
                </div>
                <div className="invoicing-form-view-info">
                  {fromCompanyData.email}
                </div>
                <div className="invoicing-form-view-info">
                  {fromCompanyData.mobile}
                </div>
              </div>
            </div>
            <div>
              <h3 className="invoicing-form-view-heading">{billToTitle}</h3>
              <div>
                <div className="invoicing-form-view-info">
                  {toCompanyData.companyName}
                </div>
                <div className="invoicing-form-view-info">
                  {toCompanyData.name}
                </div>
                <div className="invoicing-form-view-info">
                  {toCompanyData.address}
                </div>
                <div className="invoicing-form-view-info">
                  {toCompanyData.email}
                </div>
                <div className="invoicing-form-view-info">
                  {toCompanyData.mobile}
                </div>
              </div>
            </div>
          </div>
          <div className="invoicing-form-view-table-container">
            <table className="invoicing-form-view-table">
              <col span="1" style={{ width: "40%" }} />
              <col span="1" style={{ width: "20%" }} />
              <col span="1" style={{ width: "20%" }} />
              <col span="1" style={{ width: "20%" }} />
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {items?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>
                      {selectedCurrency}
                      {item.price}
                    </td>
                    <td>
                      {selectedCurrency}
                      {item.total}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            {adjustments?.map((adjustment) => (
              <div
                className="invoicing-form-view-adjustments"
                key={adjustment.id}
              >
                <div className="invoicing-form-view-adjustments-key">
                  {adjustment.title}
                </div>
                <div className="invoicing-form-view-adjustments-value">
                  {selectedCurrency}
                  {adjustment.value}
                </div>
              </div>
            ))}
          </div>
          <div className="invoicing-form-view-total">
            <span>
              Total{" "}
              <span className="invoicing-form-view-total-value">
                {selectedCurrency}
                {totalPrice.toFixed(2)}
              </span>
            </span>
          </div>
          <div className="invoicing-form-view-notes">{notes}</div>
          <div>
            {showSignature && (
              <div className="invoicing-form-view-powered">
                {" "}
                <span className="invoicing-form-view-powered-span">
                  Powered by
                </span>{" "}
                <img src={salesdashLogo} alt="Salesdash" height="25px" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceViewMode;
