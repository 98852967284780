import React from "react";
import "../../sass/CustomerIntro.scss";
import ContactsIcon from "../../images/ContactsIcon.svg";

import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex } from "../../redux";
import { compose } from "recompose";

const Contact = (props) => {
  return (
    <div
      className="schedule__items-deal"
      onClick={() => {
        props.setActiveTabIndex(props.totalTabs + 1);
        props.setTab({
          type: "contact",
          id: props.id,
          name: props.name,
          contactId: props.contactId,
        });
        props.history.push("/active-tabs");
      }}
      data-tip={props.name}
    >
      <ReactTooltip />
      <div className="schedule__items-deal-name">
        <img src={ContactsIcon} alt="" />{" "}
        {props.name && props.name.length > 36
          ? props.name.substring(0, 35) + "..."
          : props.name}
      </div>
      <div className="schedule__items-deal-date" style={{ margin: "10px 0" }}>
        {props.detail}
      </div>
      <div className="schedule__items-deal-date">
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
          <div>
            P:{" "}
            <span style={{ fontWeight: "500" }}>{props.formatted_phone}</span>
          </div>
          <div>
            M:{" "}
            <span style={{ fontWeight: "500" }}>{props.formatted_mobile}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(Contact);
