import React, { Component } from "react";
import XLSX from "xlsx";
import axios from "axios";
import _ from "lodash";
import { NotificationContainer } from "react-notifications";

class ImportUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      format: "",
      data: [],
      headers: [],
      headersMapping: {},
      rows: [],
      isLoading: false,
      erroredData: [],
      totalCreated: 0,
      totalFailed: 0,
      submitted: false,
    };
    this.fileInput = React.createRef();
  }
  headersChangeHandler = (sheetHeader, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      headers: [...prevState.headers, value],
      headersMapping: { ...prevState.headersMapping, [sheetHeader]: value },
    }));
  };
  showRows = () => {
    this.setState((prevState) => ({
      rows: this.state.data.filter((d) => Object.keys(this.state.headers)),
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let dataChunks = _.chunk(
      this.state.data
        .filter((key) => this.state.headersMapping[key] !== "")
        .map((obj) =>
          Object.keys(this.state.headersMapping).map((key) => obj[key])
        ),
      100
    );
    let totalCreated = 0,
      totalFailed = 0;
    let counter = 0;
    let headers = [];
    let erroredData = [];
    dataChunks.forEach(async (chunk, i) => {
      const res = await axios({
        method: "POST",
        url: `/admin/import/users`,
        data: {
          headers: Object.values(this.state.headersMapping).filter(
            (value) => value !== ""
          ),
          rows: chunk,
        },
      });
      totalCreated += await res.data.total_created;
      totalFailed += await res.data.total_failed;
      headers = await res.data.headers;
      console.log("$$ headers:", headers);
      erroredData = await [...erroredData, ...res.data.error_data];
      console.log("$$ erroredData:", erroredData);
      counter++;
      console.log("import acc res", res);

      console.log("import acc resxxx", counter, dataChunks.length);
      if (counter === dataChunks.length) {
        // NotificationManager.success(`Total Created : ${totalCreated}`);
        // NotificationManager.error(`Total Failed : ${totalFailed}`);
        this.setState({
          totalCreated,
          totalFailed,
          data: [],
          headers: [],
          headersMapping: {},
          submitted: true,
        });

        if (erroredData) {
          console.log("MEME", [headers, ...erroredData]);

          this.setState({ erroredData: [headers, ...erroredData] });
        }

        this.setState({ isLoading: false });
      }
    });
    console.log("import acc res", totalCreated, totalFailed);
  };

  OnDrop = (file, e) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        defval: null,
      });
      if (data) {
        console.log("json import", data);
        this.setState({
          data,
        });
      }
    };
    reader.readAsBinaryString(file.files[0]);
  };

  downloadFailedImportData = () => {
    let errorDataInExcel = XLSX.utils.aoa_to_sheet(this.state.erroredData);
    let errorDataInCSV = XLSX.utils.sheet_to_csv(errorDataInExcel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, errorDataInExcel, "Failed");

    console.log("errorDataInCSV:", errorDataInCSV);
    return XLSX.writeFile(wb, "FailedImports.xlsx");

    // return XLSX.writeFile(this.state.errorDataInCSV, "failedImports.xlsx", {
    //   bookType: "csv",
    //   bookSST: true,
    //   type: "base64",
    // });
  };

  render() {
    let headerKeyValueMappings = {
      name: "Name",
      email: "Email",
      formatted_mobile: "Phone",
      role_name: "Role",
      country_code: "Country Code",
    };

    return (
      <div style={{ margin: "auto 30px" }}>
        <NotificationContainer />
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              borderBottom: "1px solid #E3E3E3",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <h2 style={{ marginBottom: "0px" }}>Import User</h2>
              <p>
                Please upload a .csv or .xlsx file to import and map the fields
                for the data
              </p>
            </div>
            {this.state.submitted === false &&
              this.fileInput?.current?.files[0] && (
                <div
                  style={{
                    marginTop: "4vh",
                    float: "right",
                    marginRight: "30px",
                  }}
                >
                  <button
                    type="submit"
                    className="button-md"
                    style={{
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#4BC893",
                    }}
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Please Wait..." : "Start Upload"}
                  </button>
                </div>
              )}
          </div>
          <label
            for="file-upload"
            style={{
              textDecoration: "none",
              background: "#FFFFFF",
              color: "#3AAB7B",
              padding: "10px 24px",
              borderRadius: "4px",
              border: "1px solid #3AAB7B",
              cursor: "pointer",
              display: "inline-block",
              marginRight: "10px",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "150%",
            }}
          >
            {this.fileInput?.current?.files[0]?.name
              ? "File Name"
              : "Choose File"}
          </label>
          <input
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            ref={this.fileInput}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={() => this.OnDrop(this.fileInput.current)}
          />
          {this.fileInput?.current?.files[0]?.name}
        </form>
        <div style={{ padding: "5px" }}>
          {this.state.data.length > 0 &&
            Object.keys(this.state.data[0]).map((key, i) => (
              <div
                key={i}
                style={{
                  display: "inline-block",
                  margin: "10px",
                  padding: "15px",
                  border: "2px solid #E3E3E3",
                  borderRadius: "8px",
                }}
              >
                <span>
                  <h4
                    style={{
                      margin: "6px auto",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    Sheet field
                  </h4>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    {key}
                  </div>
                </span>
                <br />
                <h4
                  style={{
                    margin: "6px auto",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                    color: "#0C0D0D",
                  }}
                >
                  Salesdash field
                </h4>
                <select
                  name="headers"
                  onChange={(e) => this.headersChangeHandler(key, e)}
                  style={{
                    border: "1px solid #A7ABAA",
                    borderRadius: "8px",
                    padding: "7px",
                  }}
                >
                  <option value="">Do not import</option>
                  {(!Object.values(this.state.headersMapping).includes(
                    "name"
                  ) ||
                    this.state.headersMapping[key] === "name") && (
                    <option value="name">Name</option>
                  )}
                  {(!Object.values(this.state.headersMapping).includes(
                    "email"
                  ) ||
                    this.state.headersMapping[key] === "email") && (
                    <option value="email">Email</option>
                  )}
                  {(!Object.values(this.state.headersMapping).includes(
                    "country_code"
                  ) ||
                    this.state.headersMapping[key] === "country_code") && (
                    <option value="country_code">Country Code</option>
                  )}
                  {(!Object.values(this.state.headersMapping).includes(
                    "formatted_mobile"
                  ) ||
                    this.state.headersMapping[key] === "formatted_mobile") && (
                    <option value="formatted_mobile">Phone</option>
                  )}
                  {(!Object.values(this.state.headersMapping).includes(
                    "role"
                  ) ||
                    this.state.headersMapping[key] === "role_name") && (
                    <option value="role_name">Role</option>
                  )}
                </select>
              </div>
            ))}
        </div>
        <table className="accountList__table">
          <thead>
            <tr>
              {Object.values(this.state.headersMapping)
                .filter((header) => header !== "")
                .map((header) => {
                  return <th>{headerKeyValueMappings[header]}</th>;
                })}
            </tr>
          </thead>
          <tbody>
            {this.state.data.slice(0, 5).map((obj) => (
              <tr>
                {Object.keys(this.state.headersMapping)
                  .filter((key) => this.state.headersMapping[key] !== "")
                  .map((key) => (
                    <td>{obj[key]}</td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>

        {this.state.submitted && (
          <>
            <p
              style={{
                fontSize: "1.5rem",
              }}
            >
              Import Summary
            </p>

            <p
              style={{
                fontSize: "1rem",
              }}
            >
              <b>Total Created:</b> {this.state.totalCreated}{" "}
            </p>
            <p
              style={{
                fontSize: "1rem",
              }}
            >
              <b>Total Failed:</b> {this.state.totalFailed}{" "}
            </p>
            <button
              type="button"
              className="button-md"
              style={{ display: "inline-block" }}
              onClick={() => this.props.history.push("/users")}
            >
              Proceed
            </button>
            {/* {this.state.totalFailed > 0 && (
              <div>
                <button
                  type="button"
                  className="button-md"
                  style={{
                    textDecoration: "none",
                    background: "#FFFFFF",
                    color: "#3AAB7B",
                    padding: "10px 24px",
                    borderRadius: "4px",
                    border: "1px solid #3AAB7B",
                    cursor: "pointer",
                    display: "inline-block",
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                  }}
                  onClick={this.downloadFailedImportData}
                >
                  Download Failed imports
                </button>
              </div>
            )} */}
          </>
        )}
      </div>
    );
  }
}

export default ImportUser;
