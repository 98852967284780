import React from "react";
import "jodit";
import "../../sass/Jodit.scss";
import JoditEditor from "jodit-react";

export default class ActivityJodit extends React.Component {
  updateContent = (value) => {
    this.setState({ content: value });
  };
  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  config = {
    height: "inherit",
    maxHeight: "inherit",
    minHeight: "inherit",
    width: "100%",
    tabIndex: 1,
    enableDragAndDropFileToEditor: true,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: true,
    addNewLine: false,
    allowResizeY: false,
    toolbarButtonSize: "small",
    buttons: [
      "bold",
      "italic",
      "underline",
      "|",
      "ul",
      "ol",
      "align",
      "|",
      "fontsize",
      "brush",
      "|",
      "link",
    ],
    link: {
      modeClassName: false,
      processVideoLink: false,
    },
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
    toolbarSticky: true,
    enter: "BR",
    placeholder: "Enter your notes here",
    zIndex: 2147483640,
    spellcheck: true,
  };
  render() {
    return (
      <JoditEditor
        editorRef={this.setRef}
        value={this.props.content}
        config={this.config}
        onChange={this.props.updateContent}
      />
    );
  }
}
