import React, { Component } from "react";
import ReactModal from "react-modal";

class DealFunnelChangeModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
  };

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "50vh", top: "15%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Change Funnel</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label>Deal Owner</label>
              <select
                name="ownerId"
                value={this.props.ownerId}
                onChange={this.props.changeHandler}
                style={{ width: "100%" }}
              >
                {this.props.users?.map((user) => (
                  <option value={user.id}>{user.name}</option>
                ))}
              </select>
              <label>Funnel</label>
              <select
                name="funnelId"
                value={this.props.funnelId}
                onChange={this.props.funnelChangeHandler}
                style={{ width: "100%" }}
              >
                <option value="" selected disabled>
                  -Select-
                </option>
                {this.props.funnels
                  ?.filter(
                    (funnel) => funnel.id !== this.props.existingFunnelId
                  )
                  .map((funnel) => (
                    <option value={funnel.id}>{funnel.name}</option>
                  ))}
              </select>
              {this.props.errorMessage === true &&
              this.props.funnelId === "" ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please select a funnel
                </div>
              ) : null}
              <label>Stage</label>
              <select
                name="stageId"
                value={this.props.stageId}
                onChange={this.props.changeHandler}
                style={{ width: "100%" }}
              >
                <option value="" selected disabled>
                  -Select-
                </option>
                {this.props.stages?.map((funnel) => (
                  <option value={funnel.id}>{funnel.name}</option>
                ))}
              </select>
              {this.props.errorMessage === true && this.props.stageId === "" ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please select a stage
                </div>
              ) : null}
              {(this.props.currentStageType?.type === "won" ||
                this.props.currentStageType?.type === "lost") && (
                <label>Close Reason</label>
              )}
              {this.props.currentStageType?.type === "won" ? (
                <select
                  name="funnelCloseReason"
                  onChange={this.props.changeHandler}
                  style={
                    this.props.errorMessage === true &&
                    this.props.funnelCloseReason === ""
                      ? { border: "1px solid red", width: "100%" }
                      : { width: "100%" }
                  }
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.props.closeReasonArray?.map((reason) => {
                    const { id, name, type } = reason;
                    const options =
                      type === "won" ? (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ) : null;
                    return options;
                  })}
                </select>
              ) : this.props.currentStageType?.type === "lost" ? (
                <select
                  name="funnelCloseReason"
                  onChange={this.props.changeHandler}
                  style={
                    this.props.errorMessage === true &&
                    this.props.funnelCloseReason === ""
                      ? { border: "1px solid red", width: "auto" }
                      : { width: "auto" }
                  }
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.props.closeReasonArray?.map((reason) => {
                    const { id, name, type } = reason;
                    const options =
                      type === "lost" ? (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ) : null;
                    return options;
                  })}
                </select>
              ) : null}
              {this.props.errorMessage === true &&
              this.props.funnelCloseReason === "" &&
              (this.props.currentStageType?.type === "won" ||
                this.props.currentStageType?.type === "lost") ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please select a reason
                </div>
              ) : null}
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  disabled={this.props.funnelIsLoading}
                  type="button"
                  className="modal__confirm-button"
                  onClick={this.props.submitHandler}
                >
                  {this.props.funnelIsLoading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default DealFunnelChangeModal;
