import React from "react";
import "../../sass/DropdownHeader.scss";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../redux";

class DropdownCustomRangeFilter extends React.Component {
  state = {
    displayMenu: false,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  render() {
    return (
      <div
        ref={this.wrapperRef}
        className="dropdownSchedule"
        style={{
          background: "transparent",
          width: "15px",
        }}
      >
        <ReactTooltip />
        <div
          className="button"
          onClick={this.showDropdownMenu}
          style={{
            fontSize: "15px",
            width: "120px",
            height: "20px",
            fontWeight: "500",
            marginTop: "3px",
          }}
          // data-tip="Schedule Send"
        >
          Custom Range{" "}
          <i
            className="fa fa-caret-down"
            aria-hidden="true"
            style={{ color: "#000" }}
          ></i>
        </div>

        {this.state.displayMenu ? (
          <ul
            className="dropdownSchedule-ul"
            style={{
              width: "auto",
              top: "30px",
              left: "0",
            }}
          >
            <li style={{ boxShadow: "0px 0px 4px rgba(13, 13, 13, 0.2)" }}>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "15px" }}>
                  <label style={{ textAlign: "left", fontWeight: "500" }}>
                    Date
                  </label>
                  <DatePicker
                    selected={this.props.fromDate}
                    todayButton="Today"
                    value={this.props.fromDate}
                    onChange={(date) =>
                      this.props.dateChangeHandler(date, "fromDate")
                    }
                    placeholderText="Date"
                    autoComplete="off"
                    dateFormat={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    style={
                      this.props.scheduleErrorMessage === true &&
                      (this.props.fromDate === null ||
                        this.props.fromDate === "")
                        ? {
                            border: "2px solid #F36363",
                            width: "130px",
                          }
                        : { width: "130px" }
                    }
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.props.scheduleErrorMessage === true &&
                    (this.props.fromDate === null || this.props.fromDate === "")
                      ? "Please select date for schedule"
                      : null}
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "15px",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginTop: "10px",
                  }}
                >
                  to
                </div>
                <div>
                  <label style={{ textAlign: "left", fontWeight: "500" }}>
                    Date
                  </label>
                  <DatePicker
                    selected={this.props.toDate}
                    todayButton="Today"
                    value={this.props.toDate}
                    onChange={(date) =>
                      this.props.dateChangeHandler(date, "toDate")
                    }
                    disabled={
                      this.props.fromDate === "" || this.props.fromDate === null
                    }
                    minDate={moment(this.props.fromDate).toDate()}
                    placeholderText="Date"
                    autoComplete="off"
                    dateFormat={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    style={
                      this.props.scheduleErrorMessage === true &&
                      (this.props.toDate === null || this.props.toDate === "")
                        ? {
                            border: "2px solid #F36363",
                            width: "130px",
                          }
                        : { width: "130px" }
                    }
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.props.scheduleErrorMessage === true &&
                    (this.props.toDate === null || this.props.toDate === "")
                      ? "Please select time for schedule"
                      : null}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="dropdownSchedule-buttonSchedule"
                onClick={(e) => {
                  this.props.submitHandler(true, e);
                  this.setState({
                    displayMenu: false,
                  });
                }}
                style={{ margin: "10px auto", display: "block" }}
              >
                Apply
              </button>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};
export default connect(MSP, MDP)(DropdownCustomRangeFilter);
