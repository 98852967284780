import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/CustomerModal.scss";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AccountIcon from "../../images/AccountIcon.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { colourStyles } from "../../constants/selectStyles";
import {
  isValidCurrency,
  getCurrencyValue,
} from "../../utils/Helper/reusableFunctions";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";

class CreateCarrierModal extends Component {
  state = {
    fieldsData: [],
    name: null,
    mc_number: null,
    status_id: null,
    ownerIds: [],
    email: null,
    phone_ext: null,
    formatted_phone: null,
    equipment_type: [],
    modes: [],
    specialRequirements: [],
    dot_number: null,
    fleet_size: null,
    description: null,
    homeBase: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    phoneValidationError: false,
    emailValidationError: false,
    customFieldRevenueTypeCheck: [],
    menuOpen: "bottom",
    carrierStatusArray: [],
    requiredFields: {
      name: { required: true },
      phone: { required: false },
      phone_ext: { required: false },
      email: { required: false },
      address: { required: false },
      city: { required: false },
      state: { required: false },
      zip: { required: false },
      country: { required: false },
      preferred_communication: { required: false },
      source: { required: false },
      website: { required: false },
      linkedin: { required: false },
      description: { required: false },
    },
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      name: null,
      mc_number: null,
      status: null,
      status_id: null,
      ownerIds: [],
      email: null,
      phone_ext: null,
      formatted_phone: null,
      equipment_type: [],
      modes: [],
      specialRequirements: [],
      dot_number: null,
      fleet_size: null,
      description: null,
      homeBase: {
        address: null,
        lat: null,
        long: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      isLoading: false,
      phoneValidationError: false,
      emailValidationError: false,
    });
  };
  fetchCarrierData = () => {
    this.setState({
      fieldsData: this.props.allCarrierCustomFields,
    });
    const customField = this.props.allCarrierCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allCarrierCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };
  fetchRequiredFields = () => {
    axios({
      method: "GET",
      url: `/admin/settings`,
    }).then((res) =>
      this.setState({
        requiredFields: res.data.settings.account,
      })
    );
  };
  getCarrierStatus = () => {
    this.setState({
      carrierStatusArray: this.props.allCarrierStatuses,
      status_id: this.props.allCarrierStatuses[0]?.id,
      status: this.props.allCarrierStatuses[0]?.name,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("mousemove", this.mouseCoordinates);
    this.fetchCarrierData();
    this.fetchRequiredFields();
    this.getCarrierStatus();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.fetchCarrierData();
      this.fetchRequiredFields();
      this.getCarrierStatus();
      if (this.state.carrierStatusArray.length) {
        this.setState({
          status_id: this.state.carrierStatusArray[0].id,
          status: this.state.carrierStatusArray[0].name,
        });
      }
    }
    if (
      prevState?.carrierStatusArray.length !==
      this.state.carrierStatusArray.length
    ) {
      this.setState({
        status_id: this.state.carrierStatusArray[0].id,
        status: this.state.carrierStatusArray[0].name,
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getFields = () => {
    this.state.fields.fields.map((item, i) => {
      return console.log(item.label);
    });
  };
  changeHandler = (e) => {
    const { value, name } = e.target;
    if (name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      const formattedValue = formatter.input(value); // Format the input value
      this.setState({
        [name]: formattedValue ? formattedValue : null,
      });
    } else {
      this.setState({
        [name]: value === "" ? null : value,
      });
    }
  };
  changeHandlerSelect = (name, value) => {
    this.setState({
      [name]: value.value,
    });
  };
  changeHandlerStatusSelect = (name, value) => {
    this.setState({
      [name]: value.label,
      status_id: value.value,
    });
  };
  defaultFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      [fieldName]: selectedOptions,
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (!this.state.ownerIds || this.state.ownerIds.length === 0) {
      this.setState({
        isLoading: false,
        errorMessage: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      emailValidator.test(this.state.email)
    ) {
      this.setState({
        emailValidationError: false,
      });
    }
    const data = this.state;
    if (data.name === null || data.name === "") {
      this.setState({ errorMessage: true });
      return;
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/carriers/create`,
      data: {
        name: this.state.name,
        mc_number: this.state.mc_number,
        status_id: this.state.status_id,
        owner_ids: this.state.ownerIds?.map((id) => id.value),
        email: this.state.email,
        phone_ext: this.state.phone_ext,
        formatted_phone: this.state.formatted_phone,
        equipment_type_ids: this.state.equipment_type?.map(
          (type) => type.value
        ),
        mode_ids: this.state.modes?.map((type) => type.value),
        requirement_type_ids: this.state.specialRequirements?.map(
          (type) => type.value
        ),
        dot_number: this.state.dot_number,
        fleet_size: this.state.fleet_size,
        description: this.state.description,
        city: this.state.homeBase.city,
        state: this.state.homeBase.state,
        zip: this.state.homeBase.zip,
        country: this.state.homeBase.country,
        address: this.state.homeBase.address,
        lat: this.state.homeBase.lat,
        long: this.state.homeBase.long,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "carrier",
          id: response.data.carrier.id,
          name: response.data.carrier.name,
        };

        this.props.setActiveTabIndex(this.props.totalTabs + 1);
        this.props.setTab(newTabData);
        this.props.history.push("/active-tabs");
        this.handleCloseModal();
      })
      .then((response) => {
        NotificationManager.success("Carrier created successfully!");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating carrier.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      })
      .finally(() => {
        //will uncomment it after API
        this.fetchCarrierData();
      });
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired
  ) => {
    let inputType;
    if (fieldType === "text") {
      inputType = (
        <input
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
          required={fieldRequired}
        />
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <input
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
          required={fieldRequired}
        />
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <input
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldRevenueChangeHandler}
          required={fieldRequired}
        />
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <textarea
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
          required={fieldRequired}
        ></textarea>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <input
          type="number"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
          required={fieldRequired}
        />
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <Select
          className="basic-single"
          classNamePrefix="select"
          styles={colourStyles}
          menuPlacement={this.state.menuOpen}
          required={fieldRequired}
          value={{
            value: this.state.customFields[fieldName],
            label: this.state.customFields[fieldName],
          }}
          placeholder="-Select-"
          onChange={(selected) =>
            this.customFieldSelectChangeHandler(fieldName, selected.value)
          }
          isClearable={false}
          isSearchable={true}
          name={fieldName}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value, label: value };
          })}
        />
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px" }}>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={this.customFieldChangeHandler}
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          name={fieldName}
          selected={Date.parse(this.state.customFields[fieldName])}
          value={Date.parse(this.state.customFields[fieldName])}
          onChange={(date) =>
            this.customFieldDateChangeHandler(fieldName, date)
          }
          dateFormat={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
          required={fieldRequired}
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      inputType = (
        <Select
          value={multiSelectDropdownValue}
          closeMenuOnSelect={false}
          menuPlacement={this.state.menuOpen}
          isMulti
          name={fieldName}
          styles={colourStyles}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value: value, label: value };
          })}
          placeholder="-Select-"
          className="basic-multi-select"
          classNamePrefix="select"
          required={fieldRequired}
          onChange={(selectedOption) =>
            this.customFieldMultiDropdownChangeHandler(
              fieldName,
              selectedOption
            )
          }
        />
      );
    }
    return (
      <div>
        <label>
          {fieldLabel}{" "}
          {fieldRequired && (
            <span
              style={{
                color: "#4A9876",
                fontSize: "17px",
                lineHeight: "16px",
              }}
            >
              *
            </span>
          )}
        </label>
        {inputType}
      </div>
    );
  };

  homeBaseChangeHandler = (homeBase) => {
    this.setState({
      homeBase,
    });
  };
  render() {
    const { name, email, formatted_phone, phone_ext, description } =
      this.state;
    const { fieldsData } = this.state;
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };

    return (
      <ReactModal
        isOpen={this.props.showOpportunityModal}
        contentLabel="onRequestClose Example"
        onRequestClose={this.handleCloseModal}
        ariaHideApp={false}
        style={modalStyle}
      >
        <div className="modal">
          <div className="modal__content">
            <div className="modal__header">
              <span>
                <div className="customerInfo-icon">
                  <img
                    src={AccountIcon}
                    alt=""
                    width="17px"
                    style={{ transform: "translateY(9px)" }}
                  />
                </div>
                <NotificationContainer />
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Create a Carrier</h2>
                <p className="modal__heading-desc">
                  Add some information about the carrier
                </p>
              </div>
              <button
                onClick={this.handleCloseModal}
                className="modal__close"
                style={{
                  border: "none",
                  background: "transparent",
                  height: "12px",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <form
              className="modal__form"
              style={{
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <label>
                Carrier Owner{" "}
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              </label>
              <Select
                value={this.state.ownerIds}
                closeMenuOnSelect={false}
                isMulti
                name={"ownerIds"}
                styles={colourStyles}
                options={this.props.allUsers?.map((user) => {
                  const { name, id } = user;
                  return { value: id, label: name };
                })}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                placeholder="-Select-"
                className="basic-multi-select"
                classNamePrefix="select"
                required={true}
                onChange={(selectedOption) =>
                  this.defaultFieldMultiDropdownChangeHandler(
                    "ownerIds",
                    selectedOption
                  )
                }
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.ownerIds === null ||
                  this.state.ownerIds.length === 0)
                  ? "Please select carrier owner"
                  : null}
              </div>
              <label>
                Carrier Name{" "}
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              </label>
              <input
                type="text"
                name="name"
                value={name}
                required
                onChange={this.changeHandler}
                style={
                  this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? {
                        border: "2px solid #F36363",
                      }
                    : {}
                }
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.name === null || this.state.name === "")
                  ? "Name is required"
                  : null}
              </div>
              <label>
                MC#{" "}
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              </label>
              <input
                type="number"
                name="mc_number"
                value={this.state.mc_number}
                required
                onChange={this.changeHandler}
                style={
                  this.state.errorMessage === true &&
                  (this.state.mc_number === null || this.state.mc_number === "")
                    ? {
                        border: "2px solid #F36363",
                      }
                    : {}
                }
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.mc_number === null || this.state.mc_number === "")
                  ? "MC# is required"
                  : null}
              </div>
              <label>DOT#</label>
              <input
                type="number"
                name="dot_number"
                value={this.state.dot_number}
                onChange={this.changeHandler}
              />
              <label>Home Base </label>
              <GoogleAutocompleteComponent
                placeholder=""
                onPlaceSelect={this.homeBaseChangeHandler}
              />
              <label>Phone number </label>
              <input
                type="tel"
                name="formatted_phone"
                value={formatted_phone}
                onChange={this.changeHandler}
              />
              <label>
                Phone Extension{" "}
                {this.state.requiredFields?.["phone_ext"]?.required && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
              <input
                type="text"
                name="phone_ext"
                value={phone_ext}
                onChange={this.changeHandler}
                required={this.state.requiredFields?.["phone_ext"]?.required}
              />
              <label>Email </label>
              <input
                pattern='/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'
                type="email"
                name="email"
                value={email}
                onChange={this.changeHandler}
              />
              <label>Equipment Types</label>
              <Select
                value={this.state.equipment_type}
                closeMenuOnSelect={false}
                isMulti
                name={"equipment_type"}
                styles={colourStyles}
                options={this.props.allEquipmentTypes?.map((type) => {
                  return { value: type.id, label: type.name };
                })}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                placeholder="-Select-"
                className="basic-multi-select"
                classNamePrefix="select"
                required={true}
                onChange={(selectedOption) =>
                  this.defaultFieldMultiDropdownChangeHandler(
                    "equipment_type",
                    selectedOption
                  )
                }
              />
              <label>Modes</label>
              <Select
                value={this.state.modes}
                closeMenuOnSelect={false}
                isMulti
                name={"modes"}
                styles={colourStyles}
                options={this.props.allModes?.map((type) => {
                  return { value: type.id, label: type.name };
                })}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                placeholder="-Select-"
                className="basic-multi-select"
                classNamePrefix="select"
                required={true}
                onChange={(selectedOption) =>
                  this.defaultFieldMultiDropdownChangeHandler(
                    "modes",
                    selectedOption
                  )
                }
              />
              <label>Special Requirements</label>
              <Select
                value={this.state.specialRequirements}
                closeMenuOnSelect={false}
                isMulti
                name={"specialRequirements"}
                styles={colourStyles}
                options={this.props.allSpecialRequirements?.map((type) => {
                  return { value: type.id, label: type.name };
                })}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                placeholder="-Select-"
                className="basic-multi-select"
                classNamePrefix="select"
                required={true}
                onChange={(selectedOption) =>
                  this.defaultFieldMultiDropdownChangeHandler(
                    "specialRequirements",
                    selectedOption
                  )
                }
              />
              <label>Status</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                styles={colourStyles}
                menuPlacement={this.state.menuOpen}
                value={{
                  value: this.state.status_id,
                  label: this.state.status,
                }}
                placeholder="-Select-"
                onChange={(selected) =>
                  this.changeHandlerStatusSelect("status", selected)
                }
                isClearable={false}
                isSearchable={true}
                options={this.state.carrierStatusArray?.map((status) => ({
                  value: status.id,
                  label: status.name,
                }))}
              />
              <label>Fleet Size </label>
              <input
                type="number"
                name="fleet_size"
                value={this.state.fleet_size}
                onChange={this.changeHandler}
              />
              <label>Description </label>
              <textarea
                name="description"
                value={description}
                onChange={this.changeHandler}
                style={{ fontFamily: "inherit" }}
              ></textarea>
              {fieldsData.map((group) => {
                const { label, fields, meta, id } = group;
                return (
                  <div key={id}>
                    {meta === null ? (
                      <div>
                        <h3 className="modal__heading-sub">{label}</h3>
                        {fields?.map((field) => {
                          const { id, label, type, choices, name, required } =
                            field;
                          return (
                            <div key={id}>
                              {this.showInput(
                                name,
                                label,
                                type,
                                choices,
                                required
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </form>
            <div className="modal__buttons">
              <button
                onClick={this.handleCloseModal}
                type="reset"
                className="modal__cancel-button"
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={this.state.isLoading}
                onClick={this.submitHandler}
              >
                {this.state.isLoading ? "Saving" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allCarrierStatuses: state.allCarrierStatuses.carrierStatuses,
    allCarrierCustomFields: state.allCarrierCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(withRouter(CreateCarrierModal));
