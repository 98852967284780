import axios from "axios";
import { useState } from "react";
import ReactModal from "react-modal";
import { NotificationManager } from "react-notifications";

const ConnectApolloModal = ({
  showModal,
  handleCloseModal,
  modalHeight,
  modalTop,
  disconnectApollo,
  apolloLoading: loading,
  setApolloLoading: setLoading,
}) => {
  const [apiKey, setApiKey] = useState("");
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };

  const submitHandler = () => {
    if (!apiKey || apiKey === "") {
      setLoading(false);
      return false;
    }
    setLoading(true);
    axios({
      method: "POST",
      url: `/apollo/set-api-key`,
      data: {
        api_key: apiKey,
      },
    })
      .then((res) => {
        disconnectApollo(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Error connecting to Apollo. Please recheck your API key."
        );
      });
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Connect Apollo</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <label>
              Enter API key - Visit{" "}
              <a href="https://developer.apollo.io/keys/" target="_blank">
                https://developer.apollo.io/keys/
              </a>
            </label>
            <input value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={loading}
                onClick={submitHandler}
              >
                {loading ? "Confirming" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default ConnectApolloModal;
