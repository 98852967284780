export const defaultDealFields = {
  field_class: "opportunity",
  fields: [
    {
      choices: [],
      field_class: "opportunity",
      id: "d-opportunity_owner_id",
      label: "Deal Owner",
      name: "opportunity_owner_id",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "opportunity",
      id: "d-account_id",
      label: "Account",
      name: "account_id",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "text",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "opportunity",
      id: "d-name",
      label: "Deal Name",
      name: "name",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "text",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "opportunity",
      id: "d-Revenue",
      label: "Revenue",
      name: "revenue",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "revenue",
      visible: null,
    },
    {
      choices: [],
      field_class: "opportunity",
      id: "d-close_date",
      label: "Close Date",
      name: "close_date",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "date",
      visible: null,
    },
    {
      choices: [],
      field_class: "opportunity",
      id: "d-funnel_id",
      label: "Funnel",
      name: "funnel_id",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "opportunity",
      id: "d-stage_id",
      label: "Stage",
      name: "stage_id",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "opportunity",
      id: "d-Source",
      label: "Source",
      name: "source",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-Description",
      label: "Description",
      name: "description",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "paragraph",
      visible: null,
    },
  ],
  id: "default_fields",
  label: "Default Fields",
  meta: null,
  name: "default_fields",
  placeholder: null,
  position: 0,
  type: "section",
};
