import React from "react";
import AppRouter from "./routers/AppRouter";
import SignIn from "./components/SignIn";
import * as ROUTES from "./constants/routes";

// Utility
import withAxios from "./HOCs/Interceptor/withAxios";
// HOCs
import withAuthentication from "./HOCs/Session/withAuthentication";

// libraries
import { BrowserRouter as Router, Route } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
// import { authUserSet } from "./redux";
// import SignUp from "./components/SignUp";
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";
import "./sass/Buttons.scss";

const App = (props) => {
  return (
    <div>
      {!props.authUser ? (
        <>
          <Router>
            <Route exact path={ROUTES.LANDING} component={SignIn} />
            {/* <Route path='*' render={() => <Redirect to={ROUTES.LANDING} />} /> */}
            <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          </Router>
        </>
      ) : (
        <AppRouter />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.login.authUser,
  };
};

export default compose(
  withAuthentication,
  withAxios,
  connect(mapStateToProps)
)(App);
