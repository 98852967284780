import React, { Component } from "react";
import XLSX from "xlsx";
import axios from "axios";
import { BASE_URL } from "../../config";
import _ from "lodash";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";
import YoutubePlayer from "../YoutubePlayer";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import RedirectToBillingModal from "./RedirectToBillingModal";

class ImportDeal extends Component {
  state = {
    format: "",
    data: [],
    fieldsData: [],
    headers: [],
    headersMapping: {},
    rows: [],
    isLoading: false,
    erroredData: [],
    totalCreated: 0,
    totalFailed: 0,
    submitted: false,
    showYoutubeVideo: false,
    reportCreated: false,
    reportData: {},
    reportError: false,
    showRedirectModal: false,
    recordsLimit: 50000,
    recordLimitCheck: false,
  };
  fileInput = React.createRef();
  handleCloseRedirectModal = () => {
    this.setState({
      showRedirectModal: false,
    });
  };
  handleOpenRedirectModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  getRecordsLimit = () => {
    axios({
      method: "GET",
      url: `/company/check-records-limit`,
    }).then((res) => {
      this.setState({
        recordsLimit: res.data.records_limit - res.data.total_records_created,
        recordLimitCheck: true,
      });
    });
  };
  componentDidMount() {
    this.getRecordsLimit();
    axios({
      method: "GET",
      url: `${BASE_URL}/fields/opportunity`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((response) => {
        this.setState({
          fieldsData: response.data.fields,
        });
      })
      .catch((error) => console.log("error", error));
  }
  headersChangeHandler = (sheetHeader, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      headers: [...prevState.headers, value],
      headersMapping: { ...prevState.headersMapping, [sheetHeader]: value },
    }));
  };
  showRows = () => {
    this.setState((prevState) => ({
      rows: this.state.data.filter((d) => Object.keys(this.state.headers)),
    }));
  };
  createReport = (batch) => {
    const date = new Date();
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const myDate = moment(date).format(dateFormat);
    axios({
      method: "POST",
      url: `/reports`,
      data: {
        name: `Deals imported on ${myDate}`,
        type: "opportunities",
        description: null,
        access: "everyone",
        filters: [
          {
            attribute: "batch",
            operator: "is",
            value: batch.toString(),
          },
        ],
        columns: [
          "opportunity_owner",
          "name",
          "stage_name",
          "revenue",
          "next_step",
        ],
      },
    })
      .then((res) => {
        NotificationManager.success("Import report created");
        let newTabData = {
          type: "report",
          id: res.data.reports.id,
          name: res.data.reports.name,
        };
        this.setState({
          reportCreated: true,
          reportData: newTabData,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error creating report");
        this.setState({
          reportCreated: true,
          reportError: true,
        });
      });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const batch = Date.now();

    if (!Object.values(this.state.headersMapping).includes("close_date")) {
      NotificationManager.error("Close Date field is mandatory");
      return false;
    }
    if (!Object.values(this.state.headersMapping).includes("stage_id")) {
      NotificationManager.error("Stage field is mandatory");
      return false;
    }
    if (
      Object.values(this.state.headersMapping).includes("stage_id") &&
      !Object.values(this.state.headersMapping).includes("funnel_id")
    ) {
      NotificationManager.error(
        "Funnel field is mandatory when selecting stage"
      );
      return false;
    }
    if (!Object.values(this.state.headersMapping).includes("revenue")) {
      NotificationManager.error("Revenue field is mandatory");
      return false;
    }
    if (!Object.values(this.state.headersMapping).includes("name")) {
      NotificationManager.error("Deal Name field is mandatory");
      return false;
    }
    // if (!Object.values(this.state.headersMapping).includes("account_email")) {
    //   NotificationManager.error("Account Email field is mandatory");
    //   return false;
    // }

    this.setState({ isLoading: true });
    function isUnixTimestamp(value) {
      return (
        typeof value === "number" &&
        ((value >= 1000000000 && value <= 9999999999) ||
          (value >= 1000000000000 && value <= 9999999999999))
      );
    }

    // Function to check if a value is an Excel serial date
    function isExcelSerialDate(value) {
      return typeof value === "number" && value > 40000 && value < 60000;
    }

    // Function to check if a value is likely a phone number (e.g., 7 to 15 digits)
    function isPhoneNumber(value) {
      return (
        typeof value === "number" &&
        String(value).length >= 7 &&
        String(value).length <= 15
      );
    }

    // Function to convert Excel serial date to Unix timestamp (in seconds)
    function excelDateToUnix(excelDate) {
      return Math.floor((excelDate - 25569) * 86400);
    }

    // Function to check if a string is a valid date
    function isValidDateString(dateString) {
      // Check using Date.parse which returns NaN for invalid dates
      return !isNaN(Date.parse(dateString));
    }

    // Function to convert date string to Unix timestamp
    function dateStringToUnix(dateString) {
      const parsedDate = new Date(dateString);
      return Math.floor(parsedDate.getTime() / 1000); // Return Unix timestamp in seconds
    }

    // Main function to convert various date formats to Unix
    function convertToUnixIfDate(value) {
      // Ignore phone numbers and other non-date numbers
      if (isPhoneNumber(value)) {
        return value; // Return as-is, as it's not a date
      }

      // Case 1: Check if the value is a Unix timestamp (seconds or milliseconds)
      if (isUnixTimestamp(value)) {
        return value; // It's already in Unix format
      }

      // Case 2: Check if the value is an Excel serial date
      if (isExcelSerialDate(value)) {
        return excelDateToUnix(value); // Convert Excel serial date to Unix
      }

      // Case 3: Check if the value is a valid date string (ISO, YYYY-MM-DD, etc.)
      if (typeof value === "string" && isValidDateString(value)) {
        return dateStringToUnix(value); // Convert date string to Unix
      }

      // Case 4: if its another string then we want replace newline with spaces
      if (typeof value === "string" && !isValidDateString(value)) {
        return value.replace(/\r?\n|\r/g, " ");
      }

      // If the value is not a date, return it as is
      return value;
    }

    let dataChunks = _.chunk(
      this.state.data.map((obj) =>
        Object.keys(this.state.headersMapping)
          .filter((key) => this.state.headersMapping[key] !== "")
          .map((key) =>
            key?.toLowerCase() === "revenue"
              ? obj[key]
              : !isNaN(obj[key]) &&
                obj[key] > 4999 &&
                obj[key] < 100000 &&
                Date.parse(obj[key])
              ? moment(Math.round((obj[key] - 25569) * 86400 * 1000)).format(
                  "YYYY-MM-DD"
                )
              : obj[key]
          )
          .map((cell) => {
            console.log("cell check", cell);
            return convertToUnixIfDate(cell);
          })
      ),
      100
    );
    let totalCreated = 0,
      totalFailed = 0;
    let counter = 0;
    let headers = [];
    let erroredData = [];
    dataChunks.forEach(async (chunk, i) => {
      const res = await axios({
        method: "POST",
        url: `/opportunities/import`,
        data: {
          headers: Object.values(this.state.headersMapping).filter(
            (value) => value !== ""
          ),
          rows: chunk,
          batch: batch.toString(),
        },
      });
      totalCreated += await res.data.total_created;
      totalFailed += await res.data.total_failed;
      headers = await res.data.headers;
      console.log("$$ headers:", headers);
      erroredData = await [...erroredData, ...res.data.error_data];
      console.log("$$ erroredData:", erroredData);
      counter++;
      console.log("import acc res", res);

      console.log("import acc resxxx", counter, dataChunks.length);
      if (counter === dataChunks.length) {
        // NotificationManager.success(`Total Created : ${totalCreated}`);
        // NotificationManager.error(`Total Failed : ${totalFailed}`);
        this.setState({
          totalCreated,
          totalFailed,
          data: [],
          headers: [],
          headersMapping: {},
          submitted: true,
        });

        if (erroredData) {
          console.log("MEME", [headers, ...erroredData]);

          this.setState({ erroredData: [headers, ...erroredData] });
        }

        this.setState({ isLoading: false });
      }
    });
    this.createReport(batch);
    this.getRecordsLimit();
  };

  OnDrop = (file, e) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        defval: null,
      });
      if (data) {
        if (data.length > this.state.recordsLimit) {
          this.handleOpenRedirectModal();
          return;
        }
        console.log("json import", data);
        this.setState({
          data,
        });
      }
    };
    reader.readAsBinaryString(file.files[0]);
  };
  downloadFailedImportData = () => {
    let errorDataInExcel = XLSX.utils.aoa_to_sheet(this.state.erroredData);
    let errorDataInCSV = XLSX.utils.sheet_to_csv(errorDataInExcel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, errorDataInExcel, "Failed");

    console.log("errorDataInCSV:", errorDataInCSV);
    return XLSX.writeFile(wb, "FailedImports.xlsx");

    // return XLSX.writeFile(this.state.errorDataInCSV, "failedImports.xlsx", {
    //   bookType: "csv",
    //   bookSST: true,
    //   type: "base64",
    // });
  };

  render() {
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    console.log("$$ this.state.headersMapping:", this.state.headersMapping);
    console.log(
      "$$ this.state.headersMapping keys:",
      Object.keys(this.state.headersMapping)
    );
    console.log(
      "$$ this.state.headersMapping values:",
      Object.values(this.state.headersMapping)
    );
    console.log("$$ this.state.headers:", this.state.headers);
    console.log("$$ this.state.data:", this.state.data);
    console.log(
      "$$ this.state.data testing:",
      this.state.data.map((obj) =>
        Object.keys(this.state.headersMapping).map((key) => obj[key])
      )
    );

    let dynamicFields = this.state.fieldsData
      .map((data) => data.fields)
      .flat(1)
      .reduce((obj, item) => ((obj[item.name] = item.label), obj), {});

    console.log("this.state.fieldsDat moddeda", dynamicFields);

    let headerKeyValueMappings = {
      opportunity_owner: "Deal Owner",
      name: "Deal Name",
      account_name: "Account Name",
      contact_name: "Contact Name",
      revenue: "Revenue",
      funnel_id: "Funnel",
      stage_id: "Stage",
      close_date: "Close Date",
      description: "Description",
      close_reason: "Close Reason",
      account_email: "Account Email",
      contact_email: "Contact Email",
      ...dynamicFields,
    };
    console.log("========= headerKeyValueMappings:", headerKeyValueMappings);
    return (
      <div style={{ margin: "auto 30px" }}>
        {this.state.showYoutubeVideo && (
          <YoutubePlayer
            src="https://www.youtube.com/embed/AZFj07YaQlc"
            closeIframe={() => this.setState({ showYoutubeVideo: false })}
          />
        )}
        <RedirectToBillingModal
          showOpportunityModal={this.state.showRedirectModal}
          setShowOpportunityModal={this.handleOpenRedirectModal}
          handleCloseModal={this.handleCloseRedirectModal}
          userRole={this.props.authUser.role}
        />
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              borderBottom: "1px solid #E3E3E3",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <h2 style={{ marginBottom: "0px" }}>
                Import Deal
                <button
                  type="button"
                  onClick={() => this.setState({ showYoutubeVideo: true })}
                  className="button-no-border-red"
                  style={{ color: "#61c99d", marginLeft: "20px" }}
                >
                  <i className="fa fa-video-camera" aria-hidden="true"></i>
                  &nbsp; Walk-through video
                </button>
              </h2>
              <p>
                Please upload a .csv or .xlsx file to import and map the fields
                for the data
              </p>
            </div>
            {this.fileInput?.current?.files[0] && (
              <div
                style={{
                  marginTop: "4vh",
                  float: "right",
                  marginRight: "30px",
                }}
              >
                <button
                  type="submit"
                  className="button-md"
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Please Wait..." : "Start Upload"}
                </button>
              </div>
            )}
          </div>
          <label
            for="file-upload"
            style={{
              textDecoration: "none",
              background: !this.state.recordLimitCheck ? "#eee" : "#FFFFFF",
              color: "#3AAB7B",
              padding: "10px 24px",
              borderRadius: "4px",
              border: "1px solid #3AAB7B",
              cursor: "pointer",
              display: "inline-block",
              marginRight: "10px",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "150%",
            }}
          >
            {!this.state.recordLimitCheck
              ? "Checking Records Limit"
              : this.fileInput?.current?.files[0]?.name
              ? "File Name"
              : "Choose File"}
          </label>
          <input
            style={{ display: "none" }}
            disabled={!this.state.recordLimitCheck}
            id="file-upload"
            type="file"
            ref={this.fileInput}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={() => this.OnDrop(this.fileInput.current)}
          />
          {this.fileInput?.current?.files[0]?.name}
        </form>
        <div style={{ padding: "5px" }}>
          {this.state.data.length > 0 &&
            Object.keys(this.state.data[0]).map((key, i) => (
              <div
                key={i}
                style={{
                  display: "inline-block",
                  border: "2px solid #E3E3E3",
                  borderRadius: "8px",
                  margin: "10px",
                  padding: "15px",
                }}
              >
                <span>
                  <h4
                    style={{
                      margin: "6px auto",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    Sheet field
                  </h4>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    {key}
                  </div>
                </span>
                <h4
                  style={{
                    margin: "6px auto",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                    color: "#0C0D0D",
                  }}
                >
                  Salesdash field
                </h4>
                <select
                  name="headers"
                  onChange={(e) => this.headersChangeHandler(key, e)}
                  style={{
                    border: "1px solid #A7ABAA",
                    borderRadius: "8px",
                    padding: "7px",
                    maxWidth: "210px",
                  }}
                >
                  <option value="">Do not import</option>
                  <optgroup label="Default Fields">
                    {(!Object.values(this.state.headersMapping).includes(
                      "opportunity_owner"
                    ) ||
                      this.state.headersMapping[key] ===
                        "opportunity_owner") && (
                      <option value="opportunity_owner">Deal Owner</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "name"
                    ) ||
                      this.state.headersMapping[key] === "name") && (
                      <option value="name">Deal Name</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "account_name"
                    ) ||
                      this.state.headersMapping[key] === "account_name") && (
                      <option value="account_name">Account Name</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "contact_name"
                    ) ||
                      this.state.headersMapping[key] === "contact_name") && (
                      <option value="contact_name">Contact Name</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "revenue"
                    ) ||
                      this.state.headersMapping[key] === "revenue") && (
                      <option value="revenue">Revenue</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "funnel_id"
                    ) ||
                      this.state.headersMapping[key] === "funnel_id") && (
                      <option value="funnel_id">Funnel</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "stage_id"
                    ) ||
                      this.state.headersMapping[key] === "stage_id") && (
                      <option value="stage_id">Stage</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "close_date"
                    ) ||
                      this.state.headersMapping[key] === "close_date") && (
                      <option value="close_date">Close Date</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "description"
                    ) ||
                      this.state.headersMapping[key] === "description") && (
                      <option value="description">Description</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "close_reason"
                    ) ||
                      this.state.headersMapping[key] === "close_reason") && (
                      <option value="close_reason">Close Reason</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "account_email"
                    ) ||
                      this.state.headersMapping[key] === "account_email") && (
                      <option value="account_email">Account Email</option>
                    )}
                    {(!Object.values(this.state.headersMapping).includes(
                      "contact_email"
                    ) ||
                      this.state.headersMapping[key] === "contact_email") && (
                      <option value="contact_email">Contact Email</option>
                    )}
                  </optgroup>
                  {this.state.fieldsData.map((group) => {
                    const { label, fields, meta } = group;
                    return (
                      (meta === null || meta.visible_in_form === true) && (
                        <optgroup label={label}>
                          {fields?.map((field) => {
                            const { id, label, name } = field;
                            return (
                              (!Object.values(
                                this.state.headersMapping
                              ).includes(name) ||
                                this.state.headersMapping[key] === name) && (
                                <option key={id} value={name}>
                                  {label}
                                </option>
                              )
                            );
                          })}
                        </optgroup>
                      )
                    );
                  })}
                </select>
              </div>
            ))}
        </div>
        <table className="accountList__table">
          <thead>
            <tr>
              {Object.values(this.state.headersMapping)
                .filter((header) => header !== "")
                .map((header) => (
                  <th>{headerKeyValueMappings[header]}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {this.state.data
              .slice(0, 5)
              .filter((key) => this.state.headersMapping[key] !== "")
              .map((obj) => (
                <tr>
                  {Object.keys(this.state.headersMapping).map((key) => {
                    console.log("hassan table", key, obj, obj[key]);
                    return key?.toLowerCase() === "revenue" ? (
                      <td>{obj[key]}</td>
                    ) : !isNaN(obj[key]) &&
                      obj[key] > 4999 &&
                      obj[key] < 100000 &&
                      Date.parse(obj[key]) ? (
                      <td>
                        {moment(
                          Math.round((obj[key] - 25569) * 86400 * 1000)
                        ).format(dateFormat)}
                      </td>
                    ) : (
                      <td>{obj[key]}</td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
        {this.state.submitted && (
          <>
            <p
              style={{
                fontSize: "1.5rem",
              }}
            >
              Import Summary
            </p>

            <p
              style={{
                fontSize: "1rem",
              }}
            >
              <b>Total Created:</b> {this.state.totalCreated}{" "}
            </p>
            <p
              style={{
                fontSize: "1rem",
              }}
            >
              <b>Total Failed:</b> {this.state.totalFailed}{" "}
            </p>
            <button
              type="button"
              className="button-md"
              style={{ display: "inline-block" }}
              disabled={!this.state.reportCreated}
              onClick={() => {
                if (this.state.reportError) {
                  this.props.history.push("/deals");
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(this.state?.reportData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              Proceed
            </button>
            {this.state.totalFailed > 0 && (
              <div style={{ display: "inline-block" }}>
                <button
                  type="button"
                  style={{
                    textDecoration: "none",
                    background: "#FFFFFF",
                    color: "#3AAB7B",
                    padding: "10px 24px",
                    borderRadius: "4px",
                    border: "1px solid #3AAB7B",
                    cursor: "pointer",
                    display: "inline-block",
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                  }}
                  onClick={this.downloadFailedImportData}
                >
                  Download Failed imports
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ImportDeal);
