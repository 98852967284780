import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";

function DealsScoreCard({
  filter_rules,
  fromDashboard,
  setSegmentByDashboard,
  mode,
  segmentByDashboard,
  viewDashboard,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [segmentBy, setSegmentBy] = useState("stage");
  useEffect(() => {
    const getData = () => {
      let newValue = [];
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        return {
          attribute,
          operator,
          value,
        };
      });
      setLoading(true);
      let url = `/charts/opportunity-chart`;
      axios({
        method: "POST",
        url,
        data: {
          chart: {
            type: "scorecard",
            metric: segmentBy,
          },
          filter_rules: newValue,
        },
      })
        .then((res) => {
          setLoading(false);
          setData(res.data.opportunities);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getData();
    fromDashboard && setSegmentByDashboard(segmentBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_rules, segmentBy]);
  useEffect(() => {
    mode === "edit" &&
      viewDashboard === "scorecard" &&
      setSegmentBy(segmentByDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <label>Segment By</label>
      <select value={segmentBy} onChange={(e) => setSegmentBy(e.target.value)}>
        <option value="stage">Stage</option>
        <option value="opportunity_generated">Deal Generated</option>
        <option value="revenue_generated">Revenue</option>
        <option value="avg_deal_size">Average Deal Size</option>
      </select>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <div>
          <h3
            style={{ fontSize: "22px", fontWeight: "400", textAlign: "center" }}
          >
            Score Card
          </h3>
          <Row>
            <Col>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  paddingTop: "70px",
                  textAlign: "center",
                }}
              >
                <h4 style={{ fontSize: "16px", fontWeight: "400" }}>
                  Total Count
                </h4>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "100px",
                    textAlign: "center",
                    color: "#4a9876",
                  }}
                >
                  {data.length > 0
                    ? segmentBy === "revenue_generated"
                      ? data[0]?.revenue
                      : segmentBy === "avg_deal_size"
                      ? data[0]?.avg_deal_size
                      : data[0]?.count
                    : 0}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default DealsScoreCard;
