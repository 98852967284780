import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import SubscriptionCancelConfirm from "../../images/BillingSettingsIcon.svg";

class BillingCreditModal extends Component {
  state = {
    showModal: true,
    isLoading: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };

  purchaseCredits = () => {
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/payments/postmark-email-payment`,
      data: {},
    })
      .then((res) => {
        NotificationManager.success("Credits purchased successfully.");
        this.setState({ isLoading: false });
        this.props.checkUserProfile();
        this.props.handleCloseModal();
        this.props.handleEmailOpenModal && this.props.handleEmailOpenModal();
      })
      .catch((err) => {
        NotificationManager.error("Error purchasing credits.");
        this.setState({ isLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "32vh", top: "23%", textAlign: "center" }}
            >
              <div className="modal__header" style={{ textAlign: "left" }}>
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{ background: "transparent" }}
                  >
                    <img
                      src={SubscriptionCancelConfirm}
                      alt=""
                      width="20px"
                      style={{ transform: "translateY(14px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head">
                  <h2
                    className="modal__heading"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    Email Marketing Credits
                  </h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    height: "12px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label
                style={{
                  fontSize: "16px",
                  color: "#0C0D0D",
                  margin: "20px 0",
                }}
              >
                Salesdash allows you to send 5000 bulk emails for $10. Would you
                like to complete this purchase to activate bulk emailing?
              </label>
              <div style={{ marginTop: "25px" }}>
                <button
                  disabled={this.state.isLoading}
                  onClick={this.purchaseCredits}
                  className="button-md"
                  style={{
                    height: "48px",
                    fontSize: "18px",
                  }}
                >
                  {this.state.isLoading ? "Purchasing" : "Purchase"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default BillingCreditModal;
