import React from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { NotificationManager } from "react-notifications";

class DeleteContactModal extends React.Component {
  state = {
    showModal: true,
    isLoading: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  deleteAccount = () => {
    this.setState({ isLoading: true });
    axios({
      method: "DELETE",
      url: `/contacts/${this.props.contactId}`,
    })
      .then((res) => {
        this.props.deleteTab(this.props.tabIndex);
        NotificationManager.success("Contact deleted successfully.");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        NotificationManager.error("Failed to delete contact.");
        this.setState({ isLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "30vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Contact</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form>
                <p>
                  Are you sure you want to permanently delete this contact?
                  <br />
                  Changes will not be restored
                </p>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="modal__confirm-button"
                    onClick={this.deleteAccount}
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Deleting" : "Delete"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default DeleteContactModal;
