import React from "react";
import "./DashboardLoader.scss";

const DashboardLoader = () => {
  return (
    <>
      <div className="dashboard-loader-gradient dashboard-loader-element1"></div>
      <div className="dashboard-loader-gradient dashboard-loader-element2"></div>
      <div className="dashboard-loader-gradient dashboard-loader-element3"></div>
      <div className="dashboard-loader-gradient dashboard-loader-element4"></div>
      <div className="dashboard-loader-gradient dashboard-loader-element5"></div>
    </>
  );
};

export default DashboardLoader;
