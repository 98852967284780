import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DashboardLoader from "../../Loaders/DashboardLoader";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../../redux";

const UnassignedNext7Days = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    axios({
      method: "GET",
      url: `/accounts/shot-clock/upcoming-accounts?user_ids=${props.userId}`,
    })
      .then((res) => {
        setAccounts(res.data.accounts);
        setIsInitializing(false);
      })
      .catch((err) => {
        setAccounts([]);
        setIsInitializing(false);
      });
  }, [props.userId]);
  return (
    <div style={{ paddingTop: "55px" }}>
      <h3
        className="funnel-heading-sub"
        style={{
          marginBottom: "0",
          zIndex: "1",
        }}
      >
        Leads Needing Activity (Unassigned Risk)
      </h3>
      {isInitializing === true ? (
        <DashboardLoader />
      ) : isInitializing === false && accounts?.length === 0 ? (
        <div
          style={{
            margin: "auto",
            fontWeight: "500",
          }}
        >
          No records found.
        </div>
      ) : (
        <div
          style={{
            overflow: "auto",
            maxHeight: props.maxHeight,
            ...(props.maxWidth && {
              maxWidth: props.maxWidth,
            }),
          }}
        >
          <table className="accountList__table" style={{ marginTop: "0" }}>
            <thead>
              <th>Name</th>
              <th>Owner</th>
              <th>status</th>
              <th>Owner Assigned At</th>
              <th>Days Left</th>
            </thead>
            <tbody>
              {accounts?.map((account) => {
                let newTabData = {
                  type: "account",
                  id: account.id,
                  name: account.name,
                };
                return (
                  <tr key={account.id}>
                    <td>
                      <span
                        style={{ cursor: "pointer", color: "#41BE89" }}
                        onClick={(e) => {
                          if (e.metaKey || e.ctrlKey) {
                            props.setTab({ ...newTabData, blank: true });
                          } else {
                            props.setActiveTabIndex(props.totalTabs + 1);
                            props.setTab(newTabData);
                            props.history.push("/active-tabs");
                          }
                        }}
                      >
                        {account.name}
                      </span>
                    </td>
                    <td>{account.account_owner}</td>
                    <td>{account.status}</td>
                    <td>{moment(account.owner_assigned_at).format("l")}</td>
                    <td>{account.days_left}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};
export default connect(MSP, MDP)(UnassignedNext7Days);
