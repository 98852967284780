import {
  FETCH_CONTACT_FIELDS_REQUEST,
  FETCH_CONTACT_FIELDS_SUCCESS,
  FETCH_CONTACT_FIELDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllFormContactFieldsRequest = () => {
  return {
    type: FETCH_CONTACT_FIELDS_REQUEST,
  };
};
const fetchAllFormContactFieldsSuccess = (data) => {
  return {
    type: FETCH_CONTACT_FIELDS_SUCCESS,
    payload: data,
  };
};
const fetchAllFormContactFieldsFailure = (err) => {
  return {
    type: FETCH_CONTACT_FIELDS_FAILURE,
    payload: err,
  };
};

// Fetch All AllFormContactFields
export const fetchAllAllFormContactFields = () => {
  return (dispatch) => {
    dispatch(fetchAllFormContactFieldsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/default-fields?type=contact`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.default_fields;
        dispatch(fetchAllFormContactFieldsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllFormContactFieldsFailure(errorMsg));
      });
  };
};
