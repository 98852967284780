import React, { Component } from "react";
import axios from "axios";
import { debounce } from "lodash";
import OppModal from "../OppModal";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Col, Row } from "react-grid-system";
// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, companyOnboardingSet } from "../../redux";
import CreateDealModal from "../CreateModal/CreateDealModal";

class DealsList extends Component {
  state = {
    opportunities: [],
    activePage: 1,
    total: 0,
    showContactModal: false,
    showNewOpportunityModal: false,
    stageId: this.props.history.location.state?.stageId
      ? this.props.history.location.state.stageId
      : "",
    funnelId: this.props.history.location.state?.funnelId
      ? this.props.history.location.state.funnelId
      : "",
    userId: this.props.history.location.state?.userId
      ? this.props.history.location.state.userId
      : "",
    stages: [],
    sortDirection: true,
    currentStage: {},
    isApplying: false,
    closeDate: "all_days",
    totalRevenue: 0,
  };
  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };
  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: true });
  };

  handleCloseNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: false });
  };
  fetchDeals = debounce((query, page = 1, sortKey = "created_date") => {
    let url = `/opportunities?_limit=30&_page=${page}&total_days=${this.state.closeDate}`;
    if (this.state.userId !== "") {
      url += `&user_ids=${this.state.userId}`;
    }
    if (query) {
      url += `&name=${encodeURIComponent(query)}`;
    }
    if (this.state.stageId !== "") {
      url += `&stage_id=${this.state.stageId}`;
    } else {
      const stageId = this.state.stages
        ?.filter((stage) => stage.type !== "won" && stage.type !== "lost")
        .map((stage) => stage.id)
        .join();
      url += `&stage_id=${stageId}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        this.setState({
          opportunities: res.data.opportunities,
          total: res.data.total,
          isApplying: false,
          totalRevenue:
            res.data.total_revenue === null ? 0 : res.data.total_revenue,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);
  componentDidMount() {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel =
        this.state.funnelId !== ""
          ? res.data.funnels
              ?.map((funnel) => {
                return funnel.id === parseInt(this.state.funnelId) && funnel;
              })
              .filter((funnel) => funnel !== false)
          : res.data.funnels
              ?.map((funnel) => funnel.is_default === true && funnel)
              .filter((funnel) => funnel !== false);
      this.setState(
        {
          allFunnels: res.data.funnels,
          selectedFunnel: selectedFunnel[0],
          stages: selectedFunnel[0].stages.map((stage) => {
            stage.destroy = false;
            return stage;
          }),
          funnelId: selectedFunnel[0].id,
        },
        () => (this.state.stageId !== "" ? this.findStage() : this.fetchDeals())
      );
    });
  }
  funnelChangeHandler = (e) => {
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(value) && funnel;
        })
        .filter((funnel) => funnel !== false);
      this.setState(
        {
          allFunnels: res.data.funnels,
          selectedFunnel: selectedFunnel[0],
          selectedFunnelName: selectedFunnel[0].name,
          stages: selectedFunnel[0].stages?.map((stage) => {
            stage.destroy = false;
            return stage;
          }),
          stageId: "",
          funnelId: value,
        },
        this.fetchDeals
      );
    });
  };
  findStage = () => {
    const checkStage = this.state?.stages.find(
      (stage) => stage.id === parseInt(this.state.stageId)
    );
    this.setState({ currentStage: checkStage }, () => this.fetchDeals());
  };
  stageChangeHandler = (e) => {
    const { value } = e.target;
    const checkStage = this.state.stages.find(
      (stage) => stage.id === parseInt(value)
    );
    this.setState({ stageId: value, currentStage: checkStage }, () =>
      this.fetchDeals()
    );
  };
  closeDateChangeHandler = (e) => {
    const { value } = e.target;
    this.setState({ closeDate: value }, () => this.fetchDeals());
  };
  render() {
    const { opportunities, total, currentStage, totalRevenue } = this.state;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    return (
      <div className="accountList">
        <h1 className="accountList__heading">
          Deals{" "}
          <span style={{ color: "#4bc893", marginRight: "6px" }}>{total}</span>{" "}
          | <span style={{ marginLeft: "6px" }}>Revenue</span>{" "}
          <span style={{ color: "#4bc893" }}>
            {totalRevenue} {this.props.companyOnboarding?.currency}
          </span>
        </h1>
        <OppModal
          showOpportunityModal={this.state.showContactModal}
          setShowOpportunityModal={this.handleOpenContactModal}
          handleCloseModal={this.handleCloseContactModal}
          fromNav={true}
        />
        <CreateDealModal
          showOpportunityModal={this.state.showNewOpportunityModal}
          setShowOpportunityModal={this.handleOpenNewOpportunityModal}
          handleCloseModal={this.handleCloseNewOpportunityModal}
          fromNav={true}
        />
        <div className="accountList__function">
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Deals"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchDeals(value, 1);
              this.setState({ query: value, activePage: 1 });
            }}
          />
          <button
            className="button-md"
            type="button"
            onClick={
              this.props.isDealFieldsModified
                ? this.handleOpenNewOpportunityModal
                : this.handleOpenContactModal
            }
            style={{ float: "right" }}
          >
            Add Deal
          </button>
        </div>
        <div style={{ margin: "10px 0" }}>
          <span style={{ fontWeight: "500" }}>
            Select Funnel : &nbsp;&nbsp;
          </span>
          <select
            value={this.state.funnelId}
            onChange={this.funnelChangeHandler}
            className="c-icon-1"
            style={{
              background: "#EEF6F2",
              color: "#FFB202",
              width: "130px",
              outline: "none",
            }}
          >
            {this.state.allFunnels?.map((funnel) => (
              <option key={funnel.id} value={funnel.id}>
                {funnel.name}
              </option>
            ))}
          </select>
          <div className="dealsList-closeDate">
            <span style={{ fontWeight: "500" }}>
              Filter by Stage : &nbsp;&nbsp;
            </span>
            <select
              value={this.state.stageId}
              onChange={this.stageChangeHandler}
              className="c-icon-1"
              style={{
                background: "#EEF6F2",
                color: "#FFB202",
                width: "130px",
                outline: "none",
              }}
            >
              <option value="">All Deals</option>
              {this.state.stages.map((stage) => (
                <option key={stage.id} value={stage.id}>
                  {stage.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dealsList-closeDate">
            <span style={{ fontWeight: "500" }}>
              Filter by Close Date : &nbsp;&nbsp;
            </span>
            <select
              className="c-icon-1"
              style={{
                background: "#EEF6F2",
                color: "#FFB202",
                width: "130px",
                outline: "none",
              }}
              value={this.state.closeDate}
              onChange={this.closeDateChangeHandler}
            >
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
              <option value="365">365 Days</option>
              <option value="all_days">All Time</option>
            </select>
          </div>
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchDeals(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : (
          <table className="accountList__table">
            <thead>
              <tr>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Deal Owner
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchDeals(
                              null,
                              this.state.activePage,
                              "opportunity_owner"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Deal Name
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchDeals(null, this.state.activePage, "name")
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Account
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchDeals(
                              null,
                              this.state.activePage,
                              "account_name"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                {(currentStage?.type === "won" ||
                  currentStage?.type === "lost") && <th>Close Reason</th>}
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Stage
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchDeals(
                              null,
                              this.state.activePage,
                              "stage_name"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th style={{ background: "#DBF3E8" }}>
                  <Row>
                    <Col lg={9} xl={9}>
                      Revenue
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchDeals(
                              null,
                              this.state.activePage,
                              "revenue"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Close Date
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchDeals(
                              null,
                              this.state.activePage,
                              "close_date"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Last Activity Date
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchDeals(
                              null,
                              this.state.activePage,
                              "last_activity_date"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>Next Step</th>
              </tr>
            </thead>
            <tbody>
              {opportunities.map((opp) => {
                const {
                  name,
                  account_name,
                  stage_name,
                  revenue,
                  close_date,
                  opportunity_owner,
                  account_id,
                  next_step,
                  last_activity_date,
                  close_reason,
                  id,
                } = opp;
                let newTabData = {
                  type: "opportunity",
                  id: id,
                  name: name,
                  account_id: account_id,
                };
                let accountTabData = {
                  type: "account",
                  id: account_id,
                  name: account_name,
                };
                return (
                  <tr key={id}>
                    <td>{opportunity_owner}</td>
                    <td
                      style={{ cursor: "pointer", color: "#41BE89" }}
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          this.props.setTab({ ...newTabData, blank: true });
                        } else {
                          this.props.setActiveTabIndex(
                            this.props.totalTabs + 1
                          );
                          this.props.setTab(newTabData);
                          this.props.history.push("/active-tabs");
                        }
                      }}
                    >
                      {name}
                    </td>
                    {account_name ? (
                      <td
                        style={{ cursor: "pointer", color: "#41BE89" }}
                        onClick={(e) => {
                          if (e.metaKey || e.ctrlKey) {
                            this.props.setTab({
                              ...accountTabData,
                              blank: true,
                            });
                          } else {
                            this.props.setActiveTabIndex(
                              this.props.totalTabs + 1
                            );
                            this.props.setTab(accountTabData);
                            this.props.history.push("/active-tabs");
                          }
                        }}
                      >
                        {account_name}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {(currentStage?.type === "won" ||
                      currentStage?.type === "lost") && <td>{close_reason}</td>}
                    <td>{stage_name}</td>
                    <td>
                      {revenue} {this.props.companyOnboarding?.currency}
                    </td>
                    <td>
                      {close_date && moment(close_date).format(dateFormat)}
                    </td>
                    <td>
                      {last_activity_date &&
                        moment(last_activity_date).format(dateFormat)}
                    </td>
                    <td>{next_step}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchDeals(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    isDealFieldsModified: state.allDealFields.isModified,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(DealsList);
