import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/CustomerModal.scss";
import axios from "axios";
import OpportunityIcon from "../../images/DealsIntroLogo.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-autocomplete";
import AccountIcon from "../../images/AccountIcon.svg";
import ContactIcon from "../../images/ContactsIcon.svg";
import { colourStyles } from "../../constants/selectStyles";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../../utils/Helper/reusableFunctions";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import { withRouter } from "react-router-dom";

ReactModal.defaultStyles.overlay.zIndex = 10000001;

class CreateDealModal extends Component {
  state = {
    fieldsData: [],
    account_id: this.props.customerId || this.props.accountIdFromContact,
    contactId: this.props.contactId,
    name: "",
    description: null,
    email: null,
    mobile: null,
    website: null,
    revenue: "",
    stages: [],
    stage_id: "",
    stage_name: "",
    close_date: "",
    showModal: true,
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    close_reason_value: null,
    close_reason: null,
    isLoading: false,
    windowWidth: window.innerWidth,
    searchItems: [],
    searchValue: "",
    currentStageType: "",
    funnel_id: "",
    funnel_name: "",
    funnels: [],
    phoneValidationError: false,
    emailValidationError: false,
    menuOpen: "bottom",
    customFieldRevenueTypeCheck: [],
    defaultFields: {
      dealOwner: null,
      account_id: this.props.customerId || this.props.accountIdFromContact,
      contactId: this.props.contactId,
      name: "",
      description: null,
      revenue: "",
      stage_id: "",
      close_date: "",
      close_reason_value: null,
      close_reason: null,
      searchValue: "",
      searchItems: [],
      email: null,
      source: null,
      mobile: null,
      funnel_id: "",
      website: null,
    },
    allFields: [],
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      account_id: this.props.customerId || this.props.accountIdFromContact,
      contactId: this.props.contactId,
      name: "",
      description: null,
      revenue: "",
      stages: [],
      stage_id: "",
      close_date: "",
      showModal: true,
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      close_reason_value: null,
      close_reason: null,
      isLoading: false,
      searchValue: "",
      searchItems: [],
      email: null,
      mobile: null,
      website: null,
      phoneValidationError: false,
      emailValidationError: false,
      defaultFields: {
        account_id: this.props.customerId || this.props.accountIdFromContact,
        contactId: this.props.contactId,
        name: "",
        description: null,
        revenue: "",
        stage_id: "",
        close_date: "",
        close_reason_value: null,
        close_reason: null,
        searchValue: "",
        searchItems: [],
        email: null,
        mobile: null,
        funnel_id: "",
        website: null,
        source: null,
      },
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  searchValueChangeHandler = (e) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        account_id: undefined,
        contactId: undefined,
      },
      searchValue: e.target.value,
      account_id: undefined,
      contactId: undefined,
    }));
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/dashboard/search?query=${value}`,
    }).then((res) => {
      this.setState({ searchItems: res.data.data });
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("mousemove", this.mouseCoordinates);
    this.setState((prevState) => ({
      email: this.props.email === undefined ? null : this.props.email,
      mobile:
        this.props.formatted_mobile === undefined
          ? null
          : this.props.formatted_mobile,
      website: this.props.website === undefined ? null : this.props.website,
      allFields: this.props.allDealFields,
      defaultFields: {
        ...prevState.defaultFields,
        dealOwner: this.props.authUser.id,
      },
    }));
    this.initialStates();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.initialStates();
      this.setState((prevState) => ({
        email: this.props.email === undefined ? null : this.props.email,
        mobile:
          this.props.formatted_mobile === undefined
            ? null
            : this.props.formatted_mobile,
        website: this.props.website === undefined ? null : this.props.website,
        account_id: this.props.customerId || this.props.accountIdFromContact,
        allFields: this.props.allDealFields,
        defaultFields: {
          ...prevState.defaultFields,
          dealOwner: this.props.authUser.id,
          account_id: this.props.customerId || this.props.accountIdFromContact,
          contactId: this.props.contactId,
        },
      }));
    }
  }
  getAllFunnels = (id) => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(id) && funnel;
        })
        .filter((funnel) => funnel !== false);
      const stage_id = selectedFunnel[0].stages
        .map((item) => item.type === "new" && item)
        .filter((stage) => stage !== false);
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          stage_id: stage_id[0].id,
        },
        funnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages,
        stage_id: stage_id[0].id,
        stage_name: stage_id[0].name,
      }));
    });
  };
  initialStates = () => {
    this.setState({
      fieldsData: this.props.allDealCustomFields,
    });
    const customField = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));

    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => funnel.is_default === true && funnel)
        .filter((funnel) => funnel !== false);
      const stage = selectedFunnel[0].stages
        .map((item) => item.type === "new" && item)
        .filter((stage) => stage !== false);
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          funnel_id: selectedFunnel[0].id,
          stage_id: stage[0].id,
        },
        funnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages,
        stage_id: stage[0].id,
        stage_name: stage[0].name,
        funnel_id: selectedFunnel[0].id,
        funnel_name: selectedFunnel[0].name,
      }));
    });

    axios({
      method: "GET",
      url: `/close-reasons`,
    }).then((response) => {
      this.setState({
        close_reason: response.data.reasons,
      });
    });
  };
  showModal = () => {
    ReactModal.isOpen = false;
    // this.setState({
    //   showModal: false,
    // });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }

  getFields = () => {
    this.state.fields.fields.map((item, i) => {
      return console.log(item.label);
    });
  };
  changeHandler = (name, e) => {
    const { value } = e.target;
    if (name === "stage_id") {
      const newStage = this.state.stages.find(
        (stage) => stage.id === parseInt(value)
      );
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: value,
        },
        currentStageType: newStage.type,
      }));
    } else {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: value === "" ? null : value,
        },
      }));
    }
  };
  changeRevenueHandler = (name, e) => {
    const { value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      console.log("revenue", checkValidations, name);
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: value === "" ? null : value,
        },
      }));
    }
  };
  changeHandlerSelect = (name, value) => {
    if (name === "stage_id") {
      const newStage = this.state.stages.find(
        (stage) => stage.id === parseInt(value)
      );
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: value,
        },
        currentStageType: newStage.type,
        stage_name: newStage.name,
      }));
    } else {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: value,
        },
      }));
    }
  };
  funnelChangeHandler = (name, value) => {
    this.getAllFunnels(parseInt(value));
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        funnel_id: parseInt(value),
      },
      funnel_id: parseInt(value),
      funnel_name: name,
    }));
  };
  closeDateChangeHandler = (type, date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [type]: offsetDate,
      },
    }));
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    } else {
      return;
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (
      this.state.defaultFields.name === null ||
      this.state.defaultFields.name === "" ||
      (this.state.defaultFields.account_id === undefined &&
        this.state.defaultFields.contactId === undefined)
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return;
    }
    if (
      (this.state.currentStageType === "won" ||
        this.state.currentStageType === "lost") &&
      (this.state.defaultFields.close_reason_value === "" ||
        this.state.defaultFields.close_reason_value === null)
    ) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    const requiredFields = this.state.allFields
      ?.filter((field) => field.required)
      ?.map((field) => {
        if (field.name === "account_id") {
          const check =
            this.state.defaultFields.account_id ||
            this.state.defaultFields.contactId;
          return check ? null : field.name;
        }
        return field.name;
      })
      ?.filter((field) => field !== null);
    const isNotError = requiredFields?.every((field) =>
      Array.isArray(this.state.defaultFields[field])
        ? this.state.defaultFields[field].length !== 0
        : this.state.defaultFields[field] !== null &&
          this.state.defaultFields[field] !== ""
    );
    const isNotErrorCustom = requiredFields?.every((field) =>
      Array.isArray(this.state.customFields[field])
        ? this.state.customFields[field].length !== 0
        : this.state.customFields[field] !== null &&
          this.state.customFields[field] !== ""
    );
    console.log("isNotError", requiredFields, isNotError);
    if (!isNotError || !isNotErrorCustom) {
      this.setState({ errorMessage: true });
      return false;
    }
    let defaultFields = this.state.defaultFields;
    for (let i in defaultFields) {
      if (defaultFields[i] === "") {
        defaultFields[i] = null;
      }
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    const calculatedRevenue = getCurrencyValue(defaultFields.revenue);
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/opportunities/create`,
      data: {
        opportunity_owner_id: this.state.defaultFields.dealOwner,
        ...(this.state.defaultFields.account_id && {
          account_id: this.state.defaultFields.account_id,
        }),
        ...(this.state.defaultFields.contactId && {
          contact_id: this.state.defaultFields.contactId,
        }),
        name: defaultFields.name,
        description:
          defaultFields.description === "" ? null : defaultFields.description,
        revenue: calculatedRevenue,
        stages: this.state.stages,
        funnel_id: defaultFields.funnel_id,
        stage_id: defaultFields.stage_id,
        source: defaultFields.source,
        close_date:
          defaultFields.close_date === "" ? null : defaultFields.close_date,
        custom_fields: customField,
        close_reason: defaultFields?.close_reason_value,
        email: this.state.email === undefined ? null : this.state.email,
        formatted_phone:
          this.state.mobile === undefined || this.state.mobile === "undefined"
            ? null
            : this.state.mobile,
        website:
          this.state.website === null
            ? null
            : this.state.website.startsWith("https://") ||
              this.state.website.startsWith("http://")
            ? this.state.website
            : "https://" + this.state.website,
      },
    })
      .then((response) => {
        this.handleCloseModal();
        this.props.fromRecord && this.props.fetchAllOpportunitiesAgain();
        if (this.props.fromRecord !== true) {
          this.props.setActiveTabIndex(this.props.totalTabs + 1);
          this.props.setTab({
            type: "opportunity",
            id: response.data.opportunity.opportunity_id,
            name: response.data.opportunity.name,
            account_id:
              this.props.customerId === undefined
                ? this.state.defaultFields.account_id
                : this.props.customerId,
          });
          this.props.history.push("/active-tabs");
        }
      })
      .then((response) => {
        this.props.fromRecord && this.props.showDealPortion();
        NotificationManager.success("Deal created successfully!");
      })
      .then((response) => this.initialStates())
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating deal.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      });
  };
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired,
    fieldParent
  ) => {
    let inputType;
    if (fieldName === "funnel_id") {
      inputType = (
        <Select
          className="basic-single"
          classNamePrefix="select"
          menuPlacement={this.state.menuOpen}
          styles={colourStyles}
          value={{
            value: this.state.defaultFields.funnel_id,
            label: this.state.funnel_name,
          }}
          placeholder="-Select-"
          onChange={(selected) =>
            this.funnelChangeHandler(selected.label, selected.value)
          }
          isClearable={false}
          isSearchable={true}
          options={this.state.funnels?.map((stage) => {
            const { name, id } = stage;
            return {
              value: id,
              label: name,
            };
          })}
        />
      );
    } else if (fieldName === "stage_id") {
      inputType = (
        <div>
          <Select
            className="basic-single"
            classNamePrefix="select"
            menuPlacement={this.state.menuOpen}
            styles={colourStyles}
            value={{
              value: this.state.defaultFields.stage_id,
              label: this.state.stage_name,
            }}
            placeholder="-Select-"
            onChange={(selected) =>
              this.changeHandlerSelect("stage_id", selected.value)
            }
            isClearable={false}
            isSearchable={true}
            options={this.state.stages?.map((stage) => {
              const { name, id } = stage;
              return {
                value: id,
                label: name,
              };
            })}
          />
          {this.state?.currentStageType === "won" ||
          this.state?.currentStageType === "lost" ? (
            <div>
              <h5 className="c-title-act">Close Reason</h5>
              {this.state?.currentStageType === "won" ? (
                <select
                  name="close_reason_value"
                  value={this.state.defaultFields.close_reason_value}
                  onChange={(e) => this.changeHandler("close_reason_value", e)}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.currentStageType === "won" ||
                      this.state.currentStageType === "lost") &&
                    (this.state.defaultFields.close_reason_value === "" ||
                      this.state.defaultFields.close_reason_value === null)
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state?.close_reason?.map((reason) => {
                    const { name, id, type } = reason;
                    let options;
                    options =
                      type === "won" ? (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ) : null;
                    return options;
                  })}
                </select>
              ) : (
                <select
                  name="close_reason_value"
                  value={this.state.defaultFields.close_reason_value}
                  onChange={(e) => this.changeHandler("close_reason_value", e)}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.currentStageType === "won" ||
                      this.state.currentStageType === "lost") &&
                    (this.state.defaultFields.close_reason_value === "" ||
                      this.state.defaultFields.close_reason_value === null)
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state?.close_reason?.map((reason) => {
                    const { name, id, type } = reason;
                    let options;
                    options =
                      type === "lost" ? (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ) : null;
                    return options;
                  })}
                </select>
              )}
            </div>
          ) : null}
          <div
            style={{
              color: "#F36363",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            {this.state.errorMessage === true &&
            (this.state.currentStageType === "won" ||
              this.state.currentStageType === "lost") &&
            (this.state.defaultFields.close_reason_value === "" ||
              this.state.defaultFields.close_reason_value === null)
              ? "Close Reason is required"
              : null}
          </div>
        </div>
      );
    } else if (fieldName === "account_id") {
      inputType =
        this.props.fromNav === true ? (
          <Autocomplete
            inputProps={{
              style: {
                border: "1px solid #c5c5d3",
                borderRadius: "4px",
                padding: "5px",
                width: "27vw",
                margin: "5px 0",
              },
              placeholder: "Search Account or Contact",
            }}
            items={this.state.searchItems.filter(
              (item) => item.type === "account" || item.type === "contact"
            )}
            menuStyle={{
              borderRadius: "3px",
              // left: "70px",
              display: "block",
              boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
              background: "#252a2d",
              padding: "3px 0",
              position: "fixed",
              overflow: "auto",
              maxHeight: "250px",
              zIndex: "2147483647",
            }}
            getItemValue={(item) => item.name}
            renderItem={(item, highlighted) => {
              const { type, id } = item;
              let logo = "";
              if (type === "account") {
                logo = (
                  <img
                    src={AccountIcon}
                    alt=""
                    width="13"
                    key={id + type}
                    style={{ transform: "translateY(9px)" }}
                  />
                );
              } else {
                logo = (
                  <img
                    src={ContactIcon}
                    alt=""
                    width="13"
                    key={id + type}
                    style={{ transform: "translateY(9px)" }}
                  />
                );
              }
              return (
                <div
                  key={`${item.id}${item.type}`}
                  style={{
                    backgroundColor: highlighted ? "#3B4043" : "#252a2d",
                    color: "#CFCFD8",
                    zIndex: "2147483647",
                    fontSize: "16px",
                    cursor: "pointer",
                    borderBottom: "1px solid black",
                    padding: "5px 0",
                  }}
                >
                  <div style={{ paddingLeft: "15px" }}>
                    {logo}
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginLeft: "15px",
                      }}
                    >
                      {item.name.length > 23
                        ? item.name.substring(0, 22)
                        : item.name}
                    </span>
                  </div>
                  <div
                    style={{
                      marginLeft: "45px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#A5A1AC",
                    }}
                  >
                    {item.type}
                  </div>
                </div>
              );
            }}
            value={this.state.searchValue}
            onChange={this.searchValueChangeHandler}
            onSelect={(value, item) => {
              item.type === "account"
                ? this.setState((prevState) => ({
                    defaultFields: {
                      ...prevState.defaultFields,
                      account_id: item.id,
                      contactId: undefined,
                    },
                    account_id: item.id,
                    contactId: undefined,
                    searchValue: item.name,
                  }))
                : this.setState((prevState) => ({
                    defaultFields: {
                      ...prevState.defaultFields,
                      contactId: item.id,
                      account_id: undefined,
                    },
                    contactId: item.id,
                    account_id: undefined,
                    searchValue: item.name,
                  }));
            }}
          />
        ) : (
          <input type="text" value={this.props?.customerName} readOnly={true} />
        );
    } else if (fieldName === "close_date") {
      inputType = (
        <DatePicker
          name="close_date"
          selected={Date.parse(this.state.defaultFields.close_date)}
          value={Date.parse(this.state.defaultFields.close_date)}
          onChange={(date) => this.closeDateChangeHandler("close_date", date)}
          dateFormat={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
          required={fieldRequired}
        />
      );
    } else if (fieldType === "text") {
      inputType = (
        <input
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          required={fieldRequired}
        />
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <input
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          required={fieldRequired}
        />
      );
    } else if (fieldType === "revenue") {
      inputType =
        fieldName === "revenue" ? (
          <input
            type="text"
            name={fieldName}
            value={this.state.defaultFields[fieldName]}
            onChange={(e) => this.changeRevenueHandler(fieldName, e)}
            required={fieldRequired}
          />
        ) : (
          <input
            type="text"
            name={fieldName}
            value={this.state.customFields[fieldName]}
            onChange={(e) => this.customFieldRevenueChangeHandler(e)}
            required={fieldRequired}
          />
        );
    } else if (fieldType === "paragraph") {
      inputType = (
        <textarea
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          required={fieldRequired}
        ></textarea>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <input
          type="number"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          required={fieldRequired}
        />
      );
    } else if (fieldType === "dropdown") {
      inputType =
        fieldName === "source" ? (
          <Select
            className="basic-single"
            classNamePrefix="select"
            menuPlacement={this.state.menuOpen}
            styles={colourStyles}
            value={{
              value: this.state.defaultFields.source,
              label: this.state.defaultFields.source,
            }}
            placeholder="-Select-"
            onChange={(selected) =>
              this.changeHandlerSelect("source", selected.value)
            }
            isClearable={false}
            isSearchable={true}
            options={this.props.allAccountSources?.map((source) => ({
              value: source.name,
              label: source.name,
            }))}
          />
        ) : fieldName === "opportunity_owner_id" ? (
          <select
            value={this.state.defaultFields.dealOwner}
            onChange={(e) => this.changeHandler("dealOwner", e)}
            name="dealOwner"
            style={{ width: "99%" }}
          >
            {this.props.allUsers?.map((user) => {
              const { id, name } = user;
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
        ) : (
          <Select
            className="basic-single"
            classNamePrefix="select"
            menuPlacement={this.state.menuOpen}
            styles={colourStyles}
            required={fieldRequired}
            value={{
              value: this.state.customFields[fieldName],
              label: this.state.customFields[fieldName],
            }}
            placeholder="-Select-"
            onChange={(selected) =>
              fieldParent === "default_fields"
                ? this.changeHandlerSelect(fieldName, selected.value)
                : this.customFieldSelectChangeHandler(fieldName, selected.value)
            }
            isClearable={false}
            isSearchable={true}
            name={fieldName}
            options={fieldChoices.map((choice) => {
              const { value } = choice;
              return { value, label: value };
            })}
          />
        );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px" }}>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={(e) =>
                    fieldParent === "default_fields"
                      ? this.changeHandler(fieldName, e)
                      : this.customFieldChangeHandler(e)
                  }
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          name={fieldName}
          selected={Date.parse(this.state.customFields[fieldName])}
          value={Date.parse(this.state.customFields[fieldName])}
          onChange={(date) =>
            this.customFieldDateChangeHandler(fieldName, date)
          }
          dateFormat={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
          required={fieldRequired}
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      inputType = (
        <Select
          value={multiSelectDropdownValue}
          closeMenuOnSelect={false}
          menuPlacement={this.state.menuOpen}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value: value, label: value };
          })}
          placeholder="-Select-"
          className="basic-multi-select"
          classNamePrefix="select"
          required={fieldRequired}
          onChange={(selectedOption) =>
            this.customFieldMultiDropdownChangeHandler(
              fieldName,
              selectedOption
            )
          }
        />
      );
    }
    const showErrorMessage = () => {
      if (fieldName === "account_id") {
        let check = !(
          this.state.defaultFields.account_id ||
          this.state.defaultFields.contactId
        );
        return check;
      } else {
        return (
          fieldRequired &&
          (this.state.defaultFields[fieldName] === null ||
            this.state.defaultFields[fieldName] === "" ||
            this.state.defaultFields[fieldName]?.length === 0 ||
            this.state.customFields[fieldName] === null ||
            this.state.customFields[fieldName] === "" ||
            this.state.customFields[fieldName]?.length === 0)
        );
      }
    };
    const shouldShowErrorMessage = showErrorMessage();
    return (
      <>
        {fieldType === "heading" ? (
          <h3 className="modal__heading-sub">
            {fieldName === "account_id"
              ? this.state.defaultFields.account_id === undefined &&
                this.state.defaultFields.contactId === undefined
                ? "Account/ Contact"
                : this.state.defaultFields.contactId === undefined ||
                  this.state.defaultFields.contactId === null
                ? "Account"
                : "Contact"
              : fieldLabel}
          </h3>
        ) : (
          <div>
            <label>
              {fieldLabel}{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            {inputType}
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true && shouldShowErrorMessage
                ? `${fieldLabel} is required`
                : null}
            </div>
          </div>
        )}
      </>
    );
  };
  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          style={modalStyle}
        >
          <div className="modal">
            <div className="modal__content">
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "#F8E9E2",
                      transform: "translateY(-18px)",
                    }}
                  >
                    <img
                      src={OpportunityIcon}
                      alt="Icon"
                      width="17px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Create a deal</h2>
                  <p className="modal__heading-desc">
                    Add some information about the deal
                  </p>
                </div>
                <button
                  onClick={this.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    height: "12px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form
                className="modal__form"
                style={{
                  overflowY: "scroll",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                <NotificationContainer />
                {this.state.allFields?.map((field) => {
                  const {
                    id,
                    label,
                    type,
                    choices,
                    name,
                    required,
                    parent_id,
                  } = field;
                  return (
                    <div key={id + name}>
                      {this.showInput(
                        name,
                        label,
                        type,
                        choices,
                        required,
                        parent_id
                      )}
                    </div>
                  );
                })}
              </form>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  disabled={this.state.isLoading}
                  onClick={this.submitHandler}
                >
                  {this.state.isLoading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    allDealFields: state.allDealFields.fields,
    authUser: state.login.authUser,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(withRouter(CreateDealModal));
