import {
  FETCH_DEAL_FIELDS_REQUEST,
  FETCH_DEAL_FIELDS_SUCCESS,
  FETCH_DEAL_FIELDS_FAILURE,
} from "./types";

const initialState = {
  fields: [],
  isModified: false,
  loading: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEAL_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case FETCH_DEAL_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fields: action.payload.data,
        isModified: action.payload.is_modified,
      };
    case FETCH_DEAL_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        fields: [],
        isModified: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
