import axios from "axios";
import React, { useEffect, useState } from "react";
import GenerateApiKeyModal from "./GenerateApiKeyModal";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import DeleteApiKeyModal from "./DeleteApiKeyModal";

function ApiKeyList() {
  const [apiKeyList, setApiKeyList] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [showKeys, setShowKeys] = useState({});
  const [selectedId, setSelectedId] = useState("");

  const toggleKeyVisibility = (id) => {
    setShowKeys((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const copyToClipboard = (key) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(key)
        .then(() => {
          NotificationManager.success("API Key copied to clipboard!");
        })
        .catch(() => {
          NotificationManager.error(
            "Failed to copy text to clipboard. Please try copying manually."
          );
        });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = key;
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        NotificationManager.success("API Key copied to clipboard!");
      } catch (err) {
        NotificationManager.error(
          "Failed to copy text to clipboard. Please try copying manually."
        );
      }
      document.body.removeChild(textarea);
    }
  };

  const hideKey = (key) => {
    const firstChar = key[0];
    const lastThreeChars = key.slice(-3);
    const maskedMiddle = "*".repeat(key.length - 4);
    return `${firstChar}${maskedMiddle}${lastThreeChars}`;
  };

  const getApiKeyList = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `/api-key`,
    }).then((res) => {
      setApiKeyList(res.data.api_keys);
      setLoading(false);
    });
  };
  const generateApiKey = (name, allowed_services) => {
    setCreateLoading(true);
    axios({
      method: "POST",
      url: `/api-key`,
      data: {
        name,
        allowed_services: allowed_services?.map((service) => service.value),
      },
    })
      .then((res) => {
        getApiKeyList();
        setCreateLoading(false);
        setShowCreateModal(false);
        NotificationManager.success("API key created successfully");
      })
      .catch((err) => {
        setCreateLoading(false);
        NotificationManager.error("Error creating a new key");
      });
  };
  const editApiKey = (is_active, is_deleted, id) => {
    setCreateLoading(true);
    const message =
      is_active === true
        ? "activated"
        : is_active === false
        ? "disabled"
        : "deleted";
    axios({
      method: "PUT",
      url: `/api-key/${id}`,
      data: {
        is_active,
        is_deleted,
      },
    })
      .then((res) => {
        getApiKeyList();
        setCreateLoading(false);
        setShowDeleteModal(false);
        NotificationManager.success(`API key ${message} successfully`);
      })
      .catch((err) => {
        setCreateLoading(false);
        NotificationManager.error(`There was some error. Please try again`);
      });
  };
  useEffect(() => {
    getApiKeyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Key", value: "key" },
    { label: "Allowed Services", value: "allowed_services" },
    { label: "Created At", value: "created_at" },
    { label: "Connections", value: "connections" },
    { label: "Status", value: "is_active" },
    { label: "Action", value: null },
  ];
  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <GenerateApiKeyModal
          showModal={showCreateModal}
          handleCloseModal={() => setShowCreateModal(false)}
          submitHandler={generateApiKey}
          loading={createLoading}
        />
        <DeleteApiKeyModal
          showModal={showDeleteModal}
          handleCloseModal={() => setShowDeleteModal(false)}
          submitHandler={editApiKey}
          loading={createLoading}
          selectedId={selectedId}
        />
        <h2 style={{ marginBottom: "0px" }}>
          API Keys
          <button
            className="button-md"
            style={{
              float: "right",
              fontSize: "13.33px",
              textDecoration: "none",
              justifyContent: "center",
              textAlign: "center",
            }}
            onClick={() => setShowCreateModal(true)}
          >
            Create new key
          </button>
        </h2>
        <p> </p>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : loading === false && apiKeyList.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No Api keys found
        </div>
      ) : (
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw" }}
        >
          <thead>
            <tr>
              {tableHeaders.map((header) => (
                <th key={header.label}>{header.label}</th>
              ))}
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {apiKeyList?.map((temp) => {
              const {
                id,
                name,
                allowed_services,
                created_at,
                connections,
                key,
                is_active,
              } = temp;

              return (
                <tr key={id}>
                  <td>{name}</td>
                  <td>
                    {showKeys[id] ? key : hideKey(key)}
                    <button
                      onClick={() => toggleKeyVisibility(id)}
                      className="showpassword-button"
                      style={{ marginLeft: "5px" }}
                    >
                      {showKeys[id] ? (
                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      ) : (
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      )}
                    </button>
                    <button
                      onClick={() => copyToClipboard(key)}
                      className="showpassword-button"
                      style={{ marginLeft: "5px" }}
                    >
                      <i className="fa fa-clipboard" aria-hidden="true"></i>
                    </button>
                  </td>
                  <td>{allowed_services?.join(",")}</td>
                  <td>{moment(created_at).format("llll")}</td>
                  <td>
                    {connections
                      ?.map(
                        (connection) =>
                          `${connection?.service_name} - Created At: ${moment(
                            connection?.created_at
                          ).format("l")}`
                      )
                      .join(",")}
                  </td>
                  <td>{is_active ? "Active" : "Disabled"}</td>
                  <td>
                    <button
                      type="button"
                      style={{
                        border: "1px solid #ffffff",
                        borderRadius: "9px",
                        padding: "5px 10px",
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "18px",
                        color: "#73738C",
                        background: "#F4F6F5",
                        height: "35px",
                        cursor: "pointer",
                        marginRight: "5px",
                      }}
                      onClick={() => editApiKey(!is_active, false, id)}
                    >
                      {is_active ? "Disable" : "Enable"}
                    </button>
                    <button
                      type="button"
                      style={{
                        border: "1px solid #C5C5D3",
                        borderRadius: "9px",
                        padding: "5px 10px",
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "18px",
                        color: "#F36363",
                        background: "white",
                        height: "35px",
                        width: "65px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedId(id);
                        setShowDeleteModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ApiKeyList;
