import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DeleteIcon from "../../images/DeleteIconRed.svg";
import { CountryCodeList } from "../2FA/CountryCodeList";
import Select from "react-select";
import { colourStyles } from "../../constants/selectStyles";
// Redux stuff
import { connect } from "react-redux";
import { fetchAllUsers } from "../../redux";

class EditUserModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    name: this.props.name,
    email: this.props.email,
    formatted_mobile: this.props.formatted_mobile,
    roleId: null,
    roles: [],
    teams: [],
    team: this.props.teamId,
    teamRole: [],
    userId: null,
    errorMessage: false,
    isLoading: false,
    totalTeams: [],
    countryPhoneCode: "",
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    (this.props.countryPhoneCode === null ||
      this.props.countryPhoneCode === undefined) &&
      this.setState({ countryPhoneCode: `+1` });
    this.setState({
      name: this.props.name,
      email: this.props.email,
      formatted_mobile: this.props.formatted_mobile,
      roleId: this.props.roleId,
      team: this.props.teamId,
      teamRole: this.props.teamRole,
      userId: this.props.userId,
      countryPhoneCode: `+${this.props.countryPhoneCode}`,
      totalTeams: this.props.totalTeams.map((team) => {
        const { id, role } = team;
        return { id, role };
      }),
    });
    axios({
      method: "GET",
      url: `/admin/teams?view=table`,
    })
      .then((response) => {
        this.setState({
          teams: response.data.teams,
        });
      })
      .catch((error) => console.log("error", error));
    axios({
      method: "GET",
      url: `/admin/roles`,
    })
      .then((response) => {
        this.setState({
          roles: response.data.roles,
        });
      })
      .catch((error) => console.log("error", error));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  editChoiceChangeHandler = (choiceId, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      totalTeams: prevState.totalTeams.map((choice) => {
        if (choice.id === choiceId) {
          choice.id = value;
        }
        return choice;
      }),
    }));
  };
  editChoiceRoleChangeHandler = (choiceId, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      totalTeams: prevState.totalTeams.map((choice) => {
        if (choice.id === choiceId) {
          choice.role = value;
        }
        return choice;
      }),
    }));
  };
  deleteChoiceChangeHandler = (choiceId, e) => {
    this.setState((prevState) => ({
      totalTeams: prevState.totalTeams.filter((choice) => {
        return choice.id !== choiceId;
      }),
    }));
  };
  addEditFieldChoice = () => {
    this.setState((prevState) => ({
      totalTeams: [
        ...prevState.totalTeams,
        {
          id: null,
          role: "member",
        },
      ],
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (!data.name || !data.email || !data.formatted_mobile || !data.roleId) {
      this.setState({ errorMessage: true });
      return false;
    }
    let checkError = true;
    if (data.totalTeams) {
      checkError = data.totalTeams.every((team) => {
        if (team.id === null) {
          this.setState({ errorMessage: true });
          return false;
        }
        return team;
      });
    }
    this.setState({ isLoading: true });
    if (checkError === true) {
      axios({
        method: "PUT",
        url: `/admin/user/${this.props.userId}`,
        data: {
          name: this.state.name,
          email: this.state.email,
          formatted_mobile: this.state.formatted_mobile.toString(),
          country_code: this.state.countryPhoneCode,
          role_id: this.state.roleId,
          ...(this.state.totalTeams !== null &&
            this.state.totalTeams.length !== 0 && {
              teams: this.state.totalTeams,
            }),
        },
      })
        .then((res) => {
          this.props.fetchUsers();
          this.props.fetchAllUsers();
          this.props.handleCloseModal();
          NotificationManager.success("User edited successfully.");
          if (this.props.authUserId === this.state.userId) {
            this.props.authUserSet({
              ...this.props.authUser,
              name: this.state.name,
            });
            localStorage.setItem(
              "authUser",
              JSON.stringify({
                ...this.props.authUser,
                name: this.state.name,
              })
            );
          }
          this.setState({ isLoading: false, errorMessage: false });
        })
        .catch((error) => {
          if (error.response.status === 409) {
            return NotificationManager.error(error.response.data.message);
          }
          NotificationManager.error("Error editing user.");
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "73vh", top: "8%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Edit User</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <NotificationContainer />
              <form onSubmit={this.submitHandler}>
                <label>Name</label>
                <input
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={this.changeHandler}
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? "Name is required"
                    : null}
                </div>
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.changeHandler}
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.email === null || this.state.email === "")
                    ? "Email is required"
                    : null}
                </div>
                <label>Phone</label>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "50%",
                      fontSize: "14px",
                    }}
                  >
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      styles={colourStyles}
                      value={{
                        value: this.state.countryPhoneCode,
                        label: this.state.countryPhoneCode,
                      }}
                      onChange={(selected) =>
                        this.setState({ countryPhoneCode: selected.value })
                      }
                      isClearable={false}
                      isSearchable={true}
                      name="countryPhoneCode"
                      options={CountryCodeList?.map((currency) => ({
                        value: currency.dial_code,
                        label: `${currency.dial_code} ${currency.code}`,
                      }))}
                    />
                  </div>
                  <input
                    name="formatted_mobile"
                    value={this.state.formatted_mobile}
                    onChange={this.changeHandler}
                  />
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.formatted_mobile === null ||
                    this.state.formatted_mobile === "")
                    ? "Phone Number is required"
                    : null}
                  {this.state.errorMessage === true &&
                  this.state.formatted_mobile !== null &&
                  this.state.formatted_mobile !== "" &&
                  this.state.formatted_mobile.length !== 10
                    ? "Invalid formatted_mobile Number"
                    : null}
                </div>
                <label>Role</label>
                <select
                  name="roleId"
                  value={this.state.roleId}
                  onChange={this.changeHandler}
                  style={{ width: "100%" }}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state.roles.map((role) => {
                    const { name, id } = role;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.roleId === null || this.state.roleId === "")
                    ? "Please select a role for user"
                    : null}
                </div>
                <label style={{ fontWeight: "500" }}>
                  Team{" "}
                  <button
                    type="button"
                    onClick={this.addEditFieldChoice}
                    style={{
                      background: "linear-gradient(0deg, #61C99D, #61C99D)",
                      color: "white",
                      border: "1px solid #61C99D",
                      cursor: "pointer",
                      padding: "2px 4px",
                      outline: "none",
                      marginLeft: "20px",
                    }}
                  >
                    + Add Team
                  </button>
                </label>
                <div style={{ height: "20vh", overflowY: "auto" }}>
                  {this.state?.totalTeams.map((team) => {
                    const { id, role } = team;
                    return (
                      <div key={id} style={{ display: "flex" }}>
                        <select
                          name="team"
                          value={id}
                          onChange={(e) => this.editChoiceChangeHandler(id, e)}
                          style={
                            this.state.errorMessage === true && id === null
                              ? {
                                  width: "auto",
                                  marginRight: "5px",
                                  border: "2px solid #F36363",
                                }
                              : { width: "auto", marginRight: "5px" }
                          }
                        >
                          <option hidden>-Select-</option>
                          <option value="" selected disabled>
                            -Select-
                          </option>
                          {this.state.teams?.map((team) => {
                            const { id, name } = team;
                            return (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          name="teamRole"
                          value={role}
                          onChange={(e) =>
                            this.editChoiceRoleChangeHandler(id, e)
                          }
                        >
                          <option hidden>-Select-</option>
                          <option value="" selected disabled>
                            -Select-
                          </option>
                          <option value="leader">Leader</option>
                          <option value="member">Member</option>
                        </select>
                        <button
                          type="button"
                          onClick={(e) => this.deleteChoiceChangeHandler(id, e)}
                          style={{
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                          }}
                        >
                          <img src={DeleteIcon} alt="Delete" width="16px" />
                        </button>
                        <div
                          style={{
                            color: "#F36363",
                            fontSize: "12px",
                            marginBottom: "5px",
                          }}
                        >
                          {this.state.errorMessage === true && id === null
                            ? "Please select a team for user"
                            : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="modal__buttons">
                  {this.props.authUserId === this.state.userId ? (
                    <button
                      type="button"
                      className="modal__cancel-button"
                      onClick={this.props.handleCloseModal}
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="modal__cancel-button"
                      style={{ color: "#F36363", paddingLeft: "0" }}
                      onClick={this.props.handleOpenUserDeleteModal}
                    >
                      Delete User
                    </button>
                  )}
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    allUsers: state.allUsers,
  };
};
const MDP = (dispatch) => {
  return {
    fetchAllUsers: (usersData) => dispatch(fetchAllUsers(usersData)),
  };
};

export default connect(MSP, MDP)(EditUserModal);
