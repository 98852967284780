import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import GmailLogo from "../../images/GmailLogo.png";
import OutlookLogo from "../../images/OutlookLogo.png";
import SalesdashLogo from "../../images/SDLogoBlack.png";
import ZapierLogo from "../../images/ZapierShortLogo.png";
import CXLogo from "../../images/CXBlackLogo.png";
import ZoomInfoIcon from "../../images/zoominfoIcon.png";
import ApolloLogo from "../../images/apolloLogo.png";
import PostmarkLogo from "../../images/PostmarkLogo.png";
import CalendlyLogo from "../../images/CalendlyShortLogo.png";
import RingCentralLogo from "../../images/RingcentralShortLogo.png";
import TaskTourIntegrate from "../Onboarding/TaskTourIntegrate";
import { BASE_URL, CALENDLY_CLIENT_ID, CX_XML_URL } from "../../config";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "./IntegrationPage.scss";
import PostMarkAppModal from "../Reports/PostMarkAppModal";
import PostMarkAppVerificationModal from "../Reports/PostMarkAppVerificationModal";
import ReusableModal from "../Reusables/ReusableModal";
import ConnectApolloModal from "../ApolloLeadGen/ConnectApolloModal";
// Redux stuff
import { connect } from "react-redux";
import { authUserSet, companyOnboardingSet } from "../../redux";

const IntegrationPage = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeCategory, setActiveCategory] = useState("all");
  // other states
  const [tourComplete, setTourComplete] = useState(false);
  const [integrated, setIntegrated] = useState(false);
  const [postMarkId, setPostMarkId] = useState(null);
  const [postMarkVerified, setPostMarkVerified] = useState(false);
  const [showPostMarkModal, setShowPostMarkModal] = useState(false);
  const [isCalendlyConnected, setIsCalendlyConnected] = useState(false);
  const [isApolloConnected, setIsApolloConnected] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [calendlyLoading, setCalendlyLoading] = useState(false);
  const [mkimHost, setMkimHost] = useState(null);
  const [mkimTextValue, setMkimTextValue] = useState(null);
  const [showRingCentralDisconnectModal, setShowRingCentralDisconnectModal] =
    useState(false);
  const [showApolloModal, setShowApolloModal] = useState(false);
  const [ringCentralLoading, setRingCentralLoading] = useState(false);
  const [apolloLoading, setApolloLoading] = useState(false);
  const [showRingCentralWidget, setShowRingCentralWidget] = useState(false);
  const [enableInboundOutlook, setEnableInboundOutlook] = useState(false);
  const [userData, setUserData] = useState({});
  const [postmarkData, setPostmarkData] = useState({});

  const categories = [
    { id: "all", name: "All Integrations" },
    { id: "email", name: "Email" },
    { id: "lead-gen", name: "Lead Generation" },
    { id: "automation", name: "Automation" },
    { id: "phone", name: "Phone" },
    { id: "scheduling", name: "Scheduling" },
  ];

  const [integrations] = useState([
    {
      id: "gmail",
      name: "Gmail",
      icon: GmailLogo,
      status: "connected",
      description:
        "Send outbound emails (not inbound) from Salesdash to contacts.",
      category: "email",
      iconWidth: "40px",
      iconHeight: "35px",
    },
    {
      id: "outlook",
      name: "Outlook",
      icon: OutlookLogo,
      status: "available",
      actionLabel: "Outlook Integration",
      description:
        "Send outbound emails (not inbound) from Salesdash to contacts.",
      category: "email",
      iconWidth: "40px",
      iconHeight: "40px",
    },
    {
      id: "apollo",
      name: "Apollo.io",
      icon: ApolloLogo,
      status: "connected",
      description:
        "Connect via API to pull your saved lists in Apollo of shipper contacts and accounts. Logic is in place to catch duplicates.",
      category: "lead-gen",
      iconWidth: "40px",
      iconHeight: "40px",
      externalLink: {
        text: "Sign up for free here",
        url: "https://apollo.partnerlinks.io/yxg2420i2gak",
      },
    },
    {
      id: "sd-email",
      name: "Postmark (Bulk Email)",
      icon: PostmarkLogo,
      status: "connected",
      description:
        "Send bulk emails and email sequences via Postmark. Emails are sent and received only in Salesdash, not your Gmail or Outlook. This is a paid add-on in your Billing page.",
      category: "email",
      iconWidth: "40px",
      iconHeight: "40px",
      details: {
        hostname: "pm-bounces",
        type: "CNAME",
        value: "pm.mtasv.net",
      },
    },
    {
      id: "webhooks",
      name: "Salesdash Webhooks",
      icon: SalesdashLogo,
      status: "configured",
      description:
        "Salesdash CRM webhooks keep you updated on key events like new accounts, contacts, carriers, deals, and activities. Get instant POST notifications to your chosen URL.",
      category: "automation",
      actionType: "redirect",
      actionLabel: "Go to Webhooks Page",
      linkType: "internal",
      link: "/webhooks",
      iconWidth: "40px",
      iconHeight: "40px",
    },
    {
      id: "apikey",
      name: "ZoomInfo Chrome Extension",
      icon: ZoomInfoIcon,
      status: "configured",
      description:
        "Import contacts and companies from the Advanced Search page in ZoomInfo to Salesdash. Logic is in place to catch duplicates.",
      category: "lead-gen",
      actionType: "redirect",
      actionLabel: "Go to API Key Page",
      linkType: "internal",
      link: "/api-key",
      iconWidth: "40px",
      iconHeight: "40px",
      externalLink: {
        text: "Download Extension",
        url: "https://chromewebstore.google.com/detail/salesdash-crm-zi-extensio/mkdfkjmhmbmlfekcpcpmodejclmhnaci",
      },
    },
    {
      id: "zapier",
      name: "Zapier",
      icon: ZapierLogo,
      status: "configured",
      description:
        "Create automations from other web apps to connect with Salesdash.",
      category: "automation",
      apiKey: "zapier",
      actionType: "redirect",
      actionLabel: "Go To Salesdash Zapier Page",
      linkType: "external",
      link: "https://zapier.com/apps/salesdash-crm/integrations",
      iconWidth: "40px",
      iconHeight: "40px",
    },
    {
      id: "3cx",
      name: "3CX",
      icon: CXLogo,
      status: "configured",
      description:
        "Automatically have outbound and inbound calls logged from 3CX in your Salesdash activity timeline.",
      category: "phone",
      apiKey: "3cx",
      actionType: "download",
      actionLabel: "Download XML file",
      downloadLink: CX_XML_URL,
      iconWidth: "40px",
      iconHeight: "40px",
    },
    {
      id: "calendly",
      name: "Calendly",
      icon: CalendlyLogo,
      status: "connected",
      description:
        "Automatically have contacts created in Salesdash when someone schedules an appointment with you via Calendly.",
      category: "scheduling",
      iconWidth: "40px",
      iconHeight: "40px",
    },
    {
      id: "ringcentral",
      name: "RingCentral",
      icon: RingCentralLogo,
      status: "configured",
      description:
        "Click to dial contacts and accounts in Salesdash. Set up automatic logging of calls (optional). With RingCentral’s Advanced plan, you can also set up call recording links to be automatically added to logged calls.",
      category: "phone",
      iconWidth: "40px",
      iconHeight: "40px",
    },
  ]);

  const url_string = window.location.href;
  const url = new URL(url_string);
  const siteName = url.hostname.split(".");
  const errorType = url.searchParams.get("error");
  const location = useLocation();
  const history = useHistory();
  const fetchMailProvider = () => {
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      setIntegrated(res.data.user.mail_provider);
      setPostMarkId(res.data.user.postmark_signature_id);
      setPostMarkVerified(res.data.user.postmark_signature_verified);
      setIsCalendlyConnected(res.data.user.is_calendly_connected);
      setIsApolloConnected(res.data.user.is_apollo_connected);
      setMkimHost(res.data.user.dkim_host);
      setMkimTextValue(res.data.user.dkim_text_value);
      setPostmarkData(res.data.postmark_signature_details);
    });
  };
  const getUserDetails = () => {
    axios({
      method: "GET",
      url: `/users`,
    }).then((res) => {
      setUserData(res.data.user);
      setShowRingCentralWidget(res.data.user.is_ringcentral_enabled);
      setEnableInboundOutlook(res.data.user.is_inbound_om_enabled);
    });
  };
  useEffect(() => {
    fetchMailProvider();
    getUserDetails();
  }, []);
  useEffect(() => {
    if (errorType === "insufficient_permissions") {
      NotificationManager.error(
        "We need all the permissions to integrate google account"
      );
    }
    if (errorType === "access_denied") {
      NotificationManager.error(
        "There was a problem integrating to your outlook account. Please try again."
      );
    }
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("error")) {
      queryParams.delete("error");
      history.replace({
        search: queryParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);
  const disconnectMailProvider = () => {
    axios({
      method: "POST",
      url: `/users/disconnect-mail-provider`,
      data: {},
    })
      .then((res) => {
        fetchMailProvider();
        NotificationManager.success("Mail Provider disconnected");
      })
      .catch((err) =>
        NotificationManager.error("Error disconnecting mail provider")
      );
  };
  const checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      setPostMarkId(res.data.postmark_signature_id);
      setPostMarkVerified(res.data.postmark_signature_verified);
    });
  };
  const checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      verifySignature(res.data.postmark_signature_verified);
      setPostMarkId(res.data.postmark_signature_id);
      setPostMarkVerified(res.data.postmark_signature_verified);
    });
  };
  const verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          setShowVerificationModal(false);
          checkUserProfile();
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  const changeEnableOutlook = (is_inbound_om_enabled) => {
    axios({
      method: "PUT",
      url: `/users/config`,
      data: {
        is_inbound_om_enabled,
      },
    });
  };
  const disconnectRingCentral = () => {
    setRingCentralLoading(true);
    axios({
      method: "PUT",
      url: `/users`,
      data: {
        name: userData.name,
        formatted_mobile: userData.formatted_mobile
          ? userData.formatted_mobile.toString()
          : null,
        country_code:
          userData.country_code === null || userData.country_code === ""
            ? 1
            : userData.country_code,
        daily_digest: userData.daily_digest,
        weekly_digest: userData.weekly_digest,
        is_ringcentral_enabled: !showRingCentralWidget,
        is_apollo_connected: userData.is_apollo_connected,
      },
    })
      .then((res) => window.location.reload())
      .catch((err) => {
        setRingCentralLoading(false);
        NotificationManager.error(
          `Error ${
            showRingCentralWidget ? "disconnecting" : "connecting"
          } ring central.`
        );
      });
  };
  const disconnectApollo = (is_apollo_connected) => {
    setApolloLoading(true);
    axios({
      method: "PUT",
      url: `/users`,
      data: {
        name: userData.name,
        formatted_mobile: userData.formatted_mobile
          ? userData.formatted_mobile.toString()
          : null,
        country_code:
          userData.country_code === null || userData.country_code === ""
            ? 1
            : userData.country_code,
        daily_digest: userData.daily_digest,
        weekly_digest: userData.weekly_digest,
        is_ringcentral_enabled: userData.is_ringcentral_enabled,
        is_apollo_connected,
      },
    })
      .then((res) => window.location.reload())
      .catch((err) => {
        setApolloLoading(false);
        NotificationManager.error(
          `Error ${
            userData.is_apollo_connected ? "disconnecting" : "connecting"
          } apollo.`
        );
      });
  };
  const integrationDecision = () => {
    if (postMarkId === null) {
      setShowPostMarkModal(true);
    } else if (!postMarkVerified) {
      setShowVerificationModal(true);
    }
  };
  const disconnectCalendly = () => {
    setCalendlyLoading(true);
    axios({
      method: "POST",
      url: `/calendly/disconnect`,
    })
      .then((res) => {
        fetchMailProvider();
        setCalendlyLoading(false);
        setShowDisconnectModal(false);
      })
      .catch((err) => {
        setCalendlyLoading(false);
        NotificationManager.error("Error disconnecting calendly.");
      });
  };

  const copyKey = (apiKey) => {
    // Create temporary textarea
    const textarea = document.createElement("textarea");
    textarea.value = apiKey;
    textarea.style.position = "fixed"; // Ensure textarea is not visible
    textarea.style.opacity = "0";
    document.body.appendChild(textarea);

    // Select the text
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    // Execute copy command
    try {
      document.execCommand("copy");
      NotificationManager.success("Key copied!");
    } catch (err) {
      NotificationManager.error("Failed to copy key!");
      console.error("Failed to copy:", err);
    }

    // Cleanup
    document.body.removeChild(textarea);
  };

  const ConnectButton = (name) => (
    <button className="status-button available">
      <span className="icon plus">+</span>
      Connect {name}
    </button>
  );

  const StatusButton = ({ status, name, id }) => {
    switch (id) {
      case "gmail":
        return integrated === "google" ? (
          <button
            className="status-button connected"
            onClick={() => disconnectMailProvider()}
          >
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <a
            href={`${BASE_URL}/auth/google-email/login?site_name=${siteName[0]}&user_id=${props.authUser.id}`}
          >
            <button className="status-button available">
              <span className="icon plus">+</span>
              Connect {name}
            </button>
          </a>
        );
      case "outlook":
        return integrated === "outlook" ? (
          <button
            className="status-button connected"
            onClick={() => disconnectMailProvider()}
          >
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <a
            href={`${BASE_URL}/auth/outlook-email/login?site_name=${siteName[0]}&user_id=${props.authUser.id}`}
          >
            <button className="status-button available">
              <span className="icon plus">+</span>
              Connect {name}
            </button>
          </a>
        );
      case "apollo":
        return isApolloConnected ? (
          <button
            className="status-button connected"
            onClick={() => disconnectApollo(false)}
          >
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <button
            onClick={() => setShowApolloModal(true)}
            className="status-button available"
          >
            <span className="icon plus">+</span>
            Connect {name}
          </button>
        );
      case "sd-email":
        return postMarkId !== null && postMarkVerified ? (
          <button className="status-button connected">
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <button
            onClick={() => integrationDecision(true)}
            className="status-button available"
          >
            <span className="icon plus">+</span>
            Connect
          </button>
        );
      case "calendly":
        return isCalendlyConnected ? (
          <button
            onClick={() => setShowDisconnectModal(true)}
            className="status-button connected"
          >
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <a
            href={`https://auth.calendly.com/oauth/authorize?client_id=${CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${BASE_URL}/calendly/integration-callback&state=${siteName[0]}:${props.authUser.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <button
              onClick={() => integrationDecision(true)}
              className="status-button available"
            >
              <span className="icon plus">+</span>
              Connect {name}
            </button>
          </a>
        );
      case "ringcentral":
        return showRingCentralWidget ? (
          <button
            onClick={() => setShowRingCentralDisconnectModal(true)}
            className="status-button connected"
          >
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <button
            onClick={() => setShowRingCentralDisconnectModal(true)}
            className="status-button available"
          >
            <span className="icon plus">+</span>
            Connect {name}
          </button>
        );
      //   case "connected":
      //     return (
      //       <button className="status-button connected">
      //         <span className="icon check">✓</span>
      //         Connected
      //       </button>
      //     );
      //   case "configured":
      //     return (
      //       <button className="status-button configured">
      //         <span className="icon check">✓</span>
      //         Configured
      //       </button>
      //     );
      //   case "available":
      //     return (
      //       <button className="status-button available">
      //         <span className="icon plus">+</span>
      //         Connect {name}
      //       </button>
      //     );
      default:
        return null;
    }
  };

  const ActionButton = ({ type, label, linkType, link, downloadLink }) => {
    switch (type) {
      case "redirect":
        return linkType === "internal" ? (
          <Link to={link}>
            <button className="action-button redirect">
              <span className="icon external">↗</span>
              {label}
            </button>
          </Link>
        ) : (
          <a href={link} target="_blank" rel="noreferrer">
            <button className="action-button redirect">
              <span className="icon external">↗</span>
              {label}
            </button>
          </a>
        );
      case "download":
        return (
          <a href={downloadLink} target="_blank" rel="noreferrer">
            <button className="action-button download">{label}</button>
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <div className="integrations-page">
      <TaskTourIntegrate tourComplete={tourComplete} />
      <PostMarkAppModal
        showOpportunityModal={showPostMarkModal}
        setShowOpportunityModal={() => setShowPostMarkModal(true)}
        handleCloseModal={() => setShowPostMarkModal(false)}
        handleVerificationOpenModal={() => setShowVerificationModal(true)}
        checkUserProfile={checkUserProfile}
      />
      <PostMarkAppVerificationModal
        showOpportunityModal={showVerificationModal}
        setShowOpportunityModal={() => setShowVerificationModal(true)}
        handleCloseModal={() => setShowVerificationModal(false)}
        checkUserProfile={checkUserProfile}
        checkVerification={checkVerification}
      />
      <ReusableModal
        showModal={showDisconnectModal}
        handleCloseModal={() => setShowDisconnectModal(false)}
        submitHandler={disconnectCalendly}
        modalHeading={`Disconnect Calendly`}
        modalPara={`Are you sure you want to disconnect Calendly`}
        modalHeight={`30`}
        modalTop={`25`}
        loading={calendlyLoading}
      />
      <ReusableModal
        showModal={showRingCentralDisconnectModal}
        handleCloseModal={() => setShowRingCentralDisconnectModal(false)}
        submitHandler={disconnectRingCentral}
        modalHeading={`${
          showRingCentralWidget ? "Disconnect" : "Connect"
        } Ring Central`}
        modalPara={`Are you sure you want to ${
          showRingCentralWidget ? "disconnect" : "connect"
        } Ring Central?`}
        modalHeight={`30`}
        modalTop={`25`}
        loading={ringCentralLoading}
      />
      <ConnectApolloModal
        showModal={showApolloModal}
        handleCloseModal={() => setShowApolloModal(false)}
        modalHeight={`30`}
        modalTop={`25`}
        disconnectApollo={disconnectApollo}
        apolloLoading={apolloLoading}
        setApolloLoading={setApolloLoading}
      />
      <div className="integrations-page-header">
        <div className="header-content">
          <h1>Integrations</h1>
          <p>Manage your connected services and tools</p>

          <div className="categories">
            {categories.map((category) => (
              <button
                key={category.id}
                className={`category-button ${
                  activeCategory === category.id ? "active" : ""
                }`}
                onClick={() => setActiveCategory(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        <div className="search-container">
          <span className="search-icon">🔍</span>
          <input
            type="text"
            placeholder="Search integrations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
      </div>

      <div className="integrations-grid">
        {integrations
          .filter((integration) => {
            const matchesSearch = integration.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
            const matchesCategory =
              activeCategory === "all" ||
              integration.category === activeCategory;
            return matchesSearch && matchesCategory;
          })
          .map((integration) => (
            <div key={integration.id} className="integration-card">
              <div className="integration-content">
                <div className="integration-main">
                  <div className="integration-icon">
                    <img
                      src={integration.icon}
                      alt={integration.name}
                      style={{
                        width: integration.iconWidth,
                        height: integration.iconHeight,
                      }}
                    />
                  </div>
                  <div className="integration-info">
                    <div className="integration-header">
                      <div>
                        <h3>{integration.name}</h3>
                      </div>
                      <StatusButton
                        status={integration.status}
                        name={integration.name}
                        id={integration.id}
                      />
                    </div>
                    <p className="integration-description">{integration.description}</p>

                    {postMarkId !== null &&
                      postMarkVerified &&
                      integration.id === "sd-email" && (
                        <>
                          <div className="api-key-section">
                            <span className="api-key-label">DKIM Host:</span>
                            <code className="api-key-value">{mkimHost}</code>
                            <button
                              className="copy-button"
                              onClick={() => copyKey(mkimHost)}
                            >
                              Copy
                            </button>
                          </div>
                          <div className="api-key-section">
                            <span className="api-key-label">
                              DKIM Text Value:
                            </span>
                            <code className="api-key-value">
                              {mkimTextValue}
                            </code>
                            <button
                              className="copy-button"
                              onClick={() => copyKey(mkimTextValue)}
                            >
                              Copy
                            </button>
                          </div>
                          <div className="details-section">
                            <div className="detail-row">
                              <span className="detail-label">Hostname:</span>
                              <span className="detail-value">
                                {integration.details.hostname}
                              </span>
                            </div>
                            <div className="detail-row">
                              <span className="detail-label">Type:</span>
                              <span className="detail-value">
                                {integration.details.type}
                              </span>
                            </div>
                            <div className="detail-row">
                              <span className="detail-label">Value:</span>
                              <span className="detail-value">
                                {integration.details.value}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                    {integration.apiKey && (
                      <div className="api-key-section">
                        <span className="api-key-label">API Key:</span>
                        <code className="api-key-value">
                          {integration.id === "zapier"
                            ? props.authUser.zap_api_key
                            : integration.id === "3cx"
                            ? props.companyOnboarding.api_key
                            : ""}
                        </code>
                        <button
                          className="copy-button"
                          onClick={() =>
                            copyKey(
                              integration.id === "zapier"
                                ? props.authUser.zap_api_key
                                : integration.id === "3cx"
                                ? props.companyOnboarding.api_key
                                : ""
                            )
                          }
                        >
                          Copy
                        </button>
                      </div>
                    )}

                    {integration.externalLink && (
                      <a
                        href={integration.externalLink.url}
                        className="external-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {integration.externalLink.text}
                      </a>
                    )}
                  </div>
                </div>

                {integration.actionType && (
                  <div className="action-section">
                    <ActionButton
                      type={integration.actionType}
                      label={integration.actionLabel}
                      linkType={integration.linkType}
                      link={integration.link}
                      downloadLink={integration.downloadLink}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(IntegrationPage);
