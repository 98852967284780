import { useState } from "react";
import ReactModal from "react-modal";

const TagCarrierModal = ({
  showModal,
  handleCloseModal,
  submitHandler,
  loading,
  allUntaggedCarriers,
  getAllUnTaggedCarriers,
}) => {
  const [taggedCarriers, setTaggedCarriers] = useState([]);
  const handleTagDeals = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      setTaggedCarriers([
        ...taggedCarriers.filter((com) => com !== ""),
        parseInt(value),
      ]);
    } else {
      setTaggedCarriers(
        taggedCarriers.filter((pc) => pc.toString() !== value.toString())
      );
    }
  };
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div className="modal__content" style={{ height: "70vh", top: "5%" }}>
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Tag Carriers to Deals</h2>
                <p className="modal__heading-desc">
                  Tag existing carrier(s) into a deal
                </p>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <input
              placeholder="Search Carriers"
              onChange={(e) => getAllUnTaggedCarriers(e.target.value)}
            />
            <div
              style={{ height: "auto", maxHeight: "55vh", overflowY: "auto" }}
            >
              <table className="accountList__tableReport">
                <thead>
                  <tr>
                    <th style={{ width: "8px" }}>
                      
                    </th>
                    <th>Name</th>
                    <th>MC#</th>
                  </tr>
                </thead>
                <tbody>
                  {allUntaggedCarriers?.map((carrier) => {
                    const { id, name, mc_number } = carrier;
                    return (
                      <tr key={id}>
                        <td>
                          <input
                            value={id}
                            onChange={handleTagDeals}
                            type="checkbox"
                            style={{ display: "inline-block", width: "auto" }}
                          />
                        </td>
                        <td>{name}</td>
                        <td>{mc_number}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={loading}
                onClick={() => {
                  submitHandler(taggedCarriers)
                  setTaggedCarriers([])
                }}
              >
                {loading ? "Confirming" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default TagCarrierModal;
