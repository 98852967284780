import React, { Component } from "react";
import axios from "axios";
import "../../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { debounce } from "lodash";
import AccountsLogo from "../../images/AccountsSettingsIcon.svg";
import CarrierGreenNavIcon from "../../images/CarrierGreenNavIcon.svg";
import { NotificationManager } from "react-notifications";
import { colourStyles } from "../../constants/selectStyles";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import { AsYouType } from "libphonenumber-js";
import RangeSlider from "../MaterialComponents/RangeSlider/RangeSlider";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  fetchAllEquipmentTypes,
  fetchAllSpecialRequirements,
} from "../../redux";
import { Col, Row } from "react-grid-system";
import { CurrencyList } from "../../constants/currencylist";

class LanesList extends Component {
  state = {
    accounts: [],
    query: "",
    activePage: 1,
    total: 0,
    sortDirection: true,
    isApplying: true,
    carrierStatusArray: [],
    equipmentTypesArray: [],
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
      name: "",
    },
    origin: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    destination: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    price: null,
    equipmentTypes: [],
    speciaRequirements: [],
    modes: [],
    daysOfWeek: [],
    deadHeadOrigin: 25,
    deadHeadDestination: 25,
    searchType: false,
    sortKey: "",
  };
  days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  fetchAccounts = debounce((page = 1, sortKey) => {
    const {
      origin,
      destination,
      equipmentTypes,
      speciaRequirements,
      modes,
      price,
      daysOfWeek,
      deadHeadOrigin,
      deadHeadDestination,
    } = this.state;
    const searchParam = this.state.searchType ? "accounts" : "carriers";
    let url = `/${searchParam}/lanes?_limit=30&_page=${page}`;
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (origin.city) {
      url += `&origin_city=${origin.city}`;
    } else if (origin.zip) {
      url += `&origin_zip=${origin.zip}`;
    } else if (origin.state) {
      url += `&origin_state=${origin.state}`;
    }
    if (origin.address && deadHeadOrigin !== 0) {
      url += `&origin_lat=${origin.lat}`;
      url += `&origin_long=${origin.long}`;
      url += `&origin_radius=${deadHeadOrigin}`;
    }
    if (destination.address && deadHeadDestination !== 0) {
      url += `&destination_lat=${destination.lat}`;
      url += `&destination_long=${destination.long}`;
      url += `&destination_radius=${deadHeadDestination}`;
    }
    if (destination.city) {
      url += `&destination_city=${destination.city}`;
    } else if (destination.zip) {
      url += `&destination_zip=${destination.zip}`;
    } else if (destination.state) {
      url += `&destination_state=${destination.state}`;
    }
    if (equipmentTypes && equipmentTypes.length > 0) {
      url += `&equipment_type_ids=${equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    } else {
      url += `&equipment_type_ids=`;
    }
    if (speciaRequirements && speciaRequirements.length > 0) {
      url += `&requirement_type_ids=${speciaRequirements
        ?.map((type) => type.value)
        .join(",")}`;
    } else {
      url += `&requirement_type_ids=`;
    }
    if (modes && modes.length > 0) {
      url += `&mode_ids=${modes?.map((type) => type.value).join(",")}`;
    } else {
      url += `&mode_ids=`;
    }
    if (daysOfWeek.length > 0) {
      url += `&days_of_week=${daysOfWeek.join(",")}`;
    } else {
      url += `&days_of_week=`;
    }
    if (price) {
      url += `&price=${price}`;
    }
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          accounts: response.data.lanes?.results?.map((carrier) => ({
            ...carrier,
            equipment_types: carrier.equipment_types?.map((type) => ({
              value: type.id,
              label: type.name,
            })),
            requirement_types: carrier.requirement_types?.map((type) => ({
              value: type.id,
              label: type.name,
            })),
          })),
          total: response.data.lanes?.total,
          isApplying: false,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);
  getEquipmentTypes = () => {
    axios({
      method: "GET",
      url: `/lanes/equipment-types`,
    }).then((res) =>
      this.setState({ equipmentTypesArray: res.data.equipment_types })
    );
  };
  logCall = (description, selectedAccount) => {
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: `${description?.direction} Call`,
        description: `${description?.direction} Call made at ${Date(
          description?.startTime
        )} to <span style="color: #62ca9d;">${
          description?.to?.phoneNumber
        }</span>`,
        category_id: 1,
        ...(this.state.searchType && { account_id: selectedAccount.id }),
        ...(!this.state.searchType && { carrier_id: selectedAccount.id }),
        type: "activity_logged",
      },
    }).then((res) => {
      NotificationManager.success("Call logged.");
    });
  };
  handleClickToDial = (e) => {
    const data = e.data;
    if (
      data &&
      data.type === "rc-active-call-notify" &&
      data.call &&
      data.call.terminationType === "final"
    ) {
      const selectedAccount = this.state.accounts?.find((table) =>
        data.call.to.phoneNumber.includes(table.formatted_phone)
      );
      if (selectedAccount) {
        this.logCall(data.call, selectedAccount);
      }
    }
  };
  componentDidMount() {
    window.addEventListener("message", this.handleClickToDial);
    this.fetchAccounts();
    this.getEquipmentTypes();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.searchType !== prevState.searchType ||
      this.state.origin.address !== prevState.origin.address ||
      this.state.destination.address !== prevState.destination.address ||
      this.state.price !== prevState.price ||
      this.state.equipmentTypes?.length !== prevState.equipmentTypes?.length ||
      this.state.speciaRequirements?.length !==
        prevState.speciaRequirements?.length ||
      this.state.modes?.length !== prevState.modes?.length ||
      this.state.daysOfWeek?.length !== prevState.daysOfWeek?.length ||
      (this.state.origin.address &&
        this.state.deadHeadOrigin !== prevState.deadHeadOrigin) ||
      (this.state.destination.address &&
        this.state.deadHeadDestination !== prevState.deadHeadDestination)
    ) {
      this.fetchAccounts(1, this.state.sortKey);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handleClickToDial);
  }
  handleOriginPlaceSelect = (place) => {
    this.setState({
      origin: place,
    });
  };
  handleDestinationPlaceSelect = (place) => {
    this.setState({
      destination: place,
    });
  };
  handleDeadHeadOrigin = (e, deadHeadOrigin) => {
    this.setState({
      deadHeadOrigin,
    });
  };
  handleDeadHeadDestination = (e, deadHeadDestination) => {
    this.setState({
      deadHeadDestination,
    });
  };
  daysOfWeekChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      const newDays = [
        ...this.state.daysOfWeek.filter((day) => day !== ""),
        value,
      ];
      this.setState({
        daysOfWeek: newDays,
      });
    } else {
      const newDays = this.state.daysOfWeek.filter((pc) => pc !== value);
      this.setState({
        daysOfWeek: newDays,
      });
    }
  };
  changeFilterHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  tableHeaders = [
    {
      value: "account_name",
      label: "Name",
      sort: false,
      editable: false,
      type: "text",
    },
    {
      value: "owner",
      label: "owner",
      sort: false,
      editable: true,
      type: "number",
    },
    {
      value: "origin",
      label: "origin",
      sort: false,
      editable: true,
      type: "google",
    },
    {
      value: "destination",
      label: "destination",
      sort: false,
      editable: true,
      type: "google",
    },
    {
      value: "formatted_phone",
      label: "Phone",
      sort: false,
      editable: false,
      type: "phone",
    },
    {
      value: "equipment_types",
      label: "Equipment Types",
      sort: false,
      editable: true,
      type: "multi_select",
    },
    {
      value: "requirement_types",
      label: "Special Requirements",
      sort: false,
      editable: true,
      type: "multi_select",
    },
    {
      value: "price",
      label: "Linehaul",
      sort: false,
      editable: false,
      type: "number",
    },
    {
      value: "rate_per_mile",
      label: "rpm",
      sort: false,
      editable: false,
      type: "number",
    },
    {
      value: "volume",
      label: "weekly volume",
      sort: true,
      editable: true,
      type: "number",
    },
    {
      value: "description",
      label: "description",
      sort: false,
      editable: true,
      type: "text",
    },
  ];
  renderTableData = (carrierData, tableData, name, value) => {
    let newTabData = {
      type: carrierData.carrier_id ? "carrier" : "account",
      id: carrierData.carrier_id
        ? carrierData.carrier_id
        : carrierData.account_id,
      name: carrierData.carrier_id
        ? carrierData.carrier_name
        : carrierData.account_name,
    };
    let renderItem = <td>{value}</td>;
    if (name === "name") {
      renderItem = (
        <td>
          <span
            style={{ cursor: "pointer", color: "#41BE89" }}
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                this.props.setTab({ ...newTabData, blank: true });
              } else {
                this.props.setActiveTabIndex(this.props.totalTabs + 1);
                this.props.setTab(newTabData);
                this.props.history.push("/active-tabs");
              }
            }}
          >
            {carrierData.carrier_id
              ? carrierData.carrier_name
              : carrierData.account_name}
          </span>
        </td>
      );
    } else if (name === "owner") {
      const owner = carrierData.owners?.map((owner) => owner.name)?.join(", ");
      renderItem = <td>{owner}</td>;
    } else if (name === "formatted_phone") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: "#41BE89" }}>
              <a
                target="_blank"
                style={{ color: "#41BE89", cursor: "pointer" }}
                data-stringify-link={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                delay="150"
                data-sk="tooltip_parent"
                href={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                rel="noopener noreferrer"
                tabindex="-1"
                data-remove-tab-index="true"
              >
                {value}
              </a>
            </span>
          </div>
        </td>
      );
    } else if (name === "origin") {
      renderItem = <td>{carrierData.origin_address}</td>;
    } else if (name === "destination") {
      renderItem = <td>{carrierData.destination_address}</td>;
    } else if (tableData.type === "text") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(carrierData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: carrierData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (!tableData.editable) {
      const currency = this.props.companyOnboarding?.currency;
      const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
      renderItem = (
        <td>
          {value && currencySymbol.symbol} {value?.toLocaleString("en-US")}
          {value && tableData.value === "rate_per_mile" && "/mi"}
        </td>
      );
    } else if (tableData.type === "number") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            className="inlineReportEdit-input"
            type="number"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(carrierData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: carrierData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "dropdown") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(carrierData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: carrierData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          >
            <option value="" selected disabled>
              -Select-
            </option>
            {this.state.carrierStatusArray?.map((list) => (
              <option key={list.id} value={list.name}>
                {list.name}
              </option>
            ))}
          </select>
        </td>
      );
    } else if (tableData.type === "multi_select") {
      const selectedArray =
        tableData.value === "equipment_types"
          ? this.state.equipmentTypesArray
          : this.props.allSpecialRequirements;
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value?.map((type) => type.label)?.join(", ")}
          </span>
          <Select
            autoFocus={
              this.state.inputFocused.id === carrierData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
            }
            className="inlineReportEdit-input"
            closeMenuOnSelect={false}
            value={
              tableData.value === "equipment_types"
                ? carrierData.equipment_types
                : carrierData.requirement_types
            }
            isMulti
            name={name}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
            menuPlacement="auto"
            styles={colourStyles}
            options={selectedArray?.map((type) => {
              return { value: type.id, label: type.name };
            })}
            classNamePrefix="select"
            onChange={(selectedOption) =>
              this.fieldMultiSelectChangeHandler(
                carrierData,
                name,
                selectedOption
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: carrierData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    }

    return renderItem;
  };
  fieldChangeHandler = (account, name, value) => {
    let carrierData = account;
    let formattedValue = value;
    if (name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      formattedValue = formatter.input(value); // Format the input value
    }
    carrierData = {
      ...account,
      [name]: formattedValue === "" ? null : formattedValue,
    };
    this.setState({
      accounts: this.state.accounts?.map((acc) => {
        if (acc.id === account.id) {
          acc = {
            ...account,
            [name]: formattedValue === "" ? null : formattedValue,
          };
        }
        return acc;
      }),
    });
    this.submitHandler(carrierData);
  };
  fieldMultiSelectChangeHandler = (account, fieldName, selectedOptions) => {
    let carrierData = account;
    carrierData = {
      ...account,
      [fieldName]: selectedOptions,
    };
    this.setState((prevState) => ({
      accounts: this.state.accounts?.map((acc) => {
        if (acc.id === account.id) {
          acc = {
            ...account,
            [fieldName]: selectedOptions,
          };
        }
        return acc;
      }),
    }));
    this.submitHandler(carrierData);
  };
  submitHandler = debounce((carrierData) => {
    axios({
      method: "PUT",
      url: `/lanes/${carrierData.id}`,
      data: {
        price: carrierData.price === "" ? null : carrierData.price,
        description:
          carrierData.description === "" ? null : carrierData.description,
        origin_city: carrierData.origin_city,
        origin_state: carrierData.origin_state,
        origin_zip: carrierData.origin_zip,
        origin_address: carrierData.origin_address,
        origin_lat: carrierData.origin_lat,
        origin_long: carrierData.origin_long,
        destination_city: carrierData.destination_city,
        destination_state: carrierData.destination_state,
        destination_zip: carrierData.destination_zip,
        destination_address: carrierData.destination_address,
        destination_lat: carrierData.destination_lat,
        destination_long: carrierData.destination_long,
        equipment_type_ids: carrierData.equipment_types?.map(
          (type) => type.value
        ),
        requirement_type_ids: carrierData.requirement_types?.map(
          (type) => type.value
        ),
        mode_ids: carrierData.modes?.map((type) => type.value),
        days_of_week: carrierData.days_of_week,
      },
    })
      .then((response) => {
        // NotificationManager.success("Account edited successfully.");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing lanes.");
        }
      });
  }, 500);

  render() {
    const accounts = this.state.accounts;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    return (
      <div className="accountList">
        <h1 className="accountList__heading">
          Lanes <span style={{ color: "#62ca9d" }}>{this.state.total}</span>
        </h1>
        <div className="modal-radioButton c-radioButton">
          {[
            { label: "Carriers", value: false, image: CarrierGreenNavIcon },
            { label: "Accounts", value: true, image: AccountsLogo },
          ].map(({ label, value, image }) => (
            <span key={`${label}_${value}`}>
              <label
                htmlFor={`laneType_${label}_${value}`}
                style={
                  this.state.searchType === value
                    ? {
                        border: "2px solid rgb(75, 200, 147)",
                        outline: "none",
                        color: "#555555",
                        padding: "4px 12px",
                        borderRadius: "9px",
                        marginRight: "8px",
                        background: "#F4F6F5",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }
                    : {
                        color: "#555555",
                        padding: "4px 12px",
                        border: "none",
                        borderRadius: "9px",
                        background: "#F4F6F5",
                        marginRight: "8px",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }
                }
              >
                <img src={image} height="16px" /> {label}
              </label>
              <input
                type="radio"
                name="searchType"
                id={`laneType_${label}_${value}`}
                className="radio-button"
                checked={this.state.searchType === value}
                value={value}
                onChange={() => {
                  this.setState({
                    searchType: value,
                  });
                }}
              />
            </span>
          ))}
        </div>
        <div
          className="lanes-panel-form"
          style={{ display: "grid", gridTemplateColumns: "310px 310px 310px" }}
        >
          <div>
            <label>Origin</label>
            <GoogleAutocompleteComponent
              onPlaceSelect={this.handleOriginPlaceSelect}
            />
            <label style={{ marginTop: "10px" }}>
              Expand Origin Search ({this.state.deadHeadOrigin} Miles)
            </label>
            <RangeSlider
              width={285}
              value={this.state.deadHeadOrigin}
              handleChange={this.handleDeadHeadOrigin}
            />
          </div>
          <div>
            <label>Destination</label>
            <GoogleAutocompleteComponent
              onPlaceSelect={this.handleDestinationPlaceSelect}
            />
            <label style={{ marginTop: "10px" }}>
              Expand Destination Search ({this.state.deadHeadDestination} Miles)
            </label>
            <RangeSlider
              width={285}
              value={this.state.deadHeadDestination}
              handleChange={this.handleDeadHeadDestination}
            />
          </div>
        </div>
        <div className="lanes-panel-form-1">
          <div className="lanes-panel-form-1-div">
            <label>Equipment Types</label>
            <Select
              value={this.state.equipmentTypes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allEquipmentTypes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  equipmentTypes: selectedOptions,
                })
              }
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Modes</label>
            <Select
              value={this.state.modes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allModes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  modes: selectedOptions,
                })
              }
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Special Requirements</label>
            <Select
              value={this.state.speciaRequirements}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allSpecialRequirements?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  speciaRequirements: selectedOptions,
                })
              }
            />
          </div>
        </div>
        <div>
          <label
            style={{ color: "#586874", fontWeight: "500", fontSize: "14px" }}
          >
            Days of Week{" "}
            <span style={{ fontSize: "12px" }}>
              (
              {this.state.searchType
                ? `What days the freight ships out for this lane`
                : `When their trucks are available for this lane`}
              )
            </span>
          </label>
          <div
            className="modal-radioButton c-radioButton"
            style={{ marginTop: "5px" }}
          >
            {this.days.map((day) => (
              <span key={day}>
                <label
                  htmlFor={`week_${day}`}
                  style={
                    this.state.daysOfWeek.includes(day)
                      ? {
                          border: "2px solid rgb(75, 200, 147)",
                          outline: "none",
                          color: "#555555",
                          padding: "4px 12px",
                          borderRadius: "9px",
                          marginRight: "8px",
                          background: "#F4F6F5",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                          textTransform: "uppercase",
                        }
                      : {
                          color: "#555555",
                          padding: "4px 12px",
                          border: "none",
                          borderRadius: "9px",
                          background: "#F4F6F5",
                          marginRight: "8px",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                          textTransform: "uppercase",
                        }
                  }
                >
                  {day}
                  <input
                    type="checkbox"
                    name="category"
                    id={`week_${day}`}
                    className="radio-button"
                    checked={this.state.daysOfWeek.includes(day)}
                    value={day}
                    onChange={(e) => this.daysOfWeekChangeHandler(e)}
                  />
                </label>
              </span>
            ))}
          </div>
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(page, this.state.sortKey);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : this.state.isApplying === false && accounts.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              fontWeight: "500",
            }}
          >
            No lanes found
          </div>
        ) : (
          <table className="accountList__table">
            <thead>
              <tr>
                {this.tableHeaders.map((headers) => (
                  <th
                    style={
                      headers.value === "price" ||
                      headers.value === "rate_per_mile" ||
                      headers.value === "volume"
                        ? { minWidth: "60px", maxWidth: "65px" }
                        : {}
                    }
                  >
                    <Row>
                      <Col lg={9} xl={9}>
                        {headers.label}
                      </Col>
                      <Col lg={3} xl={3}>
                        {headers.sort && (
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              color: "#657885",
                            }}
                            onClick={() =>
                              this.setState(
                                {
                                  sortDirection: !this.state.sortDirection,
                                  sortKey: headers.value,
                                },
                                this.fetchAccounts(
                                  this.state.activePage,
                                  headers.value
                                )
                              )
                            }
                          >
                            <i className="fa fa-long-arrow-up"></i>
                            <i className="fa fa-long-arrow-down"></i>
                          </button>
                        )}
                      </Col>
                    </Row>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {accounts?.map((account) => {
                return (
                  <tr key={account.id}>
                    {this.tableHeaders?.map((header) =>
                      this.renderTableData(
                        account,
                        header,
                        header.value === "account_name" ? "name" : header.value,
                        account[
                          header.value === "account_name"
                            ? "name"
                            : header.value
                        ],
                        dateFormat
                      )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(page, this.state.sortKey);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    fetchAllEquipmentTypes: (equipmentTypes) =>
      dispatch(fetchAllEquipmentTypes(equipmentTypes)),
    fetchAllSpecialRequirements: (specialRequirementsData) =>
      dispatch(fetchAllSpecialRequirements(specialRequirementsData)),
  };
};

export default connect(MSP, MDP)(LanesList);
