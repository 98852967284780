export const accountHeaderMapping = {
  primary_owner: "Account Owner",
  // shared_owners: "Shared Account Owner",
  name: "Account Name",
  account_owner_email: "Account Owner Email",
  source: "Source",
  website: "Website",
  state: "State",
  zip: "Zip",
  city: "City",
  address: "Address",
  linkedin: "LinkedIn",
  phone_ext: "Phone Extension",
  email: "Email",
  formatted_phone: "Phone",
  description: "Description",
  country: "Country",
  status: "Status",
  preferred_communication: "Preferred Communication",
  unique_identifier: "Unique Identifier",
};
