import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import CreateTeamModal from "./CreateTeamModal";
import axios from "axios";
import DeleteTeamModal from "./DeleteTeamModal";
import EditTeamModal from "./EditTeamModal";
import TreeView from "./TreeView";
// Redux stuff
import { connect } from "react-redux";
import {
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
} from "../../redux";
import ShotClockSettingsModal from "./ShotClockSettingsModal";
import SharedUserSetingModal from "./SharedUserSetingModal";

function Teams(props) {
  const [userModal, setUserModal] = useState(false);
  const [userEditModal, setUserEditModal] = useState(false);
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [teams, setTeams] = useState([]);
  const [name, setName] = useState(null);
  const [members, setMembers] = useState([]);
  const [description, setDescription] = useState(null);
  const [parentTeam, setParentTeam] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [view, setView] = useState("tree");
  const [loading, setLoading] = useState(false);
  const [showShotClockModal, setShowShotClockModal] = useState(false);
  const [showMultipleAccountModal, setShowMultipleAccountModal] =
    useState(false);
  const handleOpenUserModal = () => {
    setUserModal(true);
  };
  const handleCloseUserModal = () => {
    setUserModal(false);
  };
  const handleOpenUserEditModal = (
    name,
    description,
    parentTeam,
    teamId,
    members
  ) => {
    setUserEditModal(true);
    setName(name);
    setDescription(description);
    setParentTeam(parentTeam);
    setTeamId(teamId);
    setMembers(members);
  };
  const handleCloseUserEditModal = () => {
    setUserEditModal(false);
  };
  const handleOpenUserDeleteModal = (name, teamId) => {
    setUserDeleteModal(true);
    setName(name);
    setTeamId(teamId);
  };
  const handleCloseUserDeleteModal = () => {
    setUserDeleteModal(false);
  };
  const viewChange = () => {
    const checkboxView = document.getElementById("checkboxView");
    if (checkboxView.checked) {
      setView("table");
    } else {
      setView("tree");
    }
  };
  const fetchTeams = useCallback(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `/admin/teams?view=${view}`,
    })
      .then((response) => {
        setTeams(response.data.teams);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  }, [view]);
  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);
  return (
    <div style={{ margin: "0px 30px" }}>
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>Teams</h2>
          <p>{teams.length} teams in your company</p>
        </div>
        {props.authUser.permissions.includes("crm:add:user") && (
          <div
            style={{ marginTop: "4vh", float: "right", marginRight: "30px" }}
          >
            <button
              type="button"
              onClick={() => handleOpenUserModal()}
              className="button-md"
            >
              Add team
            </button>
            <CreateTeamModal
              showOpportunityModal={userModal}
              setShowOpportunityModal={handleOpenUserModal}
              handleCloseModal={handleCloseUserModal}
              companyName={props.companyOnboarding.name}
              fetchTeams={fetchTeams}
            />
          </div>
        )}
      </div>
      <div>
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Link
            to="/users"
            style={{
              textDecoration: "none",
              color: "black",
              padding: "4px 12px",
            }}
          >
            Users
          </Link>
          <Link
            to="/teams"
            style={{
              textDecoration: "none",
              color: "#4BC893",
              padding: "4px 12px",
              borderBottom: "1px solid #4BC893",
              fontWeight: "500",
            }}
          >
            Teams
          </Link>
          <Link
            to="/roles"
            style={{
              textDecoration: "none",
              color: "black",
              padding: "4px 12px",
            }}
          >
            Roles
          </Link>
          {props.authUser.role === "SUPERADMIN" && (
            <div
              onClick={() => setShowShotClockModal(true)}
              style={{
                textDecoration: "none",
                color: "black",
                padding: "4px 12px",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              Shot Clock
            </div>
          )}
          {props.companyOnboarding?.is_multiple_account_owner_enabled && (
            <div
              onClick={() => setShowMultipleAccountModal(true)}
              style={{
                textDecoration: "none",
                color: "black",
                padding: "4px 12px",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              Shared Account Owners
            </div>
          )}
        </div>
        <div style={{ margin: "20px 0px" }}>
          <span className="general-heading">Org Chart &nbsp;</span>
          <label className="switch">
            <input
              type="checkbox"
              value={view}
              id="checkboxView"
              onChange={() => viewChange()}
            />
            <span className="slider round"></span>
          </label>
          <span className="general-heading"> &nbsp;Tabular View</span>
        </div>
        {loading ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : view === "table" ? (
          <div>
            <div>
              <table className="accountList__table" style={{ width: "93vw" }}>
                <thead>
                  <tr>
                    <th style={{ fontSize: "11px" }}>Name</th>
                    <th style={{ fontSize: "11px" }}>Description</th>
                    <th style={{ fontSize: "11px" }}>Parent Name</th>
                    <th style={{ fontSize: "11px" }}>Total Members</th>
                    {props.authUser.permissions.includes("crm:add:user") && (
                      <th style={{ fontSize: "11px" }}>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {teams?.map((team) => {
                    const {
                      name,
                      description,
                      parent_name,
                      total_users,
                      parent_id,
                      id,
                      members,
                      email,
                      formatted_mobile,
                    } = team;
                    return (
                      <tr>
                        <td>
                          <Link
                            to={{
                              pathname: "teams/detail",
                              state: {
                                name,
                                members,
                                email,
                                formatted_mobile,
                              },
                            }}
                          >
                            {name}
                          </Link>
                        </td>
                        <td>{description}</td>
                        <td>{parent_name}</td>
                        <td>{total_users}</td>
                        {props.authUser.permissions.includes(
                          "crm:add:user"
                        ) && (
                          <td>
                            <button
                              type="button"
                              style={{
                                borderRadius: "9px",
                                padding: "5px 10px",
                                fontSize: "15px",
                                lineHeight: "18px",
                                height: "35px",
                                width: "75px",
                                cursor: "pointer",
                                marginRight: "20px",
                              }}
                              className="button-md"
                              onClick={() =>
                                handleOpenUserEditModal(
                                  name,
                                  description,
                                  parent_id,
                                  id,
                                  members
                                )
                              }
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              style={{
                                border: "1px solid #C5C5D3",
                                borderRadius: "9px",
                                padding: "5px 10px",
                                fontWeight: "500",
                                fontSize: "15px",
                                lineHeight: "18px",
                                color: "#F36363",
                                background: "white",
                                height: "35px",
                                width: "75px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleOpenUserDeleteModal(name, id)
                              }
                            >
                              Delete
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <EditTeamModal
              showOpportunityModal={userEditModal}
              setShowOpportunityModal={handleOpenUserEditModal}
              handleCloseModal={handleCloseUserEditModal}
              name={name}
              description={description}
              parentTeam={parentTeam}
              teamId={teamId}
              members={members}
              companyName={props.companyOnboarding.name}
              key={name}
              fetchTeams={fetchTeams}
            />
            <DeleteTeamModal
              showOpportunityModal={userDeleteModal}
              setShowOpportunityModal={handleOpenUserDeleteModal}
              handleCloseModal={handleCloseUserDeleteModal}
              name={name}
              teamId={teamId}
              fetchTeams={fetchTeams}
            />
          </div>
        ) : (
          <TreeView
            teams={teams}
            handleOpenUserModal={handleOpenUserModal}
            showOpportunityModal={userModal}
            setShowOpportunityModal={handleOpenUserModal}
            handleCloseModal={handleCloseUserModal}
            companyName={props.companyOnboarding.name}
          />
        )}
      </div>
      <ShotClockSettingsModal
        showOpportunityModal={showShotClockModal}
        setShowOpportunityModal={() => setShowShotClockModal(true)}
        handleCloseModal={() => setShowShotClockModal(false)}
      />
      <SharedUserSetingModal
        showOpportunityModal={showMultipleAccountModal}
        setShowOpportunityModal={() => setShowMultipleAccountModal(true)}
        handleCloseModal={() => setShowMultipleAccountModal(false)}
      />
    </div>
  );
}
const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(Teams);
