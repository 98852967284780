import React, { Component } from "react";
import ReactModal from "react-modal";
import DeleteIcon from "../../images/DeleteIconRed.svg";

class CloseReasonChangeModal extends Component {
  state = {
    windowWidth: window.innerWidth,
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  submitHandler = async (e) => {
    e.preventDefault();
    this.props.submitCloseReasonHandler();
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const wonLength = this.props.reasons
      .map((reason) => reason.type === "won" && reason.destroy === false)
      .filter((reason) => reason !== false);
    const lostLength = this.props.reasons
      .map((reason) => reason.type === "lost" && reason.destroy === false)
      .filter((reason) => reason !== false);
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "90vh", top: "3%", width: "60vw", left: "17%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Customize Close Reasons</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <div
                  style={{ display: "grid", gridTemplateColumns: "50% 50%" }}
                >
                  <div>
                    <h3
                      style={{
                        fontSize: "16px",
                        lineHeight: "19px",
                        fontWeight: "500",
                        textAlign: "left",
                        marginTop: "8px",
                      }}
                    >
                      Won Reasons
                    </h3>
                    <form
                      style={{
                        height: "auto",
                        maxHeight: "65vh",
                        overflowY: "auto",
                      }}
                    >
                      {this.props?.reasons.map((reason) => {
                        const { id, name, type, destroy } = reason;
                        return type === "won" && destroy === false ? (
                          <span key={id} style={{ display: "flex" }}>
                            <input
                              name={id}
                              value={name}
                              onChange={(e) =>
                                this.props.changeHandler("won", e)
                              }
                              style={{
                                border: "none",
                                borderBottom: "1px solid #C5C5D3",
                                marginBottom: "15px",
                                outline: "none",
                                borderRadius: "0px",
                              }}
                            />
                            {wonLength.length >= 2 && (
                              <button
                                style={{
                                  marginRight: "30px",
                                  border: "none",
                                  background: "transparent",
                                  padding: "0",
                                  outline: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.props.deleteHandler(id)}
                                type="button"
                              >
                                <img src={DeleteIcon} alt="Delete" width="14" />
                              </button>
                            )}
                          </span>
                        ) : null;
                      })}
                    </form>
                    <form>
                      <input
                        type="text"
                        name="newWonReason"
                        value={this.props.newWonReason}
                        placeholder="Add Reason"
                        onChange={this.props.newReasonChangeHandler}
                        style={{
                          border: "none",
                          borderBottom: "1px solid #C5C5D3",
                          width: "135px",
                          marginBottom: "15px",
                          outline: "none",
                        }}
                      />
                      <button
                        disabled={this.props.newWonReason.length < 1}
                        style={{
                          marginLeft: "5px",
                        }}
                        type="button"
                        className="button-sm"
                        onClick={() =>
                          this.props.addReasonHandler(
                            "won",
                            this.props.newWonReason
                          )
                        }
                      >
                        Add
                      </button>
                    </form>
                  </div>
                  <div>
                    <h3
                      style={{
                        fontSize: "16px",
                        lineHeight: "19px",
                        fontWeight: "500",
                        textAlign: "left",
                        marginTop: "8px",
                      }}
                    >
                      Lost Reasons
                    </h3>

                    <form
                      style={{
                        height: "auto",
                        maxHeight: "65vh",
                        overflowY: "auto",
                      }}
                    >
                      {this.props?.reasons.map((reason) => {
                        const { id, name, type, destroy } = reason;
                        return type === "lost" && destroy === false ? (
                          <span key={id} style={{ display: "flex" }}>
                            <input
                              name={id}
                              value={name}
                              onChange={(e) =>
                                this.props.changeHandler("lost", e)
                              }
                              style={{
                                border: "none",
                                borderBottom: "1px solid #C5C5D3",
                                marginBottom: "15px",
                                outline: "none",
                                borderRadius: "0px",
                              }}
                            />
                            {lostLength.length >= 2 && (
                              <button
                                key={id}
                                style={{
                                  marginRight: "30px",
                                  border: "none",
                                  background: "transparent",
                                  padding: "0",
                                  outline: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.props.deleteHandler(id)}
                                type="button"
                              >
                                <img src={DeleteIcon} alt="Delete" width="14" />
                              </button>
                            )}
                          </span>
                        ) : null;
                      })}
                    </form>
                    <form>
                      <input
                        type="text"
                        name="newLostReason"
                        placeholder="Add Reason"
                        value={this.props.newLostReason}
                        onChange={this.props.newReasonChangeHandler}
                        style={{
                          border: "none",
                          borderBottom: "1px solid #C5C5D3",
                          width: "135px",
                          marginBottom: "15px",
                          outline: "none",
                        }}
                      />
                      <button
                        disabled={this.props.newLostReason.length < 1}
                        style={{
                          marginLeft: "5px",
                        }}
                        className="button-sm"
                        type="button"
                        onClick={() =>
                          this.props.addReasonHandler(
                            "lost",
                            this.props.newLostReason
                          )
                        }
                      >
                        Add
                      </button>
                    </form>
                  </div>
                </div>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.props.isLoading}
                  >
                    {this.props.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default CloseReasonChangeModal;
