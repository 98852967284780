import React, { Component } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";

class CreateTeamModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    name: "",
    parentTeam: null,
    teamLeader: [],
    frontendTeamLeader: [],
    teamMember: [],
    frontendTeamMember: [],
    description: null,
    allUsers: [],
    allTeams: [],
    errorMessage: false,
    isLoading: false,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    axios({
      method: "GET",
      url: `/admin/users`,
    })
      .then((response) => {
        this.setState({
          allUsers: response.data.users?.slice()?.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // Ignore case for comparison
            const nameB = b.name.toUpperCase(); // Ignore case for comparison

            if (nameA < nameB) {
              return -1; // Negative value: a should come before b
            }
            if (nameA > nameB) {
              return 1; // Positive value: b should come before a
            }
            return 0; // Return 0 for elements considered equal
          }),
        });
      })
      .catch((error) => console.log("error", error));
    axios({
      method: "GET",
      url: `/admin/teams?view=table`,
    })
      .then((response) => {
        this.setState({ allTeams: response.data.teams });
      })
      .catch((error) => console.log("error", error));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value === "" ? null : value,
    });
  };
  teamLeaderChangeHandler = (selected) => {
    console.log("selected:", selected);
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    console.log("QWE:", selectedArray);

    this.setState((prevState) => ({
      teamLeader: selectedArray,
      frontendTeamLeader: selected,
    }));
  };
  teamMemberChangeHandler = (selected) => {
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];

    this.setState((prevState) => ({
      teamMember: selectedArray,
      frontendTeamMember: selected,
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (!data.name) {
      this.setState({ errorMessage: true });
      return false;
    }
    const teamL = this.state.teamLeader?.map((leader) => {
      return { user_id: leader, role: "leader" };
    });
    const teamM = this.state.teamMember?.map((member) => {
      return { user_id: member, role: "member" };
    });
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/admin/teams`,
      data: {
        name: this.state.name,
        description: this.state.description,
        parent_id: this.state.parentTeam === "" ? null : this.state.parentTeam,
        users: teamL.concat(teamM),
      },
    })
      .then((res) => {
        this.props.fetchTeams();
        this.props.handleCloseModal();
        NotificationManager.success("Team created successfully.");
        this.setState({
          name: "",
          parentTeam: null,
          teamLeader: [],
          frontendTeamLeader: [],
          teamMember: [],
          frontendTeamMember: [],
          description: null,
          errorMessage: false,
          isLoading: false,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error creating team.");
        this.setState({ isLoading: false });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "97%",
        outline: "#C0EAD8",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#C0EAD8"
            : null,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#C0EAD8"),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "#C0EAD8",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
      }),
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "65vh", top: "12%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-users" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Create Team</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <label>Name</label>
                <input
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.name === null || this.state.name === "")
                      ? {
                          border: "2px solid #F36363",
                        }
                      : {}
                  }
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.name === null || this.state.name === "")
                    ? "Name is required"
                    : null}
                </div>
                <label>Description</label>
                <input
                  name="description"
                  type="text"
                  value={this.state.description}
                  onChange={this.changeHandler}
                />
                <label>Parent</label>
                <select
                  name="parentTeam"
                  value={this.state.parentTeam}
                  onChange={this.changeHandler}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  <option value="">{this.props.companyName}</option>
                  {this.state.allTeams?.map((team) => {
                    const { id, name } = team;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <label>Team Leader(s)</label>
                <Select
                  value={this.state.frontendTeamLeader}
                  closeMenuOnSelect={false}
                  isMulti
                  name="teamLeader"
                  styles={colourStyles}
                  options={this.state.allUsers
                    ?.filter((user) => !this.state.teamMember.includes(user.id))
                    .map((user) => {
                      const { id, name } = user;
                      return { value: id, label: name };
                    })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOption) =>
                    this.teamLeaderChangeHandler(selectedOption)
                  }
                />
                <label>Team Member(s)</label>
                <Select
                  value={this.state.frontTeamMember}
                  closeMenuOnSelect={false}
                  isMulti
                  name="teamMember"
                  styles={colourStyles}
                  options={this.state.allUsers
                    ?.filter((user) => !this.state.teamLeader.includes(user.id))
                    .map((user) => {
                      const { id, name } = user;
                      return { value: id, label: name };
                    })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOption) =>
                    this.teamMemberChangeHandler(selectedOption)
                  }
                />
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default CreateTeamModal;
