import React from "react";
import "../../sass/Sidebar.scss";
import { Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { authUserSet } from "../../redux";

function Sidebar(props) {
  console.log("side-bar", window.location.pathname);
  return (
    <div className="settings-sidebar">
      <nav className="settings-sidebar-menu">
        <h2 className="settings-sidebar-heading">Settings</h2>
        <ul>
          <li>
            <Link
              to="/me"
              className={
                window.location.pathname === "/me" &&
                "settings-sidebar-menu-selected-link"
              }
            >
              <i className="fa fa-user"></i>
              <span className="nav-text">Profile</span>
            </Link>
          </li>
          <li className="has-subnav">
            <Link
              to="/me/auth"
              className={
                window.location.pathname === "/me/auth" &&
                "settings-sidebar-menu-selected-link"
              }
            >
              <i className="fa fa-lock"></i>
              <span className="nav-text">2 Factor Authentication</span>
            </Link>
          </li>
          {props.authUser.permissions.includes("crm:all:editor") && (
            <li className="has-subnav">
              <Link
                to="/me/company"
                className={
                  window.location.pathname === "/me/company" &&
                  "settings-sidebar-menu-selected-link"
                }
              >
                <i className="fa fa-building-o"></i>
                <span className="nav-text">Company Settings</span>
              </Link>
            </li>
          )}
          <li className="has-subnav">
            <Link
              to="/me/notification"
              className={
                window.location.pathname === "/me/notification" &&
                "settings-sidebar-menu-selected-link"
              }
            >
              <i className="fa fa-bell"></i>
              <span className="nav-text">Notifications</span>
            </Link>
          </li>
          <li className="has-subnav">
            <Link
              to="/me/reset-password"
              className={
                window.location.pathname === "/me/reset-password" &&
                "settings-sidebar-menu-selected-link"
              }
            >
              <i className="fa fa-lock"></i>
              <span className="nav-text">Reset Password</span>
            </Link>
          </li>
          {/* <li className="has-subnav">
            <Link
              to="/me/zapier"
              className={
                window.location.pathname === "/me/zapier" &&
                "settings-sidebar-menu-selected-link"
              }
            >
              <i className="fa fa-lock"></i>
              <span className="nav-text">Zapier Settings</span>
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
}

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(Sidebar);
