import { SET_CURRENT_ACTIVITY } from "./types";

const INITIAL_STATE = {
  fetchActivity: false,
  data: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_ACTIVITY:
      const updatedObject = { ...state };
      for (const key in action.payload) {
        if (action.payload.hasOwnProperty(key)) {
          updatedObject[key] = action.payload[key];
        }
      }
      return updatedObject;
    default:
      return state;
  }
};

export default reducer;
