import React from "react";
import { YOUTUBE_VIDEOS } from "../../../constants/youtubeVideos";

function OnboardingVideoSidebar({
  setSelectedVideo,
  selectedVideo,
  showSidebar,
  setShowSidebar,
}) {
  return (
    <div
      className={`onboarding-video-sidebar ${
        showSidebar && "onboarding-video-sidebar-display"
      }`}
    >
      <div className="onboarding-video-sidebar-list">
        <div
          className="onboarding-video-sidebar-list-item mobile-only"
          onClick={() => setShowSidebar(false)}
        >
          <i
            className="fa fa-times"
            aria-hidden="true"
            style={{ marginRight: "10px" }}
          ></i>{" "}
          Close Menu
        </div>
        {YOUTUBE_VIDEOS.map((video) => (
          <>
            <div className="walk-through-sidebar-list-group">{video.group}</div>
            {video.videos.map((vid) => (
              <div
                className="onboarding-video-sidebar-list-item"
                onClick={() => {
                  setSelectedVideo(vid);
                  setShowSidebar(false);
                }}
                style={
                  selectedVideo.src === vid.src
                    ? {
                        color: "#41c99d",
                        fontWeight: "600",
                      }
                    : {}
                }
              >
                {selectedVideo.src === vid.src && (
                  <i
                    className="fa fa-caret-right"
                    aria-hidden="true"
                    style={{ marginRight: "10px" }}
                  ></i>
                )}
                {vid.title}
              </div>
            ))}
          </>
        ))}
      </div>
    </div>
  );
}

export default OnboardingVideoSidebar;
