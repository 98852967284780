import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartColors } from "../../../constants/chartColors";
import moment from "moment";
class PeriodBarChart extends Component {
  state = {
    // To avoid unnecessary update keep all options in the state.
    chartOptions: {
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: null,
      xAxis: {
        categories: ["Africa", "America", "Asia", "Europe", "Oceania"],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Count",
        },
        labels: {
          overflow: "justify",
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [],
    },
    hoverData: null,
  };

  componentDidMount() {
    this.setState({
      chartOptions: {
        ...this.state.chartOptions,
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series:
          this.props.filter === "weekly"
            ? [
                {
                  name: this.props.type,
                  data: this.props.data?.map((d, index) => ({
                    y: d.activity_count,
                    color: chartColors[d.week % chartColors.length],
                  })),
                },
              ]
            : [
                {
                  name: this.props.type,
                  data: this.props.data?.map((d, index) => ({
                    y: d.activity_count,
                    color: chartColors[index % chartColors.length],
                  })),
                },
              ],
        yAxis: {
          min: 0,
          title: {
            text: `Number of ${this.props.type}`,
          },
          labels: {
            overflow: "justify",
          },
        },
        xAxis: {
          categories: this.props.data?.map((d) => {
            let name = d.date;
            if (this.props.filter === "weekly") {
              name = moment(d.date).format("MMM DD");
            } else if (this.props.filter === "monthly") {
              name = `${d.month_name} ${d.year}`;
            } else if (this.props.filter === "quarterly") {
              name = `${d.quarter_name}(${d.year})`;
            } else if (this.props.filter === "yearly") {
              name = `${d.year}`;
            }
            return name;
          }),
        },
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    this.chart.setSize(this.props.reportWidth);
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        chartOptions: {
          ...this.state.chartOptions,
          series:
            this.props.filter === "weekly"
              ? [
                  {
                    name: this.props.type,
                    data: this.props.data?.map((d, index) => ({
                      y: d.activity_count,
                      color: chartColors[d.week % chartColors.length],
                    })),
                  },
                ]
              : [
                  {
                    name: this.props.type,
                    data: this.props.data?.map((d, index) => ({
                      y: d.activity_count,
                      color: chartColors[index % chartColors.length],
                    })),
                  },
                ],
          yAxis: {
            min: 0,
            title: {
              text: `Number of ${this.props.type}`,
            },
            labels: {
              overflow: "justify",
            },
          },
          xAxis: {
            categories: this.props.data?.map((d) => {
              let name = d.date;
              if (this.props.filter === "weekly") {
                name = moment(d.date).format("MMM DD");
              } else if (this.props.filter === "monthly") {
                name = `${d.month_name} ${d.year}`;
              } else if (this.props.filter === "quarterly") {
                name = `${d.quarter_name}(${d.year})`;
              } else if (this.props.filter === "yearly") {
                name = `${d.year}`;
              }
              return name;
            }),
          },
        },
      });
    }
  }
  setChart(chart) {
    this.chart = chart;
  }

  render() {
    const { chartOptions } = this.state;
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          callback={(chart) => this.setChart(chart)}
        />
      </div>
    );
  }
}

export default PeriodBarChart;
