import ReactModal from "react-modal";

const ActivityIdentifierModal = ({
  showModal,
  handleCloseModal,
  modalHeading,
  modalHeight,
  modalTop,
  activityRecordType,
  uniqueIdentifierFieldName,
  activityIdentifierChangeHandler,
  uniqueIdentifierMap,
}) => {
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">{modalHeading}</h2>
              </div>
            </div>
            <div>
              <h3
                style={{
                  margin: "4px 0",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Activity Record Type
              </h3>
              <label>
                Please select if you are importing these activities to Accounts
                or Contacts.
              </label>
              <select
                value={activityRecordType}
                style={{ width: "70%" }}
                onChange={(e) => {
                  activityIdentifierChangeHandler(
                    "activityRecordType",
                    e.target.value
                  );
                  activityIdentifierChangeHandler(
                    "uniqueIdentifierFieldName",
                    ""
                  );
                }}
              >
                <option value="accounts">Accounts</option>
                <option value="contacts">Contacts</option>
              </select>
              <h3
                style={{
                  margin: "4px 0",
                  marginTop: "10px",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Activity Unique Identifier
              </h3>
              <label>
                Please select how you plan to tag these activities to the
                correct record type.
              </label>
              <select
                value={uniqueIdentifierFieldName}
                style={{ width: "70%" }}
                onChange={(e) =>
                  activityIdentifierChangeHandler(
                    "uniqueIdentifierFieldName",
                    e.target.value
                  )
                }
              >
                <option value="">-</option>
                {uniqueIdentifierMap[activityRecordType]?.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__confirm-button"
                onClick={handleCloseModal}
                disabled={!uniqueIdentifierFieldName}
                style={
                  !uniqueIdentifierFieldName
                    ? {
                        color: "#a7abaa",
                        zIndex: "auto",
                        background: "#e5e5e5",
                        fontWeight: "400",
                        cursor: "not-allowed",
                      }
                    : { zIndex: "auto" }
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default ActivityIdentifierModal;
