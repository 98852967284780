import React from "react";
import "../../sass/DropdownHeader.scss";
import ArrowIcon from "../../images/ArrowDown.svg";

class DropdownFunnel extends React.Component {
  state = {
    displayMenu: false,
  };

  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  render() {
    return (
      <div
        className="dropdownAccount"
        ref={this.wrapperRef}
        style={{
          background: "transparent",
          width: "15px",
        }}
      >
        <div
          className="button"
          onClick={this.showDropdownMenu}
          style={{ fontSize: "14px" }}
        >
          <img src={ArrowIcon} alt="V" width="10px" />
        </div>

        {this.state.displayMenu ? (
          <ul style={{ width: "171px", top: "30px", zIndex: "1000" }}>
            {this.props.allFunnels?.map((funnel) => {
              const { id, name } = funnel;
              return (
                <li
                  style={{ cursor: "pointer" }}
                  key={id}
                  value={id}
                  onClick={() => {
                    this.props.getAllFunnels(id);
                    this.setState({ displayMenu: false });
                    this.props.setRefresh && this.props.setRefresh(id);
                  }}
                >
                  {name}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }
}

export default DropdownFunnel;
