import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import "./InvoicingModal.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import moment from "moment";
import InvoiceViewMode from "./InvoiceViewMode";
import jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import SalesdashLogo from "../../images/SalesdashLogo.svg";
import InvoiceSendModal from "./InvoiceSendModal";
import { BASE_URL } from "../../config";
import { NotificationManager } from "react-notifications";

function EditInvoicingModal({
  showModal,
  handleCloseModal,
  modalHeight,
  modalWidth,
  modalTop,
  modalLeft,
  dateFormat,
  opportunityId,
  getAllInvoices,
  invoiceData,
}) {
  const imageRef = useRef(null);
  const openFileSelect = () => imageRef.current.click();
  //states
  const [displayAdjustmentMenu, setDisplayAdjustmentMenu] = useState(false);
  const [displaySettingsMenu, setDisplaySettingsMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [mode, setMode] = useState(false);
  const [salesdashLogo, setSalesdashLogo] = useState("");
  const [showSendModal, setShowSendModal] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState("");
  const [isError, setIsError] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState(
    invoiceData?.currency
  );
  const [showSignature, setShowSignature] = useState(
    invoiceData?.with_signature === 1 ? true : false
  );
  const [invoiceName, setInvoiceName] = useState(invoiceData?.name);
  const [invoiceNumber, setInvoiceNumber] = useState(invoiceData?.number || "");
  const [dateIssued, setDateIssued] = useState(new Date());
  const [invoiceTitle, setInvoiceTitle] = useState(invoiceData?.title);
  const [invoiceDescription, setInvoiceDescription] = useState(
    invoiceData?.description
  );
  const [companyLogo, setCompanyLogo] = useState(invoiceData?.image);
  const [billFromTitle, setBillFromTitle] = useState(
    invoiceData?.bill_from?.heading
  );
  const [billToTitle, setBillToTitle] = useState(invoiceData?.bill_to?.heading);
  const [fromCompanyData, setFromCompanyData] = useState({
    name: invoiceData?.bill_from?.company_name,
    address: invoiceData?.bill_from?.company_address,
    country: invoiceData?.bill_from?.company_country,
    email: invoiceData?.bill_from?.email,
    mobile: invoiceData?.bill_from?.mobile,
  });
  const [toCompanyData, setToCompanyData] = useState({
    name: invoiceData?.bill_to?.name || "",
    address: invoiceData?.bill_to?.company_address,
    companyName: invoiceData?.bill_to?.company_name,
    email: invoiceData?.bill_to?.email,
    mobile: invoiceData?.bill_to?.mobile,
  });
  const [items, setItems] = useState(
    invoiceData?.items?.map((item) => ({
      name: item.name,
      quantity: item.quantity,
      price: item.price,
      total: parseInt(item.quantity) * parseInt(item.price),
      id: item.id,
    })) || []
  );
  const [adjustments, setAdjustments] = useState(
    invoiceData?.rates?.map((rate) => ({
      title: rate.title,
      type: rate.type,
      value: rate.value,
      value_type: rate.value_type,
      condition: rate.condition,
      id: rate.id,
    })) || []
  );
  const [totalPrice, setTotalPrice] = useState(0);
  const [notes, setNotes] = useState(invoiceData?.notes);

  const getImg = async () => {
    const response = await fetch(SalesdashLogo);
    const imageBlob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onloadend = () => {
      const base64data = reader.result;
      setSalesdashLogo(base64data);
    };
  };
  // edit mode get values
  useEffect(() => {
    const setEditValues = () => {
      setSelectedCurrency(invoiceData?.currency);
      setShowSignature(invoiceData?.with_signature === 1 ? true : false);
      setInvoiceName(invoiceData?.name);
      setInvoiceNumber(invoiceData?.invoice_number || "");
      setDateIssued(new Date());
      setInvoiceTitle(invoiceData?.title);
      setInvoiceDescription(invoiceData?.description);
      setCompanyLogo(invoiceData?.image);
      setBillFromTitle(invoiceData?.bill_from?.heading);
      setBillToTitle(invoiceData?.bill_to?.heading);
      setFromCompanyData({
        name: invoiceData?.bill_from?.company_name,
        address: invoiceData?.bill_from?.company_address,
        country: invoiceData?.bill_from?.company_country,
        email: invoiceData?.bill_from?.email,
        mobile: invoiceData?.bill_from?.mobile,
      });
      setToCompanyData({
        name: invoiceData?.bill_to?.customer_name || "",
        address: invoiceData?.bill_to?.company_address,
        companyName: invoiceData?.bill_to?.company_name,
        email: invoiceData?.bill_to?.email,
        mobile: invoiceData?.bill_to?.mobile,
      });
      setItems(
        invoiceData?.items?.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          total: parseInt(item.quantity) * parseInt(item.price),
          id: item.id,
        })) || []
      );
      setAdjustments(
        invoiceData?.rates?.map((rate) => ({
          title: rate.title,
          type: rate.type,
          value: rate.value,
          value_type: rate.value_type,
          condition: rate.condition,
          id: rate.id,
        })) || []
      );
      setTotalPrice(0);
      setNotes(invoiceData?.notes);
    };
    setEditValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    getImg();
  }, []);

  // image uploader
  const handleImageChange = (event) => {
    submitAttachment(event.target.files[0]);
  };
  const submitAttachment = (selectedFile) => {
    setImageLoader(true);
    const formData = new FormData();
    formData.append("attachment", selectedFile);
    formData.append("opportunity_id", opportunityId);
    axios({
      method: "POST",
      url: `/attachments`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        setCompanyLogo(res.data.s3_url);
        setImageLoader(false);
      })
      .catch((err) => {
        setImageLoader(false);
        NotificationManager.error("Error uploading company logo");
      });
  };

  // hook
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDisplayAdjustmentMenu(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideSettingsAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDisplaySettingsMenu(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const settingsRef = useRef(null);
  useOutsideSettingsAlerter(settingsRef);

  //changeHandlers
  const dateChangeHandler = (date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    setDateIssued(offsetDate);
  };
  // total price
  useEffect(() => {
    let totalItemsPrice = 0;
    for (let i = 0; i < items.length; i++) {
      totalItemsPrice += parseInt(items[i]?.total);
    }
    for (let i = 0; i < adjustments.length; i++) {
      if (adjustments[i].condition === "negative") {
        if (adjustments[i].value_type === "fixed") {
          totalItemsPrice -=
            adjustments[i].value === "" ? 0 : parseInt(adjustments[i].value);
        } else {
          totalItemsPrice -=
            adjustments[i].value === ""
              ? 0
              : totalItemsPrice * (parseInt(adjustments[i].value) / 100);
        }
      } else {
        if (adjustments[i].value_type === "fixed") {
          totalItemsPrice +=
            adjustments[i].value === "" ? 0 : parseInt(adjustments[i].value);
        } else {
          totalItemsPrice +=
            adjustments[i].value === ""
              ? 0
              : totalItemsPrice * (parseInt(adjustments[i].value) / 100);
        }
      }
    }
    setTotalPrice(totalItemsPrice);
  }, [items, adjustments]);

  //items
  const itemsBlurHandler = (id, e) => {
    const { name, value } = e.target;
    const newItems = items?.map((item) => {
      if (item.id === id) {
        item = {
          ...item,
          [name]:
            (name === "quantity" && value === "") ||
            (name === "price" && value === "")
              ? 0
              : value,
          total:
            name === "quantity"
              ? parseInt(item.price) * parseInt(value === "" ? 0 : value)
              : name === "price"
              ? parseInt(value === "" ? 0 : value) * parseInt(item.quantity)
              : parseInt(item.price) * parseInt(item.quantity),
        };
      }
      return item;
    });
    setItems(newItems);
  };
  const itemsChangeHandler = (id, e) => {
    const { name, value } = e.target;
    const newItems = items?.map((item) => {
      if (item.id === id) {
        item = {
          ...item,
          [name]: value,
          total:
            name === "quantity"
              ? parseInt(item.price) * parseInt(value === "" ? 0 : value)
              : name === "price"
              ? parseInt(value === "" ? 0 : value) * parseInt(item.quantity)
              : parseInt(item.price) * parseInt(item.quantity),
        };
      }
      return item;
    });
    setItems(newItems);
  };
  const addItems = () => {
    const newItems = [
      ...items,
      {
        name: "Item#1",
        quantity: 0,
        price: 0,
        total: 0,
        id: uuidv4(),
      },
    ];
    setItems(newItems);
  };
  const deleteItem = (id) => {
    const filteredItems = items?.filter((item) => item.id !== id);
    setItems(filteredItems);
  };
  //adjustments
  const addAdjustments = (type) => {
    let newAdjustment = {};
    if (type === "discount") {
      newAdjustment = {
        title: "discount",
        type: "discount",
        value: 0,
        value_type: "fixed",
        condition: "negative",
        id: uuidv4(),
      };
    } else if (type === "tax") {
      newAdjustment = {
        title: "VAT",
        type: "tax",
        value: 0,
        value_type: "fixed",
        condition: "positive",
        id: uuidv4(),
      };
    } else {
      newAdjustment = {
        title: "fee",
        type: "fee",
        value: 0,
        value_type: "fixed",
        condition: "positive",
        id: uuidv4(),
      };
    }
    setAdjustments([...adjustments, newAdjustment]);
    setDisplayAdjustmentMenu(false);
  };
  const deleteAdjustment = (id) => {
    const filteredAdjustments = adjustments?.filter((item) => item.id !== id);
    setAdjustments(filteredAdjustments);
  };
  const adjustmentsChangeHandler = (id, e) => {
    const { name, value } = e.target;
    const newItems = adjustments?.map((item) => {
      if (item.id === id) {
        item = {
          ...item,
          [name]: value,
        };
      }
      return item;
    });
    setAdjustments(newItems);
  };
  const adjustmentsBlurHandler = (id, e) => {
    const { name, value } = e.target;
    const newItems = adjustments?.map((item) => {
      if (item.id === id) {
        item = {
          ...item,
          [name]: name === "value" && value === "" ? 0 : value,
        };
      }
      return item;
    });
    setAdjustments(newItems);
  };
  // submit handlers
  const createInvoice = () => {
    if (
      invoiceName === "" ||
      invoiceName === null ||
      invoiceName === undefined
    ) {
      setIsError(true);
      setLoading(false);
      return false;
    }
    setLoading(true);
    axios({
      method: "PUT",
      url: `/opportunities/${opportunityId}/invoice`,
      data: {
        date_issued: moment(dateIssued).format("YYYY-MM-DD"),
        image: companyLogo === "" ? null : companyLogo,
        name: invoiceName,
        title: invoiceTitle === "" ? null : invoiceTitle,
        description: invoiceDescription === "" ? null : invoiceDescription,
        with_signature: showSignature,
        notes: notes === "" ? null : notes,
        currency: selectedCurrency,
        bill_from: {
          company_name:
            fromCompanyData.name === "" ? null : fromCompanyData.name,
          company_address:
            fromCompanyData.address === "" ? null : fromCompanyData.address,
          company_country:
            fromCompanyData.country === "" ? null : fromCompanyData.country,
          email: fromCompanyData.email === "" ? null : fromCompanyData.email,
          mobile:
            fromCompanyData.mobile === ""
              ? null
              : fromCompanyData.mobile.toString(),
          heading: billFromTitle === "" ? null : billFromTitle,
        },
        bill_to: {
          company_name:
            toCompanyData.companyName === "" ? null : toCompanyData.companyName,
          customer_name: toCompanyData.name === "" ? null : toCompanyData.name,
          company_address:
            toCompanyData.address === "" ? null : toCompanyData.address,
          email: toCompanyData.email === "" ? null : toCompanyData.email,
          mobile:
            toCompanyData.mobile === ""
              ? null
              : toCompanyData.mobile.toString(),
          heading: billToTitle === "" ? null : billToTitle,
        },
        items: items?.map((item) => ({
          name: item.name,
          price: item.price,
          quantity: item.quantity,
        })),
        invoice_number: invoiceNumber === "" ? null : invoiceNumber,
        rates:
          adjustments.length === 0
            ? null
            : adjustments?.map((adjustment) => ({
                title: adjustment.title,
                type: adjustment.type,
                value: adjustment.value,
                value_type: adjustment.value_type,
                condition: adjustment.condition,
              })),
      },
    })
      .then((res) => {
        setLoading(false);
        setIsError(false);
        handleCloseModal();
        getAllInvoices();
        NotificationManager.success("Quote edited successfully");
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error editing quote");
      });
  };

  const generateInvoices = () => {
    setMode(true);
    setSendLoading(true);
    setTimeout(() => {
      const input = document.getElementById("invoiceToPrint");
      console.log(input);
      html2canvas(input, {
        useCORS: false,
        allowTaint: false,
        proxy: `${BASE_URL}/proxy`,
      }).then((dataUrl) => {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, "PNG", 0, 0);
        var blob = pdf.output("blob");
        const formData = new FormData();
        formData.append("invoice", blob);
        axios({
          method: "POST",
          url: `/opportunities/generate-invoice-link`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
          .then((res) => {
            // res.data.file.key
            // res.data.file.location
            setInvoiceLink(res.data.file.location);
            setSendLoading(false);
          })
          .then((res) => setShowSendModal(true))
          .catch((err) => setSendLoading(false));
      });
    }, 2000);
  };
  const printDocument = () => {
    setMode(true);
    setDownloadLoading(true);
    setTimeout(() => {
      const input = document.getElementById("invoiceToPrint");
      html2canvas(input, {
        useCORS: false,
        allowTaint: false,
        proxy: `${BASE_URL}/proxy`,
      })
        .then((dataUrl) => {
          const pdf = new jsPDF();
          pdf.addImage(dataUrl, "PNG", 0, 0);
          // pdf.output('dataurlnewwindow');
          pdf.save("download.pdf");
        })
        .then((res) => setDownloadLoading(false))
        .catch((err) => setDownloadLoading(false));
    }, 2000);
  };
  // style
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  const myDateFormat = dateFormat === "DD/MM/YYYY" ? "DD/MM/YYYY" : "l";
  //test

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{
              height: `${modalHeight}vh`,
              top: `${modalTop}%`,
              width: `${modalWidth}vw`,
              left: `${modalLeft}%`,
            }}
          >
            <div className="modal__header">
              <div
                className="modal__head"
                style={{ marginLeft: "0", width: "40%" }}
              >
                <h2 className="modal__heading">
                  <input
                    value={invoiceName}
                    onChange={(e) => setInvoiceName(e.target.value)}
                    style={
                      isError === true &&
                      (invoiceName === null ||
                        invoiceName === "" ||
                        invoiceName === undefined)
                        ? {
                            border: "2px solid #F36363",
                            fontSize: "18px",
                            fontWeight: "500",
                          }
                        : { fontSize: "18px", fontWeight: "500" }
                    }
                  />
                </h2>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {isError === true &&
                  (invoiceName === null ||
                    invoiceName === "" ||
                    invoiceName === undefined)
                    ? "Invoice name is required"
                    : null}
                </div>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <div className="invoicing-menu-container">
              <div style={{ position: "relative" }}>
                <span
                  onClick={() => setDisplaySettingsMenu(!displaySettingsMenu)}
                  className="invoicing-settings-span"
                >
                  <i className="fa fa-cog" aria-hidden="true"></i>
                </span>
                {displaySettingsMenu ? (
                  <ul ref={settingsRef} className="invoicing-menu">
                    <li>
                      <span style={{ fontWeight: "500", marginRight: "5px" }}>
                        Currency:{" "}
                      </span>
                      <label
                        style={
                          selectedCurrency === "$"
                            ? { backgroundColor: "#a7e4fa" }
                            : {}
                        }
                      >
                        <input
                          type="radio"
                          value={selectedCurrency}
                          style={{ display: "none" }}
                          name="currency"
                          onChange={() => setSelectedCurrency("$")}
                        />
                        $
                      </label>
                      <label
                        style={
                          selectedCurrency === "£"
                            ? { backgroundColor: "#a7e4fa" }
                            : {}
                        }
                      >
                        <input
                          type="radio"
                          value={selectedCurrency}
                          style={{ display: "none" }}
                          name="currency"
                          onChange={() => setSelectedCurrency("£")}
                        />
                        £
                      </label>
                      <label
                        style={
                          selectedCurrency === "€"
                            ? { backgroundColor: "#a7e4fa" }
                            : {}
                        }
                      >
                        <input
                          type="radio"
                          value={selectedCurrency}
                          style={{ display: "none" }}
                          name="currency"
                          onChange={() => setSelectedCurrency("€")}
                        />
                        €
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          style={{
                            display: "inline-block",
                            width: "12px",
                            transform: "translateY(1px)",
                            marginRight: "5px",
                          }}
                          value={showSignature}
                          checked={showSignature}
                          onChange={() => setShowSignature(!showSignature)}
                        />
                        Show Signature
                      </label>
                    </li>
                  </ul>
                ) : null}
              </div>
              <div>
                <span
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                  onClick={() => setMode(false)}
                >
                  Edit
                </span>{" "}
                <label
                  className="invoice-switch"
                  style={{ transform: "translate(0px,3px)" }}
                >
                  <input
                    type="checkbox"
                    id="checkboxView"
                    checked={mode}
                    onChange={() => setMode(!mode)}
                  />
                  <span className="invoice-slider"></span>
                </label>{" "}
                <span
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                  onClick={() => setMode(true)}
                >
                  View
                </span>
              </div>
              <div>
                <button
                  className="invoicing-menu-container-download"
                  onClick={createInvoice}
                  type="button"
                >
                  {loading ? "Saving" : "Save"}
                </button>
                <button
                  className="invoicing-menu-container-download"
                  onClick={printDocument}
                  type="button"
                  disabled={downloadLoading}
                >
                  {downloadLoading ? "Downloading" : "Download"}
                </button>
                <button
                  type="button"
                  disabled={sendLoading}
                  onClick={generateInvoices}
                  className="invoicing-menu-container-send"
                >
                  {sendLoading ? "Sending" : "Send"}
                </button>
              </div>
            </div>
            <InvoiceSendModal
              showModal={showSendModal}
              handleCloseModal={() => setShowSendModal(false)}
              message={"Copy the link below to share your document"}
              link={invoiceLink}
            />
            <div className="invoicing-background">
              <div style={!mode ? { display: "none" } : {}}>
                <InvoiceViewMode
                  invoiceNumber={invoiceNumber}
                  dateIssued={moment(dateIssued).format(myDateFormat)}
                  invoiceTitle={invoiceTitle}
                  invoiceDescription={invoiceDescription}
                  companyLogo={companyLogo}
                  fromCompanyData={fromCompanyData}
                  toCompanyData={toCompanyData}
                  items={items}
                  adjustments={adjustments}
                  totalPrice={totalPrice}
                  notes={notes}
                  billToTitle={billToTitle}
                  billFromTitle={billFromTitle}
                  selectedCurrency={selectedCurrency}
                  showSignature={showSignature}
                  salesdashLogo={salesdashLogo}
                />
              </div>
              <div
                className="invoicing-form"
                style={mode ? { display: "none" } : {}}
              >
                <div className="invoicing-form-header">
                  <div className="invoicing-form-flex">
                    <div
                      style={{
                        transform: "translateY(10px)",
                        marginRight: "5px",
                        fontSize: "14px",
                      }}
                    >
                      #
                    </div>
                    <input
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      className="invoicing-form-input"
                    />
                  </div>
                  <div className="invoicing-form-flex">
                    <div
                      style={{
                        transform: "translateY(10px)",
                        marginRight: "5px",
                        fontSize: "14px",
                      }}
                    >
                      Date Issued:
                    </div>
                    <DatePicker
                      selected={dateIssued}
                      todayButton="Today"
                      value={dateIssued}
                      onChange={(date) => dateChangeHandler(date)}
                      placeholderText="Date"
                      dateFormat={
                        dateFormat === "DD/MM/YYYY"
                          ? "dd/MM/yyyy"
                          : "MM/dd/yyyy"
                      }
                    />
                  </div>
                </div>
                <div
                  className="invoicing-form-header"
                  style={{ marginTop: "24px" }}
                >
                  <div>
                    <input
                      value={invoiceTitle}
                      onChange={(e) => setInvoiceTitle(e.target.value)}
                      className={`${
                        invoiceTitle === "" && "invoicing-form-input-invisible"
                      }`}
                      style={{ fontWeight: "500", fontSize: "20px" }}
                    />
                    <input
                      value={invoiceDescription}
                      onChange={(e) => setInvoiceDescription(e.target.value)}
                      className={`${
                        invoiceTitle === "" && "invoicing-form-input-invisible"
                      }`}
                    />
                  </div>
                  <div
                    onClick={openFileSelect}
                    style={{
                      border: "1px dashed #e5e5e7",
                      height: "70px",
                      width: "32%",
                    }}
                  >
                    {imageLoader ? (
                      <div class="load-wrapp">
                        <div class="load-3">
                          <div class="line"></div>
                          <div class="line"></div>
                          <div class="line"></div>
                        </div>
                      </div>
                    ) : companyLogo ? (
                      <img
                        src={companyLogo}
                        alt=""
                        height="66px"
                        style={{
                          display: "block",
                          margin: "auto",
                          marginTop: "2px",
                          maxWidth: "220px",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          display: "block",
                          margin: "auto",
                          color: "#e5e5e7",
                          textAlign: "center",
                          marginTop: "10%",
                        }}
                      >
                        Company Logo
                      </div>
                    )}
                  </div>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleImageChange}
                    ref={imageRef}
                  />
                </div>
                <div
                  className="invoicing-form-header"
                  style={{ marginTop: "24px" }}
                >
                  <div>
                    <input
                      value={billFromTitle}
                      className={`${
                        billFromTitle === "" && "invoicing-form-input-invisible"
                      }`}
                      style={{ fontWeight: "500", fontSize: "20px" }}
                      onChange={(e) => setBillFromTitle(e.target.value)}
                    />
                    <label style={{ fontWeight: "500" }}>
                      Your company information
                    </label>
                    <div>
                      <label>Company name</label>
                      <input
                        value={fromCompanyData.name}
                        onChange={(e) =>
                          setFromCompanyData({
                            ...fromCompanyData,
                            name: e.target.value,
                          })
                        }
                      />
                      <label>Company address</label>
                      <input
                        value={fromCompanyData.address}
                        onChange={(e) =>
                          setFromCompanyData({
                            ...fromCompanyData,
                            address: e.target.value,
                          })
                        }
                      />
                      <label>Company country</label>
                      <input
                        value={fromCompanyData.country}
                        onChange={(e) =>
                          setFromCompanyData({
                            ...fromCompanyData,
                            country: e.target.value,
                          })
                        }
                      />
                      <label>Contact email</label>
                      <input
                        value={fromCompanyData.email}
                        onChange={(e) =>
                          setFromCompanyData({
                            ...fromCompanyData,
                            email: e.target.value,
                          })
                        }
                      />
                      <label>Phone number</label>
                      <input
                        value={fromCompanyData.mobile}
                        onChange={(e) =>
                          setFromCompanyData({
                            ...fromCompanyData,
                            mobile: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <input
                      value={billToTitle}
                      onChange={(e) => setBillToTitle(e.target.value)}
                      className={`${
                        billToTitle === "" && "invoicing-form-input-invisible"
                      }`}
                      style={{ fontWeight: "500", fontSize: "20px" }}
                    />
                    <label style={{ fontWeight: "500" }}>
                      Customer information
                    </label>
                    <div>
                      <label>Company name</label>
                      <input
                        value={toCompanyData.companyName}
                        onChange={(e) =>
                          setToCompanyData({
                            ...toCompanyData,
                            companyName: e.target.value,
                          })
                        }
                      />
                      <label>Customer name</label>
                      <input
                        value={toCompanyData.name}
                        onChange={(e) =>
                          setToCompanyData({
                            ...toCompanyData,
                            name: e.target.value,
                          })
                        }
                      />
                      <label>Company address</label>
                      <input
                        value={toCompanyData.address}
                        onChange={(e) =>
                          setToCompanyData({
                            ...toCompanyData,
                            address: e.target.value,
                          })
                        }
                      />
                      <label>Contact email</label>
                      <input
                        value={toCompanyData.email}
                        onChange={(e) =>
                          setToCompanyData({
                            ...toCompanyData,
                            email: e.target.value,
                          })
                        }
                      />
                      <label>Phone number</label>
                      <input
                        value={toCompanyData.mobile}
                        onChange={(e) =>
                          setToCompanyData({
                            ...toCompanyData,
                            mobile: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "35% 20% 20% 20% 5%",
                    gridColumnGap: "5px",
                    fontWeight: "500",
                    marginTop: "30px",
                  }}
                >
                  <label>Item</label>
                  <label>Quantity</label>
                  <label>Price</label>
                  <label>Total</label>
                  <label> </label>
                </div>
                {items?.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "35% 20% 20% 20% 5%",
                      gridColumnGap: "5px",
                    }}
                  >
                    <input
                      value={item.name}
                      name="name"
                      onChange={(e) => itemsChangeHandler(item.id, e)}
                    />
                    <input
                      value={item.quantity}
                      name="quantity"
                      type="number"
                      onBlur={(e) => itemsBlurHandler(item.id, e)}
                      onChange={(e) => itemsChangeHandler(item.id, e)}
                      onWheel={(e) => e.target.blur()}
                    />
                    <input
                      value={item.price}
                      name="price"
                      type="number"
                      onBlur={(e) => itemsBlurHandler(item.id, e)}
                      onChange={(e) => itemsChangeHandler(item.id, e)}
                      onWheel={(e) => e.target.blur()}
                    />
                    <input value={item.total} readOnly />
                    <button
                      onClick={() => deleteItem(item.id)}
                      className="invoicing-form-delete-button"
                    >
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                  </div>
                ))}
                <button
                  onClick={addItems}
                  className="invoicing-settings-span"
                  style={{ fontSize: "14px" }}
                >
                  Add more items
                </button>
                <br />
                <hr />
                <br />
                <div style={{ position: "relative", marginBottom: "15px" }}>
                  <span
                    onClick={() =>
                      setDisplayAdjustmentMenu(!displayAdjustmentMenu)
                    }
                    className="invoicing-settings-span"
                    style={{ fontSize: "14px" }}
                  >
                    Add adjustments
                  </span>
                  {displayAdjustmentMenu ? (
                    <ul ref={wrapperRef} className="invoicing-menu">
                      <li
                        onClick={() => addAdjustments("discount")}
                        style={{
                          cursor: "pointer",
                          borderBottom: "1px solid #e5e5e7",
                          padding: "5px",
                        }}
                      >
                        Add discount
                      </li>
                      <li
                        onClick={() => addAdjustments("tax")}
                        style={{
                          cursor: "pointer",
                          borderBottom: "1px solid #e5e5e7",
                          padding: "5px",
                        }}
                      >
                        Add VAT
                      </li>
                      <li
                        onClick={() => addAdjustments("fee")}
                        style={{
                          cursor: "pointer",
                          padding: "5px",
                        }}
                      >
                        Add fee
                      </li>
                    </ul>
                  ) : null}
                </div>
                <div>
                  {adjustments?.map((adjustment) => (
                    <div
                      key={adjustment.id}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "45% 25% 25% 5%",
                        gridColumnGap: "5px",
                      }}
                    >
                      <input
                        value={adjustment.title}
                        onChange={(e) =>
                          adjustmentsChangeHandler(adjustment.id, e)
                        }
                        name="title"
                      />
                      <select
                        value={adjustment.value_type}
                        onChange={(e) =>
                          adjustmentsChangeHandler(adjustment.id, e)
                        }
                        name="value_type"
                        style={{ width: "100%" }}
                      >
                        <option value="fixed">Fixed</option>
                        <option value="percentage">%</option>
                      </select>
                      <input
                        value={adjustment.value}
                        onChange={(e) =>
                          adjustmentsChangeHandler(adjustment.id, e)
                        }
                        onBlur={(e) => adjustmentsBlurHandler(adjustment.id, e)}
                        name="value"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                      <button
                        onClick={() => deleteAdjustment(adjustment.id)}
                        className="invoicing-form-delete-button"
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </div>
                  ))}
                </div>
                <div
                  className="invoicing-form-view-total"
                  style={{ marginTop: "24px", marginBottom: "20px" }}
                >
                  <span>
                    Total{" "}
                    <span className="invoicing-form-view-total-value">
                      {selectedCurrency}
                      {totalPrice.toFixed(2)}
                    </span>
                  </span>
                </div>
                <div>
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Add Notes"
                  ></textarea>
                </div>
                <div>
                  {showSignature && (
                    <div className="invoicing-form-view-powered">
                      <span
                        style={{
                          display: "inline-block",
                          transform: "translateY(-6px)",
                          marginRight: "5px",
                        }}
                      >
                        Powered by
                      </span>
                      <img src={salesdashLogo} alt="Salesdash" height="25px" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default EditInvoicingModal;
