import axios from "axios";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import "../../../sass/Sequence.scss";
import { withRouter } from "react-router-dom";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../../images/DragHandler.svg";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactTooltip from "react-tooltip";

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ height: "22px", cursor: "grab" }}
    />
  </span>
));

const SortableItem = sortableElement(
  ({
    step,
    removeStep,
    setDelay,
    stepIndex,
    setTime,
    setType,
    setName,
    isError,
  }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DragHandle />
      <ReactTooltip />
      <div className="sequence-steps-2" style={{ textAlign: "left" }}>
        <button
          type="button"
          onClick={() => removeStep(stepIndex)}
          className="sequence-button-remove"
        >
          Remove
        </button>
        <span style={{ fontWeight: "500" }}>Task Name: </span>
        <input
          onChange={(e) => setName(stepIndex, e.target.value)}
          value={step.name}
          style={{ display: "inline-block", width: "80%" }}
        />
        <div>
          Remind in{" "}
          <input
            type="number"
            onChange={(e) => setDelay(stepIndex, parseInt(e.target.value))}
            onWheel={(e) => e.target.blur()}
            value={step.delay}
            style={{ display: "inline-block", width: "20%" }}
          />{" "}
          Days{" "}
          <span
            data-tip="Please set this for the amount of days from your first task in this sequence."
            style={{
              fontSize: "10px",
              fontWeight: "400",
              color: "#62CA9D",
              padding: "0px 5.3px",
              height: "14px",
              borderRadius: "50%",
              border: "1px solid #62CA9D",
              marginLeft: "10px",
            }}
            data-multiline={true}
          >
            i
          </span>
        </div>
        <div>
          <label>Type</label>
          <div className="modal-radioButton" style={{ margin: "5px 0 10px 0" }}>
            <label
              style={
                step.category_id === "1"
                  ? {
                      border: "2px solid #A0DFC4",
                      outline: "none",
                      color: "#555555",
                      padding: "4px 12px",
                      borderRadius: "9px",
                      marginRight: "13px",
                      background: "#F4F6F5",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
                  : {
                      color: "#555555",
                      padding: "4px 12px",
                      border: "none",
                      borderRadius: "9px",
                      background: "#F4F6F5",
                      marginRight: "13px",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
              }
            >
              Call
              <input
                type="radio"
                name="category_id"
                value={1}
                className="radio-button"
                onChange={(e) => setType(stepIndex, e.target.value)}
              />
            </label>
            <label
              style={
                step.category_id === "2"
                  ? {
                      border: "2px solid #A0DFC4",
                      outline: "none",
                      color: "#555555",
                      padding: "4px 12px",
                      borderRadius: "9px",
                      marginRight: "13px",
                      background: "#F4F6F5",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
                  : {
                      color: "#555555",
                      padding: "4px 12px",
                      border: "none",
                      borderRadius: "9px",
                      background: "#F4F6F5",
                      marginRight: "13px",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
              }
            >
              Email
              <input
                type="radio"
                name="category_id"
                value={2}
                className="radio-button"
                onChange={(e) => setType(stepIndex, e.target.value)}
              />
            </label>
            <label
              style={
                step.category_id === "3"
                  ? {
                      border: "2px solid #A0DFC4",
                      outline: "none",
                      color: "#555555",
                      padding: "4px 12px",
                      borderRadius: "9px",
                      marginRight: "13px",
                      background: "#F4F6F5",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
                  : {
                      color: "#555555",
                      padding: "4px 12px",
                      border: "none",
                      borderRadius: "9px",
                      background: "#F4F6F5",
                      marginRight: "13px",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
              }
            >
              Text
              <input
                type="radio"
                name="category_id"
                value={3}
                className="radio-button"
                onChange={(e) => setType(stepIndex, e.target.value)}
              />
            </label>
            <label
              style={
                step.category_id === "4"
                  ? {
                      border: "2px solid #A0DFC4",
                      outline: "none",
                      color: "#555555",
                      padding: "4px 12px",
                      borderRadius: "9px",
                      marginRight: "13px",
                      background: "#F4F6F5",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
                  : {
                      color: "#555555",
                      padding: "4px 12px",
                      border: "none",
                      borderRadius: "9px",
                      background: "#F4F6F5",
                      marginRight: "13px",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
              }
            >
              Visit
              <input
                type="radio"
                name="category_id"
                value={4}
                className="radio-button"
                onChange={(e) => setType(stepIndex, e.target.value)}
              />
            </label>
            <label
              style={
                step.category_id === "5"
                  ? {
                      border: "2px solid #A0DFC4",
                      outline: "none",
                      color: "#555555",
                      padding: "4px 12px",
                      borderRadius: "9px",
                      marginRight: "13px",
                      background: "#F4F6F5",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
                  : {
                      color: "#555555",
                      padding: "4px 12px",
                      border: "none",
                      borderRadius: "9px",
                      background: "#F4F6F5",
                      marginRight: "13px",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
              }
            >
              Meeting
              <input
                type="radio"
                name="category_id"
                value={5}
                className="radio-button"
                onChange={(e) => setType(stepIndex, e.target.value)}
              />
            </label>
            <label
              style={
                step.category_id === "6"
                  ? {
                      border: "2px solid #A0DFC4",
                      outline: "none",
                      color: "#555555",
                      padding: "4px 12px",
                      borderRadius: "9px",
                      marginRight: "13px",
                      background: "#F4F6F5",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
                  : {
                      color: "#555555",
                      padding: "4px 12px",
                      border: "none",
                      borderRadius: "9px",
                      background: "#F4F6F5",
                      marginRight: "13px",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }
              }
            >
              Notes
              <input
                type="radio"
                name="category_id"
                value={6}
                className="radio-button"
                onChange={(e) => setType(stepIndex, e.target.value)}
              />
            </label>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <label>Start Time</label>
            <DatePicker
              selected={step.start_time}
              onChange={(date) => setTime(stepIndex, date, "start_time")}
              value={step.start_time}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              placeholderText="Start Time"
              autoComplete="off"
              style={{ width: "115px" }}
              className={
                isError === true &&
                ((step.end_time !== null && step.start_time === null) ||
                  (step.end_time !== "" && step.start_time === "")) &&
                "border-error"
              }
            />
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {isError === true &&
              ((step.end_time !== null && step.start_time === null) ||
                (step.end_time !== "" && step.start_time === ""))
                ? "Start Time is required"
                : null}
            </div>
          </div>
          <div>
            <label>End Time</label>
            <DatePicker
              selected={step.end_time}
              autoComplete="off"
              onChange={(date) => setTime(stepIndex, date, "end_time")}
              value={step.end_time}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              placeholderText="End Time"
              style={{ width: "115px" }}
              className={
                isError === true &&
                ((step.start_time !== null && step.end_time === null) ||
                  (step.start_time !== "" && step.end_time === "")) &&
                "border-error"
              }
            />
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {isError === true &&
              ((step.start_time !== null && step.end_time === null) ||
                (step.start_time !== "" && step.end_time === ""))
                ? "End Time is required"
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const modalStyle = {
  content: {
    background: "transparent",
    border: "none",
    width: "0px",
  },
};

function SequenceCreate(props) {
  const [seqName, setSeqName] = useState("New Sequence");
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    const checkError = steps?.every((step) => {
      const { start_time, end_time } = step;
      if ((start_time && !end_time) || (end_time && !start_time)) {
        setIsError(true);
        setLoading(false);
        return false;
      }
      return step;
    });
    if (checkError === true) {
      setIsError(false);
      axios({
        method: "POST",
        url: "/task-sequences",
        data: {
          name: seqName,
          is_public: isPublic,
          tasks: steps?.map((step) => ({
            name: step.name,
            category_id: step.category_id,
            start_time:
              step.start_time != null
                ? step.start_time && moment(step.start_time).format("HH:mm:ss")
                : null,
            end_time:
              step.end_time != null
                ? step.end_time && moment(step.end_time).format("HH:mm:ss")
                : null,
            interval: step.delay,
          })),
        },
      })
        .then((res) => {
          setLoading(false);
          setSeqName("New Sequence");
          setSteps([]);
          setIsError(false);
          NotificationManager.success("Sequence created successfully");
          props.getSeq();
          props.handleCloseModal();
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error("Error creating sequence");
        });
    }
  };

  const removeStep = (i) => {
    const newSteps = steps?.filter((step, index) => index !== i);
    setSteps(newSteps);
  };
  const setDelay = (i, value) => {
    const newStep = steps?.map((step, index) => {
      if (index === i) step.delay = value;
      return step;
    });
    setSteps(newStep);
  };
  const setTime = (i, value, type) => {
    const newStep = steps?.map((step, index) => {
      if (index === i) step[type] = value;
      return step;
    });
    setSteps(newStep);
  };
  const setType = (i, value) => {
    const newStep = steps?.map((step, index) => {
      if (index === i) step.category_id = value;
      return step;
    });
    setSteps(newStep);
  };
  const setName = (i, value) => {
    const newStep = steps?.map((step, index) => {
      if (index === i) step.name = value;
      return step;
    });
    setSteps(newStep);
  };
  const addStep = () => {
    const newStep = [
      ...steps,
      {
        name: `Follow-up Call#${steps.length + 1}`,
        category_id: "1",
        start_time: null,
        end_time: null,
        delay: 0,
      },
    ];
    setSteps(newStep);
  };
  return (
    <ReactModal
      isOpen={props.showOpportunityModal}
      contentLabel="onRequestClose Example"
      onRequestClose={props.handleCloseModal}
      style={modalStyle}
      ariaHideApp={false}
    >
      <div className="modal">
        <div
          className="modal__content"
          style={{ height: "90vh", top: "2%", left: "25%", width: "48vw" }}
        >
          <div className="modal__header">
            <span>
              <div
                className="customerInfo-icon-modal"
                style={{
                  transform: "translateY(0px)",
                }}
              >
                <i className="fa fa-info" aria-hidden="true"></i>
              </div>
            </span>
            <div className="modal__head">
              <h2 className="modal__heading">Create Sequence</h2>
            </div>
            <button
              onClick={props.handleCloseModal}
              className="modal__close"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                cursor: "pointer",
              }}
            >
              {" "}
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div style={{ height: "82vh", overflowY: "auto" }}>
            <ReactTooltip />
            <div
              style={{
                borderBottom: "1px solid #E3E3E3",
                marginBottom: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    position: "absolute",
                    left: "58%",
                    top: "3%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <label style={{ display: "inline-block" }}>
                    <span className="general-heading">
                      Private Sequence &nbsp;
                    </span>
                    <label className="switch1">
                      <input
                        type="checkbox"
                        value={isPublic}
                        checked={isPublic}
                        onChange={() => setIsPublic(!isPublic)}
                        style={{ width: "20px", display: "inline-block" }}
                      />
                      <span className="slider1 round1"></span>
                    </label>
                    <span className="general-heading">
                      {" "}
                      &nbsp;Public Sequence
                    </span>
                  </label>
                  <span
                    data-tip="Public allows all users within your company CRM to use this template.<br />
                Private keeps the template restricted to you only."
                    style={{
                      fontSize: "10px",
                      fontWeight: "400",
                      color: "#62CA9D",
                      padding: "0px 5.3px",
                      height: "14px",
                      borderRadius: "50%",
                      border: "1px solid #62CA9D",
                      marginLeft: "10px",
                    }}
                    data-multiline={true}
                  >
                    i
                  </span>
                </div>
                <h2 style={{ marginBottom: "0px" }}>
                  <div className="controlsDashboard">
                    <label
                      className="activeLabel"
                      style={{ fontSize: "12px", top: "-7px" }}
                      for="sequenceNameCreate"
                    >
                      Sequence Name
                    </label>
                    <input
                      value={seqName}
                      id="sequenceNameCreate"
                      className="floatLabel"
                      onChange={(e) => setSeqName(e.target.value)}
                      style={{
                        fontSize: "15px",
                        borderRadius: "4px",
                        padding: "8px",
                        border: "1px solid #c5c5d3",
                        paddingTop: "13px",
                        paddingBottom: "5px",
                        minWidth: "350px",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                </h2>
                <p> </p>
              </div>
            </div>
            <div className="sequence">
              {steps.length === 0 && (
                <div className="sequence-steps">
                  Get started by adding a task your new sequence.
                </div>
              )}
              <SortableContainer
                onSortEnd={({ oldIndex, newIndex }) => {
                  setSteps(arrayMove(steps, oldIndex, newIndex));
                }}
                useDragHandle
              >
                {steps?.map((step, index) => (
                  <SortableItem
                    step={step}
                    removeStep={removeStep}
                    setDelay={setDelay}
                    setTime={setTime}
                    setType={setType}
                    setName={setName}
                    stepIndex={index}
                    key={`step-${index}`}
                    index={index}
                    isError={isError}
                  />
                ))}
              </SortableContainer>
              <button
                type="button"
                onClick={addStep}
                className="sequence-button"
                style={{
                  background: "#c0ead8",
                  color: "black",
                  fontWeight: "500",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                + Add Task
              </button>
            </div>
            <div className="modal__buttons">
              <button
                className="button-md modal__confirm-button"
                style={
                  loading || steps.length === 0 || seqName === ""
                    ? {
                        right: "6%",
                        background: "#a7abaa",
                        cursor: "not-allowed",
                        marginRight: "10px",
                      }
                    : {
                        right: "6%",
                        marginRight: "10px",
                      }
                }
                type="button"
                onClick={submitHandler}
                disabled={loading || steps.length === 0 || seqName === ""}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default withRouter(SequenceCreate);
