import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardLoader from "../../Loaders/DashboardLoader";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, authUserSet } from "../../../redux";
import { Col, Row } from "react-grid-system";
import Pagination from "react-js-pagination";
import moment from "moment";
import DropdownDashboard from "../../Dropdown/DropdownDashboard";

const PipelineStageChangesPage = (props) => {
  const [filter, setFilter] = useState("last_30_days");
  const [deals, setDeals] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sortDirection, setSortDirection] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sortingKey, setSortingKey] = useState(null);
  const [user_ids, setUser_ids] = useState(props.authUser.id);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayUserName, setDisplayUserName] = useState(props.authUser.name);

  const allFilters = [
    { value: "all_time", label: "All time" },
    { value: "last_7_days", label: "Last 7 days" },
    { value: "last_14_days", label: "Last 14 days" },
    { value: "last_30_days", label: "Last 30 days" },
    { value: "last_60_days", label: "Last 60 days" },
    { value: "last_90_days", label: "Last 90 days" },
    { value: "last_365_days", label: "Last 365 days" },
  ];
  const allHeaders = [
    {
      label: "Owner",
      value: "opportunity_owner",
      sort: true,
      sortKey: "opportunity_owner",
      type: null,
    },
    {
      label: "Deal Name",
      value: "name",
      sort: true,
      sortKey: "opportunity_name",
      type: null,
    },
    {
      label: "Account Name",
      value: "account_name",
      sort: true,
      sortKey: "account_name",
      type: null,
    },
    {
      label: "Prev Stage",
      value: "prev_stage",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "New Stage",
      value: "new_stage",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Stage Change Date",
      value: "stage_changed_at",
      sort: true,
      sortKey: "stage_changed_at",
      type: "date",
    },
    {
      label: "Revenue",
      value: "revenue",
      sort: false,
      sortKey: null,
      type: null,
    },
    {
      label: "Close Date",
      value: "close_date",
      sort: false,
      sortKey: null,
      type: "date",
    },
  ];

  const getDeals = (
    page = 1,
    user_ids = props.authUser.id,
    sortKey,
    sortDir
  ) => {
    setLoader(true);
    let url = `/opportunities/stage-history?user_ids=${user_ids}&duration=${filter}&_limit=30&_page=${page}`;
    if (sortKey) {
      const dir = sortDir === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setDeals(res.data.stage_history);
        setTotal(res.data.total);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleUserId = (userIds, userName) => {
    setUser_ids(userIds);
    setDisplayMenu(!displayMenu);
    setDisplayUserName(userName);
    getDeals(activePage, userIds, sortingKey, sortDirection);
  };
  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };

  useEffect(() => {
    getDeals();
  }, [filter, props.userId]);
  return (
    <div className="accountList">
      <h1 className="accountList__heading">
        Pipeline Stage Changes <span style={{ color: "#62ca9d" }}>{total}</span>
        <div style={{ float: "right" }}>
          <DropdownDashboard
            handleUserId={handleUserId}
            user_ids={user_ids}
            userName={props.authUser.name}
            userId={props.authUser.id}
            displayMenu={displayMenu}
            showDropdownMenu={showDropdownMenu}
            displayUserName={displayUserName}
            userRole={props.authUser.role}
          />
        </div>
      </h1>
      <h6 className="accountList__function">
        <select
          className="funnel-select"
          name="filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          {allFilters.map((fil) => (
            <option key={fil.value} value={fil.value}>
              {fil.label}
            </option>
          ))}
        </select>
      </h6>
      {loader ? (
        <DashboardLoader />
      ) : (
        <>
          <div
            style={{
              overflow: "auto",
            }}
          >
            <table className="accountList__table" style={{ marginTop: "0" }}>
              <thead>
                {allHeaders.map((header) => (
                  <th>
                    <Row>
                      <Col lg={9} xl={9}>
                        {header.label}
                      </Col>
                      <Col lg={3} xl={3}>
                        {header.sort && (
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              color: "#657885",
                            }}
                            onClick={() => {
                              getDeals(
                                activePage,
                                user_ids,
                                header.sortKey,
                                !sortDirection
                              );
                              setSortDirection(!sortDirection);
                              setSortingKey(header.sortKey);
                            }}
                          >
                            <i className="fa fa-long-arrow-up"></i>
                            <i className="fa fa-long-arrow-down"></i>
                          </button>
                        )}
                      </Col>
                    </Row>
                  </th>
                ))}
              </thead>
              <tbody>
                {deals?.map((deal) => {
                  let newTabData = {
                    type: "opportunity",
                    id: deal.id,
                    name: deal.name,
                  };
                  return (
                    <tr key={deal.id}>
                      {allHeaders.map((header) => (
                        <td>
                          {header.value === "name" ? (
                            <span
                              style={{ cursor: "pointer", color: "#41BE89" }}
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  props.setTab({ ...newTabData, blank: true });
                                } else {
                                  props.setActiveTabIndex(props.totalTabs + 1);
                                  props.setTab(newTabData);
                                  props.history.push("/active-tabs");
                                }
                              }}
                            >
                              {deal.name}
                            </span>
                          ) : header.type === "date" ? (
                            moment(deal?.[header.value]).format("l")
                          ) : (
                            deal?.[header.value]
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              setActivePage(page);
              getDeals(page, user_ids, sortingKey, sortDirection);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        </>
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};
export default connect(MSP, MDP)(PipelineStageChangesPage);
