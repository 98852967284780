import React, { Component } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import SubscriptionCancelConfirm from "../../images/BillingSettingsIcon.svg";

class BillingRedirectModal extends Component {
  state = {
    showModal: true,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "45vh", top: "20%", textAlign: "center" }}
            >
              <img src={SubscriptionCancelConfirm} alt="" width="100px" />
              <h4
                style={{
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "150%",
                  color: "#0C0D0D",
                  margin: "20px auto",
                }}
              >
                Subscription Required
              </h4>
              <button
                onClick={this.props.handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
              <label style={{ fontSize: "15px" }}>
                Bulk emailing is a feature we offer for active subscribers of
                Salesdash. Click below to go to our billing page for monthly and
                annual pricing. Bulk emailing can be purchased for $10 for every
                5,000 emails.
              </label>
              <div style={{ marginTop: "25px" }}>
                <Link
                  to="/payment"
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #4BC893",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "10px 24px",
                    color: "#4BC893",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "150%",
                    textDecoration: "none",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  Go to Billing Page
                </Link>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default BillingRedirectModal;
