import React, { Component } from "react";
import ReactModal from "react-modal";
import PlanChangeSuccess from "../../images/PlanChangeSuccess.svg";

class ApolloImportSuccessModal extends Component {

  showModal = () => {
    ReactModal.isOpen = false;
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "38vh", top: "20%", textAlign: "center" }}
            >
              <img src={PlanChangeSuccess} alt="" width="100px" />
              <h4
                style={{
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "150%",
                  color: "#0C0D0D",
                  margin: "20px 0",
                }}
              >
                Your import is in progress. Please allow up to 5-10 minutes for your records to import.
              </h4>
              <button
                onClick={this.props.handleCloseModal}
                style={{
                  background: "#3AAB7B",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #3AAB7B",
                  padding: "10px 24px",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "150%",
                  textDecoration: "none",
                  cursor: "pointer",
                  marginTop: "15px",
                  outline: "none",
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default ApolloImportSuccessModal;
