import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import TaskIcon from "../images/TaskIcon.svg";
import DeleteIconRed from "../images/DeleteIconRed.svg";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../redux";

class TaskEditModal extends Component {
  state = {
    name: this.props.name,
    description: this.props.detail,
    date: new Date(this.props.date).toLocaleDateString(),
    start_time:
      this.props.start_time &&
      moment(this.props.start_time, "HH:mm:ss").format("hh:mm A"),
    end_time:
      this.props.end_time &&
      moment(this.props.end_time, "HH:mm:ss").format("hh:mm A"),
    category_id:
      this.props.category_id === undefined
        ? null
        : this.props.category_id.toString(),
    // task_id: this.props.task_id,
    showOpportunityModal: false,
    errorMessage: false,
    isLoading: false,
    deleteLoading: false,
    windowWidth: window.innerWidth,
    getDateStored: null,
    assignedTo: this.props.assignedTo,
    account_id:
      this.props.accountId === undefined ? null : this.props.accountId,
    carrier_id:
      this.props.carrierId === undefined ? null : this.props.carrierId,
    opportunity_id:
      this.props.opportunityId === undefined ? null : this.props.opportunityId,
    contact_id:
      this.props.contactId === undefined ? null : this.props.contactId,
  };
  handleOpenModal = () => {
    this.props.setShowTaskModal(true);
  };
  handleOpenDeleteModal = () => {
    this.setState({
      showOpportunityModal: true,
    });
  };
  handleCloseDeleteModal = () => {
    this.setState({
      showOpportunityModal: false,
    });
  };

  handleCloseModal = () => {
    this.props.setShowTaskModal(false);
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    let { date } = this.props;
    date = new Date(date);
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState({
      date: offsetDate,
      account_id:
        this.props.accountId === undefined ? null : this.props.accountId,
      carrier_id:
        this.props.carrierId === undefined ? null : this.props.carrierId,
      opportunity_id:
        this.props.opportunityId === undefined
          ? null
          : this.props.opportunityId,
      contact_id:
        this.props.contactId === undefined ? null : this.props.contactId,
      assignedTo: this.props.assignedTo,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showTaskModal === true &&
      prevProps.showTaskModal !== this.props.showTaskModal
    ) {
      this.setState({
        assignedTo: this.props.assignedTo,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  dateChangeHandler = (type, date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState({
      [type]: offsetDate,
      getDateStored: date,
    });
  };
  timeChangeHandler = (type, date) => {
    this.setState({
      [type]: date,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (!data.name || !data.category_id || !data.date) {
      this.setState({ errorMessage: true });
      return false;
    } else if (
      (data.start_time && !data.end_time) ||
      (data.end_time && !data.start_time)
    ) {
      this.setState({ errorMessage: true });
      return false;
    }
    this.setState({ isLoading: true });
    const newDate =
      this.state.date === new Date(this.props.date).toLocaleDateString()
        ? new Date(this.state.date).getTimezoneOffset() < 0
          ? new Date(
              new Date(this.state.date).getTime() -
                new Date(this.state.date).getTimezoneOffset() * 60000
            ).toISOString()
          : new Date(
              new Date(this.state.date).getTime() +
                new Date(this.state.date).getTimezoneOffset() * 60000
            ).toISOString()
        : this.state.date;
    axios({
      method: "PUT",
      url: `/tasks/${this.props.task_id}`,
      data: {
        ...(this.props.accountId !== "" &&
          this.props.accountId !== null &&
          this.props.accountId !== undefined && {
            account_id: this.props.accountId,
          }),
        ...(this.props.carrierId !== "" &&
          this.props.carrierId !== null &&
          this.props.carrierId !== undefined && {
            carrier_id: this.props.carrierId,
          }),
        ...(this.state.contact_id !== "" &&
          this.state.contact_id !== null && {
            contact_id: this.state.contact_id,
          }),
        ...(this.state.opportunity_id !== "" &&
          this.state.opportunity_id !== null && {
            opportunity_id: this.state.opportunity_id,
          }),
        name: this.state.name,
        description: this.state.detail,
        date: newDate && moment(newDate).format("YYYY-MM-DD"),
        start_time:
          this.state.start_time !== null
            ? this.state.start_time !==
              moment(this.state.start_time, "HH:mm").format("HH:mm")
              ? this.state.start_time &&
                moment(this.state.start_time, "hh:mm A").format("HH:mm")
              : this.state.start_time &&
                moment(this.state.start_time).format("HH:mm")
            : null,
        end_time:
          this.state.end_time !== null
            ? this.state.end_time !==
              moment(this.state.end_time, "HH:mm").format("HH:mm")
              ? this.state.end_time &&
                moment(this.state.end_time, "hh:mm A").format("HH:mm")
              : this.state.end_time &&
                moment(this.state.end_time).format("HH:mm")
            : null,
        category_id: this.state.category_id,
        assigned_to: this.state.assignedTo === "" ? null : this.state.assignedTo,
      },
    })
      .then((response) => this.setState({ isLoading: false }))
      .then((response) =>
        NotificationManager.success("Task edited successfully.")
      )
      .then((response) => this.props.handleCloseModal())
      .then((response) => this.props.getTasks())
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 406) {
          NotificationManager.error("Please assign task to a different user.");
        } else {
          NotificationManager.error("Error editing task.");
        }
      });
  };
  deleteTask = () => {
    this.setState({ deleteLoading: true });
    axios({
      method: "DELETE",
      url: `/tasks/${this.props.task_id}`,
    })
      .then((response) => this.props.getTasks())
      .then((response) => {
        NotificationManager.success("Task deleted successfully.");
        this.setState({ deleteLoading: false });
      })
      .then((response) => this.handleCloseDeleteModal())
      .then((response) => this.props.handleCloseModal())
      .catch((err) => {
        this.setState({ deleteLoading: false });
        NotificationManager.error("Error deleting task.");
      });
  };
  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <ReactModal
        isOpen={this.props.showTaskModal}
        contentLabel="onRequestClose Example"
        onRequestClose={this.props.handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <NotificationContainer />
          <div
            className="opportunity__content"
            style={{ height: "72vh", top: "11%" }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon"
                  style={{
                    background: "#B8FFDB",
                    transform: "translateY(-18px)",
                  }}
                >
                  <img
                    src={TaskIcon}
                    alt="Icon"
                    width="17px"
                    style={{ transform: "translateY(11px)" }}
                  />
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Edit a task</h2>
                <p className="modal__heading-desc">
                  Edit some information about the task
                </p>
              </div>
              <button
                onClick={this.props.handleCloseModal}
                className="modal__close"
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <DeleteTaskModal
              showOpportunityModal={this.state.showOpportunityModal}
              setShowOpportunityModal={this.handleOpenDeleteModal}
              handleCloseModal={this.handleCloseDeleteModal}
              deleteTask={this.deleteTask}
              deleteLoading={this.state.deleteLoading}
            />
            <form onSubmit={this.submitHandler}>
              <label>Account</label>
              {this.props.accountName === null ||
              this.props.accountName === undefined ? (
                <div style={{ fontSize: "14px", marginBottom: "5px" }}>
                  No account connected to this record
                </div>
              ) : (
                <input type="text" value={this.props.accountName} readOnly />
              )}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "20px" }}>
                  <div>
                    <label>Deal</label>
                  </div>
                  <select
                    name="opportunity_id"
                    onChange={this.changeHandler}
                    style={{
                      width: "auto",
                      minWidth: "70px",
                      maxWidth: "185px",
                    }}
                    value={this.state.opportunity_id}
                  >
                    {this.props.opportunityName !== null ? (
                      <option value={this.props.opportunityId}>
                        {this.props.opportunityName}
                      </option>
                    ) : (
                      <option value="" selected disabled>
                        --Select--
                      </option>
                    )}
                    {this.props.opportunities?.map((opportunity) => {
                      const { id, name } = opportunity;

                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <div>
                    <label>Contact</label>
                  </div>
                  <select
                    name="contact_id"
                    onChange={this.changeHandler}
                    style={{
                      width: "auto",
                      minWidth: "70px",
                      maxWidth: "185px",
                    }}
                    value={this.state.contact_id}
                  >
                    {this.props.contactName !== null ? (
                      <option value={this.props.contactId}>
                        {this.props.contactName}
                      </option>
                    ) : (
                      <option value="" selected disabled>
                        --Select--
                      </option>
                    )}
                    {this.props.contacts?.map((contact) => {
                      const { id, name } = contact;
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div>
                <label>Task Owner</label>
                <select
                  name="assignedTo"
                  value={this.state.assignedTo}
                  onChange={this.changeHandler}
                  style={{ width: "100%" }}
                >
                  <option value="">Unassigned Leads</option>
                  {this.props.users?.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </div>
              <label style={{ display: "inline-block" }}>Task Name</label>
              <span style={{ color: "#62CA9D", display: "inline-block" }}>
                &nbsp;*
              </span>
              <input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.changeHandler}
                required
              />
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.name === null || this.state.name === "")
                  ? "Name is required"
                  : null}
              </div>
              <label style={{ display: "inline-block" }}>Category</label>
              <div className="modal-radioButton">
                <label
                  htmlFor="call"
                  style={
                    this.state.category_id === "1"
                      ? {
                          border: "2px solid #A0DFC4",
                          outline: "none",
                          color: "#555555",
                          padding: "4px 12px",
                          borderRadius: "9px",
                          marginRight: "13px",
                          background: "#F4F6F5",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                      : {
                          color: "#555555",
                          padding: "4px 12px",
                          border: "none",
                          borderRadius: "9px",
                          background: "#F4F6F5",
                          marginRight: "13px",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                  }
                >
                  Call
                </label>
                <input
                  type="radio"
                  name="category_id"
                  value="1"
                  id="call"
                  className="radio-button"
                  onChange={this.changeHandler}
                />
                <label
                  htmlFor="email"
                  style={
                    this.state.category_id === "2"
                      ? {
                          border: "2px solid #A0DFC4",
                          outline: "none",
                          color: "#555555",
                          padding: "4px 12px",
                          borderRadius: "9px",
                          marginRight: "13px",
                          background: "#F4F6F5",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                      : {
                          color: "#555555",
                          padding: "4px 12px",
                          border: "none",
                          borderRadius: "9px",
                          background: "#F4F6F5",
                          marginRight: "13px",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                  }
                >
                  Email
                </label>
                <input
                  type="radio"
                  name="category_id"
                  value="2"
                  id="email"
                  className="radio-button"
                  onChange={this.changeHandler}
                />
                <label
                  htmlFor="text"
                  style={
                    this.state.category_id === "3"
                      ? {
                          border: "2px solid #A0DFC4",
                          outline: "none",
                          color: "#555555",
                          padding: "4px 12px",
                          borderRadius: "9px",
                          marginRight: "13px",
                          background: "#F4F6F5",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                      : {
                          color: "#555555",
                          padding: "4px 12px",
                          border: "none",
                          borderRadius: "9px",
                          background: "#F4F6F5",
                          marginRight: "13px",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                  }
                >
                  Text
                </label>
                <input
                  type="radio"
                  name="category_id"
                  value={3}
                  id="text"
                  className="radio-button"
                  onChange={this.changeHandler}
                />
                <label
                  htmlFor="visit"
                  style={
                    this.state.category_id === "4"
                      ? {
                          border: "2px solid #A0DFC4",
                          outline: "none",
                          color: "#555555",
                          padding: "4px 12px",
                          borderRadius: "9px",
                          marginRight: "13px",
                          background: "#F4F6F5",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                      : {
                          color: "#555555",
                          padding: "4px 12px",
                          border: "none",
                          borderRadius: "9px",
                          background: "#F4F6F5",
                          marginRight: "13px",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                  }
                >
                  Visit
                </label>
                <input
                  type="radio"
                  name="category_id"
                  value="4"
                  id="visit"
                  className="radio-button"
                  onChange={this.changeHandler}
                />
                <label
                  htmlFor="meeting"
                  style={
                    this.state.category_id === "5"
                      ? {
                          border: "2px solid #A0DFC4",
                          outline: "none",
                          color: "#555555",
                          padding: "4px 12px",
                          borderRadius: "9px",
                          marginRight: "13px",
                          background: "#F4F6F5",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                      : {
                          color: "#555555",
                          padding: "4px 12px",
                          border: "none",
                          borderRadius: "9px",
                          background: "#F4F6F5",
                          marginRight: "13px",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                  }
                >
                  Meeting
                </label>
                <input
                  type="radio"
                  name="category_id"
                  value="5"
                  id="meeting"
                  className="radio-button"
                  onChange={this.changeHandler}
                />
                <label
                  htmlFor="notes"
                  style={
                    this.state.category_id === "6"
                      ? {
                          border: "2px solid #A0DFC4",
                          outline: "none",
                          color: "#555555",
                          padding: "4px 12px",
                          borderRadius: "9px",
                          marginRight: "13px",
                          background: "#F4F6F5",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                      : {
                          color: "#555555",
                          padding: "4px 12px",
                          border: "none",
                          borderRadius: "9px",
                          background: "#F4F6F5",
                          marginRight: "13px",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }
                  }
                >
                  Notes
                </label>

                <input
                  type="radio"
                  name="category_id"
                  value="6"
                  id="notes"
                  className="radio-button"
                  onChange={this.changeHandler}
                />
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.category_id === null ||
                  this.state.category_id === "")
                  ? "Please select a category"
                  : null}
              </div>

              {/*Date,time */}
              <div style={{ display: "flex", marginTop: "15px" }}>
                <div>
                  <label style={{ display: "block" }}>Date</label>
                  <DatePicker
                    selected={moment(this.state.date).toDate()}
                    autoComplete="off"
                    todayButton="Today"
                    utcOffset={0}
                    value={this.state.date}
                    onChange={(date) => this.dateChangeHandler("date", date)}
                    placeholderText="Date"
                    dateFormat={
                      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    style={{ width: "125px" }}
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.state.errorMessage === true &&
                    (this.state.date === null || this.state.date === "")
                      ? "Date is required"
                      : null}
                  </div>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <label style={{ display: "block" }}>Start</label>
                  <DatePicker
                    selected={Date.parse(this.state.start_time)}
                    autoComplete="off"
                    onChange={(date) =>
                      this.timeChangeHandler("start_time", date)
                    }
                    value={this.state.start_time}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Start Time"
                    isClearable={true}
                    style={{ width: "115px" }}
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.state.errorMessage === true &&
                    ((this.state.end_time !== null &&
                      this.state.start_time === null) ||
                      (this.state.end_time !== "" &&
                        this.state.start_time === ""))
                      ? "Start Time is required"
                      : null}
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "6px",
                    transform: "translateY(22px)",
                  }}
                >
                  _
                </div>
                <div style={{ marginLeft: "6px" }}>
                  <label style={{ display: "block" }}>End</label>
                  <DatePicker
                    selected={Date.parse(this.state.end_time)}
                    autoComplete="off"
                    onChange={(date) =>
                      this.timeChangeHandler("end_time", date)
                    }
                    value={this.state.end_time}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="End Time"
                    isClearable={true}
                    style={{ width: "115px" }}
                  />
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.state.errorMessage === true &&
                    ((this.state.start_time !== null &&
                      this.state.end_time === null) ||
                      (this.state.start_time !== "" &&
                        this.state.end_time === ""))
                      ? "End Time is required"
                      : null}
                  </div>
                </div>
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__clear-1"
                  style={{ color: "red", width: "50px" }}
                  onClick={this.handleOpenDeleteModal}
                >
                  <img src={DeleteIconRed} alt="Delete" width="16px" />
                </button>
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                  style={{ right: "30%", left: "auto" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="modal__confirm-button"
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>
    );
  }
}

class DeleteTaskModal extends Component {
  state = {
    showModal: true,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "24vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Task</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <p>Are you sure you want to delete this task?</p>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  onClick={this.props.deleteTask}
                  disabled={this.props.deleteLoading}
                >
                  {this.props.deleteLoading ? "Deleting" : "Delete"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(TaskEditModal);
