export const defaultAccountFields = {
  field_class: "account",
  fields: [
    {
      choices: [],
      field_class: "account",
      id: "d-account_owner_id",
      label: "Account Owner",
      name: "account_owner_id",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-name",
      label: "Account Name",
      name: "name",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "text",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-phone",
      label: "Phone number",
      name: "phone",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-phone_ext",
      label: "Phone Extension",
      name: "phone_ext",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-email",
      label: "Email",
      name: "email",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-address",
      label: "Address",
      name: "address",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-city",
      label: "City",
      name: "city",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-State",
      label: "State",
      name: "state",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-zip",
      label: "Zip/Postal",
      name: "zip",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-country",
      label: "Country",
      name: "country",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [
        { label: "Call", value: "call" },
        { label: "Text", value: "text" },
        { label: "Email", value: "email" },
      ],
      field_class: "account",
      id: "d-preferred_communication",
      label: "Preferred Communication",
      name: "preferred_communication",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-Source",
      label: "Source",
      name: "source",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-Website",
      label: "Website",
      name: "website",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-Linkedin",
      label: "LinkedIn",
      name: "linkedin",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "text",
      visible: null,
    },
    {
      choices: [],
      field_class: "account",
      id: "d-Description",
      label: "Description",
      name: "description",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "paragraph",
      visible: null,
    },
  ],
  id: "default_fields",
  label: "Default Fields",
  meta: null,
  name: "default_fields",
  placeholder: null,
  position: 0,
  type: "section",
};
