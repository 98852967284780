import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardLoader from "../../Loaders/DashboardLoader";
import PeriodBarChart from "./PeriodBarChart";

const ActivitiesPeriodOverPeriodChart = ({ userId, reportWidth }) => {
  const [filter, setFilter] = useState("weekly");
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const allFilters = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "yearly", label: "Yearly" },
  ];

  const getData = () => {
    setLoader(true);
    axios({
      method: "GET",
      url: `/charts/activity-goals-chart?duration=${filter}&user_ids=${userId}`,
    })
      .then((res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, [filter, userId]);

  return (
    <div className="card card-default-overwrite">
      <div className="funnel-heading">
        <h4 className="funnel-heading-sub-1" style={{ marginBottom: "12px" }}>
          Activities per day
        </h4>
        <h6 className="funnel-heading-sub-c">
          <select
            className="funnel-select"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            {allFilters.map((fil) => (
              <option key={fil.value} value={fil.value}>
                {fil.label}
              </option>
            ))}
          </select>
        </h6>
      </div>
      {loader ? (
        <DashboardLoader />
      ) : (
        <PeriodBarChart
          data={data?.total_activities_chart || []}
          type={"Activities"}
          refresh={false}
          reportWidth={reportWidth}
          filter={filter}
        />
      )}
      <div className="funnel-heading" style={{ paddingTop: "12px" }}>
        <h4 className="funnel-heading-sub-1" style={{ marginBottom: "12px" }}>
          Calls per day
        </h4>
      </div>
      {loader ? (
        <DashboardLoader />
      ) : (
        <PeriodBarChart
          data={data?.call_activities_chart || []}
          type={"Calls"}
          refresh={false}
          reportWidth={reportWidth}
          filter={filter}
        />
      )}
      <div className="funnel-heading" style={{ paddingTop: "12px" }}>
        <h4 className="funnel-heading-sub-1" style={{ marginBottom: "12px" }}>
          Emails per day
        </h4>
      </div>
      {loader ? (
        <DashboardLoader />
      ) : (
        <PeriodBarChart
          data={data?.email_activities_chart || []}
          type={"Emails"}
          refresh={false}
          reportWidth={reportWidth}
          filter={filter}
        />
      )}
    </div>
  );
};

export default ActivitiesPeriodOverPeriodChart;
