import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";
import { CountryCodeList } from "./CountryCodeList";

class MobileEntryModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    isLoading: false,
    errorMessage: false,
    countryCode: "",
    mobile: "",
    countryPhoneCode: "",
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };
  getGeoInfo = () => {
    axios
      .get("https://extreme-ip-lookup.com/json/")
      .then((response) => {
        let data = response.data;
        const countryPhoneCode = CountryCodeList.find(
          (country) => data.countryCode === country.code
        );
        this.setState({
          countryName: data.country,
          countryCode: data.countryCode,
          countryPhoneCode: countryPhoneCode.dial_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    this.setState({ mobile: this.props.mobile });
    window.addEventListener("resize", this.handleResize);
    this.getGeoInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.getGeoInfo();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  submitHandler = () => {
    if (this.state.mobile === "" || this.state.mobile === null) {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    this.setState({
      isLoading: true,
    });
    axios({
      method: "PUT",
      url: `/users`,
      data: {
        formatted_mobile: this.state.mobile.toString(),
        country_code: this.state.countryPhoneCode.substring(1),
      },
    })
      .then((res) => {
        this.props.handleCloseModal();
        this.props.handlePasswordOpenModal();
        this.setState({
          isLoading: false,
          errorMessage: false,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: false,
        })
      );
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "35vh", top: "25%" }}
            >
              <div className="modal__header">
                <div className="modal__head">
                  <h2 className="modal__heading">Confirm your Phone number</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label>Phone</label>
              <div style={{ display: "flex" }}>
                <select
                  value={this.state.countryPhoneCode}
                  name="countryPhoneCode"
                  onChange={this.changeHandler}
                >
                  {CountryCodeList.map((country) => {
                    const { dial_code, code } = country;
                    return (
                      <option value={dial_code} key={dial_code+code}>
                        {dial_code} {code}
                      </option>
                    );
                  })}
                </select>
                <input
                  name="mobile"
                  value={this.state.mobile}
                  onChange={this.changeHandler}
                />
              </div>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.state.errorMessage === true &&
                (this.state.mobile === "" || this.state.mobile === null)
                  ? "Please enter mobile"
                  : null}
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  disabled={this.state.isLoading}
                  onClick={this.submitHandler}
                  type="button"
                  className="modal__confirm-button"
                >
                  {this.state.isLoading ? "Confirming" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default MobileEntryModal;
