import {
  SET_STORED_RECORD_SELECTABLE,
  REMOVE_STORED_RECORD_SELECTABLE,
} from "./types";

export const setStoredRecordSelectable = (payload) => {
  return {
    type: SET_STORED_RECORD_SELECTABLE,
    payload,
  };
};
export const removeStoredRecordSelectable = (payload) => {
  return {
    type: REMOVE_STORED_RECORD_SELECTABLE,
    payload,
  };
};
