import axios from "axios";
import React, { useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import "jodit";
import EditTemplateModal from "../SendEmail/EditTemplateModal";
import CreateTemplateModal from "../SendEmail/CreateTemplateModal";
import DeleteTemplateModal from "../SendEmail/DeleteTemplateModal";
import { Col, Row } from "react-grid-system";

const config = {
  height: "auto",
  minHeight: "20px",
  width: "inherit",
  minWidth: "20px",
  readonly: true,
  toolbar: false,
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  link: {
    modeClassName: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Type your message...",
  zIndex: 2147483640,
};

function TemplatePage() {
  const [allTemplates, setAllTemplates] = useState([]);
  const [templateData, setTemplateData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortDirection, setSortDirection] = useState(true);
  const [loading, setLoading] = useState(false);

  const getAllTemplates = (sortKey) => {
    setLoading(true);
    let url = `/emails/templates`;
    if (sortKey) {
      const dir = sortDirection === true ? "desc" : "asc";
      url += `?sort_key=${sortKey}&sort_dir=${dir}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setAllTemplates(res.data.templates);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleTemplateData = (
    id,
    label,
    subject,
    content,
    personalizeVars,
    isPrivate
  ) => {
    setTemplateData({
      id,
      label,
      subject,
      content,
      personalizeVars,
      isPrivate,
    });
  };

  const tableHeaders = [
    { label: "Name", value: "name", sort: true },
    { label: "Subject", value: "subject", sort: true },
    { label: "Sent", value: "sent", sort: false },
    { label: "delivery", value: "delivery", sort: false },
    { label: "open percentage", value: "open_percentage", sort: false },
    { label: "spam", value: "spam", sort: false },
    { label: "Action", value: null, sort: false },
  ];
  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <CreateTemplateModal
          showOpportunityModal={showCreateModal}
          setShowOpportunityModal={() => setShowCreateModal(true)}
          handleCloseModal={() => setShowCreateModal(false)}
          getAllTemplates={getAllTemplates}
          isBulkEmail={true}
        />
        <EditTemplateModal
          showOpportunityModal={showEditModal}
          setShowOpportunityModal={() => setShowEditModal(true)}
          handleCloseModal={() => setShowEditModal(false)}
          getAllTemplates={getAllTemplates}
          templateData={templateData}
          isBulkEmail={true}
        />
        <DeleteTemplateModal
          showOpportunityModal={showDeleteModal}
          setShowOpportunityModal={() => setShowDeleteModal(true)}
          handleCloseModal={() => setShowDeleteModal(false)}
          getAllTemplates={getAllTemplates}
          templateData={templateData}
        />
        <h2 style={{ marginBottom: "0px" }}>
          Templates
          <button
            className="button-md"
            style={{
              float: "right",
              fontSize: "13.33px",
              textDecoration: "none",
              justifyContent: "center",
              textAlign: "center",
            }}
            onClick={() => setShowCreateModal(true)}
          >
            Create Template
          </button>
        </h2>
        <p> </p>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : loading === false && allTemplates.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No templates found
        </div>
      ) : (
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw" }}
        >
          <colgroup>
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "10%" }} />
          </colgroup>
          <thead>
            <tr>
              {tableHeaders.map((header) => (
                <th
                  key={header.label}
                  colSpan={header.label === "Subject" ? "4" : "1"}
                >
                  <Row>
                    <Col lg={9} xl={9}>
                      {header.label}
                    </Col>
                    <Col lg={3} xl={3}>
                      {header.sort && (
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            outline: "none",
                            color: "#657885",
                          }}
                          onClick={() => {
                            setSortDirection(!sortDirection);
                            getAllTemplates(header.value);
                          }}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                          <i className="fa fa-long-arrow-down"></i>
                        </button>
                      )}
                    </Col>
                  </Row>
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {allTemplates?.map((temp) => {
              const {
                id,
                name,
                subject,
                body,
                is_private,
                personalize_vars,
                sent,
                delivery,
                open_percentage,
                spam,
              } = temp;
              let finalContent = body;
              let finalSubject = subject;
              for (let i = 0; i < personalize_vars.length; i++) {
                finalContent = finalContent.includes(
                  `{{ ${personalize_vars[i].key} }}`
                )
                  ? finalContent.replaceAll(
                      `{{ ${personalize_vars[i].key} }}`,
                      `<img src="" alt="${personalize_vars[i].label}" class="jodit-variable-img">`
                    )
                  : finalContent;
                finalSubject = finalSubject.includes(
                  `{{ ${personalize_vars[i].key} }}`
                )
                  ? finalSubject.replaceAll(
                      `{{ ${personalize_vars[i].key} }}`,
                      `<img src="" alt="${personalize_vars[i].label}" class="jodit-variable-img">`
                    )
                  : finalSubject;
              }
              return (
                <tr key={id}>
                  <td
                    onClick={() => {
                      getSingleTemplateData(
                        id,
                        name,
                        subject,
                        body,
                        personalize_vars,
                        is_private
                      );
                      setShowEditModal(true);
                    }}
                    style={{ cursor: "pointer", color: "#41BE89" }}
                  >
                    {name}
                  </td>
                  <td colspan="4">
                    <div>
                      <JoditEditor
                        value={finalSubject === false ? subject : finalSubject}
                        config={config}
                      />
                    </div>
                  </td>
                  <td>{sent}</td>
                  <td>{delivery}</td>
                  <td>{open_percentage}</td>
                  <td>{spam}</td>
                  <td>
                    <button
                      type="button"
                      style={{
                        border: "1px solid #ffffff",
                        borderRadius: "9px",
                        padding: "5px 10px",
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "18px",
                        color: "#73738C",
                        background: "#F4F6F5",
                        height: "35px",
                        width: "60px",
                        cursor: "pointer",
                        marginRight: "5px",
                      }}
                      onClick={() => {
                        setShowEditModal(true);
                        getSingleTemplateData(
                          id,
                          name,
                          subject,
                          body,
                          personalize_vars,
                          is_private
                        );
                      }}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      style={{
                        border: "1px solid #C5C5D3",
                        borderRadius: "9px",
                        padding: "5px 10px",
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "18px",
                        color: "#F36363",
                        background: "white",
                        height: "35px",
                        width: "65px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowDeleteModal(true);
                        getSingleTemplateData(
                          id,
                          name,
                          subject,
                          body,
                          personalize_vars,
                          is_private
                        );
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default TemplatePage;
