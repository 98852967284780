import { AUTH_USER_SET, AUTH_TOKEN_SET, COMPANY_ONBOARDING_SET } from "./loginTypes";

export const authUserSet = (authUserData) => {
  return { type: AUTH_USER_SET, payload: authUserData };
};

export const authTokenSet = (authTokenData) => {
  return { type: AUTH_TOKEN_SET, payload: authTokenData };
};

export const companyOnboardingSet = (companyOnboardingData) => {
  return { type: COMPANY_ONBOARDING_SET, payload: companyOnboardingData };
};
