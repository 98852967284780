import React, { Component } from "react";
import ReactModal from "react-modal";
import DragHandlerIcon from "../../images/DragHandler.svg";
import DeleteIcon from "../../images/DeleteIcon.svg";
import LockStages from "../../images/LockStages.svg";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

const DragHandle = sortableHandle(() => (
  <span
    style={{ cursor: "grab", padding: "2px 4px 0 4px", marginLeft: "14px" }}
  >
    <img src={DragHandlerIcon} alt="::" />
  </span>
));

const SortableItem = sortableElement(
  ({
    value,
    changeStageHandler,
    deleteStageHandler,
    handleOpenModal,
    stages,
    deleteNewStageHandler,
  }) => {
    return (
      <li style={{ display: "block" }}>
        <div
          style={
            value.name === "Long-term"
              ? {
                  borderBottom: "1px solid #eee",
                  width: "70%",
                  marginBottom: "10px",
                }
              : {}
          }
        ></div>
        <DragHandle />
        <input
          style={{
            border: "none",
            borderBottom: "1px solid #C5C5D3",
            width: "135px",
            marginBottom: "15px",
            outline: "none",
            padding: "4px 8px",
          }}
          name={value.id}
          type="text"
          value={value.name}
          onChange={(e) => changeStageHandler(null, e)}
          disabled={!value.editable}
        />
        {!value.editable || value.type === "new" ? (
          <button
            style={{
              marginRight: "30px",
              border: "none",
              background: "transparent",
              padding: "0",
              outline: "none",
              cursor: "not-allowed",
            }}
            // onClick={() => deleteStageHandler(value.id)}
            type="button"
            disabled={!value.editable || value.type === "new"}
          >
            <img
              src={LockStages}
              alt="Lock"
              width="14"
              style={{ padding: "4px", transform: "translateY(6px)" }}
            />
          </button>
        ) : (
          <button
            style={{
              marginRight: "30px",
              border: "none",
              background: "transparent",
              padding: "0",
              outline: "none",
              cursor: "pointer",
            }}
            // onClick={() => deleteStageHandler(value.id)}
            onClick={() => {
              value._new
                ? deleteNewStageHandler(value.id)
                : handleOpenModal(value.id, value.name);
            }}
            type="button"
            disabled={!value.editable}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              width="14"
              style={{ padding: "4px", transform: "translateY(6px)" }}
            />
          </button>
        )}
      </li>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

class SalesFunnelStagesSort extends Component {
  state = {
    items: [],
    currentStageId: "",
    currentStageName: "",
    showDeleteModal: false,
  };
  setCurrentStage = (stageId, stageName) => {
    this.setState({
      currentStageId: stageId,
      currentStageName: stageName,
    });
  };
  handleOpenModal = (stageId, stageName) => {
    this.setState({
      showDeleteModal: true,
      currentStageId: stageId,
      currentStageName: stageName,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    // this.setState(({ items }) => ({
    //   items: arrayMove(items, oldIndex, newIndex),
    // }));
    if (
      newIndex === 0 ||
      this.props?.stages[newIndex].type === "won" ||
      this.props?.stages[newIndex].type === "lost" ||
      this.props?.stages[newIndex].name === "Long-term"
    ) {
      return false;
    }

    this.props.setStages(arrayMove(this.props?.stages, oldIndex, newIndex));
  };

  render() {
    return (
      <div>
        <DeleteModal
          showOpportunityModal={this.state.showDeleteModal}
          setShowOpportunityModal={this.handleOpenModal}
          handleCloseModal={this.handleCloseModal}
          id={this.state.currentStageId}
          deleteStageHandler={this.props.deleteStageHandler}
          name={this.state.currentStageName}
          stages={this.props.stages}
        />
        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
          {this.props?.stages
            ?.filter((stage) => !stage.destroy)
            .map((stage, index) => (
              <SortableItem
                key={`stage-${index}`}
                index={index}
                value={stage}
                changeStageHandler={this.props?.changeStageHandler}
                deleteStageHandler={this.props?.deleteStageHandler}
                disabled={!stage.editable}
                stages={this.props?.stages}
                setCurrentStage={this.setCurrentStage}
                handleOpenModal={this.handleOpenModal}
                deleteNewStageHandler={this.props?.deleteNewStageHandler}
              />
            ))}
        </SortableContainer>
      </div>
    );
  }
}

class DeleteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
      stageTransfer: "",
      errorMessage: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleOpenModal() {
    this.props.setShowOpportunityModal(true);
  }

  handleCloseModal() {
    this.props.setShowOpportunityModal(false);
  }

  showModal = () => {
    ReactModal.isOpen = false;
  };
  stageTransferChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "60vh", top: "15%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Stage</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form>
                <p>
                  Are you sure you want to delete{" "}
                  <strong>{this.props.name}</strong>?
                  <br />
                  By deleting this stage all deals associated with it will be
                  transferred to new stage
                </p>
                <label>Select New Stage</label>
                <select
                  name="stageTransfer"
                  value={this.state.stageTransfer}
                  onChange={this.stageTransferChangeHandler}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.props?.stages
                    .filter((stage) => stage.id !== this.props.id)
                    .filter((stage) => stage._new !== true)
                    .map((stage) => {
                      return (
                        <option value={stage.id} key={stage.id}>
                          {stage.name}
                        </option>
                      );
                    })}
                </select>
                {this.state.errorMessage === true &&
                this.state.stageTransfer === "" ? (
                  <div style={{ color: "red" }}>Please select a stage</div>
                ) : null}
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (this.state.stageTransfer === "") {
                        this.setState({
                          errorMessage: true,
                        });
                        return;
                      } else {
                        this.props.deleteStageHandler(
                          this.props.id,
                          this.state.stageTransfer
                        );
                      }
                    }}
                    className="modal__confirm-button"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SalesFunnelStagesSort;
