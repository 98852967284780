import {
  FETCH_MODES_REQUEST,
  FETCH_MODES_SUCCESS,
  FETCH_MODES_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllModesRequest = () => {
  return {
    type: FETCH_MODES_REQUEST,
  };
};
const fetchAllModesSuccess = (data) => {
  return {
    type: FETCH_MODES_SUCCESS,
    payload: data,
  };
};
const fetchAllModesFailure = (err) => {
  return {
    type: FETCH_MODES_FAILURE,
    payload: err,
  };
};

// Fetch All AllModes
export const fetchAllModes = () => {
  return (dispatch) => {
    dispatch(fetchAllModesRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/lanes/modes`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.modes;
        dispatch(fetchAllModesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllModesFailure(errorMsg));
      });
  };
};
