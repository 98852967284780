import React, { Component } from "react";
import "../../sass/TreeView.scss";
import CreateTeamModal from "./CreateTeamModal";

class TreeView extends Component {
  state = {
    showButton: false,
    showButton1: false,
    showButton2: false,
  };
  render() {
    return (
      <div>
        <figure>
          <ul className="tree">
            <li>
              <code
                style={{
                  background: "#FFF8EB",
                  border: "1px solid #E79209",
                  borderLeft: "3px solid #E79209",
                }}
              >
                {this.props.companyName
                  ? this.props.companyName
                  : "Company Name"}
              </code>
              <ul>
                {this.props.teams.map((team) => {
                  const { name, child_teams, id } = team;
                  return (
                    <li key={id}>
                      <code
                        style={{
                          background: "#F2FCFF",
                          border: "1px solid #1778F2",
                          borderLeft: "3px solid #1778F2",
                        }}
                      >
                        {name}
                      </code>
                      {child_teams === [] ? null : (
                        <ul>
                          {child_teams?.map((child) => (
                            <li key={child.id}>
                              <code
                                style={{
                                  background: "#E7F3ED",
                                  border: "1px solid #4BC893",
                                  borderLeft: "3px solid #4BC893",
                                }}
                              >
                                {child.name}
                              </code>
                              {child_teams === [] ? null : (
                                <ul>
                                  {child.child_teams?.map((ch) => (
                                    <li key={ch.id}>
                                      <code
                                        style={{
                                          background: "#F9EFEF",
                                          border: "1px solid #D32F2F",
                                          borderLeft: "3px solid #D32F2F",
                                        }}
                                      >
                                        {ch.name}
                                      </code>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        </figure>
        <CreateTeamModal
          showOpportunityModal={this.props.userModal}
          setShowOpportunityModal={this.props.handleOpenUserModal}
          handleCloseModal={this.props.handleCloseUserModal}
        />
      </div>
    );
  }
}

export default TreeView;
