import axios from "axios";
import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import ReactModal from "react-modal";
import "react-image-crop/dist/ReactCrop.css";
// Redux
import { connect } from "react-redux";
import { authUserSet } from "../../redux";
// Increase pixel density for  crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

const ProfilePicCrop = (props) => {
  const [loading, setLoading] = useState(false);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [resizedImgRefSrc, setResizedImgRefSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 20,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // Convert a Base64-encoded string to a File object
  const base64StringtoFile = (base64String, filename) => {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const uploadingImageToServer = () => {
    setLoading(true);
    let formData = new FormData();
    // earlier ---> imgRef.current.src and the whole image got uploaded
    formData.append(
      "image",
      base64StringtoFile(resizedImgRefSrc, "profilepic.jpg")
    );

    axios({
      url: `/users/profile-img`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        NotificationManager.success("Profile Picture changed");
        setLoading(false);
        props.handleCloseModal();
        props.getUser();
        props.authUserSet({
          ...props.authUser,
          profile_img: res.data.data.image_url,
        });
        localStorage.setItem(
          "authUser",
          JSON.stringify({
            ...props.authUser,
            profile_img: res.data.data.image_url,
          })
        );
      })
      .catch((err) => {
        NotificationManager.error("Error uploading profile picture");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    setResizedImgRefSrc(canvas.toDataURL("image/jpeg"));
  }, [completedCrop]);
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };

  return (
    <>
      {/* Modal for Image crop preview (only visible if image is selected) */}
      <ReactModal
        isOpen={props.showOpportunityModal}
        contentLabel="onRequestClose Example"
        onRequestClose={props.handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div className="modal__content" style={{ height: "85vh", top: "5%" }}>
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Upload Profile Pic</h2>
              </div>
              <button
                onClick={props.handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <div>
              <input type="file" accept="image/*" onChange={onSelectFile} />
            </div>
            <div style={{ height: "60vh", overflowY: "auto" }}>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
              {upImg && <h3>Image Preview</h3>}
              <div>
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                  }}
                />
              </div>
            </div>
            <div className="modal__buttons">
              {" "}
              <button
                type="button"
                className="modal__cancel-button"
                onClick={props.handleCloseModal}
              >
                Cancel
              </button>
              <button
                className="modal__confirm-button"
                type="button"
                onClick={uploadingImageToServer}
                disabled={!completedCrop || loading}
              >
                {loading ? "Uploading" : "Upload"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(ProfilePicCrop);
