import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import "../sass/SignIn.scss";
import "../sass/Grid.scss";
import SalesdashLogo from "../images/SalesdashLogoLogin.png";
import { LANDING } from "../constants/routes";
import { Link } from "react-router-dom";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";

class ForgotPassword extends Component {
  state = {
    email: "",
    errorMessage: false,
    successMessage: false,
  };
  changeHandler = (e) => {
    const { value } = e.target;
    this.setState({
      email: value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.email === "" || this.state.email === null) {
      this.setState({ errorMessage: true });
      return;
    }
    axios({
      method: "POST",
      url: `/auth/send-password-reset`,
      data: {
        email: this.state.email,
        site_name: window.location.host.split(".")[0],
      },
    })
      .then(
        (res) => res.status === 200 && this.setState({ successMessage: true })
      )
      .catch((err) => NotificationManager.error(err.response.data.message));
  };
  render() {
    return (
      <div className="signin">
        <Container>
          <Row>
            <Col xl={6} lg={6}>
              <div className="signin__left">
                <div className="signin__form">
                  <h2 className="signin__heading">
                    Forgot Password - Salesdash
                  </h2>
                  <p>
                    Please enter the email address you created your Salesdash
                    account with.
                  </p>
                  {this.state.successMessage ? (
                    <div>
                      <h4>Your reset email has been sent to</h4>
                      <h2>"{this.state.email}"</h2>
                    </div>
                  ) : (
                    <form onSubmit={this.submitHandler}>
                      <label htmlFor="email" className="signin__label">
                        Email
                      </label>
                      <input
                        className="signin__input"
                        type="email"
                        name="email"
                        placeholder="Enter your Email"
                        onChange={this.changeHandler}
                        style={
                          this.state.errorMessage === true &&
                          (this.state.email === null || this.state.email === "")
                            ? {
                                border: "2px solid #F36363",
                              }
                            : {}
                        }
                      />
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {this.state.errorMessage === true &&
                        (this.state.email === null || this.state.email === "")
                          ? "Email is required"
                          : null}
                      </div>
                      <button className="signin__button-1" type="submit">
                        Send Reset Link
                      </button>
                    </form>
                  )}
                  <Link to={LANDING} className="forgot-password">
                    Back to Login
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={6}>
              <div className="grad">
                <img
                  src={SalesdashLogo}
                  alt=""
                  width="600px"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ForgotPassword;
