import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";

class BulkStatusUpdateModal extends Component {
  state = {
    showModal: true,
    newStatus: null,
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  changeHandler = (e) => {
    this.setState({
      newStatus: e.target.value,
    });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "28vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Bulk Status Update</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <label>New status</label>
              <select
                value={this.state.newStatus}
                onChange={this.changeHandler}
                style={
                  this.props.bulkStatusErrorMessage === true &&
                  (this.state.newStatus === null || this.state.newStatus === "")
                    ? {
                        border: "2px solid #F36363",
                      }
                    : {
                        width: "auto",
                        maxWidth: "250px",
                        minWidth: "150px",
                      }
                }
              >
                <option value="" selected disabled>
                  - Select -
                </option>
                {this.props.allStatuses?.map((user) => {
                  const { name, id } = user;
                  return (
                    <option key={id} value={name}>
                      {name}
                    </option>
                  );
                })}
              </select>
              <div
                style={{
                  color: "#F36363",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {this.props.bulkStatusErrorMessage === true &&
                (this.state.newStatus === null || this.state.newStatus === "")
                  ? "Please select a status"
                  : null}
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={this.props.bulkStatusLoader}
                  className="modal__confirm-button"
                  onClick={() => {
                    this.props.bulkStatusUpdate(this.state.newStatus);
                    this.setState({ newStatus: null });
                  }}
                >
                  {this.props.bulkStatusLoader ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default BulkStatusUpdateModal;
