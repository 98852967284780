import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const selctedRadio = {
  border: "2px solid #A0DFC4",
  outline: "none",
  color: "#555555",
  padding: "4px 12px",
  borderRadius: "9px",
  marginRight: "13px",
  background: "#F4F6F5",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "16px",
};
const unSelectedRadio = {
  color: "#555555",
  padding: "4px 12px",
  border: "none",
  borderRadius: "9px",
  background: "#F4F6F5",
  marginRight: "13px",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "16px",
};

class SendTaskSequenceModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    sequences: [],
    isLoading: false,
    assigned_to: this.props.existingUser,
    isError: false,
  };
  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  changeHandler = (i, value, type) => {
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq, index) => {
        if (i === index) {
          seq[type] = value;
        }
        return seq;
      }),
    }));
  };
  getNextBusinessDay(interval) {
    // Create a date object for today
    let date = new Date();
  
    // Function to check if a date is a weekend
    const isWeekend = (date) => date.getDay() === 6 || date.getDay() === 0;
  
    // If the interval is 0, return today's date
    if (interval === 0) {
      return date;
    }
  
    // Loop until we have added the required number of business days
    while (interval > 0) {
      // Move to the next day
      date.setDate(date.getDate() + 1);
  
      // If it's not a weekend, count it as a business day
      if (!isWeekend(date)) {
        interval--;
      }
    }
  
    return date;
  }
  setSequenceValue = () => {
    this.setState({
      sequences: this.props.selectedSeq.tasks?.map((step, index) => {
        const newDate = this.getNextBusinessDay(step.interval)
        return {
          ...step,
          assigned_to: this.props.existingUser,
          date: newDate,
          start_time:
            step.start_time !== null
              ? step.start_time &&
                moment(step.start_time, "HH:mm:ss").format("hh:mm A")
              : null,
          end_time:
            step.end_time !== null
              ? step.end_time &&
                moment(step.end_time, "HH:mm:ss").format("hh:mm A")
              : null,
        };
      }),
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setSequenceValue();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.setSequenceValue();
      this.setState({
        assigned_to: this.props.existingUser,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  sendSequence = () => {
    this.setState({
      isLoading: true,
    });
    const checkError = this.state.sequences?.every((step) => {
      const { start_time, end_time } = step;
      if ((start_time && !end_time) || (end_time && !start_time)) {
        this.setState({
          isError: true,
          isLoading: false,
        });
        return false;
      }
      return step;
    });
    if (checkError === true) {
      this.setState({
        isError: false,
      });
      axios({
        method: "POST",
        url: `/task-sequences/schedule-tasks`,
        data: {
          tasks: this.state.sequences?.map((seq) => ({
            name: seq.name,
            start_time:
              seq.start_time !== null
                ? seq.start_time !==
                  moment(seq.start_time, "HH:mm").format("HH:mm")
                  ? seq.start_time &&
                    moment(seq.start_time, "hh:mm A").format("HH:mm")
                  : seq.start_time && moment(seq.start_time).format("HH:mm")
                : null,
            end_time:
              seq.end_time !== null
                ? seq.end_time !== moment(seq.end_time, "HH:mm").format("HH:mm")
                  ? seq.end_time &&
                    moment(seq.end_time, "hh:mm A").format("HH:mm")
                  : seq.end_time && moment(seq.end_time).format("HH:mm")
                : null,
            ...(this.props.accountId && { account_id: this.props.accountId }),
            ...(this.props.opportunityId && {
              opportunity_id: this.props.opportunityId,
            }),
            ...(this.props.contactId && { contact_id: this.props.contactId }),
            ...(this.props.carrierId && { carrier_id: this.props.carrierId }),
            category_id: seq.category_id,
            date: seq.date && moment(seq.date).format("YYYY-MM-DD"),
            assigned_to: seq.assigned_to,
          })),
        },
      })
        .then((res) => {
          NotificationManager.success("Sequence started successfully");
          this.props.handleCloseModal();
          this.props.getTasks();
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          NotificationManager.error("Error starting sequence.");
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={
                this.state.windowWidth < 600
                  ? {
                      height: "95vh",
                      top: "1%",
                      left: "0%",
                      width: "90vw",
                    }
                  : { height: "90vh", top: "2%", left: "12%", width: "70vw" }
              }
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Start Task Sequence</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div style={{ height: "70vh", overflowY: "auto" }}>
                {this.state.sequences?.map((step, index) => {
                  return (
                    <div
                      style={{
                        border: "1px solid #eee",
                        position: "relative",
                        marginBottom: "16px",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        marginRight: "5px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "40px" }}>
                          <label style={{ fontWeight: "500" }}>Date</label>
                          <DatePicker
                            selected={moment(step.date).toDate()}
                            autoComplete="off"
                            todayButton="Today"
                            utcOffset={0}
                            value={step.date}
                            onChange={(date) =>
                              this.changeHandler(index, date, "date")
                            }
                            placeholderText="Date"
                            dateFormat={
                              this.props.dateFormat === "DD/MM/YYYY"
                                ? "dd/MM/yyyy"
                                : "MM/dd/yyyy"
                            }
                            className="modal-datepicker"
                          />
                        </div>
                        <div style={{ marginRight: "10px" }}>
                          <label style={{ fontWeight: "500" }}>
                            Start Time
                          </label>
                          <DatePicker
                            selected={Date.parse(step.start_time)}
                            autoComplete="off"
                            onChange={(date) =>
                              this.changeHandler(index, date, "start_time")
                            }
                            value={step.start_time}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            placeholderText="Start Time"
                            isClearable={true}
                            style={{ width: "115px" }}
                            className={
                              this.state.isError === true &&
                              ((step.end_time !== null &&
                                step.start_time === null) ||
                                (step.end_time !== "" &&
                                  step.start_time === "")) &&
                              "border-error"
                            }
                          />
                          <div
                            style={{
                              color: "#F36363",
                              fontSize: "12px",
                              marginBottom: "5px",
                            }}
                          >
                            {this.state.isError === true &&
                            ((step.end_time !== null &&
                              step.start_time === null) ||
                              (step.end_time !== "" && step.start_time === ""))
                              ? "Start Time is required"
                              : null}
                          </div>
                        </div>
                        <div>
                          <label style={{ fontWeight: "500" }}>End Time</label>
                          <DatePicker
                            selected={Date.parse(step.end_time)}
                            autoComplete="off"
                            onChange={(date) =>
                              this.changeHandler(index, date, "end_time")
                            }
                            value={step.end_time}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            placeholderText="End Time"
                            isClearable={true}
                            style={{ width: "115px" }}
                            className={
                              this.state.isError === true &&
                              ((step.start_time !== null &&
                                step.end_time === null) ||
                                (step.start_time !== "" &&
                                  step.end_time === "")) &&
                              "border-error"
                            }
                          />
                          <div
                            style={{
                              color: "#F36363",
                              fontSize: "12px",
                              marginBottom: "5px",
                            }}
                          >
                            {this.state.isError === true &&
                            ((step.start_time !== null &&
                              step.end_time === null) ||
                              (step.start_time !== "" && step.end_time === ""))
                              ? "End Time is required"
                              : null}
                          </div>
                        </div>
                        <div
                          style={{ alignSelf: "flex-end", marginLeft: "auto" }}
                        >
                          <label style={{ fontWeight: "500" }}>
                            Task Owner
                          </label>
                          <select
                            style={{ width: "100%" }}
                            value={step.assigned_to}
                            onChange={(e) =>
                              this.changeHandler(
                                index,
                                e.target.value,
                                "assigned_to"
                              )
                            }
                          >
                            {this.props.users?.map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid #EDF0F2",
                          borderBottom: "1px solid #EDF0F2",
                          margin: "10px 0",
                          padding: "3px 0",
                        }}
                      >
                        <label
                          style={{
                            fontSize: "14px",
                            display: "inline-block",
                            marginRight: "5px",
                            fontWeight: "500",
                          }}
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          style={{
                            width: "86%",
                            padding: "5px",
                            borderRadius: "4px",
                            border: "none",
                            outline: "none",
                            fontSize: "14px",
                            display: "inline-block",
                          }}
                          value={step.name}
                          onChange={(e) =>
                            this.changeHandler(index, e.target.value, "name")
                          }
                        />
                      </div>
                      <label
                        style={{ display: "inline-block", fontWeight: "500" }}
                      >
                        Category
                      </label>
                      <div
                        className="modal-radioButton"
                        style={{ margin: "5px 0 10px 0" }}
                      >
                        <label
                          style={
                            step.category_id.toString() === "1"
                              ? selctedRadio
                              : unSelectedRadio
                          }
                        >
                          Call
                          <input
                            type="radio"
                            name="category_id"
                            value="1"
                            className="radio-button"
                            onChange={(e) =>
                              this.changeHandler(
                                index,
                                e.target.value,
                                "category_id"
                              )
                            }
                          />
                        </label>
                        <label
                          style={
                            step.category_id.toString() === "2"
                              ? selctedRadio
                              : unSelectedRadio
                          }
                        >
                          Email
                          <input
                            type="radio"
                            name="category_id"
                            value="2"
                            className="radio-button"
                            onChange={(e) =>
                              this.changeHandler(
                                index,
                                e.target.value,
                                "category_id"
                              )
                            }
                          />
                        </label>
                        <label
                          style={
                            step.category_id.toString() === "3"
                              ? selctedRadio
                              : unSelectedRadio
                          }
                        >
                          Text
                          <input
                            type="radio"
                            name="category_id"
                            value={3}
                            className="radio-button"
                            onChange={(e) =>
                              this.changeHandler(
                                index,
                                e.target.value,
                                "category_id"
                              )
                            }
                          />
                        </label>
                        <label
                          style={
                            step.category_id.toString() === "4"
                              ? selctedRadio
                              : unSelectedRadio
                          }
                        >
                          Visit
                          <input
                            type="radio"
                            name="category_id"
                            value="4"
                            className="radio-button"
                            onChange={(e) =>
                              this.changeHandler(
                                index,
                                e.target.value,
                                "category_id"
                              )
                            }
                          />
                        </label>
                        <label
                          style={
                            step.category_id.toString() === "5"
                              ? selctedRadio
                              : unSelectedRadio
                          }
                        >
                          Meeting
                          <input
                            type="radio"
                            name="category_id"
                            value="5"
                            className="radio-button"
                            onChange={(e) =>
                              this.changeHandler(
                                index,
                                e.target.value,
                                "category_id"
                              )
                            }
                          />
                        </label>
                        <label
                          style={
                            step.category_id.toString() === "6"
                              ? selctedRadio
                              : unSelectedRadio
                          }
                        >
                          Notes
                          <input
                            type="radio"
                            name="category_id"
                            value="6"
                            className="radio-button"
                            onChange={(e) =>
                              this.changeHandler(
                                index,
                                e.target.value,
                                "category_id"
                              )
                            }
                          />
                        </label>
                      </div>
                    </div>
                  );
                })}
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="modal__confirm-button"
                    onClick={this.sendSequence}
                    disabled={this.state.isLoading}
                    style={{ right: "7%" }}
                  >
                    {this.state.isLoading ? "Starting ..." : "Start Sequence"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SendTaskSequenceModal;
