import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "../../sass/Notifications.scss";
import StageDecisionModal from "./StageDecisionModal";
import DealFunnelChangeModal from "./DealFunnelChangeModal";

class StageReasonModal extends Component {
  state = {
    showModal: true,
    closeReasonArray: [],
    closeReason: "",
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    showDecisionModal: false,
    showFunnelModal: false,
    ownerId: "",
    stageId: "",
    funnelId: "",
    stages: [],
    currentStageType: null,
    funnelCloseReason: "",
    funnelErrorMessage: false,
    funnelIsLoading: false,
  };
  openDecisionModal = () => {
    this.setState({ showDecisionModal: true });
  };
  closeDecisionModal = () => {
    this.setState({ showDecisionModal: false });
  };
  openFunnelModal = () => {
    this.setState({ showFunnelModal: true, showDecisionModal: false });
  };
  closeFunnelModal = () => {
    this.setState({ showFunnelModal: false });
  };
  handleFunnelChangeWithoutWon = () => {
    this.openFunnelModal();
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      ownerId: this.props.ownerId,
    });
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) =>
      this.setState({ integration: res.data.user.mail_provider })
    );
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => funnel.is_default === true && funnel)
        .filter((funnel) => funnel !== false);
      const stage_id = selectedFunnel[0].stages
        .map((item) => item.type === "new" && item.id)
        .filter((stage) => stage !== false);
      this.setState({
        funnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages,
        // stageId: stage_id[0],
        // funnelId: selectedFunnel[0].id,
        currentStageType: selectedFunnel[0].stages.find(
          (stage) => stage.id === stage_id[0]
        ),
      });
    });
    axios({
      method: "GET",
      url: `/close-reasons`,
    }).then((response) => {
      this.setState({
        closeReasonArray: response.data.reasons,
      });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.setState({
        ownerId: this.props.ownerId,
      });
    }
    this.props.setStageFunnelClick &&
      this.props.setStageFunnelClick(this.handleFunnelChangeWithoutWon);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "stageId") {
      this.setState({
        stageId: value,
        currentStageType: this.state.stages.find(
          (stage) => stage.id === parseInt(value)
        ),
      });
    } else {
      this.setState({
        [name]: value === "" ? null : value,
      });
    }
  };
  funnelChangeHandler = (e) => {
    const { name, value } = e.target;
    this.getAllFunnels(parseInt(value));
    this.setState({
      [name]: parseInt(value),
    });
  };
  getAllFunnels = (id) => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(id) && funnel;
        })
        .filter((funnel) => funnel !== false);
      const stage_id = selectedFunnel[0].stages
        .map((item) => item.type === "new" && item.id)
        .filter((stage) => stage !== false);
      this.setState({
        funnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages,
        stage_id: stage_id[0],
        currentStageType: selectedFunnel[0].stages.find(
          (stage) => stage.id === stage_id[0]
        ),
      });
    });
  };
  funnelSubmitHandler = () => {
    if (
      (this.state.funnelCloseReason === "" ||
        this.state.funnelCloseReason === null) &&
      (this.state.currentStageType?.type === "won" ||
        this.state.currentStageType?.type === "lost")
    ) {
      this.setState({
        funnelErrorMessage: true,
        funnelIsLoading: false,
      });
      return false;
    }
    if (this.state.funnelId === "" || this.state.stageId === "") {
      this.setState({
        funnelErrorMessage: true,
        funnelIsLoading: false,
      });
      return false;
    }
    this.setState({ funnelIsLoading: true });
    axios({
      method: "PUT",
      url: `/opportunities/${this.props.opportunityId}`,
      data: {
        opportunityData: this.props.state?.opportunityData,
        ...(this.props.customerId && { account_id: this.props.customerId }),
        name: this.props.state?.name,
        revenue: this.props.state.revenue,
        stage_id: this.state.stageId,
        funnel_id: this.state.funnelId,
        close_date: this.props.state.close_date,
        description: this.props.state.description,
        opportunity_owner_id: parseInt(this.state.ownerId),
        email: this.props.state.email,
        formatted_phone:
          this.props.state.phone === null ||
          this.props.state.phone === undefined ||
          this.props.state.phone === "undefined"
            ? null
            : this.props.state.phone.toString(),
        close_reason:
          this.state.funnelCloseReason === ""
            ? null
            : this.state.funnelCloseReason,
        custom_fields: this.props.state.customFields,
      },
    })
      .then((response) =>
        this.state.currentStageType?.type === "won"
          ? NotificationManager.success("Congratulations on the new deal!")
          : NotificationManager.success("Deal edited successfully.")
      )
      .then((response) => this.props.fetchOpportunitiesData())
      .then((response) => {
        this.props.handleCloseModal();
        this.closeDecisionModal();
        this.closeFunnelModal();
        if (this.props.currentStageType?.type === "won") {
          this.props.getConfetti();
        }
      })
      .then((response) =>
        this.setState({
          editing: false,
          funnelIsLoading: false,
          isLoading: false,
        })
      )
      .catch((error) => {
        this.setState({ funnelIsLoading: false, isLoading: false });
        NotificationManager.error("Error editing deal.");
      });
  };
  submitHandler = () => {
    if (this.state.closeReason === "") {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    this.setState({ showDecisionModal: false });
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/opportunities/${this.props.opportunityId}`,
      data: {
        opportunityData: this.props.state?.opportunityData,
        ...(this.props.customerId && { account_id: this.props.customerId }),
        name: this.props.state?.name,
        revenue: this.props.state?.revenue,
        stage_id: this.props.state?.stage_id,
        funnel_id: this.props.state?.funnel_id,
        close_date: this.props.state.close_date,
        description: this.props.state.description,
        opportunity_owner_id: this.props.state.opportunity_owner_id,
        close_reason: this.state?.closeReason,
        email: this.props.state.email,
        formatted_phone:
          this.props.state.phone === null ||
          this.props.state.phone === undefined ||
          this.props.state.phone === "undefined"
            ? null
            : this.props.state.phone.toString(),
        custom_fields: this.props.state.customFields,
      },
    })
      .then((response) =>
        this.props.currentStageType === "won"
          ? NotificationManager.success("Congratulations on the new deal!")
          : NotificationManager.success("Deal edited successfully.")
      )
      .then((response) => this.props.fetchOpportunitiesData())
      .then((response) => {
        this.props.handleCloseModal();
        this.closeDecisionModal();
        if (this.props.currentStageType === "won") {
          this.props.getConfetti();
        }
      })
      .then((response) => this.setState({ editing: false, isLoading: false }))
      .catch((error) => {
        this.setState({ isLoading: false });
        NotificationManager.error("Error editing deal.");
      });
  };
  makeDecision = () => {
    if (this.state.closeReason === "") {
      this.setState({
        errorMessage: true,
        isLoading: false,
      });
      return false;
    }
    if (
      this.props.funnels.length <= 1 ||
      this.props.currentStageType === "lost"
    ) {
      this.submitHandler();
    } else {
      this.openDecisionModal();
    }
  };
  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "30vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Change Stage</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <StageDecisionModal
                showOpportunityModal={this.state.showDecisionModal}
                setShowOpportunityModal={this.openDecisionModal}
                handleCloseModal={this.closeDecisionModal}
                submitHandler={this.submitHandler}
                openFunnelModal={this.openFunnelModal}
              />
              <DealFunnelChangeModal
                showOpportunityModal={this.state.showFunnelModal}
                setShowOpportunityModal={this.openFunnelModal}
                handleCloseModal={this.closeFunnelModal}
                users={this.props.users}
                ownerId={this.state.ownerId}
                funnels={this.state.funnels}
                funnelId={this.state.funnelId}
                stages={this.state.stages}
                stageId={this.state.stageId}
                changeHandler={this.changeHandler}
                currentStageType={this.state.currentStageType}
                funnelChangeHandler={this.funnelChangeHandler}
                closeReasonArray={this.state.closeReasonArray}
                errorMessage={this.state.funnelErrorMessage}
                funnelIsLoading={this.state.funnelIsLoading}
                funnelCloseReason={this.state.funnelCloseReason}
                submitHandler={this.funnelSubmitHandler}
                existingFunnelId={this.props.funnelId}
              />
              <label>Stage</label>
              <input
                name="label"
                type="text"
                value={this.props.currentStageType === "won" ? "Won" : "Lost"}
                readOnly={true}
              />
              <label>
                {this.props.currentStageType === "won"
                  ? "Won Reason"
                  : "Lost Reason"}
              </label>
              {this.props.currentStageType === "won" ? (
                <select
                  name="closeReason"
                  onChange={this.changeHandler}
                  style={{ width: "auto" }}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state.closeReasonArray.map((reason) => {
                    const { id, name, type } = reason;
                    const options =
                      type === "won" ? (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ) : null;
                    return options;
                  })}
                </select>
              ) : (
                <select
                  name="closeReason"
                  onChange={this.changeHandler}
                  style={{ width: "auto" }}
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state.closeReasonArray.map((reason) => {
                    const { id, name, type } = reason;
                    const options =
                      type === "lost" ? (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ) : null;
                    return options;
                  })}
                </select>
              )}
              {this.state.errorMessage === true &&
              this.state.closeReason === "" ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please select a reason
                </div>
              ) : null}
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={this.props.handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  disabled={this.state.isLoading}
                  onClick={this.makeDecision}
                >
                  {this.state.isLoading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default StageReasonModal;
