import {
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDS_SUCCESS,
  FETCH_DASHBOARDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchDashboardsRequest = () => {
  return {
    type: FETCH_DASHBOARDS_REQUEST,
  };
};
const fetchDashboardsSuccess = (data) => {
  return {
    type: FETCH_DASHBOARDS_SUCCESS,
    payload: data,
  };
};
const fetchDashboardsFailure = (err) => {
  return {
    type: FETCH_DASHBOARDS_FAILURE,
    payload: err,
  };
};

// Fetch All dashboards
export const fetchAllDashboards = () => {
  return (dispatch) => {
    dispatch(fetchDashboardsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/dashboard`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.dashboards;
        dispatch(fetchDashboardsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchDashboardsFailure(errorMsg));
      });
  };
};
