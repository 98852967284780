import React from "react";
import "../../sass/DropdownHeader.scss";
import ReactTooltip from "react-tooltip";
import { CONTACTS, ACCOUNTS, DEALS } from "../../constants/emailvariables";

class DropdownVariables extends React.Component {
  state = {
    displayMenu: false,
    searchValue: "",
    allVariablesArray: [],
    accountCollapsed: false,
    dealCollapsed: false,
    contactCollapsed: false,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const accountArray = ACCOUNTS.concat(this.props.accountCustomFields);
    const dealArray = DEALS.concat(this.props.dealCustomFields);
    const contactArray = CONTACTS.concat(this.props.contactCustomFields);
    const allVariablesArray = accountArray.concat(dealArray, contactArray);
    this.setState({
      allVariablesArray,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.displayMenu !== this.state.displayMenu &&
      this.state.displayMenu
    ) {
      const accountArray = ACCOUNTS.concat(this.props.accountCustomFields);
      const dealArray = DEALS.concat(this.props.dealCustomFields);
      const contactArray = CONTACTS.concat(this.props.contactCustomFields);
      const allVariablesArray = accountArray.concat(dealArray, contactArray);
      this.setState({
        allVariablesArray,
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };
  searchVariables = (e) => {
    const accountArray = ACCOUNTS.concat(this.props.accountCustomFields);
    const dealArray = DEALS.concat(this.props.dealCustomFields);
    const contactArray = CONTACTS.concat(this.props.contactCustomFields);
    const allVariablesArray = accountArray.concat(dealArray, contactArray);
    const { value } = e.target;
    this.setState({
      searchValue: value,
      allVariablesArray: value
        ? allVariablesArray?.filter((list) =>
            list.label.toLowerCase().includes(value.toLowerCase())
          )
        : allVariablesArray,
    });
  };

  render() {
    const accountArray = ACCOUNTS.concat(this.props.accountCustomFields);
    const dealArray = DEALS.concat(this.props.dealCustomFields);
    const contactArray = CONTACTS.concat(this.props.contactCustomFields);
    const accountArrayFiltered = this.state.allVariablesArray?.filter((acc) => {
      const accountArr = accountArray?.map((account) => account.key.toString());
      return accountArr?.includes(acc.key.toString());
    });
    const dealArrayFiltered = this.state.allVariablesArray?.filter((con) => {
      const dealArr = dealArray?.map((deal) => deal.key.toString());
      return dealArr?.includes(con.key.toString());
    });
    const contactArrayFiltered = this.state.allVariablesArray?.filter((con) => {
      const contactArr = contactArray?.map((contact) => contact.key.toString());
      return contactArr?.includes(con.key.toString());
    });
    return (
      <div
        ref={this.wrapperRef}
        className="dropdownTemplate"
        style={{
          background: "transparent",
          width: "15px",
        }}
      >
        <ReactTooltip />
        <div
          className="button"
          onClick={this.showDropdownMenu}
          style={{ fontSize: "14px" }}
          data-tip="Add Personalized variables"
        >
          Personalize
        </div>

        {this.state.displayMenu ? (
          <ul style={{ width: "271px", right: "-45px" }}>
            <li style={{ cursor: "default", width: "255px" }}>
              <div style={{ position: "relative" }}>
                <input
                  value={this.state.searchValue}
                  onChange={this.searchVariables}
                  placeholder="Search variables"
                  style={{ width: "94%", fontSize: "14px" }}
                />
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ position: "absolute", right: "20px", top: "7px" }}
                ></i>
              </div>
            </li>
            <div
              style={{
                height: "auto",
                maxHeight: "440px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {accountArrayFiltered.length > 0 && (
                <li
                  style={{
                    background: "#fff1d6",
                    cursor: "pointer",
                    width: "255px",
                    color: "#EDAA4F",
                    fontSize: "14px",
                  }}
                  onClick={() =>
                    this.setState({
                      accountCollapsed: !this.state.accountCollapsed,
                    })
                  }
                >
                  Accounts
                  <span style={{ float: "right" }}>
                    <i
                      className={`fa fa-caret-${
                        this.state.accountCollapsed ? "up" : "down"
                      }`}
                      aria-hidden="true"
                    ></i>
                  </span>
                </li>
              )}
              {this.state.accountCollapsed &&
                accountArrayFiltered?.map((template) => (
                  <li
                    key={template.key}
                    style={{ width: "255px", fontSize: "14px" }}
                  >
                    <div
                      style={{ maxWidth: "240px", width: "auto" }}
                      onClick={(e) => {
                        this.props.insertVariable(
                          template.key.toString(),
                          template.label
                        );
                        this.showDropdownMenu(e);
                      }}
                    >
                      {template.label}
                    </div>
                  </li>
                ))}
              {contactArrayFiltered.length > 0 && (
                <li
                  style={{
                    background: "#ebfaff",
                    cursor: "pointer",
                    width: "255px",
                    color: "#1878F2",
                    fontSize: "14px",
                  }}
                  onClick={() =>
                    this.setState({
                      contactCollapsed: !this.state.contactCollapsed,
                    })
                  }
                >
                  Contacts
                  <span style={{ float: "right" }}>
                    <i
                      className={`fa fa-caret-${
                        this.state.contactCollapsed ? "up" : "down"
                      }`}
                      aria-hidden="true"
                    ></i>
                  </span>
                </li>
              )}
              {this.state.contactCollapsed &&
                contactArrayFiltered?.map((template) => (
                  <li
                    key={template.key}
                    style={{ width: "255px", fontSize: "14px" }}
                  >
                    <div
                      style={{ maxWidth: "240px", width: "auto" }}
                      onClick={(e) => {
                        this.props.insertVariable(
                          template.key.toString(),
                          template.label
                        );
                        this.showDropdownMenu(e);
                      }}
                    >
                      {template.label}
                    </div>
                  </li>
                ))}
              {dealArrayFiltered.length > 0 && (
                <li
                  style={{
                    background: "#f9e1e1",
                    cursor: "pointer",
                    width: "255px",
                    color: "#D32F2F",
                    fontSize: "14px",
                  }}
                  onClick={() =>
                    this.setState({
                      dealCollapsed: !this.state.dealCollapsed,
                    })
                  }
                >
                  Deals
                  <span style={{ float: "right" }}>
                    <i
                      className={`fa fa-caret-${
                        this.state.dealCollapsed ? "up" : "down"
                      }`}
                      aria-hidden="true"
                    ></i>
                  </span>
                </li>
              )}
              {this.state.dealCollapsed &&
                dealArrayFiltered?.map((template) => (
                  <li
                    key={template.key}
                    style={{ width: "255px", fontSize: "14px" }}
                  >
                    <div
                      style={{ maxWidth: "240px", width: "auto" }}
                      onClick={(e) => {
                        this.props.insertVariable(
                          template.key.toString(),
                          template.label
                        );
                        this.showDropdownMenu(e);
                      }}
                    >
                      {template.label}
                    </div>
                  </li>
                ))}
            </div>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default DropdownVariables;
