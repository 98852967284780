import "./YoutubePlayer.scss";

function YoutubePlayer({ width = "560", height = "315", src = "", closeIframe }) {
  return (
    <div className="youtube-video" onClick={closeIframe}>
      <div className="youtube-video-player">
        <iframe
          width={width}
          height={height}
          src={src}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowFullScreen"
        ></iframe>
      </div>
    </div>
  );
}

export default YoutubePlayer;
