import React, { useState } from "react";
import "../../sass/Funnel.scss";
import "../../sass/Targets.scss";
import DeleteIcon from "../../images/DeleteIcon.svg";
import FunnelChartCustomFunction from "./FunnelChartCustomFunction";
import FunnelCreateModal from "./FunnelCreateModal";
import FunnelDeleteModal from "./FunnelDeleteModal";
import DropdownFunnelCustom from "../Dropdown/DropdownFunnelCustom";
import DropdownFunnel from "../Dropdown/DropdownFunnel";

function FunnelCustomFunction(props) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const wonLength = props.reasons
    .map((reason) => reason.type === "won" && reason.destroy === false)
    .filter((reason) => reason !== false);
  const lostLength = props.reasons
    .map((reason) => reason.type === "lost" && reason.destroy === false)
    .filter((reason) => reason !== false);
  return (
    <div>
      <p>&nbsp;</p>
      <div className="card card-funnel">
        <h3
          className="card-funnel-container"
          style={{
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: "500",
            textAlign: "left",
            marginLeft: "14px",
            marginTop: "8px",
            marginBottom: "0px",
          }}
        >
          <input
            style={
              props.emptyFunnelErrorMessage &&
              (props.selectedFunnelName === "" ||
                props.selectedFunnelName === null)
                ? {
                    padding: "4px",
                    border: "none",
                    borderBottom: "1px solid #F36363",
                    marginRight: "10px",
                    fontSize: "16px",
                    fontWeight: "500",
                    outline: "none",
                    marginBottom: "10px",
                  }
                : {
                    padding: "4px",
                    border: "none",
                    borderBottom: "1px solid black",
                    marginRight: "10px",
                    fontSize: "16px",
                    fontWeight: "500",
                    outline: "none",
                    marginBottom: "10px",
                  }
            }
            value={props.selectedFunnelName}
            onChange={props.changeFunnelName}
          />
          <DropdownFunnel
            allFunnels={props.allFunnels}
            getAllFunnels={props.getAllFunnels}
          />
          <span
            style={{ cursor: "pointer", float: "right", marginRight: "20px" }}
          >
            <DropdownFunnelCustom
              setShowCreateModal={setShowCreateModal}
              setShowDeleteModal={setShowDeleteModal}
            />
          </span>
        </h3>
        {props.emptyFunnelErrorMessage &&
          (props.selectedFunnelName === "" ||
            props.selectedFunnelName === null) && (
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
                textAlign: "left",
                marginLeft: "14px",
              }}
            >
              Funnel Name cannot be empty
            </div>
          )}
        <FunnelCreateModal
          showOpportunityModal={showCreateModal}
          setShowOpportunityModal={() => setShowCreateModal(true)}
          handleCloseModal={() => setShowCreateModal(false)}
          getAllFunnels={props.getAllFunnelsInitialized}
        />
        <FunnelDeleteModal
          showOpportunityModal={showDeleteModal}
          setShowOpportunityModal={() => setShowDeleteModal(true)}
          handleCloseModal={() => setShowDeleteModal(false)}
          deleteStageHandler={props.deleteStageHandler}
          name={props.selectedFunnelName}
          stages={props.selectedFunnel.stages}
          allFunnels={props.allFunnels}
          deleteFunnelHandler={props.deleteFunnelHandler}
          selectedFunnel={props.selectedFunnel}
          isDefaultChangeHandler={props.isDefaultChangeHandler}
        />
        <FunnelChartCustomFunction
          setStages={props?.setStages}
          stages={props?.stages}
          stagesLength={props?.stagesLength}
          addStageHandler={props?.addStageHandler}
          changeStageHandler={props?.changeStageHandler}
          newStage={props.newStage}
          newStageChangeHandler={props.newStageChangeHandler}
          deleteStageHandler={props.deleteStageHandler}
          deleteNewStageHandler={props.deleteNewStageHandler}
        />
      </div>
      {/* <div className="card">
        <h3
          style={{
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: "500",
            textAlign: "left",
            marginLeft: "14px",
            marginTop: "8px",
          }}
        >
          Won Reasons
        </h3>
        <div>
          <form>
            {props?.reasons.map((reason) => {
              const { id, name, type, destroy } = reason;
              return type === "won" && destroy === false ? (
                <span key={id}>
                  <input
                    name={id}
                    value={name}
                    onChange={(e) => props.changeHandler("won", e)}
                    style={{
                      border: "none",
                      borderBottom: "1px solid #C5C5D3",
                      width: "135px",
                      marginBottom: "15px",
                      outline: "none",
                    }}
                  />
                  {wonLength.length >= 2 && (
                    <button
                      style={{
                        marginRight: "30px",
                        border: "none",
                        background: "transparent",
                        padding: "0",
                        outline: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => props.deleteHandler(id)}
                      type="button"
                    >
                      <img src={DeleteIcon} alt="Delete" width="14" />
                    </button>
                  )}
                </span>
              ) : null;
            })}
          </form>
          <form>
            <input
              type="text"
              name="newWonReason"
              value={props.newWonReason}
              placeholder="Add Reason"
              onChange={props.newReasonChangeHandler}
              style={{
                border: "none",
                borderBottom: "1px solid #C5C5D3",
                width: "135px",
                marginBottom: "15px",
                outline: "none",
              }}
            />
            <button
              disabled={props.newWonReason.length < 1}
              style={{
                marginLeft: "5px",
              }}
              type="button"
              className="button-sm"
              onClick={() => props.addReasonHandler("won", props.newWonReason)}
            >
              Add
            </button>
          </form>
        </div>
        <h3
          style={{
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: "500",
            textAlign: "left",
            marginLeft: "14px",
            marginTop: "8px",
          }}
        >
          Lost Reasons
        </h3>
        <div>
          <form>
            {props?.reasons.map((reason) => {
              const { id, name, type, destroy } = reason;
              return type === "lost" && destroy === false ? (
                <span key={id}>
                  <input
                    name={id}
                    value={name}
                    onChange={(e) => props.changeHandler("lost", e)}
                    style={{
                      border: "none",
                      borderBottom: "1px solid #C5C5D3",
                      width: "135px",
                      marginBottom: "15px",
                      outline: "none",
                    }}
                  />
                  {lostLength.length >= 2 && (
                    <button
                      key={id}
                      style={{
                        marginRight: "30px",
                        border: "none",
                        background: "transparent",
                        padding: "0",
                        outline: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => props.deleteHandler(id)}
                      type="button"
                    >
                      <img src={DeleteIcon} alt="Delete" width="14" />
                    </button>
                  )}
                </span>
              ) : null;
            })}
          </form>
          <form>
            <input
              type="text"
              name="newLostReason"
              placeholder="Add Reason"
              value={props.newLostReason}
              onChange={props.newReasonChangeHandler}
              style={{
                border: "none",
                borderBottom: "1px solid #C5C5D3",
                width: "135px",
                marginBottom: "15px",
                outline: "none",
              }}
            />
            <button
              disabled={props.newLostReason.length < 1}
              style={{
                marginLeft: "5px",
              }}
              className="button-sm"
              type="button"
              onClick={() =>
                props.addReasonHandler("lost", props.newLostReason)
              }
            >
              Add
            </button>
          </form>
        </div>
      </div> */}
    </div>
  );
}

export default FunnelCustomFunction;
