import ReactModal from "react-modal";

const DeleteApiKeyModal = ({
  showModal,
  handleCloseModal,
  submitHandler,
  loading,
  selectedId,
}) => {
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `35vh`, top: `17%` }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Delete API Key</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <p>
              Are you sure you want to delete this API key. All apps using this
              API key will no longer work
            </p>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={loading}
                onClick={() => submitHandler(false, true, selectedId)}
              >
                {loading ? "Deleting" : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default DeleteApiKeyModal;
