export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const VERIFY_OTP = "/verify-otp";
export const RESET_PASSWORD = "/reset-password";
export const LANDING = "/";
export const CUSTOMERPROFILE = "/profile";
// export const HOME = '/home';
// export const EDITCOOK = '/edit-cook/:id';
// export const FINDCOOK = '/find-cook';
// export const COOKSTAGES = '/cook-stages';
// export const ASSIGNEDCOOKS = '/assigned-cooks';
// export const USERACCESS = '/user-access';
// export const CHANGEPASSWORD = '/change-password';
