import { useState } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import { colourStyles } from "../../constants/selectStyles";

const GenerateApiKeyModal = ({
  showModal,
  handleCloseModal,
  submitHandler,
  loading,
}) => {
  const [name, setName] = useState("");
  const [allowedServices, setAllowedServices] = useState([
    { label: "ZoomInfo", value: "ZoomInfo" },
  ]);

  const allServices = [{ label: "ZoomInfo", value: "ZoomInfo" }];
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `40vh`, top: `17%` }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Generate New API Key</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <label>API key name</label>
            <input value={name} onChange={(e) => setName(e.target.value)} />
            <label>Allowed Services</label>
            <Select
              value={allowedServices}
              closeMenuOnSelect={false}
              isMulti
              name={"allowedServices"}
              styles={colourStyles}
              options={allServices}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              required={true}
              onChange={(selectedOption) => setAllowedServices(selectedOption)}
            />
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={
                  loading ||
                  name === "" ||
                  allowedServices?.length === 0 ||
                  !allowedServices
                }
                style={
                  loading ||
                  name === "" ||
                  allowedServices?.length === 0 ||
                  !allowedServices
                    ? {
                        background: "#e5e5e5",
                        color: "white",
                        cursor: "not-allowed",
                      }
                    : {}
                }
                onClick={() => submitHandler(name, allowedServices)}
              >
                {loading ? "Saving" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default GenerateApiKeyModal;
