import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import "../sass/SignIn.scss";
import "../sass/Grid.scss";
import SalesdashLogo from "../images/SalesdashLogoLogin.png";
import axios from "axios";
import { BASE_URL } from "../config";
import { NotificationManager } from "react-notifications";
// import { LANDING } from "../constants/routes";
// import { Link } from "react-router-dom";

class ResetPassword extends Component {
  state = {
    password: "",
    confirmPassword: "",
    errorMessage: false,
    successMessage: false,
    query: this.useQuery(),
    showPassword: false,
    showConfirmPassword: false,
  };
  useQuery() {
    return new URLSearchParams(this.props.history.location.search);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (
      this.state.password === "" ||
      this.state.password === null ||
      this.state.confirmPassword === "" ||
      this.state.confirmPassword === null
    ) {
      this.setState({ errorMessage: true });
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ errorMessage: true });
      return;
    }
    axios({
      method: "POST",
      url: `${BASE_URL}/auth/reset-password`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        email: this.state.query.get("email"),
        reset_token: this.state.query.get("reset_token"),
        password: this.state.password,
        confirm_password: this.state.confirmPassword,
      },
    })
      .then((res) => {
        console.log("ye res hai,", res, res.data, res?.message, typeof res);
        if (res?.status === 200) {
          this.setState({ successMessage: true });
          setTimeout((window.location.href = "/"), 3000);
        } else if (res?.status === 401) {
          NotificationManager.error(res?.message);
        }
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.code === 406 || err?.response?.data?.code === 401
            ? err?.response?.data?.message
            : "Something went wrong. Please try again."
        )
      );
  };
  render() {
    return (
      <div className="signin">
        <Container>
          <Row>
            <Col xl={6} lg={6}>
              <div className="signin__left">
                <div className="signin__form">
                  {/* {this.state.query.get("reset_token")} */}
                  <h2 className="signin__heading">
                    Reset Password - Salesdash
                  </h2>
                  <p>Please enter your new password.</p>
                  {this.state.successMessage ? (
                    <h3>
                      Password Reset Successful - Redirecting to Login page
                    </h3>
                  ) : (
                    <form onSubmit={this.submitHandler}>
                      <label htmlFor="password" className="signin__label">
                        Password
                      </label>
                      <div style={{ display: "flex" }}>
                        <input
                          className="signin__input"
                          type={this.state.showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Enter your new password"
                          onChange={this.changeHandler}
                          style={
                            this.state.errorMessage === true &&
                            (this.state.email === null ||
                              this.state.email === "")
                              ? {
                                  border: "2px solid #F36363",
                                }
                              : {}
                          }
                        />
                        <button
                          onClick={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                          className="showpassword-button"
                          type="button"
                        >
                          {this.state.showPassword ? (
                            <i class="fa fa-eye-slash" aria-hidden="true"></i>
                          ) : (
                            <i class="fa fa-eye" aria-hidden="true"></i>
                          )}
                        </button>
                      </div>
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {this.state.errorMessage === true &&
                        (this.state.password === null ||
                          this.state.password === "")
                          ? "Password is required"
                          : null}
                      </div>
                      <label htmlFor="password" className="signin__label">
                        Confirm Password
                      </label>
                      <div style={{ display: "flex" }}>
                        <input
                          className="signin__input"
                          type={
                            this.state.showConfirmPassword ? "text" : "password"
                          }
                          name="confirmPassword"
                          placeholder="Confirm your new password"
                          onChange={this.changeHandler}
                        />
                        <button
                          onClick={() =>
                            this.setState({
                              showConfirmPassword:
                                !this.state.showConfirmPassword,
                            })
                          }
                          className="showpassword-button"
                          type="button"
                        >
                          {this.state.showConfirmPassword ? (
                            <i class="fa fa-eye-slash" aria-hidden="true"></i>
                          ) : (
                            <i class="fa fa-eye" aria-hidden="true"></i>
                          )}
                        </button>
                      </div>
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {this.state.errorMessage === true &&
                        (this.state.confirmPassword === null ||
                          this.state.confirmPassword === "")
                          ? "Confirm Password is required"
                          : null}
                      </div>
                      <div
                        style={{
                          color: "#F36363",
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {this.state.errorMessage === true &&
                        this.state.password !== this.state.confirmPassword
                          ? "Password and Confirm Password do not match"
                          : null}
                      </div>
                      <button className="signin__button-1" type="submit">
                        Reset Password
                      </button>
                    </form>
                  )}
                  {/* <Link to={LANDING}>Back to Login</Link> */}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={6}>
              <div className="grad">
                <img
                  src={SalesdashLogo}
                  alt=""
                  width="600px"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ResetPassword;
